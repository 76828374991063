angular
  .module('barometerApp.worksheet')
  .factory('commonWorksheetService', commonWorksheetService);

commonWorksheetService.$inject = [
  '$http',
  '$q',
  'bubbleChartService',
  'entityService',
  'optionListService',
  'utilService'
];

function commonWorksheetService($http,
                                $q,
                                bubbleChartService,
                                entityService,
                                optionListService,
                                utilService) {

  var _worksheetEntities = {};

  var DEFAULT_WORKSHEET_CONFIGS = {
    Bubble_Chart: {
      entityType: null,
      dataSources: {
        x: null,
        y: null,
        z: null,
        xScaleMode: bubbleChartService.SCALE_MODES.HIGHCHARTS_AUTO_FIT,
        yScaleMode: bubbleChartService.SCALE_MODES.HIGHCHARTS_AUTO_FIT
      }
    },
    Field_Aggregation: {
      perspective: {entityType: null, qualifier: null, entity: null},
      pathway: {data: null},
      metric: {entity: null, qualifiers: null},
      groupBy: {data: null}
    },
    Lifecycle_Roadmap: {order: 'date', customOrder: null},
    Matrix: {},
    Radial_Connection: {},
    Relational_Diagram: {layout: {name: 'COLA', mode: 'auto', nodePositions: {}}},
    none: {}
  };

  var WORKSHEET_TEMPLATES = [
    {
      bn: '044S00000001',
      name: 'Bubble_Chart',
      url: '/b/js/src/bit.ng/worksheet/partials/bubble-chart-worksheet.html',
      dashboardUrl: '/b/js/src/bit.ng/bubble-chart/partials/bubble-chart.html'
    },
    {
      bn: '044S00000006',
      name: 'Field_Aggregation',
      url: '/b/js/src/bit.ng/worksheet/partials/metric-aggregation-worksheet.html',
      dashboardUrl: '/b/js/src/bit.ng/metric-aggregation/partials/metric-aggregation.html'
    },
    {
      bn: '044S00000007',
      name: 'Lifecycle_Roadmap',
      url: '/b/js/src/bit.ng/worksheet/partials/roadmap-chart-worksheet.html',
      dashboardUrl: '/b/js/src/bit.ng/roadmap-chart/partials/dashboard-roadmap-chart.html'
    },
    {
      bn: '044S00000003',
      name: 'Matrix',
      url: '/b/js/src/bit.ng/worksheet/partials/matrix-worksheet.html',
      dashboardUrl: '/b/js/src/bit.ng/matrix/partials/matrix.html'
    },
    {
      bn: '044S00000008',
      name: 'Radial_Connection',
      url: '/b/js/src/bit.ng/worksheet/partials/radial-chart-worksheet.html',
      dashboardUrl: '/b/js/src/bit.ng/radial-chart/partials/radial-chart.html'
    },
    {
      bn: '044S00000009',
      name: 'Relational_Diagram',
      url: '/b/js/src/bit.ng/worksheet/partials/relational-diagram-worksheet.html',
      dashboardUrl: '/b/js/src/bit.ng/relational-diagram/partials/dashboard-relational-diagram.html'
    },
    {bn: '', name: 'none', url: '/b/js/src/bit.ng/worksheet/partials/no-worksheet.html', dashboardUrl: ''}
  ];

  //--------------------------------------
  // PRIVATE FUNCTIONS
  //--------------------------------------

  // The worksheetType on the entity will look like, eg "Bubble Chart" - cut it down to "bubblechart"
  function getFormattedWorksheetTypeName(rawWorksheetTypeNameFromBackend) {
    return rawWorksheetTypeNameFromBackend.replace(/\s+/g, '').toLowerCase();
  }

  // Returns latest dataSource information
  // Currently only applies to Scorecard dataSources, updating names and checking for deletion
  // (Returns null if deleted)
  function getLatestDataSourceInfo(dataSource) {
    var deferred = $q.defer();

    if (!dataSource || dataSource.dataType !== 'SCORECARD') {
      deferred.resolve(dataSource);
    } else {
      entityService.getBasicInfo(dataSource.bn).then(function (data) {
        var entity = data.data;
        deferred.resolve({
          bn: dataSource.bn,
          type: dataSource.type,
          dataType: dataSource.dataType,

          // Only these props may have changed
          name: entity.name,
          displayableName: entity.displayableName
        });
      }, function () {
        // Service returns Error 404 if entity no longer exists
        deferred.resolve(null);
      });
    }
    return deferred.promise;
  }

  // Updates config with the latest entity information for any relevant dataSources
  // Currently, only bubbleCharts trigger this, but future worksheets could follow the pattern
  // Assumes that dataSources are attributes of config.dataSources
  function updateDataSourceInfo(config) {
    var deferred = $q.defer();
    var dataSourcePromises = [];
    var dataSourceNames = [];

    if (config && config.dataSources) {
      _.each(config.dataSources, function (dataSourceInfo, dataSourceName) {
        dataSourcePromises.push(getLatestDataSourceInfo(dataSourceInfo));
        dataSourceNames.push(dataSourceName);
      });
    }

    // Once the requests are done, update the dataSources on the config object
    // Result
    $q.all(dataSourcePromises).then(function (result) {
      _.each(result, function (dataSource, index) {
        config.dataSources[dataSourceNames[index]] = dataSource;
      });
      deferred.resolve();
    });
    return deferred.promise;
  }

  function migrateConfigIfNecessary(worksheetTypeName, config) {
    if (worksheetTypeName === 'bubblechart') {
      bubbleChartService.migrateConfigIfNecessary(config);
    }
  }

  //--------------------------------------
  // PUBLIC FUNCTIONS
  //--------------------------------------

  /**
   * Export my public functions.
   */
  return {
    browserNotSupported: browserNotSupported,
    getDefaultConfig: getDefaultConfig,
    getTemplatePageByWorksheetInsightTypeBn: getTemplatePageByWorksheetInsightTypeBn,
    getWorksheetEntity: getWorksheetEntity,
    isWorksheet: isWorksheet,
    updateWorksheetConfig: updateWorksheetConfig
  };


  function isWorksheet(entityTypeCode) {
    return entityTypeCode === EntityTypes.WK2.typeCode;
  }

  function getTemplatePageByWorksheetInsightTypeBn(insightTypeBn) {
    return _.find(WORKSHEET_TEMPLATES, {bn: insightTypeBn});
  }

  function browserNotSupported(worksheetTypeName) {
    return false;
  }

  // Wrapper around getting entity basic info
  // Allows multiple UI components to call without causing multiple HTTP/API requests
  function getWorksheetEntity(worksheetBn, forceRefreshFromServer) {
    var deferred = $q.defer();
    var service = this;
    if (worksheetBn && typeof worksheetBn !== 'undefined' && (forceRefreshFromServer || !_worksheetEntities.hasOwnProperty(worksheetBn))) {
      entityService.getBasicInfo(worksheetBn).then(function (data) {

        // Set default config if not present (this will be the case right after create)
        if (!data.data.configuration || !data.data.configuration.length) {
          data.data.configuration = service.getDefaultConfig(data.data.insightType.name);
          deferred.resolve(data.data);
        }
        else {
          var unescaped = data.data.configuration.replace(/\\/g, '');

          // Strip off extra, wrapping quotes, when present
          if (unescaped.indexOf('"') === 0) {
            unescaped = unescaped.substr(1, unescaped.length - 2);
          }

          try {
            data.data.configuration = JSON.parse(unescaped);

            // Perform any config migrations required for this worksheet type
            migrateConfigIfNecessary(data.data.insightType.name, data.data.configuration);

            updateDataSourceInfo(data.data.configuration).then(function () {
              _worksheetEntities[worksheetBn] = data.data;
              deferred.resolve(data.data);
            });

          } catch (err) {
            console.error('Error parsing configuration: ', err);
            data.data.configuration = service.getDefaultConfig(data.data.insightType.name);
            deferred.resolve(data.data);
          }
        }
      });
    } else {
      deferred.resolve(_worksheetEntities[worksheetBn]);
    }
    return deferred.promise;
  }

  /**
   *
   * @param worksheetBn
   * @param newConfig
   * @param entityTypesInWorksheet
   * @param callback
   */
  function updateWorksheetConfig(worksheetBn, newConfig, entityTypesInWorksheet, callback) {
    var deferred = $q.defer();
    this.getWorksheetEntity(worksheetBn).then(function (worksheetEntity) {
      worksheetEntity.configuration = newConfig;

      // Oddly, entityTypes (a comma-delimted string) is _required_ for the POST to not error
      // If not passed in from caller, use 'SYS'.
      // TODO: Remove this requirement on the backend?
      worksheetEntity.entityTypes = entityTypesInWorksheet || 'SYS';
      entityService.updateBasicInfo(worksheetEntity, utilService.getBnCode(worksheetBn)).then(function (data) {
        _worksheetEntities[worksheetEntity] = data.data;
        if (callback) {
          callback();
        }
        deferred.resolve(data);
      });
    });
    return deferred.promise;
  }

  function getDefaultConfig(worksheetTypeName) {
    return angular.copy(DEFAULT_WORKSHEET_CONFIGS[worksheetTypeName]) || {};
  }
}
