angular.module('barometerApp.summary')
  .directive('editLayoutLink', ['$http', '$compile', '$modal', '$timeout', function ($http, $compile, $modal, $timeout) {
    return {
      scope: 'isolate',
      link: function ($scope, $element, $attrs) {
        $element.click(function () {
          $scope.markup = $attrs.markup;
          $scope.editCopyCreate = $attrs.editCopyCreate;
          $scope.modelExpression = $attrs.model;

          $scope.currentModel = $scope.$eval($scope.modelExpression);
          // always work with a copy... maybe make this configurable later
          $scope.workingModel = angular.copy($scope.currentModel);

          $scope.workingModel.type = $attrs.type;
          if ($scope.editCopyCreate === 'copy') {
            $scope.workingModel.bn = getSyntheticBnForNewEntity();
            $scope.workingModel.name = $scope.workingModel.name + "[NEW]";

            $.each($scope.workingModel.contentBlocks, function (index) {
              if (this.configuration.contentBlockType === 'TABLE') {
                // set column spec bn to null so the two copies won't share the same spec
                // nd: this introduces a defect, where the copied table will use the default columns.
                // but this is better than the two summary views changing each others tables
                this.configuration.columnSpecBn = null;
              }
            });
          }

          var entityType = null;
          if ($scope.entityType) {
            entityType = $scope.entityType;
          } else {
            // TODO : This should be changed to pass DSH through $scope.entityType, forcing it for now
            entityType = 'DSH';
          }

          if ($scope.editCopyCreate === 'create') {
            $scope.workingModel = {
              "bn": getSyntheticBnForNewEntity(), "entityType": entityType, "sections": [
                {
                  "bn": getSyntheticBnForNewEntity(),
                  "name": "", "order": 0, "layoutSectionType": "HALF"
                }
              ], "contentBlocks": []
            };
          }

          var dialogOptions = {
            windowClass: 'modal-full',
            backdrop: 'static',
            keyboard: true,
            templateUrl: '/b/js/src/bit.ng/summary/partials/summary-edit.html',
            controller: 'SummaryLayoutEditCtrl',
            resolve: {
              workingModel: function () {
                return $scope.workingModel
              },
              assocCode: function () {
                return $scope.assocCode
              },
              entityType: function () {
                return $scope.entityType
              },
              entityName: function () {
                return $scope.entityName
              },
              chooseDataSource: function () {
                // layoutModel.layout was passed in as the currentModel, it has the chooseDataSource flag set on it.
                var chooseDataSourceBoolean = false;
                if (!$scope.currentModel) {
                  chooseDataSourceBoolean = true;
                }
                else if ($scope.currentModel.chooseDataSource) {
                  chooseDataSourceBoolean = true;
                }
                return chooseDataSourceBoolean;
              },
              filter: function () {
                var filter = null;
                if ($scope.filterModel) {
                  filter = $scope.filterModel.filter
                }
                return filter;
              }
            }
          };

          const modal = $modal.open(dialogOptions);
          modal.opened.then(function () {
            //Warn user of unsaved changes before following any links
            window.onbeforeunload = () => true;

            $timeout(function () {
              prototype.setModalMaxHeights();
            }, 200);
          });
          modal.result.finally(function () {
            window.onbeforeunload = null;
          });
        });
      }
    }
  }]);
