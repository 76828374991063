angular.module('barometerApp.contentStatus')
  .directive('workflowEditField', ['contentStatusService',
    (contentStatusService) => {
      return {
        scope: {
          contentStatusId: "=",
          disabled: "="
        },
        template: `
          <div style="position: relative; top: -8px; vertical-align: middle; margin-bottom: 20px;">
            <span switch model="workflowEnabled" click-handler="toggleClicked()" disabled="disabled"></span>
            <span style="position: relative; top: 3px; margin-left: 4px;">{{label()}}</span>
          </div>`,
        link: (scope, element, attrs) => {
          scope.label = () => {
            return scope.workflowEnabled ? 'On' : 'Off'
          };
          scope.workflowEnabled = contentStatusService.contentStatusTriggersWorkflow(scope.contentStatusId);
          scope.toggleClicked = () => {
            scope.workflowEnabled = !scope.workflowEnabled;
            scope.contentStatusId = contentStatusService.getDefaultLegacyContentStatusId(scope.workflowEnabled);
          };
          // Detect contentStatusId initialization (BARO-19679).
          // Because the same assignment above happens before contentStatusId is assigned in scope:
          scope.$watch('contentStatusId', (oldVal, newVal) => {
            if (scope.workflowEnabled === undefined && typeof newVal === 'string') {
              scope.workflowEnabled = contentStatusService.contentStatusTriggersWorkflow(newVal)
            }
          })
        }
      }
    }])
  .directive('legacyContentStatusEdit', ['contentStatusService', 'utilService',
    (contentStatusService, utilService) => {
      return {
        scope: {
          disabled: "=",
          contentStatusId: "=",
          completionDate: "="
        },
        templateUrl: '/b/js/src/bit.ng/content-status/partials/legacy-content-status-edit.html',
        link: (scope, element, attrs) => {

          const contentStatusIdChanged = (contentStatusId) => {
            // Clear the completionDate if appropriate
            if (contentStatusId && (contentStatusId !== 'INPR' && contentStatusId !== 'PLND')) {
              scope.completionDateModel.completionDate = null;
            }
            // Update the overall workflow state
            scope.contentStatusTriggersWorkflow = contentStatusService.contentStatusTriggersWorkflow(contentStatusId);
          };

          // Needs to be an object to trigger digests with bs-datepicker
          scope.completionDateModel = {
            completionDate: scope.completionDate,
            dateFormat: utilService.getDateFormatForBootstrapDatepicker()
          };

          scope.options = contentStatusService.getContentStatusOptions();
          scope.options.forEach(option => {
            option.group = option.workflow ? 'Workflow: On' : 'Workflow: Off';
          });
          scope.contentStatusTriggersWorkflow = contentStatusService.contentStatusTriggersWorkflow(scope.contentStatusId);
          scope.showAdvanced = false;

          if (!scope.showCompletionDate) {
            scope.showCompletionDate = true;
          }

          scope.toggleAdvanced = () => scope.showAdvanced = !scope.showAdvanced;

          // Map updated values to passed-in scope variable
          scope.$watch('completionDateModel.completionDate', (newVal, oldVal) => {
            scope.completionDate = scope.completionDateModel.completionDate;
          });

          scope.$watch('model.contentStatusId', (newVal, oldVal) => {
            if (newVal !== oldVal) {
              contentStatusIdChanged(newVal);
            }
          });
        }
      }
    }])
  .directive('workflowSwitch', ['contentStatusService', 'entityService', '$modal', '$rootScope', 'redux', 'utilService',
    (contentStatusService, entityService, $modal, $rootScope, redux, utilService) => {
      return {
        scope: {
          entityModel: "="
        },
        template: `
        <div class="workflow-switch">
          <div class="workflow-switch-inner" ng-show="!workflowModel.loading">
            <div class="workflow-switch-inner-inner">
              <label class="workflow-switch-label">Workflow {{workflowModel.enabled ? 'On': 'Off'}}</label>
              <div class="workflow-switch-switch-wrapper">
                <span tooltip="{{tooltipHtml()}}" tooltip-placement="top">

                  <span
                    switch
                    color="{{workflowModel.enabled ? 'orange': 'green'}}"
                    model="workflowModel.enabled"
                    click-handler="workflowToggleClicked()"
                    disabled="!entityModel.isWorkflowEditor">
                  </span>
                </span>
              </div>
            </div>
            <a ng-click="helpClicked()">Help & Advanced</a>
          </div>
          <div class="content-status"><span class="status-label">Content Status: </span><span class="status-value">{{entityModel.contentStatus.displayableName}}</span></div>
        </div>
      `,
        link: (scope, element, attrs) => {
          const { store, actions } = redux;

          scope.workflowModel = {
            loading: true,
            canEdit: undefined,
            enabled: undefined
          };

          const setWorkFlowModel = (contentStatusId) => {
            if (!contentStatusId) return;
            const workflowEnabled = contentStatusService.contentStatusTriggersWorkflow(contentStatusId);
            redux.store.dispatch(
              redux.actions.setWorkflowEnabled(workflowEnabled, 'entityPage')
            );
            scope.workflowModel = {
              loading: false,
              canEdit: entityService.getCanEdit(),
              enabled: workflowEnabled
            }
          };

          scope.tooltipHtml = () => {
            if (!scope.entityModel.isWorkflowEditor) {
              return 'Only editors can turn Workflow on or off'
            }
          };

          scope.helpClicked = () => {

            // Clone of the entity model for the modal advanced editor
            scope.workingEntityModel = _.cloneDeep(scope.entityModel);

            // If necessary, convert to local date format
            if (scope.workingEntityModel.contentCompletionDate && scope.workingEntityModel.contentCompletionDate.length) {
              var dateFormat = utilService.getLocaleLongDateFormat();
              const isValidLocalDate = moment(scope.workingEntityModel.contentCompletionDate, dateFormat, true).isValid();
              if (!isValidLocalDate) {
                scope.workingEntityModel.contentCompletionDate = moment(scope.workingEntityModel.contentCompletionDate).format(dateFormat);
              }
            }

            scope.modal = $modal.open({
              backdrop: 'static',
              keyboard: true,
              templateUrl: '/b/js/src/bit.ng/content-status/partials/workflow-help-modal.html',
              windowClass: 'modal',
              scope: scope
            });
          };

          scope.closeModal = () => {
            scope.modal.dismiss('cancel');
          };

          scope.saveLegacyContentStatus = () => {
            scope.modal.dismiss('cancel');

            // Update the workflow toggle's state
            setWorkFlowModel(scope.workingEntityModel.contentStatus.id);

            // Update the entityModel
            scope.entityModel.contentStatus.id = scope.workingEntityModel.contentStatus.id;
            scope.entityModel.contentCompletionDate = scope.workingEntityModel.contentCompletionDate;

            // Use a clean model for saving
            let saveModel = _.cloneDeep(scope.workingEntityModel);
            saveModel = entityService.adjustDateFields(saveModel);
            entityService.updateBasicInfo(saveModel).then((res) => {
              $rootScope.$broadcast("reload");
            });
          };

          // This is only used to set the initial value for the toggle
          // We need to wait for the entityModel to be populated
          scope.$watch('entityModel.contentStatus.id', (newVal, oldVal) => {
            if (oldVal === undefined && typeof newVal === 'string') {
              setWorkFlowModel(newVal);
            }
          });

          scope.workflowToggleClicked = () => {

            // Update the relevant models
            const workflowEnabled = !scope.workflowModel.enabled;
            scope.workflowModel.enabled = workflowEnabled;
            const contentStatusId = contentStatusService.getDefaultLegacyContentStatusId(workflowEnabled);
            scope.entityModel.contentStatus.id = contentStatusId;

            store.dispatch(
              actions.setWorkflowEnabled(workflowEnabled, 'entityPage')
            );

            // Use a clean model for saving
            let saveModel = _.cloneDeep(scope.entityModel);
            saveModel.contentStatus.id = contentStatusService.getDefaultLegacyContentStatusId(scope.workflowModel.enabled);
            saveModel = entityService.adjustDateFields(saveModel);
            entityService.updateBasicInfo(saveModel).finally(() => {
              $rootScope.$broadcast("reload");
            });
          };

          if (scope.entityModel && scope.entityModel.contentStatus) {
            setWorkFlowModel(scope.entityModel.contentStatus.id);
          }
        }
      }
    }])
;
