angular.module('barometerApp.common')
  .directive('lifecyclePill', [function () {
    return {
      templateUrl: '/b/js/src/bit.ng/lifecycle-pill/partials/lifecycle-pill.html',
      scope: {
        label: '=',
        type: '='
      },
      link: function ($scope) {
        $scope.getPillClass = function(){
          if ($scope.type && $scope.type.length){
            return $scope.type.toLowerCase();
          }
          return '';
        }
      }
    }
  }])