angular.module('barometerApp.common')
  .controller('GenericPageCtrl', ['$rootScope', '$scope', '$timeout', '$location', 'tourService', 'securityService', 'urlService',
    function ($rootScope, $scope, $timeout, $location, tourService, securityService, urlService) {

    }])
  .controller('NavigablePageCtrl', ['$scope', '$location', 'tenantService',
    function ($scope, $location, tenantService) {
      tenantService.getCurrentTenantsNavigationItems()
        .then(navItems => {
          $scope.includeTasks = navItems.some(n => n.type === 'TASKS');
          $scope.includePinned = navItems.some(n => n.type === 'PINNED');
        });
      if ($location.search().pdf === 'true') {
        $("body").addClass("pdf-new");
      }
    }])
  .controller('QuickCreateCtrl', ['$scope', '$modal', 'optionListService', 'entityService', '$window', 'urlService', '$rootScope', 'searchService', '$http', 'commonWorksheetService', 'bitConstants', 'priorityEntitiesService', 'dataDictionaryHeaderService', 'entityMixedService','redux','utilService', 'tenantService', 'contentStatusService',
    function ($scope, $modal, optionListService, entityService, $window, urlService, $rootScope, searchService, $http, commonWorksheetService, bitConstants, priorityEntitiesService, dataDictionaryHeaderService, entityMixedService, redux, utilService, tenantService, contentStatusService) {
      var ROLES = {};
      ROLES.blueprintRole = "ROLE_BLUEPRINT_MODULE_ACCESS";
      ROLES.circleRole = "ROLE_CIRCLE_MODULE_ACCESS";
      ROLES.dataRole = "ROLE_DATA_MODULE_ACCESS";
      ROLES.demandRole = "ROLE_DEMAND_MODULE_ACCESS";
      ROLES.peopleRole = "ROLE_PEOPLE_MODULE_ACCESS";
      ROLES.registeredUserRole = "ROLE_REGISTERED_USER";
      ROLES.strategyRole = "ROLE_STRATEGY_MODULE_ACCESS";
      ROLES.superEditorRole = "ROLE_SUPER_EDITOR";
      ROLES.tagEditorRole = "ROLE_TAG_EDITOR";

      var visibleEnterpriseItems = [];
      var visibleInsightItems = [];
      $scope.COLUMN_SIZE = 8;
      $scope.priorityEntities = [];
      $scope.showAllEnterpriseEntities = $scope.priorityEntities.length === 16 || $scope.priorityEntities.length === 0;
      $scope.entityPermissions = {};
      $scope.enterpriseItemsColumnOne = [];
      $scope.enterpriseItemsColumnTwo = [];
      $scope.insightItemsColumnOne = [];
      $scope.insightItemsColumnTwo = [];
      $scope.quickCreateModel = {};

      // The entity bn is used when configuring the quick add confirm editable table.  Technically the table does not need knowledge of the current entity
      // being viewed, but if we don't set it then it overwrites the entityBn and sets it to null for other editable table directives on the page, namely the
      // associationEditor and the addByBrowse tables.  I don't know why they are sharing the same entityBn since I thought they were their own instances of the directive.
      $scope.quickCreateModel.entityBn = urlService.getEntityBn();

      $scope.quickCreateModel.createMode = "";
      $scope.quickCreateModel.entityTypeCode = "";
      $scope.quickCreateModel.entityTypeName = "";
      $scope.quickCreateModel.tableOptions = {
        associationDetailType: "associationMulti",
        displayAdd: false,
        parentEntity: null,
        displayPivot: false,
        displayFilters: false
      };
      $scope.quickCreateModel.tableId = 'quickCreateTbl';

      //from entity create ctrl:
      $scope.optionListModel = {};
      $scope.entityCreateModel = {};
      $scope.submitAttempted = false;
      $scope.confirmPanelOpen = false;
      $scope.fileUploadModel = {
        showProgress: true,
        required: true,
        formSubmitted: false,
        url: '/b/api/assetstore',
        response: null
      };

      const { store, selectors } = redux;
      const { user, settings } = selectors;

      const unsubscribePriorityEntitiesListener = priorityEntitiesService.listenForPriorityEntities((priorityEntities = []) => {
        if ($scope.priorityEntities.length !== priorityEntities.length) {
          $scope.priorityEntities = priorityEntities;
          $scope.showAllEnterpriseEntities = priorityEntities.length === 16 || priorityEntities.length === 0;
          setVisibleItems($scope.showAllEnterpriseEntities);
        }
      });

      const shouldEnableButton = (state) => {
        // we don't want the add button to show up before we know if it
        // should be disabled or not, so disable it while loading settings
        const isLoading = settings.getIsLoading(state);
        if (isLoading) {
          return false;
        }
        const hasAnyEntityPermissions = user.getHasAnyEntityPermissions(state) || user.getIsSettingsAdmin(state);
        const isAddDisabled = settings.getIsAddDisabled(state);
        return !isAddDisabled || hasAnyEntityPermissions;
      }

      const getEntityTypePermissions = (state) => {
        const securityMap = user.getEntityTypeSecurityMap();
        return Object.keys(securityMap).reduce((acc, entityType) => {
          if (!$scope.isAddDisabled) {
            acc[entityType] = true;
          } else {
            const hasPermission = user.getHasEntityPermission(state, { entityType });
            acc[entityType] = hasPermission;
          }
          return acc;
        }, {});
      }

      $scope.enableButton = shouldEnableButton(store.getState());
      $scope.roles = user.getRoles(store.getState());
      $scope.isAddDisabled = settings.getIsAddDisabled(store.getState());
      $scope.entityPermissions = getEntityTypePermissions(store.getState());

      const unsubscribe = store.subscribe(() => {
        const state = store.getState();
        const isAddDisabled = settings.getIsAddDisabled(state);
        const roles = user.getRoles(state);
        if (roles.length !== $scope.roles.length || isAddDisabled !== $scope.isAddDisabled) {
          $scope.isAddDisabled = isAddDisabled;
          $scope.enableButton = shouldEnableButton(state);
          $scope.entityPermissions = getEntityTypePermissions(state);
          setVisibleItems($scope.showAllEnterpriseEntities);
      }

      });

      $scope.$on('$destroy', function() {
        unsubscribe();
        unsubscribePriorityEntitiesListener();
      });

      $scope.openCreateModal = function (entityTypeCode, insightBn, name) {

        if (insightBn != null) {
          setInsightModalInfo(entityTypeCode, insightBn, name);
        } else {
          setEnterpriseModalInfo(entityTypeCode)
        }

        const optionListTypes = EntityOptionListTypes[$scope.quickCreateModel.entityTypeCode];

        $scope.loadOptionListChoices(optionListTypes, function () {
          $scope.quickCreateModel.modal = $modal.open({
            backdrop: 'static',
            keyboard: true,
            templateUrl: getTemplateUrl(entityTypeCode),
            scope: $scope
          });
        });

      };

      function setEnterpriseModalInfo(entityTypeCode) {
        $scope.quickCreateModel.entityTypeCode = entityTypeCode;
        $scope.quickCreateModel.entityTypeName = EntityTypes[entityTypeCode].displayName;
        $scope.entityCreateModel.contentStatus =  { id: contentStatusService.getDefaultContentStatus(entityTypeCode) };
      }

      function setInsightModalInfo(entityTypeCode, insightBn, name) {
        $scope.quickCreateModel.insightTypeInfo = {bn: insightBn, name: name};
        $scope.quickCreateModel.entityTypeName = name;
        $scope.quickCreateModel.entityTypeCode = entityTypeCode;
        if (entityTypeCode === 'LYT') {
          $scope.entityCreateModel.entityType = 'DSH';
        }
        $scope.entityCreateModel.insightType = {
          bn : insightBn
        }
      }

      function setVisibleItems(showAllEnterpriseItems) {
        const authorizedCatalogs = tenantService.getCurrentTenantsAuthorizedCatalogs();
        const filtered = authorizedCatalogs
          .filter(c => {
            const entityType = c.entityTypeCode;
            if (entityType === 'ARL' || entityType === 'TOP' || entityType === 'TEP') {
              return false;
            }
            return $scope.entityPermissions[entityType];
          });

        visibleEnterpriseItems = [];
        visibleInsightItems = [];
        _.forEach(filtered, function (catalog) {
          if ($scope.entityPermissions[catalog.entityTypeCode]) {
            if (showAllEnterpriseItems || $scope.priorityEntities.includes(catalog.entityTypeCode)) {
              if(!InsightTypes[catalog.bn]) {
                visibleEnterpriseItems.push(getAuthorizedItem(catalog));
              }
            }
            if(InsightTypes[catalog.bn]) {
              visibleInsightItems.push(getAuthorizedItem(catalog));
            }
          }
        });

        visibleEnterpriseItems.sort(sortByDisplayName);
        $scope.enterpriseItemsColumnOne = visibleEnterpriseItems.slice(0, $scope.COLUMN_SIZE);
        $scope.enterpriseItemsColumnTwo = visibleEnterpriseItems.slice($scope.COLUMN_SIZE);

        visibleInsightItems.sort(sortByDisplayName);
        $scope.insightItemsColumnOne = visibleInsightItems.slice(0, $scope.COLUMN_SIZE);
        $scope.insightItemsColumnTwo = visibleInsightItems.slice($scope.COLUMN_SIZE);

      }


      function sortByDisplayName(a, b) {
        if (a.displayName < b.displayName) {
          return -1;
        }
        if (a.displayName > b.displayName) {
          return 1;
        }
        return 0;
      }

      function getAuthorizedItem(catalog) {
        const isInsight = (catalog.entityTypeCode === 'IST');
        const type = (isInsight) ? InsightTypes[catalog.bn] : EntityTypes[catalog.entityTypeCode];
        return {
          bn: catalog.bn,
          displayName: type.displayName,
          entityTypeCode: type.typeCode,
          entityTypeDisplayName: type.displayNamePlural,
          isPriority: priorityEntitiesService.isPriorityEntityType(type.typeCode),
          insightTypeBn: (isInsight) ? catalog.bn : '',
          isEnterprise: (!isInsight),
          contentLoaderUrl: getContentLoaderUrl(type.typeCode)
        }
      }

      function getRoles(entityTypeCode) {
        switch (entityTypeCode) {
          case 'COM':
          case 'PER':
            return [ROLES.peopleRole];
          case 'DAT':
            return [ROLES.dataRole];
          case 'DEM':
            return [ROLES.demandRole];
          case 'SKI':
          case 'STA':
          case 'TEC':
            return [ROLES.registeredUserRole];
          case 'STR':
            return [ROLES.strategyRole];
          case 'TAG':
            return [ROLES.tagEditorRole, ROLES.superEditorRole];
          default:
            return [ROLES.blueprintRole];
        }
      }

      function getContentLoaderUrl(entityTypeCode) {
        switch (entityTypeCode) {
          case 'CON':
            return createUrl('connectionadd');
          case 'ORG':
            return createUrl('organizationadd');
          case 'ADQ':
            return createUrl('reportadd');
          case 'CRL':
            return createUrl('ruleadd');
          case 'AUD':
            return createUrl('scorecardadd');
          default:
            return null;
        }

      }

      function getTemplateUrl(entityTypeCode) {
        const baseUrl = '/b/js/src/bit.ng/entity/partials/';
        if ("WK2" === entityTypeCode) {
          return baseUrl + 'insight/create-modal.html';
        } else {
          return baseUrl + SingleEntityPageNames[entityTypeCode] + '/create-modal.html';
        }
      }

      function createUrl(urlSegment) {
        return urlService.prepareWicketPageUrl2(urlSegment);
      }

      $scope.cancelQuickAdd = function () {
        $scope.$broadcast('quickAddCancelled');
        $scope.reset();
        $scope.quickCreateModel.modal.close('cancel');
      };

      $scope.cancelConfirmPanel = function () {
        if ($scope.confirmPanelOpen) {
          $scope.reset();
          $scope.disableButton = false;
          $scope.quickCreateModel.confirmModal.close('cancel');
          $scope.confirmPanelOpen = false;
        }
      };

      $scope.loadOptionListChoices = function (dataListTypes, openModal) {
        optionListService.getOptionListChoices(dataListTypes)
          .then(function (results) {
            $scope.optionListModel.data = [];
            _.forEach(results.data, function (item) {
              if (!$scope.optionListModel.data[item.dataListType]) {
                $scope.optionListModel.data[item.dataListType] = [];
              }
              $scope.optionListModel.data[item.dataListType].push(item);
            });
            openModal();
          });
      };

      $scope.getDefaultActiveLifecycleStateChoice = function (dataListType) {
        var defaultChoice = _.find($scope.optionListModel.data[dataListType], {'dataListItemType': 'ACTIVE'});
        if (defaultChoice) {
          return defaultChoice.bn;
        }
      };

      $scope.getDefaultEnterpriseApprovalChoice = function (dataListType) {
        var defaultChoice = _.find($scope.optionListModel.data[dataListType], {'dataListItemType': 'UNSPECIFIED'});
        if (defaultChoice) {
          return defaultChoice.bn;
        }
      };

      $scope.createEntity = function (formInvalid) {
        $scope.submitAttempted = true;
        $scope.fileUploadModel.formSubmitted = true;
        if ($scope.isValid(formInvalid)) {
          $scope.disableButton = true;

          //make service call to get count of matches, if there are any display confirm panel
          var entityName = $scope.getEntityName();
          var bnCode = EntityTypes[$scope.quickCreateModel.entityTypeCode].bnCode;
          var addlFilter = {};

          let insightTypeBn = ($scope.quickCreateModel.hasOwnProperty('insightTypeInfo'))
            ? $scope.quickCreateModel.insightTypeInfo.bn : '';

          //wrapper to scope filter
          var countPromise = function (filter) {
            return searchService.getBrowseCountForSimpleTextSearch(bnCode, entityName, filter, insightTypeBn);
          };

          //wrapper to scope filter
          var countCallback = function (filter) {
            //this returns a function that will act as our promise call back. It will keep the filter bound to it's scope.
            return function (results) {
              if (results.data > 0) {
                //we found matches, open confirm modal and close previous quickadd modal
                $scope.quickCreateModel.filter = {
                  params: [],
                  searchString: entityName
                };
                $scope.quickCreateModel.filter = _.merge($scope.quickCreateModel.filter, filter);
                $scope.quickCreateModel.confirmModal = $modal.open({
                  backdrop: 'static',
                  keyboard: true,
                  templateUrl: '/b/js/src/bit.ng/entity/partials/quickadd-confirm.html',
                  scope: $scope
                });
                $scope.confirmPanelOpen = true;
                $scope.quickCreateModel.modal.dismiss('cancel');
              } else {
                $scope.create();
              }
            };
          };

          //if the entity we're creating is a Layout (Dashboard) we need to filter the duplicate check to just Dashboards, not Summary Views.
          if (bnCode === EntityTypes.LYT.bnCode) {
            dataDictionaryHeaderService.getDataDictionaryBnsByEntityTypeAndSearchableFieldName(EntityTypes.LYT.typeCode, "entityType").then(function (result) {
              var data = result.data;
              if (data.length === 1) {
                var filter = {};
                filter[data[0].bn] = ["DSH"];
                addlFilter.params = [filter];
              }
              countPromise(addlFilter).then(countCallback(addlFilter));
            });
          } else {
            countPromise().then(countCallback());
          }
        } else {
          $scope.disableButton = false;
        }
      };

      $scope.create = function () {
        // Disable the create button so we don't create multiple entities if the button is spammed.
        $scope.submitAttempted = false;

        var _entityTypeCode = $scope.quickCreateModel.entityTypeCode;
        var bnTypeCode = EntityTypes[_entityTypeCode].bnCode;
        $scope.entityCreateModel.entityDiscriminator = _entityTypeCode;
        if (!$scope.entityCreateModel.contentStatus) {
          $scope.entityCreateModel.contentStatus = { id: contentStatusService.getDefaultContentStatus(_entityTypeCode) }
        }
        if ($scope.quickCreateModel.entityType) {
          $scope.entityCreateModel.entityType = $scope.quickCreateModel.entityType;
        }

        // Set the worksheet's specific creation information.
          entityService.createEntity($scope.entityCreateModel, bnTypeCode, $scope.fileUploadModel.response)
            .then(function (results) {
              if ($scope.quickCreateModel.createMode === 'redirect') {
                var pageUrl = BnCodeToUrl[bnTypeCode];
                if (pageUrl) {
                  $window.location.href = urlService.getBaseUrl() + pageUrl + '/' + results.data.bn;
                }
              }
              if ($scope.quickCreateModel.createMode === 'notify') {
                var bnCode = EntityTypes[$scope.quickCreateModel.entityTypeCode].bnCode;
                var createData = {
                  bn: results.data.bn,
                  name: results.data.name,
                  entityTypeCode: bnCode,
                  typeBn: EntityBnCodeToIconClass[bnCode]
                };
                $scope.$emit('newItemAssociation', createData);
                $scope.cancelQuickAdd();
                $scope.cancelConfirmPanel();
              }
            });
      };

      $scope.getEntityName = function () {
        return $scope.quickCreateModel.entityTypeCode === 'PER' ? $scope.entityCreateModel.firstName + ' ' +
          $scope.entityCreateModel.lastName : $scope.entityCreateModel.name;
      };

      $scope.isValid = function (formInvalid) {
        return $scope.quickCreateModel.entityTypeCode === 'AST' ? $scope.fileUploadModel.response !== null && !formInvalid : !formInvalid;
      };

      $scope.reset = function () {
        $scope.submitAttempted = false;
        $scope.entityCreateModel = {};
        $scope.fileUploadModel.formSubmitted = false;
        $scope.fileUploadModel.response = null;
      };

      $scope.showAllEnterpriseEntitiesClicked = function (event) {
        $scope.showAllEnterpriseEntities = true;
        setVisibleItems($scope.showAllEnterpriseEntities);
        event.preventDefault();
        event.stopImmediatePropagation();
      };

      $scope.$on('fileSelected', function (event, data) {
        if (!$scope.entityCreateModel.name || $scope.entityCreateModel.name.length === 0) {
          $scope.entityCreateModel.name = data.file.name;
        }
        $scope.entityCreateModel.originalName = data.file.originalName;
      });

      setVisibleItems($scope.showAllEnterpriseEntities);

    }])

  .controller('CurrentUserAvatarPhotoCtrl', ['$scope', 'utilService',
    function ($scope, utilService) {
      const getURL = () => `/b/api/profile/image/${utilService.getCurrentUserBn()}?decache=${Date.now()}`;

      $scope.currentUserAvatarPhotoURL = getURL();

      $scope.$on('profilePhotoChanged', function (event, bn) {
        if (bn === utilService.getCurrentUserBn()) {
          $scope.currentUserAvatarPhotoURL = getURL();
        }
      });
    }])

  .controller('GlobalAlertController', ['$rootScope', '$scope', 'alertService',
    function ($rootScope, $scope, alertService) {
      $scope.closeAlert = function (index) {
        alertService.closeAlert(index);
      };
      $rootScope.$on('alertMessage', function (event, params) {
        alertService.addAlert(params);
      });
      $rootScope.$on('successAlertMessage', function (event, message) {
        alertService.addSuccessAlert(message);
      });
      $rootScope.$on('errorAlertMessage', function (event, message) {
        alertService.addErrorAlert(message);
      });
      $rootScope.$on('clearAllAlerts', function (event) {
        alertService.clearAllAlerts();
      });
    }])
  .controller('BasicSideNavCtrl', ['$rootScope', '$scope', 'layoutService', 'urlService', '$filter',
    function ($rootScope, $scope, layoutService, urlService, $filter) {

      $scope.basicSideNavModel = {
        selectedSectionBn: null,
        primarySectionItems: [],
        relatedSectionItems: [],
        tertiarySectionItems: []
      };

      $scope.$watch(layoutService.getCurrentLayout, function (newValue, oldValue, $scope) {
        if (newValue) {

          $scope.basicSideNavModel = {
            primarySectionItems: [],
            relatedSectionItems: [],
            tertiarySectionItems: []
          };

          // Hydrate nav item data with title and icon details.
          $.each(newValue.sections, function () {
            switch (this.category) {
              case 'primary':
                $scope.basicSideNavModel.primarySectionItems.push(this);
                break;
              case 'related':
                $scope.basicSideNavModel.relatedSectionItems.push(this);
                break;
              case 'tertiary':
                $scope.basicSideNavModel.tertiarySectionItems.push(this);
                break;
            }
            this.iconClass = SectionTypeToIconAndTitle[this.type][0];
            this.title = SectionTypeToIconAndTitle[this.type][1];
          });
        }
      });

      $scope.goToSection = function (sectionBn) {
        $rootScope.$broadcast('leftTabSelected', {sectionBn: sectionBn});
        $scope.basicSideNavModel.selectedSectionBn = sectionBn;
      };
    }])

  .controller('SelectedSectionController', ['$rootScope', '$scope', 'urlService', 'utilService', '$timeout', 'recentLocationsService', 'alertService', 'tenantService', '$window',
    function ($rootScope, $scope, urlService, utilService, $timeout, recentLocationsService, alertService, tenantService, $window) {

      const catalogCode = $scope.layoutModel.layout.bn;
      const defaultView = (catalogCode === "1") ? "DASHBOARD" : "BASIC";
      $scope.leftTabModel = {
        selectedSection: urlService.getSectionBnFromUrl() ||
          ($scope.layoutModel.layout.sections[0] && $scope.layoutModel.layout.sections[0].bn) ||
          'BASIC'
      };

      const typeCode = (utilService.isTypeCode(catalogCode)) ? utilService.getTypeCodeFromBnCode(catalogCode) : catalogCode;
      const entityType = EntityTypes[typeCode];

      if (!tenantService.isAnAuthorizedEntityTypeCode(catalogCode) && (entityType != null && entityType.typeCode !== EntityTypes.DLS.typeCode) ) {
        $window.location.href = urlService.getBaseUrl();
        alertService.addDeferredAlert({
          type: "error",
          msg: String.format("You do not have access to {0}.", EntityTypes[catalogCode].displayNamePlural)
        });
      } else {
        const viewInUrl = urlService.getSectionBnFromUrl();
        // If they do, go to that section.
        if (EntityTypes[viewInUrl] != null && tenantService.isAnAuthorizedEntityTypeCode(viewInUrl))  {
          $scope.leftTabModel.selectedSection = viewInUrl;
        } else {
          // If they don't, go to the first section or the default section.
          if (defaultView === "DASHBOARD") {
            $scope.leftTabModel.selectedSection = defaultView
          } else {
            $scope.leftTabModel.selectedSection = ($scope.layoutModel.layout.sections[0] && $scope.layoutModel.layout.sections[0].bn) || defaultView;
          }
        }

      }

      // need to get recents from section controller
      // because if user refreshes page while on section
      // the lose their recents data (out of section scope)
      recentLocationsService.getRecentsFromServerAndNotify();

      $scope.$on('leftTabSelected', function (event, data) {

        $scope.leftTabModel.selectedSection = data.sectionBn;
      });

      $scope.selectedSectionFilter = function (section) {
        return section.bn === $scope.leftTabModel.selectedSection;
      };


    }])

  // expose angular internal services and other internals to the outside world
  // just inject a service and add a getter - to access it globally:
  // var myAngularService = $('#ng-expose').scope().getMyService()
  .controller('NgExpose', ['$scope', '$rootScope', '$compile', 'urlService', 'localStorageService', 'recentLocationsService',
    function ($scope, $rootScope, $compile, urlService, localStorageService, recentLocationsService) {

      $scope.getUrlService = function () {
        return urlService;
      };
      $scope.getRootScope = function () {
        return $rootScope;
      };
      $scope.getCompile = function () {
        return $compile;
      };
      $scope.getLocalStorageService = function () {
        return localStorageService;
      };
      $scope.getRecentLocationsService = function () {
        return recentLocationsService;
      }
    }])
;

function getSyntheticBnForNewEntity() {
  return "NEW_" + Math.floor((Math.random() * 1000000) + 1);
}
