angular.module("barometerApp.transcludeToBody")
  // Manually move the HTML + angular contents of this component to the end of the <body> tag
  // Retains scope hierarchy of original DOM location
  // Handy for situations where nested HTML causes layout and overflow problems, like with fly-out menus, eg:
  //
  //     <div transclude-to-body>
  //       <div roadmap-chart-lifecycle-create-menu
  //          save-fn="saveNew()"
  //          cancel-fn="cancelAdd()"
  //          show="showAddMenu"
  //          lifecycle-state-model="selectedLSModel"
  //          row-elem="selectedLSRowElem"
  //          errors="createErrors"></div>
  //     </div>
  //
  .directive('transcludeToBody', ['$compile', function ($compile) {
    return {
      link: function (scope, elem, attrs) {
        $('body').append($compile(elem.contents())(scope));
      }
    }
  }]);