angular.module('barometerApp.common')
  .filter('findByBn', function () {
    return function (input, bn) {
      var out = [];
      if (input) {
        for (var i = 0; i < input.length; i++) {
          if (input[i].bn == bn) {
            out.push(input[i]);
          }
        }
      }
      return out;
    };
  })
  .filter('findDefault', function () {
    return function (input) {
      var out = [];
      for (var i = 0; i < input.length; i++) {
        if (input[i].tenantDefault) {
          out.push(input[i]);
        }
      }
      return out;
    };
  })
  .filter('secureByRoles', function (securityService) {
    return function (items) {
      var filtered = [];
      angular.forEach(items, function (item) {
        if (item.roles) {
          if (!securityService.hasRole(item.roles.split(","))) {
            // exclude
          } else {
            filtered.push(item);
          }
        } else {
          filtered.push(item);
        }
      });
      return filtered;
    };
  })

  /**
   * Example
   *
   * var myText = "This is an example.";
   *
   * {{myText|truncate}}
   * {{myText|truncate:5}}
   * {{myText|truncate:25:" ->"}}
   *
   * Output
   * "This is..."
   * "Th..."
   * "This is an e ->"
   *
   */
  .filter('truncate', function () {
    return function (text, length, end) {
      if (text == null || text.length == 0) {
        return null;
      }

      if (isNaN(length)) {
        length = 250;
      }

      if (end === undefined) {
        end = " ...";
      }

      if (text.length <= length || text.length - end.length <= length) {
        return text;
      } else {
        return String(text).substring(0, length - end.length) + end;
      }
    };
  })

  .filter('bytes', function () {
    return function (bytes, precision) {
      if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '-';
      if (typeof precision === 'undefined') precision = 1;
      var units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'],
        number = Math.floor(Math.log(bytes) / Math.log(1024));
      return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) + ' ' + units[number];
    }
  });