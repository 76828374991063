// Component to easily embed react components inside the angular component hierarchy
// @param name the name of the react component, eg HelloWorld
angular.module('barometerApp.reactComponentLoader')
  .directive('reactComponentLoader', ['react', 'redux', function (react, redux) {
    return {
      scope: {
        name: '@',
      },
      link: function (scope, element, attrs) {
        if (!scope.name) return console.error(`reactComponentLoader is missing required attribute "name"`);
        if (!react.hasOwnProperty(scope.name)) return console.error(`reactComponentLoader cannot find component: ${scope.name}`);

        const scopeId = attrs.scopeId || scope.$parent.$id;
        // Pass in the global redux store
        // This eliminates the need to wrap root components in <Provider>
        const props = { store: redux.store, scopeId };

        // Create the React element manually (no JSX template)
        const reactElement = react.React.createElement(react[scope.name], props, null);

        // Drop it into the DOM inside this element
        react.ReactDOM.render(reactElement, element.get(0));
      }
    }
  }]);
