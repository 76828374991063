angular.module('barometerApp.scorecard')
  .service('scorecardService', ['errorHandlingService', 'utilService', 'dataDictionaryHeaderService', 'redux', '$http', '$q',
    function (errorHandlingService, utilService, dataDictionaryHeaderService, redux, $http, $q) {
      return {
        getScorecardResultsForEntityBns: function (scorecardBn, entityBns) {
          return $http({
            method: 'POST',
            url: '/b/api/scorecard/scores/' + scorecardBn,
            data: 'entityBns=' + JSON.stringify(entityBns),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
          }).error(function (data, status, headers, config) {
            errorHandlingService.handleGenericError(status);
          });
        },
        getMaxScoreForScorecard: function (scorecardBn) {
          return $http({
            method: 'GET',
            url: '/b/api/associationDetails/list?sourceBnCode=4A&targetBnCode=3Z&relationshipType=RELATED_TO&columnSpecPurpose=associationDetails&sourceBn=' + scorecardBn
          }).error(function (data, status, headers, config) {
            errorHandlingService.handleGenericError(status);
          });
        },
        isScorecard: function(entityTypeCode) {
          return entityTypeCode === EntityTypes.CRL.typeCode;
        },
        loadInitialDataToRedux: function(scorecardBn, catalogType) {
          if (!redux.store.getState().scorecard.initialDataLoadingComplete) {
            redux.store.dispatch(redux.actions.loadScorecardInitialData({scorecardBn, catalogType, initialDataLoadingComplete: true}));
          }
        },
        getScorecardCatalogType: function() {
          return redux.store.getState().scorecard.catalogType;
        },
        setScorecardCatalogType: function(catalogType) {
          redux.store.dispatch(redux.actions.setScorecardCatalogType(catalogType));
        },
        getScorecardPredefinedFilter: function() {
          return redux.store.getState().scorecard.predefinedFilter;
        },
        setScorecardPredefinedFilter: function(predefinedFilter) {
          redux.store.dispatch(redux.actions.setPredefinedFilter(predefinedFilter));
        },
        /**
         * This function decorates the model with scorecard-specific data
         * @param tableCtrlAddModel
         * @returns updated model wrapped inside promise
         */
        decorateTableCtrlAddModelForScorecard: function(tableCtrlAddModel) {
          if (tableCtrlAddModel) {
            const deferred = $q.defer();
            const self = this;
            const { store } = redux;
            const updatedTableCtrlAddModel = _.cloneDeep(tableCtrlAddModel);
            updatedTableCtrlAddModel.displayWeightEditor = store.getState().scorecard.defaultDisplayWeightEditor;
            const catalogTypeFromRedux = this.getScorecardCatalogType();
            if (catalogTypeFromRedux) {
              dataDictionaryHeaderService.getDataDictionaryHeaders(tableCtrlAddModel.entityType.typeCode, false).then(function (response) {
                const catalogTypeBnObject = response.data.find(p => p['key'] === 'entityType');
                if (catalogTypeBnObject) {
                  const filter = {params: []};
                  const entityTypeParam = {};
                  entityTypeParam[catalogTypeBnObject.bn] = [catalogTypeFromRedux];
                  filter.params.push(entityTypeParam);
                  updatedTableCtrlAddModel.filter = filter;
                  // Update predefined filter for this scorecard in redux state
                  self.setScorecardPredefinedFilter(updatedTableCtrlAddModel.filter);
                  deferred.resolve(updatedTableCtrlAddModel);
                } else {
                  deferred.reject('catalogTypeBnObject is null');
                }
              });
              return deferred.promise;
            } else {
              deferred.reject('catalogTypeFromRedux is null');
            }
          }
          console.error('tableCtrlAddModel is null');
        },
        /**
         * Notice how we make this function return a promise even though it is not necessarily required.
         * We do this to ensure that all decorator implementations have the same 'interface' so to speak -
         * and therefore client code can call them without worrying about exact implementations
         * @param tableCtrlBrowseModel
         * @returns updated model wrapped inside promise
         */
        decorateTableCtrlBrowseModelForScorecard: function(tableCtrlBrowseModel) {
          if (tableCtrlBrowseModel) {
            const deferred = $q.defer();
            const tableCtrlBrowseModelUpdated = _.cloneDeep(tableCtrlBrowseModel);
            const predefinedFilterFromRedux = this.getScorecardPredefinedFilter();
            if (predefinedFilterFromRedux) {
              if (predefinedFilterFromRedux.params && predefinedFilterFromRedux.params.length > 0) {
                tableCtrlBrowseModelUpdated.editableTableOptions.predefinedFilter = {
                  selected: predefinedFilterFromRedux.params[0],
                };
                tableCtrlBrowseModelUpdated.useDefaultQuery = false;
                deferred.resolve(tableCtrlBrowseModelUpdated);
              } else {
                deferred.reject("Predefined filter params are null or empty");
              }
            } else {
              deferred.reject("No predefined filter for this entity in redux");
            }
            return deferred.promise;
          } else {
            console.error('tableCtrlBrowseModel is null');
          }
        }
      }
    }]);
