angular.module('barometerApp.roadmapChart')
  .controller('EntityRoadmapChartCtrl', ['$scope', '$timeout', 'roadmapChartService', 'urlService', 'entityService', 'roadmapChartScaleService', '$element', '$rootScope', 'utilService', 'tableService', 'optionListService',
    function ($scope, $timeout, roadmapChartService, urlService, entityService, roadmapChartScaleService, $element, $rootScope, utilService, tableService, optionListService) {

      const sectionBn = 'ENTITY_LIFECYCLE_STATES';

      $scope.entity = null;
      $scope.hasData = false;
      $scope.loading = true;
      $scope.editUrl = urlService.getPageIdentifierOfCurrentPage() + "lifecyclestateroadmapedit"; // This might need to be a special static map just for these URLs

      // Scope vars that tie into the roadmap libs
      $scope.sortMode = "date";
      $scope.firstDay = 1;
      $scope.weekendDays = [0, 6];
      $scope.maxHeight = 0;
      $scope.showWeekends = true;

      // For now, using default/arbitrary values.  Once the data arrives, we will re-set these, based on the data.
      $scope.scale = "year";
      $scope.scaleFactor = roadmapChartScaleService.getScaleFactor($scope.scale);
      $scope.fromDate = new Date();
      $scope.toDate = new Date();

      // This might need to be a special static map just for these URLs
      // Not sure if all the URL's matchup with the page identifier map
      $scope.editUrl = urlService.getPageIdentifierOfCurrentPage() + "lifecyclestateroadmapedit";

      $scope.editMode = 'default';
      $scope.createErrors = [];
      $scope.editErrors = [];

      // Add/Edit model
      $scope.selectedLSModel = {
        bn: null,
        entity: null,
        startDate: null,
        endDate: null,
        dataListName: null,
        dataListItem: {
          bn: null
        },
        typeOptions: [],
        selectedTypeOption: null
      };

      function resetModel() {
        $scope.showSave = false;
        $scope.editMode = 'default';
        $scope.createErrors = [];
        $scope.editErrors = [];
        $scope.selectedLSModel = {
          bn: null,
          entity: $scope.entity,
          startDate: null,
          endDate: null,
          dataListName: getDataListNameForEntity($scope.entity),
          dataListItem: {
            bn: null
          },
          typeOptions: [],
          selectedTypeOption: null
        };

        removeBodyEvent();
        populateLSTypeDropdownChoices();
      }

      function removeBodyEvent() {
        $('body').off('click.entityRoadMap');
      }

      // Fetch data for the current entity
      function loadEntityData() {
        $scope.loading = true;

        entityService.getBasicInfo(urlService.getEntityBn()).then(function (data) {
          $scope.entity = data.data;
          roadmapChartService.fetchOneRow($scope.entity, function (data) {

            // Update UI state vars
            $scope.hasData = data && data.length > 0;
            $scope.loading = false;

            // Re-set scale and date Roadmap vars, tailored to the data
            $scope.scale = roadmapChartScaleService.getTailoredScale(data[0].tasks);
            $scope.scaleFactor = roadmapChartScaleService.getScaleFactor($scope.scale);
            const dateRange = roadmapChartScaleService.getDateRange($scope.scale, $element);
            $scope.fromDate = dateRange.fromDate;
            $scope.toDate = dateRange.toDate;

            // Load visualization data
            $scope.clearData();
            $scope.loadData(data);
          });

          resetModel();
        });
      }

      // Find the right dataListName in the global constant.
      // Example dataListType array:
      //   EntityOptionListTypes['TEC'] = [
      //     {dataListType: 'TECHNOLOGY_LIFECYCLE'},
      //     {dataListType: 'TECHNOLOGY_DOMAIN'},
      //     {dataListType: 'TECHNOLOGY_APPROVAL'}
      //   ];
      function getDataListNameForEntity(entity) {
        const entityTypeCode = utilService.getEntityTypeCode(entity.bn);
        const optionListType = _.find(EntityOptionListTypes[entityTypeCode], function (type) {
          return type.dataListType.indexOf('_LIFECYCLE') !== -1;
        });
        return optionListType ? optionListType.dataListType : null;
      }

      function populateLSTypeDropdownChoices() {
        optionListService.getOptionListChoices([{dataListType: $scope.selectedLSModel.dataListName}])
          .then(function (data) {
            $scope.selectedLSModel.typeOptions = _.map(data.data, function (optionData) {
              var typeOption = {
                value: optionData.bn,
                label: optionData.displayableName
              };
              if (optionData.bn === $scope.selectedLSModel.dataListItem.bn) {
                $scope.selectedLSModel.selectedTypeOption = typeOption;
              }
              return typeOption;
            });
          });
      }

      function validateSelectedLSModel() {
        return roadmapChartService.validateLifecycleStateModel(
          $scope.selectedLSModel.selectedTypeOption ? $scope.selectedLSModel.selectedTypeOption.value : null,
          $scope.selectedLSModel.startDate,
          $scope.selectedLSModel.endDate
        );
      }

      $scope.showAddDropdownClicked = function (event) {
        showAdd(event.target);
        event.preventDefault();
      };

      function showAdd(selectedLSRowElem) {
        if ($scope.editMode === 'add') {
          return $scope.cancelAdd();
        }
        resetModel();
        $scope.editMode = 'add';
        $scope.selectedLSRowElem = $(selectedLSRowElem);

        // Close after clicking anywhere else
        $timeout(function () {
          $('body').one('click.entityRoadMap', function (event) {
            if ($scope.editMode === 'add') {
              resetModel();
            }
          });
        }, 100);
      }

      $scope.$on('roadmapLifecycleStateSelected', function (event, data) {

        // If clicked when already selected, de-select
        if ($scope.selectedLSModel && data.lifecycleState.bn === $scope.selectedLSModel.bn) {
          resetModel();
          $rootScope.$broadcast('roadmapLifecycleStateSelectionCleared');
          return;
        }

        $scope.editErrors = [];
        $scope.showSave = false;
        $scope.editMode = 'edit';

        $scope.selectedLSModel = {
          bn: data.lifecycleState.bn,
          entity: data.lifecycleState.entity,
          startDate: data.lifecycleState.startDate ? utilService.convertToLocalDate(data.lifecycleState.startDate) : null,
          endDate: data.lifecycleState.endDate ? utilService.convertToLocalDate(data.lifecycleState.endDate) : null,
          dataListName: data.lifecycleState.dataListItem.dataList.type,
          dataListItem: {
            bn: data.lifecycleState.dataListItem.bn
          },
          typeOptions: [],
          selectedTypeOption: null
        };
        $scope.selectedLSRowElem = data.elem;
        populateLSTypeDropdownChoices();

        // Close after clicking anywhere else
        $timeout(function () {
          $('body').one('click', function (event) {
            if ($scope.selectedLSModel && $scope.selectedLSModel.bn === data.lifecycleState.bn) {
              resetModel();
              $rootScope.$broadcast('roadmapLifecycleStateSelectionCleared');
            }
          });
        }, 100);
      });

      // Called from the roadmap viz
      $scope.$on('roadmapAddLifecycleStateToEntity', function (event, entity, rowElem) {
        showAdd(rowElem);
      });

      $scope.saveChanges = function () {

        $scope.editErrors = validateSelectedLSModel();
        if ($scope.editErrors.length) {
          return;
        }

        var createParams = {
          entityDiscriminator: 'PLL',
          startDate: $scope.selectedLSModel.startDate ? utilService.convertToISOUTCLocalDate($scope.selectedLSModel.startDate) : null,
          endDate: $scope.selectedLSModel.endDate ? utilService.convertToISOUTCLocalDate($scope.selectedLSModel.endDate) : null,
          dataListItem: {
            bn: $scope.selectedLSModel.selectedTypeOption.value
          }
        };
        entityService.createEntity(createParams, "PLL").then(function (result) {
          var assocParams = {
            sourceBn: $scope.selectedLSModel.entity.bn,
            associations: {
              removedAssocs: [{targetBn: $scope.selectedLSModel.bn, relationshipType: 'RELATED_TO'}],
              addedAssocs: [{targetBn: result.data.bn, relationshipType: 'RELATED_TO'}]
            },
            sectionBn: sectionBn
          };
          var bnToDelete = $scope.selectedLSModel.bn;
          $scope.clearSelected();
          tableService.updateAssociations(assocParams).then(function () {
            // We don't need to wait for the delete –– this is just behind-the-scenes clean-up
            entityService.deleteEntity(bnToDelete);
          });
        });
      };

      $scope.clearSelected = function () {
        resetModel();
        $rootScope.$broadcast('roadmapLifecycleStateSelectionCleared');
      };

      // Nearly identical to saveChanges, except no deletes to make!
      $scope.saveNew = function () {

        $scope.createErrors = validateSelectedLSModel();
        if ($scope.createErrors.length) {
          return;
        }

        const createParams = {
          entityDiscriminator: 'PLL',
          startDate: $scope.selectedLSModel.startDate ? utilService.convertToISOUTCLocalDate($scope.selectedLSModel.startDate) : null,
          endDate: $scope.selectedLSModel.endDate ? utilService.convertToISOUTCLocalDate($scope.selectedLSModel.endDate) : null,
          dataListItem: {
            bn: $scope.selectedLSModel.selectedTypeOption.value
          }
        };
        entityService.createEntity(createParams, "PLL").then(function (result) {
          const assocParams = {
            sourceBn: $scope.selectedLSModel.entity.bn,
            associations: {
              addedAssocs: [{targetBn: result.data.bn, relationshipType: 'RELATED_TO'}]
            },
            sectionBn: sectionBn
          };
          resetModel();
          tableService.updateAssociations(assocParams);
        });
      };

      $scope.cancelAdd = function () {
        resetModel();
      };

      $scope.deleteSelectedLifecycle = function () {
        const params = {
          sourceBn: $scope.selectedLSModel.bn,
          targetBnCode: utilService.getBnCode($scope.selectedLSModel.entity.bn),
          indexBn: $scope.selectedLSModel.entity.bn,
          associations: {
            addedAssocs: [],
            removedAssocs: [
              {targetBn: $scope.selectedLSModel.entity.bn, relationshipType: 'RELATED_TO'}
            ]
          },
          sectionBn: sectionBn
        };
        const bnToDelete = $scope.selectedLSModel.bn;
        $scope.clearSelected();
        tableService.updateAssociations(params).then(function (response) {
          if(response.data.proposedChangeBns && response.data.proposedChangeBns.length > 0) {
            // don't delete the entity, the relationship was not actually removed.
          } else {
            entityService.deleteEntity(bnToDelete).then(function (data) {
            });
          }
        });
      };

      // Called when blue bar determines indexing is completed
      $scope.$on('loadSection', function (event, sectionBn) {
        if (sectionBn === 'ENTITY_LIFECYCLE_STATES') {
          loadEntityData();
        }
      });

      $scope.$on('load', function (event, viewId) {
        loadEntityData();
      });

      $scope.$on('reload', function (event, viewId) {
        loadEntityData();
      });

      // Make sure roadmap click events reach body element
      // Allows open dropdowns to close as expected
      $scope.rowOrTaskClicked = function () {
        $timeout(function () {
          $("body").trigger("click")
        });
      };

      // When the menu goes off screen, treat that like a "click away"
      $scope.$on('roadmapChartLifecycleEditMenuOffScreen', function (event, data) {
        $timeout(function () {
          $("body").trigger("click")
        });
      });
    }]);
