angular.module('barometerApp.entity')
  .service('adminService', ['$http',  'alertService',
    function($http, alertService) {

      return {
        getUserRoles: function(roleName) {
          var queryParam = (roleName === undefined) ? "?type=TENANT" : ("?type=TENANT&role=" + roleName);
          var url = '/b/api/userroles' + queryParam;
          return $http({
            method: 'GET',
            url: url
          }).
            success(function (data, status, headers, config) {
            }).
            error(function (data, status, headers, config) {
              var msgString = 'Unable to retrieve data: ';
              alertService.addErrorAlert(msgString);
            });
        },
        evaluateUserRoleByPersonBn: function(userRoleBn, personBn) {
          var request, createUserURL;
          request = { "userRoleBn": userRoleBn, "personBn": personBn };
          createUserURL = '/b/api/userroles/' ;
          return $http({
            method: 'POST',
            url: createUserURL,
            data: request,
            headers: {'Content-Type': 'application/json'}
          }).
          success(function (data, status, headers, config) {
          }).
          error(function (data, status, headers, config) {
            var msgString = 'Unable to load detail for entity';
            alertService.addErrorAlert(msgString);
          });
        },
        createUser: function(createRequest, personBn) {
          var request, createUserURL;
          request = createRequest;
          createUserURL = '/b/api/users/'+ personBn ;
          return $http({
            method: 'POST',
            url: createUserURL,
            data: request,
            headers: {'Content-Type': 'application/json'}
          }).
            success(function (data, status, headers, config) {
            }).
            error(function (data, status, headers, config) {
              var msgString = 'Unable to create User';
              console.log(status +' error in adminService.createUser for createRequest: ' + createRequest + ' and personBn: ' + personBn + '.');
              if (status >= 400 && data.errorMessages) {
                msgString += ': ' + data.errorMessages;
              }
              alertService.addErrorAlert(msgString);
            });
        },
        setPassword: function(passwordRequest, personBn) {
          var request, setPasswordURL;
          request = passwordRequest;
          setPasswordURL = '/b/api/users/'+ personBn + '/passwords';
          return $http({
            method: 'POST',
            url: setPasswordURL,
            data: request,
            headers: {'Content-Type': 'application/json'}
          }).
            success(function (data, status, headers, config) {
            }).
            error(function (data, status, headers, config) {
              var msgString = 'Unable to set password';
              console.log(status ,' error in adminService.setPassword for passwordRequest: ', passwordRequest, ' and personBn: ' + personBn + '.');
              if (status >= 400 && data.message) {
                msgString += ': ' + data.message;
              }
              alertService.addErrorAlert(msgString);
            });
        },
        resetPassword: function(personBn) {
          //
          var  resetPasswordURL;
          resetPasswordURL = '/b/api/users/'+ personBn + '/passwords';
          return $http({
            method: 'GET',
            url: resetPasswordURL
          }).
            success(function (data, status, headers, config) {
            }).
            error(function (data, status, headers, config) {
              var msgString = 'Unable to set password';
              console.log(status +' error in adminService.resetPassword for person: ' + personBn + '.');
              if (status >= 400 && data.errorMessages) {
                msgString += ': ' + data.errorMessages;
              }
              alertService.addErrorAlert(msgString);
            });
        },
        currentRoles: function() {
          return $http({
            method: 'GET',
            url: '/b/api/currentroles'
          })
            .success(function (data, status, headers, config) {
            })
            .error(function (data, status, headers, config) {
              console.error(`error in currentRoles. status: ${status}`);
            });
        }
      }
    }
  ]);
