angular
  .module('barometerApp.metricAggregation')
  .controller('MetricAggregationSubheaderController', MetricAggregationSubheaderController);

MetricAggregationSubheaderController.inject = [
  '$modal',
  '$rootScope',
  '$scope',
  '$timeout',
  'commonWorksheetService',
  'metricAggregationService',
  'urlService'
];

/**
 *
 */
function MetricAggregationSubheaderController($modal,
                                              $rootScope,
                                              $scope,
                                              $timeout,
                                              commonWorksheetService,
                                              metricAggregationService,
                                              urlService) {

  $scope.showSave = false;
  $scope.pointSelected = false;
  $scope.config = null;
  $scope.pathway = null;
  $scope.includeEntityTypes = ['ACT', 'CAP', 'COM', 'CON', 'DAT', 'DEM', 'PHY', 'AST', 'MKT', 'ORG', 'PRD', 'SKI', 'STA', 'SYS', 'STR', 'TEC'];

  //--------------------------------------------
  // PUBLIC FUNCTIONS
  //--------------------------------------------

  $scope.getPrettyPerspectiveName = function () {
    var name = '--';
    if ($scope.config && $scope.config.perspective && $scope.config.perspective.entityType) {
      name = $scope.config.perspective.entityType.displayName;
      // Note that $scope.config.perspective.entity is the Report to filter by
      if ($scope.config && $scope.config.perspective && $scope.config.perspective.entity) {
        name += ' (' + $scope.config.perspective.entity.name + ')';
      } else {
        name += ' (All)';
      }
    }
    return name;
  };

  $scope.launchConfigModal = function ($event) {
    $event.preventDefault();
    var modal = $modal.open({
      backdrop: 'static',
      keyboard: true,
      templateUrl: '/b/js/src/bit.ng/metric-aggregation/partials/metric-aggregation-config-modal.html',
      controller: 'MetricAggregationConfigController',
      resolve: {
        config: function () {
          return $scope.config;
        }
      }
    });

    // This is called for both Save and Cancel of the modal, but newConfig will only exist if Save.
    modal.result
      .then(function (newConfig) {
        if (newConfig) {
          $scope.config = newConfig;
          $rootScope.$broadcast('worksheetConfigUpdated', newConfig);
          commonWorksheetService.updateWorksheetConfig(urlService.getEntityBn(), newConfig, newConfig.perspective.entityType.typeCode, function () {
            $rootScope.$broadcast('MetricAggregationConfigChanged', newConfig);
          });
        }
      });
  };

  //--------------------------------------------
  // DO IT!
  //--------------------------------------------

  commonWorksheetService.getWorksheetEntity(urlService.getEntityBn()).then(function (entity) {
    $scope.config = entity.configuration;

    $scope.pathwayValues = metricAggregationService.getPathwayTypeOptions()[$scope.config.perspective.entityType.typeCode];
    if ($scope.config.perspective.entityType) {
      var pathwayValues = metricAggregationService.getPathwayTypeOptions()[$scope.config.perspective.entityType.typeCode];
      $scope.pathway = pathwayValues[$scope.config.pathway.data].name;
    }
  });
}