// Set the moment locale for use in dates/times
// This must happen before angular bootstraps for use in $strapConfig
moment.locale('en');

// Set HTML lang attribute
$('html').attr('lang', 'en');

// barometerApp is the root module – all others declared as dependencies
angular.module('barometerApp', [
    'angularFileUpload',
    'barometerApp.activityStream',
    'barometerApp.addWidget',
    'barometerApp.advancedTable',
    'barometerApp.base',
    'barometerApp.bean',
    'barometerApp.bubbleChart',
    'barometerApp.bundled',
    'barometerApp.changeStatusNotifier',
    'barometerApp.common',
    'barometerApp.compliance',
    'barometerApp.connection',
    'barometerApp.content',
    'barometerApp.contentStatus',
    'barometerApp.dataImport',
    'barometerApp.domainFilter',
    'barometerApp.download',
    'barometerApp.donutChart',
    'barometerApp.editor',
    'barometerApp.entity',
    'barometerApp.entityMixed',
    'barometerApp.field',
    'barometerApp.fieldset',
    'barometerApp.filter',
    'barometerApp.home',
    'barometerApp.inputWithDatepicker',
    'barometerApp.insight',
    'barometerApp.layout',
    'barometerApp.lock',
    'barometerApp.matrix',
    'barometerApp.metricAggregation',
    'barometerApp.nav',
    'barometerApp.navbarGlossy',
    'barometerApp.neo4j',
    'barometerApp.envNoticeBanner',
    'barometerApp.relationalDiagram',
    'barometerApp.orgCoverageReport',
    'barometerApp.pagination',
    'barometerApp.priorityEntities',
    'barometerApp.radialChart',
    'barometerApp.reactComponentLoader',
    'barometerApp.recentLocations',
    'barometerApp.remediationForm',
    'barometerApp.roadmapChart',
    'barometerApp.search',
    'barometerApp.serviceNow',
    'barometerApp.superSelector',
    'barometerApp.scorecard',
    'barometerApp.simpleTable',
    'barometerApp.summary',
    'barometerApp.summaryInfo',
    'barometerApp.switch',
    'barometerApp.systemInventoryStatus',
    'barometerApp.tasks',
    'barometerApp.taskManager',
    'barometerApp.tour',
    'barometerApp.table',
    'barometerApp.tenant',
    'barometerApp.tenantEndpoint',
    'barometerApp.transcludeToBody',
    'barometerApp.typeAhead',
    'barometerApp.watch',
    'barometerApp.worksheet',
    'barometerApp.workspace',
    'gantt',
    'LocalStorageModule',
    'ngSanitize',
    'ui.bootstrap',
    'ui.keypress',
    'ui.select2',
    'ui.jq',
    'ui.bootstrap.datetimepicker',
    'ui.dateTimeInput',
    'ui.date',
    'ui.sortable',
    '$strap.directives'], function () {
  })
  // Intercept all http requests to ensure UTF-8 encoding is applied
  .factory('httpRequestInterceptor', function () {
    return {
      request: function (config) {

        // Complain whenever a request has data (a body) but no Content-Type is set
        if (config.data && !config.headers['Content-Type']) {
          console.error('Error: Request has data, but Content-Type is not set.');
        }

        // If Content-Type is set, make sure it has charset=UTF-8
        if (config.headers['Content-Type'] && config.headers['Content-Type'].indexOf('charset') == -1) {
          config.headers['Content-Type'] += ';charset=UTF-8;';
        }
        return config;
      }
    };
  })
  .config(function ($locationProvider, $tooltipProvider, localStorageServiceProvider, $httpProvider) {
    $locationProvider.html5Mode(false);

    //set default tooltip options
    $tooltipProvider.options({
      placement: 'right',
      appendToBody: true
    });

    localStorageServiceProvider.prefix = $('#userBnHolder').text();

    // Tie in the http request interceptor to auto-add charset to requests
    $httpProvider.interceptors.push('httpRequestInterceptor');

  })
  .value('$strapConfig', {
    datepicker: {
      language: 'en-us'
    },
    timepicker: {
      // Show AM/PM (and use 12-hour time) if locale requires it
      // E.g., for en-US:
      //   moment.localeData().longDateFormat("LT") -->  "h:mm A"
      showMeridian: moment.localeData().longDateFormat("LT").indexOf('A') !== -1
    }
  })
  .run(function ($rootScope) {

    window.$rootScope = $rootScope;

    // Warn IE8 users about lack of support
    if (isIE8() && !localStorage.getItem('ie8WarningDismissed')){
      alert('Sorry, barometerIT does not support IE8.  \n You may experience problems! \n We recommend using IE11 or Chrome.');
      localStorage.setItem('ie8WarningDismissed', 'true');
    }
});

// Set all other module dependencies
// Note: the module LocalStorageModule is self-loading
angular.module('barometerApp.activityStream', []);
angular.module('barometerApp.addWidget', ['barometerApp.common', 'barometerApp.search']);
angular.module('barometerApp.advancedTable', ['barometerApp.common', 'barometerApp.filter']);
angular.module('barometerApp.base', ['barometerApp.common']);
angular.module('barometerApp.bean', ['barometerApp.common', 'barometerApp.serviceNow']);
angular.module('barometerApp.bubbleChart', ['barometerApp.common']);
angular.module('barometerApp.bundled', []);
angular.module('barometerApp.changeStatusNotifier', ['barometerApp.lock']);
angular.module('barometerApp.common', []);
angular.module('barometerApp.compliance', []);
angular.module('barometerApp.content', ['barometerApp.common']);
angular.module('barometerApp.contentStatus', []);
angular.module('barometerApp.connection', ['barometerApp.common']);
angular.module('barometerApp.dataImport', ['barometerApp.common']);
angular.module('barometerApp.domainFilter', ['barometerApp.common', 'barometerApp.filter']);
angular.module('barometerApp.donutChart', []);
angular.module('barometerApp.download', []);
angular.module('barometerApp.editor', []);
angular.module('barometerApp.entity', ['barometerApp.common']);
angular.module('barometerApp.entityMixed', ['barometerApp.common']);
angular.module('barometerApp.field', ['barometerApp.common']);
angular.module('barometerApp.fieldset', ['barometerApp.common', 'barometerApp.superSelector']);
angular.module('barometerApp.filter', ['barometerApp.common']);
angular.module('barometerApp.inputWithDatepicker', []);
angular.module('barometerApp.insight', ['barometerApp.common', 'barometerApp.entity']);
angular.module('barometerApp.home', ['barometerApp.common']);
angular.module('barometerApp.layout', ['barometerApp.common']);
angular.module('barometerApp.lock', []),
angular.module('barometerApp.matrix', ['barometerApp.advancedTable']);
angular.module('barometerApp.metricAggregation', ['barometerApp.advancedTable']);
angular.module('barometerApp.nav', []);
angular.module('barometerApp.navbarGlossy', []);
angular.module('barometerApp.neo4j', ['barometerApp.common']);
angular.module('barometerApp.envNoticeBanner', []);
angular.module('barometerApp.orgCoverageReport', []);
angular.module('barometerApp.pagination', []);
angular.module('barometerApp.priorityEntities', ['barometerApp.common']);
angular.module('barometerApp.radialChart', []);
angular.module('barometerApp.reactComponentLoader', []);
angular.module('barometerApp.recentLocations', []);
angular.module('barometerApp.relationalDiagram', ['barometerApp.neo4j']);
angular.module('barometerApp.remediationForm', []);
angular.module('barometerApp.roadmapChart', []);
angular.module('barometerApp.search', ['barometerApp.common']);
angular.module('barometerApp.serviceNow', ['barometerApp.common']);
angular.module('barometerApp.summary', ['barometerApp.common']);
angular.module('barometerApp.summaryInfo', ['barometerApp.common']);
angular.module('barometerApp.superSelector', []);
angular.module('barometerApp.switch', []);
angular.module('barometerApp.systemInventoryStatus', []);
angular.module('barometerApp.table', ['barometerApp.common', 'barometerApp.filter', 'barometerApp.lock']);
angular.module('barometerApp.tasks', ['barometerApp.common']);
angular.module('barometerApp.taskManager', ['barometerApp.common', 'barometerApp.simpleTable']);
angular.module('barometerApp.tour', ['barometerApp.common']);
angular.module('barometerApp.tenant', ['barometerApp.common']);
angular.module('barometerApp.tenantEndpoint', ['barometerApp.common']);
angular.module('barometerApp.transcludeToBody', []);
angular.module('barometerApp.typeAhead', []);
angular.module('barometerApp.scorecard', ['barometerApp.common']);
angular.module('barometerApp.simpleTable', ['barometerApp.common', 'barometerApp.filter']);
angular.module('barometerApp.watch', ['barometerApp.common', 'barometerApp.filter', 'barometerApp.simpleTable']);
angular.module('barometerApp.worksheet', ['barometerApp.common']);
angular.module('barometerApp.workspace', ['barometerApp.common']);
