angular.module('barometerApp.layout')
/**
 * Support services contain utility operations that do not hold state and
 * which do not directly make connections to the server-side. They are analogous
 * to our Java -Support class pattern.
 */
  .service('layoutSupport', ['bitConstants', 'styleService',
    function (bitConstants, styleService) {
      return {
        /**
         * A "smart" version of addRelatedSections.
         *
         * @param groupedResults
         * @param layoutModel
         */
        updateRelatedSections: function (groupedResults, layoutModel) {
          // Remember self so that we can call our local functions within callback function, below.
          var self = this;
          // Add sections.
          _.forEach(layoutModel.layout.contentBlocks, function (contentBlock) {
            // Before we bother, is is our contentBlock an entity block?
            var entityTypes = bitConstants.getFieldsetEntityTypes();
            var isEntityBlock = _.find(entityTypes, function (entityType) {
              return entityType.typeCode === contentBlock.sectionBn;
            });
            if (!isEntityBlock || contentBlock.configuration.contentBlockType === 'METRIC') {
              // In a lodash forEach loop, this should work like a continue, because we're only exiting the closure.
              return;
            }
            // Okay, we've got a worthwhile entity block. Proceed.
            var resultGroup = self.getResultGroupByEntityType(groupedResults.data.results, contentBlock.sectionBn);
            var resultsExist = resultGroup && resultGroup.groupCount > 0;
            var entityCode = contentBlock.sectionBn; // Same as resultGroup.entityTypeCode.
            var sectionExists = self.isSectionInLayout(layoutModel, entityCode);
            //
            if (sectionExists && resultsExist) {
              self.updateSection(layoutModel, resultGroup);
            }
            else if (!sectionExists && resultsExist) {
              self.addSection(contentBlock, layoutModel, resultGroup);
            }
            else if (sectionExists && !resultsExist) {
              self.removeSection(contentBlock, layoutModel)
            }
          });
          layoutModel.layout.sections.sort(this.compareOrdered);
        },
        /**
         * Test to see if an entity-section already exists in a layout.
         *
         * @param layoutModel
         * @param bnCode
         * @returns {boolean}
         */
        isSectionInLayout: function (layoutModel, bnCode) {
          var isInLayout = false
          _.forEach(layoutModel.layout.sections, function (section) {
            if(section.bn === bnCode) {
              isInLayout = true;
            }
          });
          return isInLayout;
        },
        /**
         * Insert an entity-section into a layout.
         *
         * @param contentBlock The content block from which to model the new section.
         * @param layoutModel The model in which to add the new section.
         * @param resultGroup A sub-set of associated entities and counts (e.g. Systems).
         */
        addSection: function (contentBlock, layoutModel, resultGroup) {
          layoutModel.layout.sections.push({
            bn: contentBlock.sectionBn,
            order: contentBlock.order,
            roles: contentBlock.roles,
            category: "dynamic-related",
            assocCode: resultGroup.entityTypeCode,
            name: bitConstants.getEntityTypeDisplayNameForTypeCode(resultGroup.entityTypeCode, true),
            count: resultGroup.groupCount,
            title: bitConstants.getEntityTypeDisplayNameForTypeCode(resultGroup.entityTypeCode, true),
            iconClass: styleService.getIconClassForEntityTypeCode(resultGroup.entityTypeCode),
            entityResults: resultGroup.entityTypeResults,
            type: bitConstants.getEntityTypeDisplayNameForTypeCode(resultGroup.entityTypeCode, true)
          });
        },
        /**
         * Find an entity-section in a layout and update its result content.
         *
         * @param layoutModel
         * @param resultGroup
         */
        updateSection: function (layoutModel, resultGroup) {
          _.forEach(layoutModel.layout.sections, function (section) {
            if(section.bn === resultGroup.entityTypeCode) {
              // TODO Is this safe?
              // Note, we're going to overwrite the counts in the model regardless of diff.
              // If this causes bad refresh behavior, add a diff check.
              section.entityResults = resultGroup.entityTypeResults;
              section.count = resultGroup.groupCount;
            }
          });
        },
        /**
         * Remove an entity-section from a layout.
         *
         * @param contentBlock
         * @param layoutModel
         */
        removeSection: function (contentBlock, layoutModel) {
          // See documentation: http://api.jquery.com/jquery.grep
          layoutModel.layout.sections = jQuery.grep(layoutModel.layout.sections, function (x) {
            return x.assocCode !== contentBlock.sectionBn;
          });
        },
        /**
         * Extract a sub-set of associated entities and counts.
         *
         * @param groupedResults An array of arrays, grouped by entity code (e.g. SYS).
         * @param entityType The entity type to extract.
         */
        getResultGroupByEntityType: function (groupedResults, entityType) {
          for (var i = 0; i < groupedResults.length; i++) {
            var group = groupedResults[i];
            if (group.entityTypeCode === entityType) {
              return group;
            }
          }
        },
        /**
         * Can be used to distinguish between a static layout definition (from layouts.js)
         * and a copy of that definition which we can safely manipulate without changing the
         * state of the original.
         *
         * @param layout Usually the static layout definition you want to clone and manipulate.
         */
        cloneLayout: function (layout) {
          var clonedLayout = {};
          clonedLayout.bn = layout.bn;
          // slice(0) clones an array.
          clonedLayout.countAssocs = layout.countAssocs.slice(0);
          clonedLayout.contentBlocks = layout.contentBlocks.slice(0);
          clonedLayout.sections = layout.sections.slice(0);
          return clonedLayout;
        },
        /**
         * Can be used to re-sort a layout after pushing new sections into the array.
         *
         * @param a Any object with an o.order property, like a layout.
         * @param b Any object with an o.order property, like a layout.
         */
        compareOrdered: function (a, b) {
          if (a.order < b.order)
            return -1;
          if (a.order > b.order)
            return 1;
          return 0;
        }
      }
    }]);