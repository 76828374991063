angular.module('barometerApp.changeStatusNotifier')
// "Presentational" component for changeStatusNotifier (blue bars)
// Simply takes counts of the relevant actions and displays appropriate blue bar states
// All event handling, tracking of indexing transactions and view refreshes are contained in the parent directive/controller
  .directive('changeStatusNotifier', [function () {
    return {
      scope: {
        changesSubmittedCount: '=',
        changesSavedCount: '=',
        proposedEditsCount: '=',
        indexingInProgressCount: '=',
        slowIndexingCount: '=',
        failedIndexingCount: '=',
        closeClicked: '&',
        refreshViews: '&'
      },
      templateUrl: '/b/js/src/bit.ng/change-status-notifier/partials/change-status-notifier.html',
      link: function (scope, element, attrs) {

        scope.show = function () {
          return scope.changesSubmittedCount > 0;
        };

        // Only show the changeSubmitted before the _first_ change is saved.
        // After that, rely on changeSaved messages only.  Simpler.
        scope.showChangeSubmitted = function () {
          return scope.changesSubmittedCount > 0 && scope.changesSavedCount === 0 && scope.proposedEditsCount === 0;
        };

        scope.showChangeSaved = function () {
          return scope.changesSavedCount > 0;
        };

        scope.showProposedEdits = function () {
          return scope.proposedEditsCount > 0;
        };

        scope.showIndexingInProgress = function () {
          return scope.indexingInProgressCount > 0 && scope.failedIndexingCount === 0;
        };

        scope.showViewsUpToDate = function () {
          return scope.indexingInProgressCount === 0 && scope.failedIndexingCount === 0;
        };

        scope.showSlowIndex = function () {
          return scope.slowIndexingCount > 0;
        };

        scope.showFailedIndex = function () {
          return scope.failedIndexingCount > 0;
        };

        // Use the count after the first change
        scope.changeSavedMessage = function () {
          return scope.changesSavedCount > 1 ?
            String.format('{0} changes have been saved', scope.changesSavedCount) : 'Your changes have been saved.';
        };

        // Always use a count
        scope.proposedEditsMessage = function () {
          return String.format('{0} changes have been proposed and are pending review', scope.proposedEditsCount);
        };

        scope.indexingInProgressMessage = function () {
          return scope.indexingInProgressCount > 1 ?
            String.format('Indexing {0} changes', scope.indexingInProgressCount) : 'Indexing';
        };

        scope.slowIndexingMessage = function () {
          return scope.slowIndexingCount > 1 ?
            String.format('{0} indexing jobs are taking longer than normal.', scope.slowIndexingCount) :
            '1 indexing job is taking longer than normal.'
        };

        scope.failedIndexingMessage = function () {
          return scope.failedIndexingCount > 1 ?
            String.format('{0} indexing jobs took longer than 10 minutes.', scope.failedIndexingCount) :
            '1 indexing job took longer than 10 minutes..'
        };

        // This function will call the closeView function that was
        // passed into scope. Then it will call the clearCounts function.
        scope.closeView = function() {
          if (scope.closeClicked !== undefined) {
            scope.closeClicked();
          }
          clearCounts();
        };

        // Clear all the counts.
        var clearCounts = function () {
          scope.changesSubmittedCount = 0;
          scope.changesSavedCount = 0;
          scope.proposedEditsCount = 0;
          scope.indexingInProgressCount = 0;
          scope.slowIndexingCount = 0;
          scope.failedIndexingCount = 0;
        };
      }
    }
  }])

  // Wrapper for association panel change status notifiers
  // Contains all event handling, indexing tracking and view refresh logic
  .directive('assocPanelChangeStatusNotifier', ['lockService', '$rootScope', '$timeout', 'redux', function (lockService, $rootScope, $timeout, redux) {
    return {
      scope: {
        // Match the sectionBn of the appropriate edit panel
        sectionBn: "=",
        // Additional sectionBns to match – helps in cases where there are > 1 section for the same assoc type (Person)
        altSectionBns: "=",
        // Do not display any indexing status information
        ignoreIndexing: "@",
        // Fire off a callback when the status panel is closed.
        onCloseCallback: "="
      },
      templateUrl: '/b/js/src/bit.ng/change-status-notifier/partials/assoc-panel-change-status-notifier.html',
      link: function (scope, element, attrs) {

        scope.changesSubmittedCount = 0;
        scope.changesSavedCount = 0;
        scope.proposedEditsCount = 0;
        scope.indexingInProgressTransactionIds = [];
        scope.slowIndexingTransactionIds = [];
        scope.failedIndexingTransactionIds = [];

        var SLOW_INDEXING_THRESHOLD = 30000; // 30 seconds
        var FAILED_INDEXING_THRESHOLD = 600000; // 10 minutes
        var INDX_TO_SOLR_LAG_DELAY = 1000;  // 1 second, the delay between getting the Zookeeper lock and asking Solr for new data

        // Check if the event applies to this section
        function appliesHere(eventSectionBn) {
          var appliesHere = scope.sectionBn === eventSectionBn;

          // Also check the alt-section-bns for a match
          if (scope.altSectionBns) {
            appliesHere = appliesHere || scope.altSectionBns.indexOf(eventSectionBn) > -1;
          }
          return appliesHere;
        }

        scope.indexingInProgressCount = function () {
          return scope.indexingInProgressTransactionIds.length;
        };

        scope.slowIndexingCount = function () {
          return scope.slowIndexingTransactionIds.length;
        };

        scope.failedIndexingCount = function () {
          return scope.failedIndexingTransactionIds.length;
        };

        // Called by change-status-notifier
        scope.refreshViews = function () {
          $rootScope.$broadcast("loadSection", scope.sectionBn, true);
          $rootScope.$broadcast("refreshCounts", scope.sectionBn);
        };

        // A close function that calls the onCloseCallback
        scope.closeView = function () {
          if (scope.onCloseCallback !== undefined) {
            return scope.onCloseCallback();
          }
        };

        scope.$on('changeSubmitted', function (event, sectionBn) {
          if (appliesHere(sectionBn)) {
            scope.changesSubmittedCount++;
          }
        });

        // Fired when save triggers proposed edits
        // NOTE: not all newProposedEdits events include sectionBns!
        scope.$on('newProposedEdits', function (event, data, sectionBn) {
          if (sectionBn && appliesHere(sectionBn)) {
            // Timeout required for some Wicket invocations
            $timeout(function () {
              scope.proposedEditsCount++;
            });
          }
        });

        // Fired after the save action returns, if there is indexing to do
        scope.$on('changeWithIndexingStarted', function (event, data) {

          // 1. Ignore irrelevant events
          if (!appliesHere(data.sectionBn)) return;

          // 2. If no indexing is happening, immediately refresh views
          if (!data.transactionId) {
            scope.refreshViews();
          }

          // 3. Track indexing progress if relevant...
          // Timeout required for some Wicket invocations
          $timeout(function () {
            scope.changesSavedCount++;

            if (!scope.ignoreIndexing && data.transactionId) {
              var transactionId = data.transactionId;

              scope.indexingInProgressTransactionIds.push(transactionId);

              // Show slow index warning after 1 minute, but keep checking for the transaction to come through
              var warningTimer = $timeout(function () {
                scope.slowIndexingTransactionIds.push(transactionId);
              }, SLOW_INDEXING_THRESHOLD);

              // Start checking for indexing completion, time out after 5 minutes
              lockService.processIndexResponseForTransactionId(FAILED_INDEXING_THRESHOLD, data.transactionId)
                .then(function () {
                    indexingComplete(transactionId, warningTimer);
                  },
                  // We timed out!
                  function (data) {
                    // Remove from the in progress list
                    var i = scope.indexingInProgressTransactionIds.indexOf(transactionId);
                    if (i !== -1) {
                      scope.indexingInProgressTransactionIds.splice(i, 1);
                    }
                    // Add to failed list
                    scope.failedIndexingTransactionIds.push(transactionId);
                  });
            }
          });
        });

        function indexingComplete(transactionId, warningTimer) {

          // Additional wait to account for small lag between Index committing to Solr and Solr being ready with updates
          $timeout(function () {
            // Reload the section you're viewing.
            scope.refreshViews();

            // Remove from the in progress list
            var i = scope.indexingInProgressTransactionIds.indexOf(transactionId);
            if (i !== -1) {
              scope.indexingInProgressTransactionIds.splice(i, 1);
            }

            // Remove from the slow list, if present
            var j = scope.slowIndexingTransactionIds.indexOf(transactionId);
            if (j !== -1) {
              scope.slowIndexingTransactionIds.splice(j, 1);
            }

            // Cancel the warning timer
            $timeout.cancel(warningTimer);
          }, INDX_TO_SOLR_LAG_DELAY);
        }
      }
    }
  }]);
