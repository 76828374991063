angular.module('barometerApp.activityStream')
    .service('activityService', ['utilService', '$rootScope', '$http', 'errorHandlingService',
        function (utilService, $rootScope, $http, errorHandlingService) {
            return {
                getEventStreamData: function (category, first, count) {
                    return $http({
                        method: 'GET',
                        url: '/b/api/events',
                        params: {
                            category: category,
                            first: first || 0,
                            count: count || 50
                        }
                    }).
                        success(function (data, status, headers, config) {
                        }).
                        error(function (data, status, headers, config) {
                            console.log('error during getEventStreamData for category:' + category + ' first:' + first + ' count:' + count);
                            errorHandlingService.handleGenericError(status);
                        });
                },
                getActivities: function (params) {
                    return $http({
                        method: 'GET',
                        url: '/b/api/activities/' + params.tableType,
                        params: {
                            sourceBn: params.sourceBn || null,
                            // tableType: params.tableType,
                            eventDate: utilService.convertToISOUTCDateTime(params.eventDate),
                            dayRange: params.dayRange || 0,
                            pageIndex: params.pageIndex,
                            pageSize: params.pageSize,
                            searchString: params.searchString,
                            roleString: params.roleString,
                            sortProperty: params.sortProperty
                        }


                    }).
                        success(function (data, status, headers, config) {
                        }).
                        error(function (data, status, headers, config) {
                            console.log("Service Error:", status);
                            errorHandlingService.handleGenericError(status);
                        });
                },
                getActivityDetails: function (params) {
                    return $http({
                        method: 'GET',
                        url: '/b/api/activities/' + params.tableType + '/details',
                        params: {
                            sourceBn: params.sourceBn || null,
                            eventDate: utilService.convertToISOUTCDateTime(params.eventDate),
                            // tableType: params.tableType,
                            entityBn: params.entityBn || null
                        }
                    }).
                        success(function (data, status, headers, config) {
                        }).
                        error(function (data, status, headers, config) {
                            console.log("Service Error:", status);
                            errorHandlingService.handleGenericError(status);
                        });
                }
            }
        }]);

// PAJ 3/17/14 Getting a weird syntax error sometimes when concat'ing this file, this comment fixes it!