/**
 * This file tries to follow John Papa's Angular 1 style guide (adjusted to our AngularJS version).
 *
 * https://github.com/johnpapa/angular-styleguide/blob/master/a1/README.md
 */
angular
  .module('barometerApp.taskManager')
  .factory('taskAssignmentDataService', taskAssignmentDataService);

taskAssignmentDataService.$inject = ['$http', '$q', 'alertService', 'urlService', 'utilService'];

function taskAssignmentDataService($http, $q, alertService, urlService, utilService) {
  //
  var GRAPH_URL = urlService.getGraphApiUrl();

  /**
   * perspectiveBns: An array of Task BNs loaded previously.
   */
  var requestModel = {
    "perspectiveBns":[],
    "relatedTypes":["PER"],
    "relatedProperties":["bn","name"],
    "filterBy":{
      "relationshipTypes":["ASSIGNED_BY","ASSIGNED_TO"]
    },
    "expandRelationships":true,
    "firstRecord":1,
    "numberToReturn":-1};
  //
  var mockResponse = {
    "columnSpec": [
      {
        "id": "perspectiveBn",
        "name": "perspectiveBn",
        "sortable": false,
        "rendererId": null,
        "sorted": null,
        "entityType": "TSK",
        "entityIndex": 0,
        "totalCount": 6,
        "display": true
      },
      {
        "id": "perspectiveName",
        "name": "perspectiveName",
        "sortable": false,
        "rendererId": null,
        "sorted": null,
        "entityType": "TSK",
        "entityIndex": 0,
        "totalCount": 6,
        "display": true
      },
      {
        "id": "relationshipType",
        "name": "relationshipType",
        "sortable": false,
        "rendererId": null,
        "sorted": null,
        "entityType": null,
        "entityIndex": -1,
        "totalCount": 0,
        "display": true
      },
      {
        "id": "bn",
        "name": "bn",
        "sortable": true,
        "rendererId": null,
        "sorted": null,
        "entityType": "PER",
        "entityIndex": 1,
        "totalCount": 8,
        "display": true
      },
      {
        "id": "name",
        "name": "name",
        "sortable": true,
        "rendererId": null,
        "sorted": null,
        "entityType": "PER",
        "entityIndex": 1,
        "totalCount": 8,
        "display": true
      }
    ],
    "rows": [
      {
        "perspectiveBn": "044O0000003B",
        "perspectiveName": "Test Task 1",
        "relationshipType": "ASSIGNED_TO",
        "bn": "ZZ0E0000007J",
        "name": "John Appel"
      },
      {
        "perspectiveBn": "044O0000003B",
        "perspectiveName": "Test Task 1",
        "relationshipType": "ASSIGNED_BY",
        "bn": "050E00000048",
        "name": "Person Editor"
      },
      {
        "perspectiveBn": "044O0000003C",
        "perspectiveName": "Test Task 2",
        "relationshipType": "ASSIGNED_TO",
        "bn": "ZZ0E0000007J",
        "name": "John Appel"
      },
      {
        "perspectiveBn": "044O0000003C",
        "perspectiveName": "Test Task 2",
        "relationshipType": "ASSIGNED_BY",
        "bn": "050E00000048",
        "name": "Person Editor"
      },
      {
        "perspectiveBn": "044O0000003D",
        "perspectiveName": "Test Task 3",
        "relationshipType": "ASSIGNED_TO",
        "bn": "ZZ0E0000007J",
        "name": "John Appel"
      },
      {
        "perspectiveBn": "044O0000003D",
        "perspectiveName": "Test Task 3",
        "relationshipType": "ASSIGNED_BY",
        "bn": "050E00000048",
        "name": "Person Editor"
      },
      {
        "perspectiveBn": "044O00000049",
        "perspectiveName": "Test Task Create API 1",
        "relationshipType": "ASSIGNED_TO",
        "bn": "ZZ0E0000007J",
        "name": "John Appel"
      },
      {
        "perspectiveBn": "044O00000049",
        "perspectiveName": "Test Task Create API 1",
        "relationshipType": "ASSIGNED_BY",
        "bn": "ZZ0E00000036",
        "name": "Andrew Baker"
      }
    ],
    "rowCount": 8
  };
  //
  return {
    getAssignments : getAssignments,
    getRequestModel: getRequestModel
  };

  function getAuthToken() {
    var authToken = utilService.readCookie('baroJWTToken');
    if (!authToken) {
      alertService.addErrorAlert('No authentication credential for Task service.');
    }
    return authToken;
  }

  function getRequestModel() {
    return requestModel;
  }

  function getAssignments(myRequestModel) {
    //
    //return mockResponse;
    //
    var deferred = $q.defer();
    var token = getAuthToken();
    if (!token) {
      return deferred.reject('Authorization token missing');
    }
    $http({
      method: 'POST',
      url: GRAPH_URL + 'report/relatedEntities',
      headers: {
        'Authorization': 'Bearer ' + token,
        'bit-tenant-bn': utilService.getTenantBn(),
        'Content-Type': 'application/json'
      },
      data: myRequestModel
    }).success(function (data) {
      postProcessData(data);
      deferred.resolve(data);
    }).error(function (data) {
      console.error('Error getting neo4j path');
      deferred.reject(data);
    })
    return deferred.promise;
  }

  function postProcessData(data) {
    //
  }
}