angular.module('barometerApp.dataImport')
  .service('csvImportService', ['$http', 'alertService',
    function ($http, alertService) {
      return {
        importCsvData: function (inputData, entity) {
          return $http({
            method: 'POST',
            url: '/b/api/csvdataimport/' + entity,
            data: inputData,
            headers: {'Content-Type': 'text/plain'}
          }).success(function (data) {
          }).error(function (data) {
            var msgString = String.format("Unable to import data: {0}", data);
            alertService.addErrorAlert(msgString);
          });
        }
      }
    }])
  .service('csvAssocImportService', ['$http', 'alertService',
    function ($http, alertService) {
      return {
        importCsvData: function (assocData) {
          return $http({
            method: 'POST',
            url: '/b/api/csvassocimport/',
            params: {
              assocData: assocData,
              csvAction: "ADD"
            },
            headers: {'Content-Type': 'text/plain'}
          }).success(function (data) {
          }).error(function (data) {
            var msgString = String.format("Unable to import data: {0}", data);
            alertService.addErrorAlert(msgString);
          });
        }
      }
    }])
  .service('csvAssocRemoveService', ['$http', 'alertService',
    function ($http, alertService) {
      return {
        removeCsvData: function (assocData) {
          return $http({
            method: 'POST',
            url: '/b/api/csvassocimport/',
            params: {
              assocData: assocData,
              csvAction: "REMOVE"
            },
            headers: {'Content-Type': 'text/plain'}
          }).success(function (data) {
          }).error(function (data) {
            var msgString = String.format("Unable to import data: {0}", data);
            alertService.addErrorAlert(msgString);
          });
        }
      }
    }])
  .service('csvHelpService', function () {
    var entityTypeCode = '';

    return {
      getEntityTypeCodeFromImport: function () {
        return entityTypeCode;
      },
      setEntityTypeCodeFromImport: function (value) {
        entityTypeCode = value;
      }
    }
  })
  .service('csvUtilsService', ['priorityEntitiesService', 'entityService', 'securityService',
    function (priorityEntitiesService, entityService, securityService) {
      return {
        setGroupName: function (entityType) {
          if (priorityEntitiesService.getUsePersonalPriorityEntitiesFlag()) {
            return (priorityEntitiesService.isPersonalPriorityEntityType(entityType)) ? 'Priority Catalogs' : 'All Entities';
          } else {
            return (priorityEntitiesService.isTenantPriorityEntityType(entityType)) ? 'Priority Catalogs' : 'All Entities';
          }
        },
        getEntityChoices: async function () {
          let entityResponse = await entityService.getEntityCatalogs();
          // BARO-21971: Don't allow Topic and Endpoint in this list.
          const unsupportedList = {'AST': 1, 'TAG': 1, 'TEP': 1, 'TOP': 1};
          let allowableEntityList = entityResponse.data.filter(catalog => (!unsupportedList[catalog.entityType.code]));
          let entityChoices = [];
          for (let i = 0; i < allowableEntityList.length; i++) {
            let allowedEntity = allowableEntityList[i];
            // for each available catalog make sure the user is an editor
            if (securityService.hasEntityPermission(allowedEntity.entityType.code)) {
              let groupName = this.setGroupName(allowedEntity.entityType.code);
              let id = (groupName == 'Priority Catalogs') ? (0 + allowedEntity.name) : (1 + allowedEntity.name);
              // add available catalogs that we're an editor for to the drop down
              entityChoices.push({
                value: allowedEntity.entityType.code,
                displayName: allowedEntity.name,
                groupName: groupName,
                groupId: id
              });
            }
          }
          return entityChoices;
        }
      }
    }]);
