angular
  .module('barometerApp.tasks')
  .controller('TasksProgressStatsController', TasksProgressStatsController);

TasksProgressStatsController.$inject = ['$scope', '$rootScope', 'tasksService', '$timeout'];

/**
 * @author Tim Cramer
 */
function TasksProgressStatsController($scope, $rootScope, tasksService, $timeout) {

  $scope.helpTooltip = "Team tasks are tasks assigned to a person in the same organization as you. Enterprise tasks are tasks assigned to anyone in the same organizational tree as you.";

  $scope.progressMsgs = {
    green: "&lt; 7 days old",
    orange: "7 to 30 days old",
    red: "&gt; 30 days old"
  };

  function reset() {
    $scope.personalChartModel = {count: -1};
    $scope.teamChartModel = {count: -1};
    $scope.enterpriseChartModel = {count: -1};
  }

  var emptyChartConfig = [{
    color: "#ddd",
    name: "No Tasks Found",
    y: 1, //set y to 1 so it will display the chart
    count: 0 //set count to 0 so it won't display a count.
  }];

  $scope.load = function(){
    $scope.bns = [];
    tasksService.getTaskProgressStats({
      includePersonal: true,
      includeRollup: false
    }).then(function (data) {
      updatePersonalStats(data.data.person);
    });

    updateTeamStats();

    updateEnterpriseStats();
    $rootScope.$broadcast('taskCountRefreshed');
  };

  function updatePersonalStats(personData) {
    $scope.personalChartModel = personData;
    if($scope.personalChartModel.count == 0) {
      $scope.personalChartModel.title = "No personal tasks found.";
      $scope.personalChartModel.data = emptyChartConfig;
    } else {
      $scope.personalChartModel.title = "My Tasks";
      for(var i=0; i < $scope.personalChartModel.data.length; i++) {
        $scope.personalChartModel.data[i].count = $scope.personalChartModel.data[i].y;
        for(var j=0; j < $scope.personalChartModel.data[i].entities.length; j++) {
          $scope.bns.push($scope.personalChartModel.data[i].entities[j].bn);
        }
      }
    }
  }

  function updateTeamStats() {
    tasksService.getTaskProgressStats({
      includeTeam: true,
      includeRollup: false
    }).then(function (data) {
      $scope.teamChartModel = data.data.team;
      if($scope.teamChartModel.count == 0) {
        $scope.teamChartModel.title = "No team tasks found.";
        $scope.teamChartModel.data = emptyChartConfig;
      } else {
        $scope.teamChartModel.title = "My Team";
        for (var i = 0; i < $scope.teamChartModel.data.length; i++) {
          $scope.teamChartModel.data[i].count = $scope.teamChartModel.data[i].y;
          for(var j=0; j < $scope.teamChartModel.data[i].entities.length; j++) {
            $scope.bns.push($scope.teamChartModel.data[i].entities[j].bn);
          }
        }
      }
    });
  }

  function updateEnterpriseStats() {
    tasksService.getTaskProgressStats({
      includeEnterprise: true,
      includeRollup: true
    }).then(function (data) {
      $scope.enterpriseChartModel = data.data.enterprise;
      if($scope.enterpriseChartModel.count == 0) {
        $scope.enterpriseChartModel.title = "No enterprise tasks found.";
        $scope.enterpriseChartModel.data = emptyChartConfig;
      } else {
        $scope.enterpriseChartModel.title = "My Enterprise";
        for (var i = 0; i < $scope.enterpriseChartModel.data.length; i++) {
          $scope.enterpriseChartModel.data[i].count = $scope.enterpriseChartModel.data[i].y;
          for(var j=0; j < $scope.enterpriseChartModel.data[i].entities.length; j++) {
            $scope.bns.push($scope.enterpriseChartModel.data[i].entities[j].bn);
          }
        }
      }
    });
  }

  $rootScope.$on('taskCompletedInGraph', function (event, bn) {
    if(_.contains($scope.bns, bn)) {
      $scope.load();
    }
  });

  $rootScope.$on('taskCreatedInGraph', function (event, bn) {
    if(!_.contains($scope.bns, bn)) {
      $scope.load();
    }
  });

  reset();
  $scope.load();
}