angular.module('barometerApp.inputWithDatepicker')
  .directive('inputWithDatepicker', ['$timeout' ,
    function ($timeout) {
      return {
        scope: {
          dateModel: '=',
          dateType: '=',
          dateFormat: '='
        },
        templateUrl: "/b/js/src/bit.ng/input-with-datepicker/partials/input-with-datepicker.html",
        link: function (scope, element, attrs) {
          var open = false;
          var button = $(element).find('.btn');
          scope.buttonClicked = function(){
            if (!open){
              button.datepicker('show');
              button.datepicker('update', scope.dateModel);
              open = true;
            } else {
              button.datepicker('hide');
              open = false;
            }
          };
          button.datepicker({ format: scope.dateFormat }).on('changeDate', function(event){
            button.datepicker('hide');
            open = false;
            $timeout(function(){
              console.log('changeDate', event.format(0, scope.dateFormat));
              scope.dateModel = event.format(0, scope.dateFormat);
            });
          })
        }
      }
    }]);
