angular.module('barometerApp.fieldset')
  .controller('FieldsetsOnEntityCtrl', ['$scope', 'fieldsetService', 'urlService',
    function ($scope, fieldsetService, urlService) {
      $scope.fieldSetLoaded = false;
      $scope.fieldsetsOnEntityModel = {};

      $scope.loadFieldsets = function (locationBn) {
        var entityBn = urlService.getEntityBn();
        var fieldsetPromise = fieldsetService.getFieldsetBnsForLocation(locationBn, entityBn);
        fieldsetPromise.then(function (results) {
          //
          let fieldsetsMap = results.data;
          $scope.fieldsetsOnEntityModel.fieldsets = [];

          // Break apart the map and add it to a list.
          Object.keys(fieldsetsMap).forEach((key) => {
            // Set the orderVal for this fieldset.
            fieldsetsMap[key].orderVal = parseInt(key, 10);
            // Add the fieldset to the list of fieldsets.
            $scope.fieldsetsOnEntityModel.fieldsets.push(fieldsetsMap[key]);
          });
        });
      };

      $scope.fieldsetsOnEntityModel.locationBn = $scope.contentBlock.configuration.locationId;
      $scope.$on('loadSection', function (event, sectionBn) {
        if (sectionBn == $scope.sectionBn) {
          if (!$scope.fieldSetLoaded) {
            $scope.loadFieldsets($scope.fieldsetsOnEntityModel.locationBn);
            $scope.fieldSetLoaded = true;
          }
        }
      });
    }])

  .controller('FieldsetCtrl', ['$rootScope', '$scope', 'fieldsetService', 'urlService', 'entityService', 'recentLocationsService', 'utilService',
    function ($rootScope, $scope, fieldsetService, urlService, entityService, recentLocationsService, utilService) {
      $scope.fieldsetModel = {};
      $scope.fieldsetModel.dateFormat = utilService.getDateFormatForBootstrapDatepicker();
      $scope.selectOptions = {
        allowClear: true
      };
      $scope.fieldsetModel.hasEditableField = false;

      // make call to load recents data.
      recentLocationsService.getRecentsFromServerAndNotify();

      $scope.toggleExpanded = function () {
        $scope.fieldsetModel.expanded = !$scope.fieldsetModel.expanded;
        _.forEach($scope.fieldsetModel.data.fields, function (field) {
          field.expanded = $scope.fieldsetModel.expanded;
        });
      };

      $scope.loadFieldset = function (fieldsetBn, entityBn, isRead) {
        $scope.fieldsetModel.loading = true;

        fieldsetService.getFieldsetForFieldsetBnAndEntityBn(fieldsetBn, entityBn, isRead).then(function (results) {
          $scope.fieldsetModel.loading = false;
          $scope.fieldsetModel.data = results.data;

          if (!isRead) {
            $scope.fieldsetModel.editing = true;
          } else {
            // Hotfix for BARO-22460 - TODO: Remove - field directives should be able to display correct values
            // with their correct displayType & dataType
            $scope.fieldsetModel.data.fields = $scope.fieldsetModel.data.fields.map(f => ({
              ...f,
              dataType: 'TEXT',
              displayType: f.displayType === 'URL_LINK' ? 'URL_LINK' : 'TEXT_BOX',
            }));
          }

          // set initial expand/collapse state from data
          if (angular.isUndefined($scope.fieldsetModel.expanded)) {
            $scope.fieldsetModel.expanded = $scope.fieldsetModel.data.expanded;
          }

          //convert date/time string into js Date object
          _.forEach($scope.fieldsetModel.data.fields, function (field) {
            field.expanded = $scope.fieldsetModel.expanded;

            if (!$.isArray(field.values)) {
              field.values = [];
            }

            //check to see if the fieldset should be editable.
            if (!$scope.fieldsetModel.hasEditableField && utilService.isControlLevelEditable(field)) {
              $scope.fieldsetModel.hasEditableField = true;
            }

            if ($scope.fieldsetModel.editing && !$scope.isEditable(field) && $scope.isVisible(field)) {
              if (_.isEmpty(field.helpText)) {
                field.helpText = "You do not have permission to edit this custom field.";
              } else {
                field.helpText += " (You do not have permission to edit this custom field.)";
              }
            }
          });
        });
      };

      $scope.cancel = function () {
        $scope.loadFieldset($scope.fieldsetModel.fieldsetBn.bn, $scope.fieldsetModel.entityBn, true);
        $scope.fieldsetModel.editing = false;
      };

      $scope.edit = function () {
        if (!$scope.fieldsetModel.editing &&
          !$scope.fieldsetModel.loading &&
          !$scope.fieldsetModel.saving
        ) {
          $scope.loadFieldset($scope.fieldsetModel.fieldsetBn.bn, $scope.fieldsetModel.entityBn, false);
        }
      };

      $scope.getEditButtonText = function() {
        if ($scope.fieldsetModel.loading) return 'Loading...';
        if ($scope.fieldsetModel.saving) return 'Saving...';
        return 'Edit';
      };

      $scope.getDisabledTooltip = function() {
        if ($scope.fieldsetModel.loading) return 'You can edit data after loading completes.';
        if ($scope.fieldsetModel.saving) return 'You can edit data after saving completes.';
      };

      $scope.showEditIcon = function () {
        return !$scope.fieldsetModel.editing && $scope.fieldsetModel.hasEditableField;
      };

      $scope.isVisible = function (field) {
        return utilService.isControlLevelVisible(field);
      };

      $scope.isEditable = function (field) {
        return utilService.isControlLevelEditable(field);
      };

      $scope.isListDrivenField = function (field) {
        return _.contains(['CHECKBOX', 'SINGLE_SELECT', 'MULTI_SELECT'], field.displayType);
      };

      $scope.getSaveButtonText = function () {
        if ($scope.fieldsetModel.loading) return 'Loading...';
        if ($scope.fieldsetModel.saving) return 'Saving...';
        return 'Save';
      };

      $scope.submit = function () {
        $scope.fieldsetModel.editing = false;
        $scope.fieldsetModel.saving = true;

        //prepare json for server call
        var updateFieldsJson = {
          fieldsetBn: $scope.fieldsetModel.fieldsetBn.bn,
          entityBn: $scope.fieldsetModel.entityBn,
          fields: []
        };

        _.forEach($scope.fieldsetModel.data.fields, function (field) {
          //send down either a fieldOptionBn OR a value
          if (utilService.isControlLevelEditable(field)) {
            if ($scope.isListDrivenField(field)) {
              var values = [];
              _.forEach(field.values, function (optionBn) {
                values.push({fieldOptionBn: optionBn})
              });
              updateFieldsJson.fields.push({fieldBn: field.bn, values: values});
            } else {
              updateFieldsJson.fields.push({fieldBn: field.bn, values: [{value: field.values[0]}]});
            }
          }
        });

        fieldsetService.updateFieldsForFieldset($scope.fieldsetModel.fieldsetBn.bn, updateFieldsJson).then(function (results) {
          $scope.fieldsetModel.saving = false;
          if (results.data[0] && results.data[0].contributedByBn) {
            $rootScope.$broadcast("successAlertMessage", "Your changes have been submitted for review.");
            $rootScope.$broadcast('newProposedEdits');
          } else {
            $rootScope.$broadcast("successAlertMessage", "Your changes have been saved.");
          }

          $scope.loadFieldset($scope.fieldsetModel.fieldsetBn.bn, $scope.fieldsetModel.entityBn, true);
        });
      };

      $scope.fieldsetModel.editing = false;
      $scope.fieldsetModel.fieldsetBn = $scope.fieldsetBn;
      $scope.fieldsetModel.entityBn = urlService.getEntityBn();
      $scope.loadFieldset($scope.fieldsetModel.fieldsetBn.bn, $scope.fieldsetModel.entityBn, true);
    }])

  .controller('FieldsetDefinitionCtrl', ['$scope', 'fieldsetService', 'fieldService', 'urlService', '$location', '$window',
    'alertService', 'pageService', 'recentLocationsService', 'utilService',
    function ($scope, fieldsetService, fieldService, urlService, $location, $window, alertService, pageService, recentLocationsService, utilService) {
      $scope.fieldsetDefinitionModel = {
        fieldset: {
          fields: [],
          expanded: false
        },
        createMode: true
      };
      pageService.setIsEntityPage(true);
      $scope.submitDisabled = false;
      $scope.sortableOptions = {
        handle: '.handle',
        forcePlaceholderSize: true,
        placeholder: 'ui-state-highlight',
        stop: function (event, ui) {
          //re-order the sort values
          $scope.$apply(function () {
            for (var i = 0; i < $scope.fieldsetDefinitionModel.fieldset.fields.length; i++) {
              var field = $scope.fieldsetDefinitionModel.fieldset.fields[i];
              field.sortVal = i;
            }
          });
        }
      };

      // make call to load recents data.
      recentLocationsService.getRecentsFromServerAndNotify();

      $scope.getFieldsetEntityTypeCode = function() {
        return utilService.getTypeCodeFromBnCode('0L');
      }

      $scope.addField = function (newField, selectedFields) {
        if (newField && newField.name) {
          newField.sortVal = selectedFields.length;
          var exists = _.find(selectedFields, function (field) {
            return field.bn === newField.bn;
          });
          if (!exists) {
            selectedFields.push(newField);
          }
          //reset selected
          $scope.fieldsetDefinitionModel.selectedField = null;
        }
      };

      $scope.isFieldAddBtnDisabled = function () {
        return !$scope.fieldsetDefinitionModel.selectedField || $scope.fieldsetDefinitionModel.selectedField.name === undefined;
      };

      $scope.loadFieldsetDefinition = function (fieldsetBn) {
        fieldsetService.getFieldset(fieldsetBn).then(function (results) {
          $scope.fieldsetDefinitionModel.fieldset = results.data;
        });
      };

      $scope.cancel = function () {
        $scope.redirectToBrowse();
      };

      $scope.deleteFieldset = function () {
        if ($scope.fieldsetDefinitionModel.fieldset.bn) {
          var promise = fieldsetService.deleteFieldset($scope.fieldsetDefinitionModel.fieldset.bn);
          promise.then(function (results) {
            alertService.addDeferredAlert({
              type: 'success',
              msg: String.format("Deleted Fieldset {0}", $scope.fieldsetDefinitionModel.fieldset.name)
            });
            $scope.redirectToBrowse();
          });

        }
      };

      $scope.removeField = function (field, index) {
        if (field && index > -1) {
          $scope.fieldsetDefinitionModel.fieldset.fields.splice(index, 1);
          $scope.fieldsetDefinitionModel.selectedField = null;
        }
      };

      $scope.saveFieldset = function () {
        $scope.submitDisabled = true;
        if ($scope.fieldsetDefinitionModel.createMode) {
          $scope.createFieldset();
        } else {
          //update fieldset
          var updateJson = $scope.fieldsetDefinitionModel.fieldset;
          var slimFields = [];
          _.forEach(updateJson.fields, function (value, index) {
            slimFields.push({bn: value.bn, sortVal: index});
          });
          //replace full fields array with slimmed down array
          updateJson.fields = slimFields;
          var promise = fieldsetService.updateFieldsetDefinition($scope.fieldsetDefinitionModel.fieldset.bn, $scope.fieldsetDefinitionModel.fieldset);
          promise.then(function (results) {
            $scope.fieldsetDefinitionModel.fieldset = results.data;
            alertService.addDeferredAlert({
              type: 'success',
              msg: String.format("Updated Fieldset: {0}", $scope.fieldsetDefinitionModel.fieldset.name)
            });
            $scope.redirectToBrowse();
          });
        }
      };

      $scope.createFieldset = function () {
        var promise = fieldsetService.createFieldset($scope.buildJson());
        promise.then(function (results) {
          var bn = results.data.bn;
          if (bn) {
            if ($scope.fieldsetDefinitionModel.createMode) {
              $scope.fieldsetDefinitionModel.fieldset.bn = bn;
              $location.search({bn: bn});
            }
            $scope.fieldsetDefinitionModel.createMode = false;
            alertService.addDeferredAlert({
              type: 'success',
              msg: String.format("Created Fieldset: {0}", $scope.fieldsetDefinitionModel.fieldset.name)
            });
            $scope.redirectToBrowse();
          }
        });
      };

      $scope.redirectToBrowse = function () {
        $window.location.href = urlService.getBaseUrl() + 'fieldsets';
      };

      $scope.buildJson = function () {
        var dataToServer = angular.copy($scope.fieldsetDefinitionModel.fieldset);
        var fieldBns = _.pluck(dataToServer.fields, "bn");
        //replace fields array with just an array of field bn's
        dataToServer.fields = fieldBns;
        return dataToServer;
      };

      $scope.toggleExpanded = function () {
        if ($scope.fieldsetDefinitionModel.fieldset.expanded) {
          $scope.fieldsetDefinitionModel.fieldset.expanded = false;
        } else {
          $scope.fieldsetDefinitionModel.fieldset.expanded = true;
        }
      };

      var fieldsetBn = urlService.getEntityBn();

      //if no bn, we are in create mode
      if (fieldsetBn) {
        $scope.fieldsetDefinitionModel.createMode = false;
        $scope.loadFieldsetDefinition(fieldsetBn);
      }
    }])

  .controller('FieldsetAdminLayoutCtrl', ['$scope', 'fieldsetService', 'bitConstants', 'layoutService', 'fieldsetConstants', '$modal', 'recentLocationsService', 'tenantService', 'utilService',
    // forcing synchronous behavior on the re-order and remove fieldset behavior via the sortableOptions.disabled property here
    // to prevent odd situations (hard to reproduce locallY) where multiple re-orders/removes were causing the wrong fieldset to get removed.
    function ($scope, fieldsetService, bitConstants, layoutService, fieldsetConstants, $modal, recentLocationsService, tenantService, utilService) {
      $scope.adminModel = {
        fieldsetsByLocation: [],
        sections: [],
        selectedEntityType: {"typeCode": '', "displayName": ''},
        fieldsetChooserParamString: {},
        adminModal: null,
        adminModalOpen: false,
        entityTypes: []
      };
      $scope.configModel = {
        selectOptions: {
          allowClear: false
        },
        sortableOptions: {
          handle: '.handle',
          stop: function (event, ui) {
            //re-order the sort values
            $scope.$apply(function () {
              var locationBn = ui.item.attr('name');
              $scope.reorderFieldsets(locationBn);
            });

          }
        }
      };

      function setEntityTypes() {
        const allowedCatalogs = tenantService.getCurrentTenantsAuthorizedCatalogs();
        const entityTypes = allowedCatalogs.map(e => e.entityTypeCode);
        var fieldsetEntityTypes = bitConstants.getFieldsetEntityTypes().filter(e => _.contains(entityTypes, e.typeCode));
        $scope.adminModel.entityTypes = fieldsetEntityTypes;
      }

      $scope.addFieldsetToSection = function (locationBn) {
        if (locationBn && !$scope.adminModel.adminModalOpen) {
          $scope.adminModel.fieldsetChooserParamString = '?entityType=' + $scope.adminModel.selectedEntityType.typeCode + '&locationBn=' + locationBn;
          $scope.adminModel.adminModal = $modal.open({
            backdrop: 'static',
            keyboard: true,
            templateUrl: '/b/js/src/bit.ng/fieldset/partials/fieldset-chooser.html',
            scope: $scope
          });
          $scope.adminModel.adminModalOpen = true;
        }
      };

      $scope.updateFieldsetVisibility = function (locationBn, fieldsetBn, orderVal) {
        if (locationBn && fieldsetBn) {
          $scope.adminModel.fieldsetChooserParamString = '?entityType=' + $scope.adminModel.selectedEntityType.typeCode +
            '&locationBn=' + locationBn + '&fieldsetBn=' + fieldsetBn + '&orderVal=' + orderVal;
          $scope.adminModel.adminModal = $modal.open({
            backdrop: 'static',
            keyboard: true,
            templateUrl: '/b/js/src/bit.ng/fieldset/partials/fieldset-chooser.html',
            scope: $scope
          })
        }
      };

      $scope.loadFieldsetsForEntityType = function (entityType) {
        var promise = fieldsetService.getFieldsetsForEntityType(entityType.typeCode);
        var locationBns = [];
        promise.then(function (results) {
          //build array by locationBn
          $scope.adminModel.fieldsetsByLocation = [];
          _.forEach(results.data, function (result) {
            if (!$scope.adminModel.fieldsetsByLocation[result.locationBn]) {
              $scope.adminModel.fieldsetsByLocation[result.locationBn] = [];
            }
            $scope.adminModel.fieldsetsByLocation[result.locationBn].push(result);
            locationBns.push(result.locationBn);
          });
          //sort the results by orderVal
          locationBns = _.uniq(locationBns);  //reduce to only unique
          var sortedArray;
          _.forEach(locationBns, function (locBn) {
            var innerArr = $scope.adminModel.fieldsetsByLocation[locBn];
            sortedArray = _.sortBy(innerArr, 'orderVal');
            $scope.adminModel.fieldsetsByLocation[locBn] = sortedArray;

          });
          $scope.configModel.sortableOptions.disabled = false;
        });
      };

      $scope.removeFieldsetFromSection = function (fieldsetBn, locationBn, index) {
        $scope.adminModel.fieldsetsByLocation[locationBn].splice(index, 1);
        $scope.reorderFieldsets(locationBn);
      };

      $scope.modalOptions = function () {
        return bitConstants.getModalOptions();
      };

      $scope.closeFieldsetChooserModal = function () {
        $scope.adminModel.adminModal.dismiss('cancel');
        $scope.adminModel.adminModalOpen = false;
        //re-load fieldsets in case we added one
        $scope.loadFieldsetsForEntityType($scope.adminModel.selectedEntityType);
      };

      $scope.entityTypeChanged = function (selectedEntityType) {
        if (selectedEntityType) {
          //load static sections
          $scope.adminModel.selectedEntityType = selectedEntityType;
          $scope.adminModel.sections = fieldsetConstants.getFieldsetLayoutSectionsForEntityType($scope.adminModel.selectedEntityType.typeCode);
          $scope.loadFieldsetsForEntityType($scope.adminModel.selectedEntityType);
        }
      };

      $scope.reorderFieldsets = function (locationBn) {
        if (locationBn) {
          //disable sorting until request complete
          $scope.configModel.sortableOptions.disabled = true;
          var updateSortRequest = {locationBn: locationBn, fieldsets: []};
          var changedFieldsets = $scope.adminModel.fieldsetsByLocation[locationBn];
          for (var i = 0; i < changedFieldsets.length; i++) {
            var fieldset = changedFieldsets[i];
            updateSortRequest.fieldsets.push({fieldsetBn: fieldset.bn, orderVal: i});
            fieldset.orderVal = i;
          }
          fieldsetService.updateFieldsetOrderForLocation(updateSortRequest, function (response) {
            //error callback, reload fieldset locations for this entity type
            $scope.loadFieldsetsForEntityType($scope.adminModel.selectedEntityType);
          }).then(function (results) {
            $scope.configModel.sortableOptions.disabled = false;
          });
        }
      };
      setEntityTypes();
      recentLocationsService.getRecentsFromServerAndNotify();
    }])
  .controller('FieldsetBrowseCtrl', ['$scope', '$window', 'urlService', 'recentLocationsService',
    function ($scope, $window, urlService, recentLocationsService) {
      $scope.browseModel = {
        assocCode: "FST",
        tableId: "fieldset_table",
        tableOptions: {
          displayAdd: false,
          displayFilters: true,
          displayPivot: false
        },
        paginationData: {pageSize: 25, maxSize: 0, noOfPages: 0}
      };

      $scope.createFieldset = function () {
        $window.location.href = urlService.getBaseUrl() + 'fieldsetedit/';
      };

      recentLocationsService.getRecentsFromServerAndNotify();
    }]);

