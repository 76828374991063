angular
  .module('barometerApp.tasks')
  .directive('openTasksIndicator', openTasksIndicator);

openTasksIndicator.$inject = ['utilService', 'tasksService'];

/**
 * @author Tim Cramer
 */
function openTasksIndicator(utilService, tasksService) {

  var directive = {
    //
    template: '<span class="open-tasks-indicator" ng-if="!loading && openTaskCount > 0">{{openTaskCount}}</span>',
    restrict: 'E',
    link: function (scope, element, attrs) {
      scope.loading = true;
      function getCount() {
        tasksService.getOpenTasksAssignedToPersonBn(utilService.getCurrentUserBn(), 0, 0).then(function(data){
          scope.openTaskCount = data.totalTasks;
          scope.loading = false;
        });
      }
      scope.$on('taskCountRefreshed', function() {
        scope.loading = true;
        getCount();
      });
      getCount();
    }
  }
  //
  return directive;
}