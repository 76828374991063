angular
  .module('barometerApp.metricAggregation')
  .factory('metricAggregationService', metricAggregationService);

metricAggregationService.$inject = [
  '$q',
  '$http',
  'entityService',
  'graphService',
  'layoutService',
  'searchService'
];

/**
 * Mostly if not all about GRAPH data from Neo4j.
 */
function metricAggregationService($q,
                                  $http,
                                  entityService,
                                  graphService,
                                  layoutService,
                                  searchService) {

  const GROUP_BY_OPTIONS = {
    CAP_DOMAIN: "CAPABILITY_DOMAIN",
    CAP_LIFECYCLE: "CAPABILITY_LIFECYCLE",
    //CAP_ENTERPRISE: "enterpriseApprovalBn",
    CAP_TYPE: "CAPABILITY_TYPE",
    //CAP_CONTENTSTATUS: "contentStatusBn",
    ///
    //CAP_PRIORITY: "priority",
    //CAP_CLASSIFIER: "classifier",
    //CAP_PLANLEVEL: "drPlanLevel",
    //////////
    DEM_DOMAIN: "DEMAND_DOMAIN",
    DEM_LIFECYCLE: "DEMAND_LIFECYCLE",
    //DEM_ENTERPRISE: "enterpriseApprovalBn",
    DEM_TYPE: "DEMAND_TYPE",
    //DEM_CONTENTSTATUS: "contentStatusBn",
    ///
    //DEM_PRIORITY: "priority",
    //DEM_CLASSIFIER: "classifier",
    //DEM_PLANLEVEL: "drPlanLevel",
    //////////
    SYS_DOMAIN: "SYSTEM_DOMAIN",
    SYS_LIFECYCLE: "SYSTEM_LIFECYCLE",
    //SYS_ENTERPRISE: "enterpriseApprovalBn",
    SYS_TYPE: "SYSTEM_TYPE",
    //SYS_CONTENTSTATUS: "contentStatusBn",
    ///
    //SYS_PRIORITY: "priority",
    //SYS_CLASSIFIER: "classifier",
    //SYS_PLANLEVEL: "drPlanLevel"
    TEC_DOMAIN: "TECHNOLOGY_DOMAIN",
    ORG_DOMAIN: "ORGANIZATION_DOMAIN"
  };

  const PERSPECTIVE_TYPES = {
    ORG: 'Organization',
    CAP: 'Capability',
    DEM: 'Demand',
    SYS: 'System'
  };

  const METRIC_TYPES = {
    SUM: "SUM",
    AVERAGE: "AVERAGE",
    MIN: "MIN",
    MAX: "MAX"
  };

  const METRIC_SOURCE_TYPES = {
    SCORECARD: 'Scorecard',
    CUSTOM_FIELD: 'Custom'
  };

  //--------------------------------------------
  // PRIVATE FUNCTIONS
  //--------------------------------------------

  function getKeysForObject(object) {
    var array = [];
    for (var key in object) {
      if (object.hasOwnProperty(key)) {
        array.push(key);
      }
    }

    return array;
  }

  function getOrgPathwayTypeOptions() {
    return {
      ORG_CAP_SYS: {
        name: "Systems related to Capabilities owned by the Organization",
        firstStep: "CAP",
        lastStep: "SYS",
        headerInfo: {
          2: {name: "Capabilities", subName: "Owned By Organization"},
          3: {name: "Systems", subName: "Related To Capabilities"}
        },
        pathway: [{targetEntityType: "CAP", qualifierType: "OWNS"},
          {targetEntityType: "SYS", qualifierType: "RELATED_TO"}]
      },
      ORG_SYS_TEC: {
        name: "Technologies used by Systems owned by the Organization",
        firstStep: "SYS",
        lastStep: "TEC",
        headerInfo: {
          2: {name: "Systems", subName: "Owned By Organization"},
          3: {name: "Technologies", subName: "Related To Systems"}
        },
        pathway: [{targetEntityType: "SYS", qualifierType: "OWNS"},
          {targetEntityType: "TEC", qualifierType: "RELATED_TO"}]
      },
      ORG_SYS_TEC_2: {
        name: "Technologies used by Systems supported by the Organization",
        firstStep: "SYS",
        lastStep: "TEC",
        headerInfo: {
          2: {name: "Systems", subName: "Supported By Organization"},
          3: {name: "Technologies", subName: "Related To Systems"}
        },
        pathway: [{targetEntityType: "SYS", qualifierType: "SUPPORTS"},
          {targetEntityType: "TEC", qualifierType: "RELATED_TO"}]
      },
      ORG_SYS_ORG: {
        name: "Organizations supporting Systems owned by the Organization",
        firstStep: "SYS",
        lastStep: "ORG",
        headerInfo: {
          2: {name: "Systems", subName: "Owned By Organization"},
          3: {name: "Organization", subName: "Supporting Systems"}
        },
        pathway: [{targetEntityType: "SYS", qualifierType: "OWNS"},
          {targetEntityType: "ORG", qualifierType: "SUPPORTS"}]
      },
      ORG_TEC_ORG: {
        name: "Organizations supporting Technologies owned by the Organization",
        firstStep: "TEC",
        lastStep: "ORG",
        headerInfo: {
          2: {name: "Technologies", subName: "Owned By Organization"},
          3: {name: "Organizations", subName: "Supporting Technologies"}
        },
        pathway: [{targetEntityType: "TEC", qualifierType: "OWNS"},
          {targetEntityType: "ORG", qualifierType: "SUPPORTS"}]
      },
      ORG_ORG_SYS: {
        name: "Systems owned by Organizations descendant from the Organization",
        firstStep: "ORG",
        lastStep: "SYS",
        headerInfo: {
          2: {name: "Organizations", subName: "Child Of Organization"},
          3: {name: "Systems", subName: "Owned By Organization"}
        },//TODO populate and localize
        pathway: [{targetEntityType: "ORG", qualifierType: "HAS_CHILD", "relationDirection": "LEFT_TO_RIGHT"},
          {targetEntityType: "SYS", qualifierType: "OWNS"}]
      },
      ORG_ORG_SYS_2: {
        name: "Systems used by Organizations descendant from the Organization",
        firstStep: "ORG",
        lastStep: "SYS",
        headerInfo: {
          2: {name: "Organizations", subName: "Child Of Organization"},
          3: {name: "Systems", subName: "Used By Organization"}
        },//TODO populate and localize
        pathway: [{targetEntityType: "ORG", qualifierType: "HAS_CHILD", "relationDirection": "LEFT_TO_RIGHT"},
          {targetEntityType: "SYS", qualifierType: "USES"}]
      },
      ORG_ORG_SYS_3: {
        name: "Systems supported by Organizations descendant from the Organization",
        firstStep: "ORG",
        lastStep: "SYS",
        headerInfo: {
          2: {name: "Organizations", subName: "Child Of Organization"},
          3: {name: "Systems", subName: "Supported By Organization"}
        },//TODO populate and localize
        pathway: [{targetEntityType: "ORG", qualifierType: "HAS_CHILD", "relationDirection": "LEFT_TO_RIGHT"},
          {targetEntityType: "SYS", qualifierType: "SUPPORTS"}]
      },
      ORG_SYS_PRD: {
        name: "Products related to Systems related to the Organization",
        firstStep: "ORG",
        lastStep: "PRD",
        headerInfo: {
          2: {name: "Systems", subName: "Related To Organizations"},
          3: {name: "Products", subName: "Related To Systems"}
        },
        pathway: [{targetEntityType: "SYS"},
          {targetEntityType: "PRD", qualiferType: "RELATED_TO"}]
      }
    }
  }

  function getOrgPathwayTypeKeys() {
    return getKeysForObject(getOrgPathwayTypeOptions());
  }

  function getCapPathwayTypeOptions() {
    return {
      //TODO investigate "descended from" qualifier for this pathway -- Kevin is working on the backend
      CAP_CAP_SYS: {
        name: "Systems related to Capabilities descendant from the Capability",
        firstStep: "CAP",
        lastStep: "SYS",
        headerInfo: {
          2: {name: "Capabilities", subName: "Child Of Capability"},
          3: {name: "Systems", subName: "Related Capabilities"}
        },//TODO populate and localize
        pathway: [{targetEntityType: "CAP", qualifierType: "HAS_CHILD", "relationDirection": "LEFT_TO_RIGHT"},
          {targetEntityType: "SYS", qualifierType: "RELATED_TO"}]
      },
      CAP_SYS_TEC: {
        name: "Technologies used by Systems related to the Capability",
        firstStep: "SYS",
        lastStep: "TEC",
        headerInfo: {
          2: {name: "Systems", subName: "Related Capabilities"},
          3: {name: "Technologies", subName: "Related To Systems"}
        },//TODO populate and localize
        pathway: [{targetEntityType: "SYS"},
          {targetEntityType: "TEC"}]
      },
      CAP_SYS_ORG: {
        name: "Organizations supporting Systems owned by the Capability",
        firstStep: "SYS",
        lastStep: "ORG",
        headerInfo: {
          2: {name: "Systems", subName: "Related Capabilities"},
          3: {name: "Organizations", subName: "Supporting Systems"}
        },//TODO populate and localize
        pathway: [{targetEntityType: "SYS", qualifierType: "RELATED_TO"},
          {targetEntityType: "ORG", qualifierType: "SUPPORTS"}]
      },
      CAP_SYS_PRD: {
        name: "Products related to Systems related to the Capability",
        firstStep: "SYS",
        lastStep: "PRD",
        headerInfo: {
          2: {name: "Systems", subName: "Related Capabilities"},
          3: {name: "Products", subName: "Related Systems"}
        },
        pathway: [{targetEntityType: "SYS", qualifierType: "RELATED_TO"},
          {targetEntityType: "PRD", qualifierType: "RELATED_TO"}]
      }
    }
  }

  function getCapPathwayTypeKeys() {
    return getKeysForObject(getCapPathwayTypeOptions());
  }

  function getDemPathwayTypeOptions() {
    return {
      DEM_CAP_SYS: {
        name: "Systems related to Capabilities related to the Demand",
        firstStep: "CAP",
        lastStep: "SYS",
        headerInfo: {2: {name: "Capabilities", subName: ""}, 3: {name: "Systems", subName: ""}},//TODO populate and localize
        pathway: [{targetEntityType: "CAP", qualifierType: "RELATED_TO"},
          {targetEntityType: "SYS", qualifierType: "RELATED_TO"}]
      },
      DEM_SYS_TEC: {
        name: "Technologies used by Systems related to the Demand",
        firstStep: "SYS",
        lastStep: "TEC",
        headerInfo: {2: {name: "Systems", subName: ""}, 3: {name: "Technologies", subName: ""}},//TODO populate and localize
        pathway: [{targetEntityType: "SYS", qualifierType: "RELATED_TO"},
          {targetEntityType: "TEC"}]
      },
      DEM_SYS_ORG: {
        name: "Organizations supporting Systems related to the Demand",
        firstStep: "SYS",
        lastStep: "ORG",
        headerInfo: {2: {name: "Systems", subName: ""}, 3: {name: "Organizations", subName: ""}},//TODO populate and localize
        pathway: [{targetEntityType: "SYS", qualifierType: "RELATED_TO"},
          {targetEntityType: "ORG", qualifierType: "SUPPORTS"}]
      },
      DEM_TEC_ORG: {
        name: "Organizations supporting Technologies related to the Demand",
        firstStep: "TEC",
        lastStep: "ORG",
        headerInfo: {2: {name: "Technologies", subName: ""}, 3: {name: "Organizations", subName: ""}},//TODO populate and localize
        pathway: [{targetEntityType: "TEC", qualifierType: "RELATED_TO"},
          {targetEntityType: "ORG", qualifierType: "SUPPORTS"}]
      }
    }
  }

  function getDemPathwayTypeKeys() {
    return getKeysForObject(getDemPathwayTypeOptions());
  }

  function getSysPathwayTypeOptions() {
    return {
      SYS_CAP_ORG: {
        name: "Organization Uses Capabilities Related To the System",
        firstStep: "CAP",
        lastStep: "ORG",
        headerInfo: {
          2: {name: "Capabilities", subName: ""},
          3: {name: "Organizations", subName: ""}},
        pathway: [{targetEntityType: "CAP", qualifierType: "RELATED_TO"},
          {targetEntityType: "ORG", qualifierType: "USES"}]
      },
      SYS_TEC: {
        name: "Technologies related to the System",
        firstStep: "TEC",
        lastStep: "TEC",
        headerInfo: {2: {name: "Technologies", subName: "Related To Systems"}},
        pathway: [{targetEntityType: "TEC", qualifierType: "RELATED_TO"}]
      },
      SYS_PRD: {
        name: "Products related to the System",
        firstStep: "PRD",
        lastStep: "PRD",
        headerInfo: {2: {name: "Products", subName: "Related To Systems"}},
        pathway: [{targetEntityType: "PRD", qualifierType: "RELATED_TO"}]
      },
      SYS_SYS_PRD: {
        name: "Products related to Systems descendant from the System",
        firstStep: "SYS",
        lastStep: "PRD",
        headerInfo: {
          2: {name: "Systems", subName: "Child Of System"},
          3: {name: "Products", subName: "Related To Systems"}
        },//TODO populate and localize
        pathway: [{targetEntityType: "SYS", qualifierType: "HAS_CHILD", "relationDirection": "LEFT_TO_RIGHT"},
          {targetEntityType: "PRD", qualifierType: "RELATED_TO"}]
      },
    }
  }

  function getSysPathwayTypeKeys() {
    return getKeysForObject(getSysPathwayTypeOptions());
  }

  //TODO localize below

  function getSysGroupByOptions() {
    return [
      {name: "System: Domain", value: GROUP_BY_OPTIONS.SYS_DOMAIN, key: "dataListType"},
      {name: "System: Lifecycle State", value: GROUP_BY_OPTIONS.SYS_LIFECYCLE, key: "dataListType"},
      {name: "System: Type", value: GROUP_BY_OPTIONS.SYS_TYPE, key: "dataListType"}
      ///
    ]
  }

  function getCapGroupByOptions() {
    return [
      {name: "Capability: Domain", value: GROUP_BY_OPTIONS.CAP_DOMAIN, key: "dataListType"},
      {name: "Capability: Lifecycle State", value: GROUP_BY_OPTIONS.CAP_LIFECYCLE, key: "dataListType"},
      {name: "Capability: Type", value: GROUP_BY_OPTIONS.CAP_TYPE, key: "dataListType"}
    ]
  }

  function getDemGroupByOptions() {
    return [
      {name: "Demand: Domain", value: GROUP_BY_OPTIONS.DEM_DOMAIN, key: "dataListType"},
      {name: "Demand: Lifecycle State", value: GROUP_BY_OPTIONS.DEM_LIFECYCLE, key: "dataListType"},
      //{name: "Demand: Enterprise Approval", value: GROUP_BY_OPTIONS.DEM_ENTERPRISE, key: "dataListType"},
      //{name: "Demand: Content Status", value: GROUP_BY_OPTIONS.DEM_CONTENTSTATUS, key: "dataListType"},
      {name: "Demand: Type", value: GROUP_BY_OPTIONS.DEM_TYPE, key: "dataListType"}
    ]
  }

  function getTecGroupByOptions() {
    return [
      {name: "Technology: Domain", value: GROUP_BY_OPTIONS.TEC_DOMAIN, key: "dataListType"}
    ]
  }

  function getOrgGroupByOptions() {
    return [
      {name: "Organization: Domain", value: GROUP_BY_OPTIONS.ORG_DOMAIN, key: "dataListType"}
    ]
  }

  function addSortByParams(params, column) {
    if (!column) return params;
    params.sortBy = {};
    params.sortBy.entityIndex = column.entityIndex;
    if (column.id == "SUM" || column.id == "MIN" || column.id == "MAX" || column.id == "AVG") {
      params.sortBy.fieldType = column.id;
    } else if (column.id.indexOf("NAME") > -1) {
      params.sortBy.fieldType = "NAME";
    } else {
      params.sortBy.fieldType = "COUNT";
    }
    params.sortBy.sortOrder = column.ascending ? "ASC" : "DESC";
    return params

  }

  /**
   * Creates the parameters necessary for every top level call to Neo4j.
   */
  function createDefaultParams(configuration, page, hideGroupBy) {
    var pathwayTypes = getPathwayTypeOptions()[configuration.perspective.entityType.typeCode];
    var params = {
      perspectiveEntityType: configuration.perspective.entityType.typeCode,
      pathway: pathwayTypes[configuration.pathway.data].pathway,
      metricFieldBn: configuration.metric.entity.bn
    };
    params.aggregationFunctions = [];
    for (var i = 0; i < configuration.metric.qualifiers.length; i++) {
      params.aggregationFunctions.push(configuration.metric.qualifiers[i]);
    }
    if (params.aggregationFunctions.length <= 0) {
      params.aggregationFunctions.push("SUM");
    }

    //On drilldown calls we don't want pagination information
    if (page) {
      params.firstRecord = (10 * (page - 1)) + 1;
      params.numberToReturn = 10;
    }

    console.log("Prepared query params for Neo4j ...");
    console.log("params.perspectiveEntityType=" + params.perspectiveEntityType);
    console.log("params.pathway=" + params.pathway);
    console.log("params.metricFieldBn=" + params.metricFieldBn);
    console.log("params.aggregationFunctions=" + params.aggregationFunctions);
    console.log("params.firstRecord=" + params.firstRecord);
    console.log("params.numberToReturn=" + params.numberToReturn);

    return params;
  }

  //Get a list of BN's for each entity in the given report
  function getBnListForReport(reportBn) {
    return $http({
      method: 'GET',
      url: '/b/api/reports/' + reportBn + '/entityBns'
    }).success(function (data, status, headers, config) {
    }).error(function (data, status, headers, config) {
      console.error('error in metric-aggregation-service.getBnListForReport for bn: ' + reportBn);
    });
  }

  //--------------------------------------------
  // PUBLIC FUNCTIONS
  //--------------------------------------------

  /**
   * Export my public functions.
   */
  return {
    METRIC_SOURCE_TYPES: METRIC_SOURCE_TYPES,
    METRIC_TYPES: METRIC_TYPES,
    PERSPECTIVE_TYPES: PERSPECTIVE_TYPES,
    getCSVData: getCSVData,
    getGroupByOptions: getGroupByOptions,
    getMetricAggregationDrillDown: getMetricAggregationDrillDown,
    getMetricAggregationReportData: getMetricAggregationReportData,
    getMetricEntityOptions: getMetricEntityOptions,
    getMetricSourceTypeOptions: getMetricSourceTypeOptions,
    getMetricTypes: getMetricTypes,
    getPathwayTypeKeys: getPathwayTypeKeys,
    getPathwayTypeOptions: getPathwayTypeOptions,
    getPerspectiveTypeOptions: getPerspectiveTypeOptions,
    getSortedMetricAggregationReportData: getSortedMetricAggregationReportData,
    migrateConfigIfNecessary: migrateConfigIfNecessary
  };

  function getCSVData(configuration, page, hideGroupBy) {
    var params = {};
    //Check if we are limiting the perspective by a report
    if (configuration.perspective.entity && configuration.perspective.entity.bn) {
      //Get a list of BN's for all entities in the report
      return getBnListForReport(configuration.perspective.entity.bn).then(function (data) {
        //create the parameters
        params = createDefaultParams(configuration, page, hideGroupBy);
        //Add all of the report BN's to the parameters
        params.perspectiveEntityBns = data.data;
        params.returnCSV = true;
        params.expanded = true;
        //Call neo4j
        return graphService.getMetricAggregationReportData(params);
      });
      //We are not filtering by a report
    } else {
      params = createDefaultParams(configuration, page, hideGroupBy);
      params.returnCSV = true;
      params.expanded = true;
      return graphService.getMetricAggregationReportData(params);
    }
  }

  function getGroupByOptions() {
    var groupByOptions = {};
    var sysGroupingOptions = getSysGroupByOptions();
    var capGroupingOptions = getCapGroupByOptions();
    var demGroupingOptions = getDemGroupByOptions();
    var tecGroupingOptions = getTecGroupByOptions();
    var orgGroupingOptions = getOrgGroupByOptions();
    groupByOptions["SYS"] = sysGroupingOptions;
    groupByOptions["CAP"] = capGroupingOptions;
    groupByOptions["DEM"] = demGroupingOptions;
    groupByOptions["TEC"] = tecGroupingOptions;
    groupByOptions["ORG"] = orgGroupingOptions;
    return groupByOptions;
  }

  /**
   * Make a call to neo4j that returns the drill down information for the given entity.
   */
  function getMetricAggregationDrillDown(perspectiveEntityBn, configuration, isHideGroupBy) {
    //
    console.log("getMetricAggregationDrillDown()");
    console.log("perspectiveEntityBn=" + perspectiveEntityBn);
    console.log("configuration=" + configuration);
    console.log("isHideGroupBy=" + isHideGroupBy);
    //
    //Create params
    var params = createDefaultParams(configuration, null, isHideGroupBy);
    params.perspectiveEntityBns = [perspectiveEntityBn];

    //Always check if there is a groupby
    if ((!isHideGroupBy) && configuration.groupBy && configuration.groupBy.data && configuration.groupBy.data.value) {
      params.groupByPropertyFieldKey = configuration.groupBy.data.key;
      params.groupByPropertyFieldValue = configuration.groupBy.data.value;
    }
    params.drillDown = true;

    return graphService.getMetricAggregationReportData(params);
  }


  /**
   * Given the configuration for the metric agg worksheet, and the page that you want.
   * Call the neo4j service to get the aggregation data, and return a promise with that data.
   */
  function getMetricAggregationReportData(configuration, page, hideGroupBy) {
    var params = {};
    //Check if we are limiting the perspective by a report
    if (configuration.perspective.entity && configuration.perspective.entity.bn) {
      //Get a list of BN's for all entities in the report
      return getBnListForReport(configuration.perspective.entity.bn).then(function (data) {
        //create the parameters
        params = createDefaultParams(configuration, page, hideGroupBy);
        //Add all of the report BN's to the parameters
        params.perspectiveEntityBns = data.data;
        //Call neo4j
        return graphService.getMetricAggregationReportData(params);
      });
      //We are not filtering by a report
    } else {
      params = createDefaultParams(configuration, page, hideGroupBy);
      return graphService.getMetricAggregationReportData(params);
    }
  }

  function getMetricEntityOptions(entityTypeCode) {
    var deferred = $q.defer();
    var customFieldPromise = searchService.getFieldsForTypeCode(entityTypeCode);
    customFieldPromise.then(function (data) {
      var metricSourceOptions = {};
      var customFieldOptions = [];
      var customFieldData = data.data;

      if (customFieldData && customFieldData.length) {
        _.each(customFieldData, function (field) {
          if ((field.dataType == 'INTEGER' || field.dataType == 'DECIMAL') &&
            (field.displayType == 'NUMBER' || field.displayType == 'CURRENCY' || field.displayType == 'SINGLE_SELECT')) {
            customFieldOptions.push(field);
          }
        });
      }

      // Pack them up into a map, keyed on the DATA_SOURCE_TYPE
      metricSourceOptions[METRIC_SOURCE_TYPES.CUSTOM_FIELD] = customFieldOptions;
      deferred.resolve(metricSourceOptions);
    });
    return deferred.promise;
  }

  function getMetricSourceTypeOptions() {
    return [
      {name: 'Scorecard', value: METRIC_SOURCE_TYPES.SCORECARD},
      {name: 'Custom Field', value: METRIC_SOURCE_TYPES.CUSTOM_FIELD}
    ];
  }

  function getMetricTypes() {
    return [
      'SUM', 'AVG', 'MIN', 'MAX'
    ]
  }

  function getPathwayTypeKeys() {
    var pathwayTypeKeys = {};
    pathwayTypeKeys["ORG"] = getOrgPathwayTypeKeys();
    pathwayTypeKeys["CAP"] = getCapPathwayTypeKeys();
    pathwayTypeKeys["DEM"] = getDemPathwayTypeKeys();
    pathwayTypeKeys["SYS"] = getSysPathwayTypeKeys();

    return pathwayTypeKeys;
  }

  function getPathwayTypeOptions() {
    var pathwayTypeOptions = {};
    var orgPathOptions = getOrgPathwayTypeOptions();
    var capPathOptions = getCapPathwayTypeOptions();
    var demPathOptions = getDemPathwayTypeOptions();
    var sysPathOptions = getSysPathwayTypeOptions();
    pathwayTypeOptions["ORG"] = orgPathOptions;
    pathwayTypeOptions["CAP"] = capPathOptions;
    pathwayTypeOptions["DEM"] = demPathOptions;
    pathwayTypeOptions["SYS"] = sysPathOptions;
    return pathwayTypeOptions;
  }

  function getPerspectiveTypeOptions() {
    return [
      {name: 'Organization', value: PERSPECTIVE_TYPES.ORG},
      {name: 'Capability', value: PERSPECTIVE_TYPES.CAP},
      {name: 'Demand', value: PERSPECTIVE_TYPES.DEM},
      {name: 'System', value: PERSPECTIVE_TYPES.SYS}
    ];
  }

  function getSortedMetricAggregationReportData(configuration, page, column, hideGroupBy) {
    var params = {};
    //Check if we are limiting the perspective by a report
    if (configuration.perspective.entity && configuration.perspective.entity.bn) {
      //Get a list of BN's for all entities in the report
      return getBnListForReport(configuration.perspective.entity.bn).then(function (data) {
        //create the parameters
        params = createDefaultParams(configuration, page, hideGroupBy);
        //Add all of the report BN's to the parameters
        params.perspectiveEntityBns = data.data;
        params = addSortByParams(params, column);
        //Call neo4j
        return graphService.getMetricAggregationReportData(params);
      });
      //We are not filtering by a report
    } else {
      params = createDefaultParams(configuration, page, hideGroupBy);
      params = addSortByParams(params, column);
      return graphService.getMetricAggregationReportData(params);
    }
  }

  function migrateConfigIfNecessary(config) {
    var ds = config.dataSources;

    // See if xDataSourceType, etc, need setting
    _.each(['x', 'y', 'z'], function (axis) {
      if (ds[axis] && ds[axis].type && ds[axis + 'DataSourceType'] == undefined) {
        ds[axis + 'DataSourceType'] = ds[axis].type;
      }
    });

    return config;
  }
}

/* Example 2-hop response for outer table:

{
  "columnSpec": [
    {
      "id": "NAME1",
      "name": "FAKE SYSTEM NAME",
      "sortable": true,
      "rendererId": null,
      "sorted": "ASC",
      "entityType": "SYS",
      "entityIndex": 1,
      "totalCount": 1573,
      "display": true
    },
    {
      "id": "FAKE BN1",
      "name": "FAKE SYSTEM BN",
      "sortable": true,
      "rendererId": null,
      "sorted": null,
      "entityType": "SYS",
      "entityIndex": 1,
      "totalCount": 1573,
      "display": true
    },
    {
      "id": "FAKE COUNT2",
      "name": "FAKE TECHNOLOGY COUNT",
      "sortable": true,
      "rendererId": null,
      "sorted": null,
      "entityType": "TEC",
      "entityIndex": 2,
      "totalCount": 236,
      "display": true
    },
    {
      "id": "SUM",
      "name": "FAKE TECHNOLOGY SUM",
      "sortable": true,
      "rendererId": null,
      "sorted": null,
      "entityType": "TEC",
      "entityIndex": 2,
      "totalCount": 236,
      "display": true
    },
    {
      "id": "AVG",
      "name": "FAKE TECHNOLOGY AVG",
      "sortable": true,
      "rendererId": null,
      "sorted": null,
      "entityType": "TEC",
      "entityIndex": 2,
      "totalCount": 236,
      "display": true
    }
  ],
  "rows": [
    {
      "NAME1": "FAKE Another Basic SYSTEM Release",
      "BN1": "ZZ180000001O",
      "COUNT2": 3.0,
      "SUM": 1234389.0,
      "AVG": 411463.0
    },
    {
      "NAME1": "FAKE Another System Release",
      "BN1": "ZZ180000001M",
      "COUNT2": 0.0,
      "SUM": 0.0,
      "AVG": null
    },
    {
      "NAME1": "FAKE Barometer Tracking",
      "BN1": "ZZ180000001P",
      "COUNT2": 0.0,
      "SUM": 0.0,
      "AVG": null
    },
    {
      "NAME1": "FAKE Connected System Release 1",
      "BN1": "ZZ1800000020",
      "COUNT2": 0.0,
      "SUM": 0.0,
      "AVG": null
    },
    {
      "NAME1": "FAKE Connected System Release 2",
      "BN1": "ZZ1800000021",
      "COUNT2": 0.0,
      "SUM": 0.0,
      "AVG": null
    },
    {
      "Name1": "Fake Connected System Release 3",
      "BN1": "ZZ1800000022",
      "COUNT2": 0.0,
      "SUM": 0.0,
      "AVG": null
    },
    {
      "Name1": "Fake Connected System Release 4",
      "BN1": "ZZ1800000023",
      "COUNT2": 0.0,
      "SUM": 0.0,
      "AVG": null
    },
    {
      "Name1": "Fake Connected System Release 5",
      "BN1": "ZZ1800000024",
      "COUNT2": 0.0,
      "SUM": 0.0,
      "AVG": null
    },
    {
      "Name1": "Fake Connected System Release 6",
      "BN1": "ZZ1800000025",
      "COUNT2": 0.0,
      "SUM": 0.0,
      "AVG": null
    },
    {
      "Name1": "Fake Connected System Release 7",
      "BN1": "ZZ1800000026",
      "COUNT2": 0.0,
      "SUM": 0.0,
      "AVG": null
    }
  ],
  "rowCount": 0
}
 */

/* Example 2-hop response for inner table:
{
  "columnSpec": [
    {
      "id": "NAME1",
      "name": "FAKE SYSTEM NAME",
      "sortable": true,
      "rendererId": null,
      "sorted": null,
      "entityType": "SYS",
      "entityIndex": 1,
      "totalCount": 1,
      "display": true
    },
    {
      "id": "BN1",
      "name": "FAKE SYSTEM BN",
      "sortable": true,
      "rendererId": null,
      "sorted": null,
      "entityType": "SYS",
      "entityIndex": 1,
      "totalCount": 1,
      "display": true
    },
    {
      "id": "NAME2",
      "name": "FAKE TECHNOLOGY NAME",
      "sortable": true,
      "rendererId": null,
      "sorted": "ASC",
      "entityType": "TEC",
      "entityIndex": 2,
      "totalCount": 3,
      "display": true
    },
    {
      "id": "BN2",
      "name": "FAKE TECHNOLOGY BN",
      "sortable": true,
      "rendererId": null,
      "sorted": null,
      "entityType": "TEC",
      "entityIndex": 2,
      "totalCount": 3,
      "display": true
    },
    {
      "id": "COUNT2",
      "name": "FAKE TECHNOLOGY COUNT",
      "sortable": true,
      "rendererId": null,
      "sorted": null,
      "entityType": "TEC",
      "entityIndex": 2,
      "totalCount": 3,
      "display": true
    },
    {
      "id": "SUM",
      "name": "FAKE TECHNOLOGY SUM",
      "sortable": true,
      "rendererId": null,
      "sorted": null,
      "entityType": "TEC",
      "entityIndex": 2,
      "totalCount": 3,
      "display": true
    },
    {
      "id": "AVG",
      "name": "FAKE TECHNOLOGY AVG",
      "sortable": true,
      "rendererId": null,
      "sorted": null,
      "entityType": "TEC",
      "entityIndex": 2,
      "totalCount": 3,
      "display": true
    }
  ],
  "rows": [
    {
      "Name1": "Fake Another Basic SYSTEM Release",
      "BN1": "ZZ180000001O",
      "NAME2": "FAKE BEA WebLogic Server 10.0",
      "BN2": "ZZ1H000002VI",
      "COUNT2": 1.0,
      "SUM": 234.0,
      "AVG": 234.0
    },
    {
      "Name1": "Fake Another Basic SYSTEM Release",
      "BN1": "ZZ180000001O",
      "NAME2": "FAKE Inheritance Test Technology Edition 1",
      "BN2": "ZZ1H0000001W",
      "COUNT2": 1.0,
      "SUM": 1234123.0,
      "AVG": 1234123.0
    },
    {
      "Name1": "Fake Another Basic SYSTEM Release",
      "BN1": "ZZ180000001O",
      "NAME2": "FAKE Sun Java Enterprise System 2004Q2",
      "BN2": "ZZ1H000016FC",
      "COUNT2": 1.0,
      "SUM": 32.0,
      "AVG": 32.0
    }
  ],
  "rowCount": 0
}
 */
