angular.module('barometerApp.addWidget')
  // Multi-entity add widget based on global search
  .directive('addWidget', ['searchService', '$window', 'bitConstants', 'utilService', 'urlService', '$document', 'associatedBnCacheService', 'styleService',
    function (searchService, $window, bitConstants, utilService, urlService, $document, associatedBnCacheService, styleService) {

      // Note: this is a simplified/altered clone of GlobalSearchCtrl
      return {
        scope: {
          addEntityFn: '=',

          // Optionally limit to certain entityTypes (whitelist).  Array of entity types.
          includeEntityTypes: '=',

          //Optionally include/exclude query entities (rules/reports/scorecards) default include
          //true = exclude; false = include;
          //This is inverted on the back end, however, since I want the option to just exclude the
          //value from definitions, I want the default false to mean include.
          excludeQueries: '=',

          // Optionally provide a boolean that, when flipped to true, will trigger focus on the search
          // input within the widget.
          //
          // Eg:
          //
          //      <div class="add-entity" ng-if="showEntityAdd">
          //        <span add-widget add-entity-fn="addRelevantEntity" include-entity-types="includeRelevantEntityTypes" trigger-focus="showEntityAdd"></span>
          //     </div>
          //
          triggerFocus: '='

        },

        templateUrl: '/b/js/src/bit.ng/add-widget/partials/add-widget.html',
        link: function (scope, element, attrs) {

          function markGroups(entityTypeGroups) {
            var result = entityTypeGroups;

            // Mark existing assocs
            if (associatedBnCacheService.hasData()) {
              _.each(result, function (entityTypeGroup) {
                _.each(entityTypeGroup.entityTypeResults, function (entity) {
                  entity.isAssociated = associatedBnCacheService.isAssociated(entity.bn);
                });
              });
            }
            return result;
          }

          function markTopHit(topHit) {
            // Mark existing assocs
            topHit.isAssociated = associatedBnCacheService.isAssociated(topHit.bn);
            return topHit;
          }

          function addTopHitToGroups(groups, topHitData) {
            if (topHitData) {
              var topHit = {
                topHit: true,
                entityTypeResults: [
                  {
                    bn: topHitData.bn,
                    entityType: topHitData.entityType,
                    name: topHitData.name,
                    isAssociated: topHitData.isAssociated
                  }
                ]
              };
              groups.splice(0, 0, topHit);
            }
          }

          scope.showEmptyMessage = false;
          scope.groups = [];
          scope.topHit = {topHit: true, entityTypeResults: []};
          scope.searchModel = {
            selected: undefined
          };
          scope.openTypeahead = false;
          scope.mouseInResultsPopup = false;
          // -1 represents the default 'browse all'
          scope.activeGroupIndex = -1;
          scope.activeResultsIndex = -1;

          scope.getEntityDisplayName = function (resultGroup) {
            if (resultGroup.topHit) {
              return "Top Hit";
            }
            return bitConstants.getEntityTypeDisplayNameForTypeCode(resultGroup.entityTypeCode, true);
          };

          scope.getHeaderIconClass = function (resultGroup) {
            if (resultGroup.topHit) {
              return styleService.getIconClassForEntityTypeCode(resultGroup.entityTypeResults[0].entityType);
            }
            return styleService.getIconClassForEntityTypeCode(resultGroup.entityTypeCode);
          };

          scope.getMatches = function (query) {
            return searchService.searchUnified(query, 15, scope.includeEntityTypes, !scope.excludeQueries).then(function (results) {
              scope.groups = markGroups(results.data.matches);
              // Note: because of above filtering, results.count is not reliable
              if(results.data.topHit) {
                addTopHitToGroups(scope.groups, markTopHit(results.data.topHit));
              }
              if (scope.groups.length) {
                scope.showEmptyMessage = false;
              } else {
                scope.showEmptyMessage = true;
              }

              if (!scope.openTypeahead) {
                scope.openTypeahead = true;
                prototype.closeDropdowns();
              }

              scope.resetCursorToDefault();
            });
          };

          scope.handleEscape = function () {
            scope.openTypeahead = false;
            scope.resetCursorToDefault();
            scope.showEmptyMessage = false;
          };

          scope.isCursorAtDefault = function () {
            return scope.activeGroupIndex == 0 && scope.activeResultsIndex == 0;
          };

          scope.isOpen = function () {
            return scope.openTypeahead;
          };

          scope.mouseEnteredDropdown = function () {
            scope.mouseInResultsPopup = true;
          };

          scope.mouseExitedDropdown = function () {
            scope.mouseInResultsPopup = false;
          };

          scope.resetCursorToDefault = function () {
            if (scope.groups.length) {
              scope.activeGroupIndex = 0;
              scope.activeResultsIndex = 0;
            } else {
              scope.activeGroupIndex = -1;
              scope.activeResultsIndex = -1;
            }
          };

          scope.doSearch = function (searchVal) {
            if (searchVal) {
              scope.getMatches(searchVal);
            } else {
              scope.openTypeahead = false;
            }
          };

          // Trigger focus on the search input within the widget
          // Controlled from outside the widget via the trigger-focus param
          function focus(){
            // Hacky, but there isn't a good way to do this in angular
            setTimeout(function () {
              $(element).find('.search').focus();
            }, 100);
          }

          if (scope.triggerFocus) focus();

          scope.$watch('triggerFocus', function(oldVal, newVal){
            if (newVal) focus();
          });

          //is it ok to do this in a controller??
          $document.bind('click', function () {
            if (!scope.mouseInResultsPopup) {
              scope.openTypeahead = false;
              scope.resetCursorToDefault();
            }
            scope.$digest();
          });
        }
      }
    }]);