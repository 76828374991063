angular
    .module('barometerApp.relationalDiagram')
    .factory('relationalDiagramStyleService', relationalDiagramStyleService);

relationalDiagramStyleService.$inject = ['utilService', 'styleService'];

/**
 * Uses Cytoscape's CSS-inspired syntax for declarative styling: http://js.cytoscape.org/#core/style.
 */
function relationalDiagramStyleService(utilService, styleService) {

    var _cytoClassesTemp;

    // Styles for each entityType
    function getRuleForEntityTypeCode(entityTypeCode) {
        return {
            selector: '.entity-type-' + entityTypeCode.toLowerCase(),
            style: {
                'background-color': styleService.getColorForEntityTypeCode(entityTypeCode),
                'background-image': styleService.getIconUrlForEntityTypeCode(entityTypeCode)
            }
        };
    }

    function getRuleForLifecycleType(type) {
        return {
            selector: '.' + type,
            style: {
                'border-width': 7,
                'border-style': 'solid',
                'border-color': styleService.getColorForRoadmapType(type),
                'z-index': 1
            }
        };
    }

    return {
        getCytoCssClassesForNode: getCytoCssClassesForNode,
        getCytoCssClassesForEdge: getCytoCssClassesForEdge,
        DEFAULT: [
            {
                selector: 'node.entity',
                style: {
                    'background-color': '#666666',
                    'width': 50,
                    'height': 50,
                    'shape': 'ellipse',
                    // Note: assumes background-image is set on each node (customized by entityType)
                    'background-width': '55%',
                    'background-height': '55%',

                    // Labels
                    'label': 'data(entity.name)',
                    'text-wrap': 'wrap',
                    'text-max-width': 160,
                    'font-size': 14,
                    'font-family': 'Lato, sans-serif',
                    'color': '#666666',
                    'min-zoomed-font-size': 10,
                    'text-outline-color': '#ffffff',
                    'text-outline-width': 2,
                    'text-outline-opacity': 1
                }
            },

            getRuleForEntityTypeCode('ACT'),
            getRuleForEntityTypeCode('CAP'),
            getRuleForEntityTypeCode('COM'),
            getRuleForEntityTypeCode('DAT'),
            getRuleForEntityTypeCode('DEM'),
            getRuleForEntityTypeCode('ORG'),
            getRuleForEntityTypeCode('MKT'),
            getRuleForEntityTypeCode('PER'),
            getRuleForEntityTypeCode('PHY'),
            getRuleForEntityTypeCode('PRD'),
            getRuleForEntityTypeCode('SKI'),
            getRuleForEntityTypeCode('STA'),
            getRuleForEntityTypeCode('STR'),
            getRuleForEntityTypeCode('SYS'),
            getRuleForEntityTypeCode('TEC'),

            // Special styling for Connections
            {
                selector: '.entity-type-con',
                style: {
                    'background-color': '#999999',
                    'shape': 'diamond',
                    'height': 20,
                    'width': 20
                }
            },
            getRuleForLifecycleType('ACTIVE'),
            getRuleForLifecycleType('PLANNED'),
            getRuleForLifecycleType('INACTIVE'),


            // Nodes that are included because of rollup
            {
                selector: 'node.indirect',
                style: {
                    'height': 25,
                    'width': 25,
                    'font-size': 10
                }
            },

            // Node highlight
            {
                selector: 'node.highlight',
                style: {
                    'color': '#000000',
                    'font-size': 18,
                    'border-width': 7,
                    'border-style': 'solid',
                    'border-color': '#20498d',
                    'z-index': 1
                }
            },

            {
                selector: 'edge',
                style: {
                    'width': 10,
                    'line-color': '#dddddd',
                    'opacity': .6,

                    // Arrows
                    'target-arrow-shape': 'triangle', // 'none'
                    'target-arrow-color': '#dddddd',
                    'target-arrow-opacity': .6,

                    // Labels
                    'label': 'data(qualifier)',

                    'text-valign': 'top',
                    'text-opacity': 1,
                    'font-family': 'Lato, sans-serif',
                    'font-weight': 'bold',
                    'color': '#000000', // Extra dark because the label is affected by the edge opacity
                    'edge-text-rotation': 'autorotate',
                    'font-size': 8,
                    'min-zoomed-font-size': 5
                }
            },
            // Edges that are included because of rollup
            {
                selector: 'edge.indirect',
                style: {
                    'line-style': 'dashed'
                }
            },

            // Edge highlight
            {
                selector: 'edge.highlight',
                style: {
                    'line-color': '#20498d',
                    'color': '#ffffff',
                    'target-arrow-color': '#20498d',
                    'opacity': 1,
                    'z-index': 1
                }
            },

            //// Edges that represent connections
            //{
            //  selector: 'edge.connection',
            //  style: {
            //    'mid-target-arrow-shape': 'diamond',
            //    'mid-target-arrow-color': '#999999',
            //    'mid-target-arrow-opacity': 1,
            //    'source-label': 'source',
            //    'target-label': 'target',
            //    'label': 'data(entity.name)'
            //  }
            //}
            {
                selector: ':parent, .group',
                style: {
                    // Width and height act as minimums for empty groups
                    // These values are ignored if the group has children
                    'width': 140,
                    'height': 80,
                    'background-color': '#7891BA',
                    'background-opacity': 0.4,
                    'shape': 'roundrectangle',
                    'border-color': '#7891BA',

                    // Labels
                    'label': 'data(entity.name)',
                    'text-valign': "top",
                    'text-wrap': 'wrap',
                    'text-max-width': 160,
                    'font-size': 20,
                    'color': '#7891BA',
                    'min-zoomed-font-size': 10
                }
            },
            {
                selector: ':child',
                style: {
                    'text-outline-width': 0
                }
            }
        ]
    };

    function getCytoCssClassesForNode(node, isShowLifecycle) {
        _cytoClassesTemp = 'entity';
        _cytoClassesTemp += ' entity-type-' + utilService.getEntityTypeCode(node.bn).toLowerCase();
        _cytoClassesTemp += (node.pathType != 'direct' && node.pathType != 'none') ? ' indirect' : '';
        _cytoClassesTemp += (isShowLifecycle && node.lifecycleState && node.lifecycleState.type) ? ' ' +
          node.lifecycleState.type : '';
        return _cytoClassesTemp;
    }

    function getCytoCssClassesForEdge(edge) {
        return edge.pathType != 'direct' ? ' indirect' : '';
    }
}
