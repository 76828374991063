angular.module('barometerApp.contentStatus')
  .factory('contentStatusService', ['redux', (redux) => {
    const CONTENT_STATUS_OPTIONS = [
      { id: 'APPV', label: 'Approved', workflow: true },
      { id: 'COMP', label: 'Completed', workflow: true },
      { id: 'INPR', label: 'In Process', workflow: false },
      { id: 'PLND', label: 'Planned', workflow: false },
      { id: 'IDFD', label: 'Identified', workflow: false }
    ];
    const DEFAULT_CONTENT_STATUS_ID_FOR_ENABLED = 'COMP';
    const DEFAULT_CONTENT_STATUS_ID_FOR_DISABLED = 'IDFD';

    const { store, selectors } = redux;
    const { settings } = selectors;

    const contentStatusTriggersWorkflow = contentStatusId => {
      const cs = CONTENT_STATUS_OPTIONS.find(obj => obj.id === contentStatusId) || {};
      return cs.workflow;
    };

    const getDefaultLegacyContentStatusId = workflowEnabled => {
      return workflowEnabled ? DEFAULT_CONTENT_STATUS_ID_FOR_ENABLED : DEFAULT_CONTENT_STATUS_ID_FOR_DISABLED;
    };

    const getContentStatusOptions = () => {
      return _.cloneDeep(CONTENT_STATUS_OPTIONS);
    };

    const getDefaultContentStatus = entityType => {
      const isEntityWorkflowed = settings.getIsEntityWorkflowed(store.getState(), { entityType });
      return isEntityWorkflowed ? DEFAULT_CONTENT_STATUS_ID_FOR_ENABLED : DEFAULT_CONTENT_STATUS_ID_FOR_DISABLED
    }

    return {
      contentStatusTriggersWorkflow,
      getDefaultLegacyContentStatusId,
      getContentStatusOptions,
      getDefaultContentStatus,
    }
  }])
;
