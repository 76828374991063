angular.module('barometerApp.entity')
// ND: I'm using this directive instead of just doing an ng-include because I need the content to replace the
// tag to avoid extra markup.  If I use <ng-include src="'aURL'"> wicket(i assume) will mess with the 'aURL' part, I
// believe because ng-include is using the 'src' attribute.
  .directive('entityNav', [
    function () {
      return {
        replace: true,
        templateUrl: '/b/js/src/bit.ng/entity/partials/entity-nav.html'
      }
    }])
  .directive('newProposedEditsIdentifier', ['entityService',
    function (entityService) {
      return {
        replace: true,
        link: function (scope, element, attrs) {
          $(element).find("li").removeClass("new");
          _.forEach(entityService.getNewProposedEditBns(), function (value) {
            $(element).find("[data-pcd-bn='" + value + "']").addClass("new");
          })
        }
      }
    }])
  .directive('entityProposedInfo', ['urlService', '$http', '$compile',
    function (urlService, $http, $compile) {
      return {
        scope: {
          proposedCodes: "=",  // identifies what proposed edits to load for the section
          sectionBn: "="   // bn of section, used for lazy loading by section
        },
        replace: true,
        link: function (scope, element, attrs) {
          scope.proposedInfoModel = {};
          scope.proposedInfoModel.loaded = false;
          scope.getContent = function () {
            var baseUrl = urlService.prepareWicketPageUrl('proposededits');
            var urlOfContent = baseUrl + '?entityBn=' + urlService.getEntityBn() +
              '&proposedCodes=' + scope.proposedCodes + '&sectionBn=' + scope.sectionBn;
            var contentPromise = $http.get(urlOfContent);
            contentPromise.then(function (data) {
              // compile the markup coming back because it has angular directives in it to highlight new changes
              const compiledElements = $compile(data.data)(scope);
              $(element).empty();
              $(element).append(compiledElements);
              scope.proposedInfoModel.loaded = true;
            });
          };
          scope.$on('loadSection', function (event, sectionBn, forceReload) {
            if (!scope.sectionBn) return;
            if (scope.sectionBn == sectionBn) {
              if (scope.proposedInfoModel.loaded == false || forceReload) {
                scope.proposedInfoModel.loaded = true;
                scope.getContent();
              }
            }
          });
          scope.$on('newProposedEdits', function (event, changeBns) {
            if (scope.proposedInfoModel.loaded == true) {
              // reload views that have already been loaded so new proposed edits will display
              scope.getContent();
            }
          });
        }
      }
    }])
  .directive('entitySummaryInfoLoader', ['urlService', '$http',
    function (urlService, $http) {
      return {
        scope: {
          assocCode: "=",  // identifies assoc to load for this section
          sectionBn: "="   // bn of section, used for lazy loading by section
        },
        replace: true,
        link: function (scope, element, attrs) {
          scope.summaryInfoModel = {};
          scope.summaryInfoModel.loaded = false;
          scope.getContent = function () {
            $(element).empty();
            var baseUrl = urlService.prepareWicketPageUrl('summaryinfo');
            var urlOfContent = baseUrl + '?entityBn=' + urlService.getEntityBn() + '&assocCode=' + scope.assocCode;
            var contentPromise = $http.get(urlOfContent);
            contentPromise.then(function (data) {
              // IE is barfing on direct append, so lets strip out the body content so we're not injecting html documents into the DOM
              var match = data.data.match(/<body[^>]*>([^<]*(?:(?!<\/?body)<[^<]*)*)<\/body\s*>/i),
                bodyContent;
              if (match && match.length > 0) {
                bodyContent = match[1];
              }
              $(element).append(bodyContent || data.data || "");
              scope.summaryInfoModel.loaded = true;
            });
          };
          scope.$on('loadSection', function (event, sectionBn, forceReload) {
            if (!scope.sectionBn) return;
            if (scope.sectionBn == sectionBn) {
              if (scope.summaryInfoModel.loaded == false || forceReload) {
                scope.summaryInfoModel.loaded = true;
                scope.getContent();
              }
            }
          });
        }
      }
    }])
  .directive('entityAuditInfo', ['urlService', '$http', '$compile',
    function (urlService, $http, $compile) {
      return {
        scope: {
          assocCode: "=",  // identifies assoc to load for this section
          sectionBn: "="   // bn of section, used for lazy loading by section
        },
        replace: true,
        link: function (scope, element, attrs) {
          scope.auditInfoModel = {};
          scope.auditInfoModel.loaded = false;
          scope.getContent = function () {
            $(element).empty();
            var baseUrl = urlService.prepareWicketPageUrl('scorecardinfo');
            var urlOfContent = baseUrl + '?entityBn=' + urlService.getEntityBn() + '&assocCode=' + scope.assocCode;
            var contentPromise = $http.get(urlOfContent);
            contentPromise.then(function (data) {
              $(element).append($compile(data.data)(scope));
              scope.auditInfoModel.loaded = true;
            });
          };
          scope.$on('loadSection', function (event, sectionBn, forceReload) {
            if (!scope.sectionBn) return;
            if (scope.sectionBn == sectionBn) {
              if (scope.auditInfoModel.loaded == false || forceReload) {
                scope.auditInfoModel.loaded = true;
                scope.getContent();
              }
            }
          });
        }
      }
    }])

  //for data driven help content (such as data-lists), you need to provide the dynamicTitle and dynamicContent.  For non-data-driven help
  //you just need to provide the staticKey which maps to help content in help-content.js.
  .directive('entityHelp', [
    function () {
      return {
        scope: {
          dynamicTitle: "=",
          dynamicContent: "=",
          staticKey: "@"
        },
        replace: true,
        templateUrl: '/b/js/src/bit.ng/entity/partials/entity-help.html',
        link: function (scope, element, attrs) {
          if (scope.staticKey && StaticHelp[scope.staticKey]) {
            scope.staticTitle = StaticHelp[scope.staticKey].title;
            scope.staticContent = StaticHelp[scope.staticKey].desc;
          }
        }
      }
    }])
  .directive('entityLifecycleRoadmap', [
    function () {
      return {
        templateUrl: '/b/js/src/bit.ng/entity/partials/entity-lifecycle-roadmap.html'
      }
    }])
  .directive('entityDetails', [
    function () {
      return {
        templateUrl: '/b/js/src/bit.ng/entity/partials/entity-details.html'
      }
    }])
  .directive('entityTags', [ 'tenantService',
    function (tenantService) {
      return {
        link: function(scope) {
          scope.tagsAllowed = tenantService.getCurrentTenantsAuthorizedCatalogs().filter(auth => auth.bn === '04430000000J').length > 0;
        },
        templateUrl: '/b/js/src/bit.ng/entity/partials/entity-tags.html'
      }
    }])
  .directive('entityLinks', [
    function () {
      return {
        templateUrl: '/b/js/src/bit.ng/entity/partials/entity-links.html'
      }
    }])
;
