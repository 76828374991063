angular
  .module('barometerApp.relationalDiagram')
  .factory('relationalDiagramCytoService', relationalDiagramCytoService);

relationalDiagramCytoService.$inject = [
  '$q',
  '$timeout',
  'relationalDiagramStyleService',
  'utilService'
];

function relationalDiagramCytoService($q,
                                      $timeout,
                                      relationalDiagramStyleService,
                                      utilService) {

  //-------------------------------------------
  // PRIVATE FUNCTIONS
  //-------------------------------------------

  /**
   * Add persistent groups to the set of cyto-nodes.
   */
  function applyGroups(config, cytoNodeElements) {

    _.forEach(config.groups, function (group) {
      // Clone these instead of push direct, so we can't accidentally damage them when we edit config.
      var clonedGroup = _.cloneDeep(group);
      cytoNodeElements.push(clonedGroup);
    })
  }


  /**
   * Transfers our persistent node positions onto actual (existing) cyto nodes.
   *
   * Any node with no entry in nodePositionMap will get crudely added above the laid out nodes.
   *
   * Called on initialization or after any update-and-rerender.
   *
   * @param cytoNodes Nodes to be positioned. Each will have its "position" element updated.
   * @param nodePositionsMap A map of positions for each node. "nodePositions" are saved as part of our
   *  worksheet config JSON. They are retrieved and inserted into scope. This operation is how we restore
   *  a persisted layout to a cytoscape diagram. Map takes format e.g.: { bn: {x: 124, y: -53}, ...}.
   */
  function applyNodePositions(config, cytoNodes) {

    var nodePositionsMap = config.layout.nodePositions;
    if (!nodePositionsMap || _.isEmpty(nodePositionsMap)) {
      // Perhaps should assert and fail, but this is what's been done previously:
      return
    }

    var nodesWithoutPositions = [];
    var top = 9999, left = 9999, buffer = 65;
    var altTop;
    // For each node ...
    _.each(cytoNodes, function (cytoNode) {
      // If the map contains a specified position ...
      if (nodePositionsMap[cytoNode.data.id]) {
        // Apply the specified position.
        cytoNode.position = nodePositionsMap[cytoNode.data.id];
        // Track most top/left positions.
        top = Math.min(top, cytoNode.position.y);
        left = Math.min(left, cytoNode.position.x);
      } else {
        // Capture each node w/o specified position.
        nodesWithoutPositions.push(cytoNode);
      }
    });
    // Finally, place each un-positioned node across the top.
    top -= buffer;
    // Use alternate top value for every other node to make the starting layout non-linear.
    // Allows cyto layouts to run better.
    altTop = top - 30;
    left -= buffer;
    _.each(nodesWithoutPositions, function (nodeWithoutPosition, i) {
      nodeWithoutPosition.position = {x: left, y: i % 2 == 0 ? top : altTop};
      left += buffer;
    });
  }

  /**
   *
   * @param bn
   * @param config
   * @returns {null}
   */
  function getParent(bn, config) {
    for (var group in config.groups) {
      if (config.groups.hasOwnProperty(group)) {
        if (config.groups[group].data.children && config.groups[group].data.children.length > 0) {
          for (var child in config.groups[group].data.children) {
            if (config.groups[group].data.children.hasOwnProperty(child)) {
              if (config.groups[group].data.children[child] === bn) {
                return config.groups[group].data.id;
              }
            }
          }
        }
      }
    }
    return null;
  }

  /**
   *
   * @param relationshipType
   * @param qualifier
   * @returns {*}
   */
  function normalizeType(type) {
    //
    let result = "";
    let typeStringArray = type.split('_');
    result = typeStringArray.join(' ');
    return utilService.titleCaseString(result);
  }

  function wipeCyInstance(cy, cytoNodeElements, cytoEdgeElements) {
    // Wipe cy.
    cy.elements().remove();
    // Add fresh nodes.
    cy.add(cytoNodeElements);
    // Add fresh edges.
    cy.add(cytoEdgeElements);
  }

  //-------------------------------------------
  // PUBLIC FUNCTIONS
  //-------------------------------------------

  /**
   * Export my public functions.
   */
  return {
    clearHighlights: clearHighlights,
    toNodePositions: toNodePositions,
    loadCytoLib: loadCytoLib,
    newCyInstance: newCyInstance,
    resetCyInstance: resetCyInstance,
    resizeCyWindow: resizeCyWindow,
    toCytoEdges: toCytoEdges,
    toCytoNode: toCytoNode
  };

  /**
   * @param config Apply to cytoNodeElements.
   * @param nodePositionsMap Apply to cytoNodeElements.
   * @param cytoNodeElements Target objects.
   */
  function applyConfiguration(config, cytoNodeElements) {
    applyGroups(config, cytoNodeElements);
    // Apply remembered positions to cyto-nodes.
    applyNodePositions(config, cytoNodeElements);
  }

  /**
   *
   */
  function clearHighlights(cy) {
    if (cy.elements) {
      cy.elements('.highlight').removeClass('highlight');
    }
  }

  /**
   * Load cytoscape lib if necessary.
   */
  function loadCytoLib() {
    var deferred = $q.defer();
    //
    if (typeof cytoscape === 'undefined') {
      $LAB
        .script($LAB.jsUrl('cytoscape'))
        .wait()
        .script($LAB.jsUrl('cola'))
        .script($LAB.jsUrl('cytoscape-cola'))
        .wait(deferred.resolve);
    } else {
      deferred.resolve();
    }
    return deferred.promise;
  }

  /**
   * Transfers the positions of actual (existing) cyto nodes into our persistent node configuration format.
   *
   * @param cy
   * @returns {{}} A mapping of bn to x, y positions: { bn: { x: 10, y: 26 } , ... }
   */
  function toNodePositions(cy) {
    let pos, nodePositions = {};
    _.each(cy.nodes(), function (node) {
      pos = node.modelPosition();
      nodePositions[node.id()] = {x: pos.x, y: pos.y};
    });
    return nodePositions;
  }

  /**
   * Makes a fresh cyto node from an existing entity.
   */
  function toCytoNode(entity, config) {
    //
    var isShowLifecycle = config.showLifecycle.value;
    // Note: pre-set positions should be applied later via applyPositions.
    var position = {x: 0, y: 0};
    // Return cyto element.
    return {
      group: "nodes",
      data: {
        id: entity.bn,
        parent: getParent(entity.bn, config),
        entity: entity
      },
      classes: relationalDiagramStyleService.getCytoCssClassesForNode(entity, isShowLifecycle),
      position: position
    }
  }

  /**
   *
   */
  function toCytoEdges(graphEdges) {
    //try {
    var edgeElements = [];
    _.each(graphEdges, function (graphEdge) {
      if (graphEdge.fromBn && graphEdge.toBn) {
        let edgeData = {
          group: "edges",
          data: {
            source: graphEdge.fromBn,
            target: graphEdge.toBn,
            qualifier: (graphEdge.qualifier == null) ? '': normalizeType(graphEdge.qualifier),
            relationshipType: normalizeType(graphEdge.relationshipType),
            pathType: graphEdge.pathType
          },
          classes: relationalDiagramStyleService.getCytoCssClassesForEdge(graphEdge)
        };
        edgeElements.push(edgeData);
      }
    });
    //}
    //catch (err) {
    // TODO: A thing sometimes happens here that needs more exploration.
    // This is here because errors like this one ...
    // "Can not create edge `ele225` with nonexistant source `ZZ1800007RF0`"
    // ... may be blowing up Blue Bar logic.
    // Hmm ... although that ought to be totally async. Hmm.
    // Tested: Errors here probably do not hurt Blue Bar,
    // except when they abort "start" broadcasts that should come from our controller
    // (resulting in bad event counts and non-propagated transaction IDs).
    //console.error("toCytoEdges failed with error.", err);
    //}
    return edgeElements;
  }

  /**
   * @param cy
   * @param cytoNodeElements
   * @param cytoEdgeElements
   * @param config
   */
  function resetCyInstance(cy, cytoNodeElements, cytoEdgeElements, config) {
    clearHighlights(cy);
    applyConfiguration(config, cytoNodeElements);
    wipeCyInstance(cy, cytoNodeElements, cytoEdgeElements);
  }

  /**
   * Use this to reset view pane, for example you draw something else onto the screen
   * that might change x,y so far as pointer focus is concerned (e.g. BARO-18857).
   */
  function resizeCyWindow(cy) {
    if (cy) {
      $timeout(function () {
        cy.resize();
      }, 600);
    }
  }

  function newCyInstance(container, layout) {
    const cyInstance = cytoscape({
      container: container,
      elements: [],
      style: relationalDiagramStyleService.DEFAULT,
      layout: layout,
      minZoom: 0.3,
      maxZoom: 3,
      autoungrabify: true  // Start with nodes fixed in place – "read-only" mode
    });
    return cyInstance
  }
}

