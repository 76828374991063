/**
 * Created by timstave on 6/29/15.
 */

let entityLabels = {};

entityLabels['ACT'] = {'name': 'Actor', 'label': 'Actor BN'};
entityLabels['CAP'] = {'name': 'Capability', 'label': 'Capability BN'};
entityLabels['COM'] = {'name': 'Company', 'label': 'Company BN'};
entityLabels['CON'] = {'name': 'Connection', 'label': 'Connection BN'};
entityLabels['DAT'] = {'name': 'Data', 'label': 'Data BN'};
entityLabels['DEM'] = {'name': 'Demand', 'label': 'Demand BN'};
entityLabels['PHY'] = {'name': 'Deployment', 'label': 'Deployment BN'};
entityLabels['MKT'] = {'name': 'Market', 'label': 'Market BN'};
entityLabels['ORG'] = {'name': 'Organization', 'label': 'Organization BN'};
entityLabels['PER'] = {'name': 'Contacts (People)', 'label': 'Person BN'};
entityLabels['PRD'] = {'name': 'Product', 'label': 'Product BN'};
entityLabels['SKI'] = {'name': 'Skill', 'label': 'Skill BN'};
entityLabels['STA'] = {'name': 'Standard', 'label': 'Standard BN'};
entityLabels['STR'] = {'name': 'Strategy', 'label': 'Strategy BN'};
entityLabels['SYS'] = {'name': 'System', 'label': 'System BN'};
entityLabels['TEC'] = {'name': 'Technology', 'label': 'Technology BN'};

let relationshipsByEntityType = {};

// Actors
relationshipsByEntityType['ACT'] = {
  'Capability': 'CAP',
  'Company': 'COM',
  'Data': 'DAT',
  'Demand': 'DEM',
  'Deployment': 'PHY',
  'Market': 'MKT',
  'Organization': 'ORG',
  'Product': 'PRD',
  'Strategy': 'STR',
  'System': 'SYS'
};

// Capabilities
relationshipsByEntityType['CAP'] = {
  'Actor': 'ACT',
  'Capability': 'CAP',
  'Company': 'COM',
  'Connection': 'CON',
  'Data': 'DAT',
  'Demand': 'DEM',
  'Deployment': 'PHY',
  'Market': 'MKT',
  'Organization': 'ORG',
  'Person': 'PER',
  'Product': 'PRD',
  'Skill': 'SKI',
  'Standard': 'STA',
  'Strategy': 'STR',
  'System': 'SYS',
  'Technology': 'TEC'
};

// Companies
relationshipsByEntityType['COM'] = {
  'Capability': 'CAP',
  'Company': 'COM',
  'Connection': 'CON',
  'Data': 'DAT',
  'Demand': 'DEM',
  'Deployment': 'PHY',
  'Market': 'MKT',
  'Product': 'PRD',
  'Standard': 'STA',
  'Strategy': 'STR',
  'System': 'SYS',
  'Technology': 'TEC'
};

// Connections
relationshipsByEntityType['CON'] = {
  'Capability': 'CAP',
  'Company': 'COM',
  'Connection': 'CON',
  'Data': 'DAT',
  'Demand': 'DEM',
  'Deployment': 'PHY',
  'Market': 'MKT',
  'Organization': 'ORG',
  'Person': 'PER',
  'Product': 'PRD',
  'Standard': 'STA',
  'Strategy': 'STR',
  'System': 'SYS'
};

// Data
relationshipsByEntityType['DAT'] = {
  'Actor': 'ACT',
  'Capability': 'CAP',
  'Company': 'COM',
  'Connection': 'CON',
  'Data': 'DAT',
  'Demand': 'DEM',
  'Deployment': 'PHY',
  'Market': 'MKT',
  'Organization': 'ORG',
  'Person': 'PER',
  'Product': 'PRD',
  'Strategy': 'STR',
  'System': 'SYS'
};

// Demands
relationshipsByEntityType['DEM'] = {
  'Actor': 'ACT',
  'Capability': 'CAP',
  'Company': 'COM',
  'Connection': 'CON',
  'Data': 'DAT',
  'Demand': 'DEM',
  'Deployment': 'PHY',
  'Market': 'MKT',
  'Organization': 'ORG',
  'Person': 'PER',
  'Product': 'PRD',
  'Skill': 'SKI',
  'Standard': 'STA',
  'Strategy': 'STR',
  'System': 'SYS',
  'Technology': 'TEC'
};

// Deployments
relationshipsByEntityType['PHY'] = {
  'Actor': 'ACT',
  'Capability': 'CAP',
  'Company': 'COM',
  'Connection': 'CON',
  'Data': 'DAT',
  'Demand': 'DEM',
  'Deployment': 'PHY',
  'Market': 'MKT',
  'Organization': 'ORG',
  'Person': 'PER',
  'Product': 'PRD',
  'Skill': 'SKI',
  'Standard': 'STA',
  'Strategy': 'STR',
  'System': 'SYS',
  'Technology': 'TEC'
};

// Markets
relationshipsByEntityType['MKT'] = {
  'Actor': 'ACT',
  'Capability': 'CAP',
  'Company': 'COM',
  'Connection': 'CON',
  'Data': 'DAT',
  'Demand': 'DEM',
  'Deployment': 'PHY',
  'Market': 'MKT',
  'Organization': 'ORG',
  'Person': 'PER',
  'Product': 'PRD',
  'Strategy': 'STR',
  'System': 'SYS'
};

// Organizations
relationshipsByEntityType['ORG'] = {
  'Actor': 'ACT',
  'Capability': 'CAP',
  'Connection': 'CON',
  'Data': 'DAT',
  'Demand': 'DEM',
  'Deployment': 'PHY',
  'Market': 'MKT',
  'Organization': 'ORG',
  'Product': 'PRD',
  'Strategy': 'STR',
  'System': 'SYS',
  'Technology': 'TEC'
};

// People
relationshipsByEntityType['PER'] = {
  'Capability': 'CAP',
  'Company': 'COM',
  'Connection': 'CON',
  'Data': 'DAT',
  'Demand': 'DEM',
  'Deployment': 'PHY',
  'Market': 'MKT',
  'Product': 'PRD',
  'Standard': 'STA',
  'Strategy': 'STR',
  'System': 'SYS',
  'Technology': 'TEC'
};

// Products
relationshipsByEntityType['PRD'] = {
  'Actor': 'ACT',
  'Capability': 'CAP',
  'Company': 'COM',
  'Connection': 'CON',
  'Data': 'DAT',
  'Demand': 'DEM',
  'Deployment': 'PHY',
  'Market': 'MKT',
  'Organization': 'ORG',
  'Person': 'PER',
  'Product': 'PRD',
  'Skill': 'SKI',
  'Standard': 'STA',
  'Strategy': 'STR',
  'System': 'SYS',
  'Technology': 'TEC'
};

// Skills
relationshipsByEntityType['SKI'] = {
  'Capability': 'CAP',
  'Demand': 'DEM',
  'Deployment': 'PHY',
  'Product': 'PRD',
  'Standard': 'STA',
  'Strategy': 'STR',
  'System': 'SYS',
  'Technology': 'TEC'
};

// Standards
relationshipsByEntityType['STA'] = {
  'Capability': 'CAP',
  'Company': 'COM',
  'Connection': 'CON',
  'Demand': 'DEM',
  'Deployment': 'PHY',
  'Person': 'PER',
  'Product': 'PRD',
  'Skill': 'SKI',
  'Standard': 'STA',
  'Strategy': 'STR',
  'System': 'SYS',
  'Technology': 'TEC'
};

// Strategies
relationshipsByEntityType['STR'] = {
  'Actor': 'ACT',
  'Capability': 'CAP',
  'Company': 'COM',
  'Connection': 'CON',
  'Data': 'DAT',
  'Demand': 'DEM',
  'Deployment': 'PHY',
  'Market': 'MKT',
  'Organization': 'ORG',
  'Person': 'PER',
  'Product': 'PRD',
  'Skill': 'SKI',
  'Standard': 'STA',
  'Strategy': 'STR',
  'System': 'SYS',
  'Technology': 'TEC'
};

// Systems
relationshipsByEntityType['SYS'] = {
  'Actor': 'ACT',
  'Capability': 'CAP',
  'Company': 'COM',
  'Connection': 'CON',
  'Data': 'DAT',
  'Demand': 'DEM',
  'Deployment': 'PHY',
  'Market': 'MKT',
  'Organization': 'ORG',
  'Person': 'PER',
  'Product': 'PRD',
  'Skill': 'SKI',
  'Standard': 'STA',
  'Strategy': 'STR',
  'System': 'SYS',
  'Technology': 'TEC'
};

// Technologies
relationshipsByEntityType['TEC'] = {
  'Capability': 'CAP',
  'Company': 'COM',
  'Demand': 'DEM',
  'Deployment': 'PHY',
  'Organization': 'ORG',
  'Person': 'PER',
  'Product': 'PRD',
  'Skill': 'SKI',
  'Standard': 'STA',
  'Strategy': 'STR',
  'System': 'SYS',
  'Technology': 'TEC'
};