angular
  .module('barometerApp.relationalDiagram')
  .factory('relationalDiagramGraphDataService', relationalDiagramGraphDataService);

relationalDiagramGraphDataService.$inject = [
  '$http',
  '$q',
  '$rootScope',
  'graphService',
  'workspaceService'
];

/**
 * Mostly if not all about GRAPH data from Neo4j.
 */
function relationalDiagramGraphDataService($http,
                                           $q,
                                           $rootScope,
                                           graphService,
                                           workspaceService) {

  const entityTypes = ['01', '0Q', '2F', '07', '44', '39', '0Y', '0T', '4L', '0Z', '15', '16', '0G', '18', '1H'];

  //----------------------------------
  // PRIVATE FUNCTIONS
  //----------------------------------

  /**
   * Add dissociated nodes that were not included in any paths.
   */
  function addDisassociatedNodes(entitiesInWorksheet, tempGraph) {
    //
    _.each(entitiesInWorksheet, function (entity) {
      if (!tempGraph.nodes.hasOwnProperty(entity.bn)) {
        tempGraph.nodes[entity.bn] = {name: entity.name, bn: entity.bn, pathType: 'none'};
      }
    });
  }
  /**
   * Adds the node or edge if it isn't already present.
   */
  function addNodeOrEdge(entitiesInWorksheet, nodeOrEdge, tempGraph) {
    // Nodes...
    if (nodeOrEdge.hasOwnProperty('bn')) {
      if (!tempGraph.nodes.hasOwnProperty(nodeOrEdge.bn)) {
        // If the entity is in the worksheet, make sure the node is marked 'direct'.
        // This handles the case where the only edges to that node are rolled-up.
        if (nodeOrEdge.pathType != 'direct' && !!_.find(entitiesInWorksheet, {bn: nodeOrEdge.bn})) {
          nodeOrEdge.pathType = 'direct';
        }
        tempGraph.nodes[nodeOrEdge.bn] = nodeOrEdge;
      }
    }
    // Edges...
    else {
      if (!tempGraph.edges.hasOwnProperty(getUniqueEdgeKey(nodeOrEdge))) {
        tempGraph.edges[getUniqueEdgeKey(nodeOrEdge)] = nodeOrEdge;
      }
    }
  }

  /**
   *
   */
  function processPathSets(pathSets, entitiesInWorksheet, tempGraph) {
    //
    _.each(pathSets, function (paths, pathType) {
      _.each(paths, function (path) {
        _.each(path, function (nodeOrEdge) {
          nodeOrEdge.pathType = pathType;
          addNodeOrEdge(entitiesInWorksheet, nodeOrEdge, tempGraph);
        });
      });
    });
  }

  /**
   *
   */
  function getUniqueEdgeKey(edge) {
    edge.qualifier = edge.qualifier != undefined ? edge.qualifier : edge.relationshipType;
    return edge.fromBn + edge.toBn + edge.qualifier + edge.relationshipType + edge.direction
  }

  //----------------------------------
  // PUBLIC FUNCTIONS
  //----------------------------------

  /**
   * Export my public functions.
   */
  return {
    getAssociatedEntities: getAssociatedEntities,
    getGraphDataForWorkspaceBn: getGraphDataForWorkspaceBn,
    requestWindowResize: requestWindowResize
  };

  /**
   *
   */
  function getAssociatedEntities(bn) {
    var deferred = $q.defer();
    graphService.getAssociatedEntities(bn, entityTypes)
      .then(function (graphData) {
        deferred.resolve(graphData);
      });
    return deferred.promise;
  }

  /**
   *
   * @param workspaceBn
   * @param isShowRolledUp
   */
  function getGraphDataForWorkspaceBn(workspaceBn, isShowRolledUp) {
    //
    console.log("getGraphDataForWorkspaceBn: isShowRolledUp=" + isShowRolledUp);
    //
    var deferred = $q.defer();
    workspaceService.getAssociated(workspaceBn, 99999)
      .then(function (data) {
        var entitiesInWorksheet = [];
        _.each(data.data.results, function (entityGroup) {
          entitiesInWorksheet = entitiesInWorksheet.concat(entityGroup.entityTypeResults);
        });
        if (entitiesInWorksheet.length == 0) {
          deferred.resolve({nodes: [], edges: []});
        } else {
          graphService.getPaths(_.map(entitiesInWorksheet, 'bn'), isShowRolledUp)
            .then(function (pathSets) {
              // Temporary maps for de-duplication
              var tempGraph = {
                nodes: {},
                edges: {}
              };
              // The service returns four sets of paths:
              // 1. direct: Direct paths between member entities
              // 2. directViaConnection: Direct paths between member entities, mediated by a Connection
              // 3. rolledUp: Paths between ancestors of member entities
              // 4. rolledUpViaConnections: Paths between ancestors of member entities, mediates by a Connection
              processPathSets(pathSets, entitiesInWorksheet, tempGraph);
              addDisassociatedNodes(entitiesInWorksheet, tempGraph);
              // Return arrays from temporary maps
              deferred.resolve({
                nodes: _.values(tempGraph.nodes),
                edges: _.values(tempGraph.edges)
              });
            }, function (err) {
              deferred.reject(err);
              deferred.resolve(err);
            });
        }
      });
    return deferred.promise;
  }

  /**
   *
   */
  function requestWindowResize() {
    $rootScope.$broadcast('cyResizeRequest');
  }
}