angular.module('barometerApp.layout')
  .directive('contentBlock',
    function ($compile, urlService, layoutService, utilService) {
      return {
        scope: {
          waitToLoad: "@",
          pageSectionBn: "=", // the content block may live inside a summary that lives inside a page section, we need to know both the summary section and the page section
          sectionBn: "=",
          block: "=",
          assocCode: "=",
          filter: '=',
          layoutModel: "=",
          isEditingLayout: "="
        },
        replace: true,
        link: function (scope, element, attrs) {

          scope.filterModel = {
            filter: scope.filter || {}
          };
          scope.blockModel = {};
          scope.blockModel.loaded = false;
          scope.contentBlock = scope.block;

          scope.getContent = function () {

            // Worksheet template expects scope.worksheetBn to be defined
            // Worksheet content blocks store the worksheetBn as configuration.fieldBn
            if (scope.contentBlock.configuration.contentBlockType === "WORKSHEET") {
              scope.worksheetBn = scope.contentBlock.configuration.fieldBn;
            }

            // For any non-standard templates, directives, etc, we are going to look at the TypeToTemplate object for the Content Block Type (CBT)
            // For any "standard templates", this follows a specific naming convention for the (CBT)
            // The first "segment" (before the first underscore (_) is the folder the template lives in
            // The file name is the CBT, replacing _'s with -'s

            // e.g. CBT = COMPANY_SYSTEM
            // cbtFolder = "company"
            // cbtFile = "company-system"
            var cbtFolder = scope.block.configuration.contentBlockType.split("_")[0].toLowerCase(),
              cbtFile = scope.block.configuration.contentBlockType.toLowerCase(),
              template = layoutService.CONTENT_BLOCK_TEMPLATES[scope.block.configuration.contentBlockType];
            cbtFile = cbtFile.replace(/_/g, "-");
            if (template == null) {
              template = "<div ng-include=\"'/b/js/src/bit.ng/entity/partials/" + cbtFolder + "/" + cbtFile + ".html\'\">";
            }
            element.template = template;
            element.html(template).show();
            $compile(element.contents())(scope);
          };

          scope.getEntityPageBn = function () {
            return urlService.getEntityBn();
          };

          scope.getEntityPageType = function() {
            var bn = urlService.getEntityBn();
            return utilService.getEntityTypeCode(bn);
          };

          scope.$on('renderContentBlock', function (event, configurationBn) {
            if (configurationBn == scope.block.configuration.bn) {
              scope.getContent();
            }
          });

          scope.getContent();
        }
      }
    });
