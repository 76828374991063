angular.module("barometerApp.domainFilter")
/**
 * Filter lists of entities by Domain.
 *
 * 1. table-directives populates filterService (filterData).
 * 2. filterService (filterData) populates our selection model (scope.domainModel).
 * 3. When an option is selected domainModel and filterService are updated.
 * 4. filterService broadcasts a domainFilterChanged event.
 * 5. table-directives listens for domainFilterChanged.
 */
  .directive('domainFilter', ['domainFilterService', 'searchService', 'urlService', function (domainFilterService, searchService, urlService) {
    return {
      scope: {
        sectionBn: '=',
        sectionIconClass: '@'
      },
      templateUrl: "/b/js/src/bit.ng/domain-filter/partials/domain-filter.html",
      link: function (scope, element, attrs) {

        // SCOPED MODELS -----------------------------------------------------------------------------------------------

        scope.domainModel = {
          selected: [],
          filter: {},
          init: false
        };

        // SCOPED FUNCTIONS --------------------------------------------------------------------------------------------

        scope.noneSelected = function() {
          return !scope.domainModel.selected || scope.domainModel.selected.length === 0;
        };

        scope.allClicked = function() {
          domainFilterService.setSelected(scope.sectionBn, []);
        };

        scope.isSelected = function (option) {
          return !!_.find(scope.domainModel.selected, function (selectedBn) {
            return (option.name === selectedBn || option.value === selectedBn);
          })
        };

        scope.optionClicked = function (option) {
          if (!option) console.warn("WARN: Domain option is undefined.");
          var selected = scope.domainModel.selected.slice(0);
          // If option is already in the list, it should be removed!
          if(scope.isSelected(option)){
            selected = _.filter(selected, function(domainBn){
              return domainBn !== option.value;
            });
          }

          // Otherwise add it
          else {
            selected.push(option.value);
          }
          domainFilterService.setSelected(scope.sectionBn, selected);
        };

        scope.getSelectedOptionName = function (selectedBn) {
          var option = _.find(scope.domainModel.filterSpec.options, function (option) {
            return option.value === selectedBn;
          });
          return option.name;
        };

        // FUNCTIONS ---------------------------------------------------------------------------------------------------

        function getFilterFromService() {
          var domainFilter = domainFilterService.getDomainFilter(scope.sectionBn);
          if (domainFilter) {
            return domainFilter;
          }
          return null;
        }

        /**
         * Initially populated from domainFilterSpec in table-directives.js.
         */
        function updateSelectedFromService() {
          var filter = getFilterFromService();
          if (filter) {
            scope.domainModel.selected = filter.selected;
          }
        }

        /**
         * 1. table-directives populates filterService (filterData).
         * 2. filterService (filterData) populates our selection model (scope.domainModel).
         */
        function init() {
          var filterData = getFilterFromService();
          if (!filterData) return;

          scope.domainModel.selected = filterData.selected;
          scope.domainModel.filterSpec = filterData.filterSpec;
          scope.domainModel.entityTypeCode = filterData.entityTypeCode;
          scope.domainModel.dataListBn = filterData.dataListBn;

          // Add counts, if not a pivot
          var isNotPivot = urlService.getQueryKey() === null;
          var sourceEntityTypeCode = urlService.getEntityTypeCodeOfCurrentPage();
          var destinationEntityTypeCode = scope.domainModel.entityTypeCode;
          var queryKey = urlService.getQueryKey();
          var entityBn = urlService.getEntityBn();

          if (!scope.domainModel.filterSpec.filterBn) console.warn("WARN: filterSpec.filterBn is undefined for filterSpec:", scope.domainModel.filterSpec);
          if (!scope.domainModel.filterSpec.options) console.warn("WARN: filterSpec.options is undefined for filterSpec:", scope.domainModel.filterSpec);

          if(isNotPivot) {
            searchService.getDomainFilterCounts(scope.domainModel.filterSpec.filterBn, scope.domainModel.dataListBn, entityBn, sourceEntityTypeCode, destinationEntityTypeCode, queryKey).then(function(data) {
              scope.domainModel.filterSpec.options.forEach(function (option) {
                var row = _.find(data.data.rows, {'bn': option.value});
                option.count = row ? row.count : 0;
              });
              scope.init = true;
            });
          } else {
            searchService.getDomainFilterCounts(scope.domainModel.filterSpec.filterBn, null, entityBn, sourceEntityTypeCode, destinationEntityTypeCode, queryKey).then(function(data) {
              scope.domainModel.filterSpec.options.forEach(function (option) {
                var row = _.find(data.data.rows, {'bn': option.value});
                option.count = row ? row.count : 0;
              });
              scope.init = true;
            });
          }

          // If there's only one domain filter option, make it selected (all entities must have a domain -- this is equivalent to "All")
          if (scope.domainModel.filterSpec.options.length === 1){
            scope.domainModel.selected = [scope.domainModel.filterSpec.options[0].value];
          }
        }

        // SCOPED HANDLERS ---------------------------------------------------------------------------------------------

        // this is fired when a filter bar first sets the domain filter state, and should only fire once per section
        scope.$on('domainFilterSet', function (event, tableId) {
          if (tableId === scope.sectionBn) {
            init();
          }
        });

        // this is fired whenever a new domain is selected
        scope.$on('domainFilterChanged', function (event, tableId) {
          if (tableId === scope.sectionBn) {
            updateSelectedFromService();
          }
        });

        // Ensure domain filter loads when navigating back and forth between page sections
        scope.$on('loadSection', function (event, sectionBn, forceReload) {
          if (scope.sectionBn && scope.sectionBn === sectionBn) {
            init();
          }
        });
      }
    }
  }]);
