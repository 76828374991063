angular
  .module('barometerApp.relationalDiagram')
  .controller('RelationalDiagramChangeGroupNameController', RelationalDiagramChangeGroupNameController);

RelationalDiagramChangeGroupNameController.inject = [
  '$modalInstance',
  '$rootScope',
  '$scope',
  'config',
  'id'
];

/**
 * This is a modal popped from relational-diagram-sidebar-controller.
 */
function RelationalDiagramChangeGroupNameController($modalInstance,
                                                    $rootScope,
                                                    $scope,
                                                    config,
                                                    id) {

  $scope.tempConfig = _.cloneDeep(config);
  $scope.selectedGroup = {};
  
  //--------------------------------------
  // PUBLIC FUNCTIONS
  //--------------------------------------
  
  $scope.cancel = function () {
    $modalInstance.dismiss('cancel');
  };
  
  $scope.save = function () {
    $scope.selectedGroup.data.entity.name = $scope.selectedGroup.data.id;
    $modalInstance.close($scope.selectedGroup);
  };

  //--------------------------------------
  // DO IT!
  //--------------------------------------

  if (id) {
    for (var g in $scope.tempConfig.groups) {
      if ($scope.tempConfig.groups.hasOwnProperty(g)) {
        if ($scope.tempConfig.groups[g].data.id == id) {
          $scope.selectedGroup = $scope.tempConfig.groups[g];
        }
      }
    }
  }
}