angular.module('barometerApp.common')
  .directive('boxDiagram', ['$rootScope', 'boxDiagramService', 'urlService', 'utilService', 'heatMap', '$q', '$timeout', 'alertService',
    function ($rootScope, boxDiagramService, urlService, utilService, heatMap, $q, $timeout, alertService) {
      return {
        link: function (scope, element, attrs) {

          var nodeCount = 0;

          // Optionally set the activeEntityBn via HTML attribute
          // Otherwise, it will pull the bn from the current URL
          var activeEntityBn;
          if (attrs['entityBn']) {
            activeEntityBn = attrs['entityBn'];
          } else {
            activeEntityBn = urlService.getEntityBn();
          }

          // Optionally set a heatMap dataSourceId (definition of which is TODO)
          // For populating boxes with optional layer of heat/color data
          var heatMapDataSourceId;
          if (attrs['heatMapDataSourceId']) {
            heatMapDataSourceId = attrs['heatMapDataSourceId'];
          }

          // Start with the diagram hidden in a way that lets contents have display height (for setChildHeights())
          element.css({ visibility: "hidden", position: "absolute" });

          function getHtmlForNode(node){
            var classes = getCssClasses(node);
            var boxHtml =
              $('<div class="box" data-bn="' + node.bn + '">'
                + '<label class="' + classes.bigIcon + '">'
                  + '<span style="background-color:' + node.iconBgColor + '" class="' + classes.coreOrApproved  + ' ' + classes.smallIcon + ' ' + classes.colored + ' ' + classes.iconSystem + '"></span>'
                  + '<a href="' + urlService.getUrlForBnCode(node.bn, utilService.getBnCode(node.bn)) + '">' + node.name + '</a>'
                  + (node.type ? '<span class="type">' + node.type + '</span>' : '')
                + '</label>'
              + '</div>');

            if (node.heatColor) {
              boxHtml.addClass('box-heat');
              boxHtml.css({background: node.heatColor});
              boxHtml.attr('data-heat-value', node.heatValue);
            }

            if (node.bn.toLowerCase() == activeEntityBn.toLowerCase()) {
              boxHtml.addClass('active');
            }

            return boxHtml;
          }

          // Piece together a DOM hierarchy based on the data
          function assembleHtmlHierarchy(nodes, rootNode) {
            var current, node, parentHtml, nodeHtml, childNode;
            var diagramHtml = $('<div class="box-diagram"></div>');
            var toRender = [{
              node: rootNode,
              parentHtml: diagramHtml
            }];

            // Iterate with queue rather than recur
            // This avoids memory issues on older browsers
            while (toRender.length){
              current = toRender.shift();
              node = current.node;
              parentHtml = current.parentHtml;

              // Build html for this node and append to parentHtml
              nodeHtml = getHtmlForNode(node);
              parentHtml.append(nodeHtml);

              // Add children to queue
              _.each(node.childBns, function(childBn){
                childNode = _.find(nodes, {bn: childBn});
                toRender.push({
                  node: childNode,
                  parentHtml: nodeHtml
                });
              });
            }

            // Collapse everything and add counts
            collapseChildren(diagramHtml.find('> .box'));

            // Find the active one, open it up
            var activeBox = diagramHtml.find('.active');
            activeBox.show();

            // Show children of active
            if (activeBox.find('> .box').length > 0) {
              activeBox.find('> .box').show();
              activeBox.addClass('expanded');
            }
            activeBox.find('.box').addClass('child-of-active');

            // Open ancestors, set up siblings
            activeBox.parentsUntil('.box-diagram').each(function () {
              $(this).addClass('expanded').show();
            });

            // Need to append the diagram before heights can be calc'd
            element.append(diagramHtml);
            setChildHeight(activeBox.find('> .box'));
          }

          function getCssClasses(currentNode) {
            var classes = {
              bigIcon: '',
              smallIcon: '',
              iconSystem: '',
              coreOrApproved: '',
              colored: ''
            };

            // Handle big and small icon classes
            // 'icon-system' unique to systems, there is no 'icon-demand' or 'icon-capability', for example
            if (scope.sectionBn == 'SYS') {
              classes.bigIcon = 'big-icon';
              classes.iconSystem = 'icon-system';
            } else {
              classes.smallIcon = 'icon-small';
            }

            // Icon star, based on coreOrApproved
            if (!currentNode.coreOrApproved) {
              classes.coreOrApproved = 'icon-star-inactive';
            } else {
              classes.coreOrApproved = 'icon-star-active';
            }

            // Icon's background color
            if (currentNode.iconBgColor != '#') {
              classes.colored = 'icon-colored';
            }

            return classes;
          }

          // Normalizes height of a set of children
          function setChildHeight(children) {
            children = children.not('.expanded');
            var childHeight = 0, maxBoxHeight = 0;
            children.each(function (index, child) {
              var $child = $(child);
              childHeight = $child.outerHeight();
              maxBoxHeight = childHeight > maxBoxHeight ? childHeight : maxBoxHeight;
            });
            if (maxBoxHeight != 0) {
              children.css('height', maxBoxHeight);
            }
          }

          function siblingsOrCountClicked(event) {
            var parentHtml = $(event.target).parent();
            var children = parentHtml.children('.box');
            parentHtml.addClass('expanded');
            setChildHeight(children);
            children.show('fast');
            parentHtml.find('> .count, > .siblings').hide();
            event.stopPropagation();
          }

          function collapseClicked(event) {
            var parentHtml = $(event.target).parent();

            // hide all children, show childCount
            parentHtml.find('> .box').hide('fast');
            parentHtml.find('> .count').show();
            parentHtml.removeClass('expanded');

            event.stopPropagation();
          }

          function handleMouseEnter(event) {
            event.stopPropagation();
            var elem = $(event.target);
            elem.closest('.box-diagram').find(".box.hover").removeClass("hover");
            elem.addClass("hover");
          }

          function handleMouseLeave(event) {
            event.stopPropagation();
            var elem = $(event.target);
            elem.removeClass("hover");
            elem.find('.box').removeClass('hover');
            elem.parent(".box").addClass("hover");
          }

          // recursive collapser / counter-upper
          function collapseChildren(parentHtml) {
            var children = parentHtml.children('.box');
            if (children.length > 0) {

              // hide the children behind a count
              children.hide();

              // child count
              var count = $('<div class="count">' + children.length + '</div>');
              parentHtml.append(count);

              // sibling count (if this is in the active branch)
              // exclude active boxes and boxes that contain active boxes
              var nonActiveChildren = children.filter(function(){
                return !$(this).hasClass('active') && $(this).find('.active').length == 0;
              });
              if (nonActiveChildren.length > 1) {
                var siblingCount = $('<div class="siblings">' + (children.length - 1) + ' Others...</div>');
                parentHtml.append(siblingCount);
              }

              // both count behaviors are the same
              count.add(siblingCount);

              // add collapse control
              parentHtml.append($('<div class="collapse"></div>'));

              // keep going down the tree
              children.each(function () {
                collapseChildren($(this));
              });
            }
          }

          function init() {
            boxDiagramService.getBoxData(activeEntityBn).then(function (data) {
              var allNodes = data.data;
              nodeCount = allNodes.length;
              var rootNodes = _.filter(allNodes, {root: true});

              // Get heat data if relevant
              var heatDataRequest = $q.defer();
              if (heatMapDataSourceId) {
                heatMap.getColors(_.map(allNodes, 'bn'), attrs['heatMapDataSourceId']).then(function (heatData) {
                  _.each(allNodes, function (node) {
                    var heatDatum = _.find(heatData, {bn: node.bn});
                    node.heatColor = heatDatum.color;
                    node.heatValue = heatDatum.value;
                  });
                  heatDataRequest.resolve();
                });
              } else {
                heatDataRequest.resolve();
              }

              heatDataRequest.promise.then(function () {

                // Generate all html, starting with the roots
                _.each(rootNodes, function (rootNode) {
                  assembleHtmlHierarchy(allNodes, rootNode);
                });

                // Events are attached at the widget's root element
                element
                  .on('mouseenter', '.box', handleMouseEnter)
                  .on('mouseleave', '.box', handleMouseLeave)
                  .on('click', '.collapse', collapseClicked)
                  .on('click', '.siblings, .count', siblingsOrCountClicked);

                element.css({ visibility: 'visible', position: 'static' });
              });
            });
          }

          // Reload boxes after editing
          // Arbitrary half-second delay increases the chances that re-indexing is complete
          // Includes messaging to warn of potential delays.
          scope.$on('loadSection', function (event, sectionBn, forceReload) {
            if (forceReload && sectionBn == scope.sectionBn){
              element.empty();
              alertService.addAlert({type: 'success', message: 'Your changes have been submitted.'});
              if (nodeCount > 20){
                alertService.addAlert({type: 'warn', message: 'Changes to complex architecture may take some time to complete. <br>Your changes may not be visible right away.'});
              }
              $timeout(function(){
                init();
              }, 500);
            }
          });

          init();
        }
      }
    }]);