angular
  .module('barometerApp.relationalDiagram')
  .controller('RelationalDiagramSubheaderController', RelationalDiagramSubheaderController);

RelationalDiagramSubheaderController.inject = [
  '$modal',
  '$rootScope',
  '$scope',
  '$timeout',
  'entityService',
  'optionListService',
  'rdConfigModel',
  'tableService',
  'urlService',
  'utilService',
  'worksheetSubheaderState',
  'workspaceService'
];

/**
 *
 */
function RelationalDiagramSubheaderController($modal,
                                              $rootScope,
                                              $scope,
                                              $timeout,
                                              entityService,
                                              optionListService,
                                              rdConfigModel,
                                              tableService,
                                              urlService,
                                              utilService,
                                              worksheetSubheaderState,
                                              workspaceService) {

  $scope.showNodeSave = false;
  $scope.showLayoutSave = false;
  $scope.displayData = null;
  $scope.includeEntityTypes = ['ACT', 'CAP', 'COM', 'CON', 'DAT', 'DEM', 'ORG', 'MKT', 'PHY', 'PRD', 'SKI', 'STA', 'STR', 'SYS', 'TEC'];
  $scope.layoutModeModel = {value: null};
  $scope.layoutNameModel = {
    layoutOptions: [
      {value: 'COLA', label: 'Force'},
      {value: 'CIRCLE', label: 'Circle'},
      {value: 'CONCENTRIC', label: 'Concentric'},
      {value: 'GRID', label: 'Grid'},
      {value: 'BREADTH_FIRST', label: 'Tree'}
    ]
  };
  $scope.layoutNameModel.DISPLAYED_ENTITIES_OPTIONS = [{
    name: "Direct",
    value: false
  }, {
    name: "Rolled Up",
    value: true
  }];
  $scope.layoutNameModel.SHOW_LIFECYCLE_OPTIONS = [{
    name: "True",
    value: true
  }, {
    name: "False",
    value: false
  }];

  $scope.layoutNameModel.rolledUp = $scope.layoutNameModel.DISPLAYED_ENTITIES_OPTIONS[1];
  $scope.layoutNameModel.showLifecycle = $scope.layoutNameModel.SHOW_LIFECYCLE_OPTIONS[0];

  // Share active/inactive and mode information via service
  $scope.subheaderState = worksheetSubheaderState;

  //-------------------------------------------------------------------------
  // PRIVATE SCOPE ACCESS
  //-------------------------------------------------------------------------

  function populateConfigFromLayoutModels() {
    //
    var myConfig = rdConfigModel.getConfig();
    if (!myConfig.layout) {
      myConfig.layout = {};
    }
    myConfig.layout.name = $scope.layoutNameModel.selectedLayout.value || null;
    myConfig.layout.mode = $scope.layoutModeModel.value;
    myConfig.showLifecycle = $scope.layoutNameModel.showLifecycle;
    myConfig.rolledUp = $scope.layoutNameModel.rolledUp;
  }

  function populateLayoutModelsFromConfig() {
    //
    var myConfig = rdConfigModel.getConfig();
    $scope.layoutModeModel.value = myConfig.layout.mode;
    $scope.layoutNameModel.layoutOptions.forEach(function (option) {
      if (option.value == myConfig.layout.name) {
        $scope.layoutNameModel.selectedLayout = option;
      }
    });
  }

  function initLifecycleOptions() {
    //
    var myConfig = rdConfigModel.getConfig();
    if (typeof myConfig.showLifecycle != 'undefined') {
      for (var j = 0; j < 2; j++) {
        if ($scope.layoutNameModel.SHOW_LIFECYCLE_OPTIONS[j].value == myConfig.showLifecycle.value) {
          $scope.layoutNameModel.showLifecycle = $scope.layoutNameModel.SHOW_LIFECYCLE_OPTIONS[j];
        }
      }
    }
  }

  function initRollupOptions() {
    //
    var myConfig = rdConfigModel.getConfig();
    if (typeof myConfig.rolledUp != 'undefined') {
      for (var i = 0; i < 2; i++) {
        if ($scope.layoutNameModel.DISPLAYED_ENTITIES_OPTIONS[i].value == myConfig.rolledUp.value) {
          $scope.layoutNameModel.rolledUp = $scope.layoutNameModel.DISPLAYED_ENTITIES_OPTIONS[i];
        }
      }
    }
  }

  /**
   * @param aBoolean true = on, false = off
   */
  function toggleEditMode(aBoolean) {
    //
    if (aBoolean) {
      $scope.subheaderState.mode = 'editingLayout';
    }
    else {
      $scope.subheaderState.mode = 'default';
      setShowLayoutSaveButton(false);
    }
  }

  function isEditMode() {
    return $scope.subheaderState.mode == 'editingLayout';
  }

  function setShowLayoutSaveButton(aBoolean) {
    $scope.showLayoutSave = aBoolean;
    if (aBoolean && isEditMode()) {
      $timeout(function () {
        $scope.showLayoutSave = true;
      });
    }
    else {
      $scope.showLayoutSave = false;
    }
  }

  function getWorksheetBn() {
    return $scope.worksheetBn;
  }

  //-------------------------------------------------------------------------
  // PRIVATE FUNCTIONS
  //-------------------------------------------------------------------------

  /**
   *
   */
  function runLayout() {
    $rootScope.$broadcast('relationalDiagramLayoutRequested',
      rdConfigModel.getLayoutName());
  };

  /**
   *
   */
  function runDisplayLayout() {
    $rootScope.$broadcast('relationalDiagramDisplayedUpdated');
  };

  /**
   *
   */
  function clearSelected() {
    //console.log("clearSelected");
    toggleEditMode(false);
    $scope.displayData = null;
  };

  //-------------------------------------------------------------------------
  // PUBLIC FUNCTIONS (usually means "used from an html partial")
  //-------------------------------------------------------------------------

  /**
   *
   */
  $scope.addRelationalEntity = function ($event, entity) {
    //
    $scope.addEntity($event, entity);
    $scope.hasEntities = true;
  };

  /**
   *
   */
  $scope.editLayout = function () {
    //
    rdConfigModel.backUpConfig();
    toggleEditMode(true);
  };

  /**
   *
   */
  $scope.saveLayoutEdits = function () {
    //
    $rootScope.$broadcast('worksheetConfigSaveRequested');
  };

  /**
   *
   */
  $scope.cancelLayoutEdits = function () {
    //
    toggleEditMode(false);
    rdConfigModel.restoreConfigBackup();
    populateLayoutModelsFromConfig();
    $rootScope.$broadcast('relationalDiagramAutoungrabifyChangeRequested', true);
    $rootScope.$broadcast('relationalDiagramOriginalLayoutRequested');
  };

  /**
   *
   */
  $scope.exportImage = function () {
    $rootScope.$broadcast('relationalDiagramImageDownloadRequested');
  };

  /**
   *
   */
  $scope.displayedEntitiesChanged = function () {
    // Because this function may fire before config is gathered from service.
    if (!rdConfigModel.hasConfig()) return;
    //
    var myConfig = rdConfigModel.getConfig();
    // Save will persist this change. Cancel will back it out.
    rdConfigModel.setShowRollUp($scope.layoutNameModel.rolledUp);
    setShowLayoutSaveButton(true);
    // Go ahead and run the layout. They can always Cancel if they don't like the result.
    runDisplayLayout();
    $rootScope.$broadcast('relationalDiagramAutoungrabifyChangeRequested', myConfig.layout.mode == 'auto');
  };

  /**
   *
   */
  $scope.showLifecycleChanged = function () {
    // Because this function may fire before config is gathered from service.
    if (!rdConfigModel.hasConfig()) return;
    //
    // Save will persist this change. Cancel will back it out.
    rdConfigModel.setShowLifecycle($scope.layoutNameModel.showLifecycle);
    setShowLayoutSaveButton(true);
    runDisplayLayout();
    $rootScope.$broadcast('relationalDiagramAutoungrabifyChangeRequested', rdConfigModel.getLayoutMode() == 'auto');
  };

  /**
   *
   */
  $scope.layoutSettingsChanged = function () {
    // Because this function may fire before config is gathered from service.
    if (!rdConfigModel.hasConfig()) return;
    //
    var myConfig = rdConfigModel.getConfig();
    if (!myConfig.layout) {
      myConfig.layout = {};
    }
    // Update local config from input models.
    myConfig.layout.name = $scope.layoutNameModel.selectedLayout.value || null;
    myConfig.layout.mode = $scope.layoutModeModel.value;
    setShowLayoutSaveButton(true);
    // Go ahead and run the layout - they can always Cancel if they don't like the result
    if (myConfig.layout.mode == 'auto') {
      runLayout();
    }
    $rootScope.$broadcast('relationalDiagramAutoungrabifyChangeRequested', rdConfigModel.getLayoutMode() == 'auto');
  };

  //-------------------------------------------------------------------------
  // EVENT LISTENERS (WATCH)
  //-------------------------------------------------------------------------

  // When in editingLayout mode, allow nodes to be dragged
  $scope.$watch('subheaderState.mode', function (newMode, oldMode) {
    if (isEditMode()) {
      $rootScope.$broadcast('relationalDiagramAutoungrabifyChangeRequested', rdConfigModel.getLayoutMode() == 'auto');
    }
  });

  //-------------------------------------------------------------------------
  // EVENT LISTENERS (BROADCAST)
  //-------------------------------------------------------------------------

  $scope.$on('layout animationstarted', function (event) {
    $scope.layoutNameModel.selectedLayout.isAnimating = true;
  });

  $scope.$on('layoutanimationstopped', function (event) {
    $scope.layoutNameModel.selectedLayout.isAnimating = false;
  });

  $scope.$on('relationalDiagramRemoveEntityOptionAvailable', function (event, data) {
    $scope.displayData = data;
  });

  $scope.$on('worksheetConfigSaveCompleted', function () {
    toggleEditMode(false);
    $rootScope.$broadcast('relationalDiagramAutoungrabifyChangeRequested', true);
  });

  $scope.$on('relationalDiagramBackgroundClicked', function () {
    $scope.displayData = null;
  });

  // Show Save button when a node has been moved
  $scope.$on('relationalDiagramNodeMoved', function (event, data) {
    //
    //console.log('Received event relationalDiagramNodeMoved: Lets show the Save button!');
    //
    setShowLayoutSaveButton(true);
  });

  // TODO I don't see where this event is ever fired in our entire codebase.
  $scope.$on('worksheetAssocRemoved', function () {
    //
    //console.log("Received event: worksheetAssocRemoved");
    //
    clearSelected();
  });

  //-------------------------------------------------------------------------
  // DO IT!
  //-------------------------------------------------------------------------

  workspaceService.getAssociated(getWorksheetBn(), 99999)
    .then(function (data) {
      $scope.hasEntities = data.data.results.length > 0;
    }, function (data) {
      console.error("err ", data);
    });

  rdConfigModel.loadWorksheetForBn(getWorksheetBn())
  // Now rdConfigModel has our worksheetEntity ...
    .then(function (worksheetEntity) {
      initLifecycleOptions();
      initRollupOptions();
      populateLayoutModelsFromConfig();
      $rootScope.$broadcast('relationalDiagramAutoungrabifyChangeRequested', rdConfigModel.getLayoutMode() == 'auto');
    });
}