/**
 * Created by rbiggs on 6/20/16.
 */
angular.module('barometerApp.advancedTable')

  // Unlike editableTable, advancedTable will only be used to display a general table. Specific customization should be done on each instance
  .directive('advancedTable', ['urlService', '$rootScope', 'tableService',
    function (urlService, $rootScope, tableService) {
      return {
        templateUrl: function () {
          return "/b/js/src/bit.ng/table/partials/table-advanced.html";
        },
        scope: {
          tableId: '=',
          innerDirectiveModel: "=",
          rows: '=',
          columns: '=',
          clickFunction: '=',
          checkboxFunction: '=',
          sortFunction: '=',
          tableType: '=',
          paginationData: '=',
          currentPage: '='
        },
        controller: function ($scope, $element, $attrs) {
          var opts = angular.extend({
            displayAdd: false,
            displayFilters: false,
            displayPivot: false
          }, $scope.options || {});

          $scope.tableModel = {
            rows: $scope.rows,
            columns: $scope.columns,
            filter: $scope.filter,
            lazyLoad: typeof $scope.lazyLoad === "undefined" ? true : $scope.lazyLoad,
            queryKey: urlService.getQueryKey(),
            entityBn: urlService.getEntityBn(),
            pagination: $scope.paginationData ? $scope.paginationData : {pageSize: 10, maxSize: 0},
            sort: $scope.sort || {},
            sortClass: [],
            options: opts,
            typeaheadFilter: {},
            superSelectorFilter: {},
            defaultSelect2Options: {
              allowClear: true
            },
            isInitialLoad: true,
            tableType: $scope.tableType,
            tableId: $scope.tableId,
            loading: true,
            activeRow: null,
            clickFunction: $scope.clickFunction,
            checkboxFunction: $scope.checkboxFunction
          };

          $scope.init = function () {
            if ($scope.lazyLoad === "false") {
              $scope.loadPage(1);
            }

            //Create sort icons
            _.each($scope.tableModel.columns, function (col) {
              $scope.tableModel.sortClass[col.sortProperty] = 'icon-sort';
              $scope.tableModel.sort.ascending = false;
              col.ascending = col.id == "entity" || col.ascending;
            })
          };
          $rootScope.$on("loadSection", function (event, sectionBn, forceReload) {
            var matchesSectionBn = $scope.tableId && $scope.tableId.substring(0, sectionBn.length) == sectionBn;
            if (matchesSectionBn && (!$scope.tableModel.loaded || forceReload)) {
              $scope.tableModel.loaded = true;
              $scope.loadPage(1);
            }
          });

          tableService.initTableId({tableId: $scope.tableId, relationshipType: $scope.relationshipType});
          tableService.setSourceBn($scope.tableModel.entityBn);
          tableService.setPagination($scope.tableModel.pagination);

          //TODO handle filter change
          //function filterChanged () {
          //  //TODO
          //};
          //
          //$scope.$on('domainFilterChanged', function (event, tableId) {
          //  //TODO
          //});
          //
          //$scope.$watch('filter.selected', function (newVal, oldVal) {
          //  //TODO
          //}, true);
          //
          //$scope.$watch('filter.qualifier', function (newVal, oldVal) {
          //  //TODO
          //});
          //
          //$scope.$watch('filter.searchString', function (newVal, oldVal) {
          //  //TODO
          //});

          $scope.loadPage = function (pageNumber) {

            //TODO
            $scope.tableModel.currentPage = pageNumber;
          };

          $scope.sortColumn = function (column) {
            column.sorted = true;

            column.ascending = !column.ascending;

            //Set all not selected columns to not ascending
            _.each($scope.tableModel.columns, function (col) {
              if (col.sortProperty !== column.sortProperty) {
                col.ascending = false;
              }
            });

            for (var prop in $scope.tableModel.sortClass) {
              if ($scope.tableModel.sortClass.hasOwnProperty(prop)) {
                $scope.tableModel.sortClass[prop] = 'icon-sort';
              }
            }

            $scope.tableModel.sortClass[column.sortProperty] = column.ascending ? 'icon-sort-up' : 'icon-sort-down';
            $scope.tableModel.sort.ascending = column.ascending;
            $scope.tableModel.sort.sortProperty = column.sortProperty;

            $scope.sortFunction(column);
          };

          $scope.init();
        },
        link: function(scope, element) {
          scope.$watch('columns', function(newVal, oldVal) {
            scope.tableModel.columns = newVal;
          });

          scope.$watch('paginationData', function(newVal, oldVal) {
            scope.tableModel.pagination = newVal ? newVal : {pageSize: 10, maxSize: 0};
          });
          scope.$watch('clickFunction', function(newVal, oldVal) {
            scope.tableModel.clickFunction = newVal;
          });
          scope.$watch('checkboxFunction', function(newVal, oldVal) {
            scope.tableModel.checkboxFunction = newVal;
          });
          scope.$watch('tableType', function(newVal, oldVal) {
            scope.tableModel.tableType = newVal;
          });
          scope.$watch('tableId', function(newVal, oldVal) {
            scope.tableModel.tableId = newVal;
          });
        }
      }
    }])
  .directive('advancedNestedRows', ['$compile', 'urlService', 'utilService', function ($compile, urlService, utilService) {
    return {
      replace: true,
      scope: {
        innerModel: "=",
        rows: "=",
        columns: "=",
        options: "=",
        clickFunction: "=",
        checkboxFunction: "="
      },
      controller: function ($scope) {

        // Utility functions
        var createCheckbox = function (row, column) {
          var rowId = row.entity.id;
          var colId = column.id;
          var returnVal = "";
          var checked = column.checkboxSelected;

          returnVal += '<label class="group-by"><input type="checkbox" ng-click="updateCheckbox($event, \'' + rowId + '\', \'' + colId + '\')"' +
            (checked ? 'checked>' : '>') +
            (column.checkboxLabel ? column.checkboxLabel : '') +
            '</label>';

          return returnVal;
        };

        var createColoredBox = function(row, column) {
          if(row[column.id]) {
            var color = row[column.id].color;
            var value = row[column.id].value;
            var returnVal = '<td class="colored colored-' + color.toLowerCase() + '"><div><span>' + value + '</span></div></td>';
          } else {
            returnVal = '<td class="colored"><div><span></span></div></td>';
          }
          return returnVal
        };

        var createIconElement = function (row, column) {
          // Some columns may not have an entity type
          if (column.entityType) {
            var columnCode = EntityTypes[column.entityType].bnCode;
          }
          var iconCode = columnCode;
          if (iconCode !== "undefined" && !(row[column.id] === "")) {
            var classForEntity = EntityBnCodeToIconClass[iconCode];
            return '<span class="icon-' + classForEntity + '"></span>';
          }

          return "";
        };

        var formatCurrency = function(n, c, d, t) {
          var c = isNaN(c = Math.abs(c)) ? 2 : c,
          d = d == undefined ? "." : d,
          t = t == undefined ? "," : t,
          s = n < 0 ? "-" : "",
          i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "",
          j = (j = i.length) > 3 ? j % 3 : 0;
          return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
        };

        var createAnchorElement = function (row, column) {
          if (row[column.id] !== "") {
            var rowLink = row.entity.linkBn;
            var bnCode = utilService.getBnCode(rowLink);
            var value = row[column.id];
            if(column.displayType && column.displayType.toUpperCase() === "CURRENCY" && typeof value == 'number') {
              //Column has a display format
              switch(column.numberUom) {
                case 'EUR':
                  value = '€' + formatCurrency(value, 2, '.', ',');
                  break;
                case 'USD':
                  value = '$' + formatCurrency(value, 2, '.', ',');
                  break;
                case 'GBP':
                  value = '£' + formatCurrency(value, 2, '.', ',');
                  break;
                case 'AUD':
                  value = '$' + formatCurrency(value, 2, '.', ',');
                  break;
                case 'CAD':
                  value = '$' + formatCurrency(value, 2, '.', ',');
                  break;
              }
            } else if(typeof value == 'number') {
              value = parseFloat(value.toFixed(4))
            } else if(typeof value == 'undefined') {
              value = "";
            }
              var url = urlService.getUrlForBnCode(rowLink, bnCode);
            if (column.createLink) {
              value = '<a href="' + url + '">' + value + '</a>'
            }
            return '<span>' + value + '</span>';
          }
          return "";
        };

        // Scope functions
        $scope.select = function (item, event) {
          if (angular.equals($scope.selected, item)) {
            $scope.selected = null;
            $scope.clickFunction(null, event);
          } else {
            $scope.selected = item;
            $scope.clickFunction(item, event);
          }
        };

        $scope.updateCheckbox = function ($event, rowId, colId) {
          var checkbox = $event.target;
          $scope.checkboxFunction(checkbox.checked, rowId, colId);
        };

        function getRowClass(bn, depth) {
          // See if the row is selected
          if (bn === $scope.selected) {
            if (depth == 0) {
              return 'active';
            }
            else {
              return 'active subtle';  // Nested rows are subtler
            }
          }
          return '';
        }

        // Build table HTML
        $scope.buildTable = function (rows, columns, depth, currentHtml) {
          if(rows.length <= 0) {
            //TODO no data
            currentHtml += '<tr>'+
            '<td colspan="1" class="no-assocs-found">'+
                  '<p>'+
                    'No Results Found'+
                  '</p>'+
            '</td>'+
            '</tr>';
            $scope.html = currentHtml;
            return currentHtml;
          }
          for (var i = 0; i < rows.length; i++) {
            var row = rows[i];
            var rowId = row.entity.id;
            if (depth <= 0) {
              currentHtml += '<tr class="' + getRowClass(rowId, depth) + '" ng-click="select(\'' + rowId + '\', $event);">';
            } else {
              currentHtml += '<tr class="' + getRowClass(rowId, depth) + '">';
            }
            for (var j = 0; j < columns.length; j++) {
              var column = columns[j];
              if (column.display) {
                if (column.renderId == 'CHECKBOX') {
                  currentHtml +=
                    '<td class="checkboxColumn"><div>' +
                    createCheckbox(row, column) +
                    '</div></td>';
                } else if (column.renderId == 'rowSpan') {
                  if (!column.rendered) {
                    var numRows = rows.length;
                    currentHtml +=
                      '<td rowSpan="' + numRows + '"><div><span>' +
                      (column.hasCheckbox ? createCheckbox(row, column) : "" ) +
                      '</span></div></td>';
                    column.rendered = numRows;
                  }

                  // For some reason the watch function is getting called multiple times per change
                  // Therefore we count the number of times the column tries to render so that on consecutive calls
                  // The column gets rendered properly
                  column.rendered--;
                } else if(column.renderId == 'colored') {
                  currentHtml += createColoredBox(row, column);
                } else {
                  currentHtml += '<td><div><span>' +
                    createIconElement(row, column) +
                    createAnchorElement(row, column) +
                    '</span></div></td>';
                }
              }
            }
            currentHtml += '</tr>';
            if (row.children && row.children.rows && row.children.rows.length) {
              var columnsToUse = columns;
              if (row.children.columns) {
                columnsToUse = row.children.columns;
              }
              currentHtml = $scope.buildTable(row.children.rows, columnsToUse, depth + 1, currentHtml);
            }
          }
          $scope.html = currentHtml;
          return currentHtml;
        };

        if($scope.innerModel.rows && $scope.innerModel.columns) {
          $scope.buildTable($scope.innerModel.rows, $scope.innerModel.columns, 0, "");
        }
      },
      link: function (scope, element) {

        // Watch functions
        scope.$watch('innerModel.columns', function (newVal, oldVal) {
          if (!newVal || angular.equals(newVal, oldVal))return;
          scope.innerModel.columns = newVal;
        }, true);

        scope.$watch('innerModel.rows', function (newVal, oldVal) {
          if (!newVal || angular.equals(newVal, oldVal)) {
            return;
          }
          scope.rows = newVal;
          scope.html = "";
          scope.buildTable(scope.innerModel.rows, scope.innerModel.columns, 0, "")
        }, true);

        scope.$watch('html', function (value) {
          if (typeof value === "undefined") {
            return;
          }
          element.html(value);
          $compile(element.contents())(scope);
        }, true);
      }
    };
  }]);