/**
 * Use controllers to:
 * Set up the initial state of a scope object (data, aka the Model, incl. promise functions).
 * Add behavior to the scope object (behavioral functions).
 *
 * Do not use controllers for:
 * Any kind of DOM manipulation.
 * Input formatting.
 * Output filtering.
 * To run stateless or stateful code shared across controllers.
 * To instantiate or manage the life-cycle of other components.
 *
 * You can associate controllers with scope objects implicitly via the ngController directive or $route service.
 *
 * This file tries to follow John Papa's Angular 1 style guide (adjusted to our AngularJS version).
 *
 * https://github.com/johnpapa/angular-styleguide/blob/master/a1/README.md
 */
angular
  .module('barometerApp.watch')
  .controller('BrowseWatchedEntitiesController', BrowseWatchedEntitiesController);

BrowseWatchedEntitiesController.$inject = ['$scope', '$q', 'simpleTableUtilityService', 'browseWatchedUtilityService', 'browseWatchedDataService', 'pinDataService', 'utilService', 'tenantService'];

function BrowseWatchedEntitiesController($scope, $q, simpleTableUtilityService, browseWatchedUtilityService, browseWatchedDataService, pinDataService, utilService, tenantService) {

  /**
   * Standard Search Filter (name, etc.): SIMPLE_TEXT_SEARCH.
   */
  var filter1 = {
    displayName: "Search",
    hide: false,
    id: "filter1", // Use a Filter BN here if it makes sense.
    key: "simpleTextSearchKey",
    searchString: "", // easedInput will push values here.
    type: "SIMPLE_TEXT_SEARCH"
  };
  /**
   * Entity Type Filter: MULTI_SELECT_VALUED.
   */
  var tenantAuth = tenantService.getCurrentTenantsAuthorizedCatalogs();


  var possibleOptions = [ // selections
    {display: "Actor", name: "Actor", value: "ACT"},
    {display: "Capability", name: "Capability", value: "CAP"},
    {display: "Company", name: "Company", value: "COM"},
    {display: "Connection", name: "Connection", value: "CON"},
    {display: "Data", name: "Data", value: "DAT"},
    {display: "Demand", name: "Demand", value: "DEM"},
    {display: "Deployment", name: "Deployment", value: "PHY"},
    {display: "Market", name: "Market", value: "MKT"},
    {display: "Organization", name: "Organization", value: "ORG"},
    {display: "Person", name: "Person", value: "PER"},
    {display: "Product", name: "Product", value: "PRD"},
    {display: "Skill", name: "Skill", value: "SKI"},
    {display: "Standard", name: "Standard", value: "STA"},
    {display: "Strategy", name: "Strategy", value: "STR"},
    {display: "System", name: "System", value: "SYS"},
    {display: "Technology", name: "Technology", value: "TEC"}
  ];

  possibleOptions = possibleOptions.filter(option => tenantAuth.some(auth => auth.entityTypeCode === option.value));

  var filter2 = {
    displayName: "Catalogs",
    hide: false,
    id: "filter2", // Use a Filter BN here if it makes sense.
    isSingleSelect: false,
    key: "entityType",
    options: possibleOptions,
    selected: [],
    type: "MULTI_SELECT_VALUED"
  };

  /**
   * My state model.
   */
  $scope.watchedByBn = utilService.getCurrentUserBn();
  // Sub-components expect a tableModel.
  $scope.tableModel = {
    entityBn: $scope.watchedByBn, // TODO: Do I need this?
    // Defaults.
    page: {currentPage: 1},
    sort: {sortProperty: "name", sortOrder: "ASC"}
  };
  // Sub-components expect a filterModel.
  $scope.filterModel = {
    filters: [
      filter1,
      filter2
    ]
  };

  /**
   Example POST:
   {
       "perspectiveBns" : ["050E0000003V"],
       "relatedTypes" : ["TSK"],
       "relatedProperties" : ["bn", "name", "description", "createDate", "040K0000013B"],
       "sortBy" : {"fieldName" : "createDate", "sortOrder" : "DESC"},
       "filterBy" : {
           "relationshipTypes" : ["ASSIGNED_BY"],
           "fieldValues" : [{"fieldBn" : "040K0000013B", "value" : "Open"}],
           "propertyFilters" : [{"propertyName" : "description", "value" : "foo"}]
       },
       "expandRelationships" : false,
       "firstRecord" : 1,
       "numberToReturn" : 10
   }
   */
  var myRequestModel = {
    //
    perspectiveBns: [],
    relatedProperties: ["bn", "name"],
    relatedTypes: possibleOptions.map(option => option.value),
    filterBy: {
      relationshipTypes: ["IS_WATCHING"],
      propertyFilters: []
    },
    expandRelationships: false,
    firstRecord: -1,
    numberToReturn: 10,
    sortBy: {
      fieldName: 'name',
      sortOrder: 'ASC'
    }
  };

  /**
   * Load data into the table model.
   */
  $scope.updateTableModel = function () {
    //
    var myPage = $scope.tableModel.page.currentPage;
    var mySearchString = $scope.filterModel.filters[0].searchString;
    var myOptions = $scope.filterModel.filters[1].options;
    var mySelections = $scope.filterModel.filters[1].selected;
    //
    myRequestModel.perspectiveBns[0] = $scope.watchedByBn;
    myRequestModel.firstRecord = (10 * (myPage - 1)) + 1;
    myRequestModel.sortBy.fieldName = $scope.tableModel.sort.sortProperty;
    myRequestModel.sortBy.sortOrder = $scope.tableModel.sort.sortOrder;
    //
    browseWatchedUtilityService.applyFilter1(myRequestModel, mySearchString);
    browseWatchedUtilityService.applyFilter2(myRequestModel, myOptions, mySelections);
    //
    browseWatchedDataService
      .getData(myRequestModel)
      .then(function (data) {
        var newModel = simpleTableUtilityService.getTableModel(myPage, data);
        if ($scope.tableModel.page.currentPage == myPage) {
          // This attempts to ensure if pages are selected very quickly
          // that only the last requested page will be displayed.
          $scope.tableModel = newModel;
        }
      }, function (err) {
        console.error("Error getting report data ", err);
      });
  }

  /**
   * Configure $scope.filterModel.
   */
  $scope.updateFilterModel = function () {
    //
    // A no-op, cuz we're not reading our filter-specs from back-end.
    // We already put our filterModel in scope, above.
  }

  /**
   * TODO This is probably a dopey way to do this.
   */
  $scope.$watch('filterModel.filters[0].searchString', function (newVal, oldVal) {
    //
    $scope.updateTableModel();
  });

  $scope.unPin = function($event, targetBn) {
    //
    var personBn = $scope.watchedByBn;
    pinDataService.removePin(personBn, targetBn);
    // TODO Need to update table model, but only after pin is guaranteed removed.
    var unpinnedRow = $scope.tableModel.rows.find(function( row ) {
      return row.bn == targetBn;
    });
    unpinnedRow.action = 'unpinned';
    $scope.updateTableModel();
  }
}
