angular
  .module('barometerApp.tasks')
  .directive('taskCreateLink', taskCreateLink);

taskCreateLink.$inject = ['tasksDataService', 'optionListService', '$modal', 'utilService', 'adminService', 'alertService', 'entityService', '$rootScope', '$timeout', 'graphService', 'associatedBnCacheService', '$q', 'tasksService', 'redux', 'complianceDataService', 'relationshipDataService'];

/**
 * @author Tim Cramer
 */
function taskCreateLink(tasksDataService, optionListService, $modal, utilService, adminService, alertService, entityService, $rootScope, $timeout, graphService, associatedBnCacheService, $q, tasksService, redux, complianceDataService, relationshipDataService) {

  return {
    //
    templateUrl: '/b/js/src/bit.ng/tasks/partials/task-create-link.html',
    restrict: 'AE',
    scope: {
      first: "=",
      hiddenTaskCount: "=",
      hasMoreTasks: "=",
      tasks: '=',
      myOpenTasksActive: '=',
      prepopulatedRelevantEntityBn: '=',
      prepopulatedDescription: '='
    },
    link: function (scope, element, attrs) {
      scope.entityCreateModel = {
        description: scope.prepopulatedDescription || null,
        taskStatusBn: null
      };
      scope.statusOptionsModel = [];
      scope.quickCreateModel = {
        modal: null
      };
      // scope.reportEntityPicker = {
      //   remediatedBn: null,
      //   modal: null,
      //   selectedEntities: []
      // };
      scope.formBuilder = {
        modal: null,
        form: null
      };
      scope.includeEntityTypes = ['PER'];
      scope.assignee = null;
      scope.requester = null;
      scope.relevantEntities =  [];
      // scope.relevantEntityIsReportRule = false;
      // scope.reportRuleEntities = ['ADQ', 'AUD', 'CRL'];
      scope.remediatedEntities = [];
      scope.showEntityAdd = false;
      scope.includeRelevantEntityTypesForTasks = ['ACT', 'ADQ', 'AST', 'AUD', 'CAP', 'CIR', 'COM', 'CON', 'CRL', 'DAT', 'DEM', 'MKT', 'ORG', 'PER', 'PHY', 'PRD', 'SKI', 'STA', 'SYS', 'STR', 'TAG', 'TEC', 'WK2'];
      scope.isRoleAssigned = false;
      // scope.isContactTypeAssigned = false;
      scope.roles = [];
      scope.roleModel = {
        principalBn: null
      };
      // scope.contactTypes = [];
      // scope.contactTypeModel = {
      //   qualifierBn: null
      // };
      scope.saveInProgress = false;

      scope.switchToRoleAssigned = function () {
        // scope.isContactTypeAssigned = false;
        scope.isRoleAssigned = true;
        var adminPromise = adminService.getUserRoles();
        adminPromise.then(function (results) {
          if (results.data) {
            // Set the roles.
            scope.roles = results.data;

          } else {
            alertService.addErrorAlert("Unable to load detail for entity");
          }
        });
      };

      // scope.switchToContactTypeAssigned = function () {
      //   scope.isRoleAssigned = false;
      //   scope.isContactTypeAssigned = true;
      //   var entityType = scope.formBuilder.entityType;
      //   relationshipDataService.getRelationshipPatterns(entityType, "PER", false).then(function(results) {
      //     if(results.data) {
      //       scope.contactTypes = results.data[0].qualifiers;
      //     } else {
      //       alertService.addErrorAlert("Unable to load detail for entity");
      //     }
      //   });
      // };

      scope.switchToPersonAssigned = function () {
        scope.isRoleAssigned = false;
        // scope.isContactTypeAssigned = false;
      };

      scope.cancelTaskCreate = function () {
        scope.$broadcast('quickAddCancelled');
        scope.reset();
        scope.quickCreateModel.modal.close('cancel');
        scope.saveInProgress = false;
      };

      // scope.cancelEntityPickerModal = function () {
      //   scope.reportEntityPicker.selectedEntities = redux.store.getState().entityPicker.selectedEntities;
      //   scope.reportEntityPicker.modal.close('cancel');
      // };

      scope.reset = function () {
        scope.entityCreateModel = {};
        scope.requester = null;
        scope.assignee = null;
        scope.relevantEntities = [];
        scope.remediatedEntities = [];
        scope.isRoleAssigned = false;
        scope.roleModel = {
          principalBn: null
        };
        // scope.isContactTypeAssigned = false;
        // scope.contactTypeModel = {
        //   qualifierBn: null
        // };
        scope.formBuilder = {
          modal: null,
          form: null
        };
        associatedBnCacheService.clear();
      };

      scope.removeForm = function() {
        if(scope.formBuilder.form.associatedFields.length > 0) {
          scope.formBuilder.form = null;
        }
      };

      scope.openTaskCreateModal = function () {

        fetchPrepopulatedEntityIfRelevant().then(function(){

          // Set initial requester to current user
          scope.setRequester(utilService.getCurrentUserBn(), utilService.getCurrentUserName());

          var optionListTypes = EntityOptionListTypes['TSK'];
          loadStatusOptions(optionListTypes, function () {
            scope.quickCreateModel.modal = $modal.open({
              backdrop: 'static',
              keyboard: true,
              templateUrl: '/b/js/src/bit.ng/tasks/partials/task-create-modal.html',
              scope: scope
            });

            focusOnDescriptionField();
          });
        })

      };

      function fetchPrepopulatedEntityIfRelevant(){
        var deferred = $q.defer();
        if (scope.prepopulatedRelevantEntityBn){
          entityService.getBasicInfo(scope.prepopulatedRelevantEntityBn, false, false).then(function(entityData) {
            var entity = entityData.data;
            entity.type = {
              bnCode: utilService.getBnCodeFromTypeCode(entity.entityDiscriminator)
            };
            scope.relevantEntities.push(entity);
            scope.remediatedEntities.push(entity.bn);
            associatedBnCacheService.addAssociatedBn(entity.bn);
            deferred.resolve();
          });

        } else {
          deferred.resolve();
        }
        return deferred.promise;
      }

      /**
       * Open the Report Entity Picker
       */
      // scope.openReportEntityPickerModal = function () {
      //   // specify the base entity type, e.g. report, rule, etc
      //   // scope.reportEntityPicker.remediatedBn = scope.relevantEntities[0].bn;
      //   scope.reportEntityPicker.title = String.format("Select the entities you would like to Remediate from {0}", scope.relevantEntities[0].name);
      //
      //   console.log("dispatching remediated bn to entity picker: {}", scope.reportEntityPicker.remediatedBn);
      //   redux.store.dispatch(redux.actions.setRemediatedBn(scope.reportEntityPicker.remediatedBn));
      //   // redux.store.dispatch(redux.actions.fetchEntityPickerBns(scope.reportEntityPicker.remediatedBn));
      //
      //   scope.reportEntityPicker.modal = $modal.open({
      //     backdrop: 'static',
      //     keyboard: true,
      //     templateUrl: '/b/js/src/bit.ng/common/partials/report-entity-picker-modal.html',
      //     scope: scope
      //   });
      // };

      // function getRemediatedReportEntityType(entityBn) {
      //   var deferred = $q.defer();
      //   entityService.getBasicInfo(entityBn).then(function(data) {
      //     scope.formBuilder.entityType = data.data.entityType.code;
      //     deferred.resolve();
      //   });
      //   return deferred.promise;
      // }

      /**
       * Open the Form Builder.
       */
      scope.openFormBuilderModal = function () {
        //
        scope.formBuilder.entityType = scope.relevantEntities[0].entityType || scope.relevantEntities[0].entityDiscriminator;
        // if(scope.reportRuleEntities.includes(scope.formBuilder.entityType)) {
        //   getRemediatedReportEntityType(scope.reportEntityPicker.remediatedBn).then(function() {
        //     openFBModal();
        //   })
        // } else {
          openFBModal();
        // }
      };

      function openFBModal() {
        scope.formBuilder.modal = $modal.open({
          backdrop: 'static',
          keyboard: true,
          templateUrl: '/b/js/src/bit.ng/remediation-form-builder/partials/form-builder-modal.html',
          scope: scope
        });
      }

      var removeLastItemAndIncremementTaskCount = function() {
        scope.tasks.pop();
        scope.hiddenTaskCount += 1;
        scope.hasMoreTasks = true;
      };

      var pushTaskToOwnList = function(taskCreateJson, createdTask) {
        if(scope.myOpenTasksActive && taskCreateJson.assignedToBn == utilService.getCurrentUserBn()) {
          var newTaskForPush = {
            bn: createdTask.bn,
            createDate: Math.round((new Date()).getTime() / 1000),
            description: createdTask.description,
            name: createdTask.name,
            assignee: {
              bn: scope.assignee.bn,
              name: scope.assignee.name,
              isCurrentUser: utilService.getCurrentUserBn() === scope.assignee.bn
            },
            requester: {
              bn: scope.requester.bn,
              name: scope.requester.name,
              isCurrentUser: utilService.getCurrentUserBn() === scope.requester.bn
            }
          };
          scope.tasks.unshift(newTaskForPush);
          scope.first = scope.tasks.length - scope.hiddenTaskCount;
          if(scope.tasks.length > 10) {
            if(1 >= scope.hiddenTaskCount < 10) {
              removeLastItemAndIncremementTaskCount();
            } else if ((scope.tasks.length % 10 == 1) && !scope.hasMoreTasks && scope.hiddenTaskCount == 0) {
              removeLastItemAndIncremementTaskCount();
            }
          }
        }
      };

      scope.createTask = function (formInvalid) {

        if (formInvalid) return;

        scope.saveInProgress = true;
        var taskCreateJson = createTaskJson(scope.entityCreateModel);

        // Role-based tasks
        if (scope.isRoleAssigned && scope.roleModel.principalBn != null) {
          tasksDataService.createTasksByRole(scope.roleModel.principalBn, taskCreateJson).then(function(){
            scope.saveInProgress = false;
            scope.cancelTaskCreate();
          }, function() {
            scope.saveInProgress = false;
          });
        }

        // Task with fields assigned in a form
        else if (scope.formBuilder.form != null) {
          entityService.createEntity(scope.formBuilder.form).then(function (data) {
            taskCreateJson.remediatedEntities.push(data.data.bn);
            tasksDataService.createTask(taskCreateJson).then(function (response) {
              pushTaskToOwnList(taskCreateJson, response.data);
              scope.saveInProgress = false;
              scope.cancelTaskCreate();
            })
          }, function() {
            scope.saveInProgress = false;
          });
        }

        // Task with no relevant fields
        else {
          tasksDataService.createTask(taskCreateJson).then(function (response) {
            pushTaskToOwnList(taskCreateJson, response.data);
            $rootScope.$broadcast("taskCreated");
            tasksService.pollForTaskStatusInGraph(response.data.bn, "OPEN", 2000, function(taskBn) {
              $rootScope.$broadcast("taskCreatedInGraph", taskBn);
            });
            scope.saveInProgress = false;
            scope.cancelTaskCreate();
          }, function() {
            scope.saveInProgress = false;
          });
        }
      };

      // scope.generateTask = function(formInvalid) {
      //
      //   if (formInvalid) return;
      //
      //   scope.saveInProgress = true;
      //   if(scope.reportRuleEntities.includes(scope.reportEntityPicker.reportEntityType)) {
      //     if(scope.reportEntityPicker.reportEntityType === "CRL") {
      //       var promise = complianceDataService.createTasksForNonCompliantEntitiesByRuleBn(scope.reportEntityPicker.remediatedBn);
      //       promise.then(function (data) {
      //         if (data.data) {
      //           scope.saveInProgress = false;
      //           scope.cancelTaskCreate();
      //         }
      //       });
      //     } else if (scope.reportEntityPicker.reportEntityType === "ADQ") {
      //       var bns = [scope.relevantEntities[0].bn];
      //       if(scope.reportEntityPicker.selectedEntities.length > 0) {
      //         bns = scope.reportEntityPicker.selectedEntities;
      //       }
      //       var promise = complianceDataService.generateTasksForBns(bns);
      //       promise.then(function (data) {
      //         if(data.data) {
      //           scope.saveInProgress = false;
      //           scope.cancelTaskCreate();
      //         }
      //       });
      //       scope.cancelTaskCreate();
      //     }
      //
      //   }
      // };

      scope.addAssignee = function ($event, entity) {
        $event.preventDefault();
        scope.setAssignee(entity.bn, entity.name);
      };

      scope.addRequester = function ($event, entity) {
        $event.preventDefault();
        scope.setRequester(entity.bn, entity.name);
      };

      // Set person to "assignee" or "requester" roles
      function setPerson(role, bn, name) {
        if (typeof bn == "undefined") {
          scope[role] = null;
        } else {
          scope[role] = {
            bn: bn,
            name: name,
            isCurrentUser: utilService.getCurrentUserBn() === bn
          };
        }
      }

      scope.setAssignee = function (bn, name) {
        setPerson('assignee', bn, name);
      };
      scope.setRequester = function (bn, name) {
        setPerson('requester', bn, name);
      };

      scope.clearAssignee = function () {
        scope.assignee = null;
      };

      scope.clearRequester = function () {
        scope.requester = null;
      };

      scope.assignToCurrentUser = function () {
        scope.setAssignee(utilService.getCurrentUserBn(), utilService.getCurrentUserName());
      };

      scope.addEntityPlaceholderClicked = function () {
        scope.showEntityAdd = true;
      };

      scope.addRelevantEntity = function ($event, entity) {
        $event.preventDefault();
        scope.showEntityAdd = false;
        // if the entity added is a report or rule, we only want to allow 1 to be added
        // if (scope.reportRuleEntities.includes(utilService.getEntityTypeCode(entity.bn))) {
        //   getRemediatedReportEntityType(entity.bn);
        //   scope.reportEntityPicker.remediatedBn = entity.bn;
        //   scope.reportEntityPicker.reportEntityType = entity.entityType;
        //   scope.relevantEntityIsReportRule = true;
        //   // Remove any other entities that have been selected
        //   // We want the "Remediated Entity" here to be the report,
        //   // Then we'll populate another state object with the bn list from the selector (for remediation)
        //   for(let entity in scope.relevantEntities) {
        //     scope.removeRelevantEntity(entity);
        //   }
        // }
        scope.relevantEntities.push(entity);
        scope.remediatedEntities.push(entity.bn);
        associatedBnCacheService.addAssociatedBn(entity.bn);
        clearRelevantFields();
      };

      // scope.removeSelectedEntities = function() {
      //   scope.reportEntityPicker.selectedEntities = [];
      // };

      scope.removeRelevantEntity = function (entity) {
        // if (scope.reportRuleEntities.includes(utilService.getEntityTypeCode(entity.bn))) {
        //   scope.relevantEntityIsReportRule = false;
        // }
        scope.relevantEntities.splice(scope.relevantEntities.indexOf(entity), 1);
        scope.remediatedEntities.splice(scope.remediatedEntities.indexOf(entity.bn), 1);
        associatedBnCacheService.removeAssociatedBn(entity.bn);
        // scope.showEntityAdd = false;
        clearRelevantFields();
      };

      scope.entityIconClass = function (entity) {
        return 'icon-' + EntityBnCodeToIconClass[entity.type.bnCode];
      };

      scope.$on('set-task-description-from-entity-side-bar', function (event, data) {
        if (scope.location === 'ENTITY') {
          scope.entityCreateModel.description = data.taskDescription;
          if (scope.relevantEntities.length <= 0) {
            scope.relevantEntities.push(data.entity);
          }
          if (scope.remediatedEntities.length <= 0) {
            scope.remediatedEntities.push(data.entity.bn);
          }
        }
      });

      // We clear all form/field state whenever relevant entities are changed.
      // This simple behavior only works because we have limited relevant fields to tasks with only 1 relevant entity.
      // If we change that limitation, this logic will also need to change.
      function clearRelevantFields() {
        scope.formBuilder = {
          modal: null,
          form: null
        };
      }

      function createTaskJson(createForm) {
        var createJson = {
          task: {
            description: createForm.description,
            taskStatusBn: createForm.taskStatusBn
          },
          assignedByBn: scope.requester.bn,
          remediatedEntities: scope.remediatedEntities
        };
        if (!scope.isRoleAssigned) {
          createJson.assignedToBn = scope.assignee.bn;
        }
        return createJson;
      }

      function loadStatusOptions(dataListTypes, openModal) {
        optionListService.getOptionListChoices(dataListTypes).then(function (results) {
          scope.statusOptionsModel = results.data;
          // Set the initial status value to the first option, which should be "Open"
          scope.entityCreateModel.taskStatusBn = scope.statusOptionsModel[0].bn;
          openModal();
        });
      }

      function focusOnDescriptionField() {
        // Hacky focus workaround -- add-widgets are now greedy with focus.
        // Explicitly set the description field focus on load
        // (add-widget focus is set 100ms after it's load)
        setTimeout(function () {
          $('input.task-description-field').focus();
        }, 200);
      }

      function setAttributes() {
        // Set the default location to the task page.
        if (!attrs.location) {
          scope.location = 'TASK'
        } else {
          scope.location = attrs.location;
        }
      }

      setAttributes();
    }
  };
}