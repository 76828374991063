angular.module('barometerApp.field')
    .controller('FieldCtrl', ['$scope', 'fieldService', 'bitConstants', '$location', '$window', 'urlService', 'utilService', '$modal', 'alertService', 'pageService', 'recentLocationsService', 'redux', '$timeout',
    function ($scope, fieldService, bitConstants, $location, $window, urlService, utilService, $modal, alertService, pageService, recentLocationsService, redux, $timeout) {
        $scope.createMode = false;
        $scope.submitAttempted = false;
        pageService.setIsEntityPage(true);
        const { store, reducers } = redux;
        store.manager.add('fieldEdit', reducers.fieldEdit);

        // fieldModel is now maintained via Redux by a React component
        // we subscribe to the store here so we can reflect changes to the fieldModel in angular
        store.subscribe(() => {
          const {fieldEdit} = redux.store.getState();

          // wrapped in a timeout of 0 to make it async and avoid "digest already in progress" errors
          if ($scope.fieldModel !== fieldEdit.fieldModel) $scope.fieldModel = fieldEdit.fieldModel;

          // if there is a deferred alert to display, display it, and then tell Redux
          if (fieldEdit.angularJSDeferredAlertMessage) {
            alertService.addDeferredAlert(fieldEdit.angularJSDeferredAlertMessage);
            redux.store.dispatch(redux.actions.fieldEditAngularJSDeferredAlertSucceed());
          }
        });

        $scope.configModel = {
            selectOptions: {
                allowClear: false
            },
            choicesModal : null
        };

        $scope.getPrincipalTypes = function(privilegeType) {
          if (privilegeType === 'EDITABLE' || privilegeType === 'VISIBLE') {
            return [
              {id: 'PERSON', name: 'Person'},
              {id: 'ROLE', name: 'Role'}
            ];
          }
          if (privilegeType === 'APPROVE') {
            return [
              {id: 'PERSON', name: 'Person'},
              {id: 'ROLE', name: 'Role'},
              {id: 'CONTACT_TYPE', name: 'Contact Type'},
              {id: 'ORGANIZATION', name: 'Organization'}
            ]
          }
        };

        // make call to load recents data.  notice we are not adding a new recents record for collection pages.
        recentLocationsService.getRecentsFromServerAndNotify();

        $scope.openSecurityModal = function() {
            $scope.configModel.securityModal = $modal.open({
                backdrop: 'static',
                keyboard: true,
                templateUrl: '/b/js/src/bit.ng/field/partials/field-security-edit.html',
                windowClass: 'modal modal-large',
                scope: $scope
            })
        };

        $scope.closeSecurityModal = function() {
            $scope.configModel.securityModal.dismiss('cancel');
        };

        $scope.deleteField = function () {
            if ($scope.fieldModel.bn) {
            	var promise = fieldService.deleteField($scope.fieldModel.bn);
                promise.then(function (results) {
                    alertService.addDeferredAlert({type: 'success', msg: String.format("Deleted Custom Field: '{0}'", $scope.fieldModel.name)});
	                $scope.redirectToBrowse();
                });
            }
        };

        $scope.redirectToBrowse = function() {
            $window.location.href = urlService.getBaseUrl() + 'fields';
        };

      // define this at the end, or else (for some reason), urlService can't get the bn yet
      $scope.createMode = !urlService.getEntityBn();
    }])

    .controller('FieldBrowseCtrl', ['$scope', '$window', 'urlService', 'recentLocationsService',
        function($scope, $window, urlService, recentLocationsService) {
            $scope.browseModel = {
                assocCode: "FLD",
                tableId: "field_table",
                tableOptions: {
                    displayAdd: false,
                    displayFilters: true,
                    displayPivot: false
                },
                paginationData: { pageSize: 25, maxSize: 0, noOfPages: 0 }
            };

            $scope.createField = function() {
                $window.location.href = urlService.getBaseUrl() + 'fieldedit/';
            };

            recentLocationsService.getRecentsFromServerAndNotify();
        }]);
