var UrlToIconAndSection = {};

// Format for each nav item: [{{icon}}, {{sectionId}}, {{orderInSection}}]
// See primary-nav.html for the template

UrlToIconAndSection['enterprise'] = [EntityUtils.icons.dashboard, 1];
UrlToIconAndSection['insight'] = [EntityUtils.icons.insight, 4];

// Enterprise Entities
UrlToIconAndSection['actors'] = [EntityUtils.icons.actor, 2, 1];
UrlToIconAndSection['capabilities'] = [EntityUtils.icons.capability, 2, 2];
UrlToIconAndSection['companies'] = [EntityUtils.icons.company, 2, 3];
UrlToIconAndSection['connections'] = [EntityUtils.icons.connection, 2, 4];
UrlToIconAndSection['data'] = [EntityUtils.icons.data, 2, 5];
UrlToIconAndSection['demands'] = [EntityUtils.icons.demand, 2, 6];
UrlToIconAndSection['deployments'] = [EntityUtils.icons.physical, 2, 7];
UrlToIconAndSection['markets'] = [EntityUtils.icons.market, 2, 8];

UrlToIconAndSection['organizations'] = [EntityUtils.icons.organization, 3, 1];
UrlToIconAndSection['people'] = [EntityUtils.icons.people, 3, 2];
UrlToIconAndSection['products'] = [EntityUtils.icons.product, 3, 3];
UrlToIconAndSection['skills'] = [EntityUtils.icons.skill, 3, 4];
UrlToIconAndSection['standards'] = [EntityUtils.icons.standard, 3, 5];
UrlToIconAndSection['strategies'] = [EntityUtils.icons.strategy, 3, 6];
UrlToIconAndSection['systems'] = [EntityUtils.icons.system, 3, 7];
UrlToIconAndSection['technologies'] = [EntityUtils.icons.technology, 3, 8];

// Insight Entities
UrlToIconAndSection['044S00000001'] = [EntityUtils.icons.bubble, 5, 1];      //Bubble Chart
UrlToIconAndSection['044S00000003'] = [EntityUtils.icons.matrix, 5, 2];      //Capability Matrix
UrlToIconAndSection['044S00000002'] = [EntityUtils.icons.circle, 5, 3];      //Collection
UrlToIconAndSection['044S00000004'] = [EntityUtils.icons.dashboard, 5, 4];   //Dashboard
UrlToIconAndSection['documents'] = [EntityUtils.icons.document, 5, 5];    //Document
UrlToIconAndSection['044S00000006'] = [EntityUtils.icons.calculator, 5, 6];  //FieldAggregation
UrlToIconAndSection['044S0000000J'] = [EntityUtils.icons.table, 5, 7];       //Kanban
UrlToIconAndSection['044S00000007'] = [EntityUtils.icons.roadmap, 5, 8];     //Lifecycle Roadmap
UrlToIconAndSection['044S0000000L'] = [EntityUtils.icons.relational, 5, 9];  //Priority Board

UrlToIconAndSection['044S00000008'] = [EntityUtils.icons.connection, 6, 1];  //Radial Connection
UrlToIconAndSection['044S00000009'] = [EntityUtils.icons.relational, 6, 2];  //Relational Diagram
UrlToIconAndSection['044S0000000B'] = [EntityUtils.icons.report, 6, 3];      //Report
UrlToIconAndSection['044S0000000K'] = [EntityUtils.icons.roadmap, 6, 4];     //Roadmap
UrlToIconAndSection['044S0000000C'] = [EntityUtils.icons.compliance, 6, 5];  //Rule
UrlToIconAndSection['044S0000000D'] = [EntityUtils.icons.audit, 6, 6];       //Scorecard
UrlToIconAndSection['tags'] = [EntityUtils.icons.tag, 6, 8];         //Tag

// Administer Links
UrlToIconAndSection['bulkimport'] = [EntityUtils.icons.gear, 7, 1];
UrlToIconAndSection['catalogmanagement'] = [EntityUtils.icons.gear, 7, 2];
UrlToIconAndSection['fields'] = [EntityUtils.icons.customFields, 7, 3];
UrlToIconAndSection['dataextracts'] = [EntityUtils.icons.extract, 7, 4];
UrlToIconAndSection['eventstream'] = [EntityUtils.icons.gear, 7, 5];
UrlToIconAndSection['fieldlevelsecurity'] = [EntityUtils.icons.gear, 7, 6];
UrlToIconAndSection['fieldsetlocations'] = [EntityUtils.icons.fieldsetLayout, 7, 7];
UrlToIconAndSection['fieldsets'] = [EntityUtils.icons.fieldset, 7, 8];
UrlToIconAndSection['admininvites'] = [EntityUtils.icons.gear, 7, 9];
UrlToIconAndSection['integrationadapters'] = [EntityUtils.icons.gear, 7, 10];
UrlToIconAndSection['optionlists'] = [EntityUtils.icons.gear, 7, 11];
// UrlToIconAndSection['taskmanager'] = [EntityUtils.icons.tasks, 7, 12];
UrlToIconAndSection['taskqueue'] = [EntityUtils.icons.tasks, 7, 12];
UrlToIconAndSection['tenantendpoints'] = [EntityUtils.icons.gear, 7, 13];
UrlToIconAndSection['adminusers'] = [EntityUtils.icons.gear, 7, 14];
UrlToIconAndSection['contacttypeedit'] = [EntityUtils.icons.gear, 7, 15];
UrlToIconAndSection['tenants'] = [EntityUtils.icons.gear, 7, 16];


angular.module('barometerApp.nav')
  .service('primaryNavService', ['$http',
    function ($http) {
      return {
        getPrimaryNav: function () {
          return $http({method: 'GET', url: '/b/api/nav/primary'})
            .success(function (data, status, headers, config) {
              //
            })
            .error(function (data, status, headers, config) {
              console.log('error in primaryNavService.getPrimaryNav');
            });
        }
      };
    }]);
