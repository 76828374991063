// Applied to high-level element in app
// Makes sure the content area is always taller than the page-nav, expanded or collapsed
// Only makes the content area taller, never shrinks it back if the nav is collapsed.
angular.module('barometerApp.nav')
  .directive('mainHeightNormalizer', ['$timeout', '$rootScope', function ($timeout, $rootScope) {
    return {
      link: function (scope, element, attrs) {
        var main, pageNav, currentMainMinHeight, currentPageNavHeight;
        var BUFFER = 100;
        var DELAY = 700; // Most transitions take < 600ms

        function setMinHeightOfMain() {
          $timeout(function () {
            main = $('.main');
            pageNav = $('.page-nav');
            currentMainMinHeight = parseInt(main.css('min-height')) || 0;
            currentPageNavHeight = pageNav.height();
            if (currentMainMinHeight < currentPageNavHeight + BUFFER){
              main.css('min-height', currentPageNavHeight + BUFFER);
            }
          }, DELAY);
        }

        setMinHeightOfMain();
        $(document).on('click', '.section-link.expand', setMinHeightOfMain);
        $rootScope.$on('leftTabSelected', setMinHeightOfMain);
      }
    }
  }]);