angular.module('barometerApp.layout')
    .filter('filterColumnsForgiving', function() {
    return function(input, columnId) {
        var out = [];
        if (input) {
            for (var i = 0; i < input.length; i++){
                if(input[i].columnId >= columnId)
                    out.push(input[i]);
            }
        }
        return out;
    };
});