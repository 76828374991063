angular.module('barometerApp.entity')
  .service('entityService', ['$http', 'alertService', 'bitConstants', 'errorHandlingService', 'pageService', 'utilService', '$window',
    function ($http, alertService, bitConstants, errorHandlingService, pageService, utilService, $window) {
      var newProposedEditBns = {};
      var canEdit = false;
      var canDelete = false;
      var canEditCustomFields = true;

      // Only convert if input date matches local format
      function convertSaveModelDate(date){
        var dateFormat = utilService.getLocaleLongDateFormat();
        const isValidLocalDate = moment(date, dateFormat, true).isValid();
        if (isValidLocalDate) return utilService.convertToISOUTCLocalDate(date);
        return date;
      }

      return {
        getCanEdit: function () {
          return canEdit;
        },
        setCanEdit: function (isEditable) {
          canEdit = isEditable;
        },
        getCanDelete: function () {
          return canDelete;
        },
        setCanDelete: function (isCanDelete) {
          canDelete = isCanDelete;
        },
        getCanEditCustomFields: function () {
          return canEditCustomFields;
        },
        setCanEditCustomFields: function (canEditFields) {
          canEditCustomFields = canEditFields;
        },
        getNewProposedEditBns: function () {
          return newProposedEditBns;
        },
        setNewProposedEditBns: function (proposedEditBns) {
          newProposedEditBns = proposedEditBns;
        },
        createEntity: function (createModel, bnTypeCode, assetStoreUrl) {
          var jsonReq = angular.toJson(createModel);

          var requestParams, createEntityURL, contentType;
          if (bnTypeCode === '0U') { // Special handling for asset
            // Hack in the asset insightType bn
            createModel.insightType = {bn : '044S00000005'};
            jsonReq = angular.toJson(createModel);

          	requestParams = $.param({jsonCreateRequest: jsonReq});
          	createEntityURL = '/b/api/entity/createAsset/' + assetStoreUrl;
          	contentType = 'application/x-www-form-urlencoded';
          }
          else {
            requestParams = jsonReq;
            createEntityURL = '/b/api/entities';
            contentType = 'application/json;charset=UTF-8';
          }
          return $http({
            method: 'POST',
            url: createEntityURL,
            data: requestParams,
            headers: {'Content-Type': contentType}
          }).
            success(function (data, status, headers, config) {
            }).
            error(function (data, status, headers, config) {
              var msgString = 'Unable to create entity';
              console.error('error in entityService.entityCreate for createReqest: ' + createModel + ' and bnTypeCode: ' + bnTypeCode + ' and assetStoreUrl:' + assetStoreUrl);
              if (status === 409 && data.errorMessages) {
                msgString += ': ' + data.errorMessages;
              }
              alertService.addErrorAlert(msgString);
            });
        },
        deleteEntity: function (bn) {
          utilService.assertIsBn(bn);
          return $http({
            method: 'DELETE',
            url: '/b/api/entities/' + bn
            //params: {
            //  bn: bn
            //}
          }).
            success(function (data, status, headers, config) {
            }).
            error(function (data, status, headers, config) {
              console.log('error in entityService.deleteEntity for bn: ' + bn);
              console.log('error data: ', data);
              var msgString = "", entityObj, entityString = "", associationErrors = data.associationErrors;
              if (associationErrors && associationErrors.TEN) {
                alertService.addErrorAlert('Entity Cannot Be Deleted Due To Tenant Association');
                delete associationErrors.TEN;
              }
              if (associationErrors && Object.keys(associationErrors).length > 0) {
                msgString += "Cannot delete this entity due to the following existing associations:" + "<br />";
                for (var key in data.associationErrors) {
                  if (data.associationErrors.hasOwnProperty(key)) {
                    entityObj = bitConstants.getEntityTypeForTypeCode(key);
                    entityString = entityObj['displayName' + (data.associationErrors[key] > 1 ? "Plural" : "")];
                    msgString += data.associationErrors[key] + " " + entityString + ", ";
                  }
                }
                for (var key2 in data.siblingAssociationErrors) {
                  if (data.siblingAssociationErrors.hasOwnProperty(key2)) {
                    entityObj = bitConstants.getEntityTypeForTypeCode(key2);
                    entityString = entityObj['displayName' + (data.siblingAssociationErrors[key2] > 1 ? "Plural" : "")];
                    msgString += 'Sibling Connection : ' + data.siblingAssociationErrors[key2] + " " + entityString + ", ";
                  }
                }
                //remove the trailing ', '
                msgString = msgString.substring(0, msgString.length - 2);
                alertService.addErrorAlert(msgString);
              } else if (data.errorMessages && data.errorMessages.length > 0) {
                for (var i = 0; i < data.errorMessages.length; i++) {
                  alertService.addErrorAlert(data.errorMessages[i]);
                }
              } else {
                console.log('unhandled error in entityService.deleteEntity bn: ' + bn);
              }
            });
        },
        getBasicInfo: function (bn, getFieldsets, updatePageTitleAndEntity) {
          utilService.assertIsBn(bn);
          getFieldsets = typeof getFieldsets !== 'undefined' ? getFieldsets : false;
          return $http({method: 'GET', url: '/b/api/entities/' + bn + '?fieldsets=' + getFieldsets}).
            success(function (data, status, headers, config) {

              // On entity pages, it's useful to stash basic entity information for common page components to share
              if (updatePageTitleAndEntity){
                pageService.setTitle(data.displayableName);
                pageService.setEntity({
                  "bn": data.bn,
                  "typeBn": data.systemTypeBn,
                  "color": "#" + data.color,
                  "name": data.name,
                  "lastUpdateDate": data.lastUpdateDate,
                  "lastUpdatedName": data.updatedByBn.firstName + " " + data.updatedByBn.lastName,
                  "lastUpdatedByBn": data.updatedByBn.bn,
                  "lastAuditDataRefresh": data.lastAuditDataRefresh,
                  "domain": data.domain
                });
              }

            }).
            error(function (data, status, headers, config) {
              console.log('error in entityService.getBasicInfo for bn: ' + bn);
              $window.location.href = urlService.getBaseUrl();
              alertService.addDeferredAlert({
                type: "error",
                msg: String.format("You do not have access to {0}.", EntityTypes[utilService.getEntityTypeCode(bn)].displayNamePlural)
              });
            });
        },
        getSideBarCounts: function (bn) {
          utilService.assertIsBn(bn);
          return $http({method: 'GET', url: '/b/api/sidebar/' + bn}).
            success(function (data, status, headers, config) {
            }).
            error(function (data, status, headers, config) {
              console.log('error in entityService.getSideBarCounts for bn: ' + bn);
            });
        },
        getDetail: function (bn) {
          utilService.assertIsBn(bn);
          return $http({
            method: 'GET',
            url: '/b/api/entities/' + bn + '/details'
          }).
            success(function (data, status, headers, config) {
            }).
            error(function (data, status, headers, config) {
              console.log('error in entityService.getDetail for bn: ' + bn);
              errorHandlingService.handleGenericError(status, "Unable to load detail for entity.");
            });
        },
        getLinks: function (bn) {
          utilService.assertIsBn(bn);
          return $http({method: 'GET', url: '/b/api/entities/' + bn + '/links'}).
            success(function (data, status, headers, config) {
            }).
            error(function (data, status, headers, config) {
              console.log('error in entityService.getLinks for bn: ' + bn);
              errorHandlingService.handleGenericError(status, "Error loading links for entity.");
            });
        },
        getTags: function (bn) {
          utilService.assertIsBn(bn);
          return $http({method: 'GET', url: '/b/api/entities/' + bn + '/tags'}).
            success(function (data, status, headers, config) {
            }).
            error(function (data, status, headers, config) {
              console.log('error in entityService.getTags for bn: ' + bn);
              errorHandlingService.handleGenericError(status, "Error loading tags for entity.");
            });
        },
        activateAudit: function (value, bn) {
          utilService.assertIsBn(bn);
          return $http({method: 'GET', url: '/b/api/scorecard/activate/' + bn + '/' + value}).
            success(function (data, status, headers, config) {
            }).
            error(function (data, status, headers, config) {
              console.log('error in entityService.activateAudit for bn: ' + bn);
            });
        },
        adjustDateFields: function(saveModel) {
          if (saveModel.contentCompletionDate) {
            saveModel.contentCompletionDate = convertSaveModelDate(saveModel.contentCompletionDate);
          }
          if (saveModel.startDate) {
            saveModel.startDate = convertSaveModelDate(saveModel.startDate);
          }
          if (saveModel.endDate) {
            saveModel.endDate = convertSaveModelDate(saveModel.endDate);
          }
          if (saveModel.releaseDate) {
            saveModel.releaseDate = convertSaveModelDate(saveModel.releaseDate);
          }
          if (saveModel.retireDate) {
            saveModel.retireDate = convertSaveModelDate(saveModel.retireDate);
          }
          if (saveModel.sunsetDate) {
            saveModel.sunsetDate = convertSaveModelDate(saveModel.sunsetDate);
          }
          return saveModel;
        },
        updateBasicInfo: function (editRequest) {
          var request = angular.toJson(editRequest);
          return $http({
            method: 'PUT',
            url: '/b/api/entities',
            data: request,
            headers: {'Content-Type': 'application/json;charset=UTF-8' }
          }).
            success(function (data, status, headers, config) {
            }).
            error(function (data, status, headers, config) {
              var msgString = "Unable to edit entity";
              console.log('error in entityService.updateBasicInfo for editRequest:', editRequest);
              if (status === 409 && data.errorMessages) {
                msgString += ': ' + data.errorMessages;
              }
              alertService.addErrorAlert(msgString);
            });
        },
        updateDetail: function (editRequest) {
          var entityBn = editRequest.bn,
            jsonReq = angular.toJson(editRequest);
          utilService.assertIsBn(entityBn);
          return $http({
            method: 'POST',
            url: '/b/api/entities/'+ entityBn + '/details',
            data: jsonReq,
            headers: {'Content-Type': 'application/json;charset=UTF-8'}
          }).
              success(function (data, status, headers, config) {
              }).
              error(function (data, status, headers, config) {
                console.log('error in entityService.updateDetail for editRequest: ' + editRequest);
                alertService.addErrorAlert("Unable to edit entity detail");
              });
        },
        getFieldValuesForWorksheet: function(fieldBn, worksheetBn) {
          utilService.assertIsBn(fieldBn);
      	  return $http({
      		 method:'GET',
      		 url: '/b/api/entities/' + worksheetBn + '/fields/' + fieldBn
      	  }).
            success(function (data, status, headers, config) {
            }).
            error(function (data, status, headers, config) {
              console.log('error in fieldService.getFieldValuesForWorksheet for fieldBn: ' + fieldBn + ', worksheetBn: ' + worksheetBn);
            });
        },
        getEntityCatalogs: function() {
          return $http({
            method: 'GET',
            url: '/b/api/entity/catalogs/'
          }).success(function(data, status, headers, config) {
          }).error(function(data, status, headers, config) {
            console.error("Error reading catalogs: ", status, data);
          })
        }
      };
    }]);
