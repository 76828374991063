angular.module('barometerApp.recentLocations')
  .directive('recentLocations', ['pageService', 'queryKeyService', 'utilService', 'bitConstants', 'urlService', 'recentLocationsService', 'styleService',
    function (pageService, queryKeyService, utilService, bitConstants, urlService, recentLocationsService, styleService) {
      return {
        scope: {},
        templateUrl: '/b/js/src/bit.ng/recent-locations/partials/recent-location-pivot-nodes.html',
        controller: function ($scope, $element, $attrs) {
          $scope.bubbles = [];
          $scope.activeNode = 0; //should this be an array of active node indexes ?  clicking a node would toggle?

          function addBubble(node) {
            var iconClass = getIconForEntityType(node.entityTypeCode);
            var description = buildDescription(node);
            $scope.bubbles.push({
              icon: iconClass,
              count: node.count,
              description: description,
              url: getCurrentUrl()
            });
          }

          function buildDescription(node) {
            var description = null;
            if (node.description) {
              description = node.description;
            } else {
              //get description from entity type
              var entityType = bitConstants.getEntityTypeForTypeCode(node.entityTypeCode);
              description = entityType.displayNamePlural;
            }
            return description;
          }

          function getIconForBn(bn) {
            if (bn) {
              var bnCode = utilService.getBnCode(bn);
              var entityTypeCode = bitConstants.getEntityTypeForBnCode(bnCode);
              return styleService.getIconClassForEntityTypeCode(entityTypeCode.typeCode);
            }
            return null;
          }

          function getIconForEntityType(entityTypeCode) {
            return styleService.getIconClassForEntityTypeCode(entityTypeCode);
          }

          function loadPivotNodesFromServer(queryKey) {
            $scope.bubbles = [];
            queryKeyService.getGlobalSearchNodesForQueryKey(queryKey).then(function (results) {
              var nodes = results.data.data;
              _.forEach(nodes, function (node) {
                addBubble(node);
              });
              recentLocationsService.addRecentRecord($scope.bubbles);
            });
          }

          // Ignore hash and search parts of the URL
          function getCurrentUrl(){
              return location.origin + location.pathname;
          }

          function setupRecents(basicInfo) {
            var iconClass = getIconForBn(basicInfo.bn);
            // prevent pushing duplicate bubbles here..
            if ($scope.bubbles.length > 0) {
              var last = $scope.bubbles[$scope.bubbles.length - 1];
              if (last.url === getCurrentUrl()) {
                last.description = basicInfo.displayableName || basicInfo.name;
              }
            } else {
              $scope.bubbles.push({
                icon: iconClass,
                description: basicInfo.displayableName || basicInfo.name,
                url: getCurrentUrl()
              });
            }
          }

          // handle when we are on a single entity page
          $scope.$on('basicInfoLoaded', function (event, basicInfo) {
            setupRecents(basicInfo);
          });

          $scope.$on('addRecentRecord', function (event, basicInfo) {
            setupRecents(basicInfo);
            recentLocationsService.addRecentRecord($scope.bubbles);
          });

          // handle when we are at a search/pivot results page or collection page
          var queryKey = urlService.getQueryKey();
          if (queryKey) {
            loadPivotNodesFromServer(queryKey);
          }
        }
      }
    }])