angular.module('barometerApp.switch')
  .directive('switch', ['$timeout', ($timeout) => {
    return {
      scope: {
        model: '=',
        disabled: '=',
        clickHandler: '&',
        color: '@'      // @param color Default CSS is dark-blue, only other option is 'orange'
      },
      template: `
        <span class="switch" ng-class="getClasses()" ng-click="clicked()">
          <input type="checkbox" ng-checked="model">
          <span class="slider round"></span>
        </span>
      `,
      link: (scope, elem) => {

        // Default CSS is dark-blue, only other option is 'orange'
        scope.getClasses = () => {
          const classes = {
            disabled: scope.disabled,
            loading: scope.loading
          };
          if (scope.color) {
            classes[`color-${scope.color}`] = true
          }
          return classes;
        };

        scope.loading = true;

        // Note: click handler applied to outer span to work around angular+IE11 bug – ng-click never fires on checkbox
        // See BARO-19485
        scope.clicked = () => {
          scope.clickHandler();
        };

        // Add loading class for initial render to avoid unnecessary initial transitions
        $timeout(() => {
          scope.loading = false;
        }, 500);
      }
    }
  }]);
