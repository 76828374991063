angular.module('barometerApp.entityMixed')
  .service('entityMixedService', ['$http', 'alertService',
    function ($http, alertService) {
      return {
        extractGroupResultsForEntityType: function (groupResults, entityType) {
          for (var i = 0; i < groupResults.length; i++) {
            var group = groupResults[i];
            if (group.entityTypeCode === entityType) {
              return group;
            }
          }
        },

        getMyAuditCounts: function () {
          return $http({
            method: 'GET',
            url: '/b/api/dashboard/myaudits/counts/'
          }).success(function (data, status, headers, config) {
          }).error(function (data, status, headers, config) {
            console.error('error in entityMixedService.getMyAuditCounts');
          });
        },

        getProposedEditCounts: function (pcdFilter) {
          var queryParams = {
            pcdFilter: pcdFilter
          };
          return $http({
            method: 'GET',
            url: '/b/api/dashboard/proposededits/counts/',
            params: queryParams
          }).success(function (data, status, headers, config) {
          }).error(function (data, status, headers, config) {
            console.log('error in entityMixedService.getProposedEditCounts for pcdFilter: ', pcdFilter);
            var msgString = 'Unable to retrieve data: ';
            if (status === 409 && data.errorMessages) {
              msgString += ': ' + data.errorMessages;
            }
            alertService.addErrorAlert(msgString);
          });
        }
      };
    }]);
