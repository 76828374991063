angular.module("barometerApp.roadmapChart")
  .directive("roadmapChart", [function () {
    return {
      templateUrl: "/b/js/src/bit.ng/roadmap-chart/partials/roadmap-chart.html",
      scope: {
        'previewMode': '@',
        'worksheetBn': '='
      }
    }
  }])

  .directive('roadmapChartScaleSwitcher', ['$rootScope', function ($rootScope) {
    return {
      templateUrl: "/b/js/src/bit.ng/roadmap-chart/partials/roadmap-chart-scale-switcher.html",
      replace: true,
      link: function (scope, elem, attrs) {
        scope.options = [
          {label: 'Years', value: 'year'},
          {label: 'Months', value: 'month'},
          {label: 'Weeks', value: 'week'}
        ];
        scope.currentView = scope.options[0];
        scope.viewChanged = function () {
          $rootScope.$broadcast('roadmapScaleChange', scope.currentView.value);
        }
      }
    }
  }])
  .directive('roadmapChartSelectableTask', ['$rootScope', function ($rootScope) {
    return {
      link: function (scope, elem, attrs) {
        elem.on('click', function () {
          $('.roadmap-task.selected').removeClass('selected');
          $(this).addClass('selected');
          $rootScope.$broadcast('roadmapLifecycleStateSelected', {
            lifecycleState: scope.task.data,
            entityBn: scope.task.row.id,
            elem: elem
          });
        });
        scope.$on('roadmapLifecycleStateSelectionCleared', function () {
          elem.removeClass('selected');
        });
      }
    }
  }])
  .directive('roadmapChartLifecycleViewMenu', [function () {
    return {
      templateUrl: "/b/js/src/bit.ng/roadmap-chart/partials/roadmap-chart-lifecycle-view-menu.html",
      scope: {
        show: "=",
        lifecycleStateModel: '=',
        rowElem: "="
      },
      link: function (scope, elem, attrs) {
        scope.top = function () {
          if (scope.rowElem) {
            return scope.rowElem.offset().top + scope.rowElem.outerHeight();
          }
        };
        scope.left = function () {
          if (scope.rowElem) {
            // Place the popover in the center of narrow bars or up to 20px from the left
            return scope.rowElem.offset().left + Math.min(scope.rowElem.width() / 2, 20);
          }
        };

        // Prevent inner clicks from bubbling -- allows simpler close logic
        elem.on('click', function (event) {
          event.stopPropagation();
        });
      }
    }
  }])
  .directive('roadmapChartLifecycleEditMenu', ['$rootScope', 'utilService', function ($rootScope, utilService) {
    return {
      templateUrl: "/b/js/src/bit.ng/roadmap-chart/partials/roadmap-chart-lifecycle-edit-menu.html",
      scope: {
        saveFn: "&",
        deleteFn: "&",
        show: "=",
        lifecycleStateModel: '=',
        rowElem: "=",
        errors: '='
      },
      link: function (scope, elem, attrs) {
        scope.dateFormat = utilService.getDateFormatForBootstrapDatepicker();
        scope.showSave = false;
        scope.save = function () {
          scope.saveFn();
        };
        scope.delete = function () {
          scope.deleteFn();
        };

        scope.offset = {
          top: null,
          left: null
        };

        // If top hits zero or below, we are off-screen
        // Watching scope functions is weird:
        // see https://code.angularjs.org/1.2.32/docs/api/ng/type/$rootScope.Scope#$watch
        scope.onScreen = true;
        scope.$watch(function ($scope) {
            return $scope.top();
          }, function (newVal, oldVal) {
            scope.onScreen = newVal > 0;
          }
        );

        // When a bar goes off screen, deselect it
        scope.$watch('onScreen', function (newVal, oldVal) {
          if (oldVal && !newVal){
            $rootScope.$broadcast('roadmapChartLifecycleEditMenuOffScreen');
          }
        });

        scope.top = function () {
          if (scope.rowElem) {
            return scope.rowElem.offset().top + scope.rowElem.outerHeight();
          }
        };

        scope.left = function () {
          if (scope.rowElem) {
            // Place the popover in the center of narrow bars or up to 20px from the left
            return scope.rowElem.offset().left + Math.min(scope.rowElem.width() / 2, 20);
          }
        };

        // Prevent inner clicks from bubbling -- allows simpler close logic
        elem.on('click', function (event) {
          event.stopPropagation();
        });
      }
    }
  }])
  .directive('roadmapChartLifecycleCreateMenu', ['utilService', function (utilService) {
    return {
      templateUrl: "/b/js/src/bit.ng/roadmap-chart/partials/roadmap-chart-lifecycle-create-menu.html",
      scope: {
        saveFn: "&",
        cancelFn: "&",
        show: "=",
        lifecycleStateModel: '=',
        rowElem: "=",
        errors: '=',
        align: "@"
      },
      link: function (scope, elem, attrs) {
        scope.dateFormat = utilService.getDateFormatForBootstrapDatepicker();
        scope.showSave = false;
        scope.save = function () {
          scope.saveFn();
        };
        scope.cancel = function () {
          scope.cancelFn();
        };
        scope.top = function () {
          if (scope.rowElem) {
            return scope.rowElem.offset().top + scope.rowElem.outerHeight();
          }
        };
        scope.left = function () {
          if (scope.align === 'right') return 'auto';
          if (scope.rowElem) {
            // Place the popover in the center of narrow bars or up to 20px from the left
            return Math.max(0, scope.rowElem.offset().left + Math.min(scope.rowElem.width() / 2, 20));
          }
        };
        scope.right = function () {
          if (scope.align !== 'right') return 'auto';
          if (scope.rowElem) {
            return $('body').width() - (scope.rowElem.offset().left + scope.rowElem.outerWidth());
          }
        };

        // Prevent inner clicks from bubbling -- allows simpler close logic
        elem.on('click', function (event) {
          event.stopPropagation();
        });
      }
    }
  }]);
