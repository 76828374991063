angular.module('barometerApp.filter')
  .filter('filterType', function () {
    return function (items, filterType) {
      return _.filter(items, function (item) {
        if (filterType === "simpleText") {
          return item.key === "simpleTextSearchKey";
        } else if (filterType === "multiSelect") {
          return item.type === "MULTI_SELECT_VALUED";
        } else if (filterType === "typeahead") {
          return item.type === "MULTI_SELECT_BN";
        } else if (filterType === "groupedMultiSelect") {
          return item.type === "GROUPED_MULTI_SELECT_VALUED";
        } else if (filterType === 'qualifier') {
          return item.isQualifier;
        }
      });
    }
  });