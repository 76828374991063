angular.module('barometerApp.workspace')
  .service('workspaceService', ['$http', 'alertService', 'associatedBnCacheService', 'bitConstants', 'errorHandlingService',
    function ($http, alertService, associatedBnCacheService, bitConstants, errorHandlingService) {
      return {
        /**
         * Calls the WorkspaceService to discover associations. Workspaces (aka Circles) are a little special,
         * because they are associated generically with Resources (i.e. there is only one assoc table).
         *
         * This could be made more generic so that it is not limited to Workspaces, but today Workspace
         * is the only entity with this requirement.
         *
         * @param entityBn Workspace BN.
         * @param maxResults The maximum number of entity Name+BN records to be returned per sub-set (e.g. SYS).
         */
        getAssociated: function (entityBn, maxResults) {
          return $http({
            method: 'GET',
            url: '/b/api/workspace/resources?entityBn=' + entityBn + '&maxResults=' + (maxResults || 999)
          }).
            success(function (data, status, headers, config) {
              // Push each associated BN into the "isAssociated" cache,
              // so that we can later indicate that they cannot be re-added
              // to our entity.
              // TODO This feature works here ONLY as long as maxResults is uncapped!
              associatedBnCacheService.clear();
              _.each(data.results, function (entityTypeGroup) {
                  _.each(entityTypeGroup.entityTypeResults, function (entity) {
                    associatedBnCacheService.addAssociatedBn(entity['bn']);
                  });
              });
            }).
            error(function (data, status, headers, config) {
              console.log('error during GET workspace/resources for entityBn: ' + entityBn);
              errorHandlingService.handleGenericError(status);
            });
        },
        /**
         * Find out if a workspace has an association to a specific entity.
         * @param args contains an target entity type code, target BN, and source BN
         * @param callback
         */
        isEntityAssociated: function(args, callback) {
          this.getAssociated(args.sourceBn).then(function(assocData) {
            var entityTypeCollection = assocData.data.results;
            for(var i=0, len = entityTypeCollection.length; i < len; i++){
              if (entityTypeCollection[i]['entityTypeCode'] === args.entityTypeCode) {
                var entitiesOfGivenType = entityTypeCollection[i]['entityTypeResults'];
                for(var j=0; j < entitiesOfGivenType.length; j++) {
                  if (entitiesOfGivenType[j].bn == args.targetBn)
                    return callback(true);
                }
              }
            }
            return callback(false);
          });
        }
      }
    }])
;