angular.module('barometerApp.summary')
  .controller('LayoutSelectorCtrl', ['$scope', '$rootScope', '$timeout', '$filter', 'alertService', 'urlService', 'layoutService',
    function ($scope, $rootScope, $timeout, $filter, alertService, urlService, layoutService) {
      $scope.layoutSelectorModel = {
        loaded: false,
        layouts: null,
        tenantDefaultSelected: true,
        editorPopupClass: 'close',
        layoutSearchInput: ''
      };

      // If summaryId set in the URL, load the layout and set it as current
      var summaryIdFromUrl = urlService.getSummaryIdFromUrl();
      if (summaryIdFromUrl) {
        if (summaryIdFromUrl === 'DEFAULT') {
          layoutService.setCurrentSummaryLayout($scope.entityType, layoutService.getDefaultLayoutForEntityType($scope.entityType));
          $rootScope.$broadcast("newLayoutSelected", $scope.entityType);
        } else {
          layoutService.getLayout(summaryIdFromUrl).then(function (data) {
            layoutService.setCurrentSummaryLayout($scope.entityType, data.data);
            $rootScope.$broadcast("newLayoutSelected", $scope.entityType);
          });
        }
      }

      $scope.$on('closeAllOpenEditPopups', function (event) {
        $scope.layoutSelectorModel.editorPopupClass = "close";
      });
      $scope.$on('layoutDeleteSuccessful', function (event, layout) {
        $scope.selectLayout(layoutService.getDefaultLayoutForEntityType($scope.entityType));
        $timeout(function () {
          $scope.refreshLayoutList();
        }, 2000); // wait a second before refreshing the list to make sure new layouts have been indexed.
      });
      $scope.selectLayout = function (layout) {
        alertService.clearAllAlerts();
        $scope.layoutSelectorModel.tenantDefaultSelected = layout.tenantDefault;
        if (layout.bn == "DEFAULT") {
          layoutService.setCurrentSummaryLayout($scope.entityType, layoutService.getDefaultLayoutForEntityType($scope.entityType));
          $rootScope.$broadcast("newLayoutSelected", $scope.entityType);
        } else {
          layoutService.getLayout(layout.bn).then(function (data) {
            layoutService.setCurrentSummaryLayout($scope.entityType, data.data);
            $rootScope.$broadcast("newLayoutSelected", $scope.entityType);
          });
        }
      };
      $scope.$on('layoutSavedSuccessfully', function (even, layout) {
        if ($scope.entityType == layout.entityType) {
          var layoutPromise = layoutService.getLayout(layout.bn);
          layoutPromise.then(function (data) {
            layoutService.setCurrentSummaryLayout(layout.entityType, data.data);
            $scope.layoutSelectorModel.tenantDefaultSelected = layout.tenantDefault;
            $rootScope.$broadcast("newLayoutSelected", layout.entityType);
          });
          $timeout(function () {
            $scope.refreshLayoutList();
          }, 2000); // wait a second before refreshing the list to make sure new layouts have been indexed.
        }
      });
      $scope.refreshLayoutList = function () {
        $scope.layoutSelectorModel.loadingLayouts = true;
        layoutService.getSummaryLayoutsByEntityType($scope.entityType).then(function (data) {
          var layouts = data.data;
          layouts.unshift(layoutService.getDefaultLayoutForEntityType($scope.entityType));
          $scope.layoutSelectorModel.layouts = layouts;
          $scope.layoutSelectorModel.loadingLayouts = false;
          $scope.layoutSelectorModel.loaded = true;
        });
      };
      $scope.$on('loadSection', function (event, sectionBn, forceReload) {
        if ($scope.sectionBn == sectionBn) {
          if ($scope.layoutSelectorModel.loaded == false || forceReload) {
            $scope.refreshLayoutList();
          }
        }
      });
    }])
  .controller('SummaryLayoutCtrl', ['$scope', '$rootScope', '$timeout', '$http', '$compile', 'alertService', 'layoutService', 'urlService', 'bitConstants', 'filterService',
    function ($scope, $rootScope, $timeout, $http, $compile, alertService, layoutService, urlService, bitConstants, filterService) {
      $scope.layoutModel = {
        layoutBn: null,
        layout: null,
        loaded: false,
        modalVisible: false,
        filters: null
      };
      $rootScope.isPdf = urlService.getIsPdf();

      // Trigger URL tracking
      function triggerUrlUpdate() {
        $rootScope.$broadcast('summaryViewLoaded', {
          sectionBn: urlService.getSectionBnFromUrl(),
          viewId: urlService.getViewIdFromUrl(),
          summaryId: $scope.layoutModel.layoutBn || null
        });
      }

      $scope.getContent = function () {
        $scope.layoutModel.filterSpec = filterService.getTableFilterSpec($scope.sectionBn);
        var layout = layoutService.getCurrentSummaryLayout($scope.entityType);
        $scope.$broadcast('layoutModeChange', 'sl-view');

        // 0. Clear layouts
        $scope.layoutModel.layout = null;

        // 1. Wait for sections to unload
        $timeout(function () {
          $scope.layoutModel.layout = layout;
          $scope.layoutModel.layoutBn = layout.bn;
          triggerUrlUpdate();

          // 2. Wait a for summary layout sections to render before telling them to load
          $timeout(function () {
            $.each($scope.layoutModel.layout.sections, function () {
              $scope.$broadcast('loadSection', this.bn);
            });
          });
        });
      };

      $scope.layoutModeChange = function (modeClass) {
        $rootScope.$broadcast('layoutModeChange', modeClass);
      };
      if ($rootScope.isPdf) {
        loadStyleSheet('/b/css/build/datasheet-pdf.css');
      }
      $('body').on('click', function () {
        $rootScope.$broadcast('closeAllOpenEditPopups');
        $rootScope.$apply();
      });

      $scope.$on('changesMade', function (event) {
        alertService.clearAllAlerts();
      });

      $scope.$on('newLayoutSelected', function (event, entityType) {
        if (entityType == $scope.entityType) {
          $scope.getContent();
        }
      });
      $scope.$on('load', function (event, viewId) {
        if (!viewId) return;
        if (event.targetScope.$id == event.currentScope.$id) {
          if (!$scope.layoutModel.loaded) {
            $scope.getContent();
            $scope.layoutModel.loaded = true;
          }
        }
      });
      $scope.$on('reload', function (event, viewId) {
        if (!viewId) return;
        if (event.targetScope.$id == event.currentScope.$id) {
          $scope.getContent();
          $scope.layoutModel.loaded = true;
        }
      });
    }])
  .controller('SummaryLayoutEditCtrl', ['$window', '$scope', '$rootScope', '$timeout', '$http', '$compile', 'alertService', 'layoutService', 'urlService',
    'bitConstants', 'workingModel', 'assocCode', 'entityType', 'entityName', 'chooseDataSource', 'filter', '$modalInstance', '$modal', 'redux',
    function ($window, $scope, $rootScope, $timeout, $http, $compile, alertService, layoutService, urlService, bitConstants, workingModel, assocCode, entityType, entityName, chooseDataSource, filter, $modalInstance, $modal, redux) {
      $scope.workingModel = workingModel;
      $scope.isNew = false;
      if ($scope.workingModel.bn.indexOf("NEW_") > -1) {
        $scope.isNew = true;
      }
      $scope.assocCode = assocCode;
      $scope.entityType = entityType;
      $scope.entityName = entityName;
      $scope.chooseDataSource = chooseDataSource;
      $scope.layoutEditModel = {
        errors: []
      };

      $scope.filterModel = {};
      $scope.filterModel.filter = filter;

      $scope.entityTypeDisplayName = "Layout";
      if ($scope.entityType == null) {
        $scope.entityType = 'DSH';
        $scope.workingModel.entityType = 'DSH';
        $scope.entityTypeDisplayName = "Dashboard";
      }

      $scope.saveLayout = function () {
        var dashboardInsightBn = Object.keys(InsightTypes)
          .find(key => InsightTypes[key].typeCode === 'LYT');
        $scope.workingModel.insightType = { bn: dashboardInsightBn };
        alertService.clearAllAlerts();
        $scope.updateLocationInfo();
        if ($.trim($scope.workingModel.name).length > 0) {
          layoutService.updateLayout($scope.workingModel, $scope.entityType).then(function (data) {
            data.data.chooseDataSource = true;
            if ($scope.workingModel.entityType === 'DSH') {
              $rootScope.$broadcast("customDashboardUpdated", data.data);
            }
          });
          $modalInstance.close();
        } else {
          $scope.layoutEditModel.errors.push("'Name' is required.");
        }
      };
      $scope.cancelLayoutEditing = function () {
        alertService.clearAllAlerts();
        $modalInstance.close();
      };
      $scope.deleteLayout = function () {
        var confirmDelete = confirm(String.format("This will permanently delete {0} '{1}' It will no longer be available to any users of Barometer. This action cannot be undone. Do you want to continue?", $scope.entityTypeDisplayName, ($scope.workingModel.name || "")));
        if (confirmDelete == true) {
          layoutService.deleteLayout($scope.workingModel, function () {
            $modalInstance.close();
            if ($scope.workingModel.entityType === 'DSH') {
              window.location.href = urlService.getBaseUrl() + 'customdashboards';
            }
          });
        }
      };
      $scope.updateLocationInfo = function () {
        $.each($('.modal-body .sl-section'), function (sectionIndex) {
          $(this).scope().section.order = sectionIndex;
          var sectionBn = $(this).data('section-bn');
          $.each($(this).find('.sl-column'), function (columnIndex) {
            $.each($(this).find('.sl-content-block'), function (blockIndex) {
              $(this).scope().contentBlock.sectionBn = sectionBn;
              $(this).scope().contentBlock.columnId = columnIndex;
              $(this).scope().contentBlock.order = blockIndex;
            });
          });
        });
      };
      $scope.addNewSection = function (layoutName) {
        var newOrder = $('.modal-body .sl-section').length;
        var newSection = {
          "bn": getSyntheticBnForNewEntity(), "name": "", "order": newOrder,
          "layoutSectionType": layoutName
        };
        if (!$scope.workingModel.sections) {
          $scope.workingModel.sections = [];
        }
        $scope.workingModel.sections.push(newSection);
        $timeout(function () {
          setupContentBlockDragAndDrop()
        }, 100);
      };
      $scope.openNewContentBlockWindow = function (sectionBn, columnId) {
        var newConfig = $scope.newContentBlockPlaceHolder(sectionBn, columnId);
        var dialogOptions = {
          backdrop: 'static',
          keyboard: true,
          templateUrl: '/b/js/src/bit.ng/summary/partials/summary-content-block-config.html',
          controller: 'ConfigurationCtrl',
          resolve: {
            workingModel: function () {
              return newConfig
            },
            entityType: function () {
              return $scope.entityType
            },
            entityName: function () {
              return $scope.entityName
            },
            chooseDataSource: function () {
              return $scope.chooseDataSource;
            }
          }
        };

        $modal.open(dialogOptions).opened.then(function () {
          $timeout(function () {
            prototype.setModalMaxHeights();
          }, 200);
        });
      };
      $scope.newContentBlockPlaceHolder = function (sectionBn, columnId) {
        // use sectionBn and columnId to select correct list of content blocks. get length.
        var orderOfNewContentBlock =
          $(".modal-body").find("[data-section-bn='" + sectionBn + "']").find("[data-column-id='" + columnId + "']").find(".sl-content-block").length;

        return {
          "bn": 'PLACEHOLDER_' + getSyntheticBnForNewEntity(), "columnId": columnId,
          "order": orderOfNewContentBlock, "size": "LARGE", "sectionBn": sectionBn,
          "configuration": {
            "bn": getSyntheticBnForNewEntity(),
            "contentBlockType": "METRIC",
            "limit": "5",
            "pageSize": "25",
            "sortDescending": true,
            "fieldBn": "",
            "measurement": "COUNT"
          }
        };
      };
      $scope.resizeContentBlock = function (content, size) {
        content.size = size;
        // trigger a resize of the chart
        $timeout(function () {
          $(window).resize();
          $rootScope.$broadcast('contentBlock:resized', {bn: content.bn});
        }, 100);
      };
      $scope.changeSectionLayout = function (section, layoutName) {
        section.layoutSectionType = layoutName;
      };
      $scope.getLayoutTypeActiveClass = function (section, layoutName) {
        if (section.layoutSectionType === layoutName) {
          return 'active';
        }
      };
      $scope.$on('layoutDeleteSuccessful', function (event, layout) {
        layoutService.setCurrentSummaryLayout($scope.entityType, null);
        $modalInstance.close();
      });
      $scope.$on('newContentBlockSaved', function (event, contentBlockAndEntityType) {
        var contentBlock = contentBlockAndEntityType.contentBlock;
        var entityType = null;
        if (contentBlockAndEntityType.entityType) {
          entityType = contentBlockAndEntityType.entityType.typeCode;
        }
        // make sure updates are for summary view in the correct entity section, if dashboard  (ie entityType is null) there are not multiple sections so let it pass
        if (entityType == $scope.entityType || !$scope.entityType || $scope.entityType === "DSH") {
          var duplicate = false;
          if ($scope.workingModel.contentBlocks != null && $scope.workingModel.contentBlocks.length > 25) {
            alert("You have reached the maximum number of widgets (25). Please remove a widget before adding more.")
          } else if ($scope.workingModel.contentBlocks) {
            // don't add a content block twice.  this can happen if multiple event listeners get registered when closing and re-opening the editor popup
            $.each($scope.workingModel.contentBlocks, function (index) {
              if (this.bn == contentBlock.bn) {
                duplicate = true;
              }
            });
          } else {
            //content blocks is undefined
            $scope.workingModel.contentBlocks = [];
          }
          if (!duplicate) {
            $scope.workingModel.contentBlocks.push(contentBlock);
          }
        }
      });
      $scope.$on('configurationSaved', function (event, updatedConfiguration) {
        $.each($scope.workingModel.contentBlocks, function (index) {
          if (this.configuration.bn == updatedConfiguration.bn) {
            this.configuration = updatedConfiguration;
            $scope.$broadcast('renderContentBlock', this.configuration.bn);
          }
        });
      });
    }])
  .controller('ContentBlockCtrl', ['$scope', '$rootScope', '$timeout', 'urlService', '$modal', 'layoutService',
    function ($scope, $rootScope, $timeout, urlService, $modal, layoutService) {
      $scope.contentBlockModel = {};
      $scope.getContentBlockClass = function (contentBlock) {
        return layoutService.getContentBlockClass(contentBlock);
      };
      $scope.contentBlockModel.editorPopupClass = "close";
      //$scope.layoutModel.queryBn = urlService.getQueryBn();
      $scope.deleteContentBlock = function () {
        if ($scope.contentBlock.bn.indexOf('NEW_') == -1) {
          $scope.contentBlock.bn = 'DELETE';
        } else {
          // remove completely from json if NEW
          $.each($scope.workingModel.contentBlocks, function () {
            if (this.bn == $scope.contentBlock.bn) {
              $scope.workingModel.contentBlocks.splice($.inArray(this, $scope.workingModel.contentBlocks), 1);
            }
          });
        }
      };
      $scope.$on('closeAllOpenEditPopups', function (event) {
        $scope.contentBlockModel.editorPopupClass = "close";
      });
      $scope.isSizeSelected = function (size) {
        if (size == $scope.contentBlock.size) return 'active';
      };

      //helper functions to get chart resizing dimensions
      $scope.getChartContainerHeight = function (containerId, scale) {
        var containerWidth = $('#' + containerId).width(); //get parent container width
        return containerWidth * scale;
      };
      $scope.getChartContainerWidth = function (containerId) {
        var containerWidth = $('#' + containerId).width(); //get parent container width
        return containerWidth * .98;
      };
      $scope.getDescriptionClass = function () {
        if ($scope.contentBlock.configuration.contentBlockType == "TEXT") {
          return 'sl-text-block';
        }
      };
      $scope.configureContentBlock = function () {
        var dialogOptions = {
          backdrop: 'static',
          keyboard: true,
          templateUrl: '/b/js/src/bit.ng/summary/partials/summary-content-block-config.html',
          controller: 'ConfigurationCtrl',
          resolve: {
            workingModel: function () {
              return $scope.contentBlock
            },
            entityType: function () {
              return $scope.entityType
            },
            entityName: function () {
              return $scope.entityName
            },
            chooseDataSource: function () {
              return $scope.chooseDataSource;
            }
          }
        };

        $modal.open(dialogOptions).opened.then(function () {
          $timeout(function () {
            prototype.setModalMaxHeights();
          }, 200);
        });
      };

      $scope.ifWaitToLoad = function() {
        return $scope.filterModel?.filter?.isLoading;
      };

      setupContentBlockDragAndDrop();
    }])
  .controller('ConfigurationCtrl', ['$scope', '$rootScope', '$timeout', 'bitConstants', 'layoutService', 'workingModel',
    'entityType', 'entityName', 'chooseDataSource', '$modalInstance', 'tableService', 'alertService', 'assocService', '$filter', 'priorityEntitiesService', 'redux', 'tenantService',
    function ($scope, $rootScope, $timeout, bitConstants, layoutService, workingModel, entityType, entityName, chooseDataSource, $modalInstance, tableService, alertService, assocService, $filter, priorityEntitiesService, redux, tenantService) {

      $scope.workingModel = workingModel;
      $scope.entityName = entityName;
      $scope.chooseDataSource = chooseDataSource;
      var categoryArray = [
        {id: 'ALL', name: 'All'},
        {id: 'Attribute', name: 'Basic Attributes'},
        {id: 'Association', name: 'Associations'},
        {id: 'Custom', name: 'Custom Attributes'}
      ];
      var sortsArray = [
        {id: true, name: 'Descending'},
        {id: false, name: 'Ascending'}
      ];
      $scope.configurationModel = {
        adHocQuerySelectOptions: {
          allowClear: false,
          placeholder: 'Select a report'
        },
        summarizeValueSelectOptions: {
          allowClear: false,
          placeholder: 'Select a value to summarize'
        },
        ruleSelectOptions: {
          allowClear: false,
          placeholder: 'Select a rule'
        },
        entityType: null,
        categories: categoryArray,
        selectedCategory: categoryArray[0],
        sorts: sortsArray,
        selectedSummaryValue: null,
        dataSourceType: "all"
      };

      $scope.queriesLoaded = false;
      $scope.rulesLoaded = false;

      const { store, reducers } = redux;
      store.manager.add('summary', reducers.summary);

      $scope.initValueToSummarize = function () {
        // init values to summarize
        var summaryPropsPromise = layoutService.getPropertyListForSummary($scope.configurationModel.entityType.typeCode);
        summaryPropsPromise.then(function (data) {
          $scope.configurationModel.summaryProps = data.data;

          if ($scope.workingModel.configuration.contentBlockType == 'METRIC') {
            var selfCountProperty = {
              "bn": "SELF",
              "dataType": "SELF",
              "name": String.format("Total {0} count", EntityTypes[$scope.configurationModel.entityType.typeCode]),
              "type": "ALL"
            };
            $scope.configurationModel.summaryProps.push(selfCountProperty);
          }
          $($scope.configurationModel.summaryProps).each(function () {
            if (this.bn == $scope.workingModel.configuration.fieldBn) {
              $scope.configurationModel.selectedSummaryFieldName = this.name;
              $scope.configurationModel.selectedDataType = this.dataType;
              $scope.configurationModel.selectedFilterType = this.displayType;
              $scope.setAvailableMeasurements();
            }
          });
          if ($scope.configurationModel.selectedSummaryFieldName == null) {
            $scope.configurationModel.selectedSummaryFieldName = "---- Choose One ----"
          }

          $timeout(function () {
            //set the selected summaryField if one is already configured
            var nativeElem = $("#valueToSummarize");
            _.each($scope.configurationModel.summaryProps, function (field) {
              if (field.bn == $scope.workingModel.configuration.fieldBn) {
                $scope.configurationModel.selectedSummaryValue = field;
                // set the selected value
                nativeElem.find("option[value='" + angular.toJson(field) + "']").attr('selected', 'selected');
              }
            });
            // kick off select2
            nativeElem.select2($scope.configurationModel.summarizeValueSelectOptions);
            // on change of summarize value dropdown, set the values on the model
            nativeElem.on('change', function () {
              $scope.selectSummaryField($(this).val());
            });
            nativeElem.show();
          }, 1);

        });
      };

      $scope.initCountProperties = function () {
        var countPropsPromise = layoutService.getPropertyListForCount($scope.configurationModel.entityType.typeCode);
        countPropsPromise.then(function (data) {
          $scope.configurationModel.countProps = data.data;
          $($scope.configurationModel.countProps).each(function () {
            if (this.bn == $scope.workingModel.configuration.fieldBn) {
              $scope.configurationModel.selectedSummaryFieldName = this.name;
              $scope.configurationModel.selectedDataType = this.dataType;
            }
          });
          if ($scope.configurationModel.selectedSummaryFieldName == null) {
            $scope.configurationModel.selectedSummaryFieldName = "---- Choose One ---- "
          }
        });
      };

      $scope.initDataSourceOptions = function () {
        if ($scope.chooseDataSource) {
          // init data source options
          const entityTypeCode = $scope.configurationModel.entityType.typeCode;
          // Get the cached reports state.
          const reportsState = redux.store.getState().summary.reports;
          // Use the cashed reports that were stored from the last load (if they exist).
          $scope.advancedQueries = (reportsState[entityTypeCode] != null) ? reportsState[entityTypeCode] : [];
          // Get the list of reports from the server.
          layoutService.getAdvancedQueriesForTypeCode(entityTypeCode).then(function (data) {
            $scope.advancedQueries = data.data;
            // Store a list of reports by entityType to Redux
            const storedReports = {entityType: entityTypeCode, reports: $scope.advancedQueries};
            redux.store.dispatch(
              redux.actions.updateSummaryReports(storedReports)
            );
            $scope.queriesLoaded = true;
            // check if configuration already has a datasource set (edit scenario), if so init it
            if ($scope.workingModel.configuration.dataSource && $scope.workingModel.configuration.dataSource.type === 'AdHocReport') {
              // check to make sure the advancedQueryBn exists in the list of available.
              $scope.configurationModel.dataSourceType = 'advancedQuery';
              var foundAdvQuery = false;
              _.each($scope.advancedQueries, function (advancedQuery, index) {
                if (advancedQuery.bn == $scope.workingModel.configuration.dataSource.bn) {
                  foundAdvQuery = true;
                  $scope.configurationModel.entityType = EntityTypes[advancedQuery.type];
                  $scope.configurationModel.advancedQueryDataSource = advancedQuery;
                }
              });
            }
            // wrap this in a timeout to allow the options to render before kicking off select2
            $timeout(function () {
              //set the selected data source if one is already configured
              var dataSourceNativeElement = $("#advQueryDataSourceChoices");
              if ($scope.workingModel.configuration.dataSource && $scope.configurationModel.advancedQueryDataSource) {
                dataSourceNativeElement.find("option[value='" + $scope.configurationModel.advancedQueryDataSource.bn + "']").attr('selected', 'selected');
              }
              // kick off select2
              dataSourceNativeElement.select2($scope.configurationModel.adHocQuerySelectOptions);
              // on change of summarize value dropdown, set the values on the model
              dataSourceNativeElement.on('change', function () {
                var bn = this.value;
                $scope.configurationModel.advancedQueryDataSource = _.find($scope.advancedQueries, function(advQuery) {return advQuery.bn = bn});
              });
              //dataSourceNativeElement.show();
            }, 1);
          });

          // Get the cached rules state.
          const rulesState = redux.store.getState().summary.rules;
          // Use the cashed rules that were stored from the last load (if they exist).
          $scope.rules = (rulesState[entityTypeCode] != null) ? rulesState[entityTypeCode] : [];
          // Get the list of rules from the server.
          layoutService.getComplianceRulesForTypeCode(entityTypeCode).then(function (data) {
            $scope.rules = data.data;
            // Store a list of rules by entityType to Redux
            const storedRules = {entityType: entityTypeCode, rules: $scope.rules};
            redux.store.dispatch(
              redux.actions.updateSummaryRules(storedRules)
            );
            $scope.rulesLoaded = true;
            // check if configuration already has a datasource set (edit scenario), if so init it
            if ($scope.workingModel.configuration.dataSource && $scope.workingModel.configuration.dataSource.type === 'Rule') {
              // check to make sure the advancedQueryBn exists in the list of available.
              $scope.configurationModel.dataSourceType = 'rule';
              var foundRule = false;
              _.each($scope.rules, function (rule, index) {
                if (rule.bn == $scope.workingModel.configuration.dataSource.bn) {
                  foundRule = true;
                  $scope.configurationModel.entityType = EntityTypes[rule.type];
                  $scope.configurationModel.ruleDataSource = rule;
                }
              });
//                           if (!foundAdvQuery) {
//                               alertService.addErrorAlert("The data source for this widget no longer exists.  Please select a new datasource.");
//                           }
            }
            // wrap this in a timeout to allow the options to render before kicking off select2
            $timeout(function () {
              //set the selected data source if one is already configured
              var dataSourceNativeElement = $("#ruleDataSourceChoices");
              if ($scope.workingModel.configuration.dataSource && $scope.configurationModel.ruleDataSource) {
                dataSourceNativeElement.find("option[value='" + $scope.configurationModel.ruleDataSource.bn + "']").attr('selected', 'selected');
              }
              // kick off select2
              dataSourceNativeElement.select2($scope.configurationModel.ruleSelectOptions);
              // on change of summarize value dropdown, set the values on the model
              dataSourceNativeElement.on('change', function () {
                var bn = this.value;
                $scope.configurationModel.ruleDataSource = _.find($scope.rules, function(rule) { return rule.bn = bn; });
              });
              //dataSourceNativeElement.show();
            }, 1);
          });
        }
      };

      $scope.initConfigOptions = function () {
        if ($scope.configurationModel.entityType) {
          $scope.initValueToSummarize();
          $scope.initCountProperties();
          $scope.initDataSourceOptions();
          layoutService.getPropertyListForGrouping($scope.configurationModel.entityType.typeCode).then(function(data){
            $scope.configurationModel.groupableProps = data.data;
          });
        }
        $scope.configurationModel.countPropsList = null;
        $scope.configurationModel.summaryPropsList = null;
        $scope.configurationModel.sortFields = [];
        $scope.configurationModel.availableMeasurements = [];
        $scope.configurationModel.summarizeCustomField = false;
        $scope.configurationModel.countEditorPopupClass = "close";
        $scope.configurationModel.summaryEditorPopupClass = "close";
        $scope.configurationModel.advancedQueryDataSource = null;

        if ($scope.workingModel.configuration.contentBlockType == null || $scope.workingModel.configuration.contentBlockType == "") {
          $scope.workingModel.configuration.contentBlockType = "METRIC";
        }

      };

      function initEntityTypes() {
        var types = bitConstants.getAdvancedQueryEntityTypes();
        _.forEach(types, function(type) {
          if (priorityEntitiesService.getUsePersonalPriorityEntitiesFlag()) {
            type.groupName = (priorityEntitiesService.isPersonalPriorityEntityType(type.typeCode)) ? 'Priority Catalogs' : 'All Entities';
            type.groupId = (type.groupName == 'Priority Catalogs') ? (0 + type.displayName) : (1 + type.displayName);
          } else {
            type.groupName = (priorityEntitiesService.isTenantPriorityEntityType(type.typeCode)) ? 'Priority Catalogs' : 'All Entities';
            type.groupId = (type.groupName == 'Priority Catalogs') ? (0 + type.displayName) : (1 + type.displayName);
          }
        });
        return types;
      }

      // called when the entity type changes or widget type changes
      $scope.resetConfiguration = function () {
        var entityTypeCode = this.model;
        if(entityTypeCode) {
          $scope.configurationModel.entityType = _.find(this.entityTypes, function (type) {
            return type.typeCode === entityTypeCode
          });
        }
        $scope.configurationModel.advancedQueryDataSource = null;
        $scope.configurationModel.ruleDataSource = null;
        $scope.configurationModel.dataSourceType = 'all';
        $scope.workingModel.configuration.fieldBn = null;
        $scope.workingModel.configuration.columnSpecBn = null;
        $scope.queriesLoaded = false;
        $scope.rulesLoaded = false;
        $scope.configurationModel.selectedSummaryFieldName = "---- Choose One ---- ";
        $scope.initConfigOptions();
      };

      $scope.changeDataSourceType = function (val) {
        $scope.configurationModel.ruleDataSource = null;
        $scope.configurationModel.advancedQueryDataSource = null;
        // $scope.initDataSourceOptions();
      };

      //Custom filter - used to filter the available 'value to summarize' by the category selected in the dropdown
      $scope.categoryFilter = function (data) {
        var match = false;
        if ($scope.configurationModel.selectedCategory.id == 'ALL') {
          match = true;
        }
        if (data.type == $scope.configurationModel.selectedCategory.id) {
          match = true;
        }
        return match;
      };

      $scope.saveConfiguration = function () {
        // validate
        alertService.clearAllAlerts();
        var valid = true;
        $scope.configurationModel.configErrors = [];
        if ($scope.workingModel.configuration.contentBlockType != "TABLE" && $scope.workingModel.configuration.contentBlockType != "TEXT" && $scope.workingModel.configuration.contentBlockType != "WORKSHEET") {
          if ($scope.workingModel.configuration.fieldBn == null || $scope.workingModel.configuration.fieldBn == "") {
            alertService.addErrorAlert("A 'Value to Summarize' is required.");
            valid = false;
          }
        }
        if ($scope.workingModel.configuration.contentBlockType == "METRIC") {
          if ($scope.workingModel.configuration.measurement == null || $scope.workingModel.configuration.measurement == "") {
            alertService.addErrorAlert("A 'Metric' is required.");
            valid = false;
          }
        }
        if ($scope.workingModel.configuration.description) {
          if ($scope.workingModel.configuration.description.length > 512) {
            alertService.addErrorAlert("The description text cannot exceed 512 characters");
            valid = false;
          }
        }
        if ($scope.workingModel.configuration.name) {
          if ($scope.workingModel.configuration.name.length > 256) {
            alertService.addErrorAlert("The widget name text cannot exceed 256 characters.");
            valid = false;
          }
        }
        if ($scope.chooseDataSource && $scope.workingModel.configuration.contentBlockType != "TEXT") {
          if ($scope.configurationModel.dataSourceType === 'advancedQuery' && !$scope.configurationModel.advancedQueryDataSource) {
            alertService.addErrorAlert("A 'Data Source' is required.");
            valid = false;
          }
        }
        if ($scope.chooseDataSource && $scope.workingModel.configuration.contentBlockType === "WORKSHEET") {
          if (!$scope.configurationModel.worksheet) {
            alertService.addErrorAlert("A Worksheet is required.");
            valid = false;
          }
        }
        if ($scope.workingModel.name) {
          if ($scope.workingModel.name.length > 256) {
            alertService.addErrorAlert("The layout name text cannot exceed 256 characters.");
            valid = false;
          }
        }
        if (valid) {

          var contentBlockBn = $scope.workingModel.bn;

          if ($scope.workingModel.configuration.contentBlockType === "WORKSHEET" || $scope.workingModel.configuration.contentBlockType === "TEXT") {
            delete $scope.workingModel.configuration.dataSource;
            delete $scope.workingModel.configuration.entityType;
            delete $scope.workingModel.configuration.dataSource;
            if ($scope.configurationModel.worksheet) {
              $scope.workingModel.configuration.fieldBn = $scope.configurationModel.worksheet.entity.bn;
            }
          } else {
            if ($scope.configurationModel.advancedQueryDataSource) {
              $scope.workingModel.configuration.dataSource = {};
              $scope.workingModel.configuration.dataSource.bn = $scope.configurationModel.advancedQueryDataSource.bn;
              $scope.workingModel.configuration.dataSource.type = "AdHocReport";
            } else if ($scope.configurationModel.ruleDataSource) {
              $scope.workingModel.configuration.dataSource = {};
              $scope.workingModel.configuration.dataSource.bn = $scope.configurationModel.ruleDataSource.bn;
              $scope.workingModel.configuration.dataSource.type = "Rule";
            } else {
              delete $scope.workingModel.configuration.dataSource;
            }

            if ($scope.configurationModel.entityType) {
              $scope.workingModel.configuration.entityType = $scope.configurationModel.entityType.typeCode;
            }
          }

          if (contentBlockBn.indexOf("PLACEHOLDER_") >= 0) {
            var placeHolderBn = $scope.workingModel.bn;
            $scope.workingModel.bn = placeHolderBn.replace("PLACEHOLDER_", "");
            $rootScope.$broadcast('newContentBlockSaved', {
              "contentBlock": $scope.workingModel,
              "entityType": $scope.configurationModel.entityType
            });
          } else {
            $rootScope.$broadcast('configurationSaved', $scope.workingModel.configuration);
          }
          $modalInstance.close();
        }
      };
      $scope.saveTableConfiguration = function () {
        // make call to new rest service to persist the column spec. it should return a column spec bn5
        tableService.updateColumns($scope.workingModel.configuration.columnSpecBn, $scope.workingModel.customColumn.selectedColumns).then(function (data) {
          $scope.workingModel.configuration.columnSpecBn = data.data;
          $scope.saveConfiguration();
        });
      };
      $scope.cancelConfiguration = function () {
        $modalInstance.close();
      };
      $scope.isTypeSelected = function (type) {
        if ($scope.workingModel.configuration.contentBlockType === type) {
          return 'active';
        }
      };
      $scope.selectSummaryField = function (field) {
        $timeout(function () {
          var fieldObj = angular.fromJson(field);
          $scope.configurationModel.selectedSummaryFieldName = fieldObj.name;
          $scope.configurationModel.selectedDataType = fieldObj.dataType;
          $scope.configurationModel.selectedFilterType = fieldObj.displayType;
          $scope.workingModel.configuration.fieldBn = fieldObj.bn;
          $scope.workingModel.configuration.measurement = "COUNT";
          $scope.configurationModel.countEditorPopupClass = "close";
          $scope.configurationModel.summaryEditorPopupClass = "close";
          $scope.setAvailableMeasurements();
        });
      };
      $scope.setAvailableMeasurements = function () {
        var type = $scope.configurationModel.selectedDataType;
        var filterType = $scope.configurationModel.selectedFilterType;
        var isNumber = (type == "INTEGER" || type == "DECIMAL" || type == "MONEY" );
        var isDate = (type == "DATE" || type == "DATE_TIME" || type == "TIME" );
        var isMultiSelect = (filterType == "MULTI_SELECT")
        if (isNumber && !isMultiSelect) {
          $scope.configurationModel.availableMeasurements = [
            {"value": "COUNT", "name": "Count"}
            ,
            {"value": "AVERAGE", "name": "Average"}
            ,
            {"value": "SUM", "name": "Sum"}
            ,
            {"value": "MINIMUM", "name": "Minimum"}
            ,
            {"value": "MAXIMUM", "name": "Maximum"}
          ];
        } else if (isDate && !isMultiSelect) {
          $scope.configurationModel.availableMeasurements = [
            {"value": "COUNT", "name": "Count"}
            ,
            {"value": "AVERAGE", "name": "Average"}
            ,
            {"value": "MINIMUM", "name": "Minimum"}
            ,
            {"value": "MAXIMUM", "name": "Maximum"}
          ];
        } else {
          $scope.configurationModel.availableMeasurements = [
            {"value": "COUNT", "name": "Count"}
          ];
          $scope.workingModel.configuration.measurement = "COUNT";
        }
      };

      $scope.$on('closeAllOpenEditPopups', function (event) {
        $scope.configurationModel.countEditorPopupClass = "close";
        $scope.configurationModel.summaryEditorPopupClass = "close";
      });

      if (entityType && entityType != 'DSH') {
        // entity type is pre-defined via "resolve" if this is a summary view
        $scope.configurationModel.entityType = EntityTypes[entityType];
      } else if ($scope.workingModel.configuration.entityType) {
        // when editing an existing content block on the dashboard the config model will have the entity type.
        $scope.configurationModel.entityType = EntityTypes[$scope.workingModel.configuration.entityType];
      }
      // init entity type options
      $scope.entityTypes = initEntityTypes();

      $scope.getWorksheets = function () {
        // Get all worksheets that are allowed.
        const insightTypeBnsForWorksheets = tenantService.getCurrentTenantsInsightCatalogs()
          .filter(ist  => InsightTypes[ist.bn].typeCode === 'WK2')
          .map(ist => ist.bn);
        const promiseList = insightTypeBnsForWorksheets.map(bn => assocService.getAllWorkSheetsByInsightTypeBn(bn));

        return Promise.all(promiseList).then(([...results]) => {
          $scope.worksheets = [];

          results.forEach(results => {
            const data = results.data;
            $scope.worksheets = [...$scope.worksheets, ...data.rows];
          });

          if ($scope.workingModel.configuration.contentBlockType === "WORKSHEET") {
            $.each($scope.worksheets, function (index, obj) {
              if (obj.entity.bn === $scope.workingModel.configuration.fieldBn) {
                $scope.configurationModel.worksheet = obj;
              }
            });
          }
        })
      };

      $scope.getWorksheets()
        .then($scope.initConfigOptions);


    }])
  .controller('ColumnSpecController', ['$scope', 'urlService', 'wicketMarkupService',
    function ($scope, urlService, wicketMarkupService) {
      $scope.columnSpecModel = {};
      var columnSpecBn = null;
      if ($scope.workingModel != null) {
        columnSpecBn = $scope.workingModel.configuration.columnSpecBn;
      }
      var urlBase = urlService.prepareWicketPageUrl('advancedquerycolumnspecresponse');
      var entityTypeBnCode = EntityTypes[$scope.entityType].bnCode;
      $scope.columnSpecModel.url = urlBase + '?entityType=' + entityTypeBnCode
        + '&columnSpecBn=' + columnSpecBn;
      $scope.columnSpecModel.promise = wicketMarkupService.getMarkup($scope.columnSpecModel.url);
      $scope.columnSpecModel.promise.then(function (data) {
        $scope.columnSpecModel.data = data.data;
      });
      $scope.columnSpecModel.columnSpecBn = columnSpecBn;
      $scope.columnSpecModel.entityTypeBnCode = entityTypeBnCode;
    }])
;

function setupContentBlockDragAndDrop() {
  var startingColumnStyle;
  $('.sl-edit .sl-column').sortable({
      //cancel: '.sl-view *',   // only works in edit mode...
      items: '.sl-content-block',
      connectWith: '.sl-edit .sl-column',
      placeholder: 'drop-highlight',
      scrollSensitivity: 50,
      tolerance: 'pointer',
      distance: 3, // dist before draging starts, makes it easier to click an item on the drag handle
      revert: 200, //duration in millis for animation
      start: function (event, ui) {
        ui.placeholder.height(ui.item.height());
        ui.placeholder.width(ui.item.width());
        ui.item.data('start', ui.item.index());
        startingColumnStyle = $(ui.item).closest('.sl-column').attr('class');
      },
      stop: function (event, ui) {
        // change block to large to fill space when dropped. this provides a more consistent ui feel
        var newColumnStyle = $(ui.item).closest('.sl-column').attr('class');
        if (startingColumnStyle != newColumnStyle) {
          // default to full width since we don't know what the width should be
          ui.item.scope().contentBlock.size = 'LARGE';
        }

        // trigger chart resize
        $(window).resize();

        $('body').scope().$broadcast('changesMade');
        $('body').scope().$apply();
      }
    })
    // prevent disabling of inputs
    .find('input').bind('click.sortable mousedown.sortable', function (e) {
    e.stopImmediatePropagation();
  });
}
