angular.module('barometerApp.insight')
  .service('insightTypeService', ['$http', '$rootScope', 'alertService', 'utilService',
    function($http, $rootScope, alertService, utilService) {

      const getInsightTypes = function(bn) {
        const baseUrl = '/b/api/insightTypes?verbose=true';
        const url = (bn != null && utilService.isBn(bn))
          ? `${baseUrl}&bn=${bn}`
          : baseUrl;
        return $http({
          method: 'GET',
          url: url
        }).success(function (data, status, headers, config) {
        }).error(function (data, status, headers, config) {
          console.error('error in insightTypeService.getInsightTypes');
        });
      };

      const getInsightTypeTags = function() {
        return $http({
          method: 'GET',
          url: '/b/api/insightTypeTags'
        }).success(function (data, status, headers, config) {
        }).error(function (data, status, headers, config) {
          console.error('error in insightTypeService.getInsightTypeTags');
        });
      };

      return {
        getInsightTypes,
        getInsightTypeTags
      }
    }
  ]);
