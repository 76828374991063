angular
  .module('barometerApp.remediationForm')
  .directive('remediationFormBuilder', remediationFormBuilder);

remediationFormBuilder.$inject = ['$compile'];

function remediationFormBuilder($compile) {
  //
  var directive = {
    /**
     * Controller constructor function.
     */
    controller: 'RemediationFormBuilderController',
    /**
     * If set to true then the template will replace the current element, rather than append the template to the element.
     */
    //replace: false, // default
    /**
     * Create a new isolate scope. Declare and/or bind model properties.
     */
    //scope: {
    // AngularJS operators:
    // @ or @attr - bind a local scope property to the value of DOM attribute.
    // = or =attr - set up bi-directional binding between a local scope property and the parent scope property of name defined via the value of the attr attribute.
    // & or &attr - provides a way to execute an expression in the context of the parent scope.
    // If no property name is provided to the right of the operator, the property name is assumed to be identical to the one provided to the left.
    //},
    /**
     * AngularJS directive best practice: "Unless your template is very small, it's typically better to break
     * it apart into its own HTML file and load it with the templateUrl option." Called implicitly by AngularJS.
     *
     * @returns {string}
     */
    templateUrl: function () {
      // This defines the HTML table.
      return "/b/js/src/bit.ng/remediation-form-builder/partials/form-builder.html";
    },
    /**
     * The link function is responsible for registering DOM listeners as well as updating the DOM.
     * It is executed after the template has been cloned. This is where most of the directive logic will be put.
     * This property is used only if the compile property is not defined.
     */
    link: function(scope, element, attrs) {
      //
      scope.cancelFormBuilder = function () {
        scope.$broadcast('remediationFormBuilderCancelled');
        // TODO This is a bit dopey, since the modal open is above the scope of this directive (a scope mismatch).
        // TODO Move up?
        scope.formBuilder.modal.close('cancel');
      };
      //
      scope.createForm = function(formInvalid, selectedColumns) {
        scope.createFormEntity(selectedColumns);
        scope.formBuilder.modal.close('save');
      }
    }
  };
  return directive;
}