/**
 * Use controllers to:
 * Set up the initial state of a scope object (data, aka the Model, incl. promise functions).
 * Add behavior to the scope object (behavioral functions).
 *
 * Do not use controllers for:
 * Any kind of DOM manipulation.
 * Input formatting.
 * Output filtering.
 * To run stateless or stateful code shared across controllers.
 * To instantiate or manage the life-cycle of other components.
 *
 * You can associate controllers with scope objects implicitly via the ngController directive or $route service.
 *
 * This file tries to follow John Papa's Angular 1 style guide (adjusted to our AngularJS version).
 *
 * https://github.com/johnpapa/angular-styleguide/blob/master/a1/README.md
 */
angular
  .module('barometerApp.simpleTable')
  .controller('SimpleTableController', SimpleTableController);

SimpleTableController.inject = ['$scope'];

/**
 * Registered with the corresponding directive.
 *
 * @param $scope
 * @param $element
 * @param $attrs
 */
function SimpleTableController($scope) {

  /**
   * Prepare tableModel options. angular.extend "extends the destination object dst by copying own
   * enumerable properties from the src object(s) to dst." So, this copies $scope.options into opts so
   * they can be assigned (later) to tableModel.options.
   */
  var opts = angular.extend({
    displayAdd: false,
    displayFilters: false,
    displayPivot: false
  }, $scope.options || {});
  // TODO We're not actually leveraging these yet.
  $scope.tableModel.options = opts;

  // BEGIN CONTROL FUNCTIONS - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  /**
   * An initialization function.
   */
  function init() {
    //
    loadPage(1);
  };

  /**
   * Used by init function and loadSection event listener.
   *
   * @param pageNumber
   */
  function loadPage(pageNumber) {
    //
    $scope.tableModel.page.currentPage = pageNumber;
  }

  // BEGIN SCOPED FUNCTIONS - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  /**
   * Received a sort click.
   *
   * @param column
   */
  $scope.sortColumn = function (column) {
    //
    // Prepare model variables for update ...
    $scope.tableModel.sort.sortProperty = column.id;
    if (!column.sorted || column.sorted == 'DESC') $scope.tableModel.sort.sortOrder = 'ASC';
    else $scope.tableModel.sort.sortOrder = 'DESC';
    // Parent scope must implement updateTableModel().
    // Uses new model variables ...
    $scope.updateTableModel();
  };

  // BEGIN DO STUFF - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  init();
}