angular.module('barometerApp.workspace')
/**
 * Controller for linking functionality to work from the 'cards' in the 'In this Workspace' section.
 */
  .controller("WorkspaceLinkToSection", ['$rootScope', '$scope', function ($rootScope, $scope) {
    $scope.goToSection = function (sectionBn) {

      $rootScope.$broadcast('leftTabSelected', { sectionBn: sectionBn });

      // entityNavModel can be undefined - when the last assoc of a given type is removed.  See https://barometer.jira.com/browse/BARO-13928.
      if ($scope.entityNavModel) {
        $scope.entityNavModel.selectedSectionBn = sectionBn;
      }
    };
  }])
  /**
   * Controller for the "Add Entity" widget.
   *
   * Key to event-handling ...
   * - refreshCounts: produces
   */
  .controller('WorkspaceAddCtrl', ['$rootScope', '$scope', 'tableService', 'urlService', 'utilService', '$element',
    function ($rootScope, $scope, tableService, urlService, utilService, $element) {
      //
      $scope.entityBn = urlService.getEntityBn();
      $scope.showAddEntityToggle = true;
      $scope.showAddEntityWidget = false;

      // TODO Pretty sure this isn't working, for a few reasons.
      // Starting with ... the add control is not set up the same way on the visual view versus the collection view.
      if ($scope.entityModel.entityDiscriminator === 'WK2_LCR') {
        $scope.includeEntityTypes = ['ACT', 'CAP', 'COM', 'CON', 'DAT', 'DEM', 'PHY', 'AST', 'MKT', 'PRD', 'SKI', 'STA', 'SYS', 'STR', 'TEC'];
      } else {
        $scope.includeEntityTypes = ['ACT', 'CAP', 'COM', 'CON', 'DAT', 'DEM', 'PHY', 'AST', 'MKT', 'ORG', 'PRD', 'SKI', 'STA', 'SYS', 'STR', 'TEC'];
      }
      //
      /**
       * Open the Add Entity widget.
       */
      $scope.addEntityToggleClicked = function () {
        $scope.showAddEntityToggle = false;
        $scope.showAddEntityWidget = true;
        // Hacky, but there isn't a good way to do this in angular
        setTimeout(function () {
          $($element).find('.search').focus();
        }, 100);
      };
      /**
       * Add an entity to e.g. a Workspace.
       *
       * On dynamic entity pages, entities can be removed, but not added, from table sections.
       *
       * @param $event
       * @param entity
       */
      $scope.addEntity = function ($event, entity) {
        $event.preventDefault();
        if (!entity.isAssociated) {
          $scope.editPending = true;
          $scope.showAddEntityWidget = false;
          $scope.pendingEntityName = entity.name;
          var params = {
            sourceBn: $scope.entityBn,
            sectionBn: entity.entityType,
            targetBnCode: utilService.getBnCode($scope.entityBn),
            indexBn: entity.bn,
            relationshipType: RelationshipTypes["RELATED_TO"],
            associations: {
              addedAssocs: [{ "targetBn": entity.bn, "relationshipIdentifier": null, "relationshipType": RelationshipTypes['RELATED_TO'] }]
            }
          };
          // Note, tableService will update associatedBnCacheService.
          tableService.updateAssociations(params).then(function (data) {
            $scope.editPending = false;
            $scope.showAddEntityToggle = true;
            // TODO Are we just hitting this again with a hammer ...?
            // TODO ... cuz tableService.updateAssociations() broadcasts this anyhow.
            $rootScope.$broadcast("refreshCounts");
          }, function () {
            $scope.editPending = false;
            $scope.showAddEntityToggle = true;
          });
        }
      };
    }
  ])
  /**
   * Controller manages dynamic page section layout.
   *
   * Key to event-handling ...
   * - workspace_layoutPopulated: produces
   * - loadAllVisibleSections: produces
   * - refreshCounts: consumes
   * - sectionRenderComplete: produces, consumes
   */
  .controller('WorkspaceLayoutCtrl', ['$rootScope', '$scope', '$timeout', 'utilService', 'pageService', 'layoutService', 'layoutSupport', 'workspaceService', 'urlService',
    function ($rootScope, $scope, $timeout, utilService, pageService, layoutService, layoutSupport, workspaceService, urlService) {
      //
      /**
       * Discover the layout definition and initialize variables.
       */
      $scope.prepareLayout = function () {
        $scope.layoutModel = {
          layout: ""
        };
        var layoutDefinition = BnCodeToLayout[utilService.getBnCodeFromUrl()];
        $scope.layoutModel.layout = layoutSupport.cloneLayout(layoutDefinition);
        layoutService.setCurrentLayout($scope.layoutModel.layout);
        $scope.layoutModel.selectedSectionBn = urlService.getSectionBnFromUrl() || 'DASHBOARD';
        $scope.entityData = {
          groups: []
        };
      };
      /**
       * Use the layout definition and association counts to push sections into the page.
       */
      $scope.populateLayout = function () {
        var myEntityBn = urlService.getEntityBn();
        if (myEntityBn) {
          // TODO Improve on maxResults parameter here ...
          workspaceService.getAssociated(myEntityBn, 999).then(function (results) {
            $scope.entityData.totalCount = results.data.count;
            $scope.entityData.bn = results.data.bn;
            // Do the real work ...
            layoutSupport.updateRelatedSections(results, $scope.layoutModel);
            // Need to broadcast this out of scope, so that WorkspaceNavCtrl picks up on it.
            $rootScope.$broadcast("workspace_layoutPopulated");
          });
        }
        layoutService.setCurrentLayout($scope.layoutModel.layout);
      };
      /**
       * Do this when the page loads.
       */
      $scope.initializeLayout = function () {
        // Initialize layout state.
        $scope.prepareLayout();
        // Set default section before any more events fire.
        $scope.layoutModel.selectedSectionBn = 'BASIC';
        // Discover displayable sections.
        $scope.populateLayout();
      };
      /**
       * Do this whenever association counts change.
       */
      $scope.repopulateLayout = function () {
        // Re-discover displayable sections.
        $scope.populateLayout();
      };

      // Do it.
      $scope.initializeLayout();
      //
      /**
       * Force visible sections to draw in after the layout has been rendered.
       *
       * TODO
       */
      $scope.$on('sectionRenderComplete', function (event) {
        prototype.init();
        $scope.$broadcast("loadAllVisibleSections");
      });
      /**
       * This event (refreshCounts) is broadcast after all association table adds and removes.
       *
       * TODO
       */
      $scope.$on('refreshCounts', function (event) {
        $scope.repopulateLayout();
        // TODO
        $scope.$broadcast('sectionRenderComplete');
      });
    }])
  /**
   * Controller manages left-hand navigation.
   *
   * Key to event-handling ...
   * - workspace_layoutPopulated: consumes
   * - leftTabSelected: produces
   * - refreshCounts: consumes
   */
  .controller('WorkspaceNavCtrl', ['$rootScope', '$scope', 'layoutService', 'searchService', 'urlService', 'tableService', '$window',
    function ($rootScope, $scope, layoutService, searchService, urlService, tableService, $window) {
      //
      /**
       * Initialize our model.
       */
      $scope.entityNavModel = {
        counts: [],
        selectedSectionBn: "BASIC"
      };
      /**
       * Call the tableService to get fresh association counts.
       */
      $scope.refreshCounts = function () {
        var sourceEntityTypeCode = urlService.getEntityTypeCodeOfCurrentPage();
        var sourceBnCode = EntityTypes[sourceEntityTypeCode].bnCode;
        var countAssocs = layoutService.getCurrentLayout().countAssocs;
        var countsPromise = tableService.getCountsForAssociations({
          sourceBnCode: sourceBnCode,
          sourceBn: urlService.getEntityBn(),
          queryKey: urlService.getQueryKey(),
          targets: countAssocs
        });
        // After the service delivers results, update our model.
        countsPromise.then(function (data) {
          $scope.entityNavModel.counts = data.data;
        });
      };
      /**
       * Called by click events (incl. ng-click).
       */
      $scope.goToSection = function (sectionBn) {
        $rootScope.$broadcast('leftTabSelected', { sectionBn: sectionBn });
        $scope.entityNavModel.selectedSectionBn = sectionBn;
        $window.scrollTo(0, 0);
      };
      /**
       *
       * @param newLayout
       */
      $scope.reloadLeftNav = function (newLayout) {
        $scope.entityNavModel.sections = newLayout.sections;
        // Hydrate nav item data with title and icon details.
        $.each($scope.entityNavModel.sections, function () {
          this.iconClass = SectionTypeToIconAndTitle[this.type][0];
          this.title = SectionTypeToIconAndTitle[this.type][1];
        });
        $scope.refreshCounts();
      };
      /**
       * Whenever a fresh layout is pushed into the layoutService, update the left nav.
       */
      /*      $scope.$watch(layoutService.getCurrentLayout, function (newValue, oldValue, $scope) {
       // This is never reached now that we don't wipe the entire currentLayout.
       if (newValue) {
       $scope.reloadLeftNav(newValue);
       }
       });*/
      /**
       * TODO
       */
      $scope.$on('workspace_layoutPopulated', function (event) {
        $scope.reloadLeftNav(layoutService.getCurrentLayout());
      });
      /**
       * This event (refreshCounts) is broadcast after all association table adds and removes.
       *
       * TODO
       */
      $scope.$on('refreshCounts', function (event) {
        $scope.refreshCounts();
        if (!$scope.$$phase) {
          $scope.$apply();
        }
      });
    }])
  /**
   * Controller switches in appropriate visualization based on worksheet type.
   *
   * This information is already packaged into the basic info, so we tap into the event already emitted.
   *
   * Key to event-handling ...
   * - basicInfoLoaded: consumes
   * - loadAllVisibleSections: consumes
   * - sectionRenderComplete: produces, consumes
   */
  .controller('WorkspaceVizSwitcherCtrl', ['$rootScope', '$scope', 'urlService', 'optionListService', 'commonWorksheetService',
    function ($rootScope, $scope, urlService, optionListService, commonWorksheetService) {
      //
      $scope.entityOptionListModel = {};
      //

      if (!$scope.entityOptionListModel.loaded) {
        var entityTypeCodeOfCurrentPage = urlService.getEntityTypeCodeOfCurrentPage();
        var dataListTypes = EntityOptionListTypes[entityTypeCodeOfCurrentPage];
        if (dataListTypes) {
          var promise = optionListService.getOptionListChoices(dataListTypes);
          promise.then(function (results) {
            $scope.entityOptionListModel.data = [];
            _.forEach(results.data, function (item) {
              if (!$scope.entityOptionListModel.data[item.dataListType]) {
                $scope.entityOptionListModel.data[item.dataListType] = [];
              }
              $scope.entityOptionListModel.data[item.dataListType].push(item);
            });

          });
        }
        $scope.entityOptionListModel.loaded = true;
      }
      $scope.basicInfoLoaded = false;

      $scope.$on('basicInfoLoaded', function (event, basicInfo) {
        $scope.hasWorksheet = basicInfo.insightType.bn != null;
        $scope.worksheetTypeBn = basicInfo.insightType.bn;
        $scope.worksheetBn = basicInfo.bn;
        $scope.worksheetTypeName = basicInfo.insightType.name;
        $scope.basicInfoLoaded = true;
        $scope.previewHref = '/b/worksheetview/' + $scope.worksheetBn;
        $scope.previewName = basicInfo.insightType.name;
        $scope.browserNotSupported = commonWorksheetService.browserNotSupported($scope.worksheetTypeName);

        $scope.$broadcast('sectionRenderComplete');
      });

      $scope.$on('sectionRenderComplete', function (event) {
        prototype.init();
        $scope.$broadcast("loadAllVisibleSections");
      });
    }])
;
