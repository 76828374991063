angular
  .module('barometerApp.metricAggregation')
  .directive('metricAggregation', metricAggregation);

metricAggregation.$inject = [];

/**
 * Factory method for the directive.
 */
function metricAggregation() {

  return {
    templateUrl: '/b/js/src/bit.ng/metric-aggregation/partials/metric-aggregation.html',
    scope: {
      previewMode: '@',
      worksheetBn: '='
    }
  }
}