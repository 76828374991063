angular.module('barometerApp.common')
  .directive('eatClick', function () {
    return {
      link: function (scope, element, attrs) {
        $(element).click(function (event) {
          event.preventDefault();
          //want to eat child clicks
          if (attrs.stopImmediatePropagation) {
            event.stopImmediatePropagation();
          }
        });
      }
    }
  })
  .directive('easedInput', ['$timeout', function ($timeout) {
    return {
      templateUrl: '/b/js/src/bit.ng/common/partials/eased-input.html',
      scope: {
        value: '=',
        timeout: '@',
        placeholder: '@'
      },
      link: function ($scope) {
        $scope.timeout = parseInt($scope.timeout || 500);
        $scope.update = function () {
          if ($scope.pendingPromise) {
            $timeout.cancel($scope.pendingPromise);
          }
          $scope.pendingPromise = $timeout(function () {
            $scope.value = $scope.currentInputValue
          }, $scope.timeout);
        };
        $scope.$watch('value', function (newValue, oldValue) {
          if (typeof newValue === "undefined" || newValue == oldValue) {
            return;
          }
          $scope.currentInputValue = newValue;
        })
      }
    }
  }])

/**
 * This directive can be used to throttle changes to an ng-model by invoking a callback function. No isolate scope used
 * so it should be compatible with other directives/controllers on the scame scope.
 *
 * @param: delay: the delay in ms to wait before firing the callback
 * @param: callback: the function to invoke after the specified delay
 *
 * @example: <input throttle="{delay: '250', callback: 'myCallback()'}">
 */
  .directive('throttle', ['$timeout', '$parse', function ($timeout, $parse) {
    return {
      scope: true,
      require: 'ngModel',
      link: function (scope, element, attrs, model) {
        var params = scope.$eval(attrs.throttle);
        var delay = parseInt(params.delay || 500);
        var cb = $parse(params.callback);

        //intercept model getting updated from ui
        model.$parsers.unshift(function (value) {
          if (scope.pendingPromise) {
            $timeout.cancel(scope.pendingPromise);
          }
          scope.pendingPromise = $timeout(function () {
            scope.$apply(function () {
              cb(scope, {searchVal: value});
            });
          }, delay);
          return value;
        });
      }
    }
  }])

  .directive('formattedDate', function () {
    return {
      scope: {
        dateString: "="
      },
      templateUrl: '/b/js/src/bit.ng/common/partials/formatted-date.html',
      link: function (scope, element, attrs) {
        var dateFormat = attrs.format ? attrs.format : 'YYYY/MM/DD';
        scope.formattedDate = moment(scope.dateString).format(dateFormat);
      }
    }
  })

  .directive('bitQuery', function () {
    return {
      scope: {
        queryXml: '='
      },
      templateUrl: '/b/js/src/bit.ng/common/partials/bit-query.html',
      link: function (scope, element, attrs) {
        scope.$watch('queryXml', function (newValue, oldValue) {
          if (!newValue || newValue == oldValue) return;
          scope.queryHtml = new QueryBuilderTransformer().transformXmlToViewHtml(scope.queryXml);
        });
      }
    }
  })
  .directive('bitQueryNoWatch', function () {
    return {
      scope: {
        queryXml: '='
      },
      templateUrl: '/b/js/src/bit.ng/common/partials/bit-query-no-watch.html',
      link: function (scope, element, attrs) {
        scope.queryHtml = new QueryBuilderTransformer().transformXmlToViewHtml(scope.queryXml);
      }
    }
  })
  .directive('addDepth', function () {
    return {
      scope: {
        depth: '='
      },
      replace: true,
      templateUrl: '/b/js/src/bit.ng/common/partials/add-depth.html',
      controller: function ($scope, $element, $attrs) {
        $scope.className = "depth" + $scope.depth;
      }
    }
  })
  .directive('detailFlyout', ['utilService', function(utilService) {
    return {
      templateUrl: '/b/js/src/bit.ng/common/partials/detail-flyout.html',
      link: function(scope, element, attrs) {
        scope.$on('rowClicked', function(event, entityLink) {
          scope.editableTableModel.associationDetailsDetails.entityLink = entityLink;
          scope.editableTableModel.associationDetailsDetails.entityLink.isVisible = utilService.isControlLevelVisible(entityLink);
        })
      }
    }
  }])
  /* Requires the entity obj being passed in to have a bn, typebBn, and color */
  .directive('entityIcon', ['utilService',
    function (utilService) {
      return {
        scope: {
          entity: "=",
          showStar: "="
        },
        replace: true,
        templateUrl: '/b/js/src/bit.ng/common/partials/entity-icon.html',
        link: function ($scope, $element, $attrs) {
          if ($scope.entity && $scope.entity.model) {
            $scope.entity = angular.extend($scope.entity, $scope.entity.model);
          }
          $scope.iconFound = false;
          if (!$scope.entity.name) {
            $scope.entity.name = $scope.entity.bn;
          }

          var classForEntity = EntityBnCodeToIconClass[utilService.getBnCode($scope.entity ? $scope.entity.bn : '')];
          var classForType = EntityUtils.icons[$scope.entity ? $scope.entity.typeBn : ''] || '';
          if (classForEntity) {
            $scope.iconFound = true;
            $scope.iconClass = classForType; // TODO: apply subtypes ' + ' ' + classForType;
          }
          if ($scope.entity) {
            $scope.entity.color = $scope.entity.color || '#ccc';
            $scope.iconStyle = "{'background-color':" + $scope.entity.color + "}";
          }
        }
      }
    }])
  .directive('entityLink', ['urlService', 'utilService', 'bitConstants',
    function (urlService, utilService, bitConstants) {
      return {
        scope: {
          entity: "=",
          textLength: "=",
          optionalClickHandler: "="
        },
        templateUrl: '/b/js/src/bit.ng/common/partials/entity-link.html',
        link: function (scope, element, attrs) {
          scope.entityLinkModel = {};
          scope.entity = scope.entity || {};
          // SS -changes to accommodate activityStream data structure. Probably a candidate for refactor
          scope.entity.bn = scope.entity.bn || scope.entity.changeTarget;
          scope.entity.name = scope.entity.name || scope.entity.changeTargetName;
          scope.entity.type = bitConstants.getEntityTypeForBnCode(utilService.getBnCode(scope.entity.bn));

          if (!scope.entity.name) {
            scope.entity.name = scope.entity.bn;
          }

          if (scope.entity.bn) {
            scope.entityLinkModel.entityUrl = urlService.getUrlForBnCode(scope.entity.bn, utilService.getBnCode(scope.entity.bn));// scope.getUrlForBn(scope.entity.bn);
          }

          if(scope.entity.isVisible === undefined || scope.entity.isVisible === null) {
            if (scope.entity.type.typeCode == EntityTypes['TSK'].typeCode) {
              scope.entity.isVisible = true;
            } else {
              scope.entity.isVisible = utilService.isControlLevelVisible(scope.entity) && scope.entityLinkModel.entityUrl;
            }
          }
        }
      }
    }])
  .directive('entityChild', ['tableService',
    function (tableService) {
      return {
        scope: {
          entity: "=",
          textLength: "="
        },
        templateUrl: '/b/js/src/bit.ng/common/partials/entity-child.html',
        link: function (scope, element, attrs) {
          scope.loadNewAddByBrowseTable = function (entity) {
            // tooltips hang around if you don't clean them up
            $('.tooltip').remove();
            tableService.setParentEntity(entity);
          }
        }
      }
    }])
  .directive('entityIconLink', function () {
    return {
      scope: {
        entity: "=",
        depth: '=',
        textLength: "="
      },
      templateUrl: '/b/js/src/bit.ng/common/partials/entity-icon-link.html'
    }
  })
  .directive('entityIconChild', function () {
    return {
      scope: {
        entity: "=",
        depth: '=',
        textLength: "="
      },
      templateUrl: '/b/js/src/bit.ng/common/partials/entity-icon-child.html'
    };
  })
  .directive('entityIconText', function () {
    return {
      scope: {
        entity: "=",
        depth: '=',
        selectMatch: '&'
      },
      templateUrl: '/b/js/src/bit.ng/common/partials/entity-icon-text.html'
    }
  })
  .directive('entityActivityLink', ['urlService', 'utilService',
    function (urlService, utilService) {
      return {
        scope: {
          entity: "=",
          textLength: "="
        },
        templateUrl: '/b/js/src/bit.ng/common/partials/entity-activity-link.html',
        link: function (scope, element, attrs) {
          scope.entity = scope.entity || {};
          if (scope.entity.linked && !scope.entity.href) {
            scope.entity.href = urlService.getUrlForBnCode(scope.entity.bn, utilService.getBnCode(scope.entity.bn));// scope.getUrlForBn(scope.entity.bn);
          }
        }
      };
    }])
  .directive('countDetailLabel', ['assocService', '$timeout',
    function (assocService, $timeout) {
      return {
        scope: {
          count: "=",
          entityBn: "=",
          assocEntityCode: "="
        },
        templateUrl: '/b/js/src/bit.ng/common/partials/count-detail-list.html',
        link: function (scope, element) {
          scope.isClosed = true;
          scope.countDetailLabelModel = {};
          element.click(function () {
            if (scope.isClosed) {
              var detailPromise = assocService.getAssocList(scope.entityBn, scope.assocEntityCode);
              detailPromise.then(function (data) {
                scope.countDetailLabelModel.entityList = data.data;
                $timeout(function () {
                  element.popover('show');
                }, 100); // wait a tiny bit for angular to draw the content before the popover grabs it
                scope.isClosed = false;
              })
            } else {
              element.popover('hide');
              scope.isClosed = true;
            }
          });
          element.popover({
            html: true,
            content: function () {
              return $(element).find('#popover_content_wrapper').html();
            },
            placement: 'bottom',
            trigger: 'manual'
          });
        }
      }
    }])
  .directive('secureByRoles', ['securityService', '$timeout',
    function (securityService, $timeout) {
      return {
        // For this to work there must be an attribute of "roles" on the dom element
        // This attribute is evaluated to get the current value.
        link: function (scope, element, attrs) {
          if (attrs.roles) {
            var roleArr = [];
            var roleStringArr = attrs.roles.split(",");
            _.forEach(roleStringArr, function (item) {
              // May or may not be a literal.
              var role = (scope.$eval(item))?scope.$eval(item):item;
              if (role) {
                roleArr.push(role);
              }
            });
            if (!securityService.hasRole(roleArr)) {
              // not secure. remove content
              $(element).remove();
            } else {
            }
          }
        }
      }
    }])
  .directive('starRating', [function () {
    return {
      scope: {
        numberOfStars: '='
      },
      templateUrl: '/b/js/src/bit.ng/common/partials/star-rating.html',
      link: function (scope, element, attrs) {
        scope.numberOfStars = scope.numberOfStars ? scope.numberOfStars : 0;
        scope.stars = _.range(0, scope.numberOfStars);
        scope.emptyStars = _.range(0, 5 - scope.numberOfStars);
        scope.$on('skillLevelUpdated', function(event, data) {
          if($(element).parent().attr("row-bn") === data.targetBn) {
            scope.numberOfStars = data.stars;
            scope.stars = _.range(0, scope.numberOfStars);
            scope.emptyStars = _.range(0, 5 - scope.numberOfStars);
          }
        });
      }
    }
  }])
  .directive('weightRating', [function () {
    return {
      scope: {
        numberOfWeightIcons: '='
      },
      templateUrl: '/b/js/src/bit.ng/common/partials/weight-rating.html',
      link: function (scope, element, attrs) {
        scope.$on('weightUpdated', function (event, data) {
          if ($(element).parent().attr("row-bn") === data.targetBn) {
            scope.numberOfWeightIcons = data.weight;
            scope.weights = _.range(0, scope.numberOfWeightIcons);
          }
        });
        scope.weights = _.range(0, scope.numberOfWeightIcons.weight);
      }
    }
  }])
  .directive('contentLoader', ['$rootScope','urlService', '$http', '$compile', 'domainFilterService', 'errorHandlingService',
    function ($rootScope, urlService, $http, $compile, domainFilterService, errorHandlingService) {
      return {
        scope: {
          url: "@",
          urlParams: "=",
          paramString: "@",
          appendEntityBn: "@",
          waitToLoad: "@",
          compile: "@",                   // indicates if incoming html should be compiled into the angular scope, this allows angular content to be placed in wicket
          reloadEvent: "@",
          showLoadingSpinner: "@",        // if false, the spinner will not show when content is loading (default is true)
          domainFilterId: "=" ,            // if set, add the current selected domainBn as a url parameter, and decorate with domain-picking tabs when necessary (default is false)
          useMultipleDomains: "@",             // if true, add all current selected domainBn as a url parameter, and decorate with domain-picking tabs when necessary (default is false)
          hideSectionSpinner: "@"          // this is main section body spinner. Graphs(system connect) are not rendered properly when the loading in the main content body shows.
        },
        templateUrl: '/b/js/src/bit.ng/common/partials/content-loader.html',
        link: function (scope, element, attrs) {

          var domainFilter = {
            filter: null,          // mapped from filter
            options: [],           // subset of filter.options
            activeFilter: 0,       // index in options[]
            getSelectedDomainOptions: function () {
              return _.filter(domainFilter.filter.filterSpec.options, function (option) {
                return _.contains(domainFilter.filter.selected, option.value);
              });
            },
            fetchDomainFilter: function () {

              var oldActiveFilterValue = domainFilter.options[domainFilter.activeFilter] ?
                domainFilter.options[domainFilter.activeFilter].value : null;

              // fetch up-to-date filter from service
              domainFilter.filter = domainFilterService.getDomainFilter(scope.domainFilterId);

              if(domainFilter.filter) {
                // none selected, show all domain options
                if (_.isEmpty(domainFilter.filter.selected)) {
                  domainFilter.options = domainFilter.filter.filterSpec.options;
                }

                // show selected domain options
                else {
                  domainFilter.options = domainFilter.getSelectedDomainOptions();
                }

                // reset active option, but retain current selection if present in new options
                domainFilter.activeFilter = 0;
                if (oldActiveFilterValue) {
                  _.each(domainFilter.options, function (option, index) {
                    if (option.value === oldActiveFilterValue) {
                      domainFilter.activeFilter = index;
                    }
                  })
                }
              }
            }
          };

          scope.contentLoaderModel = {};
          scope.contentLoaderModel.showSpinner = false;
          scope.getContent = function () {
            // default to showing spinner when loading, specify attrs.showLoadingSpinner = false to never show the spinner.
            scope.contentLoaderModel.showSpinner = (attrs.showLoadingSpinner && attrs.showLoadingSpinner === 'false') ? false : true;

            let urlString = window.location.origin + urlService.prepareWicketPageUrl(scope.url);
            if (scope.urlParams) {
              _.forEach(scope.urlParams, function (value) {
                urlString = scope.appendParam(urlString, value);
              })
            }
            if(scope.paramString) {
              urlString += scope.paramString;
            }
            // var urlOfContent = urlService.prepareWicketPageUrl(scope.url);
            if (attrs.appendEntityBn === 'true') {
              urlString += urlService.getEntityBn();
              // urlOfContent = scope.appendParam(urlOfContent, urlService.getEntityBn());
            }

            let queryParams = {};

            if (scope.domainFilterId) {
              domainFilter.fetchDomainFilter();
              if (domainFilter.filter && !_.isEmpty(domainFilter.filter.selected)) {
                if (scope.useMultipleDomains) {
                  var domainBnsCommaString = '';
                   _.forEach(domainFilter.options, function(option) {
                    domainBnsCommaString += option.value + ',';
                  });
                   if (domainBnsCommaString.length > 0) {
                     var lastIndexOfComma = domainBnsCommaString.lastIndexOf(",");
                     queryParams["domainbns"] = domainBnsCommaString.substring(0, lastIndexOfComma);
                   }
                } else {
                  queryParams["domainbn"] = domainFilter.options[domainFilter.activeFilter].value;
                }
              }
            }

            if(urlService.getQueryKey()) {
              queryParams["querykey"] = urlService.getQueryKey();
            }

            let firstParam = true;
            _.forOwn(queryParams, (param, key) => {
              if(firstParam) {
                urlString += "?" + key + "=" + param;
                firstParam = false;
              } else {
                urlString += "&" + key + "=" + param;
              }
            });

            if (scope.hideSectionSpinner === 'true'){
              $rootScope.$broadcast('contentRequestStarted');
            }

            $http.get(urlString).then(
              function (data) {
                if (data.data.toString().indexOf("LOGIN_PAGE") !== -1) {
                  // Not authenticated: Redirecting to login page
                  window.location.replace("/b")
                }

                element.empty();

                if (attrs.compile === 'true') {
                  element.append($compile(data.data)(scope));
                } else {
                  element.append(data.data);
                }
                scope.contentLoaderModel.showSpinner = false;
                scope.removeMinHeight();

              }, function(data, headers, status) {
                scope.contentLoaderModel.showSpinner = false;
                console.log("error getting data for injected content.");
                errorHandlingService.handleGenericError(status, "Could not load content for section.");
              });
          };
          scope.appendParam = function (url, param) {
            var endsInSlash = url.indexOf("/", url.length - 1) !== -1;

            // if query param don't mess with trailing slashes.
            if (param) {
              var isQueryPararm = param.indexOf("?") !== -1;
              if (isQueryPararm) {
                return url + param;
              }
            }

            if (endsInSlash) {
              return url + param;
            } else {
              return url + "/" + param;
            }
          };
          scope.removeMinHeight = function () {
            // post load, remove any inline height styles added to smooth out swapping
            $(element).css({
              minHeight: 0
            });
          };
          scope.$on('load', function (event, viewId) {
            if (!viewId) return;
            if ($(element).attr('id') === viewId) {
              // only load if not previously loaded
              if (!scope.contentLoaderModel.loaded) {
                scope.getContent();
              } else {
                scope.removeMinHeight();
              }
            }
          });
          scope.$on('reload', function (event, viewId) {
            if (!viewId) return;
            if ($(element).attr('id') === viewId) {
              // force a refresh of the content
              scope.getContent();
            }
          });
          if (attrs.reloadEvent) {
            scope.$on(attrs.reloadEvent, function (event) {
              scope.getContent();
              if (!scope.$$phase) {
                scope.$apply();
              }
            });
          }
          if (attrs.waitToLoad === 'false') {
            if (!scope.contentLoaderModel.loaded) {
              scope.getContent();
            }
          }
        }
      }
    }])
  /* Lists */
  .directive('entityList', function () {
    return {
      scope: {
        list: "=",
        showIcon: "@"
      },
      templateUrl: '/b/js/src/bit.ng/common/partials/entity-list.html'
    }
  })
  .directive('entityListComma', function () {
    return {
      scope: {
        list: "=",
        showIcon: "@"
      },
      templateUrl: '/b/js/src/bit.ng/common/partials/entity-list-comma.html'
    }
  })
  .directive('textList', function () {
    return {
      scope: {
        list: "="
      },
      templateUrl: '/b/js/src/bit.ng/common/partials/text-list.html'
    }
  })
  .directive('textListComma', function () {
    return {
      scope: {
        list: "="
      },
      templateUrl: '/b/js/src/bit.ng/common/partials/text-list-comma.html'
    }
  })
  .directive('conLinkage', ['urlService', 'utilService', function (urlService, utilService) {
    return {
      scope: {
        entity: "="
      },
      templateUrl: '/b/js/src/bit.ng/common/partials/con-linkage.html',
      link: function (scope, element, attrs) {
        scope.entityUrl = '';
        scope.entity = scope.entity || {};
        if (scope.entity.bn) {
          scope.entityUrl = urlService.getUrlForBnCode(scope.entity.bn, utilService.getBnCode(scope.entity.bn));
        }
      }
    }
  }])
  .directive('conSources', function () {
    return {
      scope: {
        list: "=",
        showIcon: "@"
      },
      templateUrl: '/b/js/src/bit.ng/common/partials/con-sources.html'
    }
  })
  .directive('conTargets', function () {
    return {
      scope: {
        list: "=",
        showIcon: "@"
      },
      templateUrl: '/b/js/src/bit.ng/common/partials/con-targets.html'
    }
  })

/**
 * Directives that allow you to bind focus and blur events to a callback function.  These
 * are scheduled to be part of the official angular codebase as/of 1.1.x.  (at that point these
 * directive should be deleted)  Named them "bitFocus" and "bitBlur" so they don't conflict with
 * the official directives if we forget to delete these after upgrading.
 *
 * @example <input bit-blur="{ blur : 'someCallback()' }">
 */

  .directive('bitFocus', ['$parse', function ($parse) {
    return function (scope, element, attr) {
      var fn = $parse(attr['bitFocus']);
      element.bind('focus', function (event) {
        scope.$apply(function () {
          fn(scope, {$event: event});
        });
      });
    }
  }])

  .directive('bitBlur', ['$parse', function ($parse) {
    return function (scope, element, attr) {
      var fn = $parse(attr['bitBlur']);
      element.bind('blur', function (event) {
        scope.$apply(function () {
          fn(scope, {$event: event});
        });
      });
    }
  }])


  /* Input Fields */

  .directive('editableCheckbox', [
    function () {
      return {
        scope: {
          labelName: "=",
          editing: "=",
          choicesModel: "=",
          selectedModel: "=",
          helpText: '=',
          controlLevel: '=',
          isEditable: '='
        },
        templateUrl: '/b/js/src/bit.ng/field/partials/editableCheckbox.html',
        link: function (scope, element, attrs) {

          scope.toggleCheckbox = function (item) {
            var idx = _.indexOf(scope.selectedModel, item.bn);
            if (idx > -1) {
              //if it exists, remove it
              scope.selectedModel.splice(idx, 1);
            } else {
              //otherwise add it
              scope.selectedModel.push(item.bn);
            }
          };
          scope.getSelectedDisplayValues = function () {
            var results = [];
            _.forEach(scope.selectedModel, function (selectedItem) {
              if (scope.choicesModel) {
                _.find(scope.choicesModel, function (choice) {
                  if (choice.bn === selectedItem) {
                    results.push(choice.displayValue);
                  }
                });
              } else {
                // values in fieldsets for 'read' don't come with choices
                // so choicesModel is undefined
                results.push(selectedItem);
              }
            });
            return results.join(', ');
          };


          scope.isChecked = function (item) {
            return _.contains(scope.selectedModel, item.bn);
          }
        }
      }
    }])

  .directive('editableEmailBox', [
    function () {
      return {
        scope: {
          labelName: "=",
          valueModel: "=",
          editing: "=",
          dType: '=',
          fieldLength: "@",
          helpText: '=',
          controlLevel: '=',
          isEditable: '='
        },
        templateUrl: '/b/js/src/bit.ng/field/partials/editableEmailBox.html'
      }
    }])

  .directive('editableTextBox', [
    function () {
      return {
        scope: {
          labelName: "=",
          data: "=",
          editing: "=",
          dType: '=',
          fieldLength: "@",
          helpText: '=',
          dateFormat: '=',
          controlLevel: '=',
          isEditable: '='
        },
        templateUrl: '/b/js/src/bit.ng/field/partials/editableTextBox.html'
      }
    }])

  .directive('editableTextArea', [
    function () {
      return {
        scope: {
          labelName: "=",
          valueModel: "=",
          editing: "=",
          fieldLength: "@",
          helpText: '=',
          controlLevel: '=',
          isEditable: '='
        },
        templateUrl: '/b/js/src/bit.ng/field/partials/editableTextArea.html'
      }
    }])

  .directive('editableNumberBox', [
    function () {
      return {
        scope: {
          labelName: "=",
          valueModel: "=",
          editing: "=",
          dType: "=",
          displayType: "=",
          fieldLength: "@",
          decimalPlaces: "@",
          helpText: '=',
          controlLevel: '=',
          isEditable: '=',
          numberUom: '='
        },
        templateUrl: '/b/js/src/bit.ng/field/partials/editableNumberBox.html',
        link: function(scope) {


          var formatCurrency = function (n, c, d, t) {
            var c = isNaN(c = Math.abs(c)) ? 2 : c,
                d = d === undefined ? "." : d,
                t = t === undefined ? "," : t,
                s = n < 0 ? "-" : "",
                i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "",
                j = (j = i.length) > 3 ? j % 3 : 0;
            return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
          };

          if (scope.valueModel[0]) {
            switch (scope.numberUom) {
              case 'EUR':
                scope.currencyValue = '€' + formatCurrency(scope.valueModel[0], 2, '.', ',');
                break;
              case 'USD':
                scope.currencyValue = '$' + formatCurrency(scope.valueModel[0], 2, '.', ',');
                break;
              case 'GBP':
                scope.currencyValue = '£' + formatCurrency(scope.valueModel[0], 2, '.', ',');
                break;
              case 'AUD':
                scope.currencyValue = '$' + formatCurrency(scope.valueModel[0], 2, '.', ',');
                break;
              case 'CAD':
                scope.currencyValue = '$' + formatCurrency(scope.valueModel[0], 2, '.', ',');
                break;
            }
          }
        }
      }
    }])

  .directive('editableRadioButtons', [
    function () {
      return {
        scope: {
          labelName: "=",
          valueModel: "=",
          editing: "=",
          helpText: '=',
          controlLevel: '=',
          isEditable: '='
        },
        templateUrl: '/b/js/src/bit.ng/field/partials/editableRadioButtons.html'
      }
    }])

  .directive('editableUrlBox', [
    function () {
      return {
        scope: {
          labelName: "=",
          valueModel: "=",
          editing: "=",
          fieldLength: "@",
          helpText: '=',
          controlLevel: '=',
          isEditable: '='
        },
        templateUrl: '/b/js/src/bit.ng/field/partials/editableUrlBox.html'
      }
    }])

  //todo: right now this is no diff. than editableNumberLabel.  Does it need to be??
  .directive('editableMoneyBox', [
    function () {
      return {
        scope: {
          labelName: "=",
          valueModel: "=",
          editing: "=",
          fieldLength: "@",
          decimalPlaces: "@",
          helpText: '=',
          controlLevel: '=',
          isEditable: '='
        },
        templateUrl: '/b/js/src/bit.ng/field/partials/editableMoneyBox.html'
      }
    }])

  .directive('editableNonVisible', [
    function() {
      return {
        scope: {
          labelName: "=",
          editing: "=",
          helpText: "=",
          controlLevel: "="
        },
        templateUrl: '/b/js/src/bit.ng/field/partials/editableNonVisible.html'
      }
    }
  ])

  .directive('overlayLink', ['$http', '$compile', '$templateCache', function ($http, $compile, $templateCache) {
    return {
      scope: 'isolate',
      link: function ($scope, $element, $attrs) {
        $scope.markup = $attrs.markup;
        $scope.modelExpression = $attrs.model;
        $element.click(function () {
          var currentModel = $scope.$eval($scope.modelExpression);
          // always work with a copy... maybe make this configurable later
          $scope.workingModel = $.extend(true, {}, currentModel);
          $http.get($scope.markup, {cache: $templateCache}).success(function (html) {
            var useTopModal = false;
            if ($attrs.top == 'true') {
              useTopModal = true;
              var overlayHtml = $('#overlayTopPhx div.overlay-top-holder').html(html);
            } else {
              var overlayHtml = $('#overlayPhx div.overlay-holder').html(html);
            }
            $compile(overlayHtml)($scope);
            $('body').css('overflow', 'hidden');
            $element.modalWindow(useTopModal, false);
          });
        });
      }
    }
  }])

//testing this to try and wrap a ui-bootstrap modal to apply some styling
  .directive('dynamicModal', function () {
    return {
      restrict: 'AC',
      link: function (scope, element, attrs) {
        element.css({
          'width': 'auto',
          'margin-left': function () {
            return -($(element).width() / 2);
          }
        });
      }
    }
  })

  .directive('editableBooleanDropdown', [
    function () {
      return {
        scope: {
          labelName: "=",
          editing: "=",
          selectOptions: "=",
          selectedModel: "=",
          helpText: '=',
          controlLevel: '=',
          isEditable: '='

        },
        templateUrl: '/b/js/src/bit.ng/field/partials/editableBooleanDropdown.html',
        link: function postLink(scope, element, attrs) {
          scope.$watch('editing', function (newVal, oldVal) {
          });
        }
      }
    }])

  .directive('editableSingleSelectBox', [
    function () {
      return {
        scope: {
          labelName: "=",
          choicesModel: "=",
          editing: "=",
          selectOptions: "=",
          selectedModel: "=",
          helpText: '=',
          controlLevel: '=',
          isEditable: '='
        },
        templateUrl: '/b/js/src/bit.ng/field/partials/editableSingleSelectBox.html',
        link: function (scope, element, attrs) {
//                    scope.$watch('optionsModel.selected', function (oldVal, newVal) {
          //no-op for now, could use this to do something based on user changing selection
//                    });

          scope.updateModel = function () {
            scope.selectedModel[0] = element.find('option:selected').val();
          };
//                    element.find('option').filterByValue(scope.selectedModel).prop('selected', 'selected');
          scope.myModel = scope.selectedModel[0];
          scope.getSelectedDisplayValue = function () {
            var result = undefined;
            if (scope.choicesModel) {
              _.find(scope.choicesModel, function (choice) {
                if (choice.bn === scope.selectedModel[0]) {
                  result = choice.displayValue;
                }
              });
            } else {
              // values in fieldsets for 'read' don't come with choices
              // so choicesModel is undefined
              result = scope.myModel;
            }
            return result;
          }
        }
      }
    }])

  .directive('editableMultiSelectBox', ['$timeout',
    function ($timeout) {
      return {
        scope: {
          labelName: "=",
          choicesModel: "=",
          editing: "=",
          selectOptions: "=",
          selectedModel: "=",
          helpText: '=',
          controlLevel: '=',
          isEditable: '=',
          isRequired: '='
        },
        templateUrl: '/b/js/src/bit.ng/field/partials/editableMultiSelectBox.html',
        link: function postLink(scope, element, attrs) {
//                    scope.$watch('optionsModel.selected', function (oldVal, newVal) {
          //no-op for now, could use this to do something based on user changing selection
//                    });
          $timeout(function () {
            element.find('select').chosen()
          });

          scope.getSelectedDisplayValues = function () {
            var results = [];
            _.forEach(scope.selectedModel, function (selectedItem) {
              if (scope.choicesModel) {
                _.find(scope.choicesModel, function (choice) {
                  if (choice.bn === selectedItem) {
                    results.push(choice.displayValue);
                  }
                });
              } else {
                // values in fieldsets for 'read' don't come with choices
                // so choicesModel is undefined
                results.push(selectedItem);
              }
            });
            return results.join(', ');
          }
        }
      }
    }])

  .directive('onFinishCall', ['$timeout', function ($timeout) {
    return {
      link: function (scope, element, attr) {
        if (scope.$last === true) {
          $timeout(function () {
            scope.$emit(attr.onFinishCall);
          }, 600);
        }
      }
    }
  }])

  // links to help content need to be hijacked and resolved by opening the help pane and ajaxing the correct html file
  .directive('helpLink', ['urlService', '$rootScope', function (urlService, $rootScope) {
    return {
      scope: {
        href: "@",
        pageSpecific: "="
      },
      link: function (scope, element, attr) {

        element.on('click', function (e) {
          e.preventDefault();

          if (scope.pageSpecific) {
            // help content uses the singelTypedCollection page nomenclature
            // so we want to route uses from both b/system/xyz and b/systems to /b/help/systems
            var helpPage = singleTypedCollectionPages[urlService.getPageIdentifierOfCurrentPage()];
            scope.href = '/Content/Overview/c_' + helpPage + 'htm';
          }
          $rootScope.$broadcast('helpLinkClicked', scope.href);
        });
      }
    }
  }])

  // help pane, receives events from help-links with the url of help content to show
  .directive('helpPane', ['$http', '$compile', '$templateCache', function ($http, $compile, $templateCache) {
    // Help pages are built from /src/main/resources/help-content by gulp into /src/main/resources/build/<env>/<locale>/js/help-content.<env>.js
    // This file is loaded into templatecache and used for the help page display.
    return {
      link: function (scope, element, attr) {

        scope.contentAlreadyLoaded = false;

        element.find('.help-pane-close').on('click', function () {
          element.removeClass('open');
          $('body').removeAttr('style');
        });

        function getDefaultHelpLandingPage() {
          // fetch default help landing page
          $http.get("https://barometer.zendesk.com/hc", {cache: $templateCache})
            .then(function (data) {
              element.find('.help-pane-content').html($compile(data.data)(scope));
            }, function () {
              console.error('Could not fetch default help landing page!');
            });
        }

        scope.$on('helpLinkClicked', function (event, href) {

          // only load help content if the help-pane is already open, or it was the first time the help pane is opened
          // this lets the external help links simply toggle the pane open/close to leaf back and forth between the app and the content
          // (without losing the user's place)
          if (element.hasClass('open') || !scope.contentAlreadyLoaded) {
            scope.contentAlreadyLoaded = true;

            if (typeof href === 'undefined') {
              getDefaultHelpLandingPage();
            } else {
              // assumes hrefs are relative to the help content root
              href = "/b/bit-help" + href;
              $http.get(href, {cache: $templateCache})
                .then(function (data) {
                  element.find('.help-pane-content').html($compile(data.data)(scope));
                }, function () {
                  console.error('Could not fetch help document.  Fetching default help landing page...');
                  getDefaultHelpLandingPage()
                });
            }
          }

          if (!element.hasClass('open')) {
            element.addClass('open');
            $('body').css({overflow: 'hidden'});
          }
        });
      }
    }
  }])

/**
 * Links to kick off guided tours
 * @param href: required, the name of the tour script
 *
 */
  .directive('tourLink', ['tourService', function (tourService) {
    return {
      scope: {
        tourName: "@"
      },
      link: function (scope, element) {
        element.on('click', function () {
          tourService.launchTour(scope.tourName, true);
        })
      }
    }
  }])

/**
 * Link for the add button in editable table
 *
 */
  .directive('addLink', ['$http', '$timeout', 'tableService', 'urlService', '$rootScope', 'redux',
    function ($http, $timeout, tableService, urlService, $rootScope, redux) {
      return {
        scope: {
          tableModel: '='
        },
        replace: true,
        templateUrl: '/b/js/src/bit.ng/common/partials/add-link.html',
        link: function (scope, element, attrs) {
          scope.addRelationship = function () {

            scope.newRelationshipEditor = window.location.href.includes('newRelationshipEditor');

            if (scope.newRelationshipEditor) {
              // New Relationship Editor
              redux.store.dispatch(redux.actions.initializeRelationshipEditor(urlService.getEntityBn(), scope.tableModel.entityTypeCode, scope.tableModel.relationshipType));
              $rootScope.$broadcast('showNewEditor');
            } else {
              // Old Relationship Editor
              var tableEntityType = scope.tableModel.entityTypeCode;
              var tableId = scope.tableModel.tableId;
              var pageEntityType = urlService.getEntityTypeCodeOfCurrentPage();
              // Connections to companies and systems are handled differently
              if ((tableEntityType === 'CON') && ((pageEntityType === 'COM') || (pageEntityType === 'SYS'))) {
                $rootScope.$broadcast('openAddConnectionModal');
              }
              else {
                tableService.openDialog({dialog: 'addDialog', sectionBn: tableId, targetBn: tableEntityType});
              }
            }


          };

          // Add Tooltip
          $timeout(function () {
            element.tooltip({
              title: '<div class=&quot;title&quot;>Add</div> Click to Add a Relationship',
              html: true,
              container: 'body',
              delay: 500
            });
          })
        }
      }
    }
  ])

  .directive('relationshipEditorLink', ['$modal', 'redux',
    function($modal, redux) {
      return {
        scope: {},
        templateUrl: '/b/js/src/bit.ng/common/partials/relationship-modal-link.html',
        link: function (scope, element, attrs) {

          scope.modal = null;

          let dialogOptions = {
            backdrop: 'static',
            keyboard: true,
            templateUrl: '/b/js/src/bit.ng/common/partials/relationship-modal.html',
            scope : scope
          }

          scope.openModal = () => {
            scope.modal = $modal.open(dialogOptions);
            scope.modal.opened.then(function () {
              // Opened the model.
            });
          }

          scope.closeModal = () => {
            scope.modal.close();
          }

        }
      }

    }
  ])

/**
 * @param section-id: required, used to lookup filter parameters for a table with this same id.
 * @param to-entity-type: required, represents the entity-type you're pivoting to
 * @param pivot-type: required, either browse or association
 * @param pivot-count: required, number of pivots we have performed
 *
 */
  .directive('pivotLink', ['$http', '$timeout', 'filterService', 'queryKeyService', 'urlService', 'utilService', '$window', '$rootScope', 'alertService',
    function ($http, $timeout, filterService, queryKeyService, urlService, utilService, $window, $rootScope, alertService) {
      var pivotLimit = 6;
      return {
        scope: {
          tableModel: '='
        },
        replace: true,
        templateUrl: '/b/js/src/bit.ng/common/partials/pivot-link.html',
        link: function (scope, element, attrs) {

          scope.hasFilters = function (filter) {
            if (filter) {
              for (var prop in filter.params) {
                if (filter.params.hasOwnProperty(prop)) {
                  return true;
                }
              }
              return filter.searchString;
            }
            return false;
          };

          scope.buildPivotParams = function (pivotParams) {
            var params = {pivot: {identifier: null, entityType: null, qualifierBn: null, source: {}}, entityType: null};
            if (scope.hasFilters(pivotParams.filterData)) {
              params.filter = pivotParams.filterData;
            }
            var fromEntityType = urlService.getEntityTypeCodeOfCurrentPage();
            params.pivot.qualifierBn = (pivotParams.qualifierBn == '') ? null : pivotParams.qualifierBn;
            if (pivotParams.tableType == 'multitypebrowse' || pivotParams.tableType == 'singletypebrowse') {
              if (pivotParams.queryKey) {
                params.pivot.source.sourceBn = null;
                params.pivot.source.queryKey = pivotParams.queryKey;
                if (fromEntityType !== pivotParams.toEntityType) {
                  params.pivot.entityType = fromEntityType;
                }
                params.entityType = pivotParams.toEntityType;
                params.pivot.relationshipType = pivotParams.relationshipType;
              } else {
                if (fromEntityType) {
                  if (fromEntityType === pivotParams.toEntityType) {
                    // 'self' pivot case
                    params.pivot.entityType = pivotParams.toEntityType;
                  } else {
                    //single-typed collection case
                    params.entityType = pivotParams.toEntityType;
                    params.pivot.entityType = fromEntityType;
                    params.pivot.relationshipType = pivotParams.relationshipType;
                  }
                } else {
                  //this is the dashboard case
                  params.pivot.entityType = pivotParams.toEntityType;
                }
                params.pivot.source = null;
              }
            } else if (pivotParams.tableType == 'association') {
              params.pivot.entityType = fromEntityType;
              params.pivot.relationshipType = pivotParams.relationshipType;
              if (pivotParams.queryKey) {
                params.pivot.source.sourceBn = null;
                params.pivot.source.queryKey = pivotParams.queryKey;
              } else if (pivotParams.entityBn) {
                params.pivot.source.sourceBn = pivotParams.entityBn;
              } else {
                params.pivot.source = null;
              }
              params.entityType = pivotParams.toEntityType;
            } else if (pivotParams.tableType == 'adhoc') {
              params.pivot.source.adHocReportBn = pivotParams.entityBn;
            } else if (pivotParams.tableType == 'ruleCompliant') {
              params.entityType = pivotParams.toEntityType;
              params.pivot.entityType = fromEntityType;
              params.pivot.source.ruleBnCompliant = pivotParams.entityBn;
            } else if (pivotParams.tableType == 'ruleNotCompliant') {
              params.entityType = pivotParams.toEntityType;
              params.pivot.entityType = fromEntityType;
              params.pivot.source.ruleBnNotCompliant = pivotParams.entityBn;
            } else {
              console.log('PIVOT-TYPE NOT SPECIFIED');
            }
            return params;
          };

          /**
           * We need to first check if we have an existing query key as well as any table filters that have been
           * applied before pivoting.  If either exist, we need to call the query-key service to generate a new
           * query key. Then we can load the single-typed collection page with that new query key.
           */
          scope.pivot = function ($event) {
            var openInNewWindow = $event.ctrlKey || $event.metaKey;

            if (scope.tableModel && scope.tableModel.pivotCount >= pivotLimit) {
              alertService.addAlert({type: 'warning', message: 'Additional Pivots cannot be added to this query', isSticky: false});
              return;
            }
            var toEntityType, filterData, tableType, relationshipType, qualifierBn;
            //adhoc-reports and compliance rules pivot are on wicket components so we need to handle them differently than every
            //other pivot case where we are inside an angular editable table
            if (attrs.pivotType === 'adhoc') {
              //hack to pull entity type from wicket component
              toEntityType = $('.entityTypeMarker').html();
              tableType = 'adhoc';
            } else if ((attrs.pivotType === 'ruleCompliant') || (attrs.pivotType === 'ruleNotCompliant')) {
              //hack to pull entity type from wicket component
              toEntityType = $('.entityTypeMarker').html();
              tableType = attrs.pivotType;
            } else {
              toEntityType = scope.tableModel.entityTypeCode;
              filterData = filterService.convertFilterObjectToFilterArray(scope.tableModel.filter);
              tableType = scope.tableModel.tableType.toLowerCase();
              relationshipType = scope.tableModel.relationshipType;
              if (scope.tableModel.filter) {
                qualifierBn = (scope.tableModel.filter.qualifier == '') ? null : scope.tableModel.filter.qualifier;
              }
            }
            var url = urlService.getSingleTypedCollectionPageForEntityType(toEntityType);
            var existingQueryKey = urlService.getQueryKey();
            var entityBn = urlService.getEntityBn();
            var pivotParams = scope.buildPivotParams({
              queryKey: existingQueryKey, filterData: filterData, toEntityType: toEntityType,
              entityBn: entityBn, tableType: tableType, relationshipType: relationshipType, qualifierBn: qualifierBn
            });
            var promise = queryKeyService.getQueryKeyForPivot(pivotParams);
            promise.then(function (results) {
              var newQueryKey = results.data.queryKey;
              $window.open(
                urlService.getBaseUrl() + url + "/querykey" + newQueryKey,
                openInNewWindow ? '_blank' : '_self'
              );

              //if self-pivot, then we need to refresh count since we're not reloading page
              if (toEntityType === urlService.getEntityTypeCodeOfCurrentPage()) {
                $rootScope.$broadcast('refreshCollectionCount', newQueryKey);
              }
            });
          };

          // Add Tooltip
          $timeout(function () {
            element.tooltip({
              title: '<div class="title">Pivot</div> Center a new view on this dataset',
              html: true,
              container: 'body',
              delay: 500
            });
          })
        }
      }
    }])

  /*  VALIDATORS  */

  .directive('currencyValidator', [
    function () {
      var currencyRegex = '(?:^\\d{1,3}(?:\\.?\\d{3})*(?:,\\d{2})?$)|(?:^\\d{1,3}(?:,?\\d{3})*(?:\\.\\d{2})?$)';
      return {
        require: 'ngModel',
        link: function postLink(scope, element, attrs, model) {
          var regex = new RegExp(currencyRegex);

          model.$parsers.unshift(function (value) {
            var valid = regex.test(value);
            model.$setValidity('currencyValidator', valid);
            return valid ? value : undefined;
          });

          model.$formatters.unshift(function (value) {
            model.$setValidity('currencyValidator', regex.test(value));
            return value;
          });
        }
      }
    }])

/**
 *
 * Turns a regular select tag into a chosen select widget
 */
  .directive('chosenSelect', ['$timeout',
    function ($timeout) {
      return {
        link: function (scope, element) {
          $timeout(function () {
            element.chosen();
          });
        }
      }
    }])

/**
 * Validates that the model value is a number.  Can optionally have a decimalPlaces variable on the parent scope
 * to enforce how many numbers are allowed after the decimal point.
 *
 * Normally we could (and should?) use ng-pattern for this sort of thing, but this directive allows for dynamically
 * changing the decimal places. (ng-pattern can't handle that)
 */
  .directive('numberValidator', [
    function () {
      var decimalRegex = '^(?:\\d*\\.\\d{1,2}|\\d+)$';
      return {
        scope: true,
        require: 'ngModel',
        link: function postLink(scope, element, attrs, model) {
          if (scope.decimalPlaces) {
            decimalRegex = '^(?:\\d*\\.\\d{1,' + scope.decimalPlaces + '}|\\d+)$';
          }
          var regex = new RegExp(decimalRegex);

          model.$parsers.unshift(function (value) {
            var valid = regex.test(value);
            model.$setValidity('numInput', valid);
            return valid ? value : undefined;
          });

          model.$formatters.unshift(function (value) {
            model.$setValidity('numInput', regex.test(value));
            return value;
          });

          scope.$watch('decimalPlaces', function (newVal, oldVal) {
            if (newVal) {
              decimalRegex = '^(?:\\d*\\.\\d{1,' + scope.decimalPlaces + '}|\\d+)$';
              regex = new RegExp(decimalRegex);
            }
          });
        }
      }
    }])
  .directive('sectionLoader', ['$rootScope',
    function ($rootScope) {
      return {
        link: function (scope, element, attrs) {
          scope.isOnScreen = function () {
            var viewport = {
              // This 150 prevents sections above from loading and pushing the selected section down create jumpiness
              top: $(window).scrollTop() + 150,
              left: $(window).scrollLeft()
            };
            viewport.right = viewport.left + $(window).width();
            // This 150 loads the next section down, smoothing out scrolling
            viewport.bottom = viewport.top + $(window).height() + 150;

            var bounds = $(element).offset();
            bounds.right = bounds.left + $(element).outerWidth();
            bounds.bottom = bounds.top + $(element).outerHeight();
            return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));
          };
          function loadSection() {
            if (prototype.config.enableWaypoint) {
              if (scope.isOnScreen()) {
                $rootScope.$broadcast('loadSection', attrs.sectionBn);
              }
            }
          }

          scope.$on('loadAllVisibleSections', function (event) {
            $rootScope.$broadcast('loadSection', attrs.sectionBn);
          });
        }
      }
    }])

  // just a means of templating the bit logo HTML
  .directive('bitLogo', function () {
    return {
      templateUrl: '/b/js/src/bit.ng/common/partials/bit-logo.html'
    }
  })

  // grabs the app version from a hidden, Wicket-generated div
  // TOOD: obviously this could be more elegant
  .directive('appVersion', function () {
    return {
      link: function (scope, element, attrs) {
        element.text($('#app-version').text());
      }
    }
  })

  .directive('colorPicker', function () {
    return {
      require: 'ngModel',
      link: function (scope, element, attrs, ctrl) {
        iColorPicker();
        element.on('change', function () {
          if (scope.$root.$$phase != '$apply' && scope.$root.$$phase != '$digest') {
            scope.$apply(function () {
              ctrl.$setViewValue(element.val());
            });
          } else {
            ctrl.$setViewValue(element.val());
          }
        });

        ctrl.$render = function () {
          element.css('background', '#' + ctrl.$viewValue);
          element.val(ctrl.$viewValue);
        };
      }
    }
  })

/**
 * Used to upload (POST) a file to a resource.
 *
 * param requestResponseModel has 5 properties:
 * url=the url of the rest endpoint
 * showProgress: controls display of a progress bar
 * response: the data object returned from the rest endpoint.
 * required: adds the HTML5 required attribute to the input
 * formSubmitted: a way of handling feedback styling if required is true
 *
 */
  .directive('fileUploader', ['$upload', '$http', '$timeout',
    function ($upload, $http, $timeout) {
      return {
        scope: {
          requestResponseModel: "="
        },
        templateUrl: '/b/js/src/bit.ng/common/partials/file-uploader.html',
        link: function (scope, element, attrs) {
          var maliciousTypes = ["application/x-msdownload", "text/html", "text/javascript", "application/octet-stream"];
          var input = element.find('input[type=file]');
          if (scope.requestResponseModel.required) {
            input.addClass('ng-invalid');
            input.addClass('ng-invalid-required');
          }

          // flash detection adapted from angular-file-upload-shim.js
          // can't use their implementation b/c it's internal
          scope.flashMissing = false;
          if (isIE9()) {
            var hasFlash = false;
            try {
              var fo = new ActiveXObject('ShockwaveFlash.ShockwaveFlash');
              if (fo) hasFlash = true;
            } catch (e) {
              if (navigator.mimeTypes["application/x-shockwave-flash"] != undefined) hasFlash = true;
            }
            scope.flashMissing = !hasFlash;
          }

          scope.fileSelected = function (file) {
            input.removeClass('ng-invalid');
            input.removeClass('ng-invalid-required');
            scope.file = file[0];

            // emit an event for parent controllers to use
            setOriginalName(file[0]);
            scope.$emit('fileSelected', {
              file: file[0]
            });

            // reset
            scope.tooBig = false;
            scope.invalidType = false;
            scope.progress = 0;


            // 10 MB max file size
            if (file[0] && file[0].size > 10485760) {
              scope.tooBig = true;
              scope.tooBigFileName = file[0].name;
              element.find('input[type=file]').val('');
            } else if(file[0] && maliciousTypes.indexOf(file[0].type) >= 0) {
              scope.invalidType = true;
              scope.invalieTypeFileName = file[0].name;
            } else {
              scope.upload = $upload.upload({
                method: 'POST',
                url: scope.requestResponseModel.url,
                file: file,
                headers: {'Content-Type': 'multipart/form-data'}
              }).progress(function (evt) {
                scope.progress = parseInt(100.0 * evt.loaded / evt.total);
              }).success(function (data, status, headers, config) {
                scope.requestResponseModel.response = data;
              }).error(function () {
                console.error('error uploading file: ', file);
                scope.invalidType = true;
                scope.invalidTypeFileName = file[0].name;
              });
            }
          };

          // abort midway through upload
          scope.fileUploaderAbort = function () {
            element.find('input[type=file]').val('');
            scope.progress = 0;
            scope.upload.abort();
          };

          function setOriginalName(file) {
            file.originalName = file.name;
          }

          // when cancel button of quick add is clicked, clean up
          scope.$on('quickAddCancelled', function () {
            scope.fileUploaderAbort();
            var orphanedUrl = scope.requestResponseModel.response;
            if (orphanedUrl) {
              $http({
                method: 'DELETE',
                url: '/b/api/assetstore',
                params: {orphanedUrl: orphanedUrl}
              }).error(function () {
                console.error('Error removing orphaned document upload at url: ' + orphanedUrl);
              });
            }
          })

        }
      }
    }])

  .directive('progressBar', [
    function () {
      return {
        scope: {
          percent: "=",
          abort: "&"
        },
        templateUrl: '/b/js/src/bit.ng/common/partials/progress-bar.html',
        link: function (scope, element, attrs) {
          scope.progressBarStyle = function () {
            return {
              width: scope.percent + '%'
            }
          }
        }
      }
    }])

  .directive('imageCropper', ['$timeout',
    function ($timeout) {
      return {
        scope: {
          cropperModel: "="
        },
        templateUrl: '/b/js/src/bit.ng/common/partials/image-cropper.html',
        link: function (scope, element, attrs) {
          $timeout(function () {
            $('.crop-image').Jcrop({
              trackDocument: true,
              boxWidth: 500,
              boxHeight: 500,
              aspectRatio: 1,
              onSelect: function (x) {
                scope.cropperModel.coordinates = x;
              }
            });
          });
        }
      }
    }])

  .directive('fileRequired',
  function () {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, el, attrs, modelCtrl) {
        //change event is fired when file is selected
        el.bind('change', function () {
          scope.$apply(function () {
            modelCtrl.$setViewValue(el.val());
            ngModel.$render();
          });
        });
      }
    }
  })
  .directive('downloadPdf', ['pageService', 'alertService', 'urlService',
    function (pageService, alertService, urlService) {
      return {
        link: function (scope, element, attrs) {
          element.on('click', function () {
            var fileRef = '/b/api/layout/generatePhantomPdf?url=' + encodeURIComponent(urlService.getCurrentUrl()) + "&fileNameRoot=" + pageService.getTitle();
            alertService.addSuccessAlert("PDF is being generated. Please wait...");
            scope.$apply();
            //$("body").append("<iframe src=\"" + fileRef + "\" style=\"display: none;\" onload=\"$('body').scope().$broadcast('clearAllAlerts');\" ></iframe>");
            $("body").append("<iframe src=\"" + fileRef + "\" style=\"display: none;\"></iframe>");
          });
        }
      }
    }])
  .directive('softHyphen', ['utilService', 'pageService',
    function (utilService, pageService) {
      return {
        scope: {
          hyphenMe: '@'
        },
        link: function (scope, element, attrs) {
          scope.$watch(pageService.getEntity, function (newVal, oldVal) {
            if (scope.hyphenMe) {
              scope.hyphenMe = utilService.addSoftHyphen(scope.hyphenMe);
            }
          });
        }
      }
    }])
  // Simple wrapper that executes the javascript that's passed is against the current element.
  // It assumes a jQuery style call, in the format elem.passedJS(passedParams);
  // (We could allow parameters to be passed in someday, , but for now, KISS)
  .directive('onLink', ['$timeout', function ($timeout) {
    return {
      link: function (scope, elm, attrs) {
        $timeout(function () {
          elm[attrs.onLink]();
        });
      }
    }
  }])

  // Directive that disables the element when it is clicked.  Works on buttons and links.
  .directive('disableOnClick', [ function () {
    return {
      restrict: 'A',
      link: function (scope, elem, attrs) {
        elem.on('click', function(){
          elem.prop('disabled', true).addClass('disabled');
        })
      }
    }
  }])

.directive('warningBanner', ['bitConstants',
  function (bitConstants) {
    return {
      templateUrl: "/b/js/src/bit.ng/common/partials/warning-banner.html",
      link: function (scope, elem, attrs) {

        switch (attrs.messageType) {
          case 'Deprecation_Notice':
            console.log('Deprecation Notice');
            scope.messageTitle = 'Deprecation Notice';
            scope.messageValue = String.format('The {0} feature will be retired {1}.', attrs.deprecatedFeature, attrs.deprecationDate);
            break;
          default:
            scope.messageTitle = 'Warning';
            scope.messageValue = 'Warning';
            break;
        }
      }
    }
  }]);
