angular
  .module('barometerApp.watch')
  .controller('PinController', PinController);

PinController.$inject = ['$scope', 'pinDataService', 'urlService', 'utilService', '$timeout'];

function PinController($scope, pinDataService, urlService, utilService, $timeout) {

  /**
   * My state model.
   */
    // For the "add-widget", for "pinThisFor" feature.
  $scope.includeEntityTypes = ["PER"];
  // Default pin state. We'll check this in a sec.
  $scope.isPinned = false;
  $scope.isOpen = false;
  $scope.showAddWidget = true;

  // BEGIN CONTROL FUNCTIONS - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  /**
   * What entity are we going to pin? Reach into ancestor scope.
   */
  function getTargetBn() {
    //
    return urlService.getEntityBn();
  }

  /**
   * Do the real unpinning work.
   */
  function pin() {
    //
    var personBn = utilService.getCurrentUserBn();
    pinFor(personBn);
  }

  /**
   * Do the real pinning work.
   */
  function pinFor(personBn) {
    //
    var targetBn = getTargetBn();
    pinDataService.addPin(personBn, targetBn);
  }

  /**
   * Do the real unpinning work.
   */
  function unpin() {
    //
    var personBn = utilService.getCurrentUserBn();
    unpinFor(personBn);
  }

  /**
   * Do the real unpinning work.
   */
  function unpinFor(personBn) {
    //
    var targetBn = getTargetBn();
    pinDataService.removePin(personBn, targetBn);
  }

  /**
   * Handle request for list of Persons.
   */
  function pinnedBy() {
    //
    var myTargetBn = getTargetBn();
    var myPage = 1;
    var myProperty = 'fullName';
    var myOrder = 'ASC';
    //
    pinDataService
      .listPinnedBy(myTargetBn, myPage, myProperty, myOrder)
      .then(function (data) {
        $scope.pinnedByModel = data;
      }, function (err) {
        console.error("Error getting report data ", err);
      });
  }

  /**
   * For use by show/hide conditional.
   */
  function hasPin() {
    //
    var myPersonBn = utilService.getCurrentUserBn();
    var myTargetBn = getTargetBn();
    //
    pinDataService.getPin(myPersonBn, myTargetBn).then(
      function (data) {
        // TODO This is hokey. Clean it up.
        if (data && data != 'null') {
          $scope.isPinned = true;
        } else {
          $scope.isPinned = false;
        }
      },
      function (err) {
        console.error("Error getting pin data.", err);
      });
    //
    return true;
  }

  /**
   * Close the pin menu.
   */
  function closeMenu() {
    // Since we are using bootstrap's built-in JS, any external click will close the dropdown
    // Delay prevents a digest cycle race (I'm not sure why) and also feels more natural
    $timeout(function(){
      $('body').click();
    }, 200);
  }

  function resetAddWidget() {

    // We reset the widget by removing it and putting it back
    // The initial delay ensures the dropdown's width doesn't collapse before it is closed
    $timeout(function(){
      $scope.showAddWidget = false;
      $timeout(function(){
        $scope.showAddWidget = true;
      });
    }, 300);
  }

  // Stupid JavaScript. Why do I have to put this down here.
  $scope.isPinned = hasPin();

  // BEGIN SCOPED FUNCTIONS - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  /**
   * Handle a pin click.
   */
  $scope.pinThis = function () {
    //
    // Make the change.
    pin();
    // Toggle the indicator w/o re-read (assumes success).
    $scope.isPinned = true;
  }

  /**
   * Handle an pin-for click.
   *
   * See add-widget aka addWidget. The add-widget requires an addEntityFn and includeEntityTypes.
   */
  $scope.pinThisFor = function (event, forPerson) {
    //
    event.preventDefault();
    if (!forPerson) {
      console.warn("pinThisFor: person is null");
      return;
    }
    var forPersonBn = forPerson.bn;
    // Make the change.
    pinFor(forPersonBn);
    // Close the menu.
    closeMenu();
    resetAddWidget();
  }

  /**
   * Handle an unpin click.
   */
  $scope.unpinThis = function () {
    //
    // Make the change.
    unpin();
    // Toggle the indicator w/o re-read (assumes success).
    $scope.isPinned = false;
    // Close the menu.
    closeMenu();
  }

  /**
   * Handle open menu click.
   */
  $scope.toggleClicked = function () {
    // Refresh pinnedByList.
    pinnedBy();
  }
}