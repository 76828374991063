/**
 * This file tries to follow John Papa's Angular 1 style guide (adjusted to our AngularJS version).
 *
 * https://github.com/johnpapa/angular-styleguide/blob/master/a1/README.md
 */
angular
  .module('barometerApp.simpleTable')
  .factory('simpleTableUtilityService', simpleTableUtilityService);

simpleTableUtilityService.$inject = [];

/**
 * Stateless utility functions.
 */
function simpleTableUtilityService() {
  //
  return {
    getTableModel: getTableModel,
    getPagination: getPagination
  };

  /**
   * Unpack data into tableModel.
   */
  function getTableModel(currentPage, data) {
    //
    console.assert(data, 'Expected data. Got null.');
    //
    var tableModel = {columns: 0, page: {}, rows: 0};
    //
    tableModel.page = getPagination(currentPage, data);
    tableModel.columns = data.columnSpec;
    tableModel.rows = data.rows;
    //
    tableModel.sort = getSort(data);
    augmentColumns(tableModel);
    //
    return tableModel;
  }

  /**
   * Unpack table page info from data.
   */
  function getPagination(currentPage, data) {
    //
    console.assert(data, 'Expected data. Got null.');
    //
    var page = {currentPage: 0, maxSize: 0, noOfPages: 0, noOfRecords: 0, pageSize: 10, rowCount: 0};
    //
    // Page number.
    page.currentPage = currentPage;
    // Total size of the current page - should always be less than 10.
    page.noOfRecords = data.rows ? data.rows.length : 0;
    // Total number of records in the entire table.
    page.rowCount = data.rowCount;
    // Total number of pages (total records over 10 rounded up).
    page.noOfPages = Math.ceil(page.rowCount / 10);
    //
    return page;
  }

  /**
   * Unpack column sort info from data.
   */
  function getSort(data) {
    //
    var sort = {sortProperty: null, sortOrder: null};
    //
    // TODO Seriously, how uncool is this loop.
    for (var i = 0; i < data.columnSpec.length; i++) {
      var c = data.columnSpec[i];
      if (c.sorted) {
        sort.sortProperty = c.id;
        sort.sortOrder = c.sorted;
      }
    }
    //
    return sort;
  }

  /**
   * Set column sortClass, etc.
   */
  function augmentColumns(tableModel) {
    //
    var sort_disabled = '';
    var no_sort_available = 'sort-button';
    var not_selected = 'sort-button icon-sort-asc';
    var selected_ascending = 'sort-button icon-sort-asc selected';
    var selected_descending = 'sort-button icon-sort-desc selected';
    //
    // TODO Seriously, how uncool is this loop.
    for (var i = 0; i < tableModel.columns.length; i++) {
      var c = tableModel.columns[i];
      if (c.sorted) {
        c.sortClass = (c.sorted == 'ASC') ? selected_ascending : selected_descending;
      }
      else if (c.sortable) {
        c.sortClass = not_selected;
      }
      else {
        c.sortClass = no_sort_available;
      }
    }
  }
}