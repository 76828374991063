angular.module('barometerApp.search')
  .directive('omnibarBehaviors', ['$timeout', function ($timeout) {
    return {
      link: function (scope, element, attrs) {

        var
          search = element.find('.search'),
          searchResultsDropdown = element.find('.search-results .dropdown'),
          recents = element.find('.recents'),
          recentsMenu = recents.find('.dropdown-menu'),
          recentsToggle = recents.find('.dropdown-toggle'),
          pivotNodes = element.find('.pivot-node-holder'),
          showRecents = function () {
            recents.addClass('open');
          },
          hideRecents = function () {
            recents.removeClass('open');
          },
          showSearchResults = function () {
            searchResultsDropdown.addClass('open');
          },
          hideSearchResults = function () {
            searchResultsDropdown.removeClass('open');
          };

        // when anywhere on bar is clicked, focus search
        element.on('click', function () {
          prototype.closeDropdowns();
          search.trigger('focus');
        });

        // when search focused, hide nodes, show recents
        search
          .on('focus', function (event) {
            prototype.closeDropdowns();
            pivotNodes.hide();
            showRecents();
          })
          .on('blur', function () {
            // delay re-showing the pivot nodes                                                                                                                                                      x`
            // this looks cool and allows any re-focusing to happen and short-circuit this
            // (eg: the user clicks remove when they are already in the field)
            $timeout(function () {
              if (!(search.is(':focus'))) {
                pivotNodes.fadeIn();
              }
            }, 500);
          }).on('keyup', function () {
          $timeout(function () {
            hideRecents();
          }, 500);
        });

        // when recents clicked, hide search results
        // NOTE: standard dropdown JS handles recents show/hide
        recentsToggle.on('click', function (e) {
          hideSearchResults();

          // don't let the click event bubble up to omnibar and trigger another search focus
          e.stopPropagation();
        });

        // when search, search results or recents menu clicked
        // don't let the click event bubble up to omnibar and trigger another search focus
        search.add(searchResultsDropdown).add(recentsMenu).on('click', function (e) {
          e.stopPropagation();
        });
      }
    }
  }]);
