angular.module('barometerApp.priorityEntities')
  .directive('personalPrioritySettings', ['priorityEntitiesService', 'bitConstants', 'utilService', 'tenantService',
    function (priorityEntitiesService, bitConstants, utilService, tenantService) {
    return {
      templateUrl: '/b/js/src/bit.ng/priority-entities/partials/personal-priority-settings.html',
      link: function (scope, elem, attrs) {

        scope.loaded = false;
        scope.tenantPriorityEntitiesModel = [];
        scope.personalPriorityEntitiesModel = [];
        scope.usePersonalPriorityEntities = priorityEntitiesService.getUsePersonalPriorityEntitiesFlag();
        const load = function() {
          const authorizedCatalogs = tenantService.getCurrentTenantsEnterpriseCatalogs();
          const filteredAndSorted = authorizedCatalogs
            .filter(c => ("TOP" !== c.entityTypeCode))
            .filter(c => ("TEP" !== c.entityTypeCode))
            .sort(utilService.sortByProperty('name'));

          const priorityEntities = (entityType, selected) => ({
            entityTypeCode: entityType.typeCode,
            entityTypeDisplayName: entityType.displayNamePlural,
            selected
          });
          const tenantPriorityEntitiesModel = filteredAndSorted.map(catalog => {
            const entityType = EntityTypes[catalog.entityTypeCode];
            return priorityEntities(entityType, priorityEntitiesService.isTenantPriorityEntityType(entityType.typeCode));
          });

          const personalPriorityEntitiesModel = filteredAndSorted.map(catalog => {
            const entityType = EntityTypes[catalog.entityTypeCode];
            return priorityEntities(entityType, priorityEntitiesService.isPersonalPriorityEntityType(entityType.typeCode));
          });

          scope.tenantPriorityEntitiesModel = tenantPriorityEntitiesModel.sort(utilService.sortByProperty('entityTypeDisplayName'));
          scope.personalPriorityEntitiesModel = personalPriorityEntitiesModel.sort(utilService.sortByProperty('entityTypeDisplayName'));
          scope.loaded = true;
        };
        load();
        const priorityEntities = (entityType, selected) => ({
          entityTypeCode: entityType.typeCode,
          entityTypeDisplayName: entityType.displayNamePlural,
          selected
        });

        scope.useTenantPriorityEntitiesClicked = function () {
          scope.usePersonalPriorityEntities = false;
          priorityEntitiesService.setUsePersonalPriorityEntitiesFlag(false);
        };
        scope.usePersonalPriorityEntitiesClicked = function () {
          scope.usePersonalPriorityEntities = true;
          priorityEntitiesService.setUsePersonalPriorityEntitiesFlag(true);
        };
        scope.togglePersonalPriorityEntity = function (toggledItem) {
          var entityTypeCodes = [];
          _.forEach(scope.personalPriorityEntitiesModel, function (modelItem) {
            if (modelItem.entityTypeCode === toggledItem.entityTypeCode) {
              modelItem.selected = !modelItem.selected;
            }
            if (modelItem.selected) {
              entityTypeCodes.push(modelItem.entityTypeCode);
            }
          });
          priorityEntitiesService.updatePersonalPriorityEntities(entityTypeCodes);
          priorityEntitiesService.displayApplicableWarnings(scope.personalPriorityEntitiesModel);
        };
      }

    }
  }])
  .directive('priorityEntitySelect', ['priorityEntitiesService', function (priorityEntitiesService) {
    return {
      scope: {
        changeFn: '=',                // The function to execute on value change.
        model: '=',
        entityTypes: '=',             // The initial array of entityTypes.
        entityTypeCodeProperty: '=',  // The property key that holds the three digit entityTypeCode.
        optionDisplayProperty: '=',   // The property key that holds the displayable value.
        optionValueProperty: '=',     // This is the value of the option in the select list. If this is empty the whole model object is the value.
        filterModel: '=',
        filter: '=',
        uiSelect2Model: '=',          // Model for ui-select2 attribute.
        useMultiple: '='              // Use a multi-select input.
      },
      restrict: 'E',
      templateUrl: '/b/js/src/bit.ng/priority-entities/partials/priority-entity-selector.html',
      link: function (scope, elem, attrs) {

        function initOptions() {

          // If the 'entityTypeCodeProperty' or the 'optionDisplayProperty' aren't available,
          // default to the 'typeCode' and 'displayName' properties keys from the EntityTypes constants.
          var typeValue = scope.entityTypeCodeProperty || 'typeCode';
          var display = scope.optionDisplayProperty || 'displayName';

          scope.options = [];

          // 1. If priority entities are set, create priority entity options in it's own group
          if (priorityEntitiesService.getPriorityEntities().length > 0) {
            scope.options = scope.options.concat(
              _.map(
                // Select only priority entities
                _.filter(scope.entityTypes, function (type) {
                  return priorityEntitiesService.isPriorityEntityType(type[typeValue]);
                }),

                function (type) {
                  return {
                    groupName: 'Priority Catalogs',
                    groupId: 0 + type[display],
                    _display: type[display],
                    _value: scope.optionValueProperty ? type[scope.optionValueProperty] : cloneType(type)
                  };
                }));
          }

          // 2. Create options for all entityTypes in the ALL group
          scope.options = scope.options.concat(_.map(scope.entityTypes, function (type) {
            return {
              groupName: 'All Entities',
              groupId: 1 + type[display],
              _display: type[display],
              _value: scope.optionValueProperty ? type[scope.optionValueProperty] : cloneType(type)
            };
          }));
        }

        // Do a deep copy of the object.
        function cloneType(type) {
          return JSON.parse(JSON.stringify(type));
        }

        function filteredChangeFn(model) {
          scope.filterModel.filter.selected[scope.filter.filterBn] = [];

          // Single-select model is a string
          if (typeof model === 'string' && model.length > 0) {
            scope.filterModel.filter.selected[scope.filter.filterBn].push(model);
          }

          // Multi-select model is an Array!
          else if (Array.isArray(model)){
            scope.filterModel.filter.selected[scope.filter.filterBn] = model;
          }
        }

        scope.change = function (model) {

          // Remove dupes if it's in array (useMultiple === true)
          if (Array.isArray(model)){
            model = _.uniq(model);
            console.log('de-duped model', model);
          }

          scope.model = model;
          if (scope.changeFn !== undefined && scope.changeFn !== null) {
            scope.changeFn(scope.model);
          }

          if (scope.filterModel && scope.filter) {
            filteredChangeFn(model);
          }
        };

        scope.$watch('entityTypes', () => initOptions());
      }
    }
  }]);
