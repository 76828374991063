angular.module('barometerApp.filter')
    .controller('GroupedMultiSelectCtrl', ['$scope', '$rootScope', function ($scope, $rootScope) {

        $scope.tableId = $scope.$parent.tableId;

        var populateModel = function(){

            $scope.groupedMultiSelectModel = {
                filters: [],
                multiSelectValue: [],
                lifecycleTypeBn: "",
                cachedData: []
            };

            // record hierarchical filter as a "grouping" to aid forming the query parameters later
            // see usage in filterService.convertFilterObjectToFilterArray
            if ($scope.filter && $scope.filter.filterBn && $scope.filter.options && $scope.filter.options.length) {
                $scope.filterModel.filter.filterGroups.push(
                    {
                        grouping: [
                            $scope.filter.filterBn,            // the filter's BN
                            $scope.filter.options[0].value     // the option list's BN, same for all options
                        ],
                        filterIndex: null
                    }
                );
            }

            // Populate groupedMultiSelectModel, top level filters first
            _.each($scope.filter.options, function (option, index) {
                $scope.groupedMultiSelectModel.filters.push(
                    {
                        name: option.name,
                        display: option.display,        // special display name reserved for parent options
                        value: option.value,
                        isSelected: $scope.filterModel.filter.selected && $scope.filterModel.filter.selected[option.value] == option.name
                    });
                $scope.groupedMultiSelectModel.lifecycleTypeBn = option.value;

                // Add child filters
                _.each(option.children, function (opt) {
                    $scope.groupedMultiSelectModel.filters.push({
                        name: opt.name,
                        value: opt.value,
                        isChild: true,
                        parentBn: option.value,
                        parentValue: option.name,
                        isSelected: $scope.filterModel.filter.selected && $scope.filterModel.filter.selected[opt.value] == opt.name
                    });
                });
            });

            // Record which filters are selected
            _.each($scope.groupedMultiSelectModel.filters, function (option, index) {
                if (option.isSelected) {
                    $scope.groupedMultiSelectModel.multiSelectValue.push(index);
                    $scope.groupedMultiSelectModel.cachedData.push(index + "");
                }
            });
        };
        populateModel();

        // When select2 changes, transform flattened models back into initial format (I think) <-- PAJ
        $scope.passMultiSelectValue = function (data) {

            // when no options selected, data is null - make it an array so the rest of this function is happy
            if (!data) data=[];

            var index,
                diff = $scope.groupedMultiSelectModel.cachedData.length > data.length ? _.difference($scope.groupedMultiSelectModel.cachedData, data) : _.difference(data, $scope.groupedMultiSelectModel.cachedData);

            if (data.length == 0) {

//              // no options are selected
                if (typeof $scope.filterModel.filter.selected[$scope.filter.filterBn] !== "undefined") {
                    $scope.filterModel.filter.selected[$scope.filter.filterBn].length = 0;
                }
                if (typeof $scope.filterModel.filter.selected[$scope.groupedMultiSelectModel.lifecycleTypeBn] !== "undefined") {
                    $scope.filterModel.filter.selected[$scope.groupedMultiSelectModel.lifecycleTypeBn].length = 0;
                }
            } else {
                if (data.length > $scope.groupedMultiSelectModel.cachedData.length) {
                    //user added a value
                    _.each(diff, function (datum) {
                        datum = $scope.groupedMultiSelectModel.filters[datum];
                        if (datum.isChild) {
                            //is a BN
                            $scope.filterModel.filter.selected[$scope.filter.filterBn] = $scope.filterModel.filter.selected[$scope.filter.filterBn] || [];
                            $scope.filterModel.filter.selected[$scope.filter.filterBn].push(datum.value);
                        } else {
                            //is a type
                            $scope.filterModel.filter.selected[datum.value] = $scope.filterModel.filter.selected[datum.value] || [];
                            $scope.filterModel.filter.selected[datum.value].push(datum.name);
                        }
                    });
                } else {
                    //user removed a value
                    _.each(diff, function (datum) {
                        datum = $scope.groupedMultiSelectModel.filters[datum];
                        if (datum.isChild) {
                            //is a BN
                            index = $scope.filterModel.filter.selected[$scope.filter.filterBn].indexOf(datum.value);
                            if (index > -1) {
                                $scope.filterModel.filter.selected[$scope.filter.filterBn].splice(index, 1);
                            }
                        } else {
                            //is a type
                            index = $scope.filterModel.filter.selected[datum.value].indexOf(datum.name);
                            if (index > -1) {
                                $scope.filterModel.filter.selected[datum.value].splice(index, 1);
                            }
                        }
                    });
                }
            }
            $scope.groupedMultiSelectModel.cachedData = data;

            // Event for ensuring that the filter bar for Summary vs. Table views remain in synch
            // Other instances of the same filter will be listening
            $rootScope.$broadcast("groupedMultiSelectChanged", {
                tableId: $scope.tableId,
                value: $scope.groupedMultiSelectModel.multiSelectValue,
                scopeId: $scope.$id,
                filterBn: $scope.filter.filterBn,
                cachedData: data
            });
        };

        // Event for ensuring that the filter bar for Summary vs. Table views remain in synch
        // Other instances of the same filter will be listening
        $scope.$on("groupedMultiSelectChanged", function (event, data) {
            if (data.tableId === $scope.tableId && $scope.$id != data.scopeId && data.filterBn == $scope.filter.filterBn) {
                $scope.groupedMultiSelectModel.multiSelectValue = data.value;
                $scope.groupedMultiSelectModel.cachedData = data.cachedData;
                $scope.$apply();
            }
        });

    }])
    .controller('filterCtrl', ['$scope', function ($scope) {
        $scope.filterModel = {
            filter: {
                isLoading: true,
                specification: [],
                selected: {},
                searchString: "",
                qualifier: null,
                filterGroups: [],
                typeaheadFilter: {}
            }
        }
    }])
    .controller('QualifierFilterCtrl', ['$scope', function ($scope) {
        $scope.qualifierFilterModel = {
            filters: [],
            type: null,
            singleSelectValue: null
        };

        if ($scope.filter && $scope.filter.filterBn && $scope.filter.options && $scope.filter.options.length) {
            $scope.qualifierFilterModel.type = $scope.filter.type;
            //hack to prevent duplicates from being entered. Should only ever have 1 grouping for a given set,
            // but this is being called twice since we have a table & summary view filter
            if (!($scope.filterModel.filter.filterGroups && $scope.filterModel.filter.filterGroups.length > 0)) {

                $scope.filterModel.filter.filterGroups.push(
                    {
                        grouping: [$scope.filter.filterBn, $scope.filter.options[0].value],
                        filterIndex: null
                    }
                );
            }
        }
        _.each($scope.filter.options, function (option) {
            var isSelected = $scope.filterModel.filter.selected && $scope.filterModel.filter.selected[option.value] == option.name;
            $scope.qualifierFilterModel.filters.push({ name: option.name, value: option.value, isSelected: isSelected });
            _.each(option.children, function (opt) {
                $scope.qualifierFilterModel.filters.push({ name: opt.name, value: opt.value, isChild: true, parentBn: option.value, parentValue: option.name, isSelected: $scope.filterModel.filter.selected && $scope.filterModel.filter.selected[opt.value] == opt.name });
            });
        });

        _.each($scope.qualifierFilterModel.filters, function (option, index) {
            if (option.isSelected) {
                $scope.qualifierFilterModel.singleSelectValue.push(index);
            }
        });

        $scope.getValue = function (opt) {
            if (opt) {
                if ($scope.qualifierFilterModel.type == 'GROUPED_SINGLE_SELECT_VALUED' && !opt.isChild) {
                    return opt.name;
                }
                return opt.value;
            }
        }
    }]);
