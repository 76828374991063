/**
 * Use controllers to:
 * Set up the initial state of a scope object (data, aka the Model, incl. promise functions).
 * Add behavior to the scope object (behavioral functions).
 *
 * Do not use controllers for:
 * Any kind of DOM manipulation.
 * Input formatting.
 * Output filtering.
 * To run stateless or stateful code shared across controllers.
 * To instantiate or manage the life-cycle of other components.
 *
 * You can associate controllers with scope objects implicitly via the ngController directive or $route service.
 *
 * This file tries to follow John Papa's Angular 1 style guide (adjusted to our AngularJS version).
 *
 * https://github.com/johnpapa/angular-styleguide/blob/master/a1/README.md
 */
angular
    .module('barometerApp.simpleTable')
    .controller('SimpleTableFiltersController', SimpleTableFiltersController);

SimpleTableFiltersController.inject = ['$scope', 'utilService'];

/**
 * Registered with the corresponding directive.
 *
 * @param $scope
 */
function SimpleTableFiltersController($scope) {

    // Used for all but type-ahead.
    var select2Options = {
        allowClear: true,
        placeholder: 'Select filter value',   // required for single-selects to have 'x' to remove
        dropdownCssClass: 'parent-label-group',
        templateResult: format
    }

    // BEGIN CONTROL FUNCTIONS - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    /**
     *
     * @param data
     * @returns {string}
     */
    function format(data) {
        //
        if (!data.element) {
            return data.text;
        }

        var option = $(data.element);

        var wrapper = $('<span></span>');
        wrapper.addClass(option[0].className);
        wrapper.text(data.text);

        return wrapper;
    }

    /**
     *
     */
    function setFilters() {
        //
        // Parent scope must implement updateFilterModel().
        $scope.updateFilterModel();
        $scope.filterModel.defaultSelect2Options = select2Options;
        //
        // Check for existing state first (leftover from a previous table/summary view of the same data).
        /*var existingFilterState = filterService.getTableFilter($scope.tableId);
        if (existingFilterState) {
          $scope.filterModel = existingFilterState;
        }

        // otherwise just use the filter that was passed in
        else {
          $scope.filterModel = {
            filter: $scope.filter,
            cachedTypeahead: {},
            entityTypeCodeOfCurrentPage: $scope.entityType,
            typeaheadFilter: {},
            superSelectorFilter: {},
            tableId: $scope.tableId,

            // used for all but typeahead
            defaultSelect2Options: {
              allowClear: true,
              placeholder: 'Select filter value',   // required for single-selects to have 'x' to remove
              dropdownCssClass: 'parent-label-group',
              formatResult: format
            }
          };
        }*/
    }

    // BEGIN SCOPED FUNCTIONS - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    /**
     * Special config for type-ahead.
     *
     * @param filter
     * @returns {{allowClear: boolean, multiple: boolean, placeholder: string, query: query, initSelection: initSelection}}
     */
    $scope.getTypeaheadSelect2Options = function (filter) {
        //
        return {
            allowClear: true,
            multiple: true,
            placeholder: 'Select filter value',
            query: function (query) {
                if ($scope.pendingPromise) {
                    $timeout.cancel($scope.pendingPromise);
                }
                $scope.pendingPromise = $timeout(function () {
                    // Wrap searchString with filter obj to satisfy searchService.searchEntity() signature
                    const filterSearchString = query.term ? query.term : "";
                    const filterObj = filterService.convertFilterObjectToFilterArray({ searchString: filterSearchString });
                    const targetBnTypeCode = utilService.getTypeCodeFromBnCode(filter.targetBnCode);
                    searchService.searchEntity(10, null, targetBnTypeCode, false, null, filterObj).then(function (data) {
                        var results = [];
                        _.each(data.data.entities, function (res) {
                            results.push({id: res.bn, text: res.name});
                        });
                        query.callback({results: results});
                    })
                }, 500);
            },
            // required for rendering existing values properly
            initSelection: function (element, callback) {

                // this is already in the correct format:
                // [{id: "ZZ180000001O", text: "Another Basic SYSTEM Release"}, ...]
                //callback($scope.filterModel.filter.selected[filter.filterBn]);
                callback(filter.selected);
            }
        }
    };

    /**
     *
     * @param filter
     * @returns {{results: *}}
     */
    $scope.getMultiFilterOptions = function (filter) {
        return {
            results: filter.options
        }
    };

    /**
     * Handles 'SINGLE_SELECT_VALUED' and 'GROUPED_SINGLE_SELECT_VALUED' cases.
     *
     * @param selectedValue
     * @param filter
     * @returns {string}
     */
    $scope.getSelectedOptionNameForSingleSelect = function (selectedValue, filter) {
        var name = 'Any';
        $.each(filter.options, function (index, option) {

            // GROUPED_SINGLE_SELECT_VALUED matches on name(!) and has children
            if (filter.type == 'GROUPED_SINGLE_SELECT_VALUED') {
                if (option.name == selectedValue) {
                    name = option.name;
                    return false;
                }

                // but children match on value again(!)
                else if ('children' in option) {
                    $.each(option.children, function (index, childOption) {
                        if (childOption.value == selectedValue) {
                            name = childOption.name;
                            return false;
                        }
                    });
                }
            }

            // default matches on value, is flat list
            else if (option.value == selectedValue) {
                name = option.name;
                return false;
            }
        });
        return name;
    };

    /**
     * Handles 'MULTI_SELECT_BN' case (type-head entity selector).
     *
     * @param filter
     * @returns {*}
     */
    $scope.getSelectedOptionNamesForTypeahead = function (filter) {
        // this returns an array of objects for each selected entity:
        // [{id: ENTITY_BN, text: ENTITY_NAME}, ...]
        var selectedFilters = $scope.filterModel.filter.typeaheadFilter[filter.filterBn];

        // default empty state
        if (!selectedFilters || selectedFilters && selectedFilters.length == 0) {
            return ['Any'];
        }

        // array of selected entitie's display names
        else {
            return _.map(selectedFilters, function (entityObj) {
                return entityObj.text;
            })
        }
    };

    /**
     * Handles all other controls.
     *
     * @param filter
     * @returns {*}
     */
    $scope.getSelectedOptionNames = function (filter) {
        //
        var selectedNames = [];
        var selectedValues = filter.selected;
        $.each(selectedValues, function (index, selectedValue) {
            $.each(filter.options, function (index, option) {
                if (selectedValue == option.value) {
                    selectedNames.push(option.name);
                }
            })
        })
        if (selectedNames.length) {
            return selectedNames;
        } else {
            return ['Any'];
        }
    }

    // BEGIN DO STUFF - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    //setFilterState();
    // Update the filter state whenever the table/summary views are swapped.
    //$scope.$on('load', setFilterState);
    //$scope.$on('reload', setFilterState);

    setFilters();
}
