angular
  .module('barometerApp.relationalDiagram')
  .controller('RelationalDiagramSidebarController', RelationalDiagramSidebarController);

RelationalDiagramSidebarController.inject = [
  '$modal',
  '$rootScope',
  '$scope',
  '$timeout',
  'alertService',
  'rdConfigModel',
  'relationalDiagramGraphDataService',
  'relationalDiagramSidebarModelService',
  'urlService',
  'utilService',
  'workspaceService'
];

/**
 *
 */
function RelationalDiagramSidebarController ($modal,
                                             $rootScope,
                                             $scope,
                                             $timeout,
                                             alertService,
                                             rdConfigModel,
                                             relationalDiagramGraphDataService,
                                             relationalDiagramSidebarModelService,
                                             urlService,
                                             utilService,
                                             workspaceService) {

  $scope.sidebarState = {};
  // displayData is the main model for the right-hand sidebar!
  $scope.displayData = null;
  $scope.displayType = "";
  $scope.removeLinkActive = false;

  /*  rdConfigModel.loadWorksheetForBn(getWorksheetBn())
      .then(function (worksheetEntity) {
        setConfig(worksheetEntity.configuration);
      });*/

  //--------------------------------------------------
  // PRIVATE SCOPE ACCESS
  //--------------------------------------------------

  /**
   * Skyhook into the urlService to make sure we know our page identity.
   */
  function getWorksheetBn () {
    if (!$scope.worksheetBn) {
      $scope.worksheetBn = urlService.getEntityBn();
    }
    return $scope.worksheetBn;
  }

  function getDisplayData () {
    return $scope.displayData;
  }

  function setDisplayData (aValue) {
    $scope.displayData = aValue;
  }

  function initDisplayData (obj) {
    $scope.displayData = {};
    $scope.displayData.entityType = obj.entityDiscriminator;
    return $scope.displayData;
  }

  function clearDisplayData () {
    // Needs timeout because wonky ui stuff happens otherwise.
    $timeout(function () {
      $scope.displayData = null;
    });
  }

  /**
   * @param isActive
   * @param isButtonsActive
   */
  function toggleSidebarState (isActive, isButtonsActive) {
    //
    $scope.sidebarState.active = isActive;
    $scope.sidebarState.buttonsActive = isButtonsActive;
    clearDisplayData();
  }

  /**
   * In this controller, this function is largely used to persist changes to Groups (add, update, delete).
   *
   * @param changedObject
   */
  function saveConfig (changedObject) {
    rdConfigModel.writeWorksheetConfig(true)
      .then(function (data) {
        $rootScope.$broadcast("worksheetAssocAddedOrUpdated", changedObject);
      });
  }

  //--------------------------------------------------
  // PRIVATE FUNCTIONS
  //--------------------------------------------------

  /**
   * @param nodeBn
   * @param resultProcessor A processor for the boolean result of the question.
   */
  function isAssociatedToWorksheet (nodeBn, resultProcessor) {
    //
    //console.log("isAssociatedToWorksheet: Testing nodeBn=" + nodeBn);
    //
    var args = {
      sourceBn: getWorksheetBn(),
      targetBn: nodeBn,
      entityTypeCode: utilService.getEntityTypeCode(nodeBn)
    };
    workspaceService.isEntityAssociated(args, function (result) {
      //console.log("isAssociatedToWorksheet: result=" + result);
      return resultProcessor(result);
    });
  }

  /**
   *
   */
  function pruneAssociatedEntities (data, associated) {
    //
    //console.log("pruneAssociatedEntities");
    //
    for (var group in associated) {
      for (var code in data.data.results) {
        if (EntityTypes[data.data.results[code].entityTypeCode].bnCode == group) {
          for (var entity = associated[group].length - 1; entity >= 0; entity--) {
            for (var added in data.data.results[code].entityTypeResults) {
              if (associated[group][entity] && associated[group][entity].bn == data.data.results[code].entityTypeResults[added].bn) {
                var removed = associated[group].splice(entity, 1);
              }//end same check
            }//end loop through associated
          }//end loop through associated
        }//end grouping check
      }//end loop through related
    }//end loop through all associated
  }

  /**
   * Use as a callback when an async callout needs to be made to discover whether a node isAssociatedToWorksheet.
   * Or just use this a simple reusable function call when you know your answer = false.
   *
   * @param isRemoveLinkActive The remove link will only show if this is true.
   *  Set this with a test of something like e.g. isEntityAssociatedToWorksheet().
   */
  const toggleRemoveLink = function (isRemoveLinkActive) {
    //
    //console.log("toggleRemoveLink to " + isRemoveLinkActive);
    //
    $scope.removeLinkActive = isRemoveLinkActive;
    if ($scope.removeLinkActive) {
      $rootScope.$broadcast('relationalDiagramRemoveEntityOptionAvailable', getDisplayData());
    } else {
      $rootScope.$broadcast('relationalDiagramRemoveEntityOptionAvailable', false);
    }
  };

  /**
   *
   */
  function populateDisplayData (obj, dataType, node) {
    //
    // Re-initialize model.
    initDisplayData(obj);
    $scope.displayType = dataType;
    //
    // Re-populate the model.
    switch ($scope.displayType) {
      case 'entity':
        // Parameter obj is a bIT entity.
        // Parameter node is a cyto node, data from which has already been used to obtain obj.
        var entityData = relationalDiagramSidebarModelService.buildDisplayDataForEntity(obj, node);
        setDisplayData(entityData);
        isAssociatedToWorksheet($scope.displayData.bn, toggleRemoveLink);
        break;
      case 'group':
        // Parameter obj is the cyto node for the group.
        // Parameter node is also the same cyto node for the group.
        // This needs timeout because clear sidebar has timeout.
        // Clear sidebar needs timeout because a wonky ui glitch happens otherwise.
        $timeout(function () {
          var groupData = relationalDiagramSidebarModelService.buildDisplayDataForGroup(obj, node);
          setDisplayData(groupData);
          toggleRemoveLink(false);
        });
        break;
      case 'assoc':
        // Parameter obj is data from a cytoscape edge object, i.e. edge.data().
        // Parameter node is intentionally undefined.
        function myCallback (err, assocData) {
          setDisplayData(assocData);
          toggleRemoveLink(false);
        }

        relationalDiagramSidebarModelService.buildDisplayDataForAssoc(obj, node, myCallback);
        break;
    }
  }

  /**
   * Associate inbound entities to Worksheet.
   */
  function addAssociation (entities, $event) {
    console.log('addAssociation', entities);
    $scope.addEntities(entities);
  }

  /**
   *
   */
  function addOrUpdateGroup (protoGroup) {
    //
    // console.log("addOrUpdateGroup");
    //
    var existingGroups = rdConfigModel.getGroups();
    var existingGroup = rdConfigModel.getGroupById(protoGroup.data.id);

    // Must be a create/add.
    if (!existingGroup) {

      // Check if the child already has a group
      const childNodeId = protoGroup.data.children[0];
      const originalNodeParent = existingGroups.find(group => {
        return group.data.children.find(childId => childId === childNodeId);
      });

      // If the node was already in a group...
      if (originalNodeParent) {

        // 1. Remove the node from the old group
        originalNodeParent.data.children = originalNodeParent.data.children.filter(child => {
          return child !== childNodeId;
        });

        // 2. Add the new group to the original group (need to set both child & parent fields)
        originalNodeParent.data.children.push(protoGroup.data.id);
        protoGroup.data.parent = originalNodeParent.data.id;
      }

      existingGroups.push(protoGroup);
    }

    // Must be an update. Merge children.
    else {
      existingGroup.data.children = protoGroup.data.children;
    }
    saveConfig(protoGroup);
  }

//-------------------------------------------------------------------------
// PUBLIC FUNCTIONS (usually means "used from an html partial")
//-------------------------------------------------------------------------

  /**
   * DELETE GROUP
   */
  $scope.deleteGroup = function ($event, id) {
    //
    //console.log("deleteGroup");
    //
    var index = rdConfigModel.getGroupIndexById(id);
    if (index < 0) return;
    //
    var myGroups = rdConfigModel.getGroups();
    var removedGroup = myGroups.splice(index, 1);
    saveConfig(removedGroup);
  };

  /**
   * REMOVE ENTITY FROM GROUP
   */
  $scope.removeEntityFromGroup = function ($event, nodeBn, parentId) {
    //
    //console.log("removeEntityFromGroup");
    //
    var parentGroup = rdConfigModel.getGroupById(parentId);
    var nodeIndex = parentGroup.data.children.indexOf(nodeBn);
    var removedEntity = parentGroup.data.children.splice(nodeIndex, 1);
    addOrUpdateGroup(parentGroup);
  };

  /**
   * REMOVE GROUP FROM GROUP
   */
  $scope.removeGroupFromGroup = function ($event, childId, parentId) {
    //
    //console.log("removeGroupFromGroup");
    //
    var childGroup = rdConfigModel.getGroupById(childId);
    childGroup.data.parent = null;
    addOrUpdateGroup(childGroup);
  };

  /**
   * PREPARE AND LAUNCH ASSOCIATED ENTITIES MODAL
   *
   * Relationships need to be added synchronously or else it will result in a stale state exception.
   */
  $scope.addAssociatedEntities = function ($event, nodeBn) {
    //
    //console.log("addAssociatedEntities");
    //
    relationalDiagramGraphDataService.getAssociatedEntities(nodeBn)
      .then(function (response) {
        workspaceService.getAssociated(getWorksheetBn(), 99999)
          .then(function (data) {
            var associated = response.associatedEntities;
            pruneAssociatedEntities(data, associated);
            //
            var hasEntities = false;
            for (var group in associated) {
              if (associated[group].length > 0) {
                hasEntities = true;
              }
            }
            if (hasEntities) {
              launchAssociatedEntitiesModal($event, associated)
            } else {
              alertService.addAlert({ type: '', message: "There Are No Associated Entities To Add" });
            }
          })
      });
  };

//--------------------------------------------------
// PUBLIC MODALS
//--------------------------------------------------

  /**
   * BARO-18815: Changed from private var. Re-tested all inbound calls to this function.
   */
  function launchAssociatedEntitiesModal ($event, associatedEntities) {
    //
    //console.log('launchAssociatedEntitiesModal');
    //
    $event.preventDefault();
    //
    var modal = $modal.open({
      backdrop: 'static',
      keyboard: true,
      templateUrl: '/b/js/src/bit.ng/relational-diagram/partials/relational-diagram-associated-entity-modal.html',
      controller: 'RelationalDiagramAssociatedEntityController',
      resolve: {
        config: function () {
          return associatedEntities;
        }
      }
    });
    modal.result.then(function (addedEntities) {
      if (addedEntities) {
        addAssociation(addedEntities, $event);
      }
    })
  }

  /**
   * CREATE GROUP MODAL
   */
  $scope.createGroup = function ($event, bn) {
    //
    //console.log("createGroup: bn = " + bn);
    //
    $event.preventDefault();
    //
    var modal = $modal.open({
      backdrop: 'static',
      keyboard: true,
      templateUrl: '/b/js/src/bit.ng/relational-diagram/partials/relational-diagram-create-group-modal.html',
      controller: 'RelationalDiagramCreateGroupController',
      resolve: {
        config: function () {
          return rdConfigModel.getConfig();
        },
        bn: function () {
          return bn;
        }
      }
    });
    modal.result
      .then(function (group) {
        if (group) {
          addOrUpdateGroup(group, $event);
        }
      })
  };

  /**
   * EDIT GROUP MODAL
   */
  $scope.editGroupName = function ($event, id) {
    //
    //console.log("editGroupName: id = " + id);
    //
    $event.preventDefault();
    //
    var group = rdConfigModel.getGroupById(id);
    //
    var modal = $modal.open({
      backdrop: 'static',
      keyboard: true,
      templateUrl: '/b/js/src/bit.ng/relational-diagram/partials/relational-diagram-change-group-name-modal.html',
      controller: 'RelationalDiagramChangeGroupNameController',
      resolve: {
        config: function () {
          return rdConfigModel.getConfig();
        },
        id: function () {
          return group.data.id;
        }
      }
    });
    modal.result
      .then(function (changedGroup) {
        var groupAgain = rdConfigModel.getGroupById(id);
        groupAgain.data.id = changedGroup.data.id;
        //console.log("editGroupName data.id=" + groupAgain.data.id);
        groupAgain.data.entity.name = changedGroup.data.entity.name;
        //console.log("editGroupName data.entity.name=" + groupAgain.data.entity.name);
        saveConfig(groupAgain);
      })
  };

  /**
   * ADD ENTITY TO GROUP MODAL
   */
  $scope.addEntityToGroup = function ($event, bn) {
    //
    //console.log("addEntityToGroup: bn = " + bn);
    //
    $event.preventDefault();
    //
    var modal = $modal.open({
      backdrop: 'static',
      keyboard: true,
      templateUrl: '/b/js/src/bit.ng/relational-diagram/partials/relational-diagram-add-to-group-modal.html',
      controller: 'RelationalDiagramAddToGroupController',
      resolve: {
        config: function () {
          return rdConfigModel.getConfig();
        },
        nodeBn: function () {
          return bn;
        }
      }
    });
    modal.result
      .then(function (group) {
        if (group) {
          addOrUpdateGroup(group, $event);
        }
      })
  };

  /**
   * ADD GROUP TO GROUP MODAL
   */
  $scope.addGroupToGroup = function ($event, childId) {
    //
    //console.log("addGroupToGroup");
    //
    var childGroup = rdConfigModel.getGroupById(childId);
    //
    $event.preventDefault();
    //
    var modal = $modal.open({
      backdrop: 'static',
      keyboard: true,
      templateUrl: '/b/js/src/bit.ng/relational-diagram/partials/relational-diagram-add-to-group-modal.html',
      controller: 'RelationalDiagramAddToGroupController',
      resolve: {
        config: function () {
          return rdConfigModel.getConfig();
        },
        nodeBn: function () {
          //console.log("nodeBn=" + childGroup.data.id);
          return childGroup.data.id;
        }
      }
    });
    modal.result
      .then(function (parentId) {
        if (parentId === childId) {
          throw 'Cannot add a group to itself.';
        }
        if (parentId) {
          childGroup.data.parent = parentId;
          addOrUpdateGroup(childGroup);
        }
      })
  };

//--------------------------------------------------
// EVENT HANDLERS (BROADCAST)
//--------------------------------------------------

  $scope.$on('relationalDiagramNodeClicked', function (event, node) {
    //console.log("relationalDiagramNodeClicked");
    toggleSidebarState(true, true);
    relationalDiagramSidebarModelService
      .getEntity(node.data().entity.bn, function (entity) {
        // TODO This seems a bit roundy-round, cuz the model service could getEntity.
        // TODO And you can contrast to the same operation for Assocs, where it DOES.
        populateDisplayData(entity, 'entity', node);
      });
  });

  $scope.$on('relationalDiagramGroupClicked', function (event, node) {
    //console.log("relationalDiagramGroupClicked");
    toggleSidebarState(true, true);
    populateDisplayData(node, 'group', node);
  });

  $scope.$on('relationalDiagramEdgeClicked', function (event, edge) {
    //console.log("relationalDiagramEdgeClicked");
    toggleSidebarState(true, false);
    console.log(edge.data());
    populateDisplayData(edge.data(), 'assoc');
  });

  $scope.$on('relationalDiagramBackgroundClicked', function (event, edge) {
    //console.log("relationalDiagramBackgroundClicked");
    toggleSidebarState(false, false);
  });

  $scope.$on('worksheetEntityRemoved', function (event, nodeBn) {
    // console.log("worksheetEntityRemoved");
    toggleSidebarState(false, false);
  });

  $scope.$on('worksheetAssocRemoved', function () {
    //console.log("worksheetAssocRemoved");
    toggleSidebarState(false, false);
  });

  $scope.$on('worksheetAssocAddedOrUpdated', function () {
    //console.log("worksheetAssocAddedOrUpdated");
    toggleSidebarState(false, false);
  });

//--------------------------------------------------
// EVENT HANDLERS (WATCH)
//--------------------------------------------------

  $scope.$watch('displayData', function (newValue, oldValue) {
    //console.log("displayData changed: newValue=" + newValue + "; oldValue=" + oldValue);
  });

  $scope.$watch('displayData.bn', function (newValue, oldValue) {
    //console.log("displayData.bn changed: newValue=" + newValue + "; oldValue=" + oldValue);
  });

  $scope.$watch('displayData.children', function (newValue, oldValue) {
    //console.log("displayData.children changed: newValue=" + newValue + "; oldValue=" + oldValue);
  });

  $scope.$watch('displayData.childrenName', function (newValue, oldValue) {
    //console.log("displayData.childrenName changed: newValue=" + newValue + "; oldValue=" + oldValue);
  });

  $scope.$watch('displayData.entityType', function (newValue, oldValue) {
    //console.log("displayData.entityType changed: newValue=" + newValue + "; oldValue=" + oldValue);
  });

  $scope.$watch('displayData.parentId', function (newValue, oldValue) {
    //console.log("displayData.parentId changed: newValue=" + newValue + "; oldValue=" + oldValue);
  });

  $scope.$watch('displayType', function (newValue, oldValue) {
    //console.log("displayType changed: newValue=" + newValue + "; oldValue=" + oldValue);
  });

//--------------------------------------------------
// OTHER STUFF
//--------------------------------------------------

  /**
   * TODO Is this never ever used?
   * TODO I see no usages of "getEntityNameFromEntityBn" in our entire codebase.
   */
// $scope.getEntityNameFromEntityBn = function (bn) {
//     return entityService.getBasicInfo(bn).then(function (data) {
//         return data.data.name;
//     });
// };
}
