angular.module('barometerApp.radialChart')
  .directive('radialChart', [function () {
    return {
      templateUrl: '/b/js/src/bit.ng/radial-chart/partials/radial-chart.html',
      scope: {
        previewMode: '@',
        worksheetBn: '='
      }
    }
  }])
  .directive('radialChartViz', [function () {

    return {
      scope: {
        worksheetBn: '@',
        diagramEntities: '=',
        diagramLinks: '='
      },
      link: function ($scope, $element) {

        function getDiameter() {
          var container = $element.closest('.viz, .worksheet-content');
          return Math.max(Math.min(container.width(), container.height()) * .9, 800);
        }

        function init() {

          // Clear out the container
          $element.empty();

          console.log('init', $scope.diagramEntities, $scope.diagramLinks);
          var diam = getDiameter(),
            rx = diam / 2,
            ry = diam / 2,
            m0;

          var cluster = d3.layout.cluster().size([360, ry - 120]);

          var bundle = d3.layout.bundle();

          var line = d3.svg.line.radial()
            .interpolate('basis')
            .tension(.85)
            .radius(function (d) {
              return d.y;
            })
            .angle(function (d) {
              return d.x / 180 * Math.PI;
            });


          var div = d3.select($element.get(0)).insert('div')
            .attr('class', 'svg-holder')
            .style('width', diam + 'px')
            .style('height', diam + 'px')
            .style('-webkit-backface-visibility', 'hidden');

          var svg = div.append('svg:svg')
            .attr('viewbox', '0,0,' + diam + ',' + diam)
            .attr('height', diam)
            .attr('width', diam)
            .attr('version', '1.1')
            .append('svg:g')
            .attr('transform', 'translate(' + rx + ',' + ry + ')');

          svg.append('svg:path')
            .attr('class', 'arc')
            .attr('d', d3.svg.arc().outerRadius(ry - 120).innerRadius(0).startAngle(0).endAngle(2 * Math.PI))
            .on('mousedown', mousedown);

          var
            nodes = cluster.nodes($scope.diagramEntities),
            links = $scope.diagramLinks,
            splines = bundle(links);

          svg.selectAll('path.link')
            .data(links)
            .enter().append('svg:path')
            .attr('class', function (d) {
              return 'link source-' + d.source.key + ' target-' + d.target.key;
            })
            .attr('d', function (d, i) {
              return line(splines[i]);
            });

          svg.selectAll('g.node')
            .data(nodes.filter(function (n) {
              return !n.children;
            }))
            .enter().append('svg:g')
            .attr('class', 'node')
            .attr('id', function (d) {
              return 'node-' + d.key;
            })
            .attr('transform', function (d) {
              return 'rotate(' + (d.x - 90) + ')translate(' + d.y + ')';
            })
            .append('svg:text')
            .attr('dx', function (d) {
              return d.x < 180 ? 8 : -8;
            })
            .attr('dy', '.31em')
            .attr('text-anchor', function (d) {
              return d.x < 180 ? 'start' : 'end';
            })
            .attr('transform', function (d) {
              return d.x < 180 ? null : 'rotate(180)';
            })
            .text(function (d) {
              return d.name;
            })
            .on('mouseover', mouseover)
            .on('mouseout', mouseout);

          function mouse(e) {
            return [e.pageX - rx, e.pageY - ry];
          }

          function mousedown() {
            m0 = mouse(d3.event);
            d3.event.preventDefault();
          }

          function mouseover(d) {
            svg.selectAll('path.link.target-' + d.key)
              .classed('target', true)
              .each(updateNodes('source', true));

            svg.selectAll('path.link.source-' + d.key)
              .classed('source', true)
              .each(updateNodes('target', true));
          }

          function mouseout(d) {
            svg.selectAll('path.link.source-' + d.key)
              .classed('source', false)
              .each(updateNodes('target', false));

            svg.selectAll('path.link.target-' + d.key)
              .classed('target', false)
              .each(updateNodes('source', false));
          }

          function updateNodes(name, value) {
            return function (d) {
              if (value) this.parentNode.appendChild(this);
              svg.select('#node-' + d[name].key).classed(name, value);
            };
          }
        }

        init();

        $scope.$watch('diagramEntities', init);
        $scope.$watch('diagramLinks', init);
      }
    }
  }]);
