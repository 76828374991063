angular.module('barometerApp.layout')
  .controller("SectionCtrl", ['$scope', '$rootScope', 'layoutService',
    function ($scope, $rootScope, layoutService) {
      $scope.sectionCtrlModel = {};
      $scope.sectionCtrlModel.waypointCallback = function (direction, test) {
        if (!prototype.config.enableWaypoint && $scope.section.bn != layoutService.getSectionBnForWaypoint()) return;
      };
      $scope.sectionCtrlModel.waypointOptions = {
        offset: 300
      };
    }])
  .controller('SectionHeaderCtrl', ['$scope', '$rootScope',
    function ($scope, $rootScope) {
      //Scott: instead of sectionHeaderModel, I went with sectionHeaderModel
      $scope.sectionHeaderModel = {};

      // TODO: remove class management - not needed once bootstrap/select2 dropdowns take over
      $scope.sectionHeaderModel.editorPopupClass = "close";
      $scope.$on('closeAllOpenEditPopups', function (event) {
        $scope.sectionHeaderModel.editorPopupClass = "close";
      });
      $scope.deleteSection = function () {
        // set content blocks in section to delete
        $.each($scope.workingModel.contentBlocks, function () {
          if (this.sectionBn == $scope.section.bn) {
            this.bn = "DELETE";
          }
        });
        if ($scope.section.bn.indexOf('NEW_') == -1) {
          $scope.section.bn = 'DELETE';
        } else {
          // remove completely from json if NEW
          $.each($scope.workingModel.sections, function () {
            if (this.bn == $scope.section.bn) {
              $scope.workingModel.sections.splice($.inArray(this, $scope.workingModel.sections), 1);
            }
          });
        }
      };

      // setup draggable sections
      $('.sl-section-list').sortable({
          cancel: '.sl-view *',   // only works in edit mode
          connectWith: '.sl-edit .sl-section-list',
          placeholder: 'drop-highlight',
          handle: ".sl-section-header",
          scrollSensitivity: 50,
          forcePlaceholderSize: true,
          forceHelperSize: true,
          revert: 300, //duration in millis of re-order animation
          start: function (event, ui) {
            ui.placeholder.height(ui.item.height());
            ui.item.data('start', ui.item.index());
          },
          stop: function (event, ui) {
            $rootScope.$broadcast('changesMade');
            $rootScope.$apply();
          }
        })
        // prevent disabling of inputs
        .find('input').bind('click.sortable mousedown.sortable', function (e) {
        e.stopImmediatePropagation();
      });

      // add hover events to sections
      $('.sl-section-header')
        .on('mouseenter', function () {
          $(this).closest('.sl-section').addClass('hover');
        })
        .on('mouseleave', function () {
          $(this).closest('.sl-section').removeClass('hover');
        });
    }])
  .controller('BasicLayoutCtrl', ['$scope', 'urlService', 'layoutService', 'pageService', 'tenantService',
    function ($scope, urlService, layoutService, pageService, tenantService) {
      $scope.layoutModel = {
        layout: "",
      };
      $scope.layoutModel.layout = _.clone(PageNameToLayout[urlService.getPageName()]);
      $scope.layoutModel.layout = {
        ...$scope.layoutModel.layout,
        sections: $scope.layoutModel.layout.sections.filter(s => s.category !== 'related' || !EntityTypes[s.bn] || tenantService.isAnAuthorizedEntityTypeCode(s.bn) || tenantService.isAnAuthorizedEntityBn(s.bn))
      };

      layoutService.setCurrentLayout($scope.layoutModel.layout);
    }]);
