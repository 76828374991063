angular
  .module('barometerApp.tasks')
  .factory('tasksDataService', tasksDataService);

tasksDataService.$inject = ['$http', 'errorHandlingService', '$q', '$timeout', 'alertService', 'urlService',
  'utilService', 'entityService', 'optionListService'];

/**
 * @author Tim Cramer
 */
function tasksDataService($http, errorHandlingService, $q, $timeout, alertService, urlService, utilService,
                          entityService, optionListService) {

  var GRAPH_URL = urlService.getGraphApiUrl();

  function getAuthToken() {
    var authToken = utilService.readCookie('baroJWTToken');
    if (!authToken) {
      alertService.addErrorAlert('No authentication credential for Task service.');
    }
    return authToken;
  }

  function getRequestersAndAssigneesForTaskBns(taskBns) {
    return $http({
      method: 'POST',
      url: GRAPH_URL + 'report/relatedEntities',
      headers: {
        'Authorization': 'Bearer ' + getAuthToken(),
        'bit-tenant-bn': utilService.getTenantBn(),
        'Content-Type': 'application/json'
      },
      data: {
        "perspectiveBns": taskBns,
        "relatedTypes": ["PER"],
        "relatedProperties": ["bn", "name"],
        "filterBy": {
          "relationshipTypes": ["ASSIGNED_BY", "ASSIGNED_TO"]
        },
        "expandRelationships": true,
        "firstRecord": 1,
        "numberToReturn": -1
      }
    })
  }

  return {

    createTask: function (taskCreateRequest) {
      var jsonReq = angular.toJson(taskCreateRequest);
      var request, createEntityURL, contentType;
      request = jsonReq;
      createEntityURL = '/b/api/task/create';
      contentType = 'application/json;charset=UTF-8';
      return $http({
        method: 'POST',
        url: createEntityURL,
        data: request,
        headers: {'Content-Type': contentType}
      }).success(function (data, status, headers, config) {
        //alertService.addHttpSuccessAlert(data, status, headers, config);
        alertService.addSuccessAlert('Task has been created.');
      }).error(function (data, status, headers, config) {
        alertService.addHttpErrorAlert(data, status, headers, config);
        var msgString = 'Unable to create task.';
        console.log('error in tasksDataService.createTask for taskCreateRequest: ' + taskCreateRequest);
        if (status === 409 && data.errorMessages) {
          msgString += ': ' + data.errorMessages;
        }
        alertService.addErrorAlert(msgString);
      });
    },
    createTasksByRole: function (roleBn, taskCreateRequest) {
      var jsonReq = angular.toJson(taskCreateRequest);
      var request, createEntityURL, contentType;
      request = jsonReq;
      createEntityURL = '/b/api/task/createTasks/' + roleBn;
      contentType = 'application/json;charset=UTF-8';
      return $http({
        method: 'POST',
        url: createEntityURL,
        data: request,
        headers: {'Content-Type': contentType}
      }).success(function (data, status, headers, config) {
        //alertService.addHttpSuccessAlert(data, status, headers, config);
        alertService.addSuccessAlert('Task has been created.');
      }).error(function (data, status, headers, config) {
        alertService.addHttpErrorAlert(data, status, headers, config);
        var msgString = 'Unable to create task by role.';
        console.log('error in tasksDataService.createTasksByRole for roleBn ' + roleBn + ' and taskCreateRequest: ' + taskCreateRequest);
        if (status === 400 && data.errorMessages) {
          msgString += ': ' + data.errorMessages;
        }
        alertService.addErrorAlert(msgString);
      });
    },
    // PEH, 2/1/19 - doesn't look like tasksDataService.getTaskDetails gets used anywhere
    // taskService.getTaskDetails gets called instead...
    getTaskDetails: function (taskBn) {
      var getTaskURL = '/b/api/entities/' + taskBn;
      return $http({
        method: 'GET',
        url: getTaskURL,
        headers: {'Content-Type': contentType}
      }).success(function (data, status, headers, config) {
        //alertService.addHttpSuccessAlert(data, status, headers, config);
      }).error(function (data, status, headers, config) {
        alertService.addHttpErrorAlert(data, status, headers, config);
        var msgString = 'Unable to get task details.';
        console.log('error in tasksDataService.getTaskDetails for taskBn: ' + taskBn);
        if (status === 409 && data.errorMessages) {
          msgString += ': ' + data.errorMessages;
        }
        alertService.addErrorAlert(msgString);
      });
    },

    buildRelatedEntitiesRequestBody: function (perspectiveBn, relatedEntityType, relationshipType, first, count) {
      var postBody = {};

      postBody.perspectiveBns = [perspectiveBn];
      postBody.relatedTypes = null;
      if (relatedEntityType != null && typeof relatedEntityType != 'undefined') {
        postBody.relatedTypes = [relatedEntityType];
      }
      if (relatedEntityType != 'PER') {
        postBody.relatedProperties = ['bn', 'name', 'description', 'createDate', 'completionNotes'];
        postBody.sortBy = {"fieldName": "name", "sortOrder": "ASC"};
      } else {
        postBody.relatedProperties = ['bn', 'fullName'];
        postBody.sortBy = {"fieldName": "fullName", "sortOrder": "ASC"};
      }

      postBody.expandRelationships = false;
      postBody.filterBy = {relationshipTypes: [relationshipType]};
      postBody.firstRecord = first;
      postBody.numberToReturn = count;

      return postBody;
    },

    // making this a separate request due to promise
    buildRelatedEntitiesRequestBodyForTasks: function (perspectiveBn, relatedEntityType, relationshipType, taskStatus, first, count, sortProperty, sortDirection) {
      var deferred = $q.defer();
      var postBody = {};

      var optionListTypes = EntityOptionListTypes['TSK'];
      optionListService.getOptionList(optionListTypes).then(function (data) {
        // the only one returned should be called
        var dataList = data.data[0];

        postBody.perspectiveBns = [perspectiveBn];
        postBody.relatedTypes = null;
        if (relatedEntityType != null && typeof relatedEntityType != 'undefined') {
          postBody.relatedTypes = [relatedEntityType];
        }
        if (relatedEntityType == 'PER') {
          postBody.relatedProperties = ['bn', 'fullName'];
        } else {
          postBody.relatedProperties = ['bn', 'name', 'description', 'createDate', 'completionNotes', 'taskCompletionDate', 'lastUpdateDate', dataList.bn];
          postBody.taskStatusDataListBn = dataList.bn;
        }

        postBody.sortBy = {"fieldName": sortProperty, "sortOrder": sortDirection};
        postBody.filterBy = {relationshipTypes: [relationshipType]};

        if (taskStatus != "ANY") {
          postBody.filterBy.fieldValues = [{"fieldBn": dataList.bn, "value": taskStatus}];
        }

        postBody.expandedRelationships = false;
        postBody.firstRecord = first;
        postBody.numberToReturn = count;
        deferred.resolve(postBody)
      });

      return deferred.promise;
    },

    getTaskRequesterByTaskBn: function (taskBn, relationshipType, first, count) {
      var deferred = $q.defer();
      var token = getAuthToken();

      var postBody = this.buildRelatedEntitiesRequestBody(taskBn, 'PER', relationshipType, first, count);

      //
      if (!token) {
        return deferred.reject('Authorization token missing');
      } else {
        $http({
          method: 'POST',
          url: GRAPH_URL + 'report/relatedEntities',
          headers: {
            'Authorization': 'Bearer ' + token,
            'bit-tenant-bn': utilService.getTenantBn(),
            'Content-Type': 'application/json'
          },
          data: postBody
        }).success(function (data, status, headers, config) {
          //alertService.addHttpSuccessAlert(data, status, headers, config);
          deferred.resolve(data);
        }).error(function (data, status, headers, config) {
          alertService.addHttpErrorAlert(data, status, headers, config);
          var msgString = 'Unable to get tasks.';
          if (status === 409 && data.errorMessages) {
            msgString += ': ' + data.errorMessages;
          }
          alertService.addErrorAlert(msgString);
          deferred.reject(data);
        })
      }
      return deferred.promise;
    },

    getTaskResourcesByTaskBn: function (taskBn, relationshipType, first, count) {
      var deferred = $q.defer();
      var token = getAuthToken();

      var postBody = this.buildRelatedEntitiesRequestBody(taskBn, null, relationshipType, first, count);

      //
      if (!token) {
        return deferred.reject('Authorization token missing');
      } else {
        $http({
          method: 'POST',
          url: GRAPH_URL + 'report/relatedEntities',
          headers: {
            'Authorization': 'Bearer ' + token,
            'bit-tenant-bn': utilService.getTenantBn(),
            'Content-Type': 'application/json'
          },
          data: postBody
        }).success(function (data, status, headers, config) {
          //alertService.addHttpSuccessAlert(data, status, headers, config);
          deferred.resolve(data);
        }).error(function (data, status, headers, config) {
          alertService.addHttpErrorAlert(data, status, headers, config);
          var msgString = 'Unable to get tasks.';
          if (status === 409 && data.errorMessages) {
            msgString += ': ' + data.errorMessages;
          }
          alertService.addErrorAlert(msgString);
          deferred.reject(data);
        })
      }
      return deferred.promise;
    },

    getTasksByPersonBnAndRelationshipType: function (personBn, relationshipType, taskStatus, first, count, sortProperty, sortDirection) {
      var deferred = $q.defer();
      var token = getAuthToken();
      var relatedEntityType = "TSK";

      this.buildRelatedEntitiesRequestBodyForTasks(personBn, relatedEntityType, relationshipType, taskStatus, first, count, sortProperty, sortDirection).then(function (postBody) {

        if (!token) {
          return deferred.reject('Authorization token missing');
        } else {
          var taskStatusDataListBn = postBody.taskStatusDataListBn;
          $http({
            method: 'POST',
            url: GRAPH_URL + 'report/relatedEntities',
            headers: {
              'Authorization': 'Bearer ' + token,
              'bit-tenant-bn': utilService.getTenantBn(),
              'Content-Type': 'application/json'
            },
            data: postBody
          }).success(function (taskData, status, headers, config) {
            //alertService.addHttpSuccessAlert(taskData, status, headers, config);
            var taskBns = _.map(taskData.rows, 'bn');
            if (taskBns.length === 0) {
              deferred.resolve(taskData);
            }

            // Make an additional call to get assignee + requester info
            else {
              getRequestersAndAssigneesForTaskBns(taskBns).then(function (peopleData) {
                var people = peopleData.data.rows;
                taskData.rows.forEach(function (task) {
                  task.assignee = _.find(people, function (person) {
                    return person.perspectiveBn === task.bn && person.relationshipType === 'ASSIGNED_TO';
                  });
                  task.requester = _.find(people, function (person) {
                    return person.perspectiveBn === task.bn && person.relationshipType === 'ASSIGNED_BY';
                  });
                  // using the incoming taskStatusDataListBn from the postBody to set isCompleted
                  // if the status is null for whatever reason, then we need to look at the taskCompletionDate
                  switch (task[taskStatusDataListBn]) {
                    case "Open":
                      task.isCompleted = false;
                      break;
                    case "Completed":
                      task.isCompleted = true;
                      break;
                    default:
                      task.isCompleted = task.taskCompletionDate != null;
                      break;
                  }
                });
                deferred.resolve(taskData);
              }, function () {
                deferred.reject('Error getting requesters and assigners for tasks', taskBns);
              });
            }
          }).error(function (data, status, headers, config) {
            alertService.addHttpErrorAlert(data, status, headers, config);
            var msgString = 'Unable to get tasks.';
            if (status === 409 && data.errorMessages) {
              msgString += ': ' + data.errorMessages;
            }
            alertService.addErrorAlert(msgString);
            deferred.reject(data);
          });
        }
      });

      return deferred.promise;
    },
    addPersonAssoc: function (taskBn, relationshipType, entityBn) {
      var contentType;
      var addAssocUrl = '/b/api/task/' + taskBn + '/' + relationshipType + '/' + entityBn + '?isTaskPerson=true';
      contentType = 'application/json;charset=UTF-8';
      return $http({
        method: 'PUT',
        url: addAssocUrl,
        headers: {'Content-Type': contentType}
      }).success(function (data, status, headers, config) {
        //alertService.addHttpSuccessAlert(data, status, headers, config);
      }).error(function (data, status, headers, config) {
        alertService.addHttpErrorAlert(data, status, headers, config);
        var msgString = 'Unable to add requester / assignee to task.';
        console.log('error in tasksDataService.addPersonAssoc for taskBn: ' + taskBn + ' and personBn: ' + entityBn + ' with relationshipType:' + relationshipType);
        if (status === 409 && data.errorMessages) {
          msgString += ': ' + data.errorMessages;
        }
        alertService.addErrorAlert(msgString);
      });
    },
    addRemediatedEntityAssoc: function (taskBn, relationshipType, entityBn) {
      var contentType;
      var addAssocUrl = '/b/api/task/' + taskBn + '/' + relationshipType + '/' + entityBn;
      contentType = 'application/json;charset=UTF-8';
      return $http({
        method: 'PUT',
        url: addAssocUrl,
        headers: {'Content-Type': contentType}
      }).success(function (data, status, headers, config) {
        //alertService.addHttpSuccessAlert(data, status, headers, config);
      }).error(function (data, status, headers, config) {
        alertService.addHttpErrorAlert(data, status, headers, config);
        var msgString = 'Unable to create task association.';
        console.log('error in tasksDataService.addRemediatedEntityAssoc for taskBn: ' + taskBn + ' and entityBn: ' + entityBn + ' with relationshipType:' + relationshipType);
        if (status === 409 && data.errorMessages) {
          msgString += ': ' + data.errorMessages;
        }
        alertService.addErrorAlert(msgString);
      });
    },

    removeRemediatedEntityAssoc: function (taskBn, relationshipType, entityBn) {
      var contentType;
      var removeAssocUrl = '/b/api/task/' + taskBn + '/' + relationshipType + '/' + entityBn;
      contentType = 'application/json;charset=UTF-8';
      return $http({
        method: 'DELETE',
        url: removeAssocUrl,
        headers: {'Content-Type': contentType}
      }).success(function (data, status, headers, config) {
        //alertService.addHttpSuccessAlert(data, status, headers, config);
      }).error(function (data, status, headers, config) {
        alertService.addHttpErrorAlert(data, status, headers, config);
        var msgString = 'Unable to remove task association.';
        console.log('error in tasksDataService.removeRemediatedEntityAssoc for taskBn: ' + taskBn + ' and entityBn: ' + entityBn + ' with relationshipType:' + relationshipType);
        if (status === 409 && data.errorMessages) {
          msgString += ': ' + data.errorMessages;
        }
        alertService.addErrorAlert(msgString);
      });
    },

    manageRemediatedEntityAssocs: function (taskBn, relationshipType, addedEntityBns, removedEntityBns) {
      var request = {};
      request.taskBn = taskBn;
      request.relationshipType = relationshipType;
      request.addedEntities = addedEntityBns;
      request.removedEntities = removedEntityBns;

      var manageEntityAssocsRequest = angular.toJson(request);

      var manageEntityURL = '/b/api/task/manageEntityAssocs';
      var contentType = 'application/json;charset=UTF-8';
      return $http({
        method: 'POST',
        url: manageEntityURL,
        data: manageEntityAssocsRequest,
        headers: {'Content-Type': contentType}
      }).success(function (data, status, headers, config) {
        //alertService.addHttpSuccessAlert(data, status, headers, config);
      }).error(function (data, status, headers, config) {
        alertService.addHttpErrorAlert(data, status, headers, config);
        var msgString = 'Unable to remove task association.';
        console.log('error in tasksDataService.removeRemediatedEntityAssoc for taskBn: ' + taskBn + ' and addedEntities: ' + addedEntityBns + ' and removedEntities: ' + removedEntityBns + ' with relationshipType:' + relationshipType);
        if (status === 409 && data.errorMessages) {
          msgString += ': ' + data.errorMessages;
        }
        alertService.addErrorAlert(msgString);
      });
    }
  }
}