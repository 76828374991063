angular.module('barometerApp.bubbleChart')
  .directive('bubbleChart', [function () {
    return {
      templateUrl: '/b/js/src/bit.ng/bubble-chart/partials/bubble-chart.html',
      scope: {
        previewMode: '@',
        worksheetBn: '='
      }
    }
  }])

  //        Takes a composite chartModel Object in this format:
  //        {
  //            xAxis: {
  //                name: 'X-Axis DataSource Name',
  //                max: 10
  //            },
  //            yAxis: {
  //                name: 'Y-axis DataSource Name',
  //                max: 55
  //            },
  //            zAxis: {
  //                name: 'Radius DataSource Name'
  //            },
  //            series:  [
  //               {x:97, y:36, z:79, entity: { bn:'ZZ1800007PVO', name: 'System ABC'}},
  //               {x:94, y:74, z:60, entity: { bn:'ZZ1800007PV1', name: 'System DEF'}},
  //               {x:68, y:76, z:58, entity: { bn:'ZZ1800007PV2', name: 'System GHI'}},
  //               {x:63, y:87, z:56, entity: { bn:'ZZ1800007PV3', name: 'System JKL'}},
  //               {x:74, y:99, z:42, entity: { bn:'ZZ1800007PV4', name: 'System MNO'}}
  //            ]
  //        }
  //
  //    If any part of the chartData object changes, the graph redraws.
  //
  .directive('bubbleChartViz', ['$timeout', 'utilService', '$rootScope',
    function ($timeout, utilService, $rootScope) {

      return {
        scope: {
          chartModel: '='
        },
        link: function ($scope, $element) {

          // TODO: Tooltip does not hide and hover effect persists after mouse exits a point
          var chart;
          var _nearbyPoints = [];

          function getDistance(aX, aY, bX, bY){
            return Math.sqrt(Math.pow((aX-bX), 2) + Math.pow((aY-bY), 2));
          }

          function pointSelected() {
            var point = this;
            $rootScope.$broadcast('bubbleChartPointSelected', point);
          }

          function pointUnselected() {
            var point = this;
            $rootScope.$broadcast('bubbleChartPointUnselected', point);
          }

          function clearSelected(){
            _.each(chart.getSelectedPoints(), function (point) {
              point.select();
            });
          }

          // If point in question is clumped with nearby points, fan them out so they can be distinguished
          function pointMouseOver() {
            var point = this;
            var slice;
            var pointBounds;
            var nearbyPointThreshold;
            var nearbyPointPushDistance;

            // Start a new nearbyPoint check...
            if (_nearbyPoints.length === 0){
              pointBounds = point.graphic.element.getBoundingClientRect();
              nearbyPointThreshold = pointBounds.right - pointBounds.left;
              nearbyPointPushDistance = nearbyPointThreshold;

              _.each(this.series.data, function(otherPoint){
                if (otherPoint !== point && getDistance(point.plotX, point.plotY, otherPoint.plotX, otherPoint.plotY) < nearbyPointThreshold){
                  _nearbyPoints.push(otherPoint);

                  // If any nearby points are larger than the moused-over point, use their radius as the push distance.
                  var otherPointBounds = otherPoint.graphic.element.getBoundingClientRect();
                  var otherPointDiameter = otherPointBounds.right - otherPointBounds.left;
                  if (otherPointDiameter > nearbyPointPushDistance){
                    nearbyPointPushDistance = otherPointDiameter;
                  }
                }
              });

              if (_nearbyPoints.length){

                // Fan points out in all directions
                slice = 2 * Math.PI / _nearbyPoints.length;
                _.each(_nearbyPoints, function(nearbyPoint, i){
                  var angle = slice * i + (Math.PI/2);
                  var xOffset = Math.sin(angle) * nearbyPointPushDistance + 'px';
                  var yOffset = Math.cos(angle) * nearbyPointPushDistance + 'px';
                  var pointElem = $(nearbyPoint.graphic.element);

                  pointElem.css('transition', 'transform .2s');
                  pointElem.css('transform', 'translate('+ xOffset + ', ' + yOffset + ')');
                });

                // Check to see if the mouse stays in this area
                // If it moves away, put the points back
                $(window).on('mousemove', function(event){
                  var locusRect = point.graphic.element.getBoundingClientRect();
                  var locusCenterX = (locusRect.left + locusRect.right) / 2;
                  var locusCenterY = (locusRect.top + locusRect.bottom) / 2;
                  var distanceFromLocus = getDistance(event.pageX, event.pageY, locusCenterX, locusCenterY);

                  // Use 2x push distance as the threshold for being "away"
                  if (distanceFromLocus > nearbyPointPushDistance * 2){
                    _.each(_nearbyPoints, function(nearbyPoint){
                      $(nearbyPoint.graphic.element).css('transform', '');
                    });
                    _nearbyPoints = [];
                    $(window).off('mousemove');
                  }
                });
              }
            }
          }

          // Note: HighCharts adds pertinent context to _this_
          function getTooltipHtml() {
            var entityRow = '<div class="entity-label"><span class="icon-' + EntityBnCodeToIconClass[utilService.getBnCode(this.point.entity.bn)] + '"></span> ' + this.point.entity.name + '</div>';
            var xRow = getTooltipRow($scope.chartModel.xAxis.name, this.point.xDisplay, $scope.chartModel.xAxis.maxScore);
            var yRow = getTooltipRow($scope.chartModel.yAxis.name, this.point.yDisplay, $scope.chartModel.yAxis.maxScore);
            var zRow = (this.point.hasOwnProperty('z') && $scope.chartModel.zAxis) ? getTooltipRow($scope.chartModel.zAxis.name, this.point.zDisplay, $scope.chartModel.zAxis.maxScore) : '';
            return entityRow + xRow + yRow + zRow;
          }

          function getTooltipRow(name, value, maxScore) {
            var str = '<span><em>' + name + '</em> <strong>' + value + ' </strong>';
            if (maxScore != null) {
              str += ' / ' + maxScore;
            }
            str += '</span>';
            return str;
          }

          // Size of square that fits within container bounds
          function getSize() {
            var ctr = $element.closest('.worksheet-content, .viz');
            var size = Math.min(ctr.height(), ctr.width()) * .8;
            size = Math.max(size, 600);
            return size;
          }

          function getAxisConfig(axisData) {
            var axisConfig = {
              title: {enabled: true, text: axisData.name},
              min: axisData.rangeMin,
              max: axisData.rangeMax
            };
            return axisConfig;
          }

          function renderChart() {
            if ($scope.chartModel) {

              var size = getSize();

              chart = new Highcharts.Chart({
                chart: {
                  type: 'bubble',
                  renderTo: $element.get(0),
                  height: size,
                  width: size,
                  spacing: [80, 80, 80, 80],   // Add spacing for bubble overflow
                  plotBackgroundColor: null,
                  plotBorderWidth: null,
                  plotShadow: false
                },
                title: {
                  text: null
                },
                legend: {
                  enabled: false
                },
                plotOptions: {
                  bubble: {
                    allowPointSelect: true,
                    color: '#20498D',
                    maxSize: 60,
                    minSize: 20,
                    point: {
                      events: {
                        select: pointSelected,
                        unselect: pointUnselected,
                        mouseOver: pointMouseOver
                      }
                    }
                  }
                },
                xAxis: getAxisConfig($scope.chartModel.xAxis),
                yAxis: getAxisConfig($scope.chartModel.yAxis),
                credits: {
                  enabled: false
                },
                tooltip: {
                  // Let CSS determine styling as much as possible
                  backgroundColor: null,
                  style: {
                    color: null,
                    fontSize: null,
                    fontFamily: null
                  },
                  borderWidth: 0,
                  shadow: false,
                  useHTML: true,
                  hideDelay: 500,
                  followPointer: true,    // Works better with "nearby point" fanning
                  formatter: getTooltipHtml
                },
                series: [{
                  // Hover halo fights with "nearby point" fanning
                  states: { hover: {halo: false}},
                  data: $scope.chartModel.series
                }]
              });
            }
          }

          $scope.$watch('chartModel', function (newVal, oldVal) {
            if (oldVal !== newVal) {
              renderChart();
            }
          });

          $(window).on('resize', function () {
            if (chart) {
              var size = getSize();
              if (typeof doAnimation !== 'undefined') doAnimation = true;
              chart.setSize(size, size, true);
            }
          });

          $scope.$on('bubbleChartClearSelected', clearSelected);


          // // Triggered when indexing complete after changes
          // $scope.$on('loadSection', function (event, sectionBn) {
          //   console.log('loadSection');
          //   if(sectionBn === 'WORKSHEET'){
          //     renderChart();
          //   }
          // });

          renderChart();
        }
      }
    }]);
