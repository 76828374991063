angular.module('barometerApp.content')
  .controller('PieChartController', ['$rootScope', '$scope', '$timeout', 'layoutService', 'searchService', 'urlService', 'filterService',
    function ($rootScope, $scope, $timeout, layoutService, searchService, urlService, filterService) {

      $scope.pieChartModel = {
        seriesData: [],
        containerId: "",
        loaded: false,
        filterModel: {}
      };

      $scope.pieChartModel.containerId = Math.random() + $scope.contentBlock.configuration.bn;

      $scope.getContentBlockClass = function (contentBlock) {
        return layoutService.getContentBlockClass(contentBlock);
      };
      $scope.updatePieChart = function () {
        $scope.pieChartModel.loading = "true";
        var configuration = $scope.contentBlock.configuration;
        var currentFilter = filterService.convertFilterObjectToFilterArray($scope.pieChartModel.filterModel);
        var qualifier = $scope.pieChartModel.filterModel.qualifier && $scope.pieChartModel.filterModel.qualifier.length > 0 ? $scope.pieChartModel.filterModel.qualifier : null;
        var dataSourceBn = (configuration.dataSource == null) ? null : configuration.dataSource.bn;
        var entityType = urlService.getEntityTypeCodeOfCurrentPage() === "LYT" ? configuration.entityType : urlService.getEntityTypeCodeOfCurrentPage();
        var targetType = $scope.assocCode ? $scope.assocCode : entityType;
        var promise = searchService.searchByQueryAndField(
          urlService.getQueryKey(), urlService.getEntityBn(), entityType, targetType,
          configuration.relationshipTypes, currentFilter, configuration.fieldBn, configuration.sortDescending, 0, configuration.limit, true, qualifier,
          dataSourceBn);

        promise.then(function (data) {
          var facets = data.data.rows;
          if (facets.length == 0) {
            $scope.pieChartModel.noData = true;
          } else {
            $scope.pieChartModel.noData = false;
          }

          $scope.pieChartModel.loading = "false";
          $scope.pieChartModel.data = data.data.rows;

          // trigger window resize so charts auto-fit containers.
          $timeout(function () {
            $(window).resize();
          }, 100);
        });
      };
      // This event handler takes care of loading elements in the right sidebar when the filters are changed.
      $scope.$on("filterChanged", function (event, data) {
        if (data.tableId === $scope.contentBlock.sectionBn || data.tableId == $scope.contentBlock.configuration.filterIdentifier) {
          $scope.pieChartModel.filterModel = data.filter;
          $scope.updatePieChart();
          $scope.pieChartModel.loaded = true;
        }
      });
      // This event handler takes care of loading elements in the right sidebar when the filters are changed.
      $scope.$on("InitialFilter", function (evt, data) {
        if ((data.tableId != null) &&
          (data.tableId === $scope.contentBlock.sectionBn
            || data.tableId == $scope.contentBlock.configuration.filterIdentifier
            || data.tableId === $scope.assocCode
            || data.tableId === 'BROWSE')) {
          $scope.pieChartModel.filterModel = data.filter;
          $scope.updatePieChart();
          $scope.pieChartModel.loaded = true;
        }
      });
      $scope.$on('refreshCounts', function (event, sectionBn) {
        if ((sectionBn != null) && (sectionBn === $scope.contentBlock.sectionBn || sectionBn == $scope.contentBlock.configuration.filterIdentifier)) {
          $scope.updatePieChart();
          $scope.pieChartModel.loaded = true;
        }
      });
      // Handles loading for elements in the middle summary view area, both when summary view is selected and the filters are changed.
      if (!$scope.waitToLoad || $scope.waitToLoad == 'false') {
        $scope.pieChartModel.filterModel = $scope.filterModel.filter;
        $scope.updatePieChart();
        $scope.pieChartModel.loaded = true;
      }
    }])
  .controller('SummaryViewController', ['$scope', 'urlService', 'layoutService', 'searchService', 'filterService',
    function ($scope, urlService, layoutService, searchService, filterService) {
      $scope.summaryViewModel = {
        items: [],
        loaded: false,
        filterModel: {}
      };
      $scope.summaryViewModel.pagination = {
        noOfPages: 0,
        currentPage: 1,
        numRecords: 0,
        pageSize: $scope.contentBlock.configuration.limit,
        rowCount: 0,
        maxSize: 100,
        start: 1
      };
      $scope.getContentBlockClass = function (contentBlock) {
        return layoutService.getContentBlockClass(contentBlock);
      };
      $scope.updateData = function () {
        var first = ($scope.summaryViewModel.pagination.currentPage - 1) * $scope.summaryViewModel.pagination.pageSize;
        $scope.summaryViewModel.pagination.start = first + 1;
        $scope.summaryViewModel.loading = "true";
        var configuration = $scope.contentBlock.configuration;
        var currentFilter = filterService.convertFilterObjectToFilterArray($scope.summaryViewModel.filterModel);
        var qualifier = $scope.summaryViewModel.filterModel.qualifier && $scope.summaryViewModel.filterModel.qualifier.length > 0 ? $scope.summaryViewModel.filterModel.qualifier : null;
        var dataSourceBn = (configuration.dataSource == null) ? null : configuration.dataSource.bn;
        var entityType = urlService.getEntityTypeCodeOfCurrentPage() === "LYT" ? configuration.entityType : urlService.getEntityTypeCodeOfCurrentPage();
        var assocCode = configuration.entityType ? configuration.entityType : $scope.assocCode;
        var promise = searchService.searchByQueryAndField(
          urlService.getQueryKey(), urlService.getEntityBn(), entityType, assocCode,
          configuration.relationshipTypes, currentFilter, configuration.fieldBn, configuration.sortDescending,
          first, configuration.limit, false, qualifier, dataSourceBn);
        promise.then(function (data) {
          $scope.summaryViewModel.items = data.data.rows;
          $scope.summaryViewModel.noData = $scope.summaryViewModel.items.length == 0;
          $scope.summaryViewModel.loading = "false";
          // total num of records
          $scope.summaryViewModel.pagination.rowCount = data.data.totalRowCount;
          // num of records on current page: whatever is less, page size or total count
          $scope.summaryViewModel.pagination.numRecords = Math.min(data.data.totalRowCount, $scope.summaryViewModel.pagination.pageSize);
          $scope.summaryViewModel.pagination.noOfPages = Math.ceil(data.data.totalRowCount / $scope.summaryViewModel.pagination.pageSize);
        });
      };
      // This event handler takes care of loading elements in the right sidebar when the filters are changed.
      $scope.$on("filterChanged", function (event, data) {
        if (data.tableId === $scope.contentBlock.sectionBn || data.tableId == $scope.contentBlock.configuration.filterIdentifier) {
          $scope.summaryViewModel.filterModel = data.filter;
          $scope.updateData();
          $scope.summaryViewModel.loaded = true;
        }
      });
      // This event handler takes care of loading elements in the right sidebar when the filters are changed.
      $scope.$on("InitialFilter", function (evt, data) {
        if ((data.tableId != null) &&
          (data.tableId === $scope.contentBlock.sectionBn
            || data.tableId === $scope.contentBlock.configuration.filterIdentifier
            || data.tableId === $scope.assocCode
            || data.tableId === 'BROWSE')) {
          $scope.summaryViewModel.filterModel = data.filter;
          $scope.updateData();
          $scope.summaryViewModel.loaded = true;
        }
      });
      $scope.$watch('summaryViewModel.pagination', function (newValue, oldValue) {
        if (typeof newValue === "undefined" || angular.equals(newValue == oldValue)) return;
        $scope.updateData();
      }, true);

      // Handles loading for elements in the middle summary view area, both when summary view is selected and the filters are changed.
      if (!$scope.waitToLoad || $scope.waitToLoad == 'false') {
        $scope.summaryViewModel.filterModel = $scope.filterModel.filter;
        $scope.updateData();
        $scope.summaryViewModel.loaded = true;
      }
    }])
  .controller('CountController', ['$scope', 'urlService', 'layoutService', 'searchService', 'filterService', 'fieldService', 'utilService',
    function ($scope, urlService, layoutService, searchService, filterService, fieldService, utilService) {
      $scope.countModel = {
        loaded: false,
        filterModel: {}
      };
      $scope.countModel.loadUsingDefaultTableFilter = $scope.contentBlock.configuration.loadUsingDefaultTableFilter;
      $scope.getContentBlockClass = function (contentBlock) {
        return layoutService.getContentBlockClass(contentBlock);
      };
      $scope.updateCount = function () {
        $scope.countModel.loading = "true";
        var currentFilter = filterService.convertFilterObjectToFilterArray($scope.countModel.filterModel);
        var qualifier = $scope.countModel.filterModel.qualifier && $scope.countModel.filterModel.qualifier.length > 0 ? $scope.countModel.filterModel.qualifier : null;
        var configuration = $scope.contentBlock.configuration;

        var getFieldPromise = Promise.resolve({data: {}});
        if (configuration.contentBlockType === "METRIC" && EntityTypes.FLD.bnCode === utilService.getBnCode(configuration.fieldBn)) {
          getFieldPromise = fieldService.getField(configuration.fieldBn)
        }

        var dataSourceBn = (configuration.dataSource == null) ? null : configuration.dataSource.bn;
        var entityType = urlService.getEntityTypeCodeOfCurrentPage() === "LYT" ? configuration.entityType : urlService.getEntityTypeCodeOfCurrentPage();
        var assocCode = configuration.entityType ? configuration.entityType : $scope.assocCode;
        var countDataPromise = searchService.getCountData(urlService.getQueryKey(), urlService.getEntityBn(), entityType, assocCode,
          configuration.relationshipTypes, currentFilter, configuration.fieldBn, configuration.measurement, qualifier, dataSourceBn);

        Promise.all([getFieldPromise, countDataPromise]).then(function(data) {
          const {dataType, displayType, numberUom} = data[0].data;
          const count = data[1].data;
          const countDisplay = utilService.getMetricNumberFormat(count, dataType, displayType, numberUom);

          // AngularJS gets confused sometimes when changing scope from within an async callback
          $scope.$apply(() => {
            $scope.countModel.countVal = countDisplay;
            $scope.countModel.loading = "false";
          });
        });
      };
      // This event handler takes care of loading elements in the right sidebar when the filters are changed.
      $scope.$on("filterChanged", function (event, data) {
        if (data.tableId === $scope.contentBlock.sectionBn || data.tableId == $scope.contentBlock.configuration.filterIdentifier) {
          $scope.countModel.filterModel = data.filter;
          $scope.updateCount();
          $scope.countModel.loaded = true;
        }
      });
      // This event handler takes care of loading elements in the right sidebar when the filters are changed.
      $scope.$on("InitialFilter", function (evt, data) {
        if ((data.tableId != null) &&
          (data.tableId === $scope.contentBlock.sectionBn
            || data.tableId === $scope.contentBlock.configuration.filterIdentifier
            || data.tableId === $scope.assocCode
            || data.tableId === 'BROWSE')) {
          $scope.countModel.filterModel = data.filter;
          $scope.updateCount();
          $scope.countModel.loaded = true;
        }
      });
      $scope.$on('refreshCounts', function (event, sectionBn) {
        if ((sectionBn != null) && (sectionBn === $scope.contentBlock.sectionBn || sectionBn == $scope.contentBlock.configuration.filterIdentifier)) {
          $scope.updateCount();
          $scope.countModel.loaded = true;
        }
      });
      // Handles loading for elements in the middle summary view area, both when summary view is selected and the filters are changed.
      if (!$scope.waitToLoad || $scope.waitToLoad == 'false') {
        $scope.countModel.filterModel = $scope.filterModel.filter;
        $scope.updateCount();
        $scope.countModel.loaded = true;
      }
    }])
  .controller('BarChartController', ['$rootScope', '$scope', '$timeout', 'urlService', 'layoutService', 'searchService', 'filterService',
    function ($rootScope, $scope, $timeout, urlService, layoutService, searchService, filterService) {
      $scope.barChartModel = {
        seriesCategories: [],
        containerId: "",
        seriesData: [],
        loaded: false,
        filterModel: {}
      };
      $scope.barChartModel.containerId = Math.random() + $scope.contentBlock.configuration.bn;
      $scope.getContentBlockClass = function (contentBlock) {
        return layoutService.getContentBlockClass(contentBlock);
      };
      $scope.updateChart = function () {
        $scope.barChartModel.loading = "true";

        var configuration = $scope.contentBlock.configuration;
        var currentFilter = filterService.convertFilterObjectToFilterArray($scope.barChartModel.filterModel);
        var qualifier = $scope.barChartModel.filterModel.qualifier && $scope.barChartModel.filterModel.qualifier.length > 0 ? $scope.barChartModel.filterModel.qualifier : null;
        var dataSourceBn = configuration.dataSource ? configuration.dataSource.bn : null;
        var entityType = urlService.getEntityTypeCodeOfCurrentPage() === "LYT" ? configuration.entityType : urlService.getEntityTypeCodeOfCurrentPage();
        var targetType = $scope.assocCode ? $scope.assocCode : entityType;
        searchService.searchByQueryAndField(
          urlService.getQueryKey(), urlService.getEntityBn(), entityType, targetType,
          configuration.relationshipTypes, currentFilter, configuration.fieldBn, configuration.sortDescending, 0, configuration.limit, true, qualifier, dataSourceBn
        ).then(function (data) {
            $scope.barChartModel.data = data.data.rows;
          });
      };
      // This event handler takes care of loading elements in the right sidebar when the filters are changed.
      $scope.$on("filterChanged", function (event, data) {
        if (data.tableId === $scope.contentBlock.sectionBn || data.tableId === $scope.contentBlock.configuration.filterIdentifier) {
          $scope.barChartModel.filterModel = data.filter;
          $scope.updateChart();
          $scope.barChartModel.loaded = true;
        }
      });
      // This event handler takes care of loading elements in the right sidebar when initial filters come .
      $scope.$on("InitialFilter", function (evt, data) {
        if ((data.tableId != null) &&
          (data.tableId === $scope.contentBlock.sectionBn
            || data.tableId === $scope.contentBlock.configuration.filterIdentifier
            || data.tableId === $scope.assocCode
            || data.tableId === 'BROWSE')) {
          $scope.barChartModel.filterModel = data.filter;
          $scope.updateChart();
          $scope.barChartModel.loaded = true;
        }
      });
      $scope.$on('refreshCounts', function (event, sectionBn) {
        if ((sectionBn !== null) && (sectionBn === $scope.contentBlock.sectionBn || sectionBn === $scope.contentBlock.configuration.filterIdentifier)) {
          $scope.updateChart();
          $scope.barChartModel.loaded = true;
        }
      });
      // Handles loading for elements in the middle summary view area, both when summary view is selected and the filters are changed.
      if (!$scope.waitToLoad || $scope.waitToLoad === 'false') {
        $scope.barChartModel.filterModel = $scope.filterModel.filter;
        $scope.updateChart();
        $scope.barChartModel.loaded = true;
      }
    }])
  .controller('AdvQueryTableViewController', ['$scope', 'urlService', 'wicketMarkupService', 'filterService', 'utilService', '$sce',
    function ($scope, urlService, wicketMarkupService, filterService, utilService, $sce) {
      $scope.tableViewModel = {};
      $scope.updateTable = function () {
        $scope.tableViewModel.loading = "true";

        var filter = filterService.convertFilterObjectToFilterArray($scope.filterModel.filter);
        var qualifier = $scope.filterModel.filter.qualifier && $scope.filterModel.filter.qualifier.length > 0 ? $scope.filterModel.filter.qualifier : null;

        var tempUrl = urlService.prepareWicketPageUrl('advancedquerymatchesresponse');
        var sourceEntityTypeCode = urlService.getEntityTypeCodeOfCurrentPage() === "LYT" ? $scope.contentBlock.configuration.entityType : urlService.getEntityTypeCodeOfCurrentPage();
        var targetEntityTypeCode = $scope.assocCode ? $scope.assocCode : sourceEntityTypeCode;
        tempUrl = tempUrl + '?entityBn=' + urlService.getEntityBn()
          + '&columnSpecBn=' + $scope.contentBlock.configuration.columnSpecBn
          + '&queryKey=' + urlService.getQueryKey()
          + '&filter=' + angular.toJson(filter)
          + '&sourceEntityTypeCode=' + sourceEntityTypeCode
          + '&destinationEntityTypeCode=' + targetEntityTypeCode
          + '&relationshipTypes=' + $scope.contentBlock.configuration.relationshipTypes
          + '&qualifier=' + qualifier;
        if ($scope.contentBlock.configuration.groupPropertyBn !== null) {
          tempUrl = tempUrl + '&groupPropertyBn=' + $scope.contentBlock.configuration.groupPropertyBn;
        }
        if ($scope.contentBlock.configuration.pageSize !== null) {
          tempUrl = tempUrl + '&pageSize=' + $scope.contentBlock.configuration.pageSize;
        }
        if ($scope.contentBlock.configuration.sortProperty !== null) {
          tempUrl = tempUrl + '&sortProperty=' + $scope.contentBlock.configuration.sortProperty;
        }
        if ($scope.contentBlock.configuration.sortDescending !== null) {
          tempUrl = tempUrl + '&sortDescending=' + $scope.contentBlock.configuration.sortDescending;
        }
        if ($scope.contentBlock.configuration.dataSource) {
          tempUrl = tempUrl + '&dataSourceBn=' + $scope.contentBlock.configuration.dataSource.bn;
        }
        $scope.tableViewModel.url = tempUrl;
        $scope.tableViewModel.promise = wicketMarkupService.getMarkup($scope.tableViewModel.url);
        $scope.tableViewModel.promise.then(function (data) {
          $scope.tableViewModel.data = data.data;
          $scope.tableViewModel.loading = "false";
        });
      };

      // $sanitize chokes on Wicket's HTML output, so we bypass sanitization
      // per https://code.angularjs.org/1.2.32/docs/api/ngSanitize/service/$sanitize
      $scope.getTableHtml = function(){
        if ($scope.tableViewModel.data){
          return $sce.trustAsHtml($scope.tableViewModel.data);
        }
        return null;
      };

      $scope.$on('configurationSaved', function (event, newConfiguration) {
        if (newConfiguration.bn === $scope.contentBlock.configuration.bn) {
          $scope.updateTable();
        }
      });

      // This event handler takes care of loading elements in the right sidebar when the filters are changed.
      $scope.$on("InitialFilter", function (evt, data) {
        if (data.tableId === $scope.assocCode || data.tableId === 'BROWSE') {
          $scope.updateTable();
        }
      });

      if (!$scope.waitToLoad || $scope.waitToLoad === 'false') {
        $scope.updateTable();
      }
    }])
  .controller('ReportCtrl', ['$scope', 'urlService', '$location',
    function ($scope, urlService, $location) {
      $scope.reportModel = {
        resourceUrl: null,
        entityBn: null
      };

      var reportName = $location.absUrl().split("/")[5].replace("#", "");
      $scope.reportModel.resourceUrl = ReportNameToResourceUrl[reportName];
      $scope.reportModel.entityBn = $location.absUrl().split("/")[6].replace("#", "");
    }])
  .controller('GanttCtrl', ['$scope', 'ganttDataService', 'urlService', 'filterService', 'bitConstants',
    function ($scope, ganttDataService, urlService, filterService, bitConstants) {
      $scope.mode = "date";
      $scope.firstDay = 1;
      $scope.weekendDays = [0, 6];
      $scope.maxHeight = 0;
      $scope.showWeekends = true;
      $scope.scale = "month";
      $scope.scaleFactor = .1;
      $scope.fromDate = new Date();
      $scope.toDate = new Date();
      $scope.entityBn = urlService.getEntityBn();
      $scope.hasData = false;

      //the gantt chart doesn't currently handle null to dates.  we need to find any null to dates on the tasks
      // and set them to the greater of the global toDate (180 days beyond today) or the furthest toDate of the tasks.
      // todo:  having to iterate thru this twice is lame.  js is not pass-by-reference, though maybe there's a better
      // way to handle this..?
      function cleanToDates(data) {
        //set baseline max date
        var maxToDate = $scope.toDate.getTime();

        //iterate to find max to date
        _.forEach(data, function (row) {
          var maxTaskToDate = _.max(row.tasks, function (task) {
            return task.to;
          });
          if (maxTaskToDate.to > maxToDate) {
            maxToDate = maxTaskToDate.to;
          }
        });
        //iterate again to find any null to dates and set them accordingly
        _.forEach(data, function (row) {
          _.forEach(row.tasks, function (task) {
            if (!task.to) {
              task.to = maxToDate;
            }
          });
        });
      }

      $scope.loadGanttData = function () {
        //set the minimum end date of the chart.  (will be overridden by tasks that have a to-date beyond this date)
        $scope.toDate = new Date();
        $scope.toDate.setDate(new Date().getDate() + 180);
        var filterParams = filterService.convertFilterObjectToFilterArray($scope.filterModel.filter);
        var qualifier = null;
        if ($scope.filterModel.filter) {
          qualifier = $scope.filterModel.filter.qualifier;
        }
        var entityTypeCode = urlService.getEntityTypeCodeOfCurrentPage();
        var sourceBnCode = entityTypeCode ? bitConstants.getEntityTypeForTypeCode(entityTypeCode).bnCode : null;
        var queryParams = {
          entityBn: $scope.entityBn,
          sourceBnCode: sourceBnCode,
          filter: angular.toJson(filterParams || {}),
          qualifier: qualifier,
          queryKey: urlService.getQueryKey()
        };
        var promise = ganttDataService.fetchData(queryParams);
        promise.then(function (results) {
          $scope.clearData();
          $scope.hasData = results.data && results.data.length > 0;
          cleanToDates(results.data);
          $scope.loadData(results.data);
        });
      };

      $scope.rowEvent = function (event) {
        console.log('row event fired: ', event);
        // A row has been added, updated or clicked. Use this event to save back the updated row e.g. after a user re-ordered it.
      };

      $scope.scrollEvent = function (event) {
        if (angular.equals(event.direction, "left")) {
          // Raised if the user scrolled to the left side of the Gantt. Use this event to load more data.
        } else if (angular.equals(event.direction, "right")) {
          // Raised if the user scrolled to the right side of the Gantt. Use this event to load more data.
        }
      };

      $scope.taskEvent = function (event) {
        // A row has been added, updated or clicked.
      };

      $scope.toggleViewScaleDay = function () {
        $scope.scale = "day";
        $scope.scaleFactor = 2;
      };

      $scope.toggleViewScaleMonth = function () {
        $scope.scale = "month";
        $scope.scaleFactor = .1;
      };

      $scope.$on('load', function (event, viewId) {
        if (!viewId) return;
        if ('dem_gantt' == viewId) {
          $scope.loadGanttData();
        }
      });

      $scope.$on('reload', function (event, viewId) {
        if (!viewId) return;
        if ('dem_gantt' == viewId) {
          $scope.loadGanttData();
        }
      });

    }]);
