/**
 * Use controllers to:
 * Set up the initial state of a scope object (data, aka the Model, incl. promise functions).
 * Add behavior to the scope object (behavioral functions).
 *
 * Do not use controllers for:
 * Any kind of DOM manipulation.
 * Input formatting.
 * Output filtering.
 * To run stateless or stateful code shared across controllers.
 * To instantiate or manage the life-cycle of other components.
 *
 * You can associate controllers with scope objects implicitly via the ngController directive or $route service.
 *
 * This file tries to follow John Papa's Angular 1 style guide (adjusted to our AngularJS version).
 *
 * https://github.com/johnpapa/angular-styleguide/blob/master/a1/README.md
 */
angular
  .module('barometerApp.simpleTable')
  .controller('SimpleTableRowsController', SimpleTableRowsController);

SimpleTableRowsController.inject = ['$scope', 'simpleTableCellUtilityService'];

/**
 * Registered with the corresponding directive.
 */
function SimpleTableRowsController($scope, simpleTableCellUtilityService) {

  // BEGIN CONTROL FUNCTIONS - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  /**
   * Choose the CSS class for an un/selected row.
   */
  function getRowClass(bn, depth) {
    // See if the row is selected
    if ($scope.tableModel.selected && bn === $scope.tableModel.selected) {
      if (depth == 0) {
        return 'active';
      }
      else {
        return 'active subtle';  // Nested rows are subtler
      }
    }
    return '';
  }

  // BEGIN SCOPED FUNCTIONS - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  /**
   * Select an item.
   */
  $scope.select = function (item, event) {
    //
    if (angular.equals($scope.tableModel.selected, item)) {
      $scope.tableModel.selected = null;
      //$scope.tableModel.clickFunction(null, event);
    } else {
      $scope.tableModel.selected = item;
      //$scope.tableModel.clickFunction(item, event);
    }
  };

  /**
   * Check a checkbox.
   */
  $scope.updateCheckbox = function ($event, rowId, colId) {
    //
    var checkbox = $event.target;
    $scope.checkboxFunction(checkbox.checked, rowId, colId);
  };

  /**
   * Build table HTML.
   *
   * @param rows The data model.
   * @param columns The meta-data model.
   * @param depth Is this a nested-level tracker?
   * @param currentHtml Append to this page content and return it.
   * @returns {*}
   */
  $scope.buildTable = function (rows, columns, depth, currentHtml) {
    //
    var visibleColumns = columns.filter(function (c) {
      return c.display == true;
    });
    // If no data, return empty rows.
    if (rows.length <= 0) {
      //TODO no data
      currentHtml += '<tr>' +
        '<td colspan="' + visibleColumns.length + '" class="no-assocs-found">' +
        '<p>' +
        'No Results Found' +
        '</p>' +
        '</td>' +
        '</tr>';
      $scope.html = currentHtml;
      return currentHtml;
    }
    // Make each row.
    for (var i = 0; i < rows.length; i++) {
      var row = rows[i];
      var rowId = row.id;
      if (depth <= 0) {
        currentHtml += '<tr class="' + getRowClass(rowId, depth) + '" ng-click="select(\'' + rowId + '\', $event);">';
      } else {
        currentHtml += '<tr class="' + getRowClass(rowId, depth) + '">';
      }
      // Render each column-cell in the row.
      for (var j = 0; j < columns.length; j++) {
        var column = columns[j];
        if (column.display) {
          if (column.rendererId == 'ISOUTCLocalDate') currentHtml += simpleTableCellUtilityService.renderISOUTCLocalDate(row, column);
          else if (column.rendererId == 'localDate') currentHtml += simpleTableCellUtilityService.renderLocalDate(row, column);
          else if (column.rendererId == 'unixToLocalDate') currentHtml += simpleTableCellUtilityService.renderUnixDateToLocalDate(row, column);
          else if (column.rendererId == 'pin') currentHtml += simpleTableCellUtilityService.renderPinControl(row, column);
          else if (column.rendererId == 'numberedAndNamedLink') currentHtml += simpleTableCellUtilityService.renderNumberedAndNamedLink(row, column);
          else if (column.rendererId == 'plain') currentHtml += simpleTableCellUtilityService.renderPlain(row, column);
          else currentHtml += simpleTableCellUtilityService.renderIconAndAnchor(row, column);
        }
      }
      currentHtml += '</tr>';
    }
    $scope.html = currentHtml;
    return currentHtml;
  };
}