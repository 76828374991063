angular
  .module('barometerApp.metricAggregation')
  .directive('metricAggregationPathwayVisualization', metricAggregationPathwayVisualization);

metricAggregationPathwayVisualization.$inject = [
  'styleService'
];

/**
 * Factory method for the directive.
 */
function metricAggregationPathwayVisualization(styleService) {

  return {
    scope: {
      perspectiveEntity: '=',
      pathway: '=',
      counts: '='
    },
    templateUrl: '/b/js/src/bit.ng/metric-aggregation/partials/metric-aggregation-pathway.html',
    link: function (scope, element) {

      function getNodesAndEdges() {

        scope.nodesAndEdges = [];
        // Add the first node from the perspective
        scope.nodesAndEdges.push({
          isNode: true,
          color: styleService.getColorForEntityTypeCode(scope.perspectiveEntity.typeCode),
          count: scope.counts[1],
          name: EntityTypes[scope.perspectiveEntity.typeCode].displayNamePlural,
          iconClass: styleService.getIconClassForEntityTypeCode(scope.perspectiveEntity.typeCode)
        });

        // Add the nodes and edges from the pathway
        _.each(scope.pathway, function (hop, i) {
          var color = styleService.getColorForEntityTypeCode(hop.targetEntityType);
          var count = scope.counts[i + 2];  // Counts is 1-indexed and the first count is the perspective entityg
          var name = EntityTypes[hop.targetEntityType].displayNamePlural;
          var iconClass = styleService.getIconClassForEntityTypeCode(hop.targetEntityType);
          var edgeLabel = hop.qualifierType || hop.qualifier || 'RELATED_TO';
          edgeLabel = QualifierTypes[edgeLabel].displayName;
          scope.nodesAndEdges.push({isNode: false, edgeLabel: edgeLabel});
          scope.nodesAndEdges.push({isNode: true, color: color, count: count, name: name, iconClass: iconClass});
        });
      }

      scope.$watch('counts + pathway + perspectiveEntity', function (value) {
        getNodesAndEdges();
      });

      getNodesAndEdges();
    }
  }
}