angular.module('barometerApp.layout')
  .service('layoutService', ['$http', '$rootScope', 'errorHandlingService', 'utilService', 'alertService',
    function ($http, $rootScope, errorHandlingService, utilService, alertService) {

      // holds the state of the current selected summary layout in view by entity type
      var _currentLayoutByEntityType = {};
      var _currentLayout = null;

      // base URLs as well as a generic function to return generated URLs
      var _baseUrl = {
        layout: '/b/api/layout/',
        summary: '/b/api/summary/',
        generateUrl: function () {
          var url = '';
          for (var i = 0; i < arguments.length; i++) {
            url += arguments[i];
          }
          return url;
        }
      };

      return {
        setCurrentLayout: function (layout) {
          _currentLayout = layout;
        },
        getCurrentLayout: function () {
          return _currentLayout;
        },
        getContentBlockClass: function (contentBlock) {
          var classes = "sl-content-block ";
          if (contentBlock.size == "EXTRASMALL") {
            classes = classes + "sl-fourth";
          } else if (contentBlock.size == "SMALL") {
            classes = classes + "sl-third";
          } else if (contentBlock.size == "MEDIUM") {
            classes = classes + "sl-half";
          } else if (contentBlock.size == "LARGE") {
            classes = classes + "sl-whole";
          }
          return classes;
        },
        setCurrentSummaryLayout: function (entityType, layout) {
          _currentLayoutByEntityType[entityType] = layout;
        },
        getCurrentSummaryLayout: function (entityType) {
          var layout = _currentLayoutByEntityType[entityType];
          if (layout == null) {
            return this.getDefaultLayoutForEntityType(entityType);
          }
          return layout;
        },
        updateLayout: function (layout, entityType) {
          utilService.showLoading();
          var url = _baseUrl.generateUrl(_baseUrl.layout);
          return $http.put(url, layout).success(function (data, status, headers, config) {
            $rootScope.$broadcast("successAlertMessage", String.format('{0} was saved successfully', layout.name));
            data.entityType = entityType;
            $rootScope.$broadcast('layoutSavedSuccessfully', data);
            utilService.hideLoading();
          }).error(function (data, status, headers, config) {
            errorHandlingService.handleGenericError(status);
            $rootScope.$broadcast('errorMessage', String.format('An error occurred saving {0}. Please try again.', layout.name));
            utilService.hideLoading();
          });
        },
        getLayout: function (bn) {
          utilService.showLoading();
          var url = _baseUrl.generateUrl(_baseUrl.layout, bn);
          return $http.get(url).success(function (data, status, headers, config) {
            utilService.hideLoading();
          }).error(function (data, status, headers, config) {
            errorHandlingService.handleGenericError(status);
            $rootScope.$broadcast('errorMessage', String.format('An error occurred loading the layout with BN = {0}.', bn));
            utilService.hideLoading();
          });
        },
        deleteLayout: function (layout, cb) {
          utilService.showLoading();
          layout.bn = "DELETE_" + layout.bn;
          var url = _baseUrl.generateUrl(_baseUrl.layout);
          return $http.put(url, layout).success(function (data, status, headers, config) {
            alertService.addSuccessAlert(String.format('Layout_Name_Successfully_Deleted', layout.name));
            utilService.hideLoading();
            $rootScope.$broadcast('layoutDeleteSuccessful', layout);
            cb ? cb() : null;
          }).error(function (data, status, headers, config) {
            errorHandlingService.handleGenericError(status);
            $rootScope.$broadcast('errorMessage', String.format('An error occurred deleting layout {0}. Please try again.', layout.name));
            utilService.hideLoading();
          });
        },
        getSummaryLayoutsByEntityType: function (entityType) {
          return $http.get(_baseUrl.generateUrl(_baseUrl.layout, 'layouts/', entityType)).error(function (data, status, headers, config) {
            errorHandlingService.handleGenericError(status);
          });
        },
        getDefaultLayoutForEntityType: function (entityType) {
          return EntityTypeCodeToDefaultSummaryLayout[entityType];
        },
        getPropertyListForGrouping: function (entityType) {
          return $http.get(_baseUrl.generateUrl(_baseUrl.summary, 'groupableproperties/', entityType)).error(function (data, status, headers, config) {
            errorHandlingService.handleGenericError(status);
          });
        },
        getPropertyListForCount: function (entityType) {
          return $http.get(_baseUrl.generateUrl(_baseUrl.summary, 'countproperties/', entityType)).error(function (data, status, headers, config) {
            errorHandlingService.handleGenericError(status);
          });
        },
        getPropertyListForSummary: function (entityType) {
          return $http.get(_baseUrl.generateUrl(_baseUrl.summary, 'countproperties/', entityType)).error(function (data, status, headers, config) {
            errorHandlingService.handleGenericError(status);
          });
        },
        getAdvancedQueriesForTypeCode: function (typeCode) {
          return $http.get((_baseUrl.summary + 'queries/' + typeCode)).error(function (data, status, headers, config) {
            errorHandlingService.handleGenericError(status);
          });
        },
        getScorecardsForTypeCode: function (typeCode) {
          return $http.get((_baseUrl.summary + 'scorecards/' + typeCode)).error(function (data, status, headers, config) {
            errorHandlingService.handleGenericError(status);
          });
        },
        getComplianceRulesForTypeCode: function (typeCode) {
          return $http.get((_baseUrl.summary + 'rules/' + typeCode)).error(function (data, status, headers, config) {
            errorHandlingService.handleGenericError(status);
          })
        },

        // Map of CONTENT_BLOCK_TYPE (aka "cbt") to HTML templates
        CONTENT_BLOCK_TEMPLATES: {

          // Default
          DEFAULT: "<a class=\'sl-content-block-config-link\' overlay-link model=\'contentBlock\' markup=\"\'/b/js/src/bit.ng/layout/partials/content-block-config.html\'\" ng-show=\'layoutModel.modeClass=sl-edit\'>Configure</a>",

          // Basic
          METRIC: "<div ng-include=\"\'/b/js/src/bit.ng/content/partials/simple-count.html\'\"></div>",
          BAR: "<div ng-include=\"\'/b/js/src/bit.ng/content/partials/bar-container.html\'\"></div>",
          PIE: "<div ng-include=\"\'/b/js/src/bit.ng/content/partials/pie-container.html\'\"></div>",
          SUMMARY: "<div ng-include=\"\'/b/js/src/bit.ng/content/partials/summary-view.html\'\"></div>",
          TABLE: "<div ng-include=\"\'/b/js/src/bit.ng/content/partials/adv-query-table-view.html\'\"></div>",
          NEW_TABLE: "<div ng-include=\"\'/b/js/src/bit.ng/table/partials/table.html\'\"></div>",
          FIELDSET: "<div ng-include=\"\'/b/js/src/bit.ng/fieldset/partials/fieldsets-on-entity-view.html\'\"></div>",
          TEXT: "<div></div>",

          // Worksheet
          WORKSHEET: "<div ng-include=\"\'/b/js/src/bit.ng/worksheet/partials/dashboard-worksheet-container.html\'\"></div>",

          // System sidebar
          SYSTEM_BASIC_INFO_SIDEBAR: "<div entity-summary-info-loader assoc-code=\"assocCode\" section-bn=\"sectionBn\"></div>",

          // Entity sidebars
          BASIC_INFO_SIDEBAR: "<div ng-include=\"\'/b/js/src/bit.ng/entity/partials/basic-info-sidebar.html\'\"></div>",
          BASIC_INFO_SIDEBAR_WITH_SUMMARY_INFO: "<div ng-include=\"\'/b/js/src/bit.ng/summary-info/partials/basic-info-sidebar-with-summary-info.html\'\"></div>",
          BASIC_INFO_SIDEBAR_WITH_SUMMARY_INFO_CONTACTS: "<div ng-include=\"\'/b/js/src/bit.ng/summary-info/partials/basic-info-sidebar-with-summary-info-contacts.html\'\"></div>",
          BASIC_INFO_SIDEBAR_WITH_SUMMARY_INFO_ORGANIZATIONS_COMPANIES: "<div ng-include=\"\'/b/js/src/bit.ng/summary-info/partials/basic-info-sidebar-with-summary-info-organizations-companies.html\'\"></div>",
          BASIC_INFO_SIDEBAR_WITH_SUMMARY_INFO_CONTACTS_COMPANIES: "<div ng-include=\"\'/b/js/src/bit.ng/summary-info/partials/basic-info-sidebar-with-summary-info-contacts-companies.html\'\"></div>",
          BASIC_INFO_SIDEBAR_SYSTEM_SUMMARY_INFO: "<div ng-include=\"\'/b/js/src/bit.ng/summary-info/partials/basic-info-sidebar-with-criticality-complexity-summary-info.html\'\"></div>"
        }
      };
    }]);
