/**
 * This file tries to follow John Papa's Angular 1 style guide (adjusted to our AngularJS version).
 *
 * https://github.com/johnpapa/angular-styleguide/blob/master/a1/README.md
 */
angular
  .module('barometerApp.taskManager')
  .factory('taskManagerDataService', taskManagerDataService);

taskManagerDataService.$inject = ['$http', '$q', 'alertService', 'urlService', 'utilService'];

function taskManagerDataService($http, $q, alertService, urlService, utilService) {
  //
  var GRAPH_URL = urlService.getGraphApiUrl();

  /**
   For example:
   {
   "entityType" : "TSK",
   "sortBy" : {"fieldName" : "name", "sortOrder" : "ASC"},
   "properties" : ["name", "bn", "description", "040K0000013B"],
   "filterBy" : {
   "fieldValues" : [{"fieldBn" : "040K0000013B", "value" : "Open"}],
   "propertyFilters" : [{"propertyName" : "description", "value" : "Foo0308-2"}]
   },
   "firstRecord" : 1,
   "numberToReturn" : 10
   }
   */
  var requestModel = function(taskStatusOptionListBn) {
    return {
        //
        entityType: "TSK",
        properties: ["bn", "description", taskStatusOptionListBn, "createDate", "taskCompletionDate"],
        filterBy: {
            propertyFilters: []
        },
        firstRecord: -1,
        numberToReturn: 10,
        sortBy: {
            fieldName: 'description',
            sortOrder: 'ASC'
        }
    };
  };
  //
  var mockResponse = {
    columnSpec: [
      {
        display: true,
        entityIndex: 1,
        entityType: null,
        id: "bn",
        name: "bn",
        rendererId: null,
        sortClass: "sort-button icon-sort-asc",
        sortable: true,
        sorted: null,
        totalCount: 3
      },
      {
        display: true,
        entityIndex: 1,
        entityType: null,
        id: "description",
        name: "description",
        rendererId: null,
        sortClass: "sort-button icon-sort-asc",
        sortable: true,
        sorted: "ASC",
        totalCount: 3
      },
      {
        display: true,
        entityIndex: 1,
        entityType: null,
        id: "status",
        name: "status",
        rendererId: null,
        sortClass: "sort-button icon-sort-asc",
        sortable: false,
        sorted: null,
        totalCount: 3
      },
      {
        display: true,
        entityIndex: 1,
        entityType: null,
        id: "assignedBy",
        name: "assigned by",
        rendererId: null,
        sortClass: "sort-button icon-sort-asc",
        sortable: false,
        sorted: null,
        totalCount: 3
      },
      {
        display: true,
        entityIndex: 1,
        entityType: null,
        id: "assignedTo",
        name: "assigned to",
        rendererId: null,
        sortClass: "sort-button icon-sort-asc",
        sortable: false,
        sorted: null,
        totalCount: 3
      },
      {
        display: true,
        entityIndex: 1,
        entityType: null,
        id: "createDate",
        name: "created",
        rendererId: null,
        sortClass: "sort-button icon-sort-asc",
        sortable: false,
        sorted: null,
        totalCount: 3
      },
      {
        display: true,
        entityIndex: 1,
        entityType: null,
        id: "taskCompletionDate",
        name: "completed",
        rendererId: null,
        sortClass: "sort-button icon-sort-asc",
        sortable: false,
        sorted: null,
        totalCount: 3
      }
    ],
    rowCount: 3,
    rows: [
      {bn: "044O00000001", description: "Do something, please!", status: "Open", assignedBy: "Daffy Duck", assignedTo: "Wiley E. Coyote", createDate: "1489159722", taskCompletionDate: "2017-11-20"},
      {bn: "044O00000002", description: "Do something else, please!", status: "Open", assignedBy: "Elmer Fudd", assignedTo: "Bugs Bunny", createDate: "1489159722", taskCompletionDate: "2017-11-20"},
      {bn: "044O00000003", description: "Do yet another thing, please!", status: "Complete", assignedBy: "Daffy Duck", assignedTo: "Marvin Martian", createDate: "1489159722", taskCompletionDate: "2017-11-20"}
    ]
  };
  //
  var assignedToColumn = {
    display: true,
    entityIndex: 1,
    entityType: null,
    id: "assignedTo",
    name: "assigned to",
    rendererId: "numberedAndNamedLink",
    sortable: false,
    sorted: null,
    totalCount: null
  };
  var assignedByColumn = {
    display: true,
    entityIndex: 1,
    entityType: null,
    id: "assignedBy",
    name: "assigned by",
    rendererId: "numberedAndNamedLink",
    sortable: false,
    sorted: null,
    totalCount: null
  };
  //
  return {
    getRequestModel: getRequestModel,
    getTasks : getTasks
  };

  function getAuthToken() {
    var authToken = utilService.readCookie('baroJWTToken');
    if (!authToken) {
      alertService.addErrorAlert('No authentication credential for Task service.');
    }
    return authToken;
  }

  function getRequestModel(taskStatusOptionListBn) {
    return requestModel(taskStatusOptionListBn);
  }

  function getTasks(myRequestModel) {
    //
    //return mockResponse;
    //
    var deferred = $q.defer();
    var token = getAuthToken();
    if (!token) {
      return deferred.reject('Authorization token missing');
    }
    $http({
      method: 'POST',
      url: GRAPH_URL + 'report/entities',
      headers: {
        'Authorization': 'Bearer ' + token,
        'bit-tenant-bn': utilService.getTenantBn(),
        'Content-Type': 'application/json'
      },
      data: myRequestModel
    }).success(function (data) {
      postProcessData(data);
      deferred.resolve(data);
    }).error(function (data) {
      console.error('Error getting neo4j path');
      deferred.reject(data);
    })
    return deferred.promise;
  }

  function postProcessData(data) {
    //
    // TODO localize
    data.columnSpec[2].name = "Status";
    data.columnSpec[3].name = "Created";
    data.columnSpec[4].name = "Completed";
    //
    data.columnSpec[0].rendererId = "plain";
    data.columnSpec[1].rendererId = "ENTITY_LINK";
    data.columnSpec[2].rendererId = "plain";
    data.columnSpec[3].rendererId = "unixToLocalDate";
    data.columnSpec[4].rendererId = "unixToLocalDate";
    //
    expandColumns(data);
    expandRows(data);
  }

  /**
   * Move and insert columns.
   */
  function expandColumns(data) {
    //
    var totalCount = data.columnSpec[0].totalCount;
    //
    var col3 = data.columnSpec[3];
    data.columnSpec[5] = col3;
    data.columnSpec[3] = [];
    //
    var col4 = data.columnSpec[4];
    data.columnSpec[6] = col4;
    data.columnSpec[4] = [];
    //
    data.columnSpec[3] = assignedToColumn;
    data.columnSpec[3].totalCount = totalCount;
    //
    data.columnSpec[4] = assignedByColumn;
    data.columnSpec[4].totalCount = totalCount;
  }

  function expandRows(data) {
    //
    angular.forEach(data.rows, function (row, index, rows) {
      row.assignedTo = null;
      row.assignedBy = null;
    });
  }
}