angular.module('barometerApp.search')
  .service('searchService', ['$http', 'errorHandlingService', 'utilService',
    function ($http, errorHandlingService, utilService) {

      return {
        searchUnified: function (searchString, maxResults, includedEntityTypes, includeQueries) {
          if (!maxResults) {
            maxResults = 25;
          }
          var queryData =
            {
              searchString: searchString,
              maxResults: maxResults,
              entityTypes: includedEntityTypes,
              includeQueries: includeQueries
            };
          return $http({
            method: 'POST',
            url: '/b/api/search/unified',
            data: queryData,
            headers: {'Content-Type': 'application/json'}
          }).success(function (data, status, headers, config) {
          }).error(function (data, status, headers, config) {
            console.log('error during search unified for query: ' + searchString + ' and max results: ' + maxResults);
            errorHandlingService.handleGenericError(status);
          });
        },

        searchMixed: function (queryKey, maxResults) {
          return $http({
            method: 'GET',
            url: '/b/api/search/mixed?queryKey=' + queryKey + '&maxResults=' + maxResults
          }).success(function (data, status, headers, config) {
          }).error(function (data, status, headers, config) {
            console.log('error during search mixed for queryKey: ' + queryKey);
            errorHandlingService.handleGenericError(status);
          });
        },

        searchEnterpriseCounts: function () {
          return $http({
            method: 'GET',
            url: '/b/api/search/enterprise/counts'
          }).success(function (data, status, headers, config) {
          }).error(function (data, status, headers, config) {
            console.log('error during search mixed counts');
            errorHandlingService.handleGenericError(status);
          });
        },

        searchEntity: function (count, sourceBn, targetEntityTypeCode, checkForAssociations, relationshipType, filter) {
          count = count || 10;
          return $http({
            method: 'GET',
            url: '/b/api/entities',
            params: {
              first: 0,
              count: count,
              sourceBn: sourceBn,
              type: targetEntityTypeCode,
              checkForAssociations: checkForAssociations,
              relationshipType: relationshipType,
              filter: filter,
            }
          }).success(function (data, status, headers, config) {
          }).error(function (data, status, headers, config) {
            console.log('error during searchEntity for query: ' + filter.searchString);
            errorHandlingService.handleGenericError(status);
          });
        },
        searchByQueryAndField: function (queryKey, entityBn, sourceEntityTypeCode, destinationEntityTypeCode, relationshipTypes, filter, fieldBn, sortDescending, first, limit, provideRemainder, qualifier, dataSourceBn) {
          if (!fieldBn) console.log("WARN: fieldBn is undefined.");
          var queryData = $.param(
            {
              dataSourceBn: dataSourceBn,
              queryKey: queryKey,
              entityBn: entityBn,
              sourceEntityTypeCode: utilService.getBaseEntityType(sourceEntityTypeCode),
              destinationEntityTypeCode: destinationEntityTypeCode,
              relationshipTypes: relationshipTypes,
              filter: angular.toJson(filter),
              fieldBn: fieldBn,  // Must not be blank.
              sortDescending: sortDescending,
              first: first,
              limit: limit,
              provideRemainder: provideRemainder,
              qualifier: qualifier
            });
          return $http({
            method: 'POST',
            url: '/b/api/summary/search',
            data: queryData,
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
          }).success(function (data, status, headers, config) {
            //
          }).error(function (data, status, headers, config) {
            console.error('Error calling searchService.searchByQueryAndField for entityBn: ' + entityBn + ' fieldBn: ' + fieldBn);
            errorHandlingService.handleGenericError(status);
          });
        },
        getDomainFilterCounts: function (filterBn, dataListBn, entityBn, sourceEntityTypeCode, destinationEntityTypeCode, queryKey) {

          var params = [];
          var filter = '';
          if(dataListBn !== null && utilService.isBn(dataListBn)) {
            params[0] = {};
            params[0][dataListBn] = ["ACTIVE"];
            filter = angular.toJson({params: params});
          }
          if (!filterBn) console.log("WARN: filterBn is undefined.");

          var queryData = $.param(
            {
              entityBn: entityBn,
              sourceEntityTypeCode: sourceEntityTypeCode,
              destinationEntityTypeCode: destinationEntityTypeCode,
              queryKey: queryKey,
              filter: filter,
              fieldBn: filterBn, // Must not be blank.
              sortDescending: true,
              first: 0,
              limit: 99
            });
          return $http({
            method: 'POST',
            url: '/b/api/summary/search',
            data: queryData,
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
          }).success(function (data, status, headers, config) {
            //
          }).error(function (data, status, headers, config) {
            console.error('Error calling searchService.getDomainFilterCounts for filterBn:', filterBn, 'dataListBn: ', dataListBn, 'entityBn:', entityBn,
              'sourceEntityTypeCode:', sourceEntityTypeCode, 'destinationEntityTypeCode:', destinationEntityTypeCode, 'queryKey:', queryKey);
            errorHandlingService.handleGenericError(status);
          });
        },

        getCountData: function (queryKey, entityBn, sourceEntityTypeCode, destinationEntityTypeCode, relationshipTypes, filter, fieldBn, measurement, qualifier, dataSourceBn) {
          //TODO JASON CHANGE TO DATASOURCEBN

          var queryData = $.param(
            {
              dataSourceBn: dataSourceBn,
              queryKey: queryKey,
              entityBn: entityBn,
              sourceEntityTypeCode: utilService.getBaseEntityType(sourceEntityTypeCode),
              destinationEntityTypeCode: destinationEntityTypeCode,
              relationshipTypes: relationshipTypes,
              filter: angular.toJson(filter),
              fieldBn: fieldBn,
              measurement: measurement,
              qualifier: qualifier
            });
          return $http({
            method: 'POST',
            url: '/b/api/summary/count',
            data: queryData,
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
          }).success(function (data, status, headers, config) {
            //
          }).error(function (data, status, headers, config) {
            errorHandlingService.handleGenericError(status);
            console.error('Error calling searchService.getCountData for entityBn: ' + entityBn + ' fieldBn: ' + fieldBn);
          });
        },
        getFieldsForTypeCode: function (entityTypeCode) {
          return $http({
            method: 'GET',
            url: '/b/api/summary/fields/' + entityTypeCode
          }).success(function (data, status, headers, config) {
            //
          }).error(function (data, status, headers, config) {
            errorHandlingService.handleGenericError(status);
            console.error('Error calling searchService.getFieldsForType for entityTypeCode: ' + entityTypeCode);
          });
        },
        getCountForDSHEntityCollectionPage: function (queryKey, sourceBnCode) {
          return $http({
            method: 'GET',
            url: '/b/api/customdashboard/count',
            params: {
              queryPurpose: 'singletypebrowse',
              bnCode: sourceBnCode,
              queryKey: queryKey
            }
          }).success(function (data, status, headers, config) {
          }).error(function (data, status, headers, config) {
            errorHandlingService.handleGenericError(status);
            console.error('Error calling searchService.getCountForSingleEntityCollectionPage for queryKey: ' + queryKey + ' sourceEntityTypeCode: ' + sourceBnCode);
          });
        },
        getCountForSingleEntityCollectionPage: function (queryKey, sourceBnCode, type = '', insightType = '') {
          return $http({
            method: 'GET',
            url: '/b/api/browse',
            params: {
              format: 'count',
              queryPurpose: 'singletypebrowse',
              bnCode: sourceBnCode,
              queryKey: queryKey,
              type,
              insightType
            }
          }).success(function (data, status, headers, config) {
          }).error(function (data, status, headers, config) {
            errorHandlingService.handleGenericError(status);
            console.error('Error calling searchService.getCountForSingleEntityCollectionPage for queryKey: ' + queryKey + ' sourceEntityTypeCode: ' + sourceBnCode);
          });
        },
        getBrowseCountForSimpleTextSearch: function (sourceBnCode, searchString, additionalFilters, insightTypeBn) {
          var searchFilter = {
            searchString: searchString
          };
          searchFilter = _.merge(searchFilter, additionalFilters);
          return $http({
            method: 'GET',
            url: '/b/api/browse',
            params: {
              format: 'count',
              queryPurpose: 'singletypebrowse',
              bnCode: sourceBnCode,
              filter: searchFilter,
              insightTypeBn
            }
          }).success(function (data, status, headers, config) {
          }).error(function (data, status, headers, config) {
            errorHandlingService.handleGenericError(status);
            console.error('Error calling searchService.getBrowseCountForSimpleTextSearch for searchString: ' + searchString + ' and sourceBnCode: ' + sourceBnCode);
          });
        }
      }
    }])

  .service('searchByFieldNameService', ['$http', '$q', 'errorHandlingService', 'searchService', 'dataDictionaryHeaderService',
    function ($http, $q, errorHandlingService, searchService, dataDictionaryHeaderService) {

      return {

        /**
         * Valid searchFieldNames:
         *
         * ACT: lifecycleState.bn
         * CAP: capabilityType.bn, domain.bn, lifecycleState.bn
         * COM: enterpriseApproval.bn, lifecycleState.bn
         * CON: lifecycleState.bn
         * DAT: dataType.bn, domain.bn, enterpriseApproval.bn, lifecycleState.bn
         * DEM: budgetStatus.bn, businessValue.bn, domain.bn, enterpriseApproval.bn, investmentRange.bn, investmentScale.bn, lifecycleState.bn, priority.bn, riskLevel.bn, scheduleStatus.bn, type.bn
         * PHY: domain.bn, lifecycleState.bn, type.bn
         * MKT: domain.bn, lifecycleState.bn, type.bn
         * ORG: domain.bn
         * PER:
         * PRD: domain.bn, lifecycleState.bn, type.bn
         * SKI: enterpriseApproval.bn, lifecycleState.bn
         * STR: businessValue.bn, domain.bn, enterpriseApproval.bn, investmentScale.bn, lifecycleState.bn, priority.bn, riskLevel.bn, type.bn, urgency.bn, valueHorizon.bn
         * SYS: domain.bn, lifecycleState.bn, systemPriority.bn, systemType.bn
         * TEC: enterpriseApproval.bn, domain.bn, lifecycleState.bn
         *
         * Example:
         *
         *  var params = [];
         *  params[0] = {};
         *  params[0]["0446000000W7"] = ["ACTIVE"];
         *  searchByFieldNameService.getCountsByEntityTypeAndSearchableFieldName('SYS', 'domain.bn', {params: params}).then(function (data) {
             *    console.log('Active System domains:', data);
             *  });
         *  searchByFieldNameService.getCountsByEntityTypeAndSearchableFieldName('CAP', 'capabilityType.bn').then(function (data) {
             *    console.log('Capability types:', data);
             *  });
         *
         * Return value looks like this:
         *
         * [
         *  {"count":408,"name":"Use Case","entityViewUrl":null,"bn":"ZZ0M0000004A"},
         *  {"count":139,"name":"Use Case Scenario","entityViewUrl":null,"bn":"ZZ0M0000004B"},
         *  {"count":78,"name":"Feature","entityViewUrl":null,"bn":"ZZ0M0000004C"},
         *  {"count":51,"name":"Capability","entityViewUrl":null,"bn":"ZZ0M00000049"}
         * ]
         */
        getCountsByEntityTypeAndSearchableFieldName: function (entityTypeCode, searchFieldName, filter) {
          var deferred = $q.defer();

            dataDictionaryHeaderService.getDataDictionaryBnsByEntityTypeAndSearchableFieldName(entityTypeCode, searchFieldName).then(function (data) {
                var fieldBn = data.data[0].bn;
                searchService.getCountData(null, null, entityTypeCode, entityTypeCode, null, filter, fieldBn, 'COUNT', null, null).then(function (data) {
                    var limit = data.data;
                    searchService.searchByQueryAndField(null, null, entityTypeCode, entityTypeCode, null, filter, fieldBn, true, 0, limit, false, null, null).then(function (data) {
                        deferred.resolve(data.data.rows);
                    });
                });
          });

          return deferred.promise;
        },

        isDomained: function (entityTypeCode) {
          return DOMAIN_DATA_DICT_SEARCH_FIELD_NAMES.hasOwnProperty(entityTypeCode);
        },

        isTyped: function (entityTypeCode) {
          return TYPE_DATA_DICT_SEARCH_FIELD_NAMES.hasOwnProperty(entityTypeCode);
        },

        /**
         * @param entityTypeCode
         *
         * Facet is DOMAIN_DATA_DICT_SEARCH_FIELD_NAMES[entityTypeCode]
         */
        getDomainCountsForEntityType: function (entityTypeCode) {
          return this.getCountsForEntityType(entityTypeCode, DOMAIN_DATA_DICT_SEARCH_FIELD_NAMES[entityTypeCode]);
        },

        /**
         * @param entityTypeCode
         *
         * Facet is TYPE_DATA_DICT_SEARCH_FIELD_NAMES[entityTypeCode]
         */
        getTypeCountsForEntityType: function (entityTypeCode) {
          return this.getCountsForEntityType(entityTypeCode, TYPE_DATA_DICT_SEARCH_FIELD_NAMES[entityTypeCode]);
        },

        /**
         * @param entityTypeCode e.g. SYS, CAP
         * @param facetName e.g. 'domain.bn', 'type.bn'
         */
        getCountsForEntityType: function (entityTypeCode, facetName) {
          if (entityTypeCode == 'ORG') {
            return this.getUnlifecycledCountsForEntityType(entityTypeCode, facetName);
          } else {
            // TODO BARO-18124 Setting both conditions to Unlifecycled until we solve why DEM and PHY hang.
            // TODO Change this back to Lifecycled.
            return this.getLifecycledCountsForEntityType(entityTypeCode, facetName);
          }
        },

        /**
         * @param entityTypeCode e.g. SYS, CAP
         * @param facetName e.g. 'domain.bn', 'type.bn'
         */
        getLifecycledCountsForEntityType: function (entityTypeCode, facetName) {
          var deferred = $q.defer();
          if ((facetName == 'domain.bn' && !this.isDomained(entityTypeCode)) || ((facetName == 'type.bn' && !this.isTyped(entityTypeCode)))) {
            deferred.reject();
          } else {
            var myService = this;
            dataDictionaryHeaderService.getDataDictionaryBnsByEntityTypeAndSearchableFieldName(entityTypeCode, 'lifecycleState.type').then(function (data) {
              var lifeCycleFieldBn = data.data[0].bn;
              var params = [{}];
              params[0][lifeCycleFieldBn] = ["ACTIVE"];
              myService.getCountsByEntityTypeAndSearchableFieldName(entityTypeCode, facetName, {params: params}).then(function (data) {
                deferred.resolve(data);
              }, function (err) {
                deferred.reject(err);
              });
            });
          }
          return deferred.promise;
        },

        /**
         * @param entityTypeCode e.g. SYS, CAP
         * @param facetName e.g. 'domain.bn', 'type.bn'
         */
        getUnlifecycledCountsForEntityType: function (entityTypeCode, facetName) {
          var deferred = $q.defer();
          if ((facetName == 'domain.bn' && !this.isDomained(entityTypeCode)) || ((facetName == 'type.bn' && !this.isTyped(entityTypeCode)))) {
            deferred.reject();
          } else {
            var params = [{}];
            this.getCountsByEntityTypeAndSearchableFieldName(entityTypeCode, facetName, {params: params}).then(function (data) {
              deferred.resolve(data);
            }, function (err) {
              deferred.reject(err);
            });
          }
          return deferred.promise;
        }
      }
    }]);
