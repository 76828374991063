angular
    .module('barometerApp.relationalDiagram')
    .factory('relationalDiagramLayoutService', relationalDiagramLayoutService);

relationalDiagramLayoutService.$inject = ['$rootScope'];

/**
 * Preconfigured Cytoscape layouts. Can be composed with different styles.
 */
function relationalDiagramLayoutService($rootScope) {

    var PADDING = 100;
    var CONNECTION_COUNT = 0; // Number of calculated Connection entities.

    // *****  Simple connection positioning. *****
    var calculateConnectionPosition = function (count) {
        var newCount = (count + 1),
            yMultiplier = 25,
            xMultiplier = 20,
            yAxis = newCount * yMultiplier,
            xAxis = newCount * xMultiplier;

        setConnectionCount(newCount);
        return {x: xAxis, y: yAxis};
    };
    var setConnectionCount = function (count) {
        CONNECTION_COUNT = count;
    };
    var layoutAnimationStarted = function () {
        $rootScope.$broadcast('layoutanimationstarted');
    };
    var layoutAnimationStopped = function () {
        $rootScope.$broadcast('layoutanimationstopped');
    };
    // *****  Simple connection positioning END. *****
    return {

        // Use position already set on nodes
        PRESET: {
            name: 'preset',
            padding: PADDING
        },

        COLA: {
            name: 'cola',
            animate: true, // whether to show the layout as it's running
            refresh: 0.5, // number of ticks per frame; higher is faster but more jerky
            maxSimulationTime: 4000, // max length in ms to run the layout
            ungrabifyWhileSimulating: true, // so you can't drag nodes during layout
            fit: true, // on every layout reposition of nodes, fit the viewport
            padding: PADDING, // padding around the simulation
            boundingBox: undefined, // constrain layout bounds; { x1, y1, x2, y2 } or { x1, y1, w, h }

            // layout event callbacks
            ready: function () {
                layoutAnimationStarted();
            }, // on layoutready
            stop: function () {
                layoutAnimationStopped();
                // Reset the connection count after each layout request.
                setConnectionCount(0);
            }, // on layoutstop

            // positioning options
            randomize: false, // use random node positions at beginning of layout
            avoidOverlap: true, // if true, prevents overlap of node bounding boxes
            handleDisconnected: true, // if true, avoids disconnected components from overlapping
            nodeSpacing: function (node) {
                // Give connection nodes more empty space.
                if (node._private.classes['entity-type-con']) {
                    return 50;
                }
                return 10;
            }, // extra spacing around nodes
            flow: undefined, // use DAG/tree flow layout if specified, e.g. { axis: 'y', minSeparation: 30 }
            alignment: function (node) {
                if (node._private.classes['entity-type-con']) {
                    // Simple connection positioning.
                    return calculateConnectionPosition(CONNECTION_COUNT);
                }
                return undefined;
            }, // relative alignment constraints on nodes, e.g. function( node ){ return { x: 0, y: 1 } }
               // [DEFAULT] alignment: undefined,

            // different methods of specifying edge length
            // each can be a constant numerical value or a function like `function( edge ){ return 2; }`
            edgeLength: undefined, // sets edge length directly in simulation
            edgeSymDiffLength: undefined, // symmetric diff edge length in simulation
            edgeJaccardLength: undefined, // jaccard edge length in simulation

            // iterations of cola algorithm; uses default values on undefined
            unconstrIter: undefined, // unconstrained initial layout iterations
            userConstIter: undefined, // initial layout iterations with user-specified constraints
            allConstIter: undefined, // initial layout iterations with all constraints including non-overlap

            // infinite layout options
            infinite: false // overrides all other options for a forces-all-the-time mode
        },

        // A force layout.  Produces nice-looking network layouts.  Can incorporate geometric regions in the layout.
        COSE: {
            name: 'cose',
            fit: false,
            componentSpacing: 300, // default is 100
            nodeRepulsion: function (node) {
                // Default is: 400000

                // Indirect (rolled-up) nodes repel less
                if (node.hasClass('indirect')) {
                    return 1000000;
                }

                // Gravity (no repulsion - just attraction)
                if (node.id() == 'gravity') {
                    return 0;
                }

                // Everything else
                return 2000000;
            },
            idealEdgeLength: function (edge) {
                // Default is: 10

                // Sub-entity
                if (edge.data().relationshipType == "HAS_CHILD") {
                    return 375;
                }

                // Gravity
                else if (edge.data().source == 'gravity') {
                    return 1000;
                }

                // Everything else
                return 5;
            },
            edgeElasticity: function (edge) {
                // Default is: 100

                // Sub-entity
                if (edge.data().relationshipType == "HAS_CHILD") {
                    return 50;
                }

                // Gravity
                else if (edge.data().source == 'gravity') {
                    return 400
                }

                // Everything else
                return 100;
            },
            ready: function () {
                layoutAnimationStarted();
            },
            stop: function () {
                layoutAnimationStopped();
            },
            padding: PADDING // default is 30
        },

        // Hierarchical-ish
        BREADTH_FIRST: {
            name: 'breadthfirst',
            padding: PADDING,
            animate: true,
            spacingFactor: .25,
            ready: function () {
                layoutAnimationStarted();
            },
            stop: function () {
                layoutAnimationStopped();
            }
        },

        // Rings
        CONCENTRIC: {
            name: 'concentric',
            padding: PADDING,
            animate: true,
            ready: function () {
                layoutAnimationStarted();
            },
            stop: function () {
                layoutAnimationStopped();
            }
        },

        CIRCLE: {
            name: 'circle',
            padding: PADDING,
            animate: true,
            ready: function () {
                layoutAnimationStarted();
            },
            stop: function () {
                layoutAnimationStopped();
            }
        },

        // Dumb grid.  At least it's orderly.
        GRID: {
            name: 'grid',
            padding: PADDING,
            animate: true,
            ready: function () {
                layoutAnimationStarted();
            },
            stop: function () {
                layoutAnimationStopped();
            }
        }
    };
}