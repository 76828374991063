/**
 * This file tries to follow John Papa's Angular 1 style guide (adjusted to our AngularJS version).
 *
 * https://github.com/johnpapa/angular-styleguide/blob/master/a1/README.md
 */
angular
  .module('barometerApp.watch')
  .factory('browseWatchedUtilityService', browseWatchedUtilityService);

browseWatchedUtilityService.$inject = ['$q'];

function browseWatchedUtilityService($q) {
  return {
    applyFilter1: applyFilter1,
    applyFilter2: applyFilter2
  };

  /**
   * Prepare requestModel to filter by SearchString.
   */
  function applyFilter1(myRequestModel, mySearchString) {
    //
    myRequestModel.filterBy.searchString = null;
    //
    if (mySearchString != null && mySearchString != 'undefined') {
      myRequestModel.filterBy.propertyFilters = [
        {propertyName: "name", value: mySearchString}
      ];
    }
  }

  /**
   * Prepare requestModel to filter Entity Type.
   */
  function applyFilter2(myRequestModel, myOptions, mySelections) {
    //
    myRequestModel.relatedType = null;
    myRequestModel.relatedTypes = null;
    //
    var allOptions = myOptions.map(function(anOption) {return anOption.value;});
    //
    // Case 1: No selections.
    if (mySelections == 'undefined' || mySelections.length < 1)
      myRequestModel.relatedTypes = allOptions;
    // Case 2: One selection.
    else if (mySelections.length == 1)
      myRequestModel.relatedType = mySelections[0];
    // Case 3: Many selections.
    else
      myRequestModel.relatedTypes = mySelections;
  }
}