angular.module('barometerApp.worksheet')

  .controller('CommonWorksheetCtrl', ['$scope', '$q', 'urlService', 'pageService', 'tableService', 'utilService', 'entityService', '$element', 'commonWorksheetService', 'worksheetSubheaderState', 'relationalDiagramGraphDataService', 'recentLocationsService', '$rootScope', '$modal',
    function ($scope, $q, urlService, pageService, tableService, utilService, entityService, $element, commonWorksheetService, worksheetSubheaderState, relationalDiagramGraphDataService, recentLocationsService, $rootScope, $modal) {

      $scope.sectionBn = "WORKSHEET";
      $scope.containsInsightHelp = true;
      $scope.showInsightHelp = false;
      $scope.entityForRemoval = null;
      $scope.toggleInsightHelp = function () {
        $scope.showInsightHelp = !$scope.showInsightHelp;
        relationalDiagramGraphDataService.requestWindowResize();
      };

      // make call to load recents data.  notice we are not adding a new recents record for collection pages.
      recentLocationsService.getRecentsFromServerAndNotify();

      pageService.setIsEntityPage(true);

      // The current URL includes the WorksheetBn
      // The Worksheet entity includes it's WorkspaceBn and it's Type
      $scope.worksheetBn = urlService.getEntityBn();
      //$scope.workspaceBn = null;

      // Share subheader active/inactive information via service
      $scope.subheaderState = worksheetSubheaderState;

      $scope.worksheetTypeName = null;
      $scope.worksheetTemplate = {};

      entityService.getBasicInfo($scope.worksheetBn).then(function (data) {
        $scope.entityBasicInfoModel = data.data;
        $scope.title = data.data.displayableName;
        pageService.setTitle($scope.title);
        $scope.worksheetTypeName = data.data.insightType.name;
        $scope.worksheetTemplate = commonWorksheetService.getTemplatePageByWorksheetInsightTypeBn(data.data.insightType.bn);
        $rootScope.$broadcast('addRecentRecord', data.data);
      });

      // Add entity toggle and widget
      $scope.showAddEntityToggle = true;
      $scope.showAddEntityWidget = false;
      $scope.addEntityToggleClicked = function () {
        $scope.showAddEntityToggle = false;
        $scope.showAddEntityWidget = true;

        // Hacky, but there isn't a good way to do this in angular
        setTimeout(function () {
          $($element).find('.search').focus();
        }, 100);

      };

      // Common addEntity function can by used by any worksheet type; generally executed by the addWidget.
      $scope.addEntity = function ($event, entity) {
        $event.preventDefault();

        if (entity.isAssociated) return;

        const params = {
          sourceBn: $scope.worksheetBn,
          targetBnCode: utilService.getBnCode($scope.worksheetBn),
          indexBn: entity.bn,
          relationshipType: RelationshipTypes['RELATED_TO'],
          associations: {
            addedAssocs: [
              { "targetBn": entity.bn,
                "relationshipType": RelationshipTypes['RELATED_TO'],
                "relationshipIdentifier": null }
            ]
          },
          sectionBn: $scope.sectionBn
        };
        $scope.showAddEntityToggle = true;
        $scope.showAddEntityWidget = false;
        return tableService.updateAssociations(params);
      };


      // Common addEntity function can by used by any worksheet type; generally executed by the addWidget.
      $scope.addEntities = function (entityBns) {
        const params = {
          sourceBn: $scope.worksheetBn,
          targetBnCode: utilService.getBnCode($scope.worksheetBn),
          indexBn: entityBns[0], // TODO what should this be for batch assocs?!?
          associations: {
            addedAssocs: entityBns.map(bn => {
              return { "targetBn": bn, "relationshipIdentifier": null, relationshipType: 'RELATED_TO' }
            })
          },
          sectionBn: $scope.sectionBn
        };
        return tableService.updateAssociations(params);
      };

      // Common removeEntity function can be used by any worksheet type (usually triggered by sub-header UI)
      $scope.removeEntity = function (entity) {

        $scope.entityForRemoval = entity;

        // Open confirmation dialog before removing
        $scope.removeModal = $modal.open({
          backdrop: 'static',
          keyboard: true,
          templateUrl: '/b/js/src/bit.ng/worksheet/partials/remove-entity-modal.html',
          scope: $scope
        });

      };

      // Called from modal, when user confirms the remove action
      $scope.removeConfirmed = function () {
        $scope.removeModal.close('cancel');
        var params = {
          sourceBn: $scope.worksheetBn,
          targetBnCode: utilService.getBnCode($scope.worksheetBn),
          indexBn: $scope.worksheetBn,
          relationshipType: RelationshipTypes['RELATED_TO'],
          associations: {
            addedAssocs: [],
            removedAssocs: [
              {"targetBn": $scope.entityForRemoval.bn,
                "relationshipType": RelationshipTypes['RELATED_TO']}
            ]
          },
          sectionBn: $scope.sectionBn
        };
        $scope.entityForRemoval = null;
        tableService.updateAssociations(params);
        console.log('Throw worksheetEntityRemoved');
        $rootScope.$broadcast('worksheetEntityRemoved');
      };

      $scope.removeCancelled = function () {
        $scope.removeModal.close('cancel');
        $scope.entityForRemoval = null;
      };

      $scope.toggleSubheader = function () {
        $scope.subheaderState.active = !$scope.subheaderState.active;
      };

      $scope.requestResize = function () {
        relationalDiagramGraphDataService.requestWindowResize();
      };

      $scope.$on('worksheetOpenSubheader', function () {
        $scope.subheaderState.active = true;
      });
    }])
  .controller('DashboardWorksheetCtrl', ['$scope', 'commonWorksheetService', 'entityService',
    function ($scope, commonWorksheetService, entityService) {
      commonWorksheetService.getWorksheetEntity($scope.worksheetBn).then(function () {
        entityService.getBasicInfo($scope.worksheetBn).then(function (data) {
          $scope.worksheetBn = data.data.bn;
          $scope.title = data.data.displayableName;
          $scope.worksheetTypeName = data.data.insightType.bn;
          $scope.worksheetTemplate = commonWorksheetService.getTemplatePageByWorksheetInsightTypeBn(data.data.insightType.bn);
          $scope.worksheetName = $scope.worksheetTemplate.name;
          $scope.worksheetDashboardUrl = $scope.worksheetTemplate.dashboardUrl;
          $scope.browserNotSupported = commonWorksheetService.browserNotSupported($scope.worksheetTypeName);
        });
      });
    }])
;
