angular
  .module('barometerApp.worksheet')
  .factory('worksheetSubheaderState', worksheetSubheaderState);

worksheetSubheaderState.$inject = [];

function worksheetSubheaderState() {

  /**
   * Tip or trick: These values can be watched if you bind this object
   * into your local scope. For example:
   *
   * // Tie into subheaderState via service
   * $scope.subheaderState = worksheetSubheaderState;
   *
   * Per Pete Jones: "that's the SOP for shared state in a service".
   */
  return {
    active: false,
    mode: 'default'
  }
}