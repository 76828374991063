angular.module('barometerApp.fieldset')
    .service('fieldsetService', ['$http', 'errorHandlingService', '$rootScope',
      function ($http, errorHandlingService, $rootScope) {
        return {
          createFieldset: function (fieldsetJson) {
            var jsonString = angular.toJson(fieldsetJson);
            var requestData = jsonString;
            return $http({
              method: 'POST',
              url: '/b/api/fieldsets',
              headers: {'Content-Type': 'application/json'},
              data: requestData
            })
                .success(function (data, status, headers, config) {
                  //
                })
                .error(function (data, status, headers, config) {
                  errorHandlingService.handleGenericError(status, 'Failed to create Fieldset');
                });
          },

          getFieldset: function (fieldSetBn) {
            return $http({method: 'GET', url: '/b/api/fieldsets/' + fieldSetBn})
                .success(function (data, status, headers, config) {
                  //
                })
                .error(function (data, status, headers, config) {
                  console.log('error in fieldsetService.getFieldset for fieldSetBn: ' + fieldSetBn);
                });
          },

          getFieldsetBnsForLocation: function (locationBn, entityBn) {
            return $http({method: 'GET', url: '/b/api/entities/'+ entityBn + '/locations/' + locationBn})
                .success(function (data, status, headers, config) {
                  //
                })
                .error(function (data, status, headers, config) {
                  console.log('error in fieldsetService.getFieldsetsForEntityAndLocation for locationBn: ' + locationBn);
                });
          },

          getFieldsetForFieldsetBnAndEntityBn: function (fieldsetBn, entityBn, isRead) {
            return $http({
              method: 'GET',
              url: '/b/api/entities/' + entityBn + '/fieldsets/' + fieldsetBn+ '?read=' + isRead
            })
                .success(function (data, status, headers, config) {
                  //
                })
                .error(function (data, status, headers, config) {
                  console.log('error in fieldsetService.getFieldsetForEntity for fieldsetBN: ' + fieldsetBn + ' and entityBn: ' + entityBn);
                });
          },

          getFieldsetsForEntityType: function (entityType) {
            return $http({method: 'GET', url: '/b/api/entitytypes/' + entityType + '/fieldsets'})
                .success(function (data, status, headers, config) {
                  //
                })
                .error(function (data, status, headers, config) {
                  console.log('Error in fieldsetService.getFieldsetsForEntityType for entityType: ' + entityType);
                })
          },

          removeFieldsetFromLocation: function (fieldsetBn, locationBn) {
            return $http({
              method: 'DELETE',
              url: '/b/api/locations/' + locationBn + '/fieldsets/' + fieldsetBn
            })
                .success(function (data, status, headers, config) {
                  //
                })
                .error(function (data, status, headers, config) {
                  errorHandlingService.handleGenericError(status);
                })
          },

          updateFieldsetDefinition: function (fieldsetBn, updateJson) {
            var jsonString = angular.toJson(updateJson);
            var requestData = jsonString;
            return $http({
              method: 'PUT',
              url: '/b/api/fieldsets/' + fieldsetBn,
              headers: {'Content-Type': 'application/json'},
              data: requestData
            })
                .success(function (data, status, headers, config) {
                  //
                })
                .error(function (data, status, headers, config) {
                  errorHandlingService.handleGenericError(status, 'Failed to update Fieldset');
                });
          },

          updateFieldsetOrderForLocation: function (updateOrderJson, errorCallback) {
            var jsonString = angular.toJson(updateOrderJson);
            var requestData = jsonString;
            return $http({
              method: 'POST',
              url: '/b/api/locations/'+ updateOrderJson.locationBn +'/fieldsets',
              headers: {'Content-Type': 'application/json'},
              data: requestData
            })
                .success(function (data, status, headers, config) {
                  //
                })
                .error(function (data, status, headers, config) {
                  errorHandlingService.handleGenericError(status, 'Failed to update Fieldset order');
                  if (errorCallback) {
                    errorCallback(status);
                  }
                });
          },

          updateFieldsForFieldset: function (fieldSetBn, updateJson) {
            var jsonString = angular.toJson(updateJson);
            var updateFieldValuesReq = jsonString;
            return $http({
              method: 'POST',
              url: '/b/api/entities/'+ updateJson.entityBn +'/fieldsets/'+fieldSetBn,
              headers: {'Content-Type': 'application/json'},
              data: updateFieldValuesReq
            })
                .success(function (data, status, headers, config) {
                  //
                })
                .error(function (data, status, headers, config) {
                  console.log('error in fieldsetService.updateFieldsForFieldset for fieldSetBn: ' + fieldSetBn);
                });
          },

          deleteFieldset: function (fieldSetBn) {
            return $http({
              method: 'DELETE',
              url: '/b/api/fieldsets/'+ fieldSetBn
            })
                .success(function (data, status, headers, config) {
                })
                .error(function (data, status, headers, config) {
                  var msgString = "";
                  if (status === 409) {
                    var count = data.associationErrors['FLO'];
                    if (count > 1) {
                      msgString += String.format("Cannot delete this item. There are {0} locations that are currently using this custom field set.", count);
                    } else {
                      msgString += "Cannot delete this item. There is 1 location that is currently using this custom fieldset";
                    }

                    $rootScope.$broadcast('errorAlertMessage', msgString);
                    //localStorage.setItem("alerts", JSON.stringify({type: 'alert', msg: msgString }));
                  } else {
                    console.log('unhandled error in fieldSetService.deleteFieldSet bn: ' + bn);
                  }
                  window.location.href = urlService.getBaseUrl() + 'fieldsets';

                });
          }
        };
      }])

    .factory('fieldsetConstants', [function () {
      return {
        getFieldsetLayoutSectionsForEntityType: function (entityType) {
          switch (entityType) {
            case 'ACT':
              return [
                {
                  id: "act_basic_info",
                  header: "Basic Info",
                  locationBn: '001700000000'
                },
                {
                  id: "act_strategies",
                  header: "Actor-Strategies",
                  locationBn: '001700000053'
                },
                {
                  id: "act_demand",
                  header: "Actor-Demand",
                  locationBn: '001700000060'
                },
                {
                  id: "act_organization",
                  header: "Actor-Organizations",
                  locationBn: '001700000005'
                },
                {
                  id: "act_person",
                  header: "Actor-People",
                  locationBn: '0017000000M0'
                },
                {
                  id: "act_company",
                  header: "Actor-Companies",
                  locationBn: '001700000001'
                },
                {
                  id: "act_capability",
                  header: "Actor-Capabilities",
                  locationBn: '001700000002'
                },
                {
                  id: "act_product",
                  header: "Actor-Products",
                  locationBn: '001700000049'
                },
                {
                  id: "act_market",
                  header: "Actor-Markets",
                  locationBn: '001700000070'
                },
                {
                  id: "act_data",
                  header: "Actor-Data",
                  locationBn: '001700000003'
                },
                {
                  id: "act_connection",
                  header: "Actor-Connections",
                  locationBn: "0017000000M2"
                },
                {
                  id: "act_technology",
                  header: "Actor-Technologies",
                  locationBn: "0017000000M8"
                },
                {
                  id: "act_standard",
                  header: "Actor-Standards",
                  locationBn: "0017000000M6"
                },
                {
                  id: "act_skill",
                  header: "Actor-Skills",
                  locationBn: "0017000000M4"
                },
                {
                  id: "act_system",
                  header: "Actor-Systems",
                  locationBn: '001700000006'
                },
                {
                  id: "act_deployment",
                  header: "Actor-Deployments",
                  locationBn: '0017000000K9'
                },
                {
                  id: "act_document",
                  header: "Actor-Documents",
                  locationBn: '001700000004'
                }
              ];

            case 'CAP':
              return [
                {
                  id: "cap_basic_info",
                  header: "Basic Info",
                  locationBn: '001700000007'
                },
                {
                  id: "cap_arch",
                  header: "Capability Architecture",
                  locationBn: '00170000003M'
                },
                {
                  id: "cap_strategies",
                  header: "Capability-Strategies",
                  locationBn: '001700000054'
                },
                {
                  id: "cap_demand",
                  header: "Capability-Demand",
                  locationBn: '001700000061'
                },
                {
                  id: "cap_org",
                  header: "Capability-Organizations",
                  locationBn: '00170000004J'
                },
                {
                  id: "cap_people",
                  header: "Capability-People",
                  locationBn: '00170000000F'
                },
                {
                  id: "cap_company",
                  header: "Capability-Companies",
                  locationBn: '00170000004K'
                },
                {
                  id: "cap_actor",
                  header: "Capability-Actors",
                  locationBn: '001700000009'
                },
                {
                  id: "cap_product",
                  header: "Capability-Products",
                  locationBn: '001700000008'
                },
                {
                  id: "cap_market",
                  header: "Capability-Markets",
                  locationBn: '001700000071'
                },
                {
                  id: "cap_system",
                  header: "Capability-Systems",
                  locationBn: '00170000000G'
                },
                {
                  id: "cap_data",
                  header: "Capability-Data",
                  locationBn: '00170000000C'
                },
                {
                  id: "cap_connection",
                  header: "Capability-Connections",
                  locationBn: '00170000000B'
                },
                {
                  id: "cap_technology",
                  header: "Capability-Technologies",
                  locationBn: '00170000004H'
                },
                {
                  id: "cap_standard",
                  header: "Capability-Standards",
                  locationBn: '00170000004G'
                },
                {
                  id: "cap_skill",
                  header: "Capability-Skills",
                  locationBn: '00170000004F'
                },
                {
                  id: "cap_deployment",
                  header: "Capability-Deployments",
                  locationBn: '0017000000KB'
                },
                {
                  id: "cap_document",
                  header: "Capability-Documents",
                  locationBn: '00170000000D'
                }
              ];

            case 'CIR':
              return [
                  {
                    id: "cir_basic_info",
                    header: "Basic Info",
                    locationBn: "0017000000N3"
                  },
                  {
                    id: "collection_members",
                    header: "Collection Members",
                    locationBn: "0017000000N4"
                  },
                  {
                    id: "collection_strategies",
                    header: "Collection-Strategies",
                    locationBn: "0017000000N5"
                  },
                  {
                    id: "collection_demands",
                    header: "Collection-Demands",
                    locationBn: "0017000000N6"
                  },
                  {
                    id: "collection_organizations",
                    header: "Collection-Organizations",
                    locationBn: "0017000000N7"
                  },
                  {
                    id: "collection_companies",
                    header: "Collection-Companies",
                    locationBn: "0017000000N8"
                  },
                  {
                    id: "collection_actors",
                    header: "Collection-Actors",
                    locationBn: "0017000000N9"
                  },
                  {
                    id: "collection_capabilities",
                    header: "Collection-Capabilities",
                    locationBn: "0017000000NA"
                  },
                  {
                    id: "collection_products",
                    header: "Collection-Products",
                    locationBn: "0017000000NB"
                  },
                  {
                    id: "collection_markets",
                    header: "Collection-Markets",
                    locationBn: "0017000000NC"
                  },
                  {
                    id: "collection_data",
                    header: "Collection-Data",
                    locationBn: "0017000000ND"
                  },
                  {
                    id: "collection_systems",
                    header: "Collection-Systems",
                    locationBn: "0017000000NE"
                  },
                  {
                    id: "collection_connections",
                    header: "Collection-Connections",
                    locationBn: "0017000000NF"
                  },
                  {
                    id: "collection_technologies",
                    header: "Collection-Technologies",
                    locationBn: "0017000000NG"
                  },
                  {
                    id: "collection_standards",
                    header: "Collection-Standards",
                    locationBn: "0017000000NH"
                  },
                  {
                    id: "collection_skills",
                    header: "Collection-Skills",
                    locationBn: "0017000000NI"
                  },
                  {
                    id: "collection_deployments",
                    header: "Collection-Deployments",
                    locationBn: "0017000000NJ"
                  },
                  {
                    id: "collection_documents",
                    header: "Collection-Documents",
                    locationBn: "0017000000NK"
                  }
              ];

            case 'COM':
              return [
                {
                  id: "com_basic_info",
                  header: "Basic Info",
                  locationBn: '00170000000H'
                },
                {
                  id: "com_strategies",
                  header: "Company-Strategies",
                  locationBn: '001700000055'
                },
                {
                  id: "com_demand",
                  header: "Company-Demand",
                  locationBn: '001700000062'
                },
                {
                  id: "com_people",
                  header: "Company-People",
                  locationBn: '001700000045'
                },
                {
                  id: "com_capability",
                  header: "Company-Capabilities",
                  locationBn: '00170000004Q'
                },
                {
                  id: "com_product",
                  header: "Company-Products",
                  locationBn: '00170000004D'
                },
                {
                  id: "com_market",
                  header: "Company-Markets",
                  locationBn: '001700000072'
                },
                {
                  id: "com_organization",
                  header: "Company-Organizations",
                  locationBn: '0017000000MX'
                },
                {
                  id: "com_system",
                  header: "Company-Systems",
                  locationBn: '00170000000P'
                },
                {
                  id: "com_data",
                  header: "Company-Data",
                  locationBn: '00170000000M'
                },
                {
                  id: "com_connection",
                  header: "Company-Connections",
                  locationBn: '00170000000L'
                },
                {
                  id: "com_technology",
                  header: "Company-Technologies",
                  locationBn: '00170000000Q'
                },
                {
                  id: "com_skills",
                  header: "Company-Skills",
                  locationBn: '0017000000MA'
                },
                {
                  id: "com_standard",
                  header: "Company-Standards",
                  locationBn: '00170000000N'
                },
                {
                  id: "com_deployment",
                  header: "Company-Deployments",
                  locationBn: '0017000000KC'
                },
                {
                  id: "com_document",
                  header: "Company-Documents",
                  locationBn: '0017000000MW'
                }
              ];

            case 'CON':
              return [
                {
                  id: "con_basic_info",
                  header: "Basic Info",
                  locationBn: '00170000000R'
                },
                {
                  id: "con_strategies",
                  header: "Connection-Strategies",
                  locationBn: '001700000056'
                },
                {
                  id: "con_demand",
                  header: "Connection-Demand",
                  locationBn: '001700000063'
                },
                {
                  id: "con_organization",
                  header: "Connection-Organizations",
                  locationBn: '00170000004Z'
                },
                {
                  id: "con_people",
                  header: "Connection-People",
                  locationBn: '00170000000Z'
                },
                {
                  id: "con_actors",
                  header: "Connection-Actors",
                  locationBn: '0017000000M3'
                },
                {
                  id: "con_capability",
                  header: "Connection-Capabilities",
                  locationBn: '00170000000T'
                },
                {
                  id: "con_product",
                  header: "Connection-Products",
                  locationBn: '00170000007T'
                },
                {
                  id: "con_market",
                  header: "Connection-Markets",
                  locationBn: '001700000073'
                },
                {
                  id: "con_data",
                  header: "Connection-Data",
                  locationBn: '00170000000V'
                },
                {
                  id: "con_technologies",
                  header: "Connection-Technologies",
                  locationBn: '0017000000ME'
                },
                {
                  id: "con_standard",
                  header: "Connection-Standards",
                  locationBn: '001700000011'
                },
                {
                  id: "con_skills",
                  header: "Connection-Skills",
                  locationBn: '0017000000MC'
                },
                {
                  id: "con_deployment",
                  header: "Connection-Deployments",
                  locationBn: '0017000000KD'
                },
                {
                  id: "con_document",
                  header: "Connection-Documents",
                  locationBn: '00170000000X'
                }
              ];

            case 'DAT':
              return [
                {
                  id: "dat_basic_info",
                  header: "Basic Info",
                  locationBn: '001700000012'
                },
                {
                  id: "dat_architecture",
                  header: "Data Architecture",
                  locationBn: '00170000003N'
                },
                {
                  id: "dat_strategies",
                  header: "Data-Strategies",
                  locationBn: '001700000057'
                },
                {
                  id: "dat_demand",
                  header: "Data-Demand",
                  locationBn: '001700000064'
                },
                {
                  id: "dat_organization",
                  header: "Data-Organizations",
                  locationBn: '00170000004W'
                },
                {
                  id: "dat_people",
                  header: "Data-People",
                  locationBn: '001700000019'
                },
                {
                  id: "dat_company",
                  header: "Data-Companies",
                  locationBn: '00170000004V'
                },
                {
                  id: "dat_actor",
                  header: "Data-Actors",
                  locationBn: '001700000014'
                },
                {
                  id: "dat_capability",
                  header: "Data-Capabilities",
                  locationBn: '001700000015'
                },
                {
                  id: "dat_product",
                  header: "Data-Products",
                  locationBn: '00170000007V'
                },
                {
                  id: "dat_market",
                  header: "Data-Markets",
                  locationBn: '001700000074'
                },
                {
                  id: "dat_system",
                  header: "Data-Systems",
                  locationBn: '00170000003P'
                },
                {
                  id: "dat_connection",
                  header: "Data-Connections",
                  locationBn: '001700000016'
                },
                {
                  id: "dat_technologies",
                  header: "Data-Technologies",
                  locationBn: '0017000000MK'
                },
                {
                  id: "dat_standards",
                  header: "Data-Standards",
                  locationBn: '0017000000MI'
                },
                {
                  id: "dat_skills",
                  header: "Data-Skills",
                  locationBn: '0017000000MG'
                },
                {
                  id: "dat_deployment",
                  header: "Data-Deployments",
                  locationBn: '0017000000KF'
                },
                {
                  id: "dat_document",
                  header: "Data-Documents",
                  locationBn: '001700000018'
                }
              ];

            case 'DEM':
              return [
                {
                  id: "dem_basic_info",
                  header: "Basic Info",
                  locationBn: '001700000065'
                },
                {
                  id: "dem_architecture",
                  header: "Demand Architecture",
                  locationBn: '001700000066'
                },
                {
                  id: "dem_strategy",
                  header: "Demand-Strategy",
                  locationBn: '001700000067'
                },
                {
                  id: "dem_related_demand",
                  header: "Related Demands",
                  locationBn: '00170000006P'
                },
                {
                  id: "dem_organization",
                  header: "Demand-Organizations",
                  locationBn: '001700000068'
                },
                {
                  id: "dem_people",
                  header: "Demand-People",
                  locationBn: '001700000069'
                },
                {
                  id: "dem_company",
                  header: "Demand-Companies",
                  locationBn: '00170000006B'
                },
                {
                  id: "dem_actors",
                  header: "Demand-Actors",
                  locationBn: '00170000006C'
                },
                {
                  id: "dem_capability",
                  header: "Demand-Capabilities",
                  locationBn: '00170000006D'
                },
                {
                  id: "dem_product",
                  header: "Demand-Products",
                  locationBn: '00170000006F'
                },
                {
                  id: "dem_market",
                  header: "Demand-Markets",
                  locationBn: '001700000075'
                },
                {
                  id: "dem_system",
                  header: "Demand-Systems",
                  locationBn: '00170000006G'
                },
                {
                  id: "dem_data",
                  header: "Demand-Data",
                  locationBn: '00170000006H'
                },
                {
                  id: "dem_connection",
                  header: "Demand-Connections",
                  locationBn: '00170000006J'
                },
                {
                  id: "dem_technology",
                  header: "Demand-Technologies",
                  locationBn: '00170000006K'
                },
                {
                  id: "dem_standard",
                  header: "Demand-Standards",
                  locationBn: '00170000006L'
                },
                {
                  id: "dem_skill",
                  header: "Demand-Skills",
                  locationBn: '00170000006M'
                },
                {
                  id: "dem_deployment",
                  header: "Demand-Deployments",
                  locationBn: '0017000000KG'
                }
              ];

            case 'MKT':
              return [
                {
                  id: "mkt_basic_info",
                  header: "Basic Info",
                  locationBn: '00170000007M'
                },
                {
                  id: "mkt_architecture",
                  header: "Market Model",
                  locationBn: '00170000007N'
                },
                {
                  id: "mkt_strategies",
                  header: "Market-Strategies",
                  locationBn: '00170000007K'
                },
                {
                  id: "mkt_demand",
                  header: "Market-Demand",
                  locationBn: '00170000007D'
                },
                {
                  id: "mkt_organization",
                  header: "Market-Organizations",
                  locationBn: '00170000007G'
                },
                {
                  id: "mkt_people",
                  header: "Market-People",
                  locationBn: '00170000007H'
                },
                {
                  id: "mkt_company",
                  header: "Market-Companies",
                  locationBn: '001700000079'
                },
                {
                  id: "mkt_actor",
                  header: "Market-Actors",
                  locationBn: '001700000077'
                },
                {
                  id: "mkt_capability",
                  header: "Market-Capabilities",
                  locationBn: '001700000078'
                },
                {
                  id: "mkt_product",
                  header: "Market-Products",
                  locationBn: '00170000007J'
                },
                {
                  id: "mkt_system",
                  header: "Market-Systems",
                  locationBn: '00170000007L'
                },
                {
                  id: "mkt_data",
                  header: "Market-Data",
                  locationBn: '00170000007C'
                },
                {
                  id: "mkt_connection",
                  header: "Market-Connections",
                  locationBn: '00170000007B'
                },
                {
                  id: "mkt_technologies",
                  header: "Market-Technologies",
                  locationBn: '0017000000MQ'
                },
                {
                  id: "mkt_standards",
                  header: "Market-Standards",
                  locationBn: '0017000000MO'
                },
                {
                  id: "mkt_skills",
                  header: "Market-Skills",
                  locationBn: '0017000000MM'
                },
                {
                  id: "mkt_deployment",
                  header: "Market-Deployments",
                  locationBn: '0017000000KH'
                },
                {
                  id: "mkt_document",
                  header: "Market-Documents",
                  locationBn: '00170000007F'
                }
              ];

            case 'ORG':
              return [
                {
                  id: "org_basic_info",
                  header: "Basic Info",
                  locationBn: '00170000001C'
                },
                {
                  id: "org_chart",
                  header: "Organization Chart",
                  locationBn: '00170000001F'
                },
                {
                  id: "org_strategies",
                  header: "Organization-Strategies",
                  locationBn: '001700000058'
                },
                {
                  id: "org_demand",
                  header: "Organization-Demand",
                  locationBn: '00170000006Q'
                },
                {
                  id: "org_people",
                  header: "Organization-People",
                  locationBn: '00170000001H'
                },
                {
                  id: "org_actor",
                  header: "Organization-Actors",
                  locationBn: '00170000001D'
                },
                {
                  id: "org_capability",
                  header: "Organization-Capabilities",
                  locationBn: '00170000004R'
                },
                {
                  id: "org_company",
                  header: "Organization-Companies",
                  locationBn: '0017000000MY'
                },
                {
                  id: "org_product",
                  header: "Organization-Products",
                  locationBn: '001700000017'
                },
                {
                  id: "org_market",
                  header: "Organization-Markets",
                  locationBn: '00170000007P'
                },
                {
                  id: "org_system",
                  header: "Organization-Systems",
                  locationBn: '00170000001J'
                },
                {
                  id: "org_data",
                  header: "Organization-Data",
                  locationBn: '00170000001X'
                },
                {
                  id: "org_connection",
                  header: "Organization-Connections",
                  locationBn: '001700000050'
                },
                {
                  id: "org_technology",
                  header: "Organization-Technologies",
                  locationBn: '00170000004P'
                },
                {
                  id: "org_standards",
                  header: "Organization-Standards",
                  locationBn: '0017000000MU'
                },
                {
                  id: "org_skills",
                  header: "Organization-Skills",
                  locationBn: '0017000000MS'
                },
                {
                  id: "org_deployment",
                  header: "Organization-Deployments",
                  locationBn: '0017000000KJ'
                },
                {
                  id: "org_document",
                  header: "Organization-Documents",
                  locationBn: '00170000001G'
                }
              ];

            case 'PER':
              return [
                {
                  id: "per_basic_info",
                  header: "Basic Info",
                  locationBn: '00170000003X'
                },
                {
                  id: "per_strategies",
                  header: "Person-Strategies",
                  locationBn: '001700000059'
                },
                {
                  id: "per_demand",
                  header: "Person-Demand",
                  locationBn: '00170000006R'
                },
                {
                  id: "per_organization",
                  header: "Person-Organization",
                  locationBn: '001700000044'
                },
                {
                  id: "per_company",
                  header: "Person-Companies",
                  locationBn: '001700000024'
                },
                {
                  id: "per_actors",
                  header: "Person-Actors",
                  locationBn: '001700000024'
                },
                {
                  id: "per_capability",
                  header: "Person-Capabilities",
                  locationBn: '001700000043'
                },
                {
                  id: "per_product",
                  header: "Person-Products",
                  locationBn: '00170000007W'
                },
                {
                  id: "per_market",
                  header: "Person-Markets",
                  locationBn: '00170000007Q'
                },
                {
                  id: "per_system",
                  header: "Person-Systems",
                  locationBn: '00170000003Z'
                },
                {
                  id: "per_data",
                  header: "Person-Data",
                  locationBn: '00170000000W'
                },
                {
                  id: "per_connection",
                  header: "Person-Connection",
                  locationBn: '00170000004C'
                },
                {
                  id: "per_technology",
                  header: "Person-Technologies",
                  locationBn: '001700000040'
                },
                {
                  id: "per_standard",
                  header: "Person-Standards",
                  locationBn: '001700000041'
                },
                {
                  id: "per_skill",
                  header: "Person-Skills",
                  locationBn: '001700000042'
                },
                {
                  id: "per_deployment",
                  header: "Person-Deployments",
                  locationBn: '0017000000KK'
                },
                {
                  id: "per_document",
                  header: "Person-Documents",
                  locationBn: '00170000002G'
                }
              ];

            case 'PHY':
              return [
                {
                  id: "phy_basic_info",
                  header: "Basic Info",
                  locationBn: '0017000000JN'
                },
                {
                  id: "phy_strategies",
                  header: "Deployment-Strategies",
                  locationBn: '0017000000JQ'
                },
                {
                  id: "phy_demand",
                  header: "Deployment-Demand",
                  locationBn: '0017000000K8'
                },
                {
                  id: "phy_organization",
                  header: "Deployment-Organizations",
                  locationBn: '0017000000JR'
                },
                {
                  id: "phy_people",
                  header: "Deployment-People",
                  locationBn: '0017000000JS'
                },
                {
                  id: "phy_company",
                  header: "Deployment-Companies",
                  locationBn: '0017000000JT'
                },
                {
                  id: "phy_actor",
                  header: "Deployment-Actors",
                  locationBn: '0017000000JV'
                },
                {
                  id: "phy_capability",
                  header: "Deployment-Capabilities",
                  locationBn: '0017000000JW'
                },
                {
                  id: "phy_product",
                  header: "Deployment-Products",
                  locationBn: '0017000000JX'
                },
                {
                  id: "phy_market",
                  header: "Deployment-Markets",
                  locationBn: '0017000000K7'
                },
                {
                  id: "phy_data",
                  header: "Deployment-Data",
                  locationBn: '0017000000K0'
                },
                {
                  id: "phy_system",
                  header: "Deployment-Systems",
                  locationBn: '0017000000JZ'
                },
                {
                  id: "phy_conn",
                  header: "Deployment-Connection",
                  locationBn: '0017000000K1'
                },
                {
                  id: "phy_technology",
                  header: "Deployment-Technologies",
                  locationBn: '0017000000K2'
                },
                {
                  id: "phy_standard",
                  header: "Deployment-Standards",
                  locationBn: '0017000000K3'
                },
                {
                  id: "phy_skill",
                  header: "Deployment-Skills",
                  locationBn: '0017000000K4'
                },
                {
                  id: "phy_related_physical",
                  header: "Related Deployments",
                  locationBn: '0017000000K6'
                },
                {
                  id: "phy_document",
                  header: "Deployment-Documents",
                  locationBn: '0017000000K5'
                }
              ];

            case 'PRD':
              return [
                {
                  id: "prd_basic_info",
                  header: "Basic Info",
                  locationBn: '00170000001K'
                },
                {
                  id: "prd_strategies",
                  header: "Product-Strategies",
                  locationBn: '00170000005B'
                },
                {
                  id: "prd_demand",
                  header: "Product-Demand",
                  locationBn: '00170000006S'
                },
                {
                  id: "prd_organization",
                  header: "Product-Organizations",
                  locationBn: '00170000001L'
                },
                {
                  id: "prd_people",
                  header: "Product-People",
                  locationBn: '00170000001S'
                },
                {
                  id: "prd_company",
                  header: "Product-Companies",
                  locationBn: '00170000001P'
                },
                {
                  id: "prd_actor",
                  header: "Product-Actors",
                  locationBn: '00170000001M'
                },
                {
                  id: "prd_capability",
                  header: "Product-Capabilities",
                  locationBn: '00170000001N'
                },
                {
                  id: "prd_market",
                  header: "Product-Markets",
                  locationBn: '00170000007R'
                },
                {
                  id: "prd_system",
                  header: "Product-Systems",
                  locationBn: '00170000001T'
                },
                {
                  id: "prd_data",
                  header: "Product-Data",
                  locationBn: '001700000082'
                },
                {
                  id: "prd_conn",
                  header: "Product-Connection",
                  locationBn: '001700000085'
                },
                {
                  id: "prd_technology",
                  header: "Product-Technologies",
                  locationBn: '001700000086'
                },
                {
                  id: "prd_standard",
                  header: "Product-Standards",
                  locationBn: '001700000084'
                },
                {
                  id: "prd_skill",
                  header: "Product-Skills",
                  locationBn: '001700000083'
                },
                {
                  id: "prd_deployment",
                  header: "Product-Deployments",
                  locationBn: '0017000000KL'
                },
                {
                  id: "prd_document",
                  header: "Product-Documents",
                  locationBn: '00170000001Q'
                }
              ];

            case 'SKI':
              return [
                {
                  id: "ski_basic_info",
                  header: "Basic Info",
                  locationBn: '001700000022'
                },
                {
                  id: "ski_strategies",
                  header: "Skill-Strategies",
                  locationBn: '00170000005C'
                },
                {
                  id: "ski_demand",
                  header: "Skill-Demand",
                  locationBn: '00170000006T'
                },
                {
                  id: "ski_organizations",
                  header: "Skill-Organizations",
                  locationBn: '0017000000MT'
                },
                {
                  id: "ski_people",
                  header: "Skill-People",
                  locationBn: '001700000027'
                },
                {
                  id: "ski_companies",
                  header: "Skill-Companies",
                  locationBn: '0017000000MB'
                },
                {
                  id: "ski_actors",
                  header: "Skill-Actors",
                  locationBn: '0017000000M5'
                },
                {
                  id: "ski_capability",
                  header: "Skill-Capabilities",
                  locationBn: '00170000004L'
                },
                {
                  id: "ski_product",
                  header: "Skill-Products",
                  locationBn: '00170000007X'
                },
                {
                  id: "ski_markets",
                  header: "Skill-Markets",
                  locationBn: '0017000000MN'
                },
                {
                  id: "ski_data",
                  header: "Skill-Data",
                  locationBn: '0017000000MH'
                },
                {
                  id: "ski_system",
                  header: "Skill-Systems",
                  locationBn: '001700000029'
                },
                {
                  id: "ski_connections",
                  header: "Skill-Connections",
                  locationBn: '0017000000MD'
                },
                {
                  id: "ski_technology",
                  header: "Skill-Technologies",
                  locationBn: '00170000002B'
                },
                {
                  id: "ski_standard",
                  header: "Skill-Standards",
                  locationBn: '001700000028'
                },
                {
                  id: "ski_deployment",
                  header: "Skill-Deployments",
                  locationBn: '0017000000KM'
                },
                {
                  id: "ski_document",
                  header: "Skill-Documents",
                  locationBn: '001700000025'
                }
              ];

            case 'STA':
              return [
                {
                  id: "sta_basic_info",
                  header: "Basic Info",
                  locationBn: '00170000002C'
                },
                {
                  id: "sta_strategies",
                  header: "Standard-Strategies",
                  locationBn: '00170000005D'
                },
                {
                  id: "sta_demand",
                  header: "Standard-Demand",
                  locationBn: '00170000006V'
                },
                {
                  id: "sta_organizations",
                  header: "Standard-Organizations",
                  locationBn: '0017000000MV'
                },
                {
                  id: "sta_people",
                  header: "Standard-People",
                  locationBn: '00170000002K'
                },
                {
                  id: "sta_company",
                  header: "Standard-Companies",
                  locationBn: '00170000002D'
                },
                {
                  id: "sta_actors",
                  header: "Standard-Actors",
                  locationBn: '0017000000M7'
                },
                {
                  id: "sta_capability",
                  header: "Standard-Capabilities",
                  locationBn: '00170000004M'
                },
                {
                  id: "sta_product",
                  header: "Standard-Products",
                  locationBn: '00170000007Z'
                },
                {
                  id: "sta_market",
                  header: "Standard-Market",
                  locationBn: '0017000000MP'
                },
                {
                  id: "sta_data",
                  header: "Standard-Data",
                  locationBn: '0017000000MJ'
                },
                {
                  id: "sta_system",
                  header: "Standard-Systems",
                  locationBn: '00170000002M'
                },
                {
                  id: "sta_connection",
                  header: "Standard-Connections",
                  locationBn: '00170000002F'
                },
                {
                  id: "sta_technology",
                  header: "Standard-Technologies",
                  locationBn: '00170000002N'
                },
                {
                  id: "sta_skill",
                  header: "Standard-Skills",
                  locationBn: '00170000002L'
                },
                {
                  id: "sta_deployment",
                  header: "Standard-Deployments",
                  locationBn: '0017000000KN'
                },
                {
                  id: "sta_document",
                  header: "Standard-Documents",
                  locationBn: '00170000002H'
                }
              ];

            case 'STR':
              return [
                {
                  id: "str_basic_info",
                  header: "Basic Info",
                  locationBn: '00170000005F'
                },
                {
                  id: "str_architecture",
                  header: "Strategy Architecture",
                  locationBn: '00170000005G'
                },
                {
                  id: "str_related_strategy",
                  header: "Related Strategies",
                  locationBn: '00170000005L'
                },
                {
                  id: "str_demand",
                  header: "Strategy-Demands",
                  locationBn: '00170000005X'
                },
                {
                  id: "str_organization",
                  header: "Strategy-Organizations",
                  locationBn: '00170000005H'
                },
                {
                  id: "str_people",
                  header: "Strategy-People",
                  locationBn: '00170000005M'
                },
                {
                  id: "str_company",
                  header: "Strategy-Companies",
                  locationBn: '00170000005J'
                },
                {
                  id: "str_actors",
                  header: "Strategy-Actors",
                  locationBn: '00170000005P'
                },
                {
                  id: "str_capability",
                  header: "Strategy-Capabilities",
                  locationBn: '00170000005K'
                },
                {
                  id: "str_product",
                  header: "Strategy-Products",
                  locationBn: '00170000005Q'
                },
                {
                  id: "str_market",
                  header: "Strategy-Markets",
                  locationBn: '00170000007S'
                },
                {
                  id: "str_system",
                  header: "Strategy-Systems",
                  locationBn: '00170000005N'
                },
                {
                  id: "str_data",
                  header: "Strategy-Data",
                  locationBn: '00170000005R'
                },
                {
                  id: "str_connection",
                  header: "Strategy-Connections",
                  locationBn: '00170000005S'
                },
                {
                  id: "str_technology",
                  header: "Strategy-Technologies",
                  locationBn: '00170000005W'
                },
                {
                  id: "str_standard",
                  header: "Strategy-Standards",
                  locationBn: '00170000005T'
                },
                {
                  id: "str_skill",
                  header: "Strategy-Skills",
                  locationBn: '00170000005V'
                },
                {
                  id: "str_deployments",
                  header: "Strategy-Deployments",
                  locationBn: '0017000000KP'
                }
              ];

            case 'SYS':
              return [
                {
                  id: "sys_basic_info",
                  header: "Basic Info",
                  locationBn: '00170000002P'
                },
                {
                  id: "sys_architecture",
                  header: "System Architecture",
                  locationBn: '00170000002R'
                },
                {
                  id: "sys_organization",
                  header: "System-Organizations",
                  locationBn: '00170000002W'
                },
                {
                  id: "sys_people",
                  header: "System-People",
                  locationBn: '00170000002X'
                },
                {
                  id: "sys_company",
                  header: "System-Companies",
                  locationBn: '001700000048'
                },
                {
                  id: "sys_actors",
                  header: "System-Actors",
                  locationBn: '00170000002Q'
                },
                {
                  id: "sys_capability",
                  header: "System-Capabilities",
                  locationBn: '00170000002S'
                },
                {
                  id: "sys_product",
                  header: "System-Products",
                  locationBn: '001700000030'
                },
                {
                  id: "sys_market",
                  header: "System-Markets",
                  locationBn: '001700000076'
                },
                {
                  id: "sys_data",
                  header: "System-Data",
                  locationBn: '00170000002V'
                },
                {
                  id: "sys_connection",
                  header: "System-Connections",
                  locationBn: '00170000002T'
                },
                {
                  id: "sys_technology",
                  header: "System-Technologies",
                  locationBn: '001700000033'
                },
                {
                  id: "sys_standard",
                  header: "System-Standards",
                  locationBn: '001700000032'
                },
                {
                  id: "sys_skill",
                  header: "System-Skills",
                  locationBn: '001700000031'
                },
                {
                  id: "sys_deployment",
                  header: "System-Deployments",
                  locationBn: '0017000000KQ'
                },
                {
                  id: "sys_document",
                  header: "System-Documents",
                  locationBn: '001700000047'
                }
              ];

            case 'TAG':
              return [
                {
                  id: "tag_basic_info",
                  header: "Basic Info",
                  locationBn: '001700000034'
                },
                {
                  id: "tag_strategies",
                  header: "Tag-Strategies",
                  locationBn: '001700000052'
                },
                {
                  id: "tag_demand",
                  header: "Tag-Demand",
                  locationBn: '00170000006X'
                },
                {
                  id: "tag_company",
                  header: "Tag-Companies",
                  locationBn: '001700000035'
                },
                {
                  id: "tag_actor",
                  header: "Tag-Actors",
                  locationBn: '00170000003S'
                },
                {
                  id: "tag_capability",
                  header: "Tag-Capabilities",
                  locationBn: '00170000003T'
                },
                {
                  id: "tag_product",
                  header: "Tag-Products",
                  locationBn: '00170000003W'
                },
                {
                  id: "tag_system",
                  header: "Tag-Systems",
                  locationBn: '00170000003Q'
                },
                {
                  id: "tag_data",
                  header: "Tag-Data",
                  locationBn: '00170000003V'
                },
                {
                  id: "tag_connection",
                  header: "Tag-Connections",
                  locationBn: '00170000003R'
                },
                {
                  id: "tag_technology",
                  header: "Tag-Technologies",
                  locationBn: '001700000036'
                },
                {
                  id: "tag_standard",
                  header: "Tag-Standards",
                  locationBn: '001700000010'
                },
                {
                  id: "tag_skill",
                  header: "Tag-Skills",
                  locationBn: '00170000003L'
                },
                {
                  id: "tag_deployment",
                  header: "Tag-Deployments",
                  locationBn: '0017000000KR'
                },
                {
                  id: "tag_document",
                  header: "Tag-Documents",
                  locationBn: '001700000037'
                }
              ];

            case 'TEC':
              return [
                {
                  id: "tec_basic_info",
                  header: "Basic Info",
                  locationBn: '001700000038'
                },
                {
                  id: "tec_strategies",
                  header: "Technology-Strategies",
                  locationBn: '001700000051'
                },
                {
                  id: "tec_demand",
                  header: "Technology-Demand",
                  locationBn: '00170000006Z'
                },
                {
                  id: "tec_organization",
                  header: "Technology-Organizations",
                  locationBn: '00170000004S'
                },
                {
                  id: "tec_people",
                  header: "Technology-People",
                  locationBn: '00170000003D'
                },
                {
                  id: "tec_company",
                  header: "Technology-Companies",
                  locationBn: '001700000039'
                },
                {
                  id: "tec_actors",
                  header: "Technology-Actors",
                  locationBn: '0017000000M9'
                },
                {
                  id: "tec_capability",
                  header: "Technology-Capabilities",
                  locationBn: '00170000004N'
                },
                {
                  id: "tec_product",
                  header: "Technology-Products",
                  locationBn: '001700000081'
                },
                {
                  id: "tec_market",
                  header: "Technology-Marketes",
                  locationBn: '0017000000MR'
                },
                {
                  id: "tec_data",
                  header: "Technology-Data",
                  locationBn: '0017000000ML'
                },
                {
                  id: "tec_system",
                  header: "Technology-Systems",
                  locationBn: '00170000003K'
                },
                {
                  id: "tec_connections",
                  header: "Technology-Connections",
                  locationBn: '0017000000MF'
                },
                {
                  id: "tec_standard",
                  header: "Technology-Standards",
                  locationBn: '00170000003J'
                },
                {
                  id: "tec_skill",
                  header: "Technology-Skills",
                  locationBn: '00170000003H'
                },
                {
                  id: "tec_deployment",
                  header: "Technology-Deployments",
                  locationBn: '0017000000KS'
                },
                {
                  id: "tec_document",
                  header: "Technology-Documents",
                  locationBn: '00170000003C'
                }
              ]
          }
        }
      }
    }])

;
