angular
  .module('barometerApp.relationalDiagram')
  .factory('relationalDiagramSidebarModelService', relationalDiagramSidebarModelService);

relationalDiagramSidebarModelService.$inject = [
  '$q',
  '$timeout',
  'bitConstants',
  'entityService',
  'optionListService',
  'utilService'
];

/**
 * Refactored from RelationalDiagramSidebarController.
 */
function relationalDiagramSidebarModelService($q,
                                              $timeout,
                                              bitConstants,
                                              entityService,
                                              optionListService,
                                              utilService) {

  /**
   * Export my public functions.
   */
  return {
    buildDisplayDataForAssoc: buildDisplayDataForAssoc,
    buildDisplayDataForEntity: buildDisplayDataForEntity,
    buildDisplayDataForGroup: buildDisplayDataForGroup,
    // TODO Made public as an expediency. Clean up.
    getEntity: getEntity
  };

  // GLOSSARY
  // displayData: Carries info about the current on-screen selection (assoc | entity | group).
  //  Carries two types of info:
  //  - Model and meta-data state for UI management.
  //  - Distinct properties for display in the right-hand side-bar.
  // displayData.bn: The BN of the selected entity node.
  // displayData.children: Used only when selection is a group.
  //  An array of node BNs that belong to the group.
  // displayData.childrenName: Used only when selection is a group.
  //  An array of display names for each entity in the group.
  // displayData.entityType: (holds BN code, probably?)
  // displayData.parentId
  // displayType: (assoc, entity, group).

  //---------------------------------------
  // PRIVATE FUNCTIONS
  //---------------------------------------

  /**
   * This is and should be a private function.
   */
  function setStringFromObject(field, obj) {
    obj[field] = obj[field] ? obj[field] : '-- --';
    return obj[field]
  }

  /**
   * This is and should be a private function.
   */
  function setStringFromProperty(field, prop, obj) {
    obj[field] = obj[field] ? obj[field][prop] : '-- --';
    return obj[field];
  }

  /**
   * This is and should be a private function.
   */
  function setDelimitedStringFromArray(array, delimiter) {
    var str = '';
    var len = array.length;
    if (array.length <= 0) {
      return '-- --'
    }
    for (var i = 0; i < len; i++) {
      if (i === (len - 1)) {
        str = str + array[i].name;
      } else {
        str = str + array[i].name + delimiter;
      }
    }
    return str;
  }

  /**
   * This is and should be a private function.
   */
  function setUrl(entityType, bn) {
    var entityData = bitConstants.getEntityTypeForEntityTypeCode(entityType);
    if (entityData.typeCode === 'DAT') {
      entityData.displayName = 'datum';
    }
    if (entityData) {
      var bnCode = EntityTypes[entityData.typeCode].bnCode;
      return '/b/' + BnCodeToUrl[bnCode] + '/' + bn;
    }
    return null;
  }

  /**
   * This is and should be a private function.
   */
  function isDataListItem(qualifier) {
    return utilService.isBn(qualifier) &&
      utilService.getBnCode(obj.qualifier) === '0M';
  }

  /**
   * This is and should be a private function.
   *
   * @param dataObject The thing we're setting fields on.
   * @param displayableProperties A map of field-to-property.
   */
  function setDisplayableFields(dataObject, displayableProperties) {
    //
    for (var objectKey in dataObject) {
      for (var fieldIndex in displayableProperties) {
        if (displayableProperties[fieldIndex].field == objectKey) {
          if (displayableProperties[fieldIndex].property == null) {
            dataObject[objectKey] = setStringFromObject(displayableProperties[fieldIndex].field, dataObject);
          } else if (displayableProperties[fieldIndex].property == '_ARRAY') {
            dataObject[objectKey] = setDelimitedStringFromArray(dataObject[objectKey], ", ");
          } else {
            dataObject[objectKey] = setStringFromProperty(
              displayableProperties[fieldIndex].field, displayableProperties[fieldIndex].property, dataObject);
          }
        }
      }

    }
    dataObject.href = setUrl(dataObject.entityDiscriminator, dataObject.bn);
  }

  /**
   * @param obj
   * @param assocData
   * @param qualifierDeferred Our work may be sync or async. Either way, use this to signal we're done.
   */
  function resolveQualifier(obj, assocData, qualifierDeferred) {
    //
    //console.log("resolveQualifier");
    //
    if (isDataListItem(obj.qualifier)) {
      // Since we have to dial out, our work is async ...
      optionListService.getOptionListItemByBn(obj.qualifier)
        .then(function (data) {
          //console.log("got OptionListItemByBn");
          assocData.qualifier = data.data.displayableName;
          assocData.qualifierBn = obj.qualifier;
          // Signal that our async work is done.
          qualifierDeferred.resolve();
        });
    } else {
      // We already have our answer. No dial out.
      assocData.qualifier = obj.qualifier;
      // Signal that our (not-async) work is done.
      qualifierDeferred.resolve();
    }
  }

  /**
   * @param cyData Data from the diagram's selected edge object, i.e. edge.data().
   * @param resultCallback We're gonna defer a bunch of stuff here, so the result will be async.
   */
  function collectAssocData(cyData, resultCallback) {
    //
    //console.log("setAssocData");
    //
    var assocData = {};
    var qualifierDeferred = $q.defer();
    var targetDeferred = $q.defer();
    var sourceDeferred = $q.defer();
    //
    assocData.relationship = utilService.titleCaseString(cyData.pathType);
    // Set the qualifier value.
    resolveQualifier(cyData, assocData, qualifierDeferred);
    // Set the source node.
    getEntity(cyData.source, function (source) {
      //console.log("got source");
      assocData.source = source;
      assocData.sourceLink = setUrl(source.entityDiscriminator, source.bn);
      sourceDeferred.resolve();
    });
    // Set the target node.
    getEntity(cyData.target, function (target) {
      //console.log("got target");
      assocData.target = target;
      assocData.targetLink = setUrl(target.entityDiscriminator, target.bn);
      targetDeferred.resolve();
    });
    //
    $q.all([targetDeferred.promise, sourceDeferred.promise, qualifierDeferred.promise])
      .then(function () {
        //console.log("resolved all setAssocData promises");
        //console.log("assocData=" + assocData);
        return resultCallback(null, assocData);
      });
  };

  /**
   * BARO-18815: Changed from scoped to private. Re-tested all inbound calls to this function.
   */
  function collectEntityData(entity) {
    // TODO I see this fire over and over again. Can some of this be one-time state?
    var dataObject = {};
    var displayableProperties = [
      {field: 'acronym', property: null},                    // System Specific property
      {field: 'aliases', property: '_ARRAY'},                // System Specific property
      {field: 'bn', property: null},
      {field: 'businessValue', property: 'name'},              // Strategy Specific property
      {field: 'contentStatus', property: 'displayableName'},
      {field: 'connectionDataStructure', property: 'name'},    // Connection Specific property
      {field: 'connectionFrequency', property: 'name'},        // Connection Specific property
      {field: 'connectionPattern', property: 'name'},          // Connection Specific property
      {field: 'connectionTransport', property: 'name'},        // Connection Specific property
      {field: 'connectionType', property: 'name'},             // Connection Specific property
      {field: 'dataType', property: 'name'},                   // Data Specific property
      {field: 'displayableName', property: null},
      {field: 'domain', property: 'name'},
      {field: 'emailAddress', property: null},               // People Specific property
      {field: 'enterpriseApproval', property: 'name'},
      {field: 'enterpriseApproved', property: null},
      {field: 'entityDiscriminator', property: null},
      {field: 'externalId', property: null},
      {field: 'fundingTypes', property: '_ARRAY'},           // Demand Specific property
      {field: 'investmentScale', property: 'name'},          // Strategy Specific property
      {field: 'impactProfiles', property: '_ARRAY'},         // Strategy Specific property
      {field: 'lifecycleState', property: 'name'},
      {field: 'riskLevel', property: 'name'},                // Strategy Specific property
      {field: 'parent', property: 'displayableName'},        // Organization Specific property
      {field: 'priority', property: 'name'},
      {field: 'scheduleStatus', property: 'name'},           // Demand Specific property
      {field: 'sourceConnectionFormat', property: 'name'},   // Connection Specific property
      {field: 'standardType', property: 'name'},             // Standard Specific property
      {field: 'systemPriority', property: 'name'},           // System Specific property
      {field: 'systemType', property: 'name'},               // System Specific property
      {field: 'systemVersion', property: null},              // System Specific property
      {field: 'targetConnectionFormat', property: 'name'},   // Connection Specific property
      {field: 'technologyType', property: 'name'},           // Technology Specific property
      {field: 'technologyVersion', property: null},          // Technology Specific property
      {field: 'type', property: 'name'},
      {field: 'urgency', property: 'name'},                  // Strategy Specific property
      {field: 'valueHorizon', property: 'name'},             // Strategy Specific property
      {field: 'valueProfiles', property: '_ARRAY'}           // Strategy Specific property
    ];

    var entityPropertyKeys = Object.keys(entity);

    for (var i = 0; i < entityPropertyKeys.length; i++) {
      var key = entityPropertyKeys[i],
        value = entity[key];
      for (var l = 0; l < displayableProperties.length; l++) {
        if (key === displayableProperties[l].field) {
          dataObject[key] = value;
        }
      }
    }
    setDisplayableFields(dataObject, displayableProperties);

    return dataObject;
  };

  //---------------------------------------
  // PUBLIC FUNCTIONS
  //---------------------------------------

  /**
   * TODO Made public as an expediency. Clean up.
   *
   * BARO-18815: Changed from scoped to private. Re-tested all inbound calls to this function.
   */
  function getEntity(bn, callback) {
    $timeout(function () {
      entityService.getBasicInfo(bn)
        .then(function (data) {
          return callback(data.data);
        });
    });
  };

  /**
   * @param cyData Data from the diagram's selected edge object, i.e. edge.data().
   * @param node Superfluous. Not used. Here to match similar functional signatures.
   * @param resultCallback We're gonna defer a bunch of stuff here, so the result will be async.
   */
  function buildDisplayDataForAssoc(cyData, node, resultCallback) {
    collectAssocData(cyData, resultCallback);
  }

  /**
   * @param obj Parameter obj is a bIT entity.
   * @param node Parameter node is a cyto node, data from which has already been used to obtain obj.
   */
  function buildDisplayDataForEntity(obj, node) {
    //
    var myDisplayData = collectEntityData(obj);
    if (node && node.parent() && node.parent().data()) {
      myDisplayData.parentId = node.parent().data().id;
    }
    //
    return myDisplayData;
  }

  /**
   * @param obj Parameter obj is the cyto node for the group.
   * @param node Parameter node is also the same cyto node for the group.
   */
  function buildDisplayDataForGroup(obj, node) {
    //
    var myDisplayData = node.data();
    myDisplayData.childrenName = [];
    if (node && node.parent() && node.parent().data()) {
      myDisplayData.parentId = node.parent().data().id;
    }
    myDisplayData.children.forEach( id => {
      const isEntity = utilService.isBn(id);

      // Child is an entity, get the name from the backend
      if (isEntity) {
        entityService.getBasicInfo(id).then(function (data) {
          //For some reason there are two instances of the controller. So when the click event get broadcast
          //It gets picked up twice (once for each instance of the controller). This means that the getbasicInfo is called twice
          //Since it is a callback, the second call happens before the callback returns making it possible to have the entries in the list twice.
          //Therefore check if the entry is already in the list.
          if (myDisplayData.childrenName.indexOf(data.data.name) == -1) {
            myDisplayData.childrenName.push(data.data.name);
          }
        })
      }

      // Child is a group! Just display the Group ID
      else {
        myDisplayData.childrenName.push(`${id} (Group)`);
      }
    });
    return myDisplayData;
  }
}
