angular.module('barometerApp.priorityEntities')
  .service('priorityEntitiesService', ['$http', 'errorHandlingService', '$q', 'alertService', '$timeout', 'localStorageService',  '$rootScope', 'tenantService', 'redux',
    function ($http, errorHandlingService, $q, alertService, $timeout, localStorageService, $rootScope, tenantService, redux) {

      const LS_KEY_PERSONAL_PRIORITY_ENTITIES = 'personal_priority_entities';
      const LS_KEY_USE_PERSONAL_PRIORITY_ENTITIES_FLAG = 'use_personal_priority_entities';
      const { store, selectors } = redux;
      const { settings } = selectors;
      return {

        // If listening for Priority Entities in redux, we have to take into consideration that the store
        // gets populated asynchronously, therefore we can not guarantee that we'll know what the entities
        // are before the controllers that need them initially mount. By creating a listener,
        // the controllers can listen for changes to the redux state use the callback argument
        // to
        listenForPriorityEntities: function(cb) {
          if (this.getUsePersonalPriorityEntitiesFlag()) {
            cb(this.getPersonalPriorityEntities());
            return () => {};
          }
          return store.selectAndListen(settings.getPriorityEntities, settingValues => {
            const entityTypes = settingValues.map(({ value }) => value);
            $timeout(() => {
              cb(entityTypes);
            });
          });
        },

        // NOTE: we collect both Tenant-level and User-level (personal) priority entity settings
        // User-level override Tenant-level, if they are present
        // For general app usage, getPriorityEntities returns whichever entities actually apply
        // Return whichever priority entities the user has selected
        getPriorityEntities: function () {
          if (this.getUsePersonalPriorityEntitiesFlag()) {
            return this.getPersonalPriorityEntities();
          } else {
            return this.getTenantPriorityEntities();
          }
        },

        // Returns array of 3-letter entity typecodes, eg ['SYS','CAP','TEC']
        // For convenience, tenant priority entities are set as global variable
        getTenantPriorityEntities: function () {
          const settingValues = settings.getPriorityEntities(store.getState()) || [];
          return settingValues.map(({ value }) => value);
        },

        // Returns array of 3-letter entity typecodes, eg ['SYS','CAP','TEC']
        // Stored only in localStorage
        getPersonalPriorityEntities: function () {
          var saved = localStorageService.get(LS_KEY_PERSONAL_PRIORITY_ENTITIES);
          if (saved) {
            return saved;
          }
          return [];
        },

        // Takes array of 3-letter entity typecodes, eg ['SYS','CAP','TEC']
        // Stored only in localStorage
        updatePersonalPriorityEntities: function (entityTypeCodeArray) {
          localStorageService.set(LS_KEY_PERSONAL_PRIORITY_ENTITIES, entityTypeCodeArray);
          $rootScope.$broadcast('priorityEntitiesChanged', this.getPriorityEntities());
        },

        // Flag to determine if user would like to use Personal (true) vs Tenant (false/empty)
        getUsePersonalPriorityEntitiesFlag: function () {
          var saved = localStorageService.get(LS_KEY_USE_PERSONAL_PRIORITY_ENTITIES_FLAG);
          return saved && saved === 'true';
        },

        // Flag to determine if user would like to use Personal (true) vs Tenant (false/empty)
        setUsePersonalPriorityEntitiesFlag: function (boolean) {
          localStorageService.set(LS_KEY_USE_PERSONAL_PRIORITY_ENTITIES_FLAG, boolean);
          $rootScope.$broadcast('priorityEntitiesChanged', this.getPriorityEntities());
        },
        isPriorityEntityType: function (threeLetterTypeCode) {
          if (tenantService.isAnAuthorizedEntityTypeCode(threeLetterTypeCode)) {
            return this.getPriorityEntities().indexOf(threeLetterTypeCode) !== -1;
          } else return false;
        },

        isTenantPriorityEntityType: function (threeLetterTypeCode) {
          if (tenantService.isAnAuthorizedEntityTypeCode(threeLetterTypeCode)) {
            return this.getTenantPriorityEntities().indexOf(threeLetterTypeCode) !== -1;
          } else return false;
        },

        isPersonalPriorityEntityType: function (threeLetterTypeCode) {
          if (tenantService.isAnAuthorizedEntityTypeCode(threeLetterTypeCode)) {
            return this.getPersonalPriorityEntities().indexOf(threeLetterTypeCode) !== -1;
          } else return false;
        },

        displayApplicableWarnings: function (priorityEntitiesModel) {
          var selectedEntities = _.filter(priorityEntitiesModel, { selected: true });
          if (selectedEntities.length === 0) {
            alertService.clearAllAlerts();
            alertService.addWarningAlert('We recommend selecting at least 3 Priority Entities. <br> When no Priority Entities are selected, all entities are considered Priority.')
          } else if (selectedEntities.length < 3) {
            alertService.clearAllAlerts();
            alertService.addWarningAlert('We recommend selecting at least 3 Priority Entities.')
          } else {
            $timeout(function () {
              alertService.clearAllAlerts();
            }, 2000);
          }
        }
      }
    }])
;
