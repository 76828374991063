/**
 * Use controllers to:
 * Set up the initial state of a scope object (data, aka the Model, incl. promise functions).
 * Add behavior to the scope object (behavioral functions).
 *
 * Do not use controllers for:
 * Any kind of DOM manipulation.
 * Input formatting.
 * Output filtering.
 * To run stateless or stateful code shared across controllers.
 * To instantiate or manage the life-cycle of other components.
 *
 * You can associate controllers with scope objects implicitly via the ngController directive or $route service.
 *
 * This file tries to follow John Papa's Angular 1 style guide (adjusted to our AngularJS version).
 *
 * https://github.com/johnpapa/angular-styleguide/blob/master/a1/README.md
 */
angular
  .module('barometerApp.taskManager')
  .controller('TaskManagerController', TaskManagerController);

TaskManagerController.$inject = ['$scope', 'simpleTableUtilityService', 'taskManagerDataService', 'taskAssignmentDataService', 'taskManagerUtilityService', 'optionListService'];

function TaskManagerController($scope, simpleTableUtilityService, taskManagerDataService, taskAssignmentDataService, taskManagerUtilityService, optionListService) {

  // Shorthand aliases.
  var taskService = taskManagerDataService;
  var assignService = taskAssignmentDataService;
  var tableUtil = simpleTableUtilityService;
  var taskUtil = taskManagerUtilityService;

  /**
   * Standard Search Filter (name, etc.): SIMPLE_TEXT_SEARCH.
   */
  var filter1 = {
    displayName: "Search",
    hide: false,
    id: "filter1", // Use a Filter BN here if it makes sense.
    key: "simpleTextSearchKey",
    searchString: "", // easedInput will push values here.
    type: "SIMPLE_TEXT_SEARCH"
  };

  /**
   * Prepare requestModel to filter by SearchString.
   */
  function applyFilter1(myRequestModel, mySearchString) {
    //
    myRequestModel.filterBy.searchString = null;
    //
    if (mySearchString != null && mySearchString != 'undefined') {
      myRequestModel.filterBy.propertyFilters = [
        {propertyName: "description", value: mySearchString}
      ];
    }
  }

  /**
   * @param myPage The page number for which this update wa requested.
   * @param taskData A response from getTasks().
   */
  function updateAssignments(myPage, taskData) {
    //
    var myRequestModel = assignService.getRequestModel();
    var taskBns = taskUtil.getTaskBns(taskData);
    if (!taskBns || taskBns.length < 1) return;
    myRequestModel.perspectiveBns = taskBns;
    //
    assignService
      .getAssignments(myRequestModel)
      .then(function (assignmentData) {
        taskUtil.collateAssignments(taskData, assignmentData);
        var newModel2 = tableUtil.getTableModel(myPage, taskData);
        if ($scope.tableModel.page.currentPage == myPage) {
          // This attempts to ensure if pages are selected very quickly
          // that only the last requested page will be displayed.
          $scope.tableModel = newModel2;
        }
      }, function (err) {
        console.error("Error getting report data ", err);
      });
  }

  /**
   *
   */
  function updateTasks() {
    // //
    optionListService.getOptionList([{dataListType: "TASK_STATUS"}]).then(function (data) {
      var taskStatusOptionListBn = data.data[0].bn;
      //
      var myRequestModel = taskService.getRequestModel(taskStatusOptionListBn);
      var myPage = $scope.tableModel.page.currentPage;
      var mySearchString = $scope.filterModel.filters[0].searchString;
      //
      myRequestModel.perspectiveBn = $scope.watchedByBn;
      myRequestModel.firstRecord = (10 * (myPage - 1)) + 1;
      myRequestModel.sortBy.fieldName = $scope.tableModel.sort.sortProperty;
      myRequestModel.sortBy.sortOrder = $scope.tableModel.sort.sortOrder;
      //
      applyFilter1(myRequestModel, mySearchString);
      //
      taskService
        .getTasks(myRequestModel)
        .then(function (data) {
          var newModel = tableUtil.getTableModel(myPage, data);
          if ($scope.tableModel.page.currentPage == myPage) {
            // This attempts to ensure if pages are selected very quickly
            // that only the last requested page will be displayed.
            $scope.tableModel = newModel;
            updateAssignments(myPage, data);
          }
        }, function (err) {
          console.error("Error getting report data ", err);
        });
    }, function(err) {
      console.error("Error getting option list data ", err);
    });
  }

  // SCOPED ------------------------------------------------------------------------------------------------------------

  /**
   * My state model.
   */
    // Sub-components expect a tableModel.
  $scope.tableModel = {
    // Defaults.
    rows: [],
    page: {currentPage: 1},
    sort: {sortProperty: "createDate", sortOrder: "DESC"}
  };
  // Sub-components expect a filterModel.
  $scope.filterModel = {
    filters: [
      filter1
    ]
  }

  /**
   * Load data into the table model.
   */
  $scope.updateTableModel = function () {
    //
    updateTasks();
  }

  /**
   * Configure $scope.filterModel.
   */
  $scope.updateFilterModel = function () {
    //
    // A no-op, cuz we're not reading our filter-specs from back-end.
    // We already put our filterModel in scope, above.
  }

  /**
   * TODO This is probably a dopey way to do this.
   */
  $scope.$watch('filterModel.filters[0].searchString', function (newVal, oldVal) {
    //
    //prevent frontend from loading data twice when page is first loaded
    if(oldVal != '' || newVal != '') {
      $scope.updateTableModel();
    }
  });
}