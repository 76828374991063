angular.module('barometerApp.neo4j')
  .factory('graphService', ['$http', '$q', 'alertService', 'urlService', 'utilService',
    function ($http, $q, alertService, urlService, utilService) {

      var BASE_URL =  urlService.getGraphApiUrl();

      return {
        getPaths: getPaths,
        getMetricAggregationReportData: getMetricAggregationReportData,
        getMatrixReportData: getMatrixReportData,
        getGroupedQualifiers : getGroupedQualifiers,
        getAssociatedEntities : getAssociatedEntities,
        getEntityByBn : getEntityByBn
      };

      // Get auth token from cookie (provided and updated by baro-web when the page is loaded)
      // baro-web takes care of refreshing and expiring the token
      function getAuthToken() {
        var authToken = utilService.readCookie('baroJWTToken');
        if (!authToken) {
          alertService.addErrorAlert('No authentication credential for Task service.');
        }
        return authToken;
      }

      function getEntityByBn(bn) {
        var token = getAuthToken();

        var deferred = $q.defer();

        if(!token) {
          return deferred.reject("Authorization token missing");
        } else {
          $http({
            method: 'get',
            url: BASE_URL + 'entity/' + bn,
            headers: {
              'Authorization': 'Bearer ' + token,
              'bit-tenant-bn': utilService.getTenantBn(),
              'Content-Type': 'application/json'
            }
          }).success(function(data) {
            deferred.resolve(data);
          }).error(function(data) {
            deferred.reject(data);
          })
        }
        return deferred.promise;
      }

      function getAssociatedEntities(bn, entityTypes) {
        var params = {
          'bn': bn,
          'entityTypes': entityTypes
        };

        var deferred = $q.defer();

        var token = getAuthToken();

        if(!token) {
          return deferred.reject('Authorization token missing');
        } else {
          $http({
            method: 'post',
            url: BASE_URL + 'associated/entity',
            headers: {
              'Authorization': 'Bearer ' + token,
              'bit-tenant-bn': utilService.getTenantBn(),
              'Content-Type': 'application/json'
            },
            data: params
          }).success(function(data) {
            deferred.resolve(data);
          }).error(function(data) {
            console.error('Error getting neo4j path');
            deferred.reject(data);
          })
        }
        return deferred.promise;
      }

      function getPaths(entityBns, rollUp) {
        var deferred = $q.defer();

        var token = getAuthToken();

        if (!token){
          return deferred.reject('Authorization token missing');
        } else {
          $http({
            method: 'post',
            url: BASE_URL + 'path',
            headers: {
              'Authorization': 'Bearer ' + token,
              'bit-tenant-bn': utilService.getTenantBn(),
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: 'bns=' + JSON.stringify(entityBns) + '&rollUp=' + JSON.stringify(rollUp)
          }).success(function(data){
            deferred.resolve(data);
          }).error(function(data){
            console.error('Error getting neo4j path');
            deferred.reject(data);
            deferred.resolve(data);
          });
        }

        return deferred.promise;
      }

      function getMetricAggregationReportData(params) {
        var deferred = $q.defer();

        var token = getAuthToken();

        if(!token) {
          return deferred.reject('Authorization token missing');
        } else {
          $http({
            method: 'post',
            url: BASE_URL + 'report/aggregation',
            headers: {
              'Authorization': 'Bearer ' + token,
              'bit-tenant-bn': utilService.getTenantBn(),
              'Content-Type': 'application/json'
            },
            data: params
          }).success(function(data) {
            deferred.resolve(data);
          }).error(function(data) {
            console.error('Error getting neo4j aggregation data');
            deferred.reject(data);
            deferred.resolve(data);
          });
        }
        return deferred.promise;
      }

      function getMatrixReportData(params) {
        var deferred = $q.defer();

        var token = getAuthToken();

        if(!token) {
          return deferred.reject('Authroization token missing');
        } else {
          $http({
            method: 'post',
            url: BASE_URL + 'report/qualifier',
            headers: {
              'Authorization': 'Bearer ' + token,
              'bit-tenant-bn': utilService.getTenantBn(),
              'Content-Type': 'application/json'
            },
            data: params
          }).success(function(data) {
            deferred.resolve(data);
          }).error(function(data) {
            console.error('Error getting neo4j aggregation data');
            deferred.reject(data);
            deferred.resolve(data);
          });
        }
        return deferred.promise;
      }

      function getGroupedQualifiers(params) {
        var deferred = $q.defer();

        var token = getAuthToken();

        if(!token) {
          return deferred.reject('Authorization token missing');
        } else {
          $http({
            method: 'post',
            url: BASE_URL + 'report/groupedQualifiers',
            headers: {
              'Authorization': 'Bearer ' + token,
              'bit-tenant-bn': utilService.getTenantBn(),
              'Content-Type': 'application/json'
            },
            data: params
          }).success(function(data) {
            deferred.resolve(data);
          }).error(function(data) {
            console.error('Error getting neo4j aggregation data');
            deferred.reject(data);
            deferred.resolve(data);
          });
        }
        return deferred.promise;
      }

    }]);