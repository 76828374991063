angular.module('barometerApp.workspace')
    // ND: I'm using this directive instead of just doing an ng-include because I need the content to replace the
    // tag to avoid extra markup.  If I use <ng-include src="'aURL'"> wicket(i assume) will mess with the 'aURL' part, I
    // believe because ng-include is using the 'src' attribute.
    .directive('workspaceNav', [
        function () {
            return {
                replace: true,
                templateUrl: '/b/js/src/bit.ng/workspace/partials/workspace-nav.html'
            }
        }])
;