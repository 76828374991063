/**
 * Created by rbiggs on 3/9/17.
 */
angular.module('barometerApp.compliance')
  .controller('FormComplianceAlertCtrl', ['$scope', '$modalInstance', 'numOfNonCompliantEntities', 'ruleBn', 'getGenerateState', 'getGenerationPermission', 'optionListService',
    function($scope, $modalInstance, numOfNonCompliantEntities, ruleBn, getGenerateState, getGenerationPermission, optionListService) {
      
      
      $scope.ENTITY_COUNT_CAP = 500;
      
      $scope.save = function() {
        $modalInstance.close(true);
      };

      $scope.cancel = function() {
        $modalInstance.dismiss();
      };

      $scope.createData = function() {

        $scope.disableGenerate = getGenerateState;
        $scope.hasPermission = getGenerationPermission;

        var contactsPromise = optionListService.getContactTypesForRules(ruleBn);
        contactsPromise.then(function(data) {
          var contactTypesList = [];
          var items = data.data.contactTypes;
          for(var i = 0; i < items.length; i++) {
            if(items[i].isActive) {
              contactTypesList.push(items[i].name);
            }
          }
          
          $scope.contactTypes = '';
          $scope.lastContactType = '';
          if(contactTypesList.length > 0) {
            for (var j = 0; j < contactTypesList.length; j++) {
              if (contactTypesList.length == (j + 1)) {
                $scope.lastContactType = contactTypesList[j];
              } else {
                $scope.contactTypes += contactTypesList[j] + ',';
              }
            }
          }
          $scope.contactCount = contactTypesList.length;
        });

        $scope.numOfNonCompliantEntities = numOfNonCompliantEntities;
      };
      


      $scope.createData();
  }]);