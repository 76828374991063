//init chart color palette
Highcharts.setOptions({
    colors: ['#295E9F', '#89B1E2', '#A9D4EA', '#DDEEF6', '#ffffff']
});

// side bar counts
var sideBarOrganizationConfig = {targetCode: 'ORG'};
var sideBarPersonContactConfig = {targetCode: 'PER', relationshipTypes: ['HAS_CONTACT']};
var sideBarPersonEmploymentConfig = {targetCode: 'PER', relationshipTypes: ['EMPLOYMENT']};
var sideBarPersonContactEmploymentConfig = {targetCode: 'PER', relationshipTypes: ['HAS_CONTACT', 'EMPLOYMENT']};
var sideBarCompanyConfig = {targetCode: 'COM'};
var sideBarActorConfig = {targetCode: 'ACT'};
var sideBarAdHocReportConfig = {targetCode: 'ADQ'};
var sideBarCapabilityConfig = {targetCode: 'CAP'};
var sideBarCircleConfig = {targetCode: 'CIR'};
var sideBarComplianceConfig = {targetCode: 'CRL'};
var sideBarProductConfig = {targetCode: 'PRD'};
var sideBarPhysicalConfig = {targetCode: 'PHY'};
var sideBarMarketConfig = {targetCode: 'MKT'};
var sideBarSystemConfig = {targetCode: 'SYS'};
var sideBarDataConfig = {targetCode: 'DAT'};
var sideBarConnectionConfig = {targetCode: 'CON'};
var sideBarTechnologyConfig = {targetCode: 'TEC'};
var sideBarStandardConfig = {targetCode: 'STA'};
var sideBarSkillConfig = {targetCode: 'SKI'};
var sideBarTagConfig = {targetCode: 'TAG'};
var sideBarDocumentConfig = {targetCode: 'AST'};
var sideBarDiscussionConfig = {targetCode: 'TOP'};
var sideBarDemandConfig = {targetCode: 'DEM'};
var sideBarStrategyConfig = {targetCode: 'STR'};
var sideBarWorksheet2Config = {targetCode: 'WK2'};

var sideBarConfigs = [];
// actor
sideBarConfigs['01'] = {
    single: [
        sideBarStrategyConfig,
        sideBarDemandConfig,
        sideBarOrganizationConfig,
        sideBarPersonContactEmploymentConfig,
        sideBarCompanyConfig,
        sideBarCapabilityConfig,
        sideBarProductConfig,
        sideBarMarketConfig,
        sideBarDataConfig,
        sideBarConnectionConfig,
        sideBarTechnologyConfig,
        sideBarStandardConfig,
        sideBarSkillConfig,
        sideBarSystemConfig,
        sideBarPhysicalConfig,
        sideBarDocumentConfig,
        sideBarDiscussionConfig],
    multi: [
        sideBarStrategyConfig,
        sideBarDemandConfig,
        sideBarOrganizationConfig,
        sideBarPersonContactEmploymentConfig,
        sideBarCompanyConfig,
        sideBarCapabilityConfig,
        sideBarProductConfig,
        sideBarMarketConfig,
        sideBarDataConfig,
        sideBarConnectionConfig,
        sideBarTechnologyConfig,
        sideBarStandardConfig,
        sideBarSkillConfig,
        sideBarSystemConfig,
        sideBarPhysicalConfig,
        sideBarTagConfig,
        sideBarDocumentConfig]
};
// adhocreport
sideBarConfigs['24'] = {
    single: [
        {targetCode: 'PER', relationshipTypes: ['HAS_CHILD', 'HAS_PARENT']},
        sideBarComplianceConfig
    ],
    multi: []
};
// audit
sideBarConfigs['4A'] = {
    single: [],
    multi: []
};
// capability
sideBarConfigs['0Q'] = {
    single: [
        sideBarStrategyConfig,
        sideBarDemandConfig,
        sideBarOrganizationConfig,
        sideBarPersonContactEmploymentConfig,
        sideBarCompanyConfig,
        sideBarActorConfig,
        sideBarProductConfig,
        sideBarMarketConfig,
        sideBarDataConfig,
        sideBarSystemConfig,
        sideBarConnectionConfig,
        sideBarTechnologyConfig,
        sideBarStandardConfig,
        sideBarSkillConfig,
        sideBarPhysicalConfig,
        sideBarDocumentConfig,
        sideBarDiscussionConfig],
    multi: [
        sideBarStrategyConfig,
        sideBarDemandConfig,
        sideBarOrganizationConfig,
        sideBarPersonContactEmploymentConfig,
        sideBarCompanyConfig,
        sideBarActorConfig,
        sideBarProductConfig,
        sideBarMarketConfig,
        sideBarDataConfig,
        sideBarSystemConfig,
        sideBarConnectionConfig,
        sideBarTechnologyConfig,
        sideBarStandardConfig,
        sideBarSkillConfig,
        sideBarPhysicalConfig,
        sideBarTagConfig,
        sideBarDocumentConfig]
};
// circle
sideBarConfigs['2O'] = {
    single: [
        sideBarStrategyConfig,
        sideBarDemandConfig,
        sideBarCompanyConfig,
        sideBarOrganizationConfig,
        sideBarActorConfig,
        sideBarCapabilityConfig,
        sideBarProductConfig,
        sideBarMarketConfig,
        sideBarDataConfig,
        sideBarSystemConfig,
        sideBarConnectionConfig,
        sideBarTechnologyConfig,
        sideBarStandardConfig,
        sideBarSkillConfig,
        sideBarPhysicalConfig,
        sideBarTagConfig,
        sideBarDocumentConfig,
        sideBarDiscussionConfig],
    multi: []
};
// company
sideBarConfigs['2F'] = {
    single: [
        sideBarStrategyConfig,
        sideBarDemandConfig,
        sideBarPersonContactEmploymentConfig,
        sideBarActorConfig,
        sideBarOrganizationConfig,
        sideBarCapabilityConfig,
        sideBarProductConfig,
        sideBarMarketConfig,
        sideBarDataConfig,
        sideBarSystemConfig,
        sideBarConnectionConfig,
        sideBarTechnologyConfig,
        sideBarSkillConfig,
        sideBarStandardConfig,
        sideBarPhysicalConfig,
        sideBarDocumentConfig,
        sideBarDiscussionConfig],
    multi: [
        sideBarStrategyConfig,
        sideBarDemandConfig,
        sideBarPersonContactEmploymentConfig,
        sideBarActorConfig,
        sideBarOrganizationConfig,
        sideBarCapabilityConfig,
        sideBarProductConfig,
        sideBarMarketConfig,
        sideBarDataConfig,
        sideBarSystemConfig,
        sideBarConnectionConfig,
        sideBarTechnologyConfig,
        sideBarSkillConfig,
        sideBarStandardConfig,
        sideBarPhysicalConfig,
        sideBarTagConfig,
        sideBarDocumentConfig]
};
// rule
sideBarConfigs['3Z'] = {
    single: [
        sideBarAdHocReportConfig
    ],
    multi: []
};
// connection
sideBarConfigs['07'] = {
    single: [
        sideBarStrategyConfig,
        sideBarDemandConfig,
        sideBarOrganizationConfig,
        sideBarPersonContactConfig,
        sideBarActorConfig,
        sideBarCapabilityConfig,
        sideBarProductConfig,
        sideBarMarketConfig,
        sideBarDataConfig,
        sideBarTechnologyConfig,
        sideBarSkillConfig,
        sideBarStandardConfig,
        sideBarPhysicalConfig,
        sideBarDocumentConfig,
        sideBarDiscussionConfig],
    multi: [
        sideBarStrategyConfig,
        sideBarDemandConfig,
        sideBarOrganizationConfig,
        sideBarPersonContactConfig,
        sideBarActorConfig,
        sideBarCompanyConfig,
        sideBarCapabilityConfig,
        sideBarProductConfig,
        sideBarMarketConfig,
        sideBarDataConfig,
        sideBarSystemConfig,
        sideBarTechnologyConfig,
        sideBarSkillConfig,
        sideBarStandardConfig,
        sideBarPhysicalConfig,
        sideBarTagConfig,
        sideBarDocumentConfig]
};
// data
sideBarConfigs['44'] = {
    single: [
        sideBarStrategyConfig,
        sideBarDemandConfig,
        sideBarOrganizationConfig,
        sideBarPersonContactEmploymentConfig,
        sideBarCompanyConfig,
        sideBarActorConfig,
        sideBarCapabilityConfig,
        sideBarProductConfig,
        sideBarMarketConfig,
        sideBarSystemConfig,
        sideBarConnectionConfig,
        sideBarTechnologyConfig,
        sideBarStandardConfig,
        sideBarSkillConfig,
        sideBarPhysicalConfig,
        sideBarDocumentConfig,
        sideBarDiscussionConfig],
    multi: [
        sideBarStrategyConfig,
        sideBarDemandConfig,
        sideBarOrganizationConfig,
        sideBarPersonContactEmploymentConfig,
        sideBarCompanyConfig,
        sideBarActorConfig,
        sideBarCapabilityConfig,
        sideBarProductConfig,
        sideBarMarketConfig,
        sideBarSystemConfig,
        sideBarConnectionConfig,
        sideBarTechnologyConfig,
        sideBarStandardConfig,
        sideBarSkillConfig,
        sideBarPhysicalConfig,
        sideBarTagConfig,
        sideBarDocumentConfig]
};
// demand
sideBarConfigs['39'] = {
    single: [
        {targetCode: 'DEM', relationshipTypes: ['RELATED_TO']},
        sideBarStrategyConfig,
        sideBarOrganizationConfig,
        sideBarPersonContactEmploymentConfig,
        sideBarCompanyConfig,
        sideBarActorConfig,
        sideBarCapabilityConfig,
        sideBarProductConfig,
        sideBarMarketConfig,
        sideBarDataConfig,
        sideBarSystemConfig,
        sideBarConnectionConfig,
        sideBarTechnologyConfig,
        sideBarStandardConfig,
        sideBarSkillConfig,
        sideBarPhysicalConfig,
        sideBarDocumentConfig,
        sideBarDiscussionConfig],
    multi: [
        {targetCode: 'DEM', relationshipTypes: ['RELATED_TO']},
        sideBarStrategyConfig,
        sideBarOrganizationConfig,
        sideBarPersonContactEmploymentConfig,
        sideBarCompanyConfig,
        sideBarActorConfig,
        sideBarCapabilityConfig,
        sideBarProductConfig,
        sideBarMarketConfig,
        sideBarDataConfig,
        sideBarSystemConfig,
        sideBarConnectionConfig,
        sideBarTechnologyConfig,
        sideBarStandardConfig,
        sideBarSkillConfig,
        sideBarPhysicalConfig,
        sideBarTagConfig,
        sideBarDocumentConfig]
};
// document
sideBarConfigs['0U'] = {
    single: [
        sideBarStrategyConfig,
        sideBarDemandConfig,
        sideBarOrganizationConfig,
        sideBarPersonContactConfig,
        sideBarActorConfig,
        sideBarCapabilityConfig,
        sideBarCompanyConfig,
        sideBarProductConfig,
        sideBarMarketConfig,
        sideBarDataConfig,
        sideBarSystemConfig,
        sideBarConnectionConfig,
        sideBarTechnologyConfig,
        sideBarStandardConfig,
        sideBarSkillConfig,
        sideBarPhysicalConfig,
        sideBarCircleConfig,
        sideBarWorksheet2Config,
        sideBarDiscussionConfig],
    multi: [
        sideBarStrategyConfig,
        sideBarDemandConfig,
        sideBarOrganizationConfig,
        sideBarPersonContactConfig,
        sideBarActorConfig,
        sideBarCapabilityConfig,
        sideBarCompanyConfig,
        sideBarProductConfig,
        sideBarMarketConfig,
        sideBarDataConfig,
        sideBarSystemConfig,
        sideBarConnectionConfig,
        sideBarTechnologyConfig,
        sideBarStandardConfig,
        sideBarSkillConfig,
        sideBarPhysicalConfig,
        sideBarTagConfig,
        sideBarCircleConfig,
        sideBarWorksheet2Config]
};
// market
sideBarConfigs['0T'] = {
    single: [
        sideBarStrategyConfig,
        sideBarDemandConfig,
        sideBarOrganizationConfig,
        sideBarPersonContactEmploymentConfig,
        sideBarCompanyConfig,
        sideBarActorConfig,
        sideBarCapabilityConfig,
        sideBarProductConfig,
        sideBarDataConfig,
        sideBarSkillConfig,
        sideBarStandardConfig,
        sideBarSystemConfig,
        sideBarConnectionConfig,
        sideBarTechnologyConfig,
        sideBarPhysicalConfig,
        sideBarTagConfig,
        sideBarDiscussionConfig,
        sideBarDocumentConfig],
    multi: [
        sideBarStrategyConfig,
        sideBarDemandConfig,
        sideBarOrganizationConfig,
        sideBarPersonContactEmploymentConfig,
        sideBarCompanyConfig,
        sideBarActorConfig,
        sideBarCapabilityConfig,
        sideBarProductConfig,
        sideBarDataConfig,
        sideBarSkillConfig,
        sideBarStandardConfig,
        sideBarSystemConfig,
        sideBarConnectionConfig,
        sideBarTechnologyConfig,
        sideBarPhysicalConfig,
        sideBarTagConfig,
        sideBarDocumentConfig]
};
// option list
sideBarConfigs['0K'] = {
    single: [],
    multi: []
};
// organization
sideBarConfigs['0Y'] = {
    single: [
        sideBarStrategyConfig,
        sideBarDemandConfig,
        sideBarPersonEmploymentConfig,
        sideBarActorConfig,
        sideBarCompanyConfig,
        sideBarCapabilityConfig,
        sideBarProductConfig,
        sideBarMarketConfig,
        sideBarDataConfig,
        sideBarSkillConfig,
        sideBarSystemConfig,
        sideBarConnectionConfig,
        sideBarTechnologyConfig,
        sideBarStandardConfig,
        sideBarPhysicalConfig,
        sideBarTagConfig,
        sideBarDocumentConfig,
        sideBarDiscussionConfig],
    multi: [
        sideBarStrategyConfig,
        sideBarDemandConfig,
        sideBarPersonEmploymentConfig,
        sideBarActorConfig,
        sideBarCompanyConfig,
        sideBarCapabilityConfig,
        sideBarProductConfig,
        sideBarMarketConfig,
        sideBarDataConfig,
        sideBarSkillConfig,
        sideBarSystemConfig,
        sideBarConnectionConfig,
        sideBarTechnologyConfig,
        sideBarStandardConfig,
        sideBarPhysicalConfig,
        sideBarTagConfig,
        sideBarDocumentConfig]
};
// person
sideBarConfigs['0E'] = {
    single: [
        {targetCode: 'STR', relationshipTypes: ['HAS_CONTACT', 'EMPLOYMENT']},
        {targetCode: 'DEM', relationshipTypes: ['HAS_CONTACT', 'EMPLOYMENT']},
        {targetCode: 'ACT', relationshipTypes: ['HAS_CONTACT', 'EMPLOYMENT']},
        {targetCode: 'ORG', relationshipTypes: ['EMPLOYMENT']},
        {targetCode: 'COM', relationshipTypes: ['HAS_CONTACT', 'EMPLOYMENT']},
        {targetCode: 'CAP', relationshipTypes: ['HAS_CONTACT', 'EMPLOYMENT']},
        {targetCode: 'PRD', relationshipTypes: ['HAS_CONTACT', 'EMPLOYMENT']},
        {targetCode: 'PER', relationshipTypes: ['HAS_CONTACT']},
        {targetCode: 'MKT', relationshipTypes: ['HAS_CONTACT', 'EMPLOYMENT']},
        {targetCode: 'DAT', relationshipTypes: ['HAS_CONTACT', 'EMPLOYMENT']},
        {targetCode: 'SYS', relationshipTypes: ['HAS_CONTACT', 'EMPLOYMENT']},
        {targetCode: 'CON', relationshipTypes: ['HAS_CONTACT']},
        {targetCode: 'TEC', relationshipTypes: ['HAS_CONTACT', 'EMPLOYMENT']},
        {targetCode: 'STA', relationshipTypes: ['HAS_CONTACT', 'EMPLOYMENT']},
        {targetCode: 'SKI', relationshipTypes: ['HAS_CONTACT', 'EMPLOYMENT']},
        {targetCode: 'PHY', relationshipTypes: ['HAS_CONTACT', 'EMPLOYMENT']},
        {targetCode: 'AST', relationshipTypes: ['HAS_CONTACT']},
        {targetCode: 'TAG', relationshipTypes: ['HAS_CONTACT', 'EMPLOYMENT']},
    ],
    multi: [
        {targetCode: 'STR', relationshipTypes: ['HAS_CONTACT', 'EMPLOYMENT']},
        {targetCode: 'DEM', relationshipTypes: ['HAS_CONTACT', 'EMPLOYMENT']},
        {targetCode: 'ACT', relationshipTypes: ['HAS_CONTACT', 'EMPLOYMENT']},
        {targetCode: 'ORG', relationshipTypes: ['EMPLOYMENT']},
        {targetCode: 'COM', relationshipTypes: ['HAS_CONTACT', 'EMPLOYMENT']},
        {targetCode: 'CAP', relationshipTypes: ['HAS_CONTACT', 'EMPLOYMENT']},
        {targetCode: 'PRD', relationshipTypes: ['HAS_CONTACT', 'EMPLOYMENT']},
        {targetCode: 'MKT', relationshipTypes: ['HAS_CONTACT', 'EMPLOYMENT']},
        {targetCode: 'DAT', relationshipTypes: ['HAS_CONTACT', 'EMPLOYMENT']},
        {targetCode: 'SYS', relationshipTypes: ['HAS_CONTACT', 'EMPLOYMENT']},
        {targetCode: 'CON', relationshipTypes: ['HAS_CONTACT']},
        {targetCode: 'TEC', relationshipTypes: ['HAS_CONTACT', 'EMPLOYMENT']},
        {targetCode: 'STA', relationshipTypes: ['HAS_CONTACT', 'EMPLOYMENT']},
        {targetCode: 'SKI', relationshipTypes: ['HAS_CONTACT', 'EMPLOYMENT']},
        {targetCode: 'PHY', relationshipTypes: ['HAS_CONTACT', 'EMPLOYMENT']},
        {targetCode: 'AST', relationshipTypes: ['HAS_CONTACT']},
        {targetCode: 'TAG', relationshipTypes: ['HAS_CONTACT', 'EMPLOYMENT']},
    ]
};

// physical
sideBarConfigs['4L'] = {
    single: [
        {targetCode: 'PHY', relationshipTypes: ['RELATED_TO']},
        sideBarStrategyConfig,
        sideBarDemandConfig,
        sideBarOrganizationConfig,
        sideBarPersonContactEmploymentConfig,
        sideBarCompanyConfig,
        sideBarActorConfig,
        sideBarCapabilityConfig,
        sideBarProductConfig,
        sideBarMarketConfig,
        sideBarDataConfig,
        sideBarSystemConfig,
        sideBarConnectionConfig,
        sideBarTechnologyConfig,
        sideBarStandardConfig,
        sideBarSkillConfig,
        sideBarDocumentConfig,
        sideBarDiscussionConfig],
    multi: [
        {targetCode: 'PHY', relationshipTypes: ['RELATED_TO']},
        sideBarStrategyConfig,
        sideBarDemandConfig,
        sideBarOrganizationConfig,
        sideBarPersonContactEmploymentConfig,
        sideBarCompanyConfig,
        sideBarActorConfig,
        sideBarCapabilityConfig,
        sideBarProductConfig,
        sideBarMarketConfig,
        sideBarDataConfig,
        sideBarSystemConfig,
        sideBarConnectionConfig,
        sideBarTechnologyConfig,
        sideBarStandardConfig,
        sideBarSkillConfig,
        sideBarTagConfig,
        sideBarDocumentConfig]
};

// product
sideBarConfigs['0Z'] = {
    single: [
        sideBarStrategyConfig,
        sideBarDemandConfig,
        sideBarOrganizationConfig,
        sideBarPersonContactEmploymentConfig,
        sideBarCompanyConfig,
        sideBarActorConfig,
        sideBarCapabilityConfig,
        sideBarMarketConfig,
        sideBarDataConfig,
        sideBarSystemConfig,
        sideBarConnectionConfig,
        sideBarTechnologyConfig,
        sideBarStandardConfig,
        sideBarSkillConfig,
        sideBarPhysicalConfig,
        sideBarDocumentConfig,
        sideBarDiscussionConfig],
    multi: [
        sideBarStrategyConfig,
        sideBarDemandConfig,
        sideBarOrganizationConfig,
        sideBarPersonContactEmploymentConfig,
        sideBarCompanyConfig,
        sideBarActorConfig,
        sideBarCapabilityConfig,
        sideBarMarketConfig,
        sideBarDataConfig,
        sideBarSystemConfig,
        sideBarConnectionConfig,
        sideBarTechnologyConfig,
        sideBarStandardConfig,
        sideBarSkillConfig,
        sideBarPhysicalConfig,
        sideBarTagConfig,
        sideBarDocumentConfig]
};
// skill
sideBarConfigs['15'] = {
    single: [
        sideBarStrategyConfig,
        sideBarDemandConfig,
        sideBarOrganizationConfig,
        sideBarPersonContactEmploymentConfig,
        sideBarCompanyConfig,
        sideBarActorConfig,
        sideBarCapabilityConfig,
        sideBarDataConfig,
        sideBarProductConfig,
        sideBarMarketConfig,
        sideBarSystemConfig,
        sideBarConnectionConfig,
        sideBarTechnologyConfig,
        sideBarStandardConfig,
        sideBarPhysicalConfig,
        sideBarDocumentConfig,
        sideBarDiscussionConfig],
    multi: [
        sideBarStrategyConfig,
        sideBarDemandConfig,
        sideBarOrganizationConfig,
        sideBarPersonContactEmploymentConfig,
        sideBarCompanyConfig,
        sideBarActorConfig,
        sideBarCapabilityConfig,
        sideBarDataConfig,
        sideBarProductConfig,
        sideBarMarketConfig,
        sideBarSystemConfig,
        sideBarConnectionConfig,
        sideBarTechnologyConfig,
        sideBarStandardConfig,
        sideBarPhysicalConfig,
        sideBarTagConfig,
        sideBarDocumentConfig]
};
// standard
sideBarConfigs['16'] = {
    single: [
        sideBarStrategyConfig,
        sideBarDemandConfig,
        sideBarOrganizationConfig,
        sideBarPersonContactEmploymentConfig,
        sideBarCompanyConfig,
        sideBarActorConfig,
        sideBarCapabilityConfig,
        sideBarDataConfig,
        sideBarMarketConfig,
        sideBarProductConfig,
        sideBarSystemConfig,
        sideBarConnectionConfig,
        sideBarTechnologyConfig,
        sideBarSkillConfig,
        sideBarPhysicalConfig,
        sideBarDocumentConfig,
        sideBarDiscussionConfig],
    multi: [
        sideBarStrategyConfig,
        sideBarDemandConfig,
        sideBarOrganizationConfig,
        sideBarPersonContactEmploymentConfig,
        sideBarCompanyConfig,
        sideBarActorConfig,
        sideBarCapabilityConfig,
        sideBarDataConfig,
        sideBarMarketConfig,
        sideBarProductConfig,
        sideBarSystemConfig,
        sideBarConnectionConfig,
        sideBarTechnologyConfig,
        sideBarSkillConfig,
        sideBarPhysicalConfig,
        sideBarTagConfig,
        sideBarDocumentConfig]
};
// strategy
sideBarConfigs['0G'] = {
    single: [
        {targetCode: 'STR', relationshipTypes: ['RELATED_TO']},
        sideBarDemandConfig,
        sideBarOrganizationConfig,
        sideBarPersonContactEmploymentConfig,
        sideBarCompanyConfig,
        sideBarActorConfig,
        sideBarCapabilityConfig,
        sideBarProductConfig,
        sideBarMarketConfig,
        sideBarDataConfig,
        sideBarSystemConfig,
        sideBarConnectionConfig,
        sideBarTechnologyConfig,
        sideBarStandardConfig,
        sideBarSkillConfig,
        sideBarPhysicalConfig,
        sideBarDocumentConfig,
        sideBarDiscussionConfig],
    multi: [
        {targetCode: 'STR', relationshipTypes: ['RELATED_TO']},
        sideBarDemandConfig,
        sideBarOrganizationConfig,
        sideBarPersonContactEmploymentConfig,
        sideBarCompanyConfig,
        sideBarActorConfig,
        sideBarCapabilityConfig,
        sideBarProductConfig,
        sideBarMarketConfig,
        sideBarDataConfig,
        sideBarSystemConfig,
        sideBarConnectionConfig,
        sideBarTechnologyConfig,
        sideBarStandardConfig,
        sideBarSkillConfig,
        sideBarPhysicalConfig,
        sideBarTagConfig,
        sideBarDocumentConfig]
};
// system
sideBarConfigs['18'] = {
    single: [
        sideBarStrategyConfig,
        sideBarDemandConfig,
        sideBarOrganizationConfig,
        sideBarPersonContactEmploymentConfig,
        sideBarCompanyConfig,

        sideBarActorConfig,
        sideBarCapabilityConfig,
        sideBarProductConfig,
        sideBarMarketConfig,

        sideBarDataConfig,
        sideBarConnectionConfig,
        sideBarTechnologyConfig,
        sideBarStandardConfig,
        sideBarSkillConfig,
        sideBarPhysicalConfig,
        sideBarDocumentConfig,
        sideBarDiscussionConfig],
    multi: [
        sideBarStrategyConfig,
        sideBarDemandConfig,
        sideBarOrganizationConfig,
        sideBarPersonContactEmploymentConfig,
        sideBarCompanyConfig,

        sideBarActorConfig,
        sideBarCapabilityConfig,
        sideBarProductConfig,
        sideBarMarketConfig,

        sideBarDataConfig,
        sideBarConnectionConfig,
        sideBarTechnologyConfig,
        sideBarStandardConfig,
        sideBarSkillConfig,
        sideBarPhysicalConfig,
        sideBarTagConfig,
        sideBarDocumentConfig]
};
// tag
sideBarConfigs['1F'] = {
    single: [
        sideBarStrategyConfig,
        sideBarDemandConfig,
        sideBarOrganizationConfig,
        sideBarPersonContactEmploymentConfig,
        sideBarCompanyConfig,
        sideBarActorConfig,
        sideBarCapabilityConfig,
        sideBarProductConfig,
        sideBarMarketConfig,
        sideBarDataConfig,
        sideBarSystemConfig,
        sideBarConnectionConfig,
        sideBarTechnologyConfig,
        sideBarStandardConfig,
        sideBarSkillConfig,
        sideBarPhysicalConfig,
        sideBarDocumentConfig,
        sideBarDiscussionConfig],
    multi: [
        sideBarStrategyConfig,
        sideBarDemandConfig,
        sideBarOrganizationConfig,
        sideBarPersonContactEmploymentConfig,
        sideBarCompanyConfig,
        sideBarActorConfig,
        sideBarCapabilityConfig,
        sideBarProductConfig,
        sideBarMarketConfig,
        sideBarDataConfig,
        sideBarSystemConfig,
        sideBarConnectionConfig,
        sideBarTechnologyConfig,
        sideBarStandardConfig,
        sideBarSkillConfig,
        sideBarPhysicalConfig,
        sideBarDocumentConfig]
};
// technology
sideBarConfigs['1H'] = {
    single: [
        sideBarStrategyConfig,
        sideBarDemandConfig,
        sideBarOrganizationConfig,
        sideBarPersonContactEmploymentConfig,
        sideBarCompanyConfig,
        sideBarActorConfig,
        sideBarCapabilityConfig,
        sideBarProductConfig,
        sideBarMarketConfig,
        sideBarDataConfig,
        sideBarSystemConfig,
        sideBarConnectionConfig,
        sideBarStandardConfig,
        sideBarSkillConfig,
        sideBarPhysicalConfig,
        sideBarDocumentConfig,
        sideBarDiscussionConfig],
    multi: [
        sideBarStrategyConfig,
        sideBarDemandConfig,
        sideBarOrganizationConfig,
        sideBarPersonContactEmploymentConfig,
        sideBarCompanyConfig,
        sideBarActorConfig,
        sideBarCapabilityConfig,
        sideBarProductConfig,
        sideBarMarketConfig,
        sideBarDataConfig,
        sideBarSystemConfig,
        sideBarConnectionConfig,
        sideBarStandardConfig,
        sideBarSkillConfig,
        sideBarPhysicalConfig,
        sideBarTagConfig,
        sideBarDocumentConfig]
};
// tenant endpoint
sideBarConfigs['02'] = {
    single: [],
    multi: []
};
// worksheets
sideBarConfigs['06'] = {
  single: [
  ],
  multi: []
};

// worksheet2
sideBarConfigs['4M'] = {
  single: [
    sideBarStrategyConfig,
    sideBarDemandConfig,
    sideBarCompanyConfig,
    sideBarOrganizationConfig,
    sideBarActorConfig,
    sideBarCapabilityConfig,
    sideBarProductConfig,
    sideBarMarketConfig,
    sideBarDataConfig,
    sideBarSystemConfig,
    sideBarConnectionConfig,
    sideBarTechnologyConfig,
    sideBarStandardConfig,
    sideBarSkillConfig,
    sideBarPhysicalConfig,
    sideBarTagConfig,
    sideBarDocumentConfig,
    sideBarDiscussionConfig],
  multi: []
};

// custom dashboards
sideBarConfigs['1L'] = {
  single: [
  ],
  multi: []
};


// Side bar content blocks
var basicInfoSidebar = {bn: 'NEW_CB1S', columnId: 1, order: 0, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: '17', contentBlockType: 'BASIC_INFO_SIDEBAR'}};
var basicInfoSidebarWithSummaryInfo = {bn: 'NEW_DB1S', columnId: 1, order: 0, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: '117', contentBlockType: 'BASIC_INFO_SIDEBAR_WITH_SUMMARY_INFO'}};
var basicInfoSidebarWithSummaryInfoOrganizationsCompanies = {bn: 'NEW_EB1S', columnId: 1, order: 0, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: '117', contentBlockType: 'BASIC_INFO_SIDEBAR_WITH_SUMMARY_INFO_ORGANIZATIONS_COMPANIES'}};
var basicInfoSidebarWithSummaryInfoContactsCompanies = {bn: 'NEW_EB1S', columnId: 1, order: 0, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: '117', contentBlockType: 'BASIC_INFO_SIDEBAR_WITH_SUMMARY_INFO_CONTACTS_COMPANIES'}};
var basicInfoSidebarWithSummaryInfoContacts = {bn: 'NEW_EB1S', columnId: 1, order: 0, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: '117', contentBlockType: 'BASIC_INFO_SIDEBAR_WITH_SUMMARY_INFO_CONTACTS'}};
var basicInfoSidebarSystemSummaryInfo = {bn: 'NEW_EB1S', columnId: 1, order: 0, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: '117', contentBlockType: 'BASIC_INFO_SIDEBAR_SYSTEM_SUMMARY_INFO'}};

var actorCount = {bn: 'SB1', sectionBn: 'ACT', columnId: 1, order: 0, size: 'LARGE', configuration: { bn: 'SB1', contentBlockType: 'METRIC', title: 'Actor Count', fieldBn: 'SELF', limit: '2147483647', measurement: 'COUNT', sortDescending: true }};

var capabilityCount = {bn: 'SB3', sectionBn: 'CAP', columnId: 1, order: 0, size: 'LARGE', configuration: { bn: 'SB3', contentBlockType: 'METRIC', title: 'Capability Count', fieldBn: 'SELF', limit: '2147483647', measurement: 'COUNT', sortDescending: true }};
var capabilityEnterpriseApprovePie = {bn: 'SB4', sectionBn: 'CAP', columnId: 1, order: 1, size: 'LARGE', configuration: { bn: 'SB4', contentBlockType: 'PIE', title: 'Enterprise Approval', fieldBn: '0446000000K1', limit: '10', measurement: 'COUNT', sortDescending: false }};

var companyCount = {bn: 'SB20', sectionBn: 'COM', order: 0, size: 'LARGE', columnId: 1, configuration: {bn: 'SB20', contentBlockType: 'METRIC', fieldBn: null, title: 'Company Count', limit: 2147483647, measurement: 'COUNT', sortDescending: true}};

var connectionCount = {bn: 'SB4', sectionBn: 'CON', columnId: 1, order: 0, size: 'MEDIUM', configuration: { bn: 'SB5', contentBlockType: 'METRIC', title: 'Connection Count', fieldBn: 'SELF', limit: '2147483647', measurement: 'COUNT', sortDescending: true }};
var connectionTypesCount = {bn: 'SB5', sectionBn: 'CON', columnId: 1, order: 2, size: 'MEDIUM', configuration: { bn: 'SB6', contentBlockType: 'METRIC', title: 'Types Count', fieldBn: '0446000000KC', limit: '2147483647', measurement: 'COUNT', sortDescending: true }};
var connectionTypesPie = {bn: 'SB9', sectionBn: 'CON', columnId: 1, order: 3, size: 'MEDIUM', configuration: { bn: 'SB2', contentBlockType: 'PIE', title: 'Types', fieldBn: '0446000000KC', limit: '2147483647', sortDescending: true }};
var connectionPatternsCount = {bn: 'SB6', sectionBn: 'CON', columnId: 1, order: 4, size: 'MEDIUM', configuration: { bn: 'SB7', contentBlockType: 'METRIC', title: 'Patterns Count', fieldBn: '0446000000KD', limit: '2147483647', measurement: 'COUNT', sortDescending: true }};
var connectionPatternsPie = {bn: 'SB10', sectionBn: 'CON', columnId: 1, order: 5, size: 'MEDIUM', configuration: { bn: 'SB2', contentBlockType: 'PIE', title: 'Patterns', fieldBn: '0446000000KD', limit: '2147483647', sortDescending: true }};
var connectionTransportsCount = {bn: 'SB7', sectionBn: 'CON', columnId: 1, order: 6, size: 'MEDIUM', configuration: { bn: 'SB8', contentBlockType: 'METRIC', title: 'Transports Count', fieldBn: '0446000000KB', limit: '2147483647', measurement: 'COUNT', sortDescending: true }};
var connectionTransportsPie = {bn: 'SB11', sectionBn: 'CON', columnId: 1, order: 7, size: 'MEDIUM', configuration: { bn: 'SB2', contentBlockType: 'PIE', title: 'Transports', fieldBn: '0446000000KB', limit: '2147483647', sortDescending: true }};

var dataCount = {bn: 'SB15', sectionBn: 'DAT', order: 0, size: 'LARGE', columnId: 1, configuration: {bn: 'SB15', contentBlockType: 'METRIC', fieldBn: 'SELF', title: 'Data Count', limit: 2147483647, measurement: 'COUNT', sortDescending: true}};
var dataEnterpriseApprovalPie = {bn: 'SB16', sectionBn: 'DAT', order: 1, size: 'LARGE', columnId: 1, configuration: {bn: 'SB16', contentBlockType: 'PIE', fieldBn: '0446000000KQ', title: 'Enterprise Approval', limit: 2147483647, measurement: 'COUNT', sortDescending: true}};

var demandCount = {bn: 'SB16', sectionBn: 'DEM', order: 0, size: 'LARGE', columnId: 1, configuration: {bn: 'SB16', contentBlockType: 'METRIC', fieldBn: 'SELF', title: 'Demand Count', limit: 2147483647, measurement: 'COUNT', sortDescending: true}};
var demandRelatedCount = {bn: 'SB17', sectionBn: 'DEM', order: 0, size: 'LARGE', columnId: 1, configuration: {bn: 'SB16', contentBlockType: 'METRIC', fieldBn: '044600000208', title: 'Related Demand Count', limit: 2147483647, measurement: 'COUNT', sortDescending: true, relationshipTypes: 'RELATED_TO'}};

var orgCount = {bn: 'SB19', sectionBn: 'ORG', order: 0, size: 'LARGE', columnId: 1, configuration: {bn: 'SB19', contentBlockType: 'METRIC', fieldBn: null, title: 'Org Count', limit: 2147483647, measurement: 'COUNT', sortDescending: true}};

var personCount = {bn: 'SB21', sectionBn: 'PER', order: 0, size: 'LARGE', columnId: 1, configuration: {bn: 'SB20', contentBlockType: 'METRIC', fieldBn: null, title: 'People', limit: 2147483647, measurement: 'COUNT', sortDescending: true, relationshipTypes: 'HAS_CONTACT, EMPLOYMENT'}};
var contactCount = {bn: 'SB21', sectionBn: 'PER', order: 0, size: 'LARGE', columnId: 1, configuration: {bn: 'SB20', contentBlockType: 'METRIC', fieldBn: null, title: 'Contacts', limit: 2147483647, measurement: 'COUNT', sortDescending: true, relationshipTypes: 'HAS_CONTACT', filterIdentifier: 'PER1'}};
var employmentCount = {bn: 'SB21', sectionBn: 'PER', order: 1, size: 'LARGE', columnId: 1, configuration: {bn: 'SB20', contentBlockType: 'METRIC', fieldBn: null, title: 'People who have worked with this entity', limit: 2147483647, measurement: 'COUNT', sortDescending: true, relationshipTypes: 'EMPLOYMENT', filterIdentifier: 'PER2'}};

var marketCount = {bn: 'SB36', sectionBn: 'MKT', order: 0, size: 'LARGE', columnId: 1, configuration: {bn: 'SB36', contentBlockType: 'METRIC', fieldBn: 'SELF', title: 'Market Count', limit: 2147483647, measurement: 'COUNT', sortDescending: true}};

var physicalCount = {bn: 'SB39', sectionBn: 'PHY', columnId: 1, order: 0, size: 'LARGE', configuration: { bn: 'SB39', contentBlockType: 'METRIC', title: 'Deployment Count', fieldBn: 'SELF', limit: '2147483647', measurement: 'COUNT', sortDescending: true }};
var physicalRelatedCount = {bn: 'SB40', sectionBn: 'PHY', order: 0, size: 'LARGE', columnId: 1, configuration: {bn: 'SB40', contentBlockType: 'METRIC', fieldBn: '0446000001HJ', title: 'Related Deployment Count', limit: 2147483647, measurement: 'COUNT', sortDescending: true, relationshipTypes: 'RELATED_TO'}};

var productCount = {bn: 'SB26', sectionBn: 'PRD', columnId: 1, order: 0, size: 'LARGE', configuration: { bn: 'SB26', contentBlockType: 'METRIC', title: 'Product Count', fieldBn: 'SELF', limit: '2147483647', measurement: 'COUNT', sortDescending: true }};

var skillCount = {bn: 'SB23', order: 0, sectionBn: 'SKI', size: 'MEDIUM', columnId: 1, configuration: {bn: 'SB23', contentBlockType: 'METRIC', fieldBn: null, limit: 2147483647, measurement: 'COUNT', title: 'Skill Count', sortDescending: true}};

var standardCount = {bn: 'SB25', order: 0, sectionBn: 'STA', size: 'MEDIUM', columnId: 1, configuration: {bn: 'SB25', contentBlockType: 'METRIC', fieldBn: null, limit: 2147483647, measurement: 'COUNT', title: 'Standard Count', sortDescending: true}};

var strategyCount = {bn: 'SB30', sectionBn: 'STR', order: 0, size: 'LARGE', columnId: 1, configuration: {bn: 'SB30', contentBlockType: 'METRIC', fieldBn: 'SELF', title: 'Strategy Count', limit: 2147483647, measurement: 'COUNT', sortDescending: true}};
var strategyRelatedCount = {bn: 'SB31', sectionBn: 'STR', order: 0, size: 'LARGE', columnId: 1, configuration: {bn: 'SB30', contentBlockType: 'METRIC', fieldBn: '044600000209', title: 'Related Strategy Count', limit: 2147483647, measurement: 'COUNT', sortDescending: true, relationshipTypes: 'RELATED_TO'}};

var systemCoreSystemsPie = {bn: 'SB12', sectionBn: 'SYS', columnId: 1, order: 1, size: 'MEDIUM', configuration: {bn: 'SB12', contentBlockType: 'PIE', title: 'Core Systems', fieldBn: '0446000000L5', limit: 2147483647, sortDescending: true}};
var systemPriorityPie = {bn: 'SB13', sectionBn: 'SYS', order: 2, size: 'MEDIUM', columnId: 1, configuration: {bn: 'SB13', contentBlockType: 'PIE', title: 'System Priority', fieldBn: '0446000000LF', limit: 2147483647, sortDescending: true}};
var systemCount = {bn: 'SB14', sectionBn: 'SYS', order: 0, size: 'MEDIUM', columnId: 1, configuration: {bn: 'SB14', contentBlockType: 'METRIC', title: 'System Count', fieldBn: null, limit: 2147483647, measurement: 'COUNT', sortDescending: true}};

var tagCount = {bn: 'SB18', order: 1, sectionBn: 'TAG', size: 'MEDIUM', columnId: 1, configuration: {bn: 'SB18', contentBlockType: 'METRIC', fieldBn: null, limit: 2147483647, measurement: 'COUNT', title: 'Tag Count', sortDescending: true}};

var technologyCount = {bn: 'SB25', order: 0, sectionBn: 'TEC', size: 'MEDIUM', columnId: 1, configuration: {bn: 'SB25', contentBlockType: 'METRIC', fieldBn: null, limit: 2147483647, measurement: 'COUNT', title: 'Technology Count', sortDescending: true}};
var technologyEnterpriseApprovePie = {bn: 'SB4', sectionBn: 'TEC', columnId: 1, order: 1, size: 'MEDIUM', configuration: { bn: 'SB26', contentBlockType: 'PIE', title: 'Enterprise Approval', fieldBn: '0446000000TX', limit: '10', measurement: 'COUNT', sortDescending: false }};

// Association table content blocks
var systemAssociation = {bn: 'SYS_ASSOC', columnId: 0, order: 0, sectionBn: 'SYS', size: 'LARGE', configuration: {bn: 'SYS_ASSOC_CONFIG', contentBlockType: 'SYSTEM_ASSOCIATIONS'}};
var actorAssociation = {bn: 'ACT_ASSOC', columnId: 0, order: 0, sectionBn: 'ACT', size: 'LARGE', configuration: {bn: 'ACT_ASSOC_CONFIG', contentBlockType: 'ACTOR_ASSOCIATIONS'}};
var actorPersonAssociations = {bn: 'ACT_ASSOC', columnId: 0, order: 0, sectionBn: 'ACT', size: 'LARGE', name: 'Actors', configuration: {bn: 'ACT_ASSOC_CONFIG', contentBlockType: 'PROFILE_ENTITY_PERSON_ASSOCIATIONS'}};
var capabilityAssociation = {bn: 'CAP_ASSOC', columnId: 0, order: 0, sectionBn: 'CAP', size: 'LARGE', configuration: {bn: 'CAP_ASSOC_CONFIG', contentBlockType: 'CAPABILITY_ASSOCIATIONS'}};
var capabilityAssociationWithDiagrams = {bn: 'CAP_ASSOC', columnId: 0, order: 0, sectionBn: 'CAP', size: 'LARGE', configuration: {bn: 'CAP_ASSOC_CONFIG', contentBlockType: 'CAPABILITY_ASSOCIATIONS_WITH_DIAGRAMS'}};
var capabilityPersonAssociations = {bn: 'CAP_ASSOC', columnId: 0, order: 0, sectionBn: 'CAP', size: 'LARGE', name: 'Capabilities', configuration: {bn: 'CAP_ASSOC_CONFIG', contentBlockType: 'PROFILE_ENTITY_PERSON_ASSOCIATIONS'}};
var complianceRuleAssociation = {bn: 'CRL_ASSOC', columnId: 0, order: 0, sectionBn: '044S0000000C', size: 'LARGE', configuration: {bn: 'CRL_ASSOC_CONFIG', contentBlockType: 'COMPLIANCE_RULE_ASSOCIATIONS'}};
var connectionAssociation = {bn: 'CON_ASSOC', columnId: 0, order: 0, sectionBn: 'CON', size: 'LARGE', configuration: {bn: 'CON_ASSOC_CONFIG', contentBlockType: 'CONNECTION_ASSOCIATIONS'}};
var connectionAssociationNoDiagram = {bn: 'CON_ASSOC', columnId: 0, order: 0, sectionBn: 'CON', size: 'LARGE', configuration: {bn: 'CON_ASSOC_CONFIG', contentBlockType: 'CONNECTION_ASSOCIATIONS_NO_DIAGRAM'}};
var connectionAssociationToCompany = {bn: 'CON_ASSOC', columnId: 0, order: 0, sectionBn: 'CON', size: 'LARGE', configuration: {bn: 'CON_ASSOC_CONFIG', contentBlockType: 'CONNECTION_ASSOCIATIONS_TO_COMPANY'}};
var connectionPersonAssociations = {bn: 'CON_ASSOC', columnId: 0, order: 0, sectionBn: 'CON', size: 'LARGE', name: 'Connections', configuration: {bn: 'CON_ASSOC_CONFIG', contentBlockType: 'PROFILE_ENTITY_PERSON_ASSOCIATIONS'}};
var dataAssociation = {bn: 'DAT_ASSOC', columnId: 0, order: 0, sectionBn: 'DAT', size: 'LARGE', configuration: {bn: 'DAT_ASSOC_CONFIG', contentBlockType: 'DATA_ASSOCIATIONS'}};
var dataPersonAssociations = {bn: 'DAT_ASSOC', columnId: 0, order: 0, sectionBn: 'DAT', size: 'LARGE', name: 'Data', configuration: {bn: 'DAT_ASSOC_CONFIG', contentBlockType: 'PROFILE_ENTITY_PERSON_ASSOCIATIONS'}};
var demandAssociation = {bn: 'DEM_ASSOC', columnId: 0, order: 0, sectionBn: 'DEM', size: 'LARGE', configuration: {bn: 'DEM_ASSOC_CONFIG', contentBlockType: 'DEMAND_ASSOCIATIONS'}};
var demandPersonAssociation = {bn: 'DEM_ASSOC', columnId: 0, order: 0, sectionBn: 'DEM', size: 'LARGE', name: 'Demands', configuration: {bn: 'DEM_ASSOC_CONFIG', contentBlockType: 'PROFILE_ENTITY_PERSON_ASSOCIATIONS'}};
var demandRelatedAssociation = {bn: 'DEM_ASSOC', columnId: 0, order: 0, sectionBn: 'DEM', size: 'LARGE', configuration: {bn: 'DEM_ASSOC_CONFIG', contentBlockType: 'DEMAND_RELATED_ASSOCIATIONS'}};
var documentAssociation = {bn: 'AST_ASSOC', columnId: 0, order: 0, sectionBn: 'AST', size: 'LARGE', configuration: {bn: 'AST_ASSOC_CONFIG', contentBlockType: 'DOCUMENT_ASSOCIATIONS'}};
var documentAssociationByEntity = {bn: 'AST_ASSOC', columnId: 0, order: 0, sectionBn: 'AST', size: 'LARGE', configuration: {bn: 'AST_ASSOC_CONFIG', contentBlockType: 'DOCUMENT_ASSOCIATIONS_BY_ENTITY'}};
var documentPersonAssociations = {bn: 'AST_ASSOC', columnId: 0, order: 0, sectionBn: 'AST', size: 'LARGE', name: 'Documents', configuration: {bn: 'AST_ASSOC_CONFIG', contentBlockType: 'PROFILE_ENTITY_PERSON_ASSOCIATIONS'}};
var orgAssociation = {bn: 'ORG_ASSOC', columnId: 0, order: 0, sectionBn: 'ORG', size: 'LARGE', configuration: {bn: 'ORG_ASSOC_CONFIG', contentBlockType: 'ORGANIZATION_ASSOCIATIONS'}};
var orgPersonAssociations = {bn: 'ORG_ASSOC', columnId: 0, order: 0, sectionBn: 'ORG', size: 'LARGE', name: 'Organizations', configuration: {bn: 'ORG_ASSOC_CONFIG', contentBlockType: 'PROFILE_ENTITY_PERSON_ASSOCIATIONS'}};
var circleAssociation = {bn: 'CIR_ASSOC', columnId: 0, order: 0, sectionBn: 'CIR', size: 'LARGE', configuration: {bn: 'CIR_ASSOC_CONFIG', contentBlockType: 'CIRCLE_ASSOCIATIONS', displayPivot: false}};
var companyAssociation = {bn: 'COM_ASSOC', columnId: 0, order: 0, sectionBn: 'COM', size: 'LARGE', configuration: {bn: 'COM_ASSOC_CONFIG', contentBlockType: 'COMPANY_ASSOCIATIONS'}};
var companyPersonAssociations = {bn: 'COM_ASSOC', columnId: 0, order: 0, sectionBn: 'COM', size: 'LARGE', name: 'Companies', configuration: {bn: 'COM_ASSOC_CONFIG', contentBlockType: 'PROFILE_ENTITY_PERSON_ASSOCIATIONS'}};
var marketAssociation = {bn: 'MKT_ASSOC', columnId: 0, order: 0, sectionBn: 'MKT', size: 'LARGE', configuration: {bn: 'MKT_ASSOC_CONFIG', contentBlockType: 'MARKET_ASSOCIATIONS'}};
var marketPersonAssociations = {bn: 'MKT_ASSOC', columnId: 0, order: 0, sectionBn: 'MKT', size: 'LARGE', name: 'Markets', configuration: {bn: 'MKT_ASSOC_CONFIG', contentBlockType: 'PROFILE_ENTITY_PERSON_ASSOCIATIONS'}};
var marketRelatedAssociation = {bn: 'MKT_ASSOC', columnId: 0, order: 0, sectionBn: 'MKT', size: 'LARGE', configuration: {bn: 'MKT_ASSOC_CONFIG', contentBlockType: 'MARKET_RELATED_ASSOCIATIONS'}};
var personAssociation = {bn: 'PER_ASSOC', columnId: 0, order: 0, sectionBn: 'PER', size: 'LARGE', configuration: {bn: 'PER_ASSOC_CONFIG', contentBlockType: 'PROFILE_ASSOCIATIONS'}};
var personContactAssociation = {bn: 'PER_ASSOC', columnId: 0, order: 0, sectionBn: 'PER', size: 'LARGE', configuration: {bn: 'PER_ASSOC_CONFIG', contentBlockType: 'PROFILE_CONTACT_ASSOCIATIONS'}};
var personEmploymentAssociation = {bn: 'PER_ASSOC', columnId: 0, order: 0, sectionBn: 'PER', size: 'LARGE', configuration: {bn: 'PER_ASSOC_CONFIG', contentBlockType: 'PROFILE_EMPLOYMENT_ASSOCIATIONS'}};
var projectAssociation = {bn: 'PRJ_ASSOC', columnId: 0, order: 0, sectionBn: 'PRJ', size: 'LARGE', configuration: {bn: 'PRJ_ASSOC_CONFIG', contentBlockType: 'PROJECT_ASSOCIATIONS'}};
var physicalAssociation = {bn: 'PHY_ASSOC', columnId: 0, order: 0, sectionBn: 'PHY', size: 'LARGE', configuration: {bn: 'PHY_ASSOC_CONFIG', contentBlockType: 'PHYSICAL_ASSOCIATIONS'}};
var physicalPersonAssociation = {bn: 'PHY_ASSOC', columnId: 0, order: 0, sectionBn: 'PHY', size: 'LARGE', name: "Deployments", configuration: {bn: 'PHY_ASSOC_CONFIG', contentBlockType: 'PROFILE_ENTITY_PERSON_ASSOCIATIONS'}};
var physicalRelatedAssociation = {bn: 'PHY_ASSOC', columnId: 0, order: 0, sectionBn: 'PHY', size: 'LARGE', configuration: {bn: 'PHY_ASSOC_CONFIG', contentBlockType: 'PHYSICAL_RELATED_ASSOCIATIONS'}};
var productAssociation = {bn: 'PRD_ASSOC', columnId: 0, order: 0, sectionBn: 'PRD', size: 'LARGE', configuration: {bn: 'PRD_ASSOC_CONFIG', contentBlockType: 'PRODUCT_ASSOCIATIONS'}};
var productPersonAssociation = {bn: 'PRD_ASSOC', columnId: 0, order: 0, sectionBn: 'PRD', size: 'LARGE', name: "Products", configuration: {bn: 'PRD_ASSOC_CONFIG', contentBlockType: 'PROFILE_ENTITY_PERSON_ASSOCIATIONS'}};
var skillAssociation = {bn: 'SKI_ASSOC', columnId: 0, order: 0, sectionBn: 'SKI', size: 'LARGE', configuration: {bn: 'SKI_ASSOC_CONFIG', contentBlockType: 'SKILL_ASSOCIATIONS'}};
var skillPersonAssociations = {bn: 'SKI_ASSOC', columnId: 0, order: 0, sectionBn: 'SKI', size: 'LARGE', name: 'Skills', configuration: {bn: 'SKI_ASSOC_CONFIG', contentBlockType: 'PROFILE_ENTITY_PERSON_ASSOCIATIONS'}};
var standardAssociation = {bn: 'STA_ASSOC', columnId: 0, order: 0, sectionBn: 'STA', size: 'LARGE', configuration: {bn: 'STA_ASSOC_CONFIG', contentBlockType: 'STANDARD_ASSOCIATIONS'}};
var standardPersonAssociations =  {bn: 'STA_ASSOC', columnId: 0, order: 0, sectionBn: 'STA', size: 'LARGE', name: 'Standards', configuration: {bn: 'STA_ASSOC_CONFIG', contentBlockType: 'PROFILE_ENTITY_PERSON_ASSOCIATIONS'}};
var strategyAssociation = {bn: 'STR_ASSOC', columnId: 0, order: 0, sectionBn: 'STR', size: 'LARGE', configuration: {bn: 'STR_ASSOC_CONFIG', contentBlockType: 'STRATEGY_ASSOCIATIONS'}};
var strategyPersonAssociation = {bn: 'STR_ASSOC', columnId: 0, order: 0, sectionBn: 'STR', size: 'LARGE', name: 'Strategies', configuration: {bn: 'STR_ASSOC_CONFIG', contentBlockType: 'PROFILE_ENTITY_PERSON_ASSOCIATIONS'}};
var strategyRelatedAssociation = {bn: 'STR_ASSOC', columnId: 0, order: 0, sectionBn: 'STR', size: 'LARGE', configuration: {bn: 'STR_ASSOC_CONFIG', contentBlockType: 'STRATEGY_RELATED_ASSOCIATIONS'}};
var systemPersonAssociations = {bn: 'SYS_ASSOC', columnId: 0, order: 0, sectionBn: 'SYS', size: 'LARGE', name: 'Systems', configuration: {bn: 'SYS_ASSOC_CONFIG', contentBlockType: 'PROFILE_ENTITY_PERSON_ASSOCIATIONS'}};
var tagAssociation = {bn: 'TAG_ASSOC', columnId: 0, order: 0, sectionBn: 'TAG', size: 'LARGE', configuration: {bn: 'TAG_ASSOC_CONFIG', contentBlockType: 'TAG_ASSOCIATIONS'}};
var tagPersonAssociations = {bn: 'TAG_ASSOC', columnId: 0, order: 0, sectionBn: 'TAG', size: 'LARGE', name: 'Tags', configuration: {bn: 'TAG_ASSOC_CONFIG', contentBlockType: 'PROFILE_ENTITY_PERSON_ASSOCIATIONS', displayPivot: false}};
var technologyAssociation = {bn: 'TEC_ASSOC', columnId: 0, order: 0, sectionBn: 'TEC', size: 'LARGE', configuration: {bn: 'TEC_ASSOC_CONFIG', contentBlockType: 'TECHNOLOGY_ASSOCIATIONS'}};
var technologyPersonAssociations = {bn: 'TEC_ASSOC', columnId: 0, order: 0, sectionBn: 'TEC', size: 'LARGE', name: 'Technologies', configuration: {bn: 'TEC_ASSOC_CONFIG', contentBlockType: 'PROFILE_ENTITY_PERSON_ASSOCIATIONS'}};
var discussionAssociation = {bn: 'TOP_ASSOC', columnId: 0, order: 0, sectionBn: 'TOP', size: 'LARGE', configuration: {bn: 'TOP_ASSOC_CONFIG', contentBlockType: 'DISCUSSION_ASSOCIATIONS'}};
var activityAssociation = {bn: 'EAY_ASSOC', columnId: 0, order: 0, sectionBn: 'EAY', size: 'LARGE', configuration: {bn: 'EAY_ASSOC_CONFIG', contentBlockType: 'ACTIVITY_ASSOCIATIONS'}};
var profileNews = {bn: 'PAY_ASSOC', columnId: 0, order: 0, sectionBn: 'PAY', size: 'LARGE', configuration: {bn: 'PAY_ASSOC_CONFIG', contentBlockType: 'ACTIVITY_ASSOCIATIONS'}};
var circleNews = {bn: 'CAY_ASSOC', columnId: 0, order: 0, sectionBn: 'CAY', size: 'LARGE', configuration: {bn: 'CAY_ASSOC_CONFIG', contentBlockType: 'ACTIVITY_ASSOCIATIONS'}};
var demandNews = {bn: 'DAC_ASSOC', columnId: 0, order: 0, sectionBn: 'DAC', size: 'LARGE', configuration: {bn: 'DAC_ASSOC_CONFIG', contentBlockType: 'ACTIVITY_ASSOCIATIONS'}};
var strategyNews = {bn: 'SAC_ASSOC', columnId: 0, order: 0, sectionBn: 'SAC', size: 'LARGE', configuration: {bn: 'SAC_ASSOC_CONFIG', contentBlockType: 'ACTIVITY_ASSOCIATIONS'}};
var worksheet2Association = {bn: 'WK2_ASSOC', columnId: 0, order: 0, sectionBn: 'WK2', size: 'LARGE', configuration: {bn: 'WK2_ASSOC_CONFIG', contentBlockType: 'WORKSHEET2_ASSOCIATIONS', displayPivot: false}};

///////////////////////////////////
//      Single Entity Layout    //
//////////////////////////////////
var BnCodeToLayout = {};
// Default System Layout
BnCodeToLayout['18'] = {bn: '18',
    countAssocs: sideBarConfigs['18'].single,
    contentBlocks: [
        {bn: '1', columnId: 0, order: 0, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: '1', contentBlockType: 'SYSTEM_BASIC_INFO'}},
        {bn: '3', columnId: 0, order: 0, sectionBn: 'SYS', size: 'LARGE', configuration: {bn: '2', contentBlockType: 'SYSTEM_ARCHITECTURE'}},
        basicInfoSidebarSystemSummaryInfo,
        strategyAssociation,
        strategyCount,
        demandAssociation,
        demandCount,
        orgAssociation,
        orgCount,
        personAssociation,
        contactCount,
        employmentCount,
        companyAssociation,
        companyCount,
        actorAssociation,
        actorCount,
        capabilityAssociationWithDiagrams,
        capabilityCount,
        capabilityEnterpriseApprovePie,
        productAssociation,
        productCount,
        marketCount,
        marketAssociation,
        dataAssociation,
        dataCount,
        dataEnterpriseApprovalPie,
        connectionAssociation,
        connectionCount,
        connectionTypesCount,
        connectionTypesPie,
        connectionPatternsCount,
        connectionPatternsPie,
        connectionTransportsCount,
        connectionTransportsPie,
        technologyAssociation,
        technologyEnterpriseApprovePie,
        technologyCount,
        standardAssociation,
        standardCount,
        skillAssociation,
        skillCount,
        physicalAssociation,
        physicalCount,
        documentAssociationByEntity,
        projectAssociation,
        discussionAssociation,
        activityAssociation,
        //fieldsets
        {bn: 'NEW_CBFS1', columnId: 0, order: 1, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: '1A', contentBlockType: 'FIELDSET', locationId: '00170000002P'}},
        {bn: 'NEW_CBFS2', columnId: 0, order: 1, sectionBn: 'SYS', size: 'LARGE', configuration: {bn: '2A', contentBlockType: 'FIELDSET', locationId: '00170000002R'}},
        {bn: 'NEW_CBFS10', columnId: 0, order: 1, sectionBn: 'ORG', size: 'LARGE', configuration: {bn: '8A', contentBlockType: 'FIELDSET', locationId: '00170000002W'}},
        {bn: 'NEW_CBFS12', columnId: 0, order: 1, sectionBn: 'PER', size: 'LARGE', configuration: {bn: '10A', contentBlockType: 'FIELDSET', locationId: '00170000002X'}},
        {bn: 'NEW_CBFS11', columnId: 0, order: 1, sectionBn: 'COM', size: 'LARGE', configuration: {bn: '9A', contentBlockType: 'FIELDSET', locationId: '001700000048'}},
        {bn: 'NEW_CBFS4', columnId: 0, order: 1, sectionBn: 'ACT', size: 'LARGE', configuration: {bn: '3A', contentBlockType: 'FIELDSET', locationId: '00170000002Q'}},
        {bn: 'NEW_CBFS5', columnId: 0, order: 1, sectionBn: 'CAP', size: 'LARGE', configuration: {bn: '4A', contentBlockType: 'FIELDSET', locationId: '00170000002S'}},
        {bn: 'NEW_CBFS13', columnId: 0, order: 1, sectionBn: 'PRD', size: 'LARGE', configuration: {bn: '10A', contentBlockType: 'FIELDSET', locationId: '001700000030'}},
        {bn: 'NEW_CBFS17', columnId: 0, order: 1, sectionBn: 'MKT', size: 'LARGE', configuration: {bn: '16A', contentBlockType: 'FIELDSET', locationId: '001700000076'}},
        {bn: 'NEW_CBFS8', columnId: 0, order: 1, sectionBn: 'DAT', size: 'LARGE', configuration: {bn: '7B', contentBlockType: 'FIELDSET', locationId: '00170000002V'}},
        {bn: 'NEW_CBFS6', columnId: 0, order: 1, sectionBn: 'CON', size: 'LARGE', configuration: {bn: '5A', contentBlockType: 'FIELDSET', locationId: '00170000002T'}},
        {bn: 'NEW_CBFS16', columnId: 0, order: 1, sectionBn: 'TEC', size: 'LARGE', configuration: {bn: '15A', contentBlockType: 'FIELDSET', locationId: '001700000033'}},
        {bn: 'NEW_CBFS15', columnId: 0, order: 1, sectionBn: 'STA', size: 'LARGE', configuration: {bn: '14A', contentBlockType: 'FIELDSET', locationId: '001700000032'}},
        {bn: 'NEW_CBFS14', columnId: 0, order: 1, sectionBn: 'SKI', size: 'LARGE', configuration: {bn: '13A', contentBlockType: 'FIELDSET', locationId: '001700000031'}},
        {bn: 'NEW_CBFS7', columnId: 0, order: 1, sectionBn: 'PHY', size: 'LARGE', configuration: {bn: '7A', contentBlockType: 'FIELDSET', locationId: '0017000000KQ'}},
        {bn: 'NEW_CBFS9', columnId: 0, order: 1, sectionBn: 'AST', size: 'LARGE', configuration: {bn: '8A', contentBlockType: 'FIELDSET', locationId: '001700000047'}}
    ],
    sections: [
        {bn: 'BASIC', name: 'System Overview', type: 'SystemOverview', order: 0, category: 'primary', assocCode: 'basic', proposedCodes: 'SYS:HAS_CHILD,ALI,PLL,SYS:REPLACES,TAG,LNK'},
        {bn: 'SYS', name: 'System Architecture', type: 'SystemArchitecture', order: 1, category: 'primary', assocCode: 'SYS', proposedCodes: 'SYS:HAS_CHILD'},
        {bn: 'STR', name: 'Related Strategies', type: 'Strategies', order: 2, category: 'related', assocCode: 'STR', proposedCodes: 'STR', roles: 'ROLE_STRATEGY_MODULE_ACCESS'},
        {bn: 'DEM', name: 'Related Demands', type: 'Demands', order: 3, category: 'related', assocCode: 'DEM', proposedCodes: 'DEM', roles: 'ROLE_DEMAND_MODULE_ACCESS'},
        {bn: 'ORG', name: 'Related Organizations', type: 'Organizations', order: 4, category: 'related', assocCode: 'ORG', proposedCodes: 'ORG'},
        {bn: 'PER', name: 'Related People', type: 'People', order: 5, category: 'related', assocCode: 'PER', proposedCodes: 'PER'},
        {bn: 'COM', name: 'Related Companies', type: 'Companies', order: 6, category: 'related', assocCode: 'COM', proposedCodes: 'COM'},
        {bn: 'ACT', name: 'Related Actors', type: 'Actors', order: 7, category: 'related', assocCode: 'ACT', proposedCodes: 'ACT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CAP', name: 'Related Capabilities', type: 'Capabilities', order: 8, category: 'related', assocCode: 'CAP', proposedCodes: 'CAP', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'PRD', name: 'Related Products', type: 'Products', order: 9, category: 'related', assocCode: 'PRD', proposedCodes: 'PRD', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'MKT', name: 'Related Markets', type: 'Markets', order: 10, category: 'related', assocCode: 'MKT', proposedCodes: 'MKT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'DAT', name: 'Related Data', type: 'Data', order: 11, category: 'related', assocCode: 'DAT', proposedCodes: 'DAT', roles: 'ROLE_DATA_MODULE_ACCESS'},
        {bn: 'CON', name: 'Related Connections', type: 'Connections', order: 12, category: 'related', assocCode: 'CON', proposedCodes: 'CON', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TEC', name: 'Related Technologies', type: 'Technologies', order: 13, category: 'related', assocCode: 'TEC', proposedCodes: 'TEC'},
        {bn: 'STA', name: 'Related Standards', type: 'Standards', order: 14, category: 'related', assocCode: 'STA', proposedCodes: 'STA'},
        {bn: 'SKI', name: 'Related Skills', type: 'Skills', order: 15, category: 'related', assocCode: 'SKI', proposedCodes: 'SKI'},
        {bn: 'PHY', name: 'Related Deployments', type: 'Physicals', order: 16, category: 'related', assocCode: 'PHY', proposedCodes: 'PHY', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'AST', name: 'Related Documents', type: 'Documents', order: 17, category: 'related', assocCode: 'AST', proposedCodes: 'AST'},
        {bn: 'TOP', name: 'Related Discussions', type: 'Discussions', order: 18, category: 'related', assocCode: 'TOP', proposedCodes: 'TOP'},
        {bn: 'EAY', name: 'Activity', type: 'Activity', order: 19, category: 'tertiary', assocCode: 'EAY'}
    ]};

// Default Actor Layout
BnCodeToLayout['01'] = {bn: '01',
    countAssocs: sideBarConfigs['01'].single,
    contentBlocks: [
        {bn: 'NEW_CB1', columnId: 0, order: 0, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'ACTOR_BASIC_INFO'}},
        basicInfoSidebarWithSummaryInfoOrganizationsCompanies,
        strategyAssociation,
        strategyCount,
        demandAssociation,
        demandCount,
        orgAssociation,
        orgCount,
        // people
        personAssociation,
        contactCount,
        employmentCount,
        companyAssociation,
        companyCount,
        capabilityAssociation,
        capabilityCount,
        capabilityEnterpriseApprovePie,
        productAssociation,
        productCount,
        marketCount,
        marketAssociation,
        dataAssociation,
        dataCount,
        dataEnterpriseApprovalPie,
        systemAssociation,
        systemCount,
        systemPriorityPie,
        systemCoreSystemsPie,
        connectionAssociationNoDiagram,
        connectionCount,
        connectionTypesCount,
        connectionTypesPie,
        connectionPatternsCount,
        connectionPatternsPie,
        connectionTransportsCount,
        connectionTransportsPie,
        technologyAssociation,
        technologyEnterpriseApprovePie,
        standardAssociation,
        standardCount,
        skillAssociation,
        skillCount,
        physicalAssociation,
        physicalCount,
        documentAssociationByEntity,
        discussionAssociation,
        activityAssociation,

        //fieldsets
        {bn: 'NEW_CBFS1', columnId: 0, order: 1, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '001700000000'}},
        {bn: 'NEW_CBFS11', columnId: 0, order: 1, sectionBn: 'STR', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '001700000053'}},
        {bn: 'NEW_CBFS8', columnId: 0, order: 1, sectionBn: 'DEM', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '001700000060'}},
        {bn: 'NEW_CBFS4', columnId: 0, order: 1, sectionBn: 'ORG', size: 'LARGE', configuration: {bn: 'NEW_CONFIG3A', contentBlockType: 'FIELDSET', locationId: '001700000005'}},
        {bn: 'NEW_CBFS12', columnId: 0, order: 1, sectionBn: 'PER', size: 'LARGE', configuration: {bn: '10A', contentBlockType: 'FIELDSET', locationId: '0017000000M0'}},
        {bn: 'NEW_CBFS5', columnId: 0, order: 1, sectionBn: 'COM', size: 'LARGE', configuration: {bn: 'NEW_CONFIG4A', contentBlockType: 'FIELDSET', locationId: '001700000001'}},
        {bn: 'NEW_CBFS6', columnId: 0, order: 1, sectionBn: 'CAP', size: 'LARGE', configuration: {bn: 'NEW_CONFIG5A', contentBlockType: 'FIELDSET', locationId: '001700000002'}},
        {bn: 'NEW_CBFS9', columnId: 0, order: 1, sectionBn: 'PRD', size: 'LARGE', configuration: {bn: 'NEW_CONFIG7A', contentBlockType: 'FIELDSET', locationId: '001700000049'}},
        {bn: 'NEW_CBFS12', columnId: 0, order: 1, sectionBn: 'MKT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '001700000070'}},
        {bn: 'NEW_CBFS7', columnId: 0, order: 1, sectionBn: 'DAT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG6A', contentBlockType: 'FIELDSET', locationId: '001700000003'}},
        {bn: 'NEW_CBFS6', columnId: 0, order: 1, sectionBn: 'CON', size: 'LARGE', configuration: {bn: '5A', contentBlockType: 'FIELDSET', locationId: '0017000000M2'}},
        {bn: 'NEW_CBFS6', columnId: 0, order: 1, sectionBn: 'TEC', size: 'LARGE', configuration: {bn: '5A', contentBlockType: 'FIELDSET', locationId: '0017000000M8'}},
        {bn: 'NEW_CBFS6', columnId: 0, order: 1, sectionBn: 'STA', size: 'LARGE', configuration: {bn: '5A', contentBlockType: 'FIELDSET', locationId: '0017000000M6'}},
        {bn: 'NEW_CBFS6', columnId: 0, order: 1, sectionBn: 'SKI', size: 'LARGE', configuration: {bn: '5A', contentBlockType: 'FIELDSET', locationId: '0017000000M4'}},
        {bn: 'NEW_CBFS3', columnId: 0, order: 1, sectionBn: 'SYS', size: 'LARGE', configuration: {bn: 'NEW_CONFIG2A', contentBlockType: 'FIELDSET', locationId: '001700000006'}},
        {bn: 'NEW_CBFS7', columnId: 0, order: 1, sectionBn: 'PHY', size: 'LARGE', configuration: {bn: '7A', contentBlockType: 'FIELDSET', locationId: '0017000000K9'}},
        {bn: 'NEW_CBFS10', columnId: 0, order: 1, sectionBn: 'AST', size: 'LARGE', configuration: {bn: 'NEW_CONFIG8A', contentBlockType: 'FIELDSET', locationId: '001700000004'}}
    ],
    sections: [
        {bn: 'BASIC', name: 'Actor Overview', type: 'ActorOverview', order: 0, category: 'primary', proposedCodes: 'PLL, LNK, TAG'},
        {bn: 'STR', name: 'Related Strategies', type: 'Strategies', order: 1, category: 'related', assocCode: 'STR', proposedCodes: 'STR', roles: 'ROLE_STRATEGY_MODULE_ACCESS'},
        {bn: 'DEM', name: 'Related Demands', type: 'Demands', order: 2, category: 'related', assocCode: 'DEM', proposedCodes: 'DEM', roles: 'ROLE_DEMAND_MODULE_ACCESS'},
        {bn: 'ORG', name: 'Related Organizations', type: 'Organizations', order: 3, category: 'related', assocCode: 'ORG', proposedCodes: 'ORG'},
        {bn: 'PER', name: 'Related People', type: 'People', order: 4, category: 'related', assocCode: 'PER', proposedCodes: 'PER'},
        {bn: 'COM', name: 'Related Companies', type: 'Companies', order: 5, category: 'related', assocCode: 'COM', proposedCodes: 'COM'},
        {bn: 'CAP', name: 'Related Capabilities', type: 'Capabilities', order: 6, category: 'related', assocCode: 'CAP', proposedCodes: 'CAP'},
        {bn: 'PRD', name: 'Related Products', type: 'Products', order: 7, category: 'related', assocCode: 'PRD', proposedCodes: 'PRD'},
        {bn: 'MKT', name: 'Related Markets', type: 'Markets', order: 8, category: 'related', assocCode: 'MKT', proposedCodes: 'MKT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'DAT', name: 'Related Data', type: 'Data', order: 9, category: 'related', assocCode: 'DAT', proposedCodes: 'DAT', roles: 'ROLE_DATA_MODULE_ACCESS'},
        {bn: 'CON', name: 'Related Connections', type: 'Connections', order: 10, category: 'related', assocCode: 'CON', proposedCodes: 'CON', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TEC', name: 'Related Technologies', type: 'Technologies', order: 11, category: 'related', assocCode: 'TEC', proposedCodes: 'TEC', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'STA', name: 'Related Standards', type: 'Standards', order: 12, category: 'related', assocCode: 'STA', proposedCodes: 'STA', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'SKI', name: 'Related Skills', type: 'Skills', order: 13, category: 'related', assocCode: 'SKI', proposedCodes: 'SKI', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'SYS', name: 'Related Systems', type: 'Systems', order: 14, category: 'related', assocCode: 'SYS', proposedCodes: 'SYS'},
        {bn: 'PHY', name: 'Related Deployments', type: 'Physicals', order: 15, category: 'related', assocCode: 'PHY', proposedCodes: 'PHY', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'AST', name: 'Related Documents', type: 'Documents', order: 16, category: 'related', assocCode: 'AST', proposedCodes: 'AST'},
        {bn: 'TOP', name: 'Related Discussions', type: 'Discussions', order: 17, category: 'related', assocCode: 'TOP', proposedCodes: 'TOP'},
        {bn: 'EAY', name: 'Activity', type: 'Activity', order: 18, category: 'tertiary', assocCode: 'EAY'}
    ]};

// Default Capability Layout
BnCodeToLayout['0Q'] = {bn: '0Q',
    countAssocs: sideBarConfigs['0Q'].single,
    contentBlocks: [
        {bn: 'NEW_CB1', columnId: 0, order: 0, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'CAPABILITY_BASIC_INFO'}},
        basicInfoSidebarWithSummaryInfo,
        {bn: 'NEW_CBXA1', columnId: 0, order: 0, sectionBn: 'CAP', size: 'LARGE', configuration: {bn: 'NEW_CONFIGXA', contentBlockType: 'CAPABILITY_ARCHITECTURE'}},
        strategyAssociation,
        strategyCount,
        demandAssociation,
        demandCount,
        orgAssociation,
        orgCount,
        personAssociation,
        contactCount,
        employmentCount,
        companyAssociation,
        companyCount,
        actorAssociation,
        actorCount,
        productAssociation,
        productCount,
        marketCount,
        marketAssociation,
        dataAssociation,
        dataCount,
        dataEnterpriseApprovalPie,
        systemAssociation,
        systemCount,
        systemPriorityPie,
        systemCoreSystemsPie,
        connectionAssociationNoDiagram,
        connectionCount,
        connectionTypesCount,
        connectionTypesPie,
        connectionPatternsCount,
        connectionPatternsPie,
        connectionTransportsCount,
        connectionTransportsPie,
        technologyAssociation,
        technologyCount,
        standardAssociation,
        standardCount,
        skillAssociation,
        skillCount,
        physicalAssociation,
        physicalCount,
        documentAssociationByEntity,
        discussionAssociation,
        activityAssociation,

        //fieldsets
        {bn: 'NEW_CBFS1', columnId: 0, order: 1, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '001700000007'}},
        {bn: 'NEW_CBFS2', columnId: 0, order: 1, sectionBn: 'CAP', size: 'LARGE', configuration: {bn: 'NEW_CONFIGXB', contentBlockType: 'FIELDSET', locationId: '00170000003M'}},
        {bn: 'NEW_CBFS16', columnId: 0, order: 1, sectionBn: 'STR', size: 'LARGE', configuration: {bn: 'NEW_CONFIG14A', contentBlockType: 'FIELDSET', locationId: '001700000054'}},
        {bn: 'NEW_CBFS8', columnId: 0, order: 1, sectionBn: 'DEM', size: 'LARGE', configuration: {bn: 'NEW_CONFIG6A', contentBlockType: 'FIELDSET', locationId: '001700000061'}},
        {bn: 'NEW_CBFS17', columnId: 0, order: 1, sectionBn: 'ORG', size: 'LARGE', configuration: {bn: 'NEW_CONFIG15A', contentBlockType: 'FIELDSET', locationId: '00170000004J'}},
        {bn: 'NEW_CBFS10', columnId: 0, order: 1, sectionBn: 'PER', size: 'LARGE', configuration: {bn: 'NEW_CONFIG8A', contentBlockType: 'FIELDSET', locationId: '00170000000F'}},
        {bn: 'NEW_CBFS18', columnId: 0, order: 1, sectionBn: 'COM', size: 'LARGE', configuration: {bn: 'NEW_CONFIG16A', contentBlockType: 'FIELDSET', locationId: '00170000004K'}},
        {bn: 'NEW_CBFS6', columnId: 0, order: 1, sectionBn: 'ACT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG4A', contentBlockType: 'FIELDSET', locationId: '001700000009'}},
        {bn: 'NEW_CBFS12', columnId: 0, order: 1, sectionBn: 'PRD', size: 'LARGE', configuration: {bn: 'NEW_CONFIG10A', contentBlockType: 'FIELDSET', locationId: '001700000008'}},
        {bn: 'NEW_CBFS19', columnId: 0, order: 1, sectionBn: 'MKT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG17A', contentBlockType: 'FIELDSET', locationId: '001700000071'}},
        {bn: 'NEW_CBFS7', columnId: 0, order: 1, sectionBn: 'DAT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG5A', contentBlockType: 'FIELDSET', locationId: '00170000000C'}},
        {bn: 'NEW_CBFS4', columnId: 0, order: 1, sectionBn: 'SYS', size: 'LARGE', configuration: {bn: 'NEW_CONFIG2A', contentBlockType: 'FIELDSET', locationId: '00170000000G'}},
        {bn: 'NEW_CBFS9', columnId: 0, order: 1, sectionBn: 'CON', size: 'LARGE', configuration: {bn: 'NEW_CONFIG7A', contentBlockType: 'FIELDSET', locationId: '00170000000B'}},
        {bn: 'NEW_CBFS13', columnId: 0, order: 1, sectionBn: 'TEC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG11A', contentBlockType: 'FIELDSET', locationId: '00170000004H'}},
        {bn: 'NEW_CBFS14', columnId: 0, order: 1, sectionBn: 'STA', size: 'LARGE', configuration: {bn: 'NEW_CONFIG12A', contentBlockType: 'FIELDSET', locationId: '00170000004G'}},
        {bn: 'NEW_CBFS15', columnId: 0, order: 1, sectionBn: 'SKI', size: 'LARGE', configuration: {bn: 'NEW_CONFIG13A', contentBlockType: 'FIELDSET', locationId: '00170000004F'}},
        {bn: 'NEW_CBFS5', columnId: 0, order: 1, sectionBn: 'PHY', size: 'LARGE', configuration: {bn: 'NEW_CONFIG3A', contentBlockType: 'FIELDSET', locationId: '0017000000KB'}},
        {bn: 'NEW_CBFS11', columnId: 0, order: 1, sectionBn: 'AST', size: 'LARGE', configuration: {bn: 'NEW_CONFIG9A', contentBlockType: 'FIELDSET', locationId: '00170000000D'}}

    ],

    sections: [
        {bn: 'BASIC', name: 'Capability Overview', type: 'CapabilityOverview', order: 0, category: 'primary', assocCode: 'basic', proposedCodes: 'PLL, CAP:RELATED_TO, LNK, TAG'},
        {bn: 'CAP', name: 'Capability Tree', type: 'CapabilityArchitecture', order: 1, category: 'primary', assocCode: 'CAP', proposedCodes: 'CAP, CAP:HAS_CHILD, CAP:HAS_PARENT'},
        {bn: 'STR', name: 'Related Strategies', type: 'Strategies', order: 2, category: 'related', assocCode: 'STR', proposedCodes: 'STR', roles: 'ROLE_STRATEGY_MODULE_ACCESS'},
        {bn: 'DEM', name: 'Related Demands', type: 'Demands', order: 3, category: 'related', assocCode: 'DEM', proposedCodes: 'DEM', roles: 'ROLE_DEMAND_MODULE_ACCESS'},
        {bn: 'ORG', name: 'Related Organizations', type: 'Organizations', order: 4, category: 'related', assocCode: 'ORG', proposedCodes: 'ORG'},
        {bn: 'PER', name: 'Related People', type: 'People', order: 5, category: 'related', assocCode: 'PER', proposedCodes: 'PER'},
        {bn: 'COM', name: 'Related Companies', type: 'Companies', order: 6, category: 'related', assocCode: 'COM', proposedCodes: 'COM'},
        {bn: 'ACT', name: 'Related Actors', type: 'Actors', order: 7, category: 'related', assocCode: 'ACT', proposedCodes: 'ACT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'PRD', name: 'Related Products', type: 'Products', order: 8, category: 'related', assocCode: 'PRD', proposedCodes: 'PRD', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'MKT', name: 'Related Markets', type: 'Markets', order: 9, category: 'related', assocCode: 'MKT', proposedCodes: 'MKT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'DAT', name: 'Related Data', type: 'Data', order: 10, category: 'related', assocCode: 'DAT', proposedCodes: 'DAT', roles: 'ROLE_DATA_MODULE_ACCESS'},
        {bn: 'SYS', name: 'Related Systems', type: 'Systems', order: 11, category: 'related', assocCode: 'SYS', proposedCodes: 'SYS', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CON', name: 'Related Connections', type: 'Connections', order: 12, category: 'related', assocCode: 'CON', proposedCodes: 'CON', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TEC', name: 'Related Technologies', type: 'Technologies', order: 13, category: 'related', assocCode: 'TEC', proposedCodes: 'TEC'},
        {bn: 'STA', name: 'Related Standards', type: 'Standards', order: 14, category: 'related', assocCode: 'STA', proposedCodes: 'STA'},
        {bn: 'SKI', name: 'Related Skills', type: 'Skills', order: 15, category: 'related', assocCode: 'SKI', proposedCodes: 'SKI'},
        {bn: 'PHY', name: 'Related Deployments', type: 'Physicals', order: 16, category: 'related', assocCode: 'PHY', proposedCodes: 'PHY', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'AST', name: 'Related Documents', type: 'Documents', order: 17, category: 'related', assocCode: 'AST', proposedCodes: 'AST'},
        {bn: 'TOP', name: 'Related Discussions', type: 'Discussions', order: 18, category: 'related', assocCode: 'TOP', proposedCodes: 'TOP'},
        {bn: 'EAY', name: 'Activity', type: 'Activity', order: 19, category: 'tertiary', assocCode: 'EAY'}
    ]};

// Default Data Layout
BnCodeToLayout['44'] = {bn: '44',
    countAssocs: sideBarConfigs['44'].single,
    contentBlocks: [
        {bn: 'NEW_CB1', columnId: 0, order: 0, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'DATA_BASIC_INFO'}},
        basicInfoSidebarWithSummaryInfo,
        {bn: 'NEW_CB3', columnId: 0, order: 0, sectionBn: 'DAT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG2', contentBlockType: 'DATA_ARCHITECTURE'}},
        strategyAssociation,
        strategyCount,
        demandAssociation,
        demandCount,
        orgAssociation,
        orgCount,
        personAssociation,
        contactCount,
        employmentCount,

        companyAssociation,
        companyCount,
        actorAssociation,
        actorCount,
        capabilityAssociation,
        capabilityCount,
        capabilityEnterpriseApprovePie,
        productCount,
        productAssociation,
        marketCount,
        marketAssociation,
        systemAssociation,
        systemCount,
        systemPriorityPie,
        systemCoreSystemsPie,
        connectionAssociationNoDiagram,
        connectionCount,
        connectionTypesCount,
        connectionTypesPie,
        connectionPatternsCount,
        connectionPatternsPie,
        connectionTransportsCount,
        connectionTransportsPie,
        technologyAssociation,
        technologyCount,
        standardAssociation,
        standardCount,
        skillAssociation,
        skillCount,
        physicalAssociation,
        physicalCount,
        documentAssociationByEntity,
        discussionAssociation,
        activityAssociation,
        //fieldsets
        {bn: 'NEW_CBFS1', columnId: 0, order: 1, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '001700000012'}},
        {bn: 'NEW_CBFS2', columnId: 0, order: 1, sectionBn: 'DAT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG2A', contentBlockType: 'FIELDSET', locationId: '00170000003N'}},
        {bn: 'NEW_CBFS3', columnId: 0, order: 1, sectionBn: 'STR', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '001700000057'}},
        {bn: 'NEW_CBFS4', columnId: 0, order: 1, sectionBn: 'DEM', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '001700000064'}},
        {bn: 'NEW_CBFS5', columnId: 0, order: 1, sectionBn: 'ORG', size: 'LARGE', configuration: {bn: 'NEW_CONFIG3A', contentBlockType: 'FIELDSET', locationId: '00170000004W'}},
        {bn: 'NEW_CBFS11', columnId: 0, order: 1, sectionBn: 'PER', size: 'LARGE', configuration: {bn: 'NEW_CONFIG7A', contentBlockType: 'FIELDSET', locationId: '001700000019'}},
        {bn: 'NEW_CBFS6', columnId: 0, order: 1, sectionBn: 'COM', size: 'LARGE', configuration: {bn: 'NEW_CONFIG3A', contentBlockType: 'FIELDSET', locationId: '00170000004V'}},
        {bn: 'NEW_CBFS9', columnId: 0, order: 1, sectionBn: 'ACT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG5A', contentBlockType: 'FIELDSET', locationId: '001700000014'}},
        {bn: 'NEW_CBFS8', columnId: 0, order: 1, sectionBn: 'CAP', size: 'LARGE', configuration: {bn: 'NEW_CONFIG4A', contentBlockType: 'FIELDSET', locationId: '001700000015'}},
        {bn: 'NEW_CBFS13', columnId: 0, order: 1, sectionBn: 'PRD', size: 'LARGE', configuration: {bn: 'NEW_CONFIG8A', contentBlockType: 'FIELDSET', locationId: '00170000007V'}},
        {bn: 'NEW_CBFS14', columnId: 0, order: 1, sectionBn: 'MKT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG8A', contentBlockType: 'FIELDSET', locationId: '001700000074'}},
        {bn: 'NEW_CBFS7', columnId: 0, order: 1, sectionBn: 'SYS', size: 'LARGE', configuration: {bn: 'NEW_CONFIG3A', contentBlockType: 'FIELDSET', locationId: '00170000003P'}},
        {bn: 'NEW_CBFS10', columnId: 0, order: 1, sectionBn: 'CON', size: 'LARGE', configuration: {bn: 'NEW_CONFIG6A', contentBlockType: 'FIELDSET', locationId: '001700000016'}},
        {bn: 'NEW_CBFS16', columnId: 0, order: 1, sectionBn: 'TEC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG6A', contentBlockType: 'FIELDSET', locationId: '0017000000MK'}},
        {bn: 'NEW_CBFS14', columnId: 0, order: 1, sectionBn: 'STA', size: 'LARGE', configuration: {bn: 'NEW_CONFIG12A', contentBlockType: 'FIELDSET', locationId: '0017000000MI'}},
        {bn: 'NEW_CBFS15', columnId: 0, order: 1, sectionBn: 'SKI', size: 'LARGE', configuration: {bn: 'NEW_CONFIG12A', contentBlockType: 'FIELDSET', locationId: '0017000000MG'}},
        {bn: 'NEW_CBFS7', columnId: 0, order: 1, sectionBn: 'PHY', size: 'LARGE', configuration: {bn: '7A', contentBlockType: 'FIELDSET', locationId: '0017000000KF'}},
        {bn: 'NEW_CBFS12', columnId: 0, order: 1, sectionBn: 'AST', size: 'LARGE', configuration: {bn: 'NEW_CONFIG8A', contentBlockType: 'FIELDSET', locationId: '001700000018'}}

    ],
    sections: [
        {bn: 'BASIC', name: 'Data Overview', type: 'DataOverview', order: 0, category: 'primary', proposedCodes: 'PLL, LNK, TAG'},
        {bn: 'DAT', name: 'Data Architecture', type: 'DataArchitecture', order: 1, category: 'primary', assocCode: 'DAT', proposedCodes: 'DAT'},
        {bn: 'STR', name: 'Related Strategies', type: 'Strategies', order: 2, category: 'related', assocCode: 'STR', proposedCodes: 'STR', roles: 'ROLE_STRATEGY_MODULE_ACCESS'},
        {bn: 'DEM', name: 'Related Demands', type: 'Demands', order: 3, category: 'related', assocCode: 'DEM', proposedCodes: 'DEM', roles: 'ROLE_DEMAND_MODULE_ACCESS'},
        {bn: 'ORG', name: 'Related Organizations', type: 'Organizations', order: 4, category: 'related', assocCode: 'ORG', proposedCodes: 'ORG'},
        {bn: 'PER', name: 'Related People', type: 'People', order: 5, category: 'related', assocCode: 'PER', proposedCodes: 'PER'},
        {bn: 'COM', name: 'Related Companies', type: 'Companies', order: 6, category: 'related', assocCode: 'COM', proposedCodes: 'COM'},
        {bn: 'ACT', name: 'Related Actors', type: 'Actors', order: 7, category: 'related', assocCode: 'ACT', proposedCodes: 'ACT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CAP', name: 'Related Capabilities', type: 'Capabilities', order: 8, category: 'related', assocCode: 'CAP', proposedCodes: 'CAP', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'PRD', name: 'Related Products', type: 'Products', order: 9, category: 'related', assocCode: 'PRD', proposedCodes: 'PRD', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'MKT', name: 'Related Markets', type: 'Markets', order: 10, category: 'related', assocCode: 'MKT', proposedCodes: 'MKT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'SYS', name: 'Related Systems', type: 'Systems', order: 11, category: 'related', assocCode: 'SYS', proposedCodes: 'SYS', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CON', name: 'Related Connections', type: 'Connections', order: 12, category: 'related', assocCode: 'CON', proposedCodes: 'CON', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TEC', name: 'Related Technologies', type: 'Technologies', order: 13, category: 'related', assocCode: 'TEC', proposedCodes: 'TEC'},
        {bn: 'STA', name: 'Related Standards', type: 'Standards', order: 14, category: 'related', assocCode: 'STA', proposedCodes: 'STA'},
        {bn: 'SKI', name: 'Related Skills', type: 'Skills', order: 15, category: 'related', assocCode: 'SKI', proposedCodes: 'SKI'},
        {bn: 'PHY', name: 'Related Deployments', type: 'Physicals', order: 16, category: 'related', assocCode: 'PHY', proposedCodes: 'PHY', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'AST', name: 'Related Documents', type: 'Documents', order: 17, category: 'related', assocCode: 'AST', proposedCodes: 'AST'},
        {bn: 'TOP', name: 'Related Discussions', type: 'Discussions', order: 18, category: 'related', assocCode: 'TOP'},
        {bn: 'EAY', name: 'Activity', type: 'Activity', order: 19, category: 'tertiary', assocCode: 'EAY'}
    ]};

// Default Demand Layout
BnCodeToLayout['39'] = {bn: '39',
    countAssocs: sideBarConfigs['39'].single,
    contentBlocks: [
        {bn: 'NEW_CB1', columnId: 0, order: 0, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'DEMAND_BASIC_INFO'}},
        basicInfoSidebarWithSummaryInfo,
        {bn: 'NEW_DMXA1', columnId: 0, order: 0, sectionBn: 'DEM', size: 'LARGE', configuration: {bn: 'NEW_CONFIGXA', contentBlockType: 'DEMAND_ARCHITECTURE'}},
        demandNews,
        demandRelatedAssociation,
        demandRelatedCount,
        strategyAssociation,
        strategyCount,
        orgAssociation,
        orgCount,
        personAssociation,
        contactCount,
        employmentCount,
        companyAssociation,
        companyCount,
        actorAssociation,
        actorCount,
        capabilityAssociation,
        capabilityCount,
        productAssociation,
        productCount,
        marketCount,
        marketAssociation,
        dataAssociation,
        dataCount,
        dataEnterpriseApprovalPie,
        systemAssociation,
        systemCount,
        systemPriorityPie,
        systemCoreSystemsPie,
        connectionAssociationNoDiagram,
        connectionCount,
        connectionTypesCount,
        connectionTypesPie,
        connectionPatternsCount,
        connectionPatternsPie,
        connectionTransportsCount,
        connectionTransportsPie,
        technologyAssociation,
        technologyCount,
        standardAssociation,
        standardCount,
        skillAssociation,
        skillCount,
        physicalAssociation,
        physicalCount,
        documentAssociationByEntity,
        discussionAssociation,
        activityAssociation,

        {bn: 'NEW_CBFS1', columnId: 0, order: 1, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '001700000065'}},
        {bn: 'NEW_CBFS2', columnId: 0, order: 1, sectionBn: 'DEM', size: 'LARGE', configuration: {bn: 'NEW_CONFIGXB', contentBlockType: 'FIELDSET', locationId: '001700000066'}},
        {bn: 'NEW_CBFS3', columnId: 0, order: 1, sectionBn: 'DEM', size: 'LARGE', configuration: {bn: 'NEW_CONFIGXB', contentBlockType: 'FIELDSET', locationId: '00170000006P'}},
        {bn: 'NEW_CBFS4', columnId: 0, order: 1, sectionBn: 'STR', size: 'LARGE', configuration: {bn: 'NEW_CONFIGXB', contentBlockType: 'FIELDSET', locationId: '001700000067'}},
        {bn: 'NEW_CBFS15', columnId: 0, order: 1, sectionBn: 'ORG', size: 'LARGE', configuration: {bn: 'NEW_CONFIG13A', contentBlockType: 'FIELDSET', locationId: '001700000068'}},
        {bn: 'NEW_CBFS10', columnId: 0, order: 1, sectionBn: 'PER', size: 'LARGE', configuration: {bn: 'NEW_CONFIG6A', contentBlockType: 'FIELDSET', locationId: '001700000069'}},
        {bn: 'NEW_CBFS16', columnId: 0, order: 1, sectionBn: 'COM', size: 'LARGE', configuration: {bn: 'NEW_CONFIG14A', contentBlockType: 'FIELDSET', locationId: '00170000006B'}},
        {bn: 'NEW_CBFS6', columnId: 0, order: 1, sectionBn: 'ACT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG3A', contentBlockType: 'FIELDSET', locationId: '00170000006C'}},
        {bn: 'NEW_CBFS8', columnId: 0, order: 1, sectionBn: 'CAP', size: 'LARGE', configuration: {bn: 'NEW_CONFIG5A', contentBlockType: 'FIELDSET', locationId: '00170000006D'}},
        {bn: 'NEW_CBFS11', columnId: 0, order: 1, sectionBn: 'PRD', size: 'LARGE', configuration: {bn: 'NEW_CONFIG9A', contentBlockType: 'FIELDSET', locationId: '00170000006F'}},
        {bn: 'NEW_CBFS17', columnId: 0, order: 1, sectionBn: 'MKT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG14A', contentBlockType: 'FIELDSET', locationId: '001700000075'}},
        {bn: 'NEW_CBFS7', columnId: 0, order: 1, sectionBn: 'DAT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG4A', contentBlockType: 'FIELDSET', locationId: '00170000006H'}},
        {bn: 'NEW_CBFS5', columnId: 0, order: 1, sectionBn: 'SYS', size: 'LARGE', configuration: {bn: 'NEW_CONFIG2A', contentBlockType: 'FIELDSET', locationId: '00170000006G'}},
        {bn: 'NEW_CBFS9', columnId: 0, order: 1, sectionBn: 'CON', size: 'LARGE', configuration: {bn: 'NEW_CONFIG5A', contentBlockType: 'FIELDSET', locationId: '00170000006J'}},
        {bn: 'NEW_CBFS12', columnId: 0, order: 1, sectionBn: 'TEC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG10A', contentBlockType: 'FIELDSET', locationId: '00170000006K'}},
        {bn: 'NEW_CBFS13', columnId: 0, order: 1, sectionBn: 'STA', size: 'LARGE', configuration: {bn: 'NEW_CONFIG11A', contentBlockType: 'FIELDSET', locationId: '00170000006L'}},
        {bn: 'NEW_CBFS14', columnId: 0, order: 1, sectionBn: 'SKI', size: 'LARGE', configuration: {bn: 'NEW_CONFIG12A', contentBlockType: 'FIELDSET', locationId: '00170000006M'}},
        {bn: 'NEW_CBFS7', columnId: 0, order: 1, sectionBn: 'PHY', size: 'LARGE', configuration: {bn: '7A', contentBlockType: 'FIELDSET', locationId: '0017000000KG'}}

    ],

    sections: [
        {bn: 'BASIC', name: 'Demand Overview', type: 'DemandOverview', order: 0, category: 'primary', proposedCodes: 'PLL, DEM:HAS_PARENT, DEM:HAS_CHILD, LNK, TAG'},
        {bn: 'DEM', name: 'Demand Model', type: 'DemandArchitecture', order: 1, category: 'primary', assocCode: 'DEM', proposedCodes: 'DEM:HAS_PARENT, DEM:HAS_CHILD, DEM:RELATED_TO'},
        {bn: 'DAC', name: 'News', type: 'DemandNews', order: 2, category: 'primary', assocCode: 'DAC'},
        {bn: 'STR', name: 'Related Strategies', type: 'Strategies', order: 3, category: 'related', assocCode: 'STR', proposedCodes: 'STR', roles: 'ROLE_STRATEGY_MODULE_ACCESS'},
        {bn: 'ORG', name: 'Related Organizations', type: 'Organizations', order: 4, category: 'related', assocCode: 'ORG', proposedCodes: 'ORG'},
        {bn: 'PER', name: 'Related People', type: 'People', order: 5, category: 'related', assocCode: 'PER', proposedCodes: 'PER'},
        {bn: 'COM', name: 'Related Companies', type: 'Companies', order: 6, category: 'related', assocCode: 'COM', proposedCodes: 'COM'},
        {bn: 'ACT', name: 'Related Actors', type: 'Actors', order: 7, category: 'related', assocCode: 'ACT', proposedCodes: 'ACT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CAP', name: 'Related Capabilities', type: 'Capabilities', order: 8, category: 'related', assocCode: 'CAP', proposedCodes: 'CAP', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'PRD', name: 'Related Products', type: 'Products', order: 9, category: 'related', assocCode: 'PRD', proposedCodes: 'PRD', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'MKT', name: 'Related Markets', type: 'Markets', order: 10, category: 'related', assocCode: 'MKT', proposedCodes: 'MKT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'DAT', name: 'Related Data', type: 'Data', order: 11, category: 'related', assocCode: 'DAT', proposedCodes: 'DAT', roles: 'ROLE_DATA_MODULE_ACCESS'},
        {bn: 'SYS', name: 'Related Systems', type: 'Systems', order: 12, category: 'related', assocCode: 'SYS', proposedCodes: 'SYS', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CON', name: 'Related Connections', type: 'Connections', order: 13, category: 'related', assocCode: 'CON', proposedCodes: 'CON', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TEC', name: 'Related Technologies', type: 'Technologies', order: 14, category: 'related', assocCode: 'TEC', proposedCodes: 'TEC'},
        {bn: 'STA', name: 'Related Standards', type: 'Standards', order: 15, category: 'related', assocCode: 'STA', proposedCodes: 'STA'},
        {bn: 'SKI', name: 'Related Skills', type: 'Skills', order: 16, category: 'related', assocCode: 'SKI', proposedCodes: 'SKI'},
        {bn: 'PHY', name: 'Related Deployments', type: 'Physicals', order: 17, category: 'related', assocCode: 'PHY', proposedCodes: 'PHY', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'AST', name: 'Related Documents', type: 'Documents', order: 18, category: 'related', assocCode: 'AST', proposedCodes: 'AST'},
        {bn: 'TOP', name: 'Related Discussions', type: 'Discussions', order: 19, category: 'related', assocCode: 'TOP', proposedCodes: 'TOP'},
        {bn: 'EAY', name: 'Activity', type: 'Activity', order: 20, category: 'tertiary', assocCode: 'EAY'}
    ]};

// Default Product Layout
BnCodeToLayout['0Z'] = {bn: '0Z',
    countAssocs: sideBarConfigs['0Z'].single,
    contentBlocks: [
        {bn: 'NEW_CB1', columnId: 0, order: 0, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'PRODUCT_BASIC_INFO'}},
        {bn: 'NEW_CBXA1', columnId: 0, order: 0, sectionBn: 'PRD', size: 'LARGE', configuration: {bn: 'NEW_CONFIGXA', contentBlockType: 'PRODUCT_ARCHITECTURE'}},
        basicInfoSidebarWithSummaryInfo,
        strategyAssociation,
        strategyCount,
        demandAssociation,
        demandCount,
        orgAssociation,
        orgCount,
        personAssociation,
        contactCount,
        employmentCount,
        companyAssociation,
        companyCount,
        actorAssociation,
        actorCount,
        capabilityAssociation,
        capabilityCount,
        capabilityEnterpriseApprovePie,
        marketCount,
        marketAssociation,
        dataAssociation,
        dataCount,
        systemAssociation,
        systemCount,
        systemPriorityPie,
        systemCoreSystemsPie,
        connectionAssociationNoDiagram,
        connectionCount,
        technologyCount,
        technologyAssociation,
        technologyEnterpriseApprovePie,
        standardAssociation,
        standardCount,
        skillAssociation,
        skillCount,
        physicalAssociation,
        physicalCount,
        documentAssociationByEntity,
        discussionAssociation,
        activityAssociation,
        //fieldsets
        {bn: 'NEW_CBFS1', columnId: 0, order: 1, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '00170000001K'}},
        {bn: 'NEW_CBFS2', columnId: 0, order: 1, sectionBn: 'STR', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '00170000005B'}},
        {bn: 'NEW_CBFS3', columnId: 0, order: 1, sectionBn: 'DEM', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '00170000006S'}},
        {bn: 'NEW_CBFS7', columnId: 0, order: 1, sectionBn: 'ORG', size: 'LARGE', configuration: {bn: 'NEW_CONFIG9A', contentBlockType: 'FIELDSET', locationId: '00170000001L'}},
        {bn: 'NEW_CBFS9', columnId: 0, order: 1, sectionBn: 'PER', size: 'LARGE', configuration: {bn: 'NEW_CONFIG6A', contentBlockType: 'FIELDSET', locationId: '00170000001S'}},
        {bn: 'NEW_CBFS8', columnId: 0, order: 1, sectionBn: 'COM', size: 'LARGE', configuration: {bn: 'NEW_CONFIG5A', contentBlockType: 'FIELDSET', locationId: '00170000001P'}},
        {bn: 'NEW_CBFS6', columnId: 0, order: 1, sectionBn: 'ACT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG4A', contentBlockType: 'FIELDSET', locationId: '00170000001M'}},
        {bn: 'NEW_CBFS5', columnId: 0, order: 1, sectionBn: 'CAP', size: 'LARGE', configuration: {bn: 'NEW_CONFIG3A', contentBlockType: 'FIELDSET', locationId: '00170000001N'}},
        {bn: 'NEW_CBFS16', columnId: 0, order: 1, sectionBn: 'MKT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG7A', contentBlockType: 'FIELDSET', locationId: '00170000007R'}},
        {bn: 'NEW_CBFS11', columnId: 0, order: 1, sectionBn: 'DAT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG7A', contentBlockType: 'FIELDSET', locationId: '001700000082'}},
        {bn: 'NEW_CBFS4', columnId: 0, order: 1, sectionBn: 'SYS', size: 'LARGE', configuration: {bn: 'NEW_CONFIG2A', contentBlockType: 'FIELDSET', locationId: '00170000001T'}},
        {bn: 'NEW_CBFS12', columnId: 0, order: 1, sectionBn: 'CON', size: 'LARGE', configuration: {bn: 'NEW_CONFIG7A', contentBlockType: 'FIELDSET', locationId: '001700000085'}},
        {bn: 'NEW_CBFS13', columnId: 0, order: 1, sectionBn: 'TEC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG7A', contentBlockType: 'FIELDSET', locationId: '001700000086'}},
        {bn: 'NEW_CBFS14', columnId: 0, order: 1, sectionBn: 'STA', size: 'LARGE', configuration: {bn: 'NEW_CONFIG7A', contentBlockType: 'FIELDSET', locationId: '001700000084'}},
        {bn: 'NEW_CBFS15', columnId: 0, order: 1, sectionBn: 'SKI', size: 'LARGE', configuration: {bn: 'NEW_CONFIG7A', contentBlockType: 'FIELDSET', locationId: '001700000083'}},
        {bn: 'NEW_CBFS7', columnId: 0, order: 1, sectionBn: 'PHY', size: 'LARGE', configuration: {bn: '7A', contentBlockType: 'FIELDSET', locationId: '0017000000KL'}},
        {bn: 'NEW_CBFS10', columnId: 0, order: 1, sectionBn: 'AST', size: 'LARGE', configuration: {bn: 'NEW_CONFIG7A', contentBlockType: 'FIELDSET', locationId: '00170000001Q'}}

    ],
    sections: [
        {bn: 'BASIC', name: 'Product Overview', type: 'ProductOverview', order: 0, category: 'primary', proposedCodes: 'PLL, LNK, TAG'},
        {bn: 'PRD', name: 'Product Model', type: 'ProductArchitecture', order: 1, category: 'primary', assocCode: 'PRD', proposedCodes: 'PRD:HAS_PARENT, PRD:HAS_CHILD'},
        {bn: 'STR', name: 'Related Strategies', type: 'Strategies', order: 2, category: 'related', assocCode: 'STR', proposedCodes: 'STR', roles: 'ROLE_STRATEGY_MODULE_ACCESS'},
        {bn: 'DEM', name: 'Related Demands', type: 'Demands', order: 3, category: 'related', assocCode: 'DEM', proposedCodes: 'DEM', roles: 'ROLE_DEMAND_MODULE_ACCESS'},
        {bn: 'ORG', name: 'Related Organizations', type: 'Organizations', order: 4, category: 'related', assocCode: 'ORG', proposedCodes: 'ORG'},
        {bn: 'PER', name: 'Related People', type: 'People', order: 5, category: 'related', assocCode: 'PER', proposedCodes: 'PER'},
        {bn: 'COM', name: 'Related Companies', type: 'Companies', order: 6, category: 'related', assocCode: 'COM', proposedCodes: 'COM'},
        {bn: 'ACT', name: 'Related Actors', type: 'Actors', order: 7, category: 'related', assocCode: 'ACT', proposedCodes: 'ACT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CAP', name: 'Related Capabilities', type: 'Capabilities', order: 8, category: 'related', assocCode: 'CAP', proposedCodes: 'CAP', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'MKT', name: 'Related Markets', type: 'Markets', order: 9, category: 'related', assocCode: 'MKT', proposedCodes: 'MKT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'DAT', name: 'Related Data', type: 'Data', order: 10, category: 'related', assocCode: 'DAT', proposedCodes: 'DAT', roles: 'ROLE_DATA_MODULE_ACCESS'},
        {bn: 'SYS', name: 'Related Systems', type: 'Systems', order: 11, category: 'related', assocCode: 'SYS', proposedCodes: 'SYS', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CON', name: 'Related Connections', type: 'Connections', order: 12, category: 'related', assocCode: 'CON', proposedCodes: 'CON', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TEC', name: 'Related Technologies', type: 'Technologies', order: 13, category: 'related', assocCode: 'TEC', proposedCodes: 'TEC'},
        {bn: 'STA', name: 'Related Standards', type: 'Standards', order: 14, category: 'related', assocCode: 'STA', proposedCodes: 'STA'},
        {bn: 'SKI', name: 'Related Skills', type: 'Skills', order: 15, category: 'related', assocCode: 'SKI', proposedCodes: 'SKI'},
        {bn: 'PHY', name: 'Related Deployments', type: 'Physicals', order: 16, category: 'related', assocCode: 'PHY', proposedCodes: 'PHY', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'AST', name: 'Related Documents', type: 'Documents', order: 17, category: 'related', assocCode: 'AST', proposedCodes: 'AST'},
        {bn: 'TOP', name: 'Related Discussions', type: 'Discussions', order: 18, category: 'related', assocCode: 'TOP'},
        {bn: 'EAY', name: 'Activity', type: 'Activity', order: 19, category: 'tertiary', assocCode: 'EAY'}
    ]};


// Default Physical Layout
BnCodeToLayout['4L'] = {bn: '4L',
    countAssocs: sideBarConfigs['4L'].single,
    contentBlocks: [
        {bn: 'NEW_CB1', columnId: 0, order: 0, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'PHYSICAL_BASIC_INFO'}},
        basicInfoSidebarWithSummaryInfo,
        {bn: 'NEW_PBXA1', columnId: 0, order: 0, sectionBn: 'PHY', size: 'LARGE', configuration: {bn: 'NEW_CONFIG2', contentBlockType: 'PHYSICAL_ARCHITECTURE'}},
        physicalRelatedAssociation,
        physicalRelatedCount,
        strategyAssociation,
        strategyCount,
        demandAssociation,
        demandCount,
        orgAssociation,
        orgCount,
        personAssociation,
        contactCount,
        employmentCount,
        companyAssociation,
        companyCount,
        actorAssociation,
        actorCount,
        capabilityAssociation,
        capabilityCount,
        capabilityEnterpriseApprovePie,
        productAssociation,
        productCount,
        marketCount,
        marketAssociation,
        dataAssociation,
        dataCount,
        systemAssociation,
        systemCount,
        systemPriorityPie,
        systemCoreSystemsPie,
        connectionAssociationNoDiagram,
        connectionCount,
        technologyCount,
        technologyAssociation,
        technologyEnterpriseApprovePie,
        standardAssociation,
        standardCount,
        skillAssociation,
        skillCount,
        documentAssociationByEntity,
        discussionAssociation,
        activityAssociation,
        //fieldsets
        {bn: 'NEW_CBFS1', columnId: 0, order: 1, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '0017000000JN'}},
        {bn: 'NEW_CBFS2', columnId: 0, order: 1, sectionBn: 'PHY', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '0017000000JP'}},
        {bn: 'NEW_CBFS3', columnId: 0, order: 1, sectionBn: 'PHY', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '0017000000K6'}},
        {bn: 'NEW_CBFS4', columnId: 0, order: 1, sectionBn: 'STR', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1B', contentBlockType: 'FIELDSET', locationId: '0017000000JQ'}},
        {bn: 'NEW_CBFS5', columnId: 0, order: 1, sectionBn: 'DEM', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1C', contentBlockType: 'FIELDSET', locationId: '0017000000K8'}},
        {bn: 'NEW_CBFS9', columnId: 0, order: 1, sectionBn: 'ORG', size: 'LARGE', configuration: {bn: 'NEW_CONFIG9A', contentBlockType: 'FIELDSET', locationId: '0017000000JR'}},
        {bn: 'NEW_CBFS11', columnId: 0, order: 1, sectionBn: 'PER', size: 'LARGE', configuration: {bn: 'NEW_CONFIG6A', contentBlockType: 'FIELDSET', locationId: '0017000000JS'}},
        {bn: 'NEW_CBFS10', columnId: 0, order: 1, sectionBn: 'COM', size: 'LARGE', configuration: {bn: 'NEW_CONFIG5A', contentBlockType: 'FIELDSET', locationId: '0017000000JT'}},
        {bn: 'NEW_CBFS8', columnId: 0, order: 1, sectionBn: 'ACT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG4A', contentBlockType: 'FIELDSET', locationId: '0017000000JV'}},
        {bn: 'NEW_CBFS7', columnId: 0, order: 1, sectionBn: 'CAP', size: 'LARGE', configuration: {bn: 'NEW_CONFIG3A', contentBlockType: 'FIELDSET', locationId: '0017000000JW'}},
        {bn: 'NEW_CBFS19', columnId: 0, order: 1, sectionBn: 'PRD', size: 'LARGE', configuration: {bn: 'NEW_CONFIG9A', contentBlockType: 'FIELDSET', locationId: '0017000000JX'}},
        {bn: 'NEW_CBFS18', columnId: 0, order: 1, sectionBn: 'MKT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG7A', contentBlockType: 'FIELDSET', locationId: '0017000000K7'}},
        {bn: 'NEW_CBFS13', columnId: 0, order: 1, sectionBn: 'DAT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG7A', contentBlockType: 'FIELDSET', locationId: '0017000000K0'}},
        {bn: 'NEW_CBFS6', columnId: 0, order: 1, sectionBn: 'SYS', size: 'LARGE', configuration: {bn: 'NEW_CONFIG2A', contentBlockType: 'FIELDSET', locationId: '0017000000JZ'}},
        {bn: 'NEW_CBFS14', columnId: 0, order: 1, sectionBn: 'CON', size: 'LARGE', configuration: {bn: 'NEW_CONFIG7A', contentBlockType: 'FIELDSET', locationId: '0017000000K1'}},
        {bn: 'NEW_CBFS15', columnId: 0, order: 1, sectionBn: 'TEC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG7A', contentBlockType: 'FIELDSET', locationId: '0017000000K2'}},
        {bn: 'NEW_CBFS16', columnId: 0, order: 1, sectionBn: 'STA', size: 'LARGE', configuration: {bn: 'NEW_CONFIG7A', contentBlockType: 'FIELDSET', locationId: '0017000000K3'}},
        {bn: 'NEW_CBFS17', columnId: 0, order: 1, sectionBn: 'SKI', size: 'LARGE', configuration: {bn: 'NEW_CONFIG7A', contentBlockType: 'FIELDSET', locationId: '0017000000K4'}},
        {bn: 'NEW_CBFS12', columnId: 0, order: 1, sectionBn: 'AST', size: 'LARGE', configuration: {bn: 'NEW_CONFIG7A', contentBlockType: 'FIELDSET', locationId: '0017000000K5'}}

    ],
    sections: [
        {bn: 'BASIC', name: 'Deployment Overview', type: 'PhysicalOverview', order: 0, category: 'primary', proposedCodes: 'PLL, LNK, TAG'},
        {bn: 'PHY', name: 'Deployment Model', type: 'PhysicalArchitecture', order: 1, category: 'primary', assocCode: 'PHY', proposedCodes: 'PHY:RELATED_TO, PHY:HAS_PARENT, PHY:HAS_CHILD'},
        {bn: 'STR', name: 'Related Strategies', type: 'Strategies', order: 2, category: 'related', assocCode: 'STR', proposedCodes: 'STR', roles: 'ROLE_STRATEGY_MODULE_ACCESS'},
        {bn: 'DEM', name: 'Related Demands', type: 'Demands', order: 3, category: 'related', assocCode: 'DEM', proposedCodes: 'DEM', roles: 'ROLE_DEMAND_MODULE_ACCESS'},
        {bn: 'ORG', name: 'Related Organizations', type: 'Organizations', order: 4, category: 'related', assocCode: 'ORG', proposedCodes: 'ORG'},
        {bn: 'PER', name: 'Related People', type: 'People', order: 5, category: 'related', assocCode: 'PER', proposedCodes: 'PER'},
        {bn: 'COM', name: 'Related Companies', type: 'Companies', order: 6, category: 'related', assocCode: 'COM', proposedCodes: 'COM'},
        {bn: 'ACT', name: 'Related Actors', type: 'Actors', order: 7, category: 'related', assocCode: 'ACT', proposedCodes: 'ACT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CAP', name: 'Related Capabilities', type: 'Capabilities', order: 8, category: 'related', assocCode: 'CAP', proposedCodes: 'CAP', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'PRD', name: 'Related Products', type: 'Products', order: 9, category: 'related', assocCode: 'PRD', proposedCodes: 'PRD', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'MKT', name: 'Related Markets', type: 'Markets', order: 10, category: 'related', assocCode: 'MKT', proposedCodes: 'MKT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'DAT', name: 'Related Data', type: 'Data', order: 11, category: 'related', assocCode: 'DAT', proposedCodes: 'DAT', roles: 'ROLE_DATA_MODULE_ACCESS'},
        {bn: 'SYS', name: 'Related Systems', type: 'Systems', order: 12, category: 'related', assocCode: 'SYS', proposedCodes: 'SYS', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CON', name: 'Related Connections', type: 'Connections', order: 13, category: 'related', assocCode: 'CON', proposedCodes: 'CON', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TEC', name: 'Related Technologies', type: 'Technologies', order: 14, category: 'related', assocCode: 'TEC', proposedCodes: 'TEC'},
        {bn: 'STA', name: 'Related Standards', type: 'Standards', order: 15, category: 'related', assocCode: 'STA', proposedCodes: 'STA'},
        {bn: 'SKI', name: 'Related Skills', type: 'Skills', order: 16, category: 'related', assocCode: 'SKI', proposedCodes: 'SKI'},
        {bn: 'AST', name: 'Related Documents', type: 'Documents', order: 17, category: 'related', assocCode: 'AST', proposedCodes: 'AST'},
        {bn: 'TOP', name: 'Related Discussions', type: 'Discussions', order: 18, category: 'related', assocCode: 'TOP'},
        {bn: 'EAY', name: 'Activity', type: 'Activity', order: 19, category: 'tertiary', assocCode: 'EAY'}
    ]};

// Default Connection Layout
BnCodeToLayout['07'] = {bn: '07',
    countAssocs: sideBarConfigs['07'].single,
    contentBlocks: [
        {bn: 'NEW_CB1', columnId: 0, order: 0, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'CONNECTION_BASIC_INFO'}},
        basicInfoSidebarWithSummaryInfo,
        {bn: 'NEW_CBS', columnId: 0, order: 0, sectionBn: 'SUB_CONNECTION', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1B', contentBlockType: 'CONNECTION_SUB_CONNECTION'}},
        strategyAssociation,
        strategyCount,
        demandAssociation,
        demandCount,
        orgAssociation,
        orgCount,
        personContactAssociation,
        contactCount,
        actorAssociation,
        actorCount,
        capabilityAssociation,
        capabilityCount,
        capabilityEnterpriseApprovePie,
        productCount,
        productAssociation,
        marketCount,
        marketAssociation,
        dataAssociation,
        dataCount,
        dataEnterpriseApprovalPie,
        technologyCount,
        technologyAssociation,
        technologyEnterpriseApprovePie,
        standardAssociation,
        standardCount,
        skillAssociation,
        skillCount,
        physicalCount,
        physicalAssociation,
        documentAssociationByEntity,
        discussionAssociation,
        activityAssociation,
        //fieldsets
        {bn: 'NEW_CBFS1', columnId: 0, order: 1, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '00170000000R'}},
        {bn: 'NEW_CBFS2', columnId: 0, order: 1, sectionBn: 'STR', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '001700000056'}},
        {bn: 'NEW_CBFS3', columnId: 0, order: 1, sectionBn: 'DEM', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '001700000063'}},
        {bn: 'NEW_CBFS4', columnId: 0, order: 1, sectionBn: 'ORG', size: 'LARGE', configuration: {bn: 'NEW_CONFIG4A', contentBlockType: 'FIELDSET', locationId: '00170000004Z'}},
        {bn: 'NEW_CBFS9', columnId: 0, order: 1, sectionBn: 'PER', size: 'LARGE', configuration: {bn: 'NEW_CONFIG7A', contentBlockType: 'FIELDSET', locationId: '00170000000Z'}},
        {bn: 'NEW_CBFS8', columnId: 0, order: 1, sectionBn: 'ACT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG4A', contentBlockType: 'FIELDSET', locationId: '0017000000M3'}},
        {bn: 'NEW_CBFS5', columnId: 0, order: 1, sectionBn: 'CAP', size: 'LARGE', configuration: {bn: 'NEW_CONFIG3A', contentBlockType: 'FIELDSET', locationId: '00170000000T'}},
        {bn: 'NEW_CBFS11', columnId: 0, order: 1, sectionBn: 'PRD', size: 'LARGE', configuration: {bn: 'NEW_CONFIG9A', contentBlockType: 'FIELDSET', locationId: '00170000007T'}},
        {bn: 'NEW_CBFS12', columnId: 0, order: 1, sectionBn: 'MKT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG10A', contentBlockType: 'FIELDSET', locationId: '001700000073'}},
        {bn: 'NEW_CBFS8', columnId: 0, order: 1, sectionBn: 'DAT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG6A', contentBlockType: 'FIELDSET', locationId: '00170000000V'}},
        {bn: 'NEW_CBFS15', columnId: 0, order: 1, sectionBn: 'TEC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG7A', contentBlockType: 'FIELDSET', locationId: '0017000000ME'}},
        {bn: 'NEW_CBFS6', columnId: 0, order: 1, sectionBn: 'STA', size: 'LARGE', configuration: {bn: 'NEW_CONFIG4A', contentBlockType: 'FIELDSET', locationId: '001700000011'}},
        {bn: 'NEW_CBFS17', columnId: 0, order: 1, sectionBn: 'SKI', size: 'LARGE', configuration: {bn: 'NEW_CONFIG7A', contentBlockType: 'FIELDSET', locationId: '0017000000MC'}},
        {bn: 'NEW_CBFS7', columnId: 0, order: 1, sectionBn: 'PHY', size: 'LARGE', configuration: {bn: 'NEW_CONFIG5A', contentBlockType: 'FIELDSET', locationId: '0017000000KD'}},
        {bn: 'NEW_CBFS10', columnId: 0, order: 1, sectionBn: 'AST', size: 'LARGE', configuration: {bn: 'NEW_CONFIG8A', contentBlockType: 'FIELDSET', locationId: '00170000000X'}}
    ],
    sections: [
        {bn: 'BASIC', name: 'Connection Overview', type: 'ConnectionOverview', order: 0, category: 'primary', proposedCodes: 'PLL, LNK, TAG, SYS, COM'},
        {bn: 'SUB_CONNECTION', name: 'Sub-Connections', type: 'SubConnections', order: 1, category: 'primary', assocCode: 'CON', proposedCodes: 'CON'},
        {bn: 'STR', name: 'Related Strategies', type: 'Strategies', order: 2, category: 'related', assocCode: 'STR', proposedCodes: 'STR', roles: 'ROLE_STRATEGY_MODULE_ACCESS'},
        {bn: 'DEM', name: 'Related Demands', type: 'Demands', order: 3, category: 'related', assocCode: 'DEM', proposedCodes: 'DEM', roles: 'ROLE_DEMAND_MODULE_ACCESS'},
        {bn: 'ORG', name: 'Related Organizations', type: 'Organizations', order: 4, category: 'related', assocCode: 'ORG', proposedCodes: 'ORG'},
        {bn: 'PER', name: 'Related People', type: 'People', order: 5, category: 'related', assocCode: 'PER', proposedCodes: 'PER'},
        {bn: 'ACT', name: 'Related Actors', type: 'Actors', order: 6, category: 'related', assocCode: 'ACT', proposedCodes: 'ACT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CAP', name: 'Related Capabilities', type: 'Capabilities', order: 7, category: 'related', assocCode: 'CAP', proposedCodes: 'CAP'},
        {bn: 'PRD', name: 'Related Products', type: 'Products', order: 8, category: 'related', assocCode: 'PRD', proposedCodes: 'PRD', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'MKT', name: 'Related Markets', type: 'Markets', order: 9, category: 'related', assocCode: 'MKT', proposedCodes: 'MKT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'DAT', name: 'Related Data', type: 'Data', order: 10, category: 'related', assocCode: 'DAT', proposedCodes: 'DAT', roles: 'ROLE_DATA_MODULE_ACCESS'},
        {bn: 'TEC', name: 'Related Technologies', type: 'Technologies', order: 11, category: 'related', assocCode: 'TEC', proposedCodes: 'TEC'},
        {bn: 'STA', name: 'Related Standards', type: 'Standards', order: 12, category: 'related', assocCode: 'STA', proposedCodes: 'STA'},
        {bn: 'SKI', name: 'Related Skills', type: 'Skills', order: 13, category: 'related', assocCode: 'SKI', proposedCodes: 'SKI'},
        {bn: 'PHY', name: 'Related Deployments', type: 'Physicals', order: 14, category: 'related', assocCode: 'PHY', proposedCodes: 'PHY', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'AST', name: 'Related Documents', type: 'Documents', order: 15, category: 'related', assocCode: 'AST', proposedCodes: 'AST'},
        {bn: 'TOP', name: 'Related Discussions', type: 'Discussions', order: 16, category: 'related', assocCode: 'TOP'},
        {bn: 'EAY', name: 'Activity', type: 'Activity', order: 17, category: 'tertiary', assocCode: 'EAY'}
    ]};

// Default Market Layout
BnCodeToLayout['0T'] = {bn: '0T',
    countAssocs: sideBarConfigs['0T'].single,
    contentBlocks: [
        {bn: 'NEW_CB1', columnId: 0, order: 0, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'MARKET_BASIC_INFO'}},
        {bn: 'NEW_CBXA1', columnId: 0, order: 0, sectionBn: 'MKT', size: 'LARGE', configuration: {bn: 'NEW_CONFIGXA', contentBlockType: 'MARKET_ARCHITECTURE'}},
        basicInfoSidebarWithSummaryInfo,
        marketRelatedAssociation,
        strategyAssociation,
        strategyCount,
        demandAssociation,
        demandCount,
        orgAssociation,
        orgCount,
        personAssociation,
        contactCount,
        employmentCount,
        companyAssociation,
        companyCount,
        actorAssociation,
        actorCount,
        capabilityAssociation,
        capabilityCount,
        capabilityEnterpriseApprovePie,
        productAssociation,
        productCount,
        dataAssociation,
        dataCount,
        dataEnterpriseApprovalPie,
        technologyCount,
        technologyAssociation,
        technologyEnterpriseApprovePie,
        systemAssociation,
        systemCount,
        systemPriorityPie,
        systemCoreSystemsPie,
        connectionAssociationNoDiagram,
        connectionCount,
        connectionTypesCount,
        connectionTypesPie,
        connectionPatternsCount,
        connectionPatternsPie,
        connectionTransportsCount,
        connectionTransportsPie,
        skillAssociation,
        skillCount,
        standardAssociation,
        standardCount,
        physicalAssociation,
        physicalCount,
        discussionAssociation,
        documentAssociationByEntity,
        activityAssociation,
        //fieldsets
        {bn: 'NEW_CBFS1', columnId: 0, order: 1, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '00170000007M'}},
        {bn: 'NEW_CBFS2', columnId: 0, order: 1, sectionBn: 'MKT', size: 'LARGE', configuration: {bn: 'NEW_CONFIGXB', contentBlockType: 'FIELDSET', locationId: '00170000007N'}},
        {bn: 'NEW_CBFS3', columnId: 0, order: 1, sectionBn: 'STR', size: 'LARGE', configuration: {bn: 'NEW_CONFIGXB', contentBlockType: 'FIELDSET', locationId: '00170000007K'}},
        {bn: 'NEW_CBFS4', columnId: 0, order: 1, sectionBn: 'DEM', size: 'LARGE', configuration: {bn: 'NEW_CONFIGXB', contentBlockType: 'FIELDSET', locationId: '00170000007D'}},
        {bn: 'NEW_CBFS5', columnId: 0, order: 1, sectionBn: 'ORG', size: 'LARGE', configuration: {bn: 'NEW_CONFIG13A', contentBlockType: 'FIELDSET', locationId: '00170000007G'}},
        {bn: 'NEW_CBFS6', columnId: 0, order: 1, sectionBn: 'PER', size: 'LARGE', configuration: {bn: 'NEW_CONFIG6A', contentBlockType: 'FIELDSET', locationId: '00170000007H'}},
        {bn: 'NEW_CBFS7', columnId: 0, order: 1, sectionBn: 'COM', size: 'LARGE', configuration: {bn: 'NEW_CONFIG6A', contentBlockType: 'FIELDSET', locationId: '001700000079'}},
        {bn: 'NEW_CBFS8', columnId: 0, order: 1, sectionBn: 'ACT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG6A', contentBlockType: 'FIELDSET', locationId: '001700000077'}},
        {bn: 'NEW_CBFS9', columnId: 0, order: 1, sectionBn: 'CAP', size: 'LARGE', configuration: {bn: 'NEW_CONFIG6A', contentBlockType: 'FIELDSET', locationId: '001700000078'}},
        {bn: 'NEW_CBFS10', columnId: 0, order: 1, sectionBn: 'PRD', size: 'LARGE', configuration: {bn: 'NEW_CONFIG6A', contentBlockType: 'FIELDSET', locationId: '00170000007J'}},
        {bn: 'NEW_CBFS12', columnId: 0, order: 1, sectionBn: 'DAT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG6A', contentBlockType: 'FIELDSET', locationId: '00170000007C'}},
        {bn: 'NEW_CBFS11', columnId: 0, order: 1, sectionBn: 'SYS', size: 'LARGE', configuration: {bn: 'NEW_CONFIG6A', contentBlockType: 'FIELDSET', locationId: '00170000007L'}},
        {bn: 'NEW_CBFS13', columnId: 0, order: 1, sectionBn: 'CON', size: 'LARGE', configuration: {bn: 'NEW_CONFIG6A', contentBlockType: 'FIELDSET', locationId: '00170000007B'}},
        {bn: 'NEW_CBFS15', columnId: 0, order: 1, sectionBn: 'TEC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG7A', contentBlockType: 'FIELDSET', locationId: '0017000000MQ'}},
        {bn: 'NEW_CBFS15', columnId: 0, order: 1, sectionBn: 'STA', size: 'LARGE', configuration: {bn: 'NEW_CONFIG7A', contentBlockType: 'FIELDSET', locationId: '0017000000MO'}},
        {bn: 'NEW_CBFS15', columnId: 0, order: 1, sectionBn: 'SKI', size: 'LARGE', configuration: {bn: 'NEW_CONFIG7A', contentBlockType: 'FIELDSET', locationId: '0017000000MM'}},
        {bn: 'NEW_CBFS7', columnId: 0, order: 1, sectionBn: 'PHY', size: 'LARGE', configuration: {bn: '7A', contentBlockType: 'FIELDSET', locationId: '0017000000KH'}},
        {bn: 'NEW_CBFS14', columnId: 0, order: 1, sectionBn: 'AST', size: 'LARGE', configuration: {bn: 'NEW_CONFIG6A', contentBlockType: 'FIELDSET', locationId: '00170000007F'}}
    ],
    sections: [
        {bn: 'BASIC', name: 'Market Overview', type: 'MarketOverview', order: 0, category: 'primary', proposedCodes: 'PLL, LNK, TAG'},
        {bn: 'MKT', name: 'Market Model', type: 'MarketArchitecture', order: 1, category: 'primary', assocCode: 'MKT', proposedCodes: 'MKT:HAS_PARENT, MKT:HAS_CHILD'},
        {bn: 'STR', name: 'Related Strategies', type: 'Strategies', order: 2, category: 'related', assocCode: 'STR', proposedCodes: 'STR', roles: 'ROLE_STRATEGY_MODULE_ACCESS'},
        {bn: 'DEM', name: 'Related Demands', type: 'Demands', order: 3, category: 'related', assocCode: 'DEM', proposedCodes: 'DEM', roles: 'ROLE_DEMAND_MODULE_ACCESS'},
        {bn: 'ORG', name: 'Related Organizations', type: 'Organizations', order: 4, category: 'related', assocCode: 'ORG', proposedCodes: 'ORG'},
        {bn: 'PER', name: 'Related People', type: 'People', order: 5, category: 'related', assocCode: 'PER', proposedCodes: 'PER'},
        {bn: 'COM', name: 'Related Companies', type: 'Companies', order: 6, category: 'related', assocCode: 'COM', proposedCodes: 'COM'},
        {bn: 'ACT', name: 'Related Actors', type: 'Actors', order: 7, category: 'related', assocCode: 'ACT', proposedCodes: 'ACT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CAP', name: 'Related Capabilities', type: 'Capabilities', order: 8, category: 'related', assocCode: 'CAP', proposedCodes: 'CAP'},
        {bn: 'PRD', name: 'Related Products', type: 'Products', order: 9, category: 'related', assocCode: 'PRD', proposedCodes: 'PRD'},
        {bn: 'DAT', name: 'Related Data', type: 'Data', order: 10, category: 'related', assocCode: 'DAT', proposedCodes: 'DAT', roles: 'ROLE_DATA_MODULE_ACCESS'},
        {bn: 'SYS', name: 'Related Systems', type: 'Systems', order: 11, category: 'related', assocCode: 'SYS', proposedCodes: 'SYS'},
        {bn: 'CON', name: 'Related Connections', type: 'Connections', order: 12, category: 'related', assocCode: 'CON', proposedCodes: 'CON', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TEC', name: 'Related Technologies', type: 'Technologies', order: 13, category: 'related', assocCode: 'TEC', proposedCodes: 'TEC'},
        {bn: 'SKI', name: 'Related Skills', type: 'Skills', order: 14, category: 'related', assocCode: 'SKI', proposedCodes: 'SKI'},
        {bn: 'STA', name: 'Related Standards', type: 'Standards', order: 15, category: 'related', assocCode: 'STA', proposedCodes: 'STA'},
        {bn: 'PHY', name: 'Related Deployments', type: 'Physicals', order: 16, category: 'related', assocCode: 'PHY', proposedCodes: 'PHY', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'AST', name: 'Related Documents', type: 'Documents', order: 17, category: 'related', assocCode: 'AST', proposedCodes: 'AST'},
        {bn: 'TOP', name: 'Related Discussions', type: 'Discussions', order: 18, category: 'related', assocCode: 'TOP', proposedCodes: 'TOP'},
        {bn: 'EAY', name: 'Activity', type: 'Activity', order: 19, category: 'tertiary', assocCode: 'EAY'}
    ]};

// Default Tag Layout
BnCodeToLayout['1F'] = {bn: '1F',
    countAssocs: sideBarConfigs['1F'].single,
    contentBlocks: [
        {bn: 'NEW_CB1', columnId: 0, order: 0, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'TAG_BASIC_INFO'}},
        basicInfoSidebar,
        strategyAssociation,
        strategyCount,
        demandAssociation,
        demandCount,
        orgAssociation,
        orgCount,
        personAssociation,
        contactCount,
        employmentCount,
        companyAssociation,
        companyCount,
        actorAssociation,
        actorCount,
        capabilityAssociation,
        capabilityCount,
        capabilityEnterpriseApprovePie,
        productAssociation,
        productCount,
        marketCount,
        marketAssociation,
        dataAssociation,
        dataCount,
        dataEnterpriseApprovalPie,
        systemAssociation,
        systemCount,
        systemPriorityPie,
        systemCoreSystemsPie,
        connectionAssociationNoDiagram,
        connectionCount,
        connectionTypesCount,
        connectionTypesPie,
        connectionPatternsCount,
        connectionPatternsPie,
        connectionTransportsCount,
        connectionTransportsPie,
        technologyAssociation,
        technologyCount,
        technologyEnterpriseApprovePie,
        standardCount,
        standardAssociation,
        skillAssociation,
        skillCount,
        physicalAssociation,
        physicalCount,
        documentAssociation,
        discussionAssociation,
        activityAssociation,
        //fieldsets
        {bn: 'NEW_CBFS1', columnId: 0, order: 1, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '001700000034'}},
        {bn: 'NEW_CBFS2', columnId: 0, order: 1, sectionBn: 'STR', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '001700000052'}},
        {bn: 'NEW_CBFS3', columnId: 0, order: 1, sectionBn: 'DEM', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '00170000006X'}},
        {bn: 'NEW_CBFS3', columnId: 0, order: 1, sectionBn: 'ORG', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '0017000000N0'}},
        {bn: 'NEW_CBFS6', columnId: 0, order: 1, sectionBn: 'PER', size: 'LARGE', configuration: {bn: 'NEW_CONFIG6A', contentBlockType: 'FIELDSET', locationId: '0017000000N2'}},
        {bn: 'NEW_CBFS9', columnId: 0, order: 1, sectionBn: 'COM', size: 'LARGE', configuration: {bn: 'NEW_CONFIG8A', contentBlockType: 'FIELDSET', locationId: '001700000035'}},
        {bn: 'NEW_CBFS6', columnId: 0, order: 1, sectionBn: 'ACT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG4A', contentBlockType: 'FIELDSET', locationId: '00170000003S'}},
        {bn: 'NEW_CBFS7', columnId: 0, order: 1, sectionBn: 'CAP', size: 'LARGE', configuration: {bn: 'NEW_CONFIG5A', contentBlockType: 'FIELDSET', locationId: '00170000003T'}},
        {bn: 'NEW_CBFS13', columnId: 0, order: 1, sectionBn: 'PRD', size: 'LARGE', configuration: {bn: 'NEW_CONFIG6A', contentBlockType: 'FIELDSET', locationId: '00170000003W'}},
        {bn: 'NEW_CBFS8', columnId: 0, order: 1, sectionBn: 'DAT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG6A', contentBlockType: 'FIELDSET', locationId: '00170000003V'}},
        {bn: 'NEW_CBFS4', columnId: 0, order: 1, sectionBn: 'SYS', size: 'LARGE', configuration: {bn: 'NEW_CONFIG2A', contentBlockType: 'FIELDSET', locationId: '00170000003Q'}},
        {bn: 'NEW_CBFS5', columnId: 0, order: 1, sectionBn: 'CON', size: 'LARGE', configuration: {bn: 'NEW_CONFIG3A', contentBlockType: 'FIELDSET', locationId: '00170000003R'}},
        {bn: 'NEW_CBFS12', columnId: 0, order: 1, sectionBn: 'TEC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG6A', contentBlockType: 'FIELDSET', locationId: '001700000036'}},
        {bn: 'NEW_CBFS11', columnId: 0, order: 1, sectionBn: 'STA', size: 'LARGE', configuration: {bn: 'NEW_CONFIG6A', contentBlockType: 'FIELDSET', locationId: '001700000010'}},
        {bn: 'NEW_CBFS10', columnId: 0, order: 1, sectionBn: 'SKI', size: 'LARGE', configuration: {bn: 'NEW_CONFIG2A', contentBlockType: 'FIELDSET', locationId: '00170000003L'}},
        {bn: 'NEW_CBFS7', columnId: 0, order: 1, sectionBn: 'PHY', size: 'LARGE', configuration: {bn: '7A', contentBlockType: 'FIELDSET', locationId: '0017000000KR'}},
        {bn: 'NEW_CBFS14', columnId: 0, order: 1, sectionBn: 'AST', size: 'LARGE', configuration: {bn: 'NEW_CONFIG7A', contentBlockType: 'FIELDSET', locationId: '001700000037'}}
    ],
    sections: [
        {bn: 'BASIC', name: 'Tag Overview', type: 'TagOverview', order: 0, category: 'primary'},
        {bn: 'STR', name: 'Related Strategies', type: 'Strategies', order: 1, category: 'related', assocCode: 'STR', proposedCodes: 'STR', roles: 'ROLE_STRATEGY_MODULE_ACCESS'},
        {bn: 'DEM', name: 'Related Demands', type: 'Demands', order: 2, category: 'related', assocCode: 'DEM', proposedCodes: 'DEM', roles: 'ROLE_DEMAND_MODULE_ACCESS'},
        {bn: 'ORG', name: 'Related Organizations', type: 'Organizations', order: 3, category: 'related', assocCode: 'ORG', proposedCodes: 'ORG'},
        {bn: 'PER', name: 'Related People', type: 'People', order: 4, category: 'related', assocCode: 'PER', proposedCodes: 'PER'},
        {bn: 'COM', name: 'Related Companies', type: 'Companies', order: 5, category: 'related', assocCode: 'COM', proposedCodes: 'COM'},
        {bn: 'ACT', name: 'Related Actors', type: 'Actors', order: 6, category: 'related', assocCode: 'ACT', proposedCodes: 'ACT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CAP', name: 'Related Capabilities', type: 'Capabilities', order: 7, category: 'related', assocCode: 'CAP', proposedCodes: 'CAP', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'PRD', name: 'Related Products', type: 'Products', order: 8, category: 'related', assocCode: 'PRD', proposedCodes: 'PRD', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'MKT', name: 'Related Markets', type: 'Markets', order: 9, category: 'related', assocCode: 'MKT', proposedCodes: 'MKT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'DAT', name: 'Related Data', type: 'Data', order: 10, category: 'related', assocCode: 'DAT', proposedCodes: 'DAT', roles: 'ROLE_DATA_MODULE_ACCESS'},
        {bn: 'SYS', name: 'Related Systems', type: 'Systems', order: 11, category: 'related', assocCode: 'SYS', proposedCodes: 'SYS', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CON', name: 'Related Connections', type: 'Connections', order: 12, category: 'related', assocCode: 'CON', proposedCodes: 'CON', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TEC', name: 'Related Technologies', type: 'Technologies', order: 13, category: 'related', assocCode: 'TEC', proposedCodes: 'TEC'},
        {bn: 'STA', name: 'Related Standards', type: 'Standards', order: 14, category: 'related', assocCode: 'STA', proposedCodes: 'STA'},
        {bn: 'SKI', name: 'Related Skills', type: 'Skills', order: 15, category: 'related', assocCode: 'SKI', proposedCodes: 'SKI'},
        {bn: 'PHY', name: 'Related Deployments', type: 'Physicals', order: 16, category: 'related', assocCode: 'PHY', proposedCodes: 'PHY', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'AST', name: 'Related Documents', type: 'Documents', order: 17, category: 'related', assocCode: 'AST', proposedCodes: 'AST'},
        {bn: 'TOP', name: 'Related Discussions', type: 'Discussions', order: 18, category: 'related', assocCode: 'TOP'},
        {bn: 'EAY', name: 'Activity', type: 'Activity', order: 19, category: 'tertiary', assocCode: 'EAY'}
    ]};

// Default Skill Layout
BnCodeToLayout['15'] = {bn: '15',
    countAssocs: sideBarConfigs['15'].single,
    contentBlocks: [
        {bn: 'NEW_CB1', columnId: 0, order: 0, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'SKILL_BASIC_INFO'}},
        basicInfoSidebar,
        strategyAssociation,
        strategyCount,
        demandAssociation,
        demandCount,
        orgAssociation,
        orgCount,
        personAssociation,
        contactCount,
        employmentCount,
        companyCount,
        companyAssociation,
        actorAssociation,
        actorCount,
        capabilityAssociation,
        capabilityCount,
        productCount,
        productAssociation,
        dataCount,
        dataAssociation,
        marketCount,
        marketAssociation,
        systemAssociation,
        systemCount,
        systemPriorityPie,
        systemCoreSystemsPie,
        connectionAssociationNoDiagram,
        connectionCount,
        connectionTypesCount,
        connectionTypesPie,
        connectionPatternsCount,
        connectionPatternsPie,
        connectionTransportsCount,
        connectionTransportsPie,
        technologyAssociation,
        technologyEnterpriseApprovePie,
        technologyCount,
        standardAssociation,
        standardCount,
        physicalAssociation,
        physicalCount,
        documentAssociationByEntity,
        discussionAssociation,
        activityAssociation,

        //fieldsets
        {bn: 'NEW_CBFS1', columnId: 0, order: 1, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '001700000022'}},
        {bn: 'NEW_CBFS2', columnId: 0, order: 1, sectionBn: 'STR', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '00170000005C'}},
        {bn: 'NEW_CBFS3', columnId: 0, order: 1, sectionBn: 'DEM', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '00170000006T'}},
        {bn: 'NEW_CBFS5', columnId: 0, order: 1, sectionBn: 'ORG', size: 'LARGE', configuration: {bn: 'NEW_CONFIG13A', contentBlockType: 'FIELDSET', locationId: '0017000000MT'}},
        {bn: 'NEW_CBFS5', columnId: 0, order: 1, sectionBn: 'PER', size: 'LARGE', configuration: {bn: 'NEW_CONFIG3A', contentBlockType: 'FIELDSET', locationId: '001700000027'}},
        {bn: 'NEW_CBFS6', columnId: 0, order: 1, sectionBn: 'COM', size: 'LARGE', configuration: {bn: 'NEW_CONFIG9A', contentBlockType: 'FIELDSET', locationId: '0017000000MB'}},
        {bn: 'NEW_CBFS5', columnId: 0, order: 1, sectionBn: 'ACT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG3A', contentBlockType: 'FIELDSET', locationId: '0017000000M5'}},
        {bn: 'NEW_CBFS10', columnId: 0, order: 1, sectionBn: 'CAP', size: 'LARGE', configuration: {bn: 'NEW_CONFIG9A', contentBlockType: 'FIELDSET', locationId: '00170000004L'}},
        {bn: 'NEW_CBFS11', columnId: 0, order: 1, sectionBn: 'PRD', size: 'LARGE', configuration: {bn: 'NEW_CONFIG9A', contentBlockType: 'FIELDSET', locationId: '00170000007X'}},
        {bn: 'NEW_CBFS11', columnId: 0, order: 1, sectionBn: 'MKT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG9A', contentBlockType: 'FIELDSET', locationId: '0017000000MN'}},
        {bn: 'NEW_CBFS11', columnId: 0, order: 1, sectionBn: 'DAT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG9A', contentBlockType: 'FIELDSET', locationId: '0017000000MH'}},
        {bn: 'NEW_CBFS4', columnId: 0, order: 1, sectionBn: 'SYS', size: 'LARGE', configuration: {bn: 'NEW_CONFIG2A', contentBlockType: 'FIELDSET', locationId: '001700000029'}},
        {bn: 'NEW_CBFS5', columnId: 0, order: 1, sectionBn: 'CON', size: 'LARGE', configuration: {bn: 'NEW_CONFIG3A', contentBlockType: 'FIELDSET', locationId: '0017000000MD'}},
        {bn: 'NEW_CBFS6', columnId: 0, order: 1, sectionBn: 'TEC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG4A', contentBlockType: 'FIELDSET', locationId: '00170000002B'}},
        {bn: 'NEW_CBFS7', columnId: 0, order: 1, sectionBn: 'STA', size: 'LARGE', configuration: {bn: 'NEW_CONFIG5A', contentBlockType: 'FIELDSET', locationId: '001700000028'}},
        {bn: 'NEW_CBFS7', columnId: 0, order: 1, sectionBn: 'PHY', size: 'LARGE', configuration: {bn: '7A', contentBlockType: 'FIELDSET', locationId: '0017000000KM'}},
        {bn: 'NEW_CBFS8', columnId: 0, order: 1, sectionBn: 'AST', size: 'LARGE', configuration: {bn: 'NEW_CONFIG7A', contentBlockType: 'FIELDSET', locationId: '001700000025'}},
        {bn: 'NEW_CBFS9', columnId: 0, order: 1, sectionBn: 'EAY', size: 'LARGE', configuration: {bn: 'NEW_CONFIG8A', contentBlockType: 'FIELDSET', locationId: '001700000023'}}
    ],
    sections: [
        {bn: 'BASIC', name: 'Skill Overview', type: 'SkillOverview', order: 0, category: 'primary', proposedCodes: 'PLL, LNK, TAG'},
        {bn: 'STR', name: 'Related Strategies', type: 'Strategies', order: 1, category: 'related', assocCode: 'STR', proposedCodes: 'STR', roles: 'ROLE_STRATEGY_MODULE_ACCESS'},
        {bn: 'DEM', name: 'Related Demands', type: 'Demands', order: 2, category: 'related', assocCode: 'DEM', proposedCodes: 'DEM', roles: 'ROLE_DEMAND_MODULE_ACCESS'},
        {bn: 'ORG', name: 'Related Organizations', type: 'Organizations', order: 3, category: 'related', assocCode: 'ORG', proposedCodes: 'ORG'},
        {bn: 'PER', name: 'Related People', type: 'People', order: 4, category: 'related', assocCode: 'PER', proposedCodes: 'PER'},
        {bn: 'COM', name: 'Related Companies', type: 'Companies', order: 5, category: 'related', assocCode: 'COM', proposedCodes: 'COM'},
        {bn: 'ACT', name: 'Related Actors', type: 'Actors', order: 6, category: 'related', assocCode: 'ACT', proposedCodes: 'ACT'},
        {bn: 'CAP', name: 'Related Capabilities', type: 'Capabilities', order: 7, category: 'related', assocCode: 'CAP', proposedCodes: 'CAP', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'PRD', name: 'Related Products', type: 'Products', order: 8, category: 'related', assocCode: 'PRD', proposedCodes: 'PRD', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'MKT', name: 'Related Markets', type: 'Markets', order: 9, category: 'related', assocCode: 'MKT', proposedCodes: 'MKT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'DAT', name: 'Related Data', type: 'Data', order: 10, category: 'related', assocCode: 'DAT', proposedCodes: 'DAT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'SYS', name: 'Related Systems', type: 'Systems', order: 11, category: 'related', assocCode: 'SYS', proposedCodes: 'SYS', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CON', name: 'Related Connections', type: 'Connections', order: 12, category: 'related', assocCode: 'CON', proposedCodes: 'CON', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TEC', name: 'Related Technologies', type: 'Technologies', order: 13, category: 'related', assocCode: 'TEC', proposedCodes: 'TEC'},
        {bn: 'STA', name: 'Related Standards', type: 'Standards', order: 14, category: 'related', assocCode: 'STA', proposedCodes: 'STA'},
        {bn: 'PHY', name: 'Related Deployments', type: 'Physicals', order: 15, category: 'related', assocCode: 'PHY', proposedCodes: 'PHY', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'AST', name: 'Related Documents', type: 'Documents', order: 16, category: 'related', assocCode: 'AST', proposedCodes: 'AST'},
        {bn: 'TOP', name: 'Related Discussions', type: 'Discussions', order: 17, category: 'related', assocCode: 'TOP'},
        {bn: 'EAY', name: 'Activity', type: 'Activity', order: 18, category: 'tertiary', assocCode: 'EAY'}
    ]};

// Default Standard Layout
BnCodeToLayout['16'] = {bn: '16',
    countAssocs: sideBarConfigs['16'].single,
    contentBlocks: [
        {bn: 'NEW_CB1', columnId: 0, order: 0, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'STANDARD_BASIC_INFO'}},
        basicInfoSidebarWithSummaryInfoContactsCompanies,
        strategyAssociation,
        strategyCount,
        demandAssociation,
        demandCount,
        orgAssociation,
        orgCount,
        personAssociation,
        contactCount,
        employmentCount,
        companyCount,
        companyAssociation,
        actorAssociation,
        actorCount,
        capabilityAssociation,
        capabilityCount,
        marketAssociation,
        marketCount,
        productCount,
        productAssociation,
        dataCount,
        dataAssociation,
        systemAssociation,
        systemCount,
        systemPriorityPie,
        systemCoreSystemsPie,
        connectionAssociationNoDiagram,
        connectionCount,
        connectionTypesCount,
        connectionTypesPie,
        connectionPatternsCount,
        connectionPatternsPie,
        connectionTransportsCount,
        connectionTransportsPie,
        technologyAssociation,
        technologyEnterpriseApprovePie,
        technologyCount,
        skillAssociation,
        skillCount,
        physicalAssociation,
        physicalCount,
        documentAssociationByEntity,
        discussionAssociation,
        activityAssociation,
        //fieldsets
        {bn: 'NEW_CBFS1', columnId: 0, order: 1, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '00170000002C'}},
        {bn: 'NEW_CBFS2', columnId: 0, order: 1, sectionBn: 'STR', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '00170000005D'}},
        {bn: 'NEW_CBFS3', columnId: 0, order: 1, sectionBn: 'DEM', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '00170000006V'}},
        {bn: 'NEW_CBFS5', columnId: 0, order: 1, sectionBn: 'ORG', size: 'LARGE', configuration: {bn: 'NEW_CONFIG13A', contentBlockType: 'FIELDSET', locationId: '0017000000MV'}},
        {bn: 'NEW_CBFS5', columnId: 0, order: 1, sectionBn: 'PER', size: 'LARGE', configuration: {bn: 'NEW_CONFIG3A', contentBlockType: 'FIELDSET', locationId: '00170000002K'}},
        {bn: 'NEW_CBFS6', columnId: 0, order: 1, sectionBn: 'COM', size: 'LARGE', configuration: {bn: 'NEW_CONFIG9A', contentBlockType: 'FIELDSET', locationId: '00170000002D'}},
        {bn: 'NEW_CBFS6', columnId: 0, order: 1, sectionBn: 'ACT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG9A', contentBlockType: 'FIELDSET', locationId: '0017000000M7'}},
        {bn: 'NEW_CBFS9', columnId: 0, order: 1, sectionBn: 'CAP', size: 'LARGE', configuration: {bn: 'NEW_CONFIG10A', contentBlockType: 'FIELDSET', locationId: '00170000004M'}},
        {bn: 'NEW_CBFS12', columnId: 0, order: 1, sectionBn: 'PRD', size: 'LARGE', configuration: {bn: 'NEW_CONFIG8A', contentBlockType: 'FIELDSET', locationId: '00170000007Z'}},
        {bn: 'NEW_CBFS12', columnId: 0, order: 1, sectionBn: 'MKT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG8A', contentBlockType: 'FIELDSET', locationId: '0017000000MP'}},
        {bn: 'NEW_CBFS12', columnId: 0, order: 1, sectionBn: 'DAT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG8A', contentBlockType: 'FIELDSET', locationId: '0017000000MJ'}},
        {bn: 'NEW_CBFS4', columnId: 0, order: 1, sectionBn: 'SYS', size: 'LARGE', configuration: {bn: 'NEW_CONFIG2A', contentBlockType: 'FIELDSET', locationId: '00170000002M'}},
        {bn: 'NEW_CBFS7', columnId: 0, order: 1, sectionBn: 'CON', size: 'LARGE', configuration: {bn: 'NEW_CONFIG4A', contentBlockType: 'FIELDSET', locationId: '00170000002F'}},
        {bn: 'NEW_CBFS8', columnId: 0, order: 1, sectionBn: 'TEC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG5A', contentBlockType: 'FIELDSET', locationId: '00170000002N'}},
        {bn: 'NEW_CBFS10', columnId: 0, order: 1, sectionBn: 'SKI', size: 'LARGE', configuration: {bn: 'NEW_CONFIG6A', contentBlockType: 'FIELDSET', locationId: '00170000002L'}},
        {bn: 'NEW_CBFS7', columnId: 0, order: 1, sectionBn: 'PHY', size: 'LARGE', configuration: {bn: '7A', contentBlockType: 'FIELDSET', locationId: '0017000000KN'}},
        {bn: 'NEW_CBFS11', columnId: 0, order: 1, sectionBn: 'AST', size: 'LARGE', configuration: {bn: 'NEW_CONFIG8A', contentBlockType: 'FIELDSET', locationId: '00170000002H'}}
    ],
    sections: [
        {bn: 'BASIC', name: 'Standard Overview', type: 'StandardOverview', order: 0, category: 'primary', proposedCodes: 'PLL, STA:HAS_CHILD, STA:CONTAINS, STA:PREVIOUS, LNK, TAG'},
        {bn: 'STR', name: 'Related Strategies', type: 'Strategies', order: 1, category: 'related', assocCode: 'STR', proposedCodes: 'STR', roles: 'ROLE_STRATEGY_MODULE_ACCESS'},
        {bn: 'DEM', name: 'Related Demands', type: 'Demands', order: 2, category: 'related', assocCode: 'DEM', proposedCodes: 'DEM', roles: 'ROLE_DEMAND_MODULE_ACCESS'},
        {bn: 'ORG', name: 'Related Organizations', type: 'Organizations', order: 3, category: 'related', assocCode: 'ORG', proposedCodes: 'ORG'},
        {bn: 'PER', name: 'Related People', type: 'People', order: 4, category: 'related', assocCode: 'PER', proposedCodes: 'PER'},
        {bn: 'COM', name: 'Related Companies', type: 'Companies', order: 5, category: 'related', assocCode: 'COM', proposedCodes: 'COM'},
        {bn: 'ACT', name: 'Related Actors', type: 'Actors', order: 6, category: 'related', assocCode: 'ACT', proposedCodes: 'ACT'},
        {bn: 'CAP', name: 'Related Capabilities', type: 'Capabilities', order: 7, category: 'related', assocCode: 'CAP', proposedCodes: 'CAP', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'PRD', name: 'Related Products', type: 'Products', order: 8, category: 'related', assocCode: 'PRD', proposedCodes: 'PRD', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'MKT', name: 'Related Markets', type: 'Markets', order: 9, category: 'related', assocCode: 'MKT', proposedCodes: 'MKT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'DAT', name: 'Related Data', type: 'Data', order: 10, category: 'related', assocCode: 'DAT', proposedCodes: 'DAT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'SYS', name: 'Related Systems', type: 'Systems', order: 11, category: 'related', assocCode: 'SYS', proposedCodes: 'SYS', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CON', name: 'Related Connections', type: 'Connections', order: 12, category: 'related', assocCode: 'CON', proposedCodes: 'CON', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TEC', name: 'Related Technologies', type: 'Technologies', order: 13, category: 'related', assocCode: 'TEC', proposedCodes: 'TEC'},
        {bn: 'SKI', name: 'Related Skills', type: 'Skills', order: 14, category: 'related', assocCode: 'SKI', proposedCodes: 'SKI'},
        {bn: 'PHY', name: 'Related Deployments', type: 'Physicals', order: 15, category: 'related', assocCode: 'PHY', proposedCodes: 'PHY', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'AST', name: 'Related Documents', type: 'Documents', order: 16, category: 'related', assocCode: 'AST', proposedCodes: 'AST'},
        {bn: 'TOP', name: 'Related Discussions', type: 'Discussions', order: 17, category: 'related', assocCode: 'TOP'},
        {bn: 'EAY', name: 'Activity', type: 'Activity', order: 18, category: 'tertiary', assocCode: 'EAY'}
    ]};

// Default Strategy Layout
BnCodeToLayout['0G'] = {bn: '0G',
    countAssocs: sideBarConfigs['0G'].single,
    contentBlocks: [
        {bn: 'NEW_CB1', columnId: 0, order: 0, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'STRATEGY_BASIC_INFO'}},
        basicInfoSidebarWithSummaryInfo,
        {bn: 'NEW_CBXA1', columnId: 0, order: 0, sectionBn: 'STR', size: 'LARGE', configuration: {bn: 'NEW_CONFIGXA', contentBlockType: 'STRATEGY_ARCHITECTURE'}},
        strategyNews,
        strategyRelatedAssociation,
        strategyRelatedCount,
        demandAssociation,
        demandCount,
        orgAssociation,
        orgCount,
        personAssociation,
        contactCount,
        employmentCount,
        companyAssociation,
        companyCount,
        actorAssociation,
        actorCount,
        capabilityAssociation,
        capabilityCount,
        productAssociation,
        productCount,
        marketCount,
        marketAssociation,
        dataAssociation,
        dataCount,
        dataEnterpriseApprovalPie,
        systemAssociation,
        systemCount,
        systemPriorityPie,
        systemCoreSystemsPie,
        connectionAssociationNoDiagram,
        connectionCount,
        connectionTypesCount,
        connectionTypesPie,
        connectionPatternsCount,
        connectionPatternsPie,
        connectionTransportsCount,
        connectionTransportsPie,
        technologyAssociation,
        technologyCount,
        standardAssociation,
        standardCount,
        skillAssociation,
        skillCount,
        physicalAssociation,
        physicalCount,
        documentAssociationByEntity,
        discussionAssociation,
        activityAssociation,
        //fieldsets
        {bn: 'NEW_CBFS1', columnId: 0, order: 1, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '00170000005F'}},
        {bn: 'NEW_CBFS4', columnId: 0, order: 1, sectionBn: 'DEM', size: 'LARGE', configuration: {bn: 'NEW_CONFIG2A', contentBlockType: 'FIELDSET', locationId: '00170000005X'}},
        {bn: 'NEW_CBFS16', columnId: 0, order: 1, sectionBn: 'ORG', size: 'LARGE', configuration: {bn: 'NEW_CONFIG13A', contentBlockType: 'FIELDSET', locationId: '00170000005H'}},
        {bn: 'NEW_CBFS10', columnId: 0, order: 1, sectionBn: 'PER', size: 'LARGE', configuration: {bn: 'NEW_CONFIG6A', contentBlockType: 'FIELDSET', locationId: '00170000005M'}},
        {bn: 'NEW_CBFS17', columnId: 0, order: 1, sectionBn: 'COM', size: 'LARGE', configuration: {bn: 'NEW_CONFIG14A', contentBlockType: 'FIELDSET', locationId: '00170000005J'}},
        {bn: 'NEW_CBFS6', columnId: 0, order: 1, sectionBn: 'ACT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG3A', contentBlockType: 'FIELDSET', locationId: '00170000005P'}},
        {bn: 'NEW_CBFS8', columnId: 0, order: 1, sectionBn: 'CAP', size: 'LARGE', configuration: {bn: 'NEW_CONFIG4A', contentBlockType: 'FIELDSET', locationId: '00170000005K'}},
        {bn: 'NEW_CBFS12', columnId: 0, order: 1, sectionBn: 'PRD', size: 'LARGE', configuration: {bn: 'NEW_CONFIG9A', contentBlockType: 'FIELDSET', locationId: '00170000005Q'}},
        {bn: 'NEW_CBFS18', columnId: 0, order: 1, sectionBn: 'MKT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG15A', contentBlockType: 'FIELDSET', locationId: '00170000007S'}},
        {bn: 'NEW_CBFS7', columnId: 0, order: 1, sectionBn: 'DAT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG4A', contentBlockType: 'FIELDSET', locationId: '00170000005R'}},
        {bn: 'NEW_CBFS5', columnId: 0, order: 1, sectionBn: 'SYS', size: 'LARGE', configuration: {bn: 'NEW_CONFIG2A', contentBlockType: 'FIELDSET', locationId: '00170000005N'}},
        {bn: 'NEW_CBFS9', columnId: 0, order: 1, sectionBn: 'CON', size: 'LARGE', configuration: {bn: 'NEW_CONFIG5A', contentBlockType: 'FIELDSET', locationId: '00170000005S'}},
        {bn: 'NEW_CBFS13', columnId: 0, order: 1, sectionBn: 'TEC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG10A', contentBlockType: 'FIELDSET', locationId: '00170000005W'}},
        {bn: 'NEW_CBFS14', columnId: 0, order: 1, sectionBn: 'STA', size: 'LARGE', configuration: {bn: 'NEW_CONFIG11A', contentBlockType: 'FIELDSET', locationId: '00170000005T'}},
        {bn: 'NEW_CBFS15', columnId: 0, order: 1, sectionBn: 'SKI', size: 'LARGE', configuration: {bn: 'NEW_CONFIG12A', contentBlockType: 'FIELDSET', locationId: '00170000005V'}},
        {bn: 'NEW_CBFS7', columnId: 0, order: 1, sectionBn: 'PHY', size: 'LARGE', configuration: {bn: '7A', contentBlockType: 'FIELDSET', locationId: '0017000000KP'}},
        {bn: 'NEW_CBFS11', columnId: 0, order: 1, sectionBn: 'AST', size: 'LARGE', configuration: {bn: 'NEW_CONFIG7A', contentBlockType: 'FIELDSET', locationId: '00170000005L'}}
    ],

    sections: [
        {bn: 'BASIC', name: 'Strategy Overview', type: 'StrategyOverview', order: 0, category: 'primary', proposedCodes: 'PLL, STR:HAS_PARENT, STR:HAS_CHILD, LNK, TAG'},
        {bn: 'STR', name: 'Strategy Model', type: 'StrategyArchitecture', order: 1, category: 'primary', assocCode: 'STR', proposedCodes: 'STR:HAS_PARENT, STR:HAS_CHILD, STR:RELATED_TO'},
        {bn: 'SAC', name: 'News', type: 'StrategyNews', order: 2, category: 'primary', assocCode: 'SAC'},
        {bn: 'DEM', name: 'Related Demand', type: 'Demands', order: 4, category: 'related', assocCode: 'DEM', proposedCodes: 'DEM', roles: 'ROLE_DEMAND_MODULE_ACCESS'},
        {bn: 'ORG', name: 'Related Organizations', type: 'Organizations', order: 5, category: 'related', assocCode: 'ORG', proposedCodes: 'ORG'},
        {bn: 'PER', name: 'Related People', type: 'People', order: 6, category: 'related', assocCode: 'PER', proposedCodes: 'PER'},
        {bn: 'COM', name: 'Related Companies', type: 'Companies', order: 7, category: 'related', assocCode: 'COM', proposedCodes: 'COM'},
        {bn: 'ACT', name: 'Related Actors', type: 'Actors', order: 8, category: 'related', assocCode: 'ACT', proposedCodes: 'ACT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CAP', name: 'Related Capabilities', type: 'Capabilities', order: 9, category: 'related', assocCode: 'CAP', proposedCodes: 'CAP', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'PRD', name: 'Related Products', type: 'Products', order: 10, category: 'related', assocCode: 'PRD', proposedCodes: 'PRD', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'MKT', name: 'Related Markets', type: 'Markets', order: 11, category: 'related', assocCode: 'MKT', proposedCodes: 'MKT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'DAT', name: 'Related Data', type: 'Data', order: 12, category: 'related', assocCode: 'DAT', proposedCodes: 'DAT', roles: 'ROLE_DATA_MODULE_ACCESS'},
        {bn: 'SYS', name: 'Related Systems', type: 'Systems', order: 13, category: 'related', assocCode: 'SYS', proposedCodes: 'SYS', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CON', name: 'Related Connections', type: 'Connections', order: 14, category: 'related', assocCode: 'CON', proposedCodes: 'CON', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TEC', name: 'Related Technologies', type: 'Technologies', order: 15, category: 'related', assocCode: 'TEC', proposedCodes: 'TEC'},
        {bn: 'STA', name: 'Related Standards', type: 'Standards', order: 16, category: 'related', assocCode: 'STA', proposedCodes: 'STA'},
        {bn: 'SKI', name: 'Related Skills', type: 'Skills', order: 17, category: 'related', assocCode: 'SKI', proposedCodes: 'SKI'},
        {bn: 'PHY', name: 'Related Deployments', type: 'Physicals', order: 18, category: 'related', assocCode: 'PHY', proposedCodes: 'PHY', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'AST', name: 'Related Documents', type: 'Documents', order: 19, category: 'related', assocCode: 'AST', proposedCodes: 'AST'},
        {bn: 'TOP', name: 'Related Discussions', type: 'Discussions', order: 20, category: 'related', assocCode: 'TOP', proposedCodes: 'TOP'},
        {bn: 'EAY', name: 'Activity', type: 'Activity', order: 21, category: 'tertiary', assocCode: 'EAY'}
    ]};

// Default Technology Layout
BnCodeToLayout['1H'] = {bn: '1H',
    countAssocs: sideBarConfigs['1H'].single,
    contentBlocks: [
        {bn: 'NEW_CB1', columnId: 0, order: 0, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'TECHNOLOGY_BASIC_INFO'}},
        basicInfoSidebarWithSummaryInfo,
        strategyAssociation,
        strategyCount,
        demandAssociation,
        demandCount,
        orgAssociation,
        orgCount,
        personAssociation,
        contactCount,
        employmentCount,
        companyCount,
        companyAssociation,
        actorAssociation,
        actorCount,
        capabilityAssociation,
        capabilityCount,
        productAssociation,
        productCount,
        marketCount,
        marketAssociation,
        dataAssociation,
        dataCount,
        dataEnterpriseApprovalPie,
        systemAssociation,
        systemCount,
        systemPriorityPie,
        systemCoreSystemsPie,
        connectionAssociationNoDiagram,
        connectionCount,
        connectionTypesCount,
        connectionTypesPie,
        connectionPatternsCount,
        connectionPatternsPie,
        connectionTransportsCount,
        connectionTransportsPie,
        physicalAssociation,
        physicalCount,
        standardAssociation,
        standardCount,
        skillAssociation,
        skillCount,
        documentAssociationByEntity,
        discussionAssociation,
        activityAssociation,
        //fieldsets
        {bn: 'NEW_CBFS2', columnId: 0, order: 1, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '001700000038'}},
        {bn: 'NEW_CBFS3', columnId: 0, order: 1, sectionBn: 'STR', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '001700000051'}},
        {bn: 'NEW_CBFS4', columnId: 0, order: 1, sectionBn: 'DEM', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '00170000006Z'}},
        {bn: 'NEW_CBFS5', columnId: 0, order: 1, sectionBn: 'ORG', size: 'LARGE', configuration: {bn: 'NEW_CONFIG3A', contentBlockType: 'FIELDSET', locationId: '00170000004S'}},
        {bn: 'NEW_CBFS9', columnId: 0, order: 1, sectionBn: 'PER', size: 'LARGE', configuration: {bn: 'NEW_CONFIG3A', contentBlockType: 'FIELDSET', locationId: '00170000003D'}},
        {bn: 'NEW_CBFS6', columnId: 0, order: 1, sectionBn: 'COM', size: 'LARGE', configuration: {bn: 'NEW_CONFIG8A', contentBlockType: 'FIELDSET', locationId: '001700000039'}},
        {bn: 'NEW_CBFS6', columnId: 0, order: 1, sectionBn: 'ACT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG3A', contentBlockType: 'FIELDSET', locationId: '0017000000M9'}},
        {bn: 'NEW_CBFS8', columnId: 0, order: 1, sectionBn: 'CAP', size: 'LARGE', configuration: {bn: 'NEW_CONFIG8A', contentBlockType: 'FIELDSET', locationId: '00170000004N'}},
        {bn: 'NEW_CBFS1', columnId: 0, order: 1, sectionBn: 'PRD', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '001700000081'}},
        {bn: 'NEW_CBFS18', columnId: 0, order: 1, sectionBn: 'MKT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG15A', contentBlockType: 'FIELDSET', locationId: '0017000000MR'}},
        {bn: 'NEW_CBFS13', columnId: 0, order: 1, sectionBn: 'DAT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '0017000000ML'}},
        {bn: 'NEW_CBFS7', columnId: 0, order: 1, sectionBn: 'SYS', size: 'LARGE', configuration: {bn: 'NEW_CONFIG2A', contentBlockType: 'FIELDSET', locationId: '00170000003K'}},
        {bn: 'NEW_CBFS7', columnId: 0, order: 1, sectionBn: 'CON', size: 'LARGE', configuration: {bn: 'NEW_CONFIG2A', contentBlockType: 'FIELDSET', locationId: '0017000000MF'}},
        {bn: 'NEW_CBFS11', columnId: 0, order: 1, sectionBn: 'STA', size: 'LARGE', configuration: {bn: 'NEW_CONFIG5A', contentBlockType: 'FIELDSET', locationId: '00170000003J'}},
        {bn: 'NEW_CBFS10', columnId: 0, order: 1, sectionBn: 'SKI', size: 'LARGE', configuration: {bn: 'NEW_CONFIG4A', contentBlockType: 'FIELDSET', locationId: '00170000003H'}},
        {bn: 'NEW_CBFS7', columnId: 0, order: 1, sectionBn: 'PHY', size: 'LARGE', configuration: {bn: '7A', contentBlockType: 'FIELDSET', locationId: '0017000000KS'}},
        {bn: 'NEW_CBFS12', columnId: 0, order: 1, sectionBn: 'AST', size: 'LARGE', configuration: {bn: 'NEW_CONFIG7A', contentBlockType: 'FIELDSET', locationId: '00170000003C'}}
    ],
    sections: [
        {bn: 'BASIC', name: 'Technology Overview', type: 'TechnologyOverview', order: 0, category: 'primary', proposedCodes: 'PLL, TEC:HAS_CHILD, TEC:CONTAINS, TEC:PREVIOUS, LNK, TAG'},
        {bn: 'STR', name: 'Related Strategies', type: 'Strategies', order: 1, category: 'related', assocCode: 'STR', proposedCodes: 'STR', roles: 'ROLE_STRATEGY_MODULE_ACCESS'},
        {bn: 'DEM', name: 'Related Demands', type: 'Demands', order: 2, category: 'related', assocCode: 'DEM', proposedCodes: 'DEM', roles: 'ROLE_DEMAND_MODULE_ACCESS'},
        {bn: 'ORG', name: 'Related Organizations', type: 'Organizations', order: 3, category: 'related', assocCode: 'ORG', proposedCodes: 'ORG'},
        {bn: 'PER', name: 'Related People', type: 'People', order: 4, category: 'related', assocCode: 'PER', proposedCodes: 'PER'},
        {bn: 'COM', name: 'Related Companies', type: 'Companies', order: 5, category: 'related', assocCode: 'COM', proposedCodes: 'COM'},
        {bn: 'ACT', name: 'Related Actors', type: 'Actors', order: 6, category: 'related', assocCode: 'ACT', proposedCodes: 'ACT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CAP', name: 'Related Capabilities', type: 'Capabilities', order: 7, category: 'related', assocCode: 'CAP', proposedCodes: 'CAP', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'PRD', name: 'Related Products', type: 'Products', order: 8, category: 'related', assocCode: 'PRD', proposedCodes: 'PRD', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'MKT', name: 'Related Markets', type: 'Markets', order: 9, category: 'related', assocCode: 'MKT', proposedCodes: 'MKT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'DAT', name: 'Related Data', type: 'Data', order: 10, category: 'related', assocCode: 'DAT', proposedCodes: 'DAT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'SYS', name: 'Related Systems', type: 'Systems', order: 11, category: 'related', assocCode: 'SYS', proposedCodes: 'SYS', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CON', name: 'Related Connections', type: 'Connections', order: 12, category: 'related', assocCode: 'CON', proposedCodes: 'CON', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'STA', name: 'Related Standards', type: 'Standards', order: 13, category: 'related', assocCode: 'STA', proposedCodes: 'STA'},
        {bn: 'SKI', name: 'Related Skills', type: 'Skills', order: 14, category: 'related', assocCode: 'SKI', proposedCodes: 'SKI'},
        {bn: 'PHY', name: 'Related Deployments', type: 'Physicals', order: 15, category: 'related', assocCode: 'PHY', proposedCodes: 'PHY', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'AST', name: 'Related Documents', type: 'Documents', order: 16, category: 'related', assocCode: 'AST', proposedCodes: 'AST'},
        {bn: 'TOP', name: 'Related Discussions', type: 'Discussions', order: 17, category: 'related', assocCode: 'TOP'},
        {bn: 'EAY', name: 'Activity', type: 'Activity', order: 18, category: 'tertiary', assocCode: 'EAY'}
    ]};

// Default Person / Profile Layout
BnCodeToLayout['0E'] = {bn: '0E',
    countAssocs: sideBarConfigs['0E'].single,
    contentBlocks: [
        {bn: 'NEW_CB1', columnId: 0, order: 0, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'PROFILE_BASIC_INFO'}},
        profileNews,
        {bn: 'NEW_CB3', columnId: 1, order: 0, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'PROFILE_PICTURE'}},
        strategyPersonAssociation,
        demandPersonAssociation,
        orgPersonAssociations,
        companyPersonAssociations,
        actorPersonAssociations,
        capabilityPersonAssociations,
        productPersonAssociation,
        personContactAssociation,
        marketPersonAssociations,
        dataPersonAssociations,
        systemPersonAssociations,
        connectionPersonAssociations,
        technologyPersonAssociations,
        standardPersonAssociations,
        skillPersonAssociations,
        physicalPersonAssociation,
        documentPersonAssociations,
        tagPersonAssociations,
        activityAssociation,
        //fieldsets
        {bn: 'NEW_CBFS1', columnId: 0, order: 1, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '00170000003X'}},
        {bn: 'NEW_CBFS12', columnId: 0, order: 1, sectionBn: 'STR', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '001700000059'}},
        {bn: 'NEW_CBFS2', columnId: 0, order: 1, sectionBn: 'DEM', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '00170000006R'}},
        {bn: 'NEW_CBFS11', columnId: 0, order: 1, sectionBn: 'ORG', size: 'LARGE', configuration: {bn: 'NEW_CONFIG9A', contentBlockType: 'FIELDSET', locationId: '001700000044'}},
        {bn: 'NEW_CBFS3', columnId: 0, order: 1, sectionBn: 'COM', size: 'LARGE', configuration: {bn: 'NEW_CONFIG2A', contentBlockType: 'FIELDSET', locationId: '001700000024'}},
        {bn: 'NEW_CBFS3', columnId: 0, order: 1, sectionBn: 'ACT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG2A', contentBlockType: 'FIELDSET', locationId: '0017000000M1'}},
        {bn: 'NEW_CBFS7', columnId: 0, order: 1, sectionBn: 'CAP', size: 'LARGE', configuration: {bn: 'NEW_CONFIG7A', contentBlockType: 'FIELDSET', locationId: '001700000043'}},
        {bn: 'NEW_CBFS13', columnId: 0, order: 1, sectionBn: 'PRD', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '00170000007W'}},
        {bn: 'NEW_CBFS14', columnId: 0, order: 1, sectionBn: 'MKT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '00170000007Q'}},
        {bn: 'NEW_CBFS10', columnId: 0, order: 1, sectionBn: 'DAT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG8A', contentBlockType: 'FIELDSET', locationId: '00170000000W'}},
        {bn: 'NEW_CBFS3', columnId: 0, order: 1, sectionBn: 'SYS', size: 'LARGE', configuration: {bn: 'NEW_CONFIG2A', contentBlockType: 'FIELDSET', locationId: '00170000003Z'}},
        {bn: 'NEW_CBFS8', columnId: 0, order: 1, sectionBn: 'CON', size: 'LARGE', configuration: {bn: 'NEW_CONFIG7B', contentBlockType: 'FIELDSET', locationId: '00170000004C'}},
        {bn: 'NEW_CBFS4', columnId: 0, order: 1, sectionBn: 'TEC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG4A', contentBlockType: 'FIELDSET', locationId: '001700000040'}},
        {bn: 'NEW_CBFS5', columnId: 0, order: 1, sectionBn: 'STA', size: 'LARGE', configuration: {bn: 'NEW_CONFIG5A', contentBlockType: 'FIELDSET', locationId: '001700000041'}},
        {bn: 'NEW_CBFS6', columnId: 0, order: 1, sectionBn: 'SKI', size: 'LARGE', configuration: {bn: 'NEW_CONFIG6A', contentBlockType: 'FIELDSET', locationId: '001700000042'}},
        {bn: 'NEW_CBFS7', columnId: 0, order: 1, sectionBn: 'PHY', size: 'LARGE', configuration: {bn: '7A', contentBlockType: 'FIELDSET', locationId: '0017000000KK'}},
        {bn: 'NEW_CBFS9', columnId: 0, order: 1, sectionBn: 'AST', size: 'LARGE', configuration: {bn: 'NEW_CONFIG8A', contentBlockType: 'FIELDSET', locationId: '00170000002G'}},
        {bn: 'NEW_CBFS9', columnId: 0, order: 1, sectionBn: 'TAG', size: 'LARGE', configuration: {bn: 'NEW_CONFIG8A', contentBlockType: 'FIELDSET', locationId: '0017000000N1'}}
    ],
    sections: [
        {bn: 'BASIC', name: 'Profile Overview', type: 'PersonOverview', order: 0, category: 'primary'},
        {bn: 'PAY', layoutSectionType: 'WHOLE', name: 'News', type: 'PersonNews', order: 1, category: 'primary', assocCode: 'PAY'},
        {bn: 'STR', name: 'Related Strategies', type: 'Strategies', order: 2, category: 'related', assocCode: 'STR', proposedCodes: 'STR', roles: 'ROLE_STRATEGY_MODULE_ACCESS'},
        {bn: 'DEM', name: 'Related Demands', type: 'Demands', order: 3, category: 'related', assocCode: 'DEM', proposedCodes: 'DEM', roles: 'ROLE_DEMAND_MODULE_ACCESS'},
        {bn: 'ORG', name: 'Related Organizations', type: 'Organizations', order: 4, category: 'related', assocCode: 'ORG'},
        {bn: 'COM', name: 'Related Companies', type: 'Companies', order: 5, category: 'related', assocCode: 'COM', proposedCodeds: 'COM'},
        {bn: 'ACT', name: 'Related Actors', type: 'Actors', order: 6, category: 'related', assocCode: 'ACT', proposedCodeds: 'ACT'},
        {bn: 'CAP', name: 'Related Capabilities', type: 'Capabilities', order: 7, category: 'related', assocCode: 'CAP', proposedCodes: 'CAP', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'PRD', name: 'Related Products', type: 'Products', order: 8, category: 'related', assocCode: 'PRD', proposedCodes: 'PRD', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'PER', name: 'Related People', type: 'People', order: 9, category: 'related', assocCode: 'PER', proposedCodes: 'PER'},
        {bn: 'MKT', name: 'Related Markets', type: 'Markets', order: 10, category: 'related', assocCode: 'MKT', proposedCodes: 'MKT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'DAT', name: 'Related Data', type: 'Data', order: 11, category: 'related', assocCode: 'DAT', proposedCodes: 'DAT', roles: 'ROLE_DATA_MODULE_ACCESS'},
        {bn: 'SYS', name: 'Related Systems', type: 'Systems', order: 12, category: 'related', assocCode: 'SYS', proposedCodes: 'SYS', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CON', name: 'Related Connections', type: 'Connections', order: 13, category: 'related', assocCode: 'CON', proposedCodes: 'CON', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TEC', name: 'Related Technologies', type: 'Technologies', order: 14, category: 'related', assocCode: 'TEC', proposedCodes: 'TEC'},
        {bn: 'STA', name: 'Related Standards', type: 'Standards', order: 15, category: 'related', assocCode: 'STA', proposedCodes: 'STA'},
        {bn: 'SKI', name: 'Related Skills', type: 'Skills', order: 16, category: 'related', assocCode: 'SKI', proposedCodes: 'SKI'},
        {bn: 'PHY', name: 'Related Deployments', type: 'Physicals', order: 17, category: 'related', assocCode: 'PHY', proposedCodes: 'PHY', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'AST', name: 'Related Documents', type: 'Documents', order: 18, category: 'related', assocCode: 'AST', proposedCodes: 'AST'},
        {bn: 'TAG', name: 'Related Tags', type: 'Tags', order: 19, category: 'related', assocCode: 'TAG', proposedCodes: 'TAG'},
        {bn: 'EAY', name: 'Activity', type: 'Activity', order: 20, category: 'tertiary', assocCode: 'EAY'}
    ]};

// Default Organization Layout
BnCodeToLayout['0Y'] = {bn: '0Y',
    countAssocs: sideBarConfigs['0Y'].single,
    contentBlocks: [
        {bn: 'NEW_CB1', columnId: 0, order: 0, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'ORGANIZATION_BASIC_INFO'}},
        basicInfoSidebar,
        {bn: 'NEW_CBXA1', columnId: 0, order: 0, sectionBn: 'ORG', size: 'LARGE', configuration: {bn: 'NEW_CONFIGXA', contentBlockType: 'ORGANIZATION_ARCHITECTURE'}},
        strategyAssociation,
        strategyCount,
        demandAssociation,
        demandCount,
        personEmploymentAssociation,
        employmentCount,
        actorAssociation,
        actorCount,
        capabilityAssociation,
        capabilityCount,
        companyCount,
        companyAssociation,
        productAssociation,
        productCount,
        marketCount,
        marketAssociation,
        dataAssociation,
        dataCount,
        systemAssociation,
        systemCount,
        systemPriorityPie,
        systemCoreSystemsPie,
        connectionAssociationNoDiagram,
        connectionCount,
        technologyAssociation,
        technologyCount,
        skillAssociation,
        skillCount,
        physicalAssociation,
        physicalCount,
        standardAssociation,
        standardCount,
        documentAssociationByEntity,
        tagCount,
        {bn: 'TAG', columnId: 0, order: 20, sectionBn: 'TAG', size: 'LARGE', configuration: {bn: 'TAG_ASSOC_CONFIG', contentBlockType: 'TAG_ASSOCIATIONS'}},
        discussionAssociation,
        activityAssociation,
        //fieldsets
        {bn: 'NEW_CBFS1', columnId: 0, order: 1, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '00170000001C'}},
        {bn: 'NEW_CBFS2', columnId: 0, order: 1, sectionBn: 'ORG', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '00170000001F'}},
        {bn: 'NEW_CBFS3', columnId: 0, order: 1, sectionBn: 'STR', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '001700000058'}},
        {bn: 'NEW_CBFS4', columnId: 0, order: 1, sectionBn: 'DEM', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '00170000006Q'}},
        {bn: 'NEW_CBFS8', columnId: 0, order: 1, sectionBn: 'PER', size: 'LARGE', configuration: {bn: 'NEW_CONFIG3A', contentBlockType: 'FIELDSET', locationId: '00170000001H'}},
        {bn: 'NEW_CBFS9', columnId: 0, order: 1, sectionBn: 'ACT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG5A', contentBlockType: 'FIELDSET', locationId: '00170000001D'}},
        {bn: 'NEW_CBFS12', columnId: 0, order: 1, sectionBn: 'CAP', size: 'LARGE', configuration: {bn: 'NEW_CONFIG6A', contentBlockType: 'FIELDSET', locationId: '00170000004R'}},
        {bn: 'NEW_CBFS15', columnId: 0, order: 1, sectionBn: 'COM', size: 'LARGE', configuration: {bn: 'NEW_CONFIG6A', contentBlockType: 'FIELDSET', locationId: '0017000000MY'}},
        {bn: 'NEW_CBFS10', columnId: 0, order: 1, sectionBn: 'PRD', size: 'LARGE', configuration: {bn: 'NEW_CONFIG6A', contentBlockType: 'FIELDSET', locationId: '001700000017'}},
        {bn: 'NEW_CBFS14', columnId: 0, order: 1, sectionBn: 'MKT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG5A', contentBlockType: 'FIELDSET', locationId: '00170000007P'}},
        {bn: 'NEW_CBFS6', columnId: 0, order: 1, sectionBn: 'DAT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG5A', contentBlockType: 'FIELDSET', locationId: '00170000001X'}},
        {bn: 'NEW_CBFS5', columnId: 0, order: 1, sectionBn: 'SYS', size: 'LARGE', configuration: {bn: 'NEW_CONFIG2A', contentBlockType: 'FIELDSET', locationId: '00170000001J'}},
        {bn: 'NEW_CBFS7', columnId: 0, order: 1, sectionBn: 'CON', size: 'LARGE', configuration: {bn: 'NEW_CONFIG5A', contentBlockType: 'FIELDSET', locationId: '001700000050'}},
        {bn: 'NEW_CBFS13', columnId: 0, order: 1, sectionBn: 'TEC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG5A', contentBlockType: 'FIELDSET', locationId: '00170000004P'}},
        {bn: 'NEW_CBFS11', columnId: 0, order: 1, sectionBn: 'STA', size: 'LARGE', configuration: {bn: 'NEW_CONFIG5A', contentBlockType: 'FIELDSET', locationId: '0017000000MU'}},
        {bn: 'NEW_CBFS10', columnId: 0, order: 1, sectionBn: 'SKI', size: 'LARGE', configuration: {bn: 'NEW_CONFIG4A', contentBlockType: 'FIELDSET', locationId: '0017000000MS'}},
        {bn: 'NEW_CBFS7', columnId: 0, order: 1, sectionBn: 'PHY', size: 'LARGE', configuration: {bn: '7A', contentBlockType: 'FIELDSET', locationId: '0017000000KJ'}},
        {bn: 'NEW_CBFS22', columnId: 0, order: 1, sectionBn: 'TAG', size: 'LARGE', configuration: {bn: '8A', contentBlockType: 'FIELDSET', locationId: '0017000000MZ'}},
        {bn: 'NEW_CBFS11', columnId: 0, order: 1, sectionBn: 'AST', size: 'LARGE', configuration: {bn: 'NEW_CONFIG4A', contentBlockType: 'FIELDSET', locationId: '00170000001G'}}
    ],
    sections: [
        {bn: 'BASIC', name: 'Organization Overview', type: 'OrganizationOverview', order: 0, category: 'primary', proposedCodes: 'LNK'},
        {bn: 'ORG', name: 'Organization Chart', type: 'OrganizationArchitecture', order: 1, category: 'primary', proposedCodes: 'LNK, ORG:HAS_CHILD'},
        {bn: 'STR', name: 'Related Strategies', type: 'Strategies', order: 2, category: 'related', assocCode: 'STR', proposedCodes: 'STR', roles: 'ROLE_STRATEGY_MODULE_ACCESS'},
        {bn: 'DEM', name: 'Related Demands', type: 'Demands', order: 3, category: 'related', assocCode: 'DEM', proposedCodes: 'DEM', roles: 'ROLE_DEMAND_MODULE_ACCESS'},
        {bn: 'PER', name: 'Related People', type: 'People', order: 4, category: 'related', assocCode: 'PER', proposedCodes: 'PER'},
        {bn: 'ACT', name: 'Related Actors', type: 'Actors', order: 5, category: 'related', assocCode: 'ACT', proposedCodes: 'ACT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CAP', name: 'Related Capabilities', type: 'Capabilities', order: 6, category: 'related', assocCode: 'CAP', proposedCodes: 'CAP', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'COM', name: 'Related Companies', type: 'Companies', order: 7, category: 'related', assocCode: 'COM', proposedCodes: 'COM', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'PRD', name: 'Related Products', type: 'Products', order: 8, category: 'related', assocCode: 'PRD', proposedCodes: 'PRD', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'MKT', name: 'Related Markets', type: 'Markets', order: 9, category: 'related', assocCode: 'MKT', proposedCodes: 'MKT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'DAT', name: 'Related Data', type: 'Data', order: 10, category: 'related', assocCode: 'DAT', proposedCodes: 'DAT', roles: 'ROLE_DATA_MODULE_ACCESS'},
        {bn: 'SYS', name: 'Related Systems', type: 'Systems', order: 11, category: 'related', assocCode: 'SYS', proposedCodes: 'SYS', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CON', name: 'Related Connections', type: 'Connections', order: 12, category: 'related', assocCode: 'CON', proposedCodes: 'CON', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'STA', name: 'Related Standards', type: 'Standards', order: 13, category: 'related', assocCode: 'STA', proposedCodes: 'STA'},
        {bn: 'TEC', name: 'Related Technologies', type: 'Technologies', order: 14, category: 'related', assocCode: 'TEC', proposedCodes: 'TEC'},
        {bn: 'SKI', name: 'Related Skills', type: 'Skills', order: 15, category: 'related', assocCode: 'SKI', proposedCodes: 'SKI'},
        {bn: 'PHY', name: 'Related Deployments', type: 'Physicals', order: 16, category: 'related', assocCode: 'PHY', proposedCodes: 'PHY', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TAG', name: 'Related Tags', type: 'Tags', order: 17, category: 'related', assocCode: 'TAG', proposedCodes: 'TAG'},
        {bn: 'AST', name: 'Related Documents', type: 'Documents', order: 18, category: 'related', assocCode: 'AST', proposedCodes: 'AST'},
        {bn: 'TOP', name: 'Related Discussions', type: 'Discussions', order: 19, category: 'related', assocCode: 'TOP'},
        {bn: 'EAY', name: 'Activity', type: 'Activity', order: 20, category: 'tertiary', assocCode: 'EAY'}


    ]};

// Default Company Layout
BnCodeToLayout['2F'] = {bn: '2F',
    countAssocs: sideBarConfigs['2F'].single,
    contentBlocks: [
        {bn: 'NEW_CB1', columnId: 0, order: 0, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'COMPANY_BASIC_INFO'}},
        basicInfoSidebarWithSummaryInfoContacts,
        strategyAssociation,
        strategyCount,
        demandAssociation,
        demandCount,
        personAssociation,
        contactCount,
        actorAssociation,
        actorCount,
        employmentCount,
        capabilityAssociation,
        capabilityCount,
        productAssociation,
        productCount,
        marketCount,
        marketAssociation,
        orgCount,
        orgAssociation,
        dataAssociation,
        dataCount,
        systemAssociation,
        systemCount,
        systemPriorityPie,
        systemCoreSystemsPie,
        connectionAssociationToCompany,
        connectionCount,
        connectionTypesCount,
        connectionTypesPie,
        connectionPatternsCount,
        connectionPatternsPie,
        connectionTransportsCount,
        connectionTransportsPie,
        technologyAssociation,
        technologyEnterpriseApprovePie,
        technologyCount,
        skillAssociation,
        skillCount,
        standardAssociation,
        standardCount,
        physicalAssociation,
        physicalCount,
        documentAssociationByEntity,
        discussionAssociation,
        activityAssociation,
        //fieldsets
        {bn: 'NEW_CBFS1', columnId: 0, order: 1, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG2', contentBlockType: 'FIELDSET', locationId: '00170000000H'}},
        {bn: 'NEW_CBFS2', columnId: 0, order: 1, sectionBn: 'STR', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '001700000055'}},
        {bn: 'NEW_CBFS3', columnId: 0, order: 1, sectionBn: 'DEM', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1A', contentBlockType: 'FIELDSET', locationId: '001700000062'}},
        {bn: 'NEW_CBFS11', columnId: 0, order: 1, sectionBn: 'PER', size: 'LARGE', configuration: {bn: 'NEW_CONFIG8', contentBlockType: 'FIELDSET', locationId: '001700000045'}},
        {bn: 'NEW_CBFS6', columnId: 0, order: 1, sectionBn: 'ACT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG4A', contentBlockType: 'FIELDSET', locationId: '00170000003S'}},
        {bn: 'NEW_CBFS4', columnId: 0, order: 1, sectionBn: 'CAP', size: 'LARGE', configuration: {bn: 'NEW_CONFIG9', contentBlockType: 'FIELDSET', locationId: '00170000004Q'}},
        {bn: 'NEW_CBFS15', columnId: 0, order: 1, sectionBn: 'COM', size: 'LARGE', configuration: {bn: 'NEW_CONFIG6A', contentBlockType: 'FIELDSET', locationId: '0017000000MY'}},
        {bn: 'NEW_CBFS5', columnId: 0, order: 1, sectionBn: 'PRD', size: 'LARGE', configuration: {bn: 'NEW_CONFIG3', contentBlockType: 'FIELDSET', locationId: '00170000004D'}},
        {bn: 'NEW_CBFS12', columnId: 0, order: 1, sectionBn: 'MKT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG8', contentBlockType: 'FIELDSET', locationId: '001700000072'}},
        {bn: 'NEW_CBFS13', columnId: 0, order: 1, sectionBn: 'ORG', size: 'LARGE', configuration: {bn: 'NEW_CONFIG8', contentBlockType: 'FIELDSET', locationId: '0017000000MX'}},
        {bn: 'NEW_CBFS7', columnId: 0, order: 1, sectionBn: 'DAT', size: 'LARGE', configuration: {bn: 'NEW_CONFIG4', contentBlockType: 'FIELDSET', locationId: '00170000000M'}},
        {bn: 'NEW_CBFS6', columnId: 0, order: 1, sectionBn: 'SYS', size: 'LARGE', configuration: {bn: 'NEW_CONFIG4', contentBlockType: 'FIELDSET', locationId: '00170000000P'}},
        {bn: 'NEW_CBFS8', columnId: 0, order: 1, sectionBn: 'CON', size: 'LARGE', configuration: {bn: 'NEW_CONFIG5', contentBlockType: 'FIELDSET', locationId: '00170000000L'}},
        {bn: 'NEW_CBFS9', columnId: 0, order: 1, sectionBn: 'TEC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG6', contentBlockType: 'FIELDSET', locationId: '00170000000Q'}},
        {bn: 'NEW_CBFS13', columnId: 0, order: 1, sectionBn: 'SKI', size: 'LARGE', configuration: {bn: 'NEW_CONFIG5A', contentBlockType: 'FIELDSET', locationId: '0017000000MA'}},
        {bn: 'NEW_CBFS10', columnId: 0, order: 1, sectionBn: 'STA', size: 'LARGE', configuration: {bn: 'NEW_CONFIG7', contentBlockType: 'FIELDSET', locationId: '00170000000N'}},
        {bn: 'NEW_CBFS7', columnId: 0, order: 1, sectionBn: 'PHY', size: 'LARGE', configuration: {bn: '7A', contentBlockType: 'FIELDSET', locationId: '0017000000KC'}},
        {bn: 'NEW_CBFS14', columnId: 0, order: 1, sectionBn: 'AST', size: 'LARGE', configuration: {bn: 'NEW_CONFIG4A', contentBlockType: 'FIELDSET', locationId: '0017000000MW'}}
    ],
    sections: [
        {bn: 'BASIC', name: 'Company Overview', type: 'CompanyOverview', order: 0, category: 'primary', proposedCodes: 'PLL, LNK, TAG, COM:ACQUIRED'},
        {bn: 'STR', name: 'Related Strategies', type: 'Strategies', order: 1, category: 'related', assocCode: 'STR', proposedCodes: 'STR', roles: 'ROLE_STRATEGY_MODULE_ACCESS'},
        {bn: 'DEM', name: 'Related Demands', type: 'Demands', order: 2, category: 'related', assocCode: 'DEM', proposedCodes: 'DEM', roles: 'ROLE_DEMAND_MODULE_ACCESS'},
        {bn: 'PER', name: 'Related People', type: 'People', order: 3, category: 'related', assocCode: 'PER', proposedCodes: 'PER'},
        {bn: 'ACT', name: 'Related Actors', type: 'Actors', order: 4, category: 'related', assocCode: 'ACT', proposedCodes: 'ACT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'ORG', name: 'Related Organizations', type: 'Organizations', order: 5, category: 'related', assocCode: 'ORG', proposedCodes: 'ORG', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CAP', name: 'Related Capabilities', type: 'Capabilities', order: 6, category: 'related', assocCode: 'CAP', proposedCodes: 'CAP', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'PRD', name: 'Related Products', type: 'Products', order: 7, category: 'related', assocCode: 'PRD', proposedCodes: 'PRD', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'MKT', name: 'Related Markets', type: 'Markets', order: 8, category: 'related', assocCode: 'MKT', proposedCodes: 'MKT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'DAT', name: 'Related Data', type: 'Data', order: 9, category: 'related', assocCode: 'DAT', proposedCodes: 'DAT', roles: 'ROLE_DATA_MODULE_ACCESS'},
        {bn: 'SYS', name: 'Related Systems', type: 'Systems', order: 10, category: 'related', assocCode: 'SYS', proposedCodes: 'SYS', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CON', name: 'Related Connections', type: 'Connections', order: 11, category: 'related', assocCode: 'CON', proposedCodes: 'CON', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TEC', name: 'Related Technologies', type: 'Technologies', order: 12, category: 'related', assocCode: 'TEC', proposedCodes: 'TEC'},
        {bn: 'SKI', name: 'Related Skills', type: 'Skills', order: 13, category: 'related', assocCode: 'SKI', proposedCodes: 'SKI'},
        {bn: 'STA', name: 'Related Standards', type: 'Standards', order: 14, category: 'related', assocCode: 'STA', proposedCodes: 'STA'},
        {bn: 'PHY', name: 'Related Deployments', type: 'Physicals', order: 15, category: 'related', assocCode: 'PHY', proposedCodes: 'PHY', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'AST', name: 'Related Documents', type: 'Documents', order: 16, category: 'related', assocCode: 'AST', proposedCodes: 'AST'},
        {bn: 'TOP', name: 'Related Discussions', type: 'Discussions', order: 17, category: 'related', assocCode: 'TOP'},
        {bn: 'EAY', name: 'Activity', type: 'Activity', order: 18, category: 'tertiary', assocCode: 'EAY'}
    ]};

// Default Document Layout
BnCodeToLayout['0U'] = {bn: '0U',
    countAssocs: sideBarConfigs['0U'].single,
    contentBlocks: [
        {bn: '1', columnId: 0, order: 0, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'DOCUMENT_BASIC_INFO'}},
        basicInfoSidebar,
        {bn: '2', columnId: 0, order: 0, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG2', contentBlockType: 'DOCUMENT_VERSIONS'}},
        strategyAssociation,
        strategyCount,
        demandAssociation,
        demandCount,
        orgAssociation,
        orgCount,
        personContactAssociation,
        contactCount,
        actorAssociation,
        actorCount,
        capabilityAssociation,
        capabilityCount,
        capabilityEnterpriseApprovePie,
        companyAssociation,
        companyCount,
        productAssociation,
        productCount,
        marketCount,
        marketAssociation,
        dataAssociation,
        dataCount,
        dataEnterpriseApprovalPie,
        systemAssociation,
        systemCount,
        systemPriorityPie,
        systemCoreSystemsPie,
        connectionAssociationNoDiagram,
        connectionCount,
        connectionTypesCount,
        connectionTypesPie,
        connectionPatternsCount,
        connectionPatternsPie,
        connectionTransportsCount,
        connectionTransportsPie,
        technologyAssociation,
        technologyEnterpriseApprovePie,
        technologyCount,
        standardAssociation,
        standardCount,
        skillAssociation,
        skillCount,
        physicalAssociation,
        physicalCount,
        circleAssociation,
        worksheet2Association,
        discussionAssociation,
        activityAssociation
    ],
    sections: [
        {bn: 'BASIC', name: 'Document Overview', type: 'DocumentOverview', order: 0, category: 'primary', proposedCodes: 'PLL,TAG'},
        {bn: 'STR', name: 'Related Strategies', type: 'Strategies', order: 1, category: 'related', assocCode: 'STR', proposedCodes: 'STR', roles: 'ROLE_STRATEGY_MODULE_ACCESS'},
        {bn: 'DEM', name: 'Related Demands', type: 'Demands', order: 2, category: 'related', assocCode: 'DEM', proposedCodes: 'DEM', roles: 'ROLE_DEMAND_MODULE_ACCESS'},
        {bn: 'ORG', name: 'Related Organizations', type: 'Organizations', order: 3, category: 'related', assocCode: 'ORG', proposedCodes: 'ORG'},
        {bn: 'PER', name: 'Related People', type: 'People', order: 4, category: 'related', assocCode: 'PER', proposedCodes: 'PER'},
        {bn: 'ACT', name: 'Related Actors', type: 'Actors', order: 5, category: 'related', assocCode: 'ACT', proposedCodes: 'ACT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CAP', name: 'Related Capabilities', type: 'Capabilities', order: 6, category: 'related', assocCode: 'CAP', proposedCodes: 'CAP', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'COM', name: 'Related_Company', type: 'Companies', order: 7, category: 'related', assocCode: 'COM', proposedCodes: 'COM', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'PRD', name: 'Related Products', type: 'Products', order: 8, category: 'related', assocCode: 'PRD', proposedCodes: 'PRD', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'MKT', name: 'Related Markets', type: 'Markets', order: 9, category: 'related', assocCode: 'MKT', proposedCodes: 'MKT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'DAT', name: 'Related Data', type: 'Data', order: 10, category: 'related', assocCode: 'DAT', proposedCodes: 'DAT', roles: 'ROLE_DATA_MODULE_ACCESS'},
        {bn: 'SYS', name: 'Related Systems', type: 'Systems', order: 11, category: 'related', assocCode: 'SYS', proposedCodes: 'SYS', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CON', name: 'Related Connections', type: 'Connections', order: 12, category: 'related', assocCode: 'CON', proposedCodes: 'CON', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TEC', name: 'Related Technologies', type: 'Technologies', order: 13, category: 'related', assocCode: 'TEC', proposedCodes: 'TEC'},
        {bn: 'STA', name: 'Related Standards', type: 'Standards', order: 14, category: 'related', assocCode: 'STA', proposedCodes: 'STA'},
        {bn: 'SKI', name: 'Related Skills', type: 'Skills', order: 15, category: 'related', assocCode: 'SKI', proposedCodes: 'SKI'},
        {bn: 'PHY', name: 'Related Deployments', type: 'Physicals', order: 16, category: 'related', assocCode: 'PHY', proposedCodes: 'PHY', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CIR', name: 'Related Collections', type: 'Workspaces', order: 17, category: 'related', assocCode: 'CIR', proposedCodes: 'CIR', roles: 'ROLE_CIRCLE_MODULE_ACCESS'},
        {bn: 'WK2', name: 'Related Worksheets', type: 'Worksheets', order: 18, category: 'related', assocCode: 'WK2', proposedCodes: 'WK2', roles: 'ROLE_CIRCLE_MODULE_ACCESS'},
        {bn: 'TOP', name: 'Related Discussions', type: 'Discussions', order: 19, category: 'related', assocCode: 'TOP'},
        {bn: 'EAY', name: 'Activity', type: 'Activity', order: 20, category: 'tertiary', assocCode: 'EAY'}
    ]};
// Workspace Layout
BnCodeToLayout['2O'] = {bn: '2O',
    countAssocs: sideBarConfigs['2O'].single,
    contentBlocks: [
        {bn: 'NEW_CB1', columnId: 0, order: 0, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'WORKSPACE_BASIC_INFO'}},
        basicInfoSidebar,
        {bn: 'CAY', columnId: 0, order: 0, sectionBn: 'CAY', size: 'LARGE', configuration: {bn: 'CAY_ASSOC_CONFIG', contentBlockType: 'ACTIVITY_ASSOCIATIONS'}}, //circleNews
        {bn: 'NEW_CB4', columnId: 0, order: 0, sectionBn: 'MEMBERS', size: 'LARGE', configuration: {bn: 'NEW_CONFIG4', contentBlockType: 'CIRCLE_MEMBERS'}},
        strategyCount,
        {bn: 'STR', columnId: 0, order: 0, sectionBn: 'STR', size: 'LARGE', configuration: {bn: 'STR_ASSOC_CONFIG', contentBlockType: 'STRATEGY_ASSOCIATIONS'}},
        demandCount,
        {bn: 'DEM', columnId: 0, order: 0, sectionBn: 'DEM', size: 'LARGE', configuration: {bn: 'DEM_ASSOC_CONFIG', contentBlockType: 'DEMAND_ASSOCIATIONS'}},
        orgCount,
        {bn: 'ORG', columnId: 0, order: 0, sectionBn: 'ORG', size: 'LARGE', configuration: {bn: 'ORG_ASSOC_CONFIG', contentBlockType: 'ORGANIZATION_ASSOCIATIONS'}},
        companyCount,
        {bn: 'COM', columnId: 0, order: 0, sectionBn: 'COM', size: 'LARGE', configuration: {bn: 'COM_ASSOC_CONFIG', contentBlockType: 'COMPANY_ASSOCIATIONS'}},
        actorCount,
        {bn: 'ACT', columnId: 0, order: 0, sectionBn: 'ACT', size: 'LARGE', configuration: {bn: 'ACT_ASSOC_CONFIG', contentBlockType: 'ACTOR_ASSOCIATIONS'}},
        capabilityCount,
        {bn: 'CAP', columnId: 0, order: 0, sectionBn: 'CAP', size: 'LARGE', configuration: {bn: 'CAP_ASSOC_CONFIG', contentBlockType: 'CAPABILITY_ASSOCIATIONS'}},
        productCount,
        {bn: 'PRD', columnId: 0, order: 0, sectionBn: 'PRD', size: 'LARGE', configuration: {bn: 'PRD_ASSOC_CONFIG', contentBlockType: 'PRODUCT_ASSOCIATIONS'}},
        marketCount,
        {bn: 'MKT', columnId: 0, order: 0, sectionBn: 'MKT', size: 'LARGE', configuration: {bn: 'MKT_ASSOC_CONFIG', contentBlockType: 'MARKET_ASSOCIATIONS'}},
        dataCount,
        {bn: 'DAT', columnId: 0, order: 0, sectionBn: 'DAT', size: 'LARGE', configuration: {bn: 'DAT_ASSOC_CONFIG', contentBlockType: 'DATA_ASSOCIATIONS'}},
        systemCount,
        {bn: 'SYS', columnId: 0, order: 0, sectionBn: 'SYS', size: 'LARGE', configuration: {bn: 'SYS_ASSOC_CONFIG', contentBlockType: 'SYSTEM_ASSOCIATIONS'}},
        connectionCount,
        {bn: 'CON', columnId: 0, order: 0, sectionBn: 'CON', size: 'LARGE', configuration: {bn: 'CON_ASSOC_CONFIG', contentBlockType: 'CONNECTION_ASSOCIATIONS_NO_DIAGRAM'}},
        technologyCount,
        {bn: 'TEC', columnId: 0, order: 0, sectionBn: 'TEC', size: 'LARGE', configuration: {bn: 'TEC_ASSOC_CONFIG', contentBlockType: 'TECHNOLOGY_ASSOCIATIONS'}},
        standardCount,
        {bn: 'STA', columnId: 0, order: 0, sectionBn: 'STA', size: 'LARGE', configuration: {bn: 'STA_ASSOC_CONFIG', contentBlockType: 'STANDARD_ASSOCIATIONS'}},
        skillCount,
        {bn: 'SKI', columnId: 0, order: 0, sectionBn: 'SKI', size: 'LARGE', configuration: {bn: 'SKI_ASSOC_CONFIG', contentBlockType: 'SKILL_ASSOCIATIONS'}},
        physicalCount,
        {bn: 'PHY', columnId: 0, order: 0, sectionBn: 'PHY', size: 'LARGE', configuration: {bn: 'PHY_ASSOC_CONFIG', contentBlockType: 'PHYSICAL_ASSOCIATIONS'}},
        tagCount,
        {bn: 'TAG', columnId: 0, order: 0, sectionBn: 'TAG', size: 'LARGE', configuration: {bn: 'TAG_ASSOC_CONFIG', contentBlockType: 'TAG_ASSOCIATIONS'}},
        //
        documentAssociationByEntity,
        discussionAssociation,
        activityAssociation,
        {bn: 'CF_FS_1', columnId: 0, order: 1, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'CONFIG_1A', contentBlockType: 'FIELDSET', locationId: '0017000000N3'}},
        // TODO
        {bn: 'CF_FS_2', columnId: 0, order: 1, sectionBn: 'MEMBERS', size: 'LARGE', configuration: {bn: 'CONFIG_2A', contentBlockType: 'FIELDSET', locationId: '0017000000N4'}},
        {bn: 'CF_FS_3', columnId: 0, order: 1, sectionBn: 'STR', size: 'LARGE', configuration: {bn: 'CONFIG_3A', contentBlockType: 'FIELDSET', locationId: '0017000000N5'}},
        {bn: 'CF_FS_4', columnId: 0, order: 1, sectionBn: 'DEM', size: 'LARGE', configuration: {bn: 'CONFIG_4A', contentBlockType: 'FIELDSET', locationId: '0017000000N6'}},
        {bn: 'CF_FS_5', columnId: 0, order: 1, sectionBn: 'ORG', size: 'LARGE', configuration: {bn: 'CONFIG_5A', contentBlockType: 'FIELDSET', locationId: '0017000000N7'}},
        {bn: 'CF_FS_6', columnId: 0, order: 1, sectionBn: 'COM', size: 'LARGE', configuration: {bn: 'CONFIG_6A', contentBlockType: 'FIELDSET', locationId: '0017000000N8'}},
        {bn: 'CF_FS_7', columnId: 0, order: 1, sectionBn: 'ACT', size: 'LARGE', configuration: {bn: 'CONFIG_7A', contentBlockType: 'FIELDSET', locationId: '0017000000N9'}},
        {bn: 'CF_FS_8', columnId: 0, order: 1, sectionBn: 'CAP', size: 'LARGE', configuration: {bn: 'CONFIG_8A', contentBlockType: 'FIELDSET', locationId: '0017000000NA'}},
        {bn: 'CF_FS_9', columnId: 0, order: 1, sectionBn: 'PRD', size: 'LARGE', configuration: {bn: 'CONFIG_9A', contentBlockType: 'FIELDSET', locationId: '0017000000NB'}},
        {bn: 'CF_FS_10', columnId: 0, order: 1, sectionBn: 'MKT', size: 'LARGE', configuration: {bn: 'CONFIG_10A', contentBlockType: 'FIELDSET', locationId: '0017000000NC'}},
        {bn: 'CF_FS_11', columnId: 0, order: 1, sectionBn: 'DAT', size: 'LARGE', configuration: {bn: 'CONFIG_11A', contentBlockType: 'FIELDSET', locationId: '0017000000ND'}},
        {bn: 'CF_FS_12', columnId: 0, order: 1, sectionBn: 'SYS', size: 'LARGE', configuration: {bn: 'CONFIG_12A', contentBlockType: 'FIELDSET', locationId: '0017000000NE'}},
        {bn: 'CF_FS_13', columnId: 0, order: 1, sectionBn: 'CON', size: 'LARGE', configuration: {bn: 'CONFIG_13A', contentBlockType: 'FIELDSET', locationId: '0017000000NF'}},
        {bn: 'CF_FS_14', columnId: 0, order: 1, sectionBn: 'TEC', size: 'LARGE', configuration: {bn: 'CONFIG_14A', contentBlockType: 'FIELDSET', locationId: '0017000000NG'}},
        {bn: 'CF_FS_15', columnId: 0, order: 1, sectionBn: 'STA', size: 'LARGE', configuration: {bn: 'CONFIG_15A', contentBlockType: 'FIELDSET', locationId: '0017000000NH'}},
        {bn: 'CF_FS_16', columnId: 0, order: 1, sectionBn: 'SKI', size: 'LARGE', configuration: {bn: 'CONFIG_16A', contentBlockType: 'FIELDSET', locationId: '0017000000NI'}},
        {bn: 'CF_FS_17', columnId: 0, order: 1, sectionBn: 'PHY', size: 'LARGE', configuration: {bn: 'CONFIG_17A', contentBlockType: 'FIELDSET', locationId: '0017000000NJ'}},
        {bn: 'CF_FS_18', columnId: 0, order: 1, sectionBn: 'AST', size: 'LARGE', configuration: {bn: 'CONFIG_18A', contentBlockType: 'FIELDSET', locationId: '0017000000NK'}}
    ],
    sections: [
        {bn: 'BASIC', name: 'Overview', type: 'WorkspaceOverview', order: 0, category: 'primary'},
        {bn: 'CAY', name: 'Collection News', type: 'WorkspaceNews', order: 2, category: 'primary', assocCode: 'CAY'},
        {bn: 'MEMBERS', name: 'Collection Members', type: 'People', order: 3, category: 'related'},
        {bn: 'AST', name: 'Related Documents', type: 'Documents', order: 21, category: 'related', assocCode: 'AST'},
        {bn: 'TOP', name: 'Related Discussions', type: 'Discussions', order: 22, category: 'related', assocCode: 'TOP'},
        {bn: 'EAY', name: 'Activity', type: 'Activity', order: 23, category: 'tertiary', assocCode: 'EAY'}
]};

// Worksheet2 Layout
BnCodeToLayout['4M'] = {bn: '4M',
  countAssocs: sideBarConfigs['4M'].single,
  contentBlocks: [
    {bn: 'NEW_CB1', columnId: 0, order: 0, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'WORKSHEET2_BASIC_INFO'}},
    {bn: 'WAY', columnId: 0, order: 2, sectionBn: 'WAY', size: 'LARGE', configuration: {bn: 'WAY_ASSOC_CONFIG', contentBlockType: 'ACTIVITY_ASSOCIATIONS'}}, //circleNews
    {bn: 'NEW_CB4', columnId: 0, order: 3, sectionBn: 'MEMBERS', size: 'LARGE', configuration: {bn: 'NEW_CONFIG4', contentBlockType: 'WORKSHEET2_MEMBERS'}},
    strategyCount,
    {bn: 'STR', columnId: 0, order: 4, sectionBn: 'STR', size: 'LARGE', configuration: {bn: 'STR', contentBlockType: 'STRATEGY_ASSOCIATIONS'}},
    demandCount,
    {bn: 'DEM', columnId: 0, order: 5, sectionBn: 'DEM', size: 'LARGE', configuration: {bn: 'DEM', contentBlockType: 'DEMAND_ASSOCIATIONS'}},
    orgCount,
    {bn: 'ORG', columnId: 0, order: 6, sectionBn: 'ORG', size: 'LARGE', configuration: {bn: 'ORG', contentBlockType: 'ORGANIZATION_ASSOCIATIONS'}},
    companyCount,
    {bn: 'COM', columnId: 0, order: 8, sectionBn: 'COM', size: 'LARGE', configuration: {bn: 'COM', contentBlockType: 'COMPANY_ASSOCIATIONS'}},
    actorCount,
    {bn: 'ACT', columnId: 0, order: 9, sectionBn: 'ACT', size: 'LARGE', configuration: {bn: 'ACT', contentBlockType: 'ACTOR_ASSOCIATIONS'}},
    capabilityCount,
    {bn: 'CAP', columnId: 0, order: 10, sectionBn: 'CAP', size: 'LARGE', configuration: {bn: 'CAP', contentBlockType: 'CAPABILITY_ASSOCIATIONS'}},
    productCount,
    {bn: 'PRD', columnId: 0, order: 11, sectionBn: 'PRD', size: 'LARGE', configuration: {bn: 'PRD', contentBlockType: 'PRODUCT_ASSOCIATIONS'}},
    marketCount,
    {bn: 'MKT', columnId: 0, order: 12, sectionBn: 'MKT', size: 'LARGE', configuration: {bn: 'MKT', contentBlockType: 'MARKET_ASSOCIATIONS'}},
    dataCount,
    {bn: 'DAT', columnId: 0, order: 13, sectionBn: 'DAT', size: 'LARGE', configuration: {bn: 'DAT', contentBlockType: 'DATA_ASSOCIATIONS'}},
    systemCount,
    {bn: 'SYS', columnId: 0, order: 14, sectionBn: 'SYS', size: 'LARGE', configuration: {bn: 'SYS', contentBlockType: 'SYSTEM_ASSOCIATIONS'}},
    connectionCount,
    {bn: 'CON', columnId: 0, order: 15, sectionBn: 'CON', size: 'LARGE', configuration: {bn: 'CON', contentBlockType: 'CONNECTION_ASSOCIATIONS_NO_DIAGRAM'}},
    technologyCount,
    {bn: 'TEC', columnId: 0, order: 16, sectionBn: 'TEC', size: 'LARGE', configuration: {bn: 'TEC', contentBlockType: 'TECHNOLOGY_ASSOCIATIONS'}},
    standardCount,
    {bn: 'STA', columnId: 0, order: 17, sectionBn: 'STA', size: 'LARGE', configuration: {bn: 'STA', contentBlockType: 'STANDARD_ASSOCIATIONS'}},
    skillCount,
    {bn: 'SKI', columnId: 0, order: 18, sectionBn: 'SKI', size: 'LARGE', configuration: {bn: 'SKI', contentBlockType: 'SKILL_ASSOCIATIONS'}},
    physicalCount,
    {bn: 'PHY', columnId: 0, order: 19, sectionBn: 'PHY', size: 'LARGE', configuration: {bn: 'PHY', contentBlockType: 'PHYSICAL_ASSOCIATIONS'}},
    tagCount,
    {bn: 'TAG', columnId: 0, order: 20, sectionBn: 'TAG', size: 'LARGE', configuration: {bn: 'TAG', contentBlockType: 'TAG_ASSOCIATIONS'}},
    documentAssociationByEntity,
    discussionAssociation,
    activityAssociation
  ],
  sections: [
    {bn: 'BASIC', name: 'Overview', type: 'Worksheet2Overview', order: 0, category: 'primary'},
    {bn: 'WAY', name: 'Worksheet News', type: 'WorksheetNews', order: 2, category: 'primary', assocCode: 'WAY'},
    {bn: 'MEMBERS', name: 'Worksheet Members', type: 'People', order: 3, category: 'related'},
    {bn: 'AST', name: 'Related Documents', type: 'Documents', order: 21, category: 'related', assocCode: 'AST'},
    {bn: 'TOP', name: 'Related Discussions', type: 'Discussions', order: 22, category: 'related', assocCode: 'TOP'},
    {bn: 'EAY', name: 'Activity', type: 'Activity', order: 23, category: 'tertiary', assocCode: 'EAY'}
  ]};

///////////////////////////////////////////////
//        Default Summary Layouts           //
//////////////////////////////////////////////

var EntityTypeCodeToDefaultSummaryLayout = {};

EntityTypeCodeToDefaultSummaryLayout['ACT'] = {bn: 'DEFAULT', tenantDefault: true, // All default summary layouts must have a bn of 'DEFAULT'
    entityType: 'ACT', name: 'Actor Summary',
    contentBlocks: [
        {bn: '1', columnId: 0, configuration: {bn: '1', columnSpecBn: null, contentBlockType: 'PIE', description: null, fieldBn: '0446000000LQ', groupPropertyBn: null, limit: 10, measurement: 'COUNT', name: 'Lifecycle State', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '1', size: 'MEDIUM'},
        {bn: '2', columnId: 0, configuration: {bn: '2', columnSpecBn: null, contentBlockType: 'PIE', description: null, fieldBn: '0446000000JT', groupPropertyBn: null, limit: 5, measurement: 'COUNT', name: 'Content Status', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 1, sectionBn: '1', size: 'MEDIUM'},
        {bn: '3', columnId: 1, configuration: {bn: '3', columnSpecBn: null, contentBlockType: 'METRIC', description: null, fieldBn: null, groupPropertyBn: null, limit: 5, measurement: 'COUNT', name: 'Actors', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '1', size: 'MEDIUM'},
        {bn: '4', columnId: 0, configuration: {bn: '4', columnSpecBn: null, contentBlockType: 'TABLE', description: null, fieldBn: null, groupPropertyBn: null, limit: 25, measurement: 'COUNT', name: null, 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '2', size: 'LARGE'}
    ],
    sections: [
        {bn: '1', layoutSectionType: 'TWOTHIRD_THIRD', name: '', order: 0},
        {bn: '2', layoutSectionType: 'WHOLE', name: '', order: 1}
    ]};

EntityTypeCodeToDefaultSummaryLayout['AST'] = {bn: 'DEFAULT', tenantDefault: true,
    entityType: 'AST', name: 'Documents Summary',
    contentBlocks: [
        {bn: '1', columnId: 1, configuration: {bn: '1', columnSpecBn: null, contentBlockType: 'METRIC', description: null, fieldBn: null, groupPropertyBn: null, limit: 5, measurement: 'COUNT', name: 'Documents', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '1', size: 'MEDIUM'},
        {bn: '2', columnId: 0, configuration: {bn: '2', columnSpecBn: null, contentBlockType: 'TABLE', description: null, fieldBn: null, groupPropertyBn: null, limit: 25, measurement: 'COUNT', name: null, 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '2', size: 'LARGE'}
    ],
    sections: [
        {bn: '1', layoutSectionType: 'TWOTHIRD_THIRD', name: 'Overview', order: 0},
        {bn: '2', layoutSectionType: 'WHOLE', name: 'Documents', order: 1}
    ]
};

EntityTypeCodeToDefaultSummaryLayout['SYS'] = {bn: 'DEFAULT', tenantDefault: true,
    entityType: 'SYS', name: 'System Summary',
    contentBlocks: [
        {bn: '044I00000059', columnId: 0, configuration: {bn: '044K00000059', columnSpecBn: null, contentBlockType: 'PIE', description: null, fieldBn: '0446000000L5', groupPropertyBn: null, limit: 3, measurement: 'COUNT', name: 'Core Systems', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '041X00000042', size: 'MEDIUM'},
        {bn: '044I0000005B', columnId: 0, configuration: {bn: '044K0000005B', columnSpecBn: null, contentBlockType: 'BAR', description: null, fieldBn: '0446000000LF', groupPropertyBn: null, limit: 10, measurement: 'COUNT', name: 'System Priority', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 1, sectionBn: '041X00000042', size: 'MEDIUM'},
        {bn: '044I0000005D', columnId: 1, configuration: {bn: '044K0000005D', columnSpecBn: null, contentBlockType: 'METRIC', description: null, fieldBn: null, groupPropertyBn: null, limit: 5, measurement: 'COUNT', name: 'Systems', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '041X00000042', size: 'MEDIUM'},
        {bn: '044I0000005K', columnId: 0, configuration: {bn: '044K0000005K', columnSpecBn: null, contentBlockType: 'TABLE', description: null, fieldBn: null, groupPropertyBn: null, limit: 25, measurement: 'COUNT', name: null, 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '041X00000043', size: 'LARGE'}
    ],
    sections: [
        {bn: '041X00000042', layoutSectionType: 'TWOTHIRD_THIRD', name: 'Overview', order: 0},
        {bn: '041X00000043', layoutSectionType: 'WHOLE', name: 'Systems', order: 1}
    ]
};

EntityTypeCodeToDefaultSummaryLayout['CAP'] = {bn: 'DEFAULT', tenantDefault: true,
    entityType: 'CAP', name: 'Capability Summary',
    contentBlocks: [
        {bn: '044I0000003J', columnId: 0, configuration: {bn: '044K0000003J', columnSpecBn: null, contentBlockType: 'PIE', description: null, fieldBn: '0446000000K1', groupPropertyBn: null, limit: 10, measurement: 'COUNT', name: 'Enterprise Approval', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '041X0000003D', size: 'MEDIUM'},
        {bn: '044I0000003K', columnId: 0, configuration: {bn: '044K0000003K', columnSpecBn: null, contentBlockType: 'PIE', description: null, fieldBn: '0446000000MK', groupPropertyBn: null, limit: 10, measurement: 'COUNT', name: 'Lifecycle State', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 1, sectionBn: '041X0000003D', size: 'MEDIUM'},
        {bn: '044I0000003M', columnId: 1, configuration: {bn: '044K0000003M', columnSpecBn: null, contentBlockType: 'METRIC', description: null, fieldBn: null, groupPropertyBn: null, limit: 5, measurement: 'COUNT', name: 'Capabilities', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '041X0000003D', size: 'LARGE'},
        {bn: '044I0000003P', columnId: 0, configuration: {bn: '044K0000003P', columnSpecBn: null, contentBlockType: 'TABLE', description: null, fieldBn: null, groupPropertyBn: null, limit: 25, measurement: 'COUNT', name: null, 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '041X0000003F', size: 'LARGE'}
    ],
    sections: [
        {bn: '041X0000003D', layoutSectionType: 'TWOTHIRD_THIRD', name: 'Overview', order: 0},
        {bn: '041X0000003F', layoutSectionType: 'WHOLE', name: 'Capabilities', order: 1}
    ]
};

EntityTypeCodeToDefaultSummaryLayout['COM'] = {bn: 'DEFAULT', tenantDefault: true,
    entityType: 'COM', name: 'Company Summary',
    contentBlocks: [
        {bn: '044I0000003Q', columnId: 0, configuration: {bn: '044K0000003Q', columnSpecBn: null, contentBlockType: 'PIE', description: null, fieldBn: '0446000000MY', groupPropertyBn: null, limit: 5, measurement: 'COUNT', name: 'Content Status', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '041X0000003G', size: 'MEDIUM'},
        {bn: '044I0000003R', columnId: 1, configuration: {bn: '044K0000003R', columnSpecBn: null, contentBlockType: 'METRIC', description: null, fieldBn: null, groupPropertyBn: null, limit: 5, measurement: 'COUNT', name: 'Companies', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '041X0000003G', size: 'MEDIUM'},
        {bn: '044I0000003S', columnId: 0, configuration: {bn: '044K0000003S', columnSpecBn: null, contentBlockType: 'TABLE', description: null, fieldBn: null, groupPropertyBn: null, limit: 25, measurement: 'COUNT', name: null, 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '041X0000003H', size: 'LARGE'}
    ],
    sections: [
        {bn: '041X0000003G', layoutSectionType: 'TWOTHIRD_THIRD', name: 'Overview', order: 0},
        {bn: '041X0000003H', layoutSectionType: 'WHOLE', name: 'Companies', order: 1}
    ]
};

EntityTypeCodeToDefaultSummaryLayout['CON'] = {bn: 'DEFAULT', tenantDefault: true,
    entityType: 'CON', name: 'Connection Summary',
    contentBlocks: [
        {bn: '044I0000003T', columnId: 0, configuration: {bn: '044K0000003T', columnSpecBn: null, contentBlockType: 'PIE', description: null, fieldBn: '0446000000KK', groupPropertyBn: null, limit: 5, measurement: 'COUNT', name: 'Content Status', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '041X0000003J', size: 'MEDIUM'},
        {bn: '044I0000003V', columnId: 0, configuration: {bn: '044K0000003V', columnSpecBn: null, contentBlockType: 'PIE', description: null, fieldBn: '0446000000KC', groupPropertyBn: null, limit: 10, measurement: 'COUNT', name: 'Types', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 1, sectionBn: '041X0000003J', size: 'MEDIUM'},
        {bn: '044I0000003W', columnId: 0, configuration: {bn: '044K0000003W', columnSpecBn: null, contentBlockType: 'PIE', description: null, fieldBn: '0446000000KD', groupPropertyBn: null, limit: 10, measurement: 'COUNT', name: 'Patterns', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 2, sectionBn: '041X0000003J', size: 'MEDIUM'},
        {bn: '044I0000003X', columnId: 0, configuration: {bn: '044K0000003X', columnSpecBn: null, contentBlockType: 'PIE', description: null, fieldBn: '0446000000KB', groupPropertyBn: null, limit: 10, measurement: 'COUNT', name: 'Transports', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 3, sectionBn: '041X0000003J', size: 'MEDIUM'},
        {bn: '044I0000003Z', columnId: 1, configuration: {bn: '044K0000003Z', columnSpecBn: null, contentBlockType: 'METRIC', description: null, fieldBn: null, groupPropertyBn: null, limit: 5, measurement: 'COUNT', name: 'Connections', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '041X0000003J', size: 'MEDIUM'},
        {bn: '044I00000040', columnId: 1, configuration: {bn: '044K00000040', columnSpecBn: null, contentBlockType: 'METRIC', description: null, fieldBn: '0446000000KC', groupPropertyBn: null, limit: 5, measurement: 'COUNT', name: 'Types', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 1, sectionBn: '041X0000003J', size: 'MEDIUM'},
        {bn: '044I00000041', columnId: 1, configuration: {bn: '044K00000041', columnSpecBn: null, contentBlockType: 'METRIC', description: null, fieldBn: '0446000000KD', groupPropertyBn: null, limit: 5, measurement: 'COUNT', name: 'Patterns', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 2, sectionBn: '041X0000003J', size: 'MEDIUM'},
        {bn: '044I00000042', columnId: 1, configuration: {bn: '044K00000042', columnSpecBn: null, contentBlockType: 'METRIC', description: null, fieldBn: '0446000000KB', groupPropertyBn: null, limit: 5, measurement: 'COUNT', name: 'Transports', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 3, sectionBn: '041X0000003J', size: 'MEDIUM'},
        {bn: '044I00000044', columnId: 0, configuration: {bn: '044K00000044', columnSpecBn: null, contentBlockType: 'TABLE', description: null, fieldBn: null, groupPropertyBn: null, limit: 25, measurement: 'COUNT', name: null, 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '041X0000003K', size: 'LARGE'}
    ],
    sections: [
        {bn: '041X0000003J', layoutSectionType: 'TWOTHIRD_THIRD', name: 'Overview', order: 0},
        {bn: '041X0000003K', layoutSectionType: 'WHOLE', name: 'Connections', order: 1}
    ]
};

EntityTypeCodeToDefaultSummaryLayout['DAT'] = {bn: 'DEFAULT', tenantDefault: true,
    entityType: 'DAT', name: 'Data Summary',
    contentBlocks: [
        {bn: '044I00000045', columnId: 0, configuration: {bn: '044K00000045', columnSpecBn: null, contentBlockType: 'PIE', description: null, fieldBn: '0446000000KQ', groupPropertyBn: null, limit: 10, measurement: 'COUNT', name: 'Enterprise Approval', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '041X0000003L', size: 'MEDIUM'},
        {bn: '044I00000046', columnId: 0, configuration: {bn: '044K00000046', columnSpecBn: null, contentBlockType: 'PIE', description: null, fieldBn: '0446000000NM', groupPropertyBn: null, limit: 10, measurement: 'COUNT', name: 'Data Lifecycle State', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 1, sectionBn: '041X0000003L', size: 'MEDIUM'},
        {bn: '044I00000048', columnId: 1, configuration: {bn: '044K00000048', columnSpecBn: null, contentBlockType: 'METRIC', description: null, fieldBn: null, groupPropertyBn: null, limit: 5, measurement: 'COUNT', name: 'Data', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '041X0000003L', size: 'LARGE'},
        {bn: '044I0000004B', columnId: 0, configuration: {bn: '044K0000004B', columnSpecBn: null, contentBlockType: 'TABLE', description: null, fieldBn: null, groupPropertyBn: null, limit: 25, measurement: 'COUNT', name: null, 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '041X0000003M', size: 'LARGE'}
    ],
    sections: [
        {bn: '041X0000003L', layoutSectionType: 'TWOTHIRD_THIRD', name: 'Overview', order: 0},
        {bn: '041X0000003M', layoutSectionType: 'WHOLE', name: 'Data', order: 1}
    ]
};

EntityTypeCodeToDefaultSummaryLayout['DEM'] = {bn: 'DEFAULT', tenantDefault: true,
    entityType: 'DEM', name: 'Demand Summary',
    contentBlocks: [
        {bn: '1', columnId: 1, configuration: {bn: '1', columnSpecBn: null, contentBlockType: 'METRIC', description: null, fieldBn: null, groupPropertyBn: null, limit: 5, measurement: 'COUNT', name: 'Demands', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '1', size: 'MEDIUM'},
        {bn: '2', columnId: 0, configuration: {bn: '2', columnSpecBn: null, contentBlockType: 'TABLE', description: null, fieldBn: null, groupPropertyBn: null, limit: 25, measurement: 'COUNT', name: null, 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '2', size: 'LARGE'}
    ],
    sections: [
        {bn: '1', layoutSectionType: 'TWOTHIRD_THIRD', name: 'Overview', order: 0},
        {bn: '2', layoutSectionType: 'WHOLE', name: 'Demands', order: 1}
    ]
};

EntityTypeCodeToDefaultSummaryLayout['MKT'] = {bn: 'DEFAULT', tenantDefault: true,
    entityType: 'MKT', name: 'Market Summary',
    contentBlocks: [
        {bn: '1', columnId: 1, configuration: {bn: '1', columnSpecBn: null, contentBlockType: 'METRIC', description: null, fieldBn: null, groupPropertyBn: null, limit: 5, measurement: 'COUNT', name: 'Markets', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '1', size: 'MEDIUM'},
        {bn: '2', columnId: 0, configuration: {bn: '2', columnSpecBn: null, contentBlockType: 'TABLE', description: null, fieldBn: null, groupPropertyBn: null, limit: 25, measurement: 'COUNT', name: null, 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '2', size: 'LARGE'}
    ],
    sections: [
        {bn: '1', layoutSectionType: 'TWOTHIRD_THIRD', name: 'Overview', order: 0},
        {bn: '2', layoutSectionType: 'WHOLE', name: 'Markets', order: 1}
    ]
};

EntityTypeCodeToDefaultSummaryLayout['ORG'] = {bn: 'DEFAULT', tenantDefault: true,
    entityType: 'ORG', name: 'Organization Summary',
    contentBlocks: [
        {bn: '044I0000004C', columnId: 1, configuration: {bn: '044K0000004C', columnSpecBn: null, contentBlockType: 'METRIC', description: null, fieldBn: null, groupPropertyBn: null, limit: 5, measurement: 'COUNT', name: 'Organizations', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '041X0000003N', size: 'MEDIUM'},
        {bn: '044I0000004D', columnId: 0, configuration: {bn: '044K0000004D', columnSpecBn: null, contentBlockType: 'TABLE', description: null, fieldBn: null, groupPropertyBn: null, limit: 25, measurement: 'COUNT', name: null, 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '041X0000003P', size: 'LARGE'}

    ],
    sections: [
        {bn: '041X0000003N', layoutSectionType: 'TWOTHIRD_THIRD', name: 'Overview', order: 0},
        {bn: '041X0000003P', layoutSectionType: 'WHOLE', name: 'Organizations', order: 1}
    ]
};

EntityTypeCodeToDefaultSummaryLayout['PER'] = {bn: 'DEFAULT', tenantDefault: true,
    entityType: 'PER', name: 'People Summary',
    contentBlocks: [
        {bn: '044I0000004F', columnId: 0, configuration: {bn: '044K0000004F', columnSpecBn: null, contentBlockType: 'PIE', description: null, fieldBn: '0446000000RB', groupPropertyBn: null, limit: 5, measurement: 'COUNT', name: 'Profile Status', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '041X0000003Q', size: 'LARGE'},
        {bn: '044I0000004H', columnId: 1, configuration: {bn: '044K0000004H', columnSpecBn: null, contentBlockType: 'METRIC', description: null, fieldBn: null, groupPropertyBn: null, limit: 5, measurement: 'COUNT', name: 'People', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '041X0000003Q', size: 'LARGE'},
        {bn: '044I0000004K', columnId: 0, configuration: {bn: '044K0000004K', columnSpecBn: null, contentBlockType: 'TABLE', description: null, fieldBn: null, groupPropertyBn: null, limit: 25, measurement: 'COUNT', name: null, 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '041X0000003R', size: 'LARGE'}
    ],
    sections: [
        {bn: '041X0000003Q', layoutSectionType: 'TWOTHIRD_THIRD', name: 'Overview', order: 0},
        {bn: '041X0000003R', layoutSectionType: 'WHOLE', name: 'People', order: 1}
    ]
};

EntityTypeCodeToDefaultSummaryLayout['PHY'] = {bn: 'DEFAULT', tenantDefault: true,
    entityType: 'PHY', name: 'Deployment Summary',
    contentBlocks: [
        {bn: '044I00000060', columnId: 0, configuration: {bn: '044K00000060', columnSpecBn: null, contentBlockType: 'PIE', description: null, fieldBn: '0446000001FR', groupPropertyBn: null, limit: 5, measurement: 'COUNT', name: 'Content Status', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '041X0000006S', size: 'MEDIUM'},
        {bn: '044I00000061', columnId: 0, configuration: {bn: '044K00000061', columnSpecBn: null, contentBlockType: 'PIE', description: null, fieldBn: '0446000001CN', groupPropertyBn: null, limit: 10, measurement: 'COUNT', name: 'Lifecycle State', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 1, sectionBn: '041X0000006S', size: 'MEDIUM'},
        {bn: '044I00000062', columnId: 1, configuration: {bn: '044K00000062', columnSpecBn: null, contentBlockType: 'METRIC', description: null, fieldBn: null, groupPropertyBn: null, limit: 5, measurement: 'COUNT', name: 'Deployments', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '041X0000006S', size: 'MEDIUM'},
        {bn: '044I00000063', columnId: 0, configuration: {bn: '044K00000063', columnSpecBn: null, contentBlockType: 'TABLE', description: null, fieldBn: null, groupPropertyBn: null, limit: 25, measurement: 'COUNT', name: null, 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '041X0000006T', size: 'LARGE'}
    ],
    sections: [
        {bn: '041X0000006S', layoutSectionType: 'TWOTHIRD_THIRD', name: 'Overview', order: 0},
        {bn: '041X0000006T', layoutSectionType: 'WHOLE', name: 'Deployments', order: 1}
    ]
};

EntityTypeCodeToDefaultSummaryLayout['PRD'] = {bn: 'DEFAULT', tenantDefault: true,
    entityType: 'PRD', name: 'Product Summary',
    contentBlocks: [
        {bn: '044I0000004L', columnId: 0, configuration: {bn: '044K0000004L', columnSpecBn: null, contentBlockType: 'PIE', description: null, fieldBn: '0446000000L0', groupPropertyBn: null, limit: 5, measurement: 'COUNT', name: 'Content Status', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '041X0000003S', size: 'MEDIUM'},
        {bn: '044I0000004M', columnId: 0, configuration: {bn: '044K0000004M', columnSpecBn: null, contentBlockType: 'PIE', description: null, fieldBn: '0446000000OF', groupPropertyBn: null, limit: 10, measurement: 'COUNT', name: 'Lifecycle State', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 1, sectionBn: '041X0000003S', size: 'MEDIUM'},
        {bn: '044I0000004P', columnId: 1, configuration: {bn: '044K0000004P', columnSpecBn: null, contentBlockType: 'METRIC', description: null, fieldBn: null, groupPropertyBn: null, limit: 5, measurement: 'COUNT', name: 'Products', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '041X0000003S', size: 'MEDIUM'},
        {bn: '044I0000004V', columnId: 0, configuration: {bn: '044K0000004V', columnSpecBn: null, contentBlockType: 'TABLE', description: null, fieldBn: null, groupPropertyBn: null, limit: 25, measurement: 'COUNT', name: null, 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '041X0000003T', size: 'LARGE'}
    ],
    sections: [
        {bn: '041X0000003S', layoutSectionType: 'TWOTHIRD_THIRD', name: 'Overview', order: 0},
        {bn: '041X0000003T', layoutSectionType: 'WHOLE', name: 'Products', order: 1}
    ]
};

EntityTypeCodeToDefaultSummaryLayout['PRJ'] = {bn: 'DEFAULT', tenantDefault: true,
    entityType: 'PRJ', name: 'Project Summary',
    contentBlocks: [
        {bn: '044I0000004W', columnId: 0, configuration: {bn: '044K0000004W', columnSpecBn: null, contentBlockType: 'PIE', description: null, fieldBn: '0446000000PW', groupPropertyBn: null, limit: 5, measurement: 'COUNT', name: 'Budget Status', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '041X0000003V', size: 'MEDIUM'},
        {bn: '044I0000004X', columnId: 0, configuration: {bn: '044K0000004X', columnSpecBn: null, contentBlockType: 'PIE', description: null, fieldBn: '0446000000PZ', groupPropertyBn: null, limit: 10, measurement: 'COUNT', name: 'Schedule Status', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 1, sectionBn: '041X0000003V', size: 'MEDIUM'},
        {bn: '044I0000004Z', columnId: 0, configuration: {bn: '044K0000004Z', columnSpecBn: null, contentBlockType: 'PIE', description: null, fieldBn: '0446000000PX', groupPropertyBn: null, limit: 10, measurement: 'COUNT', name: 'Priority', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 2, sectionBn: '041X0000003V', size: 'MEDIUM'},
        {bn: '044I00000050', columnId: 0, configuration: {bn: '044K00000050', columnSpecBn: null, contentBlockType: 'PIE', description: null, fieldBn: '0446000000PY', groupPropertyBn: null, limit: 10, measurement: 'COUNT', name: 'Stage', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 2, sectionBn: '041X0000003V', size: 'MEDIUM'},
        {bn: '044I00000051', columnId: 1, configuration: {bn: '044K00000051', columnSpecBn: null, contentBlockType: 'METRIC', description: null, fieldBn: null, groupPropertyBn: null, limit: 5, measurement: 'COUNT', name: 'Projects', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '041X0000003V', size: 'MEDIUM'},
        {bn: '044I00000052', columnId: 0, configuration: {bn: '044K00000052', columnSpecBn: null, contentBlockType: 'TABLE', description: null, fieldBn: null, groupPropertyBn: null, limit: 25, measurement: 'COUNT', name: null, 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '041X0000003W', size: 'LARGE'}
    ],
    sections: [
        {bn: '041X0000003V', layoutSectionType: 'TWOTHIRD_THIRD', name: 'Overview', order: 0},
        {bn: '041X0000003W', layoutSectionType: 'WHOLE', name: 'Projects', order: 1}
    ]
};

EntityTypeCodeToDefaultSummaryLayout['SKI'] = {bn: 'DEFAULT', tenantDefault: true,
    entityType: 'SKI', name: 'Skill Summary',
    contentBlocks: [
        {bn: '044I00000053', columnId: 0, configuration: {bn: '044K00000053', columnSpecBn: null, contentBlockType: 'PIE', description: null, fieldBn: '0446000000OM', groupPropertyBn: null, limit: 5, measurement: 'COUNT', name: 'Content Status', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '041X0000003X', size: 'MEDIUM'},
        {bn: '044I00000054', columnId: 1, configuration: {bn: '044K00000054', columnSpecBn: null, contentBlockType: 'METRIC', description: null, fieldBn: null, groupPropertyBn: null, limit: 5, measurement: 'COUNT', name: 'Skills', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '041X0000003X', size: 'MEDIUM'},
        {bn: '044I00000055', columnId: 0, configuration: {bn: '044K00000055', columnSpecBn: null, contentBlockType: 'TABLE', description: null, fieldBn: null, groupPropertyBn: null, limit: 25, measurement: 'COUNT', name: null, 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '041X0000003Z', size: 'LARGE'}
    ],
    sections: [
        {bn: '041X0000003X', layoutSectionType: 'TWOTHIRD_THIRD', name: 'Overview', order: 0},
        {bn: '041X0000003Z', layoutSectionType: 'WHOLE', name: 'Skills', order: 1}
    ]
};

EntityTypeCodeToDefaultSummaryLayout['STA'] = {bn: 'DEFAULT', tenantDefault: true,
    entityType: 'STA', name: 'Standard Summary',
    contentBlocks: [
        {bn: '044I00000056', columnId: 0, configuration: {bn: '044K00000056', columnSpecBn: null, contentBlockType: 'PIE', description: null, fieldBn: '0446000000OU', groupPropertyBn: null, limit: 5, measurement: 'COUNT', name: 'Content Status', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '041X00000040', size: 'MEDIUM'},
        {bn: '044I00000057', columnId: 1, configuration: {bn: '044K00000057', columnSpecBn: null, contentBlockType: 'METRIC', description: null, fieldBn: null, groupPropertyBn: null, limit: 5, measurement: 'COUNT', name: 'Standards', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '041X00000040', size: 'MEDIUM'},
        {bn: '044I00000058', columnId: 0, configuration: {bn: '044K00000058', columnSpecBn: null, contentBlockType: 'TABLE', description: null, fieldBn: null, groupPropertyBn: null, limit: 25, measurement: 'COUNT', name: null, 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '041X00000041', size: 'LARGE'}
    ],
    sections: [
        {bn: '041X00000040', layoutSectionType: 'TWOTHIRD_THIRD', name: 'Overview', order: 0},
        {bn: '041X00000041', layoutSectionType: 'WHOLE', name: 'Standards', order: 1}
    ]
};

EntityTypeCodeToDefaultSummaryLayout['STR'] = {bn: 'DEFAULT', tenantDefault: true,
    entityType: 'STR', name: 'Strategy Summary',
    contentBlocks: [
        {bn: '1', columnId: 1, configuration: {bn: '1', columnSpecBn: null, contentBlockType: 'METRIC', description: null, fieldBn: null, groupPropertyBn: null, limit: 5, measurement: 'COUNT', name: 'Strategies', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '1', size: 'MEDIUM'},
        {bn: '2', columnId: 0, configuration: {bn: '2', columnSpecBn: null, contentBlockType: 'TABLE', description: null, fieldBn: null, groupPropertyBn: null, limit: 25, measurement: 'COUNT', name: null, 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '2', size: 'LARGE'}
    ],
    sections: [
        {bn: '1', layoutSectionType: 'TWOTHIRD_THIRD', name: 'Overview', order: 0},
        {bn: '2', layoutSectionType: 'WHOLE', name: 'Strategies', order: 1}
    ]
};

EntityTypeCodeToDefaultSummaryLayout['TAG'] = {bn: 'DEFAULT', tenantDefault: true,
    entityType: 'TAG', name: 'Tag Summary',
    contentBlocks: [
        {bn: '044I0000005L', columnId: 1, configuration: {bn: '044K0000005L', columnSpecBn: null, contentBlockType: 'METRIC', description: null, fieldBn: null, groupPropertyBn: null, limit: 5, measurement: 'COUNT', name: 'Tags', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '041X00000044', size: 'MEDIUM'},
        {bn: '044I0000005M', columnId: 0, configuration: {bn: '044K0000005M', columnSpecBn: null, contentBlockType: 'TABLE', description: null, fieldBn: null, groupPropertyBn: null, limit: 25, measurement: 'COUNT', name: null, 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '041X00000045', size: 'LARGE'}
    ],
    sections: [
        {bn: '041X00000044', layoutSectionType: 'TWOTHIRD_THIRD', name: 'Overview', order: 0},
        {bn: '041X00000045', layoutSectionType: 'WHOLE', name: 'Tags', order: 1}
    ]
};

EntityTypeCodeToDefaultSummaryLayout['TEC'] = {bn: 'DEFAULT', tenantDefault: true,
    entityType: 'TEC', name: 'Technology Summary',
    contentBlocks: [
        {bn: '044I0000005N', columnId: 0, configuration: {bn: '044K0000005N', columnSpecBn: null, contentBlockType: 'PIE', description: null, fieldBn: '0446000000P5', groupPropertyBn: null, limit: 10, measurement: 'COUNT', name: 'Content Status', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '041X00000046', size: 'MEDIUM'},
        {bn: '044I0000005P', columnId: 0, configuration: {bn: '044K0000005P', columnSpecBn: null, contentBlockType: 'PIE', description: null, fieldBn: '0446000000TY', groupPropertyBn: null, limit: 10, measurement: 'COUNT', name: 'Lifecycle State', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 1, sectionBn: '041X00000046', size: 'MEDIUM'},
        {bn: '044I0000005Q', columnId: 1, configuration: {bn: '044K0000005Q', columnSpecBn: null, contentBlockType: 'METRIC', description: null, fieldBn: null, groupPropertyBn: null, limit: 0, measurement: 'COUNT', name: 'Technologies', 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '041X00000046', size: 'MEDIUM'},
        {bn: '044I0000005T', columnId: 0, configuration: {bn: '044K0000005T', columnSpecBn: null, contentBlockType: 'TABLE', description: null, fieldBn: null, groupPropertyBn: null, limit: 25, measurement: 'COUNT', name: null, 'pageSize': null, sortDescending: false, 'sortProperty': null}, order: 0, sectionBn: '041X00000047', size: 'LARGE'}
    ],
    sections: [
        {bn: '041X00000046', layoutSectionType: 'TWOTHIRD_THIRD', name: 'Overview', order: 0},
        {bn: '041X00000047', layoutSectionType: 'WHOLE', name: 'Technologies', order: 1}
    ]
};


//////////////////////////////////////////////////////////////////////////////
//     Layouts  driven by page name                                         //
//  - page name is pulled off the url                                       //
//  - single type collection pages use this map                             //
//  - pages outside the entity pattern, like audit, compliance use this map //
//////////////////////////////////////////////////////////////////////////////

var PageNameToLayout = {};
PageNameToLayout['systems'] = {bn: 'SYS', name: 'Systems',
    countAssocs: sideBarConfigs['18'].multi,
    contentBlocks: [
        // Here's an example of how to set the page size...
        {bn: '1', columnId: 0, order: 0, sectionBn: 'BROWSE', size: 'LARGE', configuration: {bn: '2', contentBlockType: 'SYSTEM_BROWSE', pageSize: 10, sortDescending: true, sortProperty: 'name'}},
        strategyAssociation,
        demandAssociation,
        orgAssociation,
        personAssociation,
        companyAssociation,
        actorAssociation,
        capabilityAssociation,
        productAssociation,
        marketAssociation,
        dataAssociation,
        connectionAssociationNoDiagram,
        technologyAssociation,
        standardAssociation,
        skillAssociation,
        physicalAssociation,
        tagAssociation,
        documentAssociation
    ],
    sections: [
        {bn: 'BROWSE', name: 'Overview', type: 'SystemBrowse', order: 0, category: 'primary', assocCode: 'SYS'},
        {bn: 'STR', name: 'Related Strategies', type: 'Strategies', order: 1, category: 'related', assocCode: 'STR', roles: 'ROLE_STRATEGY_MODULE_ACCESS'},
        {bn: 'DEM', name: 'Related Demands', type: 'Demands', order: 2, category: 'related', assocCode: 'DEM', roles: 'ROLE_DEMAND_MODULE_ACCESS'},
        {bn: 'ORG', name: 'Related Organizations', type: 'Organizations', order: 3, category: 'related', assocCode: 'ORG'},
        {bn: 'PER', name: 'Related People', type: 'People', order: 4, category: 'related', assocCode: 'PER'},
        {bn: 'COM', name: 'Related Companies', type: 'Companies', order: 5, category: 'related', assocCode: 'COM'},
        {bn: 'ACT', name: 'Related Actors', type: 'Actors', order: 6, category: 'related', assocCode: 'ACT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CAP', name: 'Related Capabilities', type: 'Capabilities', order: 7, category: 'related', assocCode: 'CAP', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'PRD', name: 'Related Products', type: 'Products', order: 8, category: 'related', assocCode: 'PRD', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'MKT', name: 'Related Markets', type: 'Markets', order: 9, category: 'related', assocCode: 'MKT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'DAT', name: 'Related Data', type: 'Data', order: 10, category: 'related', assocCode: 'DAT', roles: 'ROLE_DATA_MODULE_ACCESS'},
        {bn: 'CON', name: 'Related Connections', type: 'Connections', order: 11, category: 'related', assocCode: 'CON', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TEC', name: 'Related Technologies', type: 'Technologies', order: 12, category: 'related', assocCode: 'TEC'},
        {bn: 'STA', name: 'Related Standards', type: 'Standards', order: 13, category: 'related', assocCode: 'STA'},
        {bn: 'SKI', name: 'Related Skills', type: 'Skills', order: 14, category: 'related', assocCode: 'SKI'},
        {bn: 'PHY', name: 'Related Deployments', type: 'Physicals', order: 15, category: 'related', assocCode: 'PHY', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TAG', name: 'Related Tags', type: 'Tags', order: 16, category: 'related', assocCode: 'TAG'},
        {bn: 'AST', name: 'Related Documents', type: 'Documents', order: 17, category: 'related', assocCode: 'AST'}

    ]};

PageNameToLayout['actors'] = {bn: 'ACT', name: 'Actors',
    countAssocs: sideBarConfigs['01'].multi,
    contentBlocks: [
        {bn: '1', columnId: 0, order: 0, sectionBn: 'BROWSE', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'ACTOR_BROWSE'}},
        strategyAssociation,
        demandAssociation,
        orgAssociation,
        personAssociation,
        companyAssociation,
        capabilityAssociation,
        productAssociation,
        marketAssociation,
        dataAssociation,
        connectionAssociationNoDiagram,
        systemAssociation,
        technologyAssociation,
        standardAssociation,
        skillAssociation,
        physicalAssociation,
        tagAssociation,
        documentAssociation
    ],
    sections: [
        {bn: 'BROWSE', name: 'Overview', type: 'ActorBrowse', order: 0, category: 'primary', assocCode: 'ACT'},
        {bn: 'STR', name: 'Related Strategies', type: 'Strategies', order: 1, category: 'related', assocCode: 'STR', roles: 'ROLE_STRATEGY_MODULE_ACCESS'},
        {bn: 'DEM', name: 'Related Demands', type: 'Demands', order: 2, category: 'related', assocCode: 'DEM', roles: 'ROLE_DEMAND_MODULE_ACCESS'},
        {bn: 'ORG', name: 'Related Organizations', type: 'Organizations', order: 3, category: 'related', assocCode: 'ORG'},
        {bn: 'PER', name: 'Related People', type: 'People', order: 4, category: 'related', assocCode: 'PER'},
        {bn: 'COM', name: 'Related Companies', type: 'Companies', order: 5, category: 'related', assocCode: 'COM'},
        {bn: 'CAP', name: 'Related Capabilities', type: 'Capabilities', order: 6, category: 'related', assocCode: 'CAP'},
        {bn: 'PRD', name: 'Related Products', type: 'Products', order: 7, category: 'related', assocCode: 'PRD'},
        {bn: 'MKT', name: 'Related Markets', type: 'Markets', order: 8, category: 'related', assocCode: 'MKT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'DAT', name: 'Related Data', type: 'Data', order: 9, category: 'related', assocCode: 'DAT', roles: 'ROLE_DATA_MODULE_ACCESS'},
        {bn: 'CON', name: 'Related Connections', type: 'Connections', order: 10, category: 'related', assocCode: 'CON', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'SYS', name: 'Related Systems', type: 'Systems', order: 11, category: 'related', assocCode: 'SYS'},
        {bn: 'TEC', name: 'Related Technologies', type: 'Technologies', order: 12, category: 'related', assocCode: 'TEC'},
        {bn: 'STA', name: 'Related Standards', type: 'Standards', order: 13, category: 'related', assocCode: 'STA'},
        {bn: 'SKI', name: 'Related Skills', type: 'Skills', order: 14, category: 'related', assocCode: 'SKI'},
        {bn: 'PHY', name: 'Related Deployments', type: 'Physicals', order: 15, category: 'related', assocCode: 'PHY', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TAG', name: 'Related Tags', type: 'Tags', order: 16, category: 'related', assocCode: 'TAG'},
        {bn: 'AST', name: 'Related Documents', type: 'Documents', order: 17, category: 'related', assocCode: 'AST'}
    ]};

PageNameToLayout['capabilities'] = {bn: 'CAP', name: 'Capabilities',
    countAssocs: sideBarConfigs['0Q'].multi,
    contentBlocks: [
        {bn: 'NEW_CB1', columnId: 0, order: 0, sectionBn: 'BROWSE', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'CAPABILITY_BROWSE'}},
        strategyAssociation,
        demandAssociation,
        orgAssociation,
        personAssociation,
        companyAssociation,
        actorAssociation,
        productAssociation,
        marketAssociation,
        dataAssociation,
        systemAssociation,
        connectionAssociationNoDiagram,
        technologyAssociation,
        standardAssociation,
        skillAssociation,
        physicalAssociation,
        tagAssociation,
        documentAssociation
    ],
    sections: [
        {bn: 'BROWSE', name: 'Overview', type: 'CapabilityBrowse', order: 0, category: 'primary', assocCode: 'CAP'},
        {bn: 'STR', name: 'Related Strategies', type: 'Strategies', order: 1, category: 'related', assocCode: 'STR', roles: 'ROLE_STRATEGY_MODULE_ACCESS'},
        {bn: 'DEM', name: 'Related Demands', type: 'Demands', order: 2, category: 'related', assocCode: 'DEM', roles: 'ROLE_DEMAND_MODULE_ACCESS'},
        {bn: 'ORG', name: 'Related Organizations', type: 'Organizations', order: 3, category: 'related', assocCode: 'ORG', proposedCodes: 'ORG'},
        {bn: 'PER', name: 'Related People', type: 'People', order: 4, category: 'related', assocCode: 'PER'},
        {bn: 'COM', name: 'Related Companies', type: 'Companies', order: 5, category: 'related', assocCode: 'COM'},
        {bn: 'ACT', name: 'Related Actors', type: 'Actors', order: 6, category: 'related', assocCode: 'ACT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'PRD', name: 'Related Products', type: 'Products', order: 7, category: 'related', assocCode: 'PRD', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'MKT', name: 'Related Markets', type: 'Markets', order: 8, category: 'related', assocCode: 'MKT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'DAT', name: 'Related Data', type: 'Data', order: 9, category: 'related', assocCode: 'DAT', roles: 'ROLE_DATA_MODULE_ACCESS'},
        {bn: 'SYS', name: 'Related Systems', type: 'Systems', order: 10, category: 'related', assocCode: 'SYS', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CON', name: 'Related Connections', type: 'Connections', order: 11, category: 'related', assocCode: 'CON', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TEC', name: 'Related Technologies', type: 'Technologies', order: 12, category: 'related', assocCode: 'TEC', proposedCodes: 'TEC'},
        {bn: 'STA', name: 'Related Standards', type: 'Standards', order: 13, category: 'related', assocCode: 'STA', proposedCodes: 'STA'},
        {bn: 'SKI', name: 'Related Skills', type: 'Skills', order: 14, category: 'related', assocCode: 'SKI', proposedCodes: 'SKI'},
        {bn: 'PHY', name: 'Related Deployments', type: 'Physicals', order: 15, category: 'related', assocCode: 'PHY', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TAG', name: 'Related Tags', type: 'Tags', order: 16, category: 'related', assocCode: 'TAG'},
        {bn: 'AST', name: 'Related Documents', type: 'Documents', order: 17, category: 'related', assocCode: 'AST'}
    ]};


PageNameToLayout['circle'] = {bn: '2O', name: 'Circle',
    countAssocs: sideBarConfigs['2O'].single,
    contentBlocks: [
        {bn: 'NEW_CB1', columnId: 0, order: 0, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'CIRCLE_BASIC_INFO'}},
        {bn: 'NEW_CB2', columnId: 0, order: 1, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'CIRCLE_CENTERED_ON'}},
        circleNews,
        {bn: 'NEW_CB3', columnId: 0, order: 2, sectionBn: 'MEMBERS', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'CIRCLE_MEMBERS'}},
        documentAssociationByEntity,
        discussionAssociation,
        activityAssociation

    ],
    sections: [
        {bn: 'BASIC', name: 'Overview', type: 'CircleOverview', order: 0, category: 'primary'},
        {bn: 'CAY', name: 'Circle News', type: 'CircleNews', order: 2, category: 'primary', assocCode: 'CAY'},
        {bn: 'MEMBERS', name: 'Circle Members', type: 'People', order: 3, category: 'related'},
        {bn: 'AST', name: 'Related Documents', type: 'Documents', order: 4, category: 'related', assocCode: 'AST'},
        {bn: 'TOP', name: 'Related Discussions', type: 'Discussions', order: 5, category: 'related', assocCode: 'TOP'},
        {bn: 'EAY', name: 'Activity', type: 'Activity', order: 6, category: 'tertiary', assocCode: 'EAY'}
    ]};

PageNameToLayout['circles'] = {bn: 'CIR', name: 'Collections',
    countAssocs: sideBarConfigs['2O'].multi,
    contentBlocks: [
        {bn: 'NEW_CB1', columnId: 0, order: 0, sectionBn: 'BROWSE', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'CIRCLE_BROWSE', displayPivot: false}},
        documentAssociation
    ],
    sections: [
        {bn: 'BROWSE', name: 'Overview', type: 'CircleBrowse', order: 0, category: 'primary', assocCode: 'CIR'}
    ]};

PageNameToLayout['workspaces'] = {bn: 'CIR', name: 'Collections',
    countAssocs: sideBarConfigs['2O'].multi,
    contentBlocks: [
        {bn: 'NEW_CB1', columnId: 0, order: 0, sectionBn: 'BROWSE', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'WORKSPACE_BROWSE', displayPivot: false}},
        documentAssociation
    ],
    sections: [
        {bn: 'BROWSE', name: 'Overview', type: 'WorkspaceBrowse', order: 0, category: 'primary', assocCode: 'CIR'}
    ]};

PageNameToLayout['companies'] = {bn: 'COM', name: 'Companies',
    countAssocs: sideBarConfigs['2F'].multi,
    contentBlocks: [
        {bn: 'NEW_CB1', columnId: 0, order: 0, sectionBn: 'BROWSE', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'COMPANY_BROWSE'}},
        strategyAssociation,
        demandAssociation,
        orgAssociation,
        personAssociation,
        actorAssociation,
        capabilityAssociation,
        productAssociation,
        marketAssociation,
        dataAssociation,
        systemAssociation,
        connectionAssociationNoDiagram,
        technologyAssociation,
        standardAssociation,
        skillAssociation,
        physicalAssociation,
        tagAssociation,
        documentAssociation
    ],
    sections: [
        {bn: 'BROWSE', name: 'Overview', type: 'CompanyBrowse', order: 0, category: 'primary', assocCode: 'COM'},
        {bn: 'STR', name: 'Related Strategies', type: 'Strategies', order: 1, category: 'related', assocCode: 'STR', roles: 'ROLE_STRATEGY_MODULE_ACCESS'},
        {bn: 'DEM', name: 'Related Demands', type: 'Demands', order: 2, category: 'related', assocCode: 'DEM', roles: 'ROLE_DEMAND_MODULE_ACCESS'},
        {bn: 'ORG', name: 'Related Organizations', type: 'Organizations', order: 3, category: 'related', assocCode: 'ORG'},
        {bn: 'PER', name: 'Related People', type: 'People', order: 4, category: 'related', assocCode: 'PER'},
        {bn: 'ACT', name: 'Related Actors', type: 'Actors', order: 5, category: 'related', assocCode: 'ACT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CAP', name: 'Related Capabilities', type: 'Capabilities', order: 6, category: 'related', assocCode: 'CAP', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'PRD', name: 'Related Products', type: 'Products', order: 7, category: 'related', assocCode: 'PRD', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'MKT', name: 'Related Markets', type: 'Markets', order: 8, category: 'related', assocCode: 'MKT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'DAT', name: 'Related Data', type: 'Data', order: 9, category: 'related', assocCode: 'DAT', roles: 'ROLE_DATA_MODULE_ACCESS'},
        {bn: 'SYS', name: 'Related Systems', type: 'Systems', order: 10, category: 'related', assocCode: 'SYS', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CON', name: 'Related Connections', type: 'Connections', order: 11, category: 'related', assocCode: 'CON', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TEC', name: 'Related Technologies', type: 'Technologies', order: 12, category: 'related', assocCode: 'TEC'},
        {bn: 'STA', name: 'Related Standards', type: 'Standards', order: 13, category: 'related', assocCode: 'STA'},
        {bn: 'SKI', name: 'Related Skills', type: 'Skills', order: 14, category: 'related', assocCode: 'SKI', proposedCodes: 'SKI'},
        {bn: 'PHY', name: 'Related Deployments', type: 'Physicals', order: 15, category: 'related', assocCode: 'PHY', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TAG', name: 'Related Tags', type: 'Tags', order: 16, category: 'related', assocCode: 'TAG'},
        {bn: 'AST', name: 'Related Documents', type: 'Documents', order: 17, category: 'related', assocCode: 'AST'}
    ]};

PageNameToLayout['connections'] = {bn: 'CON', name: 'Connections',
    countAssocs: sideBarConfigs['07'].multi,
    contentBlocks: [
        {bn: 'NEW_CB1', columnId: 0, order: 0, sectionBn: 'BROWSE', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'CONNECTION_BROWSE'}},
        strategyAssociation,
        demandAssociation,
        orgAssociation,
        companyAssociation,
        personContactAssociation,
        actorAssociation,
        capabilityAssociation,
        productAssociation,
        marketAssociation,
        dataAssociation,
        systemAssociation,
        technologyAssociation,
        standardAssociation,
        skillAssociation,
        physicalAssociation,
        tagAssociation,
        documentAssociation
    ],
    sections: [
        {bn: 'BROWSE', name: 'Overview', type: 'ConnectionBrowse', order: 0, category: 'primary', assocCode: 'CON'},
        {bn: 'STR', name: 'Related Strategies', type: 'Strategies', order: 1, category: 'related', assocCode: 'STR', roles: 'ROLE_STRATEGY_MODULE_ACCESS'},
        {bn: 'DEM', name: 'Related Demands', type: 'Demands', order: 2, category: 'related', assocCode: 'DEM', roles: 'ROLE_DEMAND_MODULE_ACCESS'},
        {bn: 'ORG', name: 'Related Organizations', type: 'Organizations', order: 3, category: 'related', assocCode: 'ORG'},
        {bn: 'COM', name: 'Related Companies', type: 'Companies', order: 4, category: 'related', assocCode: 'COM'},
        {bn: 'PER', name: 'Related People', type: 'People', order: 5, category: 'related', assocCode: 'PER'},
        {bn: 'ACT', name: 'Related Actors', type: 'Actors', order: 6, category: 'related', assocCode: 'ACT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CAP', name: 'Related Capabilities', type: 'Capabilities', order: 7, category: 'related', assocCode: 'CAP', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'PRD', name: 'Related Products', type: 'Products', order: 8, category: 'related', assocCode: 'PRD', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'MKT', name: 'Related Markets', type: 'Markets', order: 9, category: 'related', assocCode: 'MKT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'DAT', name: 'Related Data', type: 'Data', order: 10, category: 'related', assocCode: 'DAT', roles: 'ROLE_DATA_MODULE_ACCESS'},
        {bn: 'SYS', name: 'Related Systems', type: 'Systems', order: 11, category: 'related', assocCode: 'SYS', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TEC', name: 'Related Technologies', type: 'Technologies', order: 12, category: 'related', assocCode: 'TEC', proposedCodes: 'TEC'},
        {bn: 'STA', name: 'Related Standards', type: 'Standards', order: 13, category: 'related', assocCode: 'STA', proposedCodes: 'STA'},
        {bn: 'SKI', name: 'Related Skills', type: 'Skills', order: 14, category: 'related', assocCode: 'SKI', proposedCodes: 'SKI'},
        {bn: 'PHY', name: 'Related Deployments', type: 'Physicals', order: 15, category: 'related', assocCode: 'PHY', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TAG', name: 'Related Tags', type: 'Tags', order: 16, category: 'related', assocCode: 'TAG'},
        {bn: 'AST', name: 'Related Documents', type: 'Documents', order: 17, category: 'related', assocCode: 'AST'}
    ]};

PageNameToLayout['data'] = {bn: 'DAT', name: 'Data',
    countAssocs: sideBarConfigs['44'].multi,
    contentBlocks: [
        {bn: 'NEW_CB1', columnId: 0, order: 0, sectionBn: 'BROWSE', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'DATA_BROWSE'}},
        strategyAssociation,
        demandAssociation,
        orgAssociation,
        personAssociation,
        companyAssociation,
        actorAssociation,
        capabilityAssociation,
        productAssociation,
        marketAssociation,
        systemAssociation,
        connectionAssociationNoDiagram,
        technologyAssociation,
        standardAssociation,
        skillAssociation,
        physicalAssociation,
        tagAssociation,
        documentAssociation
    ],
    sections: [
        {bn: 'BROWSE', name: 'Overview', type: 'DataBrowse', order: 0, category: 'primary', assocCode: 'DAT'},
        {bn: 'STR', name: 'Related Strategies', type: 'Strategies', order: 1, category: 'related', assocCode: 'STR', roles: 'ROLE_STRATEGY_MODULE_ACCESS'},
        {bn: 'DEM', name: 'Related Demands', type: 'Demands', order: 2, category: 'related', assocCode: 'DEM', roles: 'ROLE_DEMAND_MODULE_ACCESS'},
        {bn: 'ORG', name: 'Related Organizations', type: 'Organizations', order: 3, category: 'related', assocCode: 'ORG'},
        {bn: 'PER', name: 'Related People', type: 'People', order: 4, category: 'related', assocCode: 'PER'},
        {bn: 'COM', name: 'Related Companies', type: 'Companies', order: 5, category: 'related', assocCode: 'COM'},
        {bn: 'ACT', name: 'Related Actors', type: 'Actors', order: 6, category: 'related', assocCode: 'ACT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CAP', name: 'Related Capabilities', type: 'Capabilities', order: 7, category: 'related', assocCode: 'CAP', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'PRD', name: 'Related Products', type: 'Products', order: 8, category: 'related', assocCode: 'PRD', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'MKT', name: 'Related Markets', type: 'Markets', order: 9, category: 'related', assocCode: 'MKT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'SYS', name: 'Related Systems', type: 'Systems', order: 10, category: 'related', assocCode: 'SYS', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CON', name: 'Related Connections', type: 'Connections', order: 11, category: 'related', assocCode: 'CON', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TEC', name: 'Related Technologies', type: 'Technologies', order: 12, category: 'related', assocCode: 'TEC', proposedCodes: 'TEC'},
        {bn: 'STA', name: 'Related Standards', type: 'Standards', order: 13, category: 'related', assocCode: 'STA', proposedCodes: 'STA'},
        {bn: 'SKI', name: 'Related Skills', type: 'Skills', order: 14, category: 'related', assocCode: 'SKI', proposedCodes: 'SKI'},
        {bn: 'PHY', name: 'Related Deployments', type: 'Physicals', order: 15, category: 'related', assocCode: 'PHY', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TAG', name: 'Related Tags', type: 'Tags', order: 16, category: 'related', assocCode: 'TAG'},
        {bn: 'AST', name: 'Related Documents', type: 'Documents', order: 17, category: 'related', assocCode: 'AST'}
    ]};

PageNameToLayout['demands'] = {bn: 'DEM', name: 'Demands',
    countAssocs: sideBarConfigs['39'].multi,
    contentBlocks: [
        {bn: 'NEW_CB1', columnId: 0, order: 0, sectionBn: 'BROWSE', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'DEMAND_BROWSE'}},
        strategyAssociation,
        orgAssociation,
        personAssociation,
        companyAssociation,
        actorAssociation,
        capabilityAssociation,
        productAssociation,
        marketAssociation,
        dataAssociation,
        systemAssociation,
        connectionAssociationNoDiagram,
        technologyAssociation,
        standardAssociation,
        skillAssociation,
        physicalAssociation,
        tagAssociation,
        documentAssociation
    ],
    sections: [
        {bn: 'BROWSE', name: 'Overview', type: 'DemandBrowse', order: 0, category: 'primary', assocCode: 'DEM'},
        {bn: 'STR', name: 'Related_Strategy', type: 'Strategies', order: 1, category: 'related', assocCode: 'STR', roles: 'ROLE_STRATEGY_MODULE_ACCESS'},
        {bn: 'ORG', name: 'Related Organizations', type: 'Organizations', order: 2, category: 'related', assocCode: 'ORG', proposedCodes: 'ORG'},
        {bn: 'PER', name: 'Related People', type: 'People', order: 3, category: 'related', assocCode: 'PER'},
        {bn: 'COM', name: 'Related Companies', type: 'Companies', order: 4, category: 'related', assocCode: 'COM'},
        {bn: 'ACT', name: 'Related Actors', type: 'Actors', order: 5, category: 'related', assocCode: 'ACT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CAP', name: 'Related Capabilities', type: 'Capabilities', order: 6, category: 'related', assocCode: 'CAP', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'PRD', name: 'Related Products', type: 'Products', order: 7, category: 'related', assocCode: 'PRD', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'MKT', name: 'Related Markets', type: 'Markets', order: 8, category: 'related', assocCode: 'MKT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'DAT', name: 'Related Data', type: 'Data', order: 9, category: 'related', assocCode: 'DAT', roles: 'ROLE_DATA_MODULE_ACCESS'},
        {bn: 'SYS', name: 'Related Systems', type: 'Systems', order: 10, category: 'related', assocCode: 'SYS', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CON', name: 'Related Connections', type: 'Connections', order: 11, category: 'related', assocCode: 'CON', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TEC', name: 'Related Technologies', type: 'Technologies', order: 12, category: 'related', assocCode: 'TEC', proposedCodes: 'TEC'},
        {bn: 'STA', name: 'Related Standards', type: 'Standards', order: 13, category: 'related', assocCode: 'STA', proposedCodes: 'STA'},
        {bn: 'SKI', name: 'Related Skills', type: 'Skills', order: 14, category: 'related', assocCode: 'SKI', proposedCodes: 'SKI'},
        {bn: 'PHY', name: 'Related Deployments', type: 'Physicals', order: 15, category: 'related', assocCode: 'PHY', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TAG', name: 'Related Tags', type: 'Tags', order: 16, category: 'related', assocCode: 'TAG'},
        {bn: 'AST', name: 'Related Documents', type: 'Documents', order: 17, category: 'related', assocCode: 'AST'}
    ]};

PageNameToLayout['documents'] = {bn: 'AST', name: 'Documents',
    countAssocs: sideBarConfigs['0U'].multi,
    contentBlocks: [
        {bn: 'NEW_WK2_044S00000005', columnId: 0, order: 0, sectionBn: 'AST', size: 'LARGE', configuration: {bn: 'AST', contentBlockType: 'DOCUMENT_BROWSE'}},
        strategyAssociation,
        demandAssociation,
        orgAssociation,
        personAssociation,
        companyAssociation,
        actorAssociation,
        capabilityAssociation,
        productAssociation,
        marketAssociation,
        dataAssociation,
        systemAssociation,
        connectionAssociationNoDiagram,
        technologyAssociation,
        standardAssociation,
        skillAssociation,
        physicalAssociation,
        tagAssociation
    ],
    sections: [
        {bn: 'AST', name: 'Overview', type: 'DocumentBrowse', order: 0, category: 'primary', assocCode: 'AST'},
        {bn: 'STR', name: 'Related_Strategy', type: 'Strategies', order: 1, category: 'related', assocCode: 'STR', roles: 'ROLE_STRATEGY_MODULE_ACCESS'},
        {bn: 'DEM', name: 'Related Demands', type: 'Demands', order: 2, category: 'related', assocCode: 'DEM', roles: 'ROLE_DEMAND_MODULE_ACCESS'},
        {bn: 'ORG', name: 'Related Organizations', type: 'Organizations', order: 2, category: 'related', assocCode: 'ORG', proposedCodes: 'ORG'},
        {bn: 'PER', name: 'Related People', type: 'People', order: 3, category: 'related', assocCode: 'PER'},
        {bn: 'COM', name: 'Related Companies', type: 'Companies', order: 4, category: 'related', assocCode: 'COM'},
        {bn: 'ACT', name: 'Related Actors', type: 'Actors', order: 5, category: 'related', assocCode: 'ACT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CAP', name: 'Related Capabilities', type: 'Capabilities', order: 6, category: 'related', assocCode: 'CAP', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'PRD', name: 'Related Products', type: 'Products', order: 7, category: 'related', assocCode: 'PRD', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'MKT', name: 'Related Markets', type: 'Markets', order: 8, category: 'related', assocCode: 'MKT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'DAT', name: 'Related Data', type: 'Data', order: 9, category: 'related', assocCode: 'DAT', roles: 'ROLE_DATA_MODULE_ACCESS'},
        {bn: 'SYS', name: 'Related Systems', type: 'Systems', order: 10, category: 'related', assocCode: 'SYS', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CON', name: 'Related Connections', type: 'Connections', order: 11, category: 'related', assocCode: 'CON', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TEC', name: 'Related Technologies', type: 'Technologies', order: 12, category: 'related', assocCode: 'TEC', proposedCodes: 'TEC'},
        {bn: 'STA', name: 'Related Standards', type: 'Standards', order: 13, category: 'related', assocCode: 'STA', proposedCodes: 'STA'},
        {bn: 'SKI', name: 'Related Skills', type: 'Skills', order: 14, category: 'related', assocCode: 'SKI', proposedCodes: 'SKI'},
        {bn: 'PHY', name: 'Related Deployments', type: 'Physicals', order: 15, category: 'related', assocCode: 'PHY', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TAG', name: 'Related Tags', type: 'Tags', order: 16, category: 'related', assocCode: 'TAG'},
    ]};

PageNameToLayout['markets'] = {bn: 'MKT', name: 'Markets',
    countAssocs: sideBarConfigs['0T'].multi,
    contentBlocks: [
        {bn: '1', columnId: 0, order: 0, sectionBn: 'BROWSE', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'MARKET_BROWSE'}},
        strategyAssociation,
        demandAssociation,
        orgAssociation,
        personAssociation,
        companyAssociation,
        actorAssociation,
        capabilityAssociation,
        productAssociation,
        dataAssociation,
        systemAssociation,
        connectionAssociationNoDiagram,
        technologyAssociation,
        standardAssociation,
        skillAssociation,
        physicalAssociation,
        tagAssociation,
        documentAssociation
    ],
    sections: [
        {bn: 'BROWSE', name: 'Overview', type: 'MarketBrowse', order: 0, category: 'primary', assocCode: 'MKT'},
        {bn: 'STR', name: 'Related Strategies', type: 'Strategies', order: 1, category: 'related', assocCode: 'STR', roles: 'ROLE_STRATEGY_MODULE_ACCESS'},
        {bn: 'DEM', name: 'Related Demands', type: 'Demands', order: 2, category: 'related', assocCode: 'DEM', roles: 'ROLE_DEMAND_MODULE_ACCESS'},
        {bn: 'ORG', name: 'Related Organizations', type: 'Organizations', order: 3, category: 'related', assocCode: 'ORG'},
        {bn: 'PER', name: 'Related People', type: 'People', order: 4, category: 'related', assocCode: 'PER'},
        {bn: 'COM', name: 'Related Companies', type: 'Companies', order: 5, category: 'related', assocCode: 'COM'},
        {bn: 'ACT', name: 'Related Actors', type: 'Actors', order: 6, category: 'related', assocCode: 'ACT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CAP', name: 'Related Capabilities', type: 'Capabilities', order: 7, category: 'related', assocCode: 'CAP'},
        {bn: 'PRD', name: 'Related Products', type: 'Products', order: 8, category: 'related', assocCode: 'PRD', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'DAT', name: 'Related Data', type: 'Data', order: 9, category: 'related', assocCode: 'DAT', roles: 'ROLE_DATA_MODULE_ACCESS'},
        {bn: 'SYS', name: 'Related Systems', type: 'Systems', order: 10, category: 'related', assocCode: 'SYS'},
        {bn: 'CON', name: 'Related Connections', type: 'Connections', order: 11, category: 'related', assocCode: 'CON', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TEC', name: 'Related Technologies', type: 'Technologies', order: 12, category: 'related', assocCode: 'TEC', proposedCodes: 'TEC'},
        {bn: 'STA', name: 'Related Standards', type: 'Standards', order: 13, category: 'related', assocCode: 'STA', proposedCodes: 'STA'},
        {bn: 'SKI', name: 'Related Skills', type: 'Skills', order: 14, category: 'related', assocCode: 'SKI', proposedCodes: 'SKI'},
        {bn: 'PHY', name: 'Related Deployments', type: 'Physicals', order: 15, category: 'related', assocCode: 'PHY', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TAG', name: 'Related Tags', type: 'Tags', order: 16, category: 'related', assocCode: 'TAG'},
        {bn: 'AST', name: 'Related Documents', type: 'Documents', order: 17, category: 'related', assocCode: 'AST'}
    ]};

PageNameToLayout['organizations'] = {bn: 'ORG', name: 'Organizations',
    countAssocs: sideBarConfigs['0Y'].multi,
    contentBlocks: [
        {bn: 'NEW_CB1', columnId: 0, order: 0, sectionBn: 'BROWSE', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'ORGANIZATION_BROWSE'}},
        strategyAssociation,
        demandAssociation,
        personEmploymentAssociation,
        actorAssociation,
        capabilityAssociation,
        companyAssociation,
        productAssociation,
        marketAssociation,
        dataAssociation,
        systemAssociation,
        connectionAssociationNoDiagram,
        technologyAssociation,
        standardAssociation,
        skillAssociation,
        physicalAssociation,
        documentAssociation
    ],
    sections: [
        {bn: 'BROWSE', name: 'Overview', type: 'OrganizationBrowse', order: 0, category: 'primary', assocCode: 'ORG'},
        {bn: 'STR', name: 'Related Strategies', type: 'Strategies', order: 1, category: 'related', assocCode: 'STR', roles: 'ROLE_STRATEGY_MODULE_ACCESS'},
        {bn: 'DEM', name: 'Related Demands', type: 'Demands', order: 2, category: 'related', assocCode: 'DEM', roles: 'ROLE_DEMAND_MODULE_ACCESS'},
        {bn: 'PER', name: 'Related People', type: 'People', order: 3, category: 'related', assocCode: 'PER'},
        {bn: 'ACT', name: 'Related Actors', type: 'Actors', order: 4, category: 'related', assocCode: 'ACT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CAP', name: 'Related Capabilities', type: 'Capabilities', order: 5, category: 'related', assocCode: 'CAP', proposedCodes: 'CAP', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'COM', name: 'Related Companies', type: 'Companies', order: 6, category: 'related', assocCode: 'COM', proposedCodes: 'COM', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'PRD', name: 'Related Products', type: 'Products', order: 7, category: 'related', assocCode: 'PRD', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'MKT', name: 'Related Markets', type: 'Markets', order: 8, category: 'related', assocCode: 'MKT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'DAT', name: 'Related Data', type: 'Data', order: 9, category: 'related', assocCode: 'DAT', roles: 'ROLE_DATA_MODULE_ACCESS'},
        {bn: 'SYS', name: 'Related Systems', type: 'Systems', order: 10, category: 'related', assocCode: 'SYS', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CON', name: 'Related Connections', type: 'Connections', order: 11, category: 'related', assocCode: 'CON', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TEC', name: 'Related Technologies', type: 'Technologies', order: 12, category: 'related', assocCode: 'TEC'},
        {bn: 'STA', name: 'Related Standards', type: 'Standards', order: 13, category: 'related', assocCode: 'STA', proposedCodes: 'STA'},
        {bn: 'SKI', name: 'Related Skills', type: 'Skills', order: 14, category: 'related', assocCode: 'SKI', proposedCodes: 'SKI'},
        {bn: 'PHY', name: 'Related Deployments', type: 'Physicals', order: 15, category: 'related', assocCode: 'PHY', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'AST', name: 'Related Documents', type: 'Documents', order: 16, category: 'related', assocCode: 'AST'}
    ]};

PageNameToLayout['people'] = {bn: 'PER', name: 'People',
    countAssocs: sideBarConfigs['0E'].multi,
    contentBlocks: [
        {bn: 'BROWSE', columnId: 0, order: 0, sectionBn: 'BROWSE', size: 'LARGE', configuration: {bn: 'BROWSE', contentBlockType: 'PROFILE_BROWSE'}},
        strategyPersonAssociation,
        demandPersonAssociation,
        companyPersonAssociations,
        orgPersonAssociations,
        actorPersonAssociations,
        capabilityPersonAssociations,
        productPersonAssociation,
        marketPersonAssociations,
        dataPersonAssociations,
        systemPersonAssociations,
        connectionPersonAssociations,
        technologyPersonAssociations,
        standardPersonAssociations,
        skillPersonAssociations,
        physicalPersonAssociation,
        tagPersonAssociations,
        documentPersonAssociations
    ],
    sections: [
        {bn: 'BROWSE', layoutSectionType: 'WHOLE', name: 'Overview', type: 'PersonBrowse', order: 0, category: 'primary', assocCode: 'PER'},
        {bn: 'STR', name: 'Related Strategies', type: 'Strategies', order: 1, category: 'related', assocCode: 'STR', roles: 'ROLE_STRATEGY_MODULE_ACCESS'},
        {bn: 'DEM', name: 'Related Demands', type: 'Demands', order: 2, category: 'related', assocCode: 'DEM', roles: 'ROLE_DEMAND_MODULE_ACCESS'},
        {bn: 'ORG', name: 'Related Organizations', type: 'Organizations', order: 3, category: 'related', assocCode: 'ORG'},
        {bn: 'COM', name: 'Related Companies', type: 'Companies', order: 4, category: 'related', assocCode: 'COM'},
        {bn: 'ACT', name: 'Related Actors', type: 'Actors', order: 5, category: 'related', assocCode: 'ACT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CAP', name: 'Related Capabilities', type: 'Capabilities', order: 6, category: 'related', assocCode: 'CAP', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'PRD', name: 'Related Products', type: 'Products', order: 7, category: 'related', assocCode: 'PRD', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'MKT', name: 'Related Markets', type: 'Markets', order: 8, category: 'related', assocCode: 'MKT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'DAT', name: 'Related Data', type: 'Data', order: 9, category: 'related', assocCode: 'DAT', roles: 'ROLE_DATA_MODULE_ACCESS'},
        {bn: 'SYS', name: 'Related Systems', type: 'Systems', order: 10, category: 'related', assocCode: 'SYS', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CON', name: 'Related Connections', type: 'Connections', order: 11, category: 'related', assocCode: 'CON', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TEC', name: 'Related Technologies', type: 'Technologies', order: 12, category: 'related', assocCode: 'TEC'},
        {bn: 'STA', name: 'Related Standards', type: 'Standards', order: 13, category: 'related', assocCode: 'STA'},
        {bn: 'SKI', name: 'Related Skills', type: 'Skills', order: 14, category: 'related', assocCode: 'SKI'},
        {bn: 'PHY', name: 'Related Deployments', type: 'Physicals', order: 15, category: 'related', assocCode: 'PHY', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TAG', name: 'Related Tags', type: 'Tags', order: 16, category: 'related', assocCode: 'TAG'},
        {bn: 'AST', name: 'Related Documents', type: 'Documents', order: 17, category: 'related', assocCode: 'AST'}
    ]};

PageNameToLayout['deployments'] = {bn: 'PHY', name: 'Deployments',
    countAssocs: sideBarConfigs['4L'].multi,
    contentBlocks: [
        {bn: 'NEW_CB1', columnId: 0, order: 0, sectionBn: 'BROWSE', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'PHYSICAL_BROWSE'}},
        strategyAssociation,
        demandAssociation,
        orgAssociation,
        personAssociation,
        companyAssociation,
        actorAssociation,
        capabilityAssociation,
        productAssociation,
        marketAssociation,
        dataAssociation,
        systemAssociation,
        connectionAssociationNoDiagram,
        technologyAssociation,
        standardAssociation,
        skillAssociation,
        tagAssociation,
        documentAssociation
    ],
    sections: [
        {bn: 'BROWSE', name: 'Overview', type: 'PhysicalBrowse', order: 0, category: 'primary', assocCode: 'PHY'},
        {bn: 'STR', name: 'Related Strategies', type: 'Strategies', order: 1, category: 'related', assocCode: 'STR', roles: 'ROLE_STRATEGY_MODULE_ACCESS'},
        {bn: 'DEM', name: 'Related Demands', type: 'Demands', order: 2, category: 'related', assocCode: 'DEM', roles: 'ROLE_DEMAND_MODULE_ACCESS'},
        {bn: 'ORG', name: 'Related Organizations', type: 'Organizations', order: 3, category: 'related', assocCode: 'ORG'},
        {bn: 'PER', name: 'Related People', type: 'People', order: 4, category: 'related', assocCode: 'PER'},
        {bn: 'COM', name: 'Related Companies', type: 'Companies', order: 5, category: 'related', assocCode: 'COM'},
        {bn: 'ACT', name: 'Related Actors', type: 'Actors', order: 6, category: 'related', assocCode: 'ACT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CAP', name: 'Related Capabilities', type: 'Capabilities', order: 7, category: 'related', assocCode: 'CAP', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'PRD', name: 'Related Products', type: 'Products', order: 8, category: 'related', assocCode: 'PRD', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'MKT', name: 'Related Markets', type: 'Markets', order: 9, category: 'related', assocCode: 'MKT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'DAT', name: 'Related Data', type: 'Data', order: 10, category: 'related', assocCode: 'DAT', roles: 'ROLE_DATA_MODULE_ACCESS'},
        {bn: 'SYS', name: 'Related Systems', type: 'Systems', order: 11, category: 'related', assocCode: 'SYS', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CON', name: 'Related Connections', type: 'Connections', order: 12, category: 'related', assocCode: 'CON', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TEC', name: 'Related Technologies', type: 'Technologies', order: 13, category: 'related', assocCode: 'TEC'},
        {bn: 'STA', name: 'Related Standards', type: 'Standards', order: 14, category: 'related', assocCode: 'STA'},
        {bn: 'SKI', name: 'Related Skills', type: 'Skills', order: 15, category: 'related', assocCode: 'SKI'},
        {bn: 'TAG', name: 'Related Tags', type: 'Tags', order: 16, category: 'related', assocCode: 'TAG'},
        {bn: 'AST', name: 'Related Documents', type: 'Documents', order: 17, category: 'related', assocCode: 'AST'}
    ]};

PageNameToLayout['products'] = {bn: 'PRD', name: 'Products',
    countAssocs: sideBarConfigs['0Z'].multi,
    contentBlocks: [
        {bn: 'NEW_CB1', columnId: 0, order: 0, sectionBn: 'BROWSE', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'PRODUCT_BROWSE'}},
        strategyAssociation,
        demandAssociation,
        orgAssociation,
        personAssociation,
        companyAssociation,
        actorAssociation,
        capabilityAssociation,
        marketAssociation,
        dataAssociation,
        systemAssociation,
        connectionAssociationNoDiagram,
        technologyAssociation,
        standardAssociation,
        skillAssociation,
        physicalAssociation,
        tagAssociation,
        documentAssociation
    ],
    sections: [
        {bn: 'BROWSE', name: 'Overview', type: 'ProductBrowse', order: 0, category: 'primary', assocCode: 'PRD'},
        {bn: 'STR', name: 'Related Strategies', type: 'Strategies', order: 1, category: 'related', assocCode: 'STR', roles: 'ROLE_STRATEGY_MODULE_ACCESS'},
        {bn: 'DEM', name: 'Related Demands', type: 'Demands', order: 2, category: 'related', assocCode: 'DEM', roles: 'ROLE_DEMAND_MODULE_ACCESS'},
        {bn: 'ORG', name: 'Related Organizations', type: 'Organizations', order: 3, category: 'related', assocCode: 'ORG'},
        {bn: 'PER', name: 'Related People', type: 'People', order: 4, category: 'related', assocCode: 'PER'},
        {bn: 'COM', name: 'Related Companies', type: 'Companies', order: 5, category: 'related', assocCode: 'COM'},
        {bn: 'ACT', name: 'Related Actors', type: 'Actors', order: 6, category: 'related', assocCode: 'ACT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CAP', name: 'Related Capabilities', type: 'Capabilities', order: 7, category: 'related', assocCode: 'CAP', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'MKT', name: 'Related Markets', type: 'Markets', order: 8, category: 'related', assocCode: 'MKT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'DAT', name: 'Related Data', type: 'Data', order: 9, category: 'related', assocCode: 'DAT', roles: 'ROLE_DATA_MODULE_ACCESS'},
        {bn: 'SYS', name: 'Related Systems', type: 'Systems', order: 10, category: 'related', assocCode: 'SYS', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CON', name: 'Related Connections', type: 'Connections', order: 11, category: 'related', assocCode: 'CON', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TEC', name: 'Related Technologies', type: 'Technologies', order: 12, category: 'related', assocCode: 'TEC'},
        {bn: 'STA', name: 'Related Standards', type: 'Standards', order: 13, category: 'related', assocCode: 'STA'},
        {bn: 'SKI', name: 'Related Skills', type: 'Skills', order: 14, category: 'related', assocCode: 'SKI'},
        {bn: 'PHY', name: 'Related Deployments', type: 'Physicals', order: 15, category: 'related', assocCode: 'PHY', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TAG', name: 'Related Tags', type: 'Tags', order: 16, category: 'related', assocCode: 'TAG'},
        {bn: 'AST', name: 'Related Documents', type: 'Documents', order: 17, category: 'related', assocCode: 'AST'}
    ]};

PageNameToLayout['skills'] = {bn: 'SKI', name: 'Skills',
    countAssocs: sideBarConfigs['15'].multi,
    contentBlocks: [
        {bn: 'NEW_CB1', columnId: 0, order: 0, sectionBn: 'BROWSE', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'SKILL_BROWSE'}},
        strategyAssociation,
        demandAssociation,
        orgAssociation,
        personEmploymentAssociation,
        companyAssociation,
        actorAssociation,
        capabilityAssociation,
        productAssociation,
        marketAssociation,
        dataAssociation,
        systemAssociation,
        connectionAssociationNoDiagram,
        technologyAssociation,
        standardAssociation,
        skillAssociation,
        physicalAssociation,
        tagAssociation,
        documentAssociation
    ],
    sections: [
        {bn: 'BROWSE', name: 'Overview', type: 'SkillBrowse', order: 0, category: 'primary', assocCode: 'SKI'},
        {bn: 'STR', name: 'Related Strategies', type: 'Strategies', order: 1, category: 'related', assocCode: 'STR', roles: 'ROLE_STRATEGY_MODULE_ACCESS'},
        {bn: 'DEM', name: 'Related Demands', type: 'Demands', order: 2, category: 'related', assocCode: 'DEM', roles: 'ROLE_DEMAND_MODULE_ACCESS'},
        {bn: 'ORG', name: 'Related Organizations', type: 'Organizations', order: 3, category: 'related', assocCode: 'ORG'},
        {bn: 'PER', name: 'Related People', type: 'People', order: 4, category: 'related', assocCode: 'PER'},
        {bn: 'COM', name: 'Related Companies', type: 'Companies', order: 5, category: 'related', assocCode: 'COM'},
        {bn: 'ACT', name: 'Related Actors', type: 'Actors', order: 6, category: 'related', assocCode: 'ACT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CAP', name: 'Related Capabilities', type: 'Capabilities', order: 7, category: 'related', assocCode: 'CAP', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'PRD', name: 'Related Products', type: 'Products', order: 8, category: 'related', assocCode: 'PRD', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'MKT', name: 'Related Markets', type: 'Markets', order: 9, category: 'related', assocCode: 'MKT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'DAT', name: 'Related Data', type: 'Data', order: 10, category: 'related', assocCode: 'DAT', roles: 'ROLE_DATA_MODULE_ACCESS'},
        {bn: 'SYS', name: 'Related Systems', type: 'Systems', order: 11, category: 'related', assocCode: 'SYS', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CON', name: 'Related Connections', type: 'Connections', order: 12, category: 'related', assocCode: 'CON', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TEC', name: 'Related Technologies', type: 'Technologies', order: 13, category: 'related', assocCode: 'TEC'},
        {bn: 'STA', name: 'Related Standards', type: 'Standards', order: 14, category: 'related', assocCode: 'STA'},
        {bn: 'PHY', name: 'Related Deployments', type: 'Physicals', order: 15, category: 'related', assocCode: 'PHY', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TAG', name: 'Related Tags', type: 'Tags', order: 16, category: 'related', assocCode: 'TAG'},
        {bn: 'AST', name: 'Related Documents', type: 'Documents', order: 17, category: 'related', assocCode: 'AST'}
    ]};

PageNameToLayout['standards'] = {bn: 'STA', name: 'Standards',
    countAssocs: sideBarConfigs['16'].multi,
    contentBlocks: [
        {bn: 'NEW_CB1', columnId: 0, order: 0, sectionBn: 'BROWSE', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'STANDARD_BROWSE'}},
        strategyAssociation,
        demandAssociation,
        orgAssociation,
        personAssociation,
        companyAssociation,
        actorAssociation,
        capabilityAssociation,
        productAssociation,
        marketAssociation,
        dataAssociation,
        systemAssociation,
        connectionAssociationNoDiagram,
        technologyAssociation,
        skillAssociation,
        physicalAssociation,
        documentAssociation,
        tagAssociation
    ],
    sections: [
        {bn: 'BROWSE', name: 'Overview', type: 'StandardBrowse', order: 0, category: 'primary', assocCode: 'STA'},
        {bn: 'STR', name: 'Related Strategies', type: 'Strategies', order: 1, category: 'related', assocCode: 'STR', roles: 'ROLE_STRATEGY_MODULE_ACCESS'},
        {bn: 'DEM', name: 'Related Demands', type: 'Demands', order: 2, category: 'related', assocCode: 'DEM', roles: 'ROLE_DEMAND_MODULE_ACCESS'},
        {bn: 'ORG', name: 'Related Organizations', type: 'Organizations', order: 3, category: 'related', assocCode: 'ORG'},
        {bn: 'PER', name: 'Related People', type: 'People', order: 4, category: 'related', assocCode: 'PER'},
        {bn: 'COM', name: 'Related Companies', type: 'Companies', order: 5, category: 'related', assocCode: 'COM'},
        {bn: 'ACT', name: 'Related Actors', type: 'Actors', order: 6, category: 'related', assocCode: 'ACT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CAP', name: 'Related Capabilities', type: 'Capabilities', order: 7, category: 'related', assocCode: 'CAP', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'PRD', name: 'Related Products', type: 'Products', order: 8, category: 'related', assocCode: 'PRD', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'MKT', name: 'Related Markets', type: 'Markets', order: 9, category: 'related', assocCode: 'MKT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'DAT', name: 'Related Data', type: 'Data', order: 10, category: 'related', assocCode: 'DAT', roles: 'ROLE_DATA_MODULE_ACCESS'},
        {bn: 'SYS', name: 'Related Systems', type: 'Systems', order: 11, category: 'related', assocCode: 'SYS', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CON', name: 'Related Connections', type: 'Connections', order: 12, category: 'related', assocCode: 'CON', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TEC', name: 'Related Technologies', type: 'Technologies', order: 13, category: 'related', assocCode: 'TEC'},
        {bn: 'SKI', name: 'Related Skills', type: 'Skills', order: 14, category: 'related', assocCode: 'SKI'},
        {bn: 'PHY', name: 'Related Deployments', type: 'Physicals', order: 15, category: 'related', assocCode: 'PHY', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TAG', name: 'Related Tags', type: 'Tags', order: 16, category: 'related', assocCode: 'TAG'},
        {bn: 'AST', name: 'Related Documents', type: 'Documents', order: 17, category: 'related', assocCode: 'AST'}
    ]};

PageNameToLayout['strategies'] = {bn: 'STR', name: 'Strategies',
    countAssocs: sideBarConfigs['0G'].multi,
    contentBlocks: [
        {bn: 'NEW_CB1', columnId: 0, order: 0, sectionBn: 'BROWSE', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'STRATEGY_BROWSE'}},
        demandAssociation,
        orgAssociation,
        personAssociation,
        companyAssociation,
        actorAssociation,
        capabilityAssociation,
        productAssociation,
        marketAssociation,
        dataAssociation,
        systemAssociation,
        connectionAssociationNoDiagram,
        technologyAssociation,
        standardAssociation,
        skillAssociation,
        physicalAssociation,
        documentAssociation,
        tagAssociation
    ],
    sections: [
        {bn: 'BROWSE', name: 'Overview', type: 'StrategyBrowse', order: 0, category: 'primary', assocCode: 'STR'},
        {bn: 'DEM', name: 'Related Demand', type: 'Demands', order: 1, category: 'related', assocCode: 'DEM', roles:'ROLE_DEMAND_MODULE_ACCESS'},
        {bn: 'ORG', name: 'Related Organizations', type: 'Organizations', order: 2, category: 'related', assocCode: 'ORG', proposedCodes: 'ORG'},
        {bn: 'PER', name: 'Related People', type: 'People', order: 3, category: 'related', assocCode: 'PER'},
        {bn: 'COM', name: 'Related Companies', type: 'Companies', order: 4, category: 'related', assocCode: 'COM'},
        {bn: 'ACT', name: 'Related Actors', type: 'Actors', order: 5, category: 'related', assocCode: 'ACT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CAP', name: 'Related Capabilities', type: 'Capabilities', order: 6, category: 'related', assocCode: 'CAP', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'PRD', name: 'Related Products', type: 'Products', order: 7, category: 'related', assocCode: 'PRD', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'MKT', name: 'Related Markets', type: 'Markets', order: 8, category: 'related', assocCode: 'MKT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'DAT', name: 'Related Data', type: 'Data', order: 9, category: 'related', assocCode: 'DAT', roles: 'ROLE_DATA_MODULE_ACCESS'},
        {bn: 'SYS', name: 'Related Systems', type: 'Systems', order: 10, category: 'related', assocCode: 'SYS', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CON', name: 'Related Connections', type: 'Connections', order: 11, category: 'related', assocCode: 'CON', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TEC', name: 'Related Technologies', type: 'Technologies', order: 12, category: 'related', assocCode: 'TEC', proposedCodes: 'TEC'},
        {bn: 'STA', name: 'Related Standards', type: 'Standards', order: 13, category: 'related', assocCode: 'STA', proposedCodes: 'STA'},
        {bn: 'SKI', name: 'Related Skills', type: 'Skills', order: 14, category: 'related', assocCode: 'SKI', proposedCodes: 'SKI'},
        {bn: 'PHY', name: 'Related Deployments', type: 'Physicals', order: 15, category: 'related', assocCode: 'PHY', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TAG', name: 'Related Tags', type: 'Tags', order: 16, category: 'related', assocCode: 'TAG'},
        {bn: 'AST', name: 'Related Documents', type: 'Documents', order: 17, category: 'related', assocCode: 'AST'}
    ]};

PageNameToLayout['tags'] = {bn: 'TAG', name: 'Tags',
    countAssocs: sideBarConfigs['1F'].multi,
    contentBlocks: [
        {bn: 'NEW_CB1', columnId: 0, order: 0, sectionBn: 'BROWSE', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'TAG_BROWSE'}}
    ],
    sections: [
        {bn: 'BROWSE', name: 'Overview', type: 'TagBrowse', order: 0, category: 'primary', assocCode: 'TAG'}
    ]};

PageNameToLayout['technologies'] = {bn: 'TEC', name: 'Technologies',
    countAssocs: sideBarConfigs['1H'].multi,
    contentBlocks: [
        {bn: 'NEW_CB1', columnId: 0, order: 0, sectionBn: 'BROWSE', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'TECHNOLOGY_BROWSE'}},
        strategyAssociation,
        demandAssociation,
        orgAssociation,
        personAssociation,
        companyAssociation,
        actorAssociation,
        capabilityAssociation,
        productAssociation,
        marketAssociation,
        dataAssociation,
        systemAssociation,
        connectionAssociationNoDiagram,
        standardAssociation,
        skillAssociation,
        physicalAssociation,
        tagAssociation,
        documentAssociation
    ],
    sections: [
        {bn: 'BROWSE', name: 'Overview', type: 'TechnologyBrowse', order: 0, category: 'primary', assocCode: 'TEC'},
        {bn: 'STR', name: 'Related Strategies', type: 'Strategies', order: 1, category: 'related', assocCode: 'STR', roles: 'ROLE_STRATEGY_MODULE_ACCESS'},
        {bn: 'DEM', name: 'Related Demands', type: 'Demands', order: 2, category: 'related', assocCode: 'DEM', roles: 'ROLE_DEMAND_MODULE_ACCESS'},
        {bn: 'ORG', name: 'Related Organizations', type: 'Organizations', order: 3, category: 'related', assocCode: 'ORG'},
        {bn: 'PER', name: 'Related People', type: 'People', order: 4, category: 'related', assocCode: 'PER'},
        {bn: 'COM', name: 'Related Companies', type: 'Companies', order: 5, category: 'related', assocCode: 'COM'},
        {bn: 'ACT', name: 'Related Actors', type: 'Actors', order: 6, category: 'related', assocCode: 'ACT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CAP', name: 'Related Capabilities', type: 'Capabilities', order: 7, category: 'related', assocCode: 'CAP', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'PRD', name: 'Related Products', type: 'Products', order: 8, category: 'related', assocCode: 'PRD', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'MKT', name: 'Related Markets', type: 'Markets', order: 9, category: 'related', assocCode: 'MKT', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'DAT', name: 'Related Data', type: 'Data', order: 10, category: 'related', assocCode: 'DAT', roles: 'ROLE_DATA_MODULE_ACCESS'},
        {bn: 'SYS', name: 'Related Systems', type: 'Systems', order: 11, category: 'related', assocCode: 'SYS', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CON', name: 'Related Connections', type: 'Connections', order: 12, category: 'related', assocCode: 'CON', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'STA', name: 'Related Standards', type: 'Standards', order: 13, category: 'related', assocCode: 'STA', proposedCodes: 'STA'},
        {bn: 'SKI', name: 'Related Skills', type: 'Skills', order: 14, category: 'related', assocCode: 'SKI'},
        {bn: 'PHY', name: 'Related Deployments', type: 'Physicals', order: 15, category: 'related', assocCode: 'PHY', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TAG', name: 'Related Tags', type: 'Tags', order: 16, category: 'related', assocCode: 'TAG'},
        {bn: 'AST', name: 'Related Documents', type: 'Documents', order: 17, category: 'related', assocCode: 'AST'}
    ]};

PageNameToLayout['rule'] = {
    bn: '3Z',
    name: 'Rule',
    countAssocs: sideBarConfigs['3Z'].single,
    contentBlocks: [
        {bn: 'BASIC', columnId: 0, order: 0, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'BASIC', contentBlockType: 'COMPLIANCE_BASIC_INFO'}},
        {bn: 'PER', columnId: 0, order: 0, sectionBn: 'PER', size: 'LARGE', configuration: {bn: 'PER', contentBlockType: 'RULE_PEOPLE'}},
        {bn: 'DEPAUDITS', columnId: 0, order: 0, sectionBn: 'DEPAUDITS', size: 'LARGE', configuration: {bn: 'DEPAUDITS', contentBlockType: 'COMPLIANCE_DEPENDENT_AUDITS'}},
        {bn: 'ADQ_ASSOC', columnId: 0, order: 0, sectionBn: 'ADQ', size: 'LARGE', configuration: {bn: 'ADQ_ASSOC_CONFIG', contentBlockType: 'ADHOCREPORT_COMPLIANCE_ASSOCIATIONS'}},
        activityAssociation
    ],
    sections: [
        {bn: 'BASIC', name: 'Overview', type: 'ComplianceOverview', order: 0, category: 'primary'},
        {bn: 'PER', name: 'Contacts', type: 'People', order: 2, category: 'related'},
        {bn: 'ADQ', name: 'Related Reports', type: 'AdHocReports', order: 3, category: 'related', assocCode: 'ADQ'},
        {bn: 'DEPAUDITS', name: 'Dependent Scorecards', type: 'ComplianceRuleDependentAudits', order: 4, category: 'related', roles: 'ROLE_AUDIT_MODULE_ACCESS'},
        {bn: 'EAY', name: 'Activity', type: 'Activity', order: 5, category: 'tertiary', assocCode: 'EAY'}
    ]
};

PageNameToLayout['rules'] = {
    bn: '044S0000000C',
    name: 'Rules',
    countAssocs: sideBarConfigs['3Z'].multi,
    contentBlocks: [
        {bn: 'NEW_WK2_044S0000000C', columnId: 0, order: 0, sectionBn: '044S0000000C', size: 'LARGE', configuration: {bn: '044S0000000C', contentBlockType: 'COMPLIANCE_BROWSE', displayPivot: false}}
    ],
    sections: [
        {bn: '044S0000000C', name: 'All Rules', type: 'ComplianceBrowse', order: 0, category: 'primary', assocCode: 'CRL'}
    ]
};

PageNameToLayout['audit'] = {
    bn: '4A',
    name: 'Audit',
    countAssocs: sideBarConfigs['4A'].single,
    contentBlocks: [
        {bn: 'BASIC', columnId: 0, order: 0, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'BASIC', contentBlockType: 'AUDIT_BASIC_INFO'}},
        {bn: 'PER', columnId: 0, order: 0, sectionBn: 'PER', size: 'LARGE', configuration: {bn: 'PER', contentBlockType: 'AUDIT_PEOPLE'}},
        {bn: 'AUDITRESULTS', columnId: 0, order: 0, sectionBn: 'AUDITRESULTS', size: 'LARGE', configuration: {bn: 'AUDITRESULTS', contentBlockType: 'AUDIT_RESULTS'}},
        activityAssociation
    ],
    sections: [
        {bn: 'BASIC', name: 'Overview', type: 'AuditOverview', order: 0, category: 'primary'},
        {bn: 'AUDITRESULTS', name: 'Audit Results', type: 'AuditTable', order: 1, category: 'related'},
        {bn: 'PER', name: 'Contacts', type: 'People', order: 2, category: 'related'},
        {bn: 'EAY', name: 'Activity', type: 'Activity', order: 3, category: 'tertiary', assocCode: 'EAY'}
    ]
};

PageNameToLayout['audits'] = {
    bn: 'AUD',
    name: 'Audits',
    countAssocs: sideBarConfigs['4A'].multi,
    contentBlocks: [
        {bn: 'AUD', columnId: 0, order: 0, sectionBn: 'AUD', size: 'LARGE', configuration: {bn: 'AUD', contentBlockType: 'AUDIT_BROWSE', displayPivot: false}}
    ],
    sections: [
        {bn: 'AUD', name: 'All Audits', type: 'AuditBrowse', order: 0, category: 'primary', assocCode: 'AUD'}
    ]
};

PageNameToLayout['report'] = {
    bn: '24',
    name: 'Report',
    countAssocs: sideBarConfigs['24'].single,
    contentBlocks: [
        {bn: 'BASIC', columnId: 0, order: 0, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'BASIC', contentBlockType: 'ADHOCREPORT_BASIC_INFO'}},
        {bn: '`_ASSOC', columnId: 0, order: 0, sectionBn: 'ADQ', size: 'LARGE', configuration: {bn: 'ADQ_ASSOC_CONFIG', contentBlockType: 'ADHOCREPORT_ADHOCREPORT_ASSOCIATIONS'}},
        {bn: 'CRL_ASSOC', columnId: 0, order: 0, sectionBn: '044S0000000C', size: 'LARGE', configuration: {bn: 'CRL_ASSOC_CONFIG', contentBlockType: 'COMPLIANCE_ADHOCREPORT_ASSOCIATIONS'}},
        activityAssociation
    ],
    sections: [
        {bn: 'BASIC', name: 'Report Overview', type: 'AdHocReport', order: 0, category: 'primary'},
        {bn: 'ADQ', name: 'Related Reports', type: 'AdHocReports', order: 1, category: 'related', assocCode: 'ADQ'},
        {bn: '044S0000000C', name: 'Related Rules', type: 'ComplianceRules', order: 2, category: 'related', assocCode: 'CRL'},
        {bn: 'EAY', name: 'Activity', type: 'Activity', order: 6, category: 'tertiary', assocCode: 'EAY'}
    ]};

PageNameToLayout['reports'] = {
    bn: 'ADQ',
    name: 'Reports',
    countAssocs: sideBarConfigs['24'].multi,
    contentBlocks: [
        {bn: 'ADQ', columnId: 0, order: 0, sectionBn: 'ADQ', size: 'LARGE', configuration: {bn: 'ADQ', contentBlockType: 'ADHOCREPORT_BROWSE', displayPivot: false}}
    ],
    sections: [
        {bn: 'ADQ', name: 'All Reports', type: 'AdHocReportBrowse', order: 0, category: 'primary', assocCode: 'ADQ'}
    ]
};

PageNameToLayout['optionlists'] = {
	    bn: 'DLS',
	    name: 'Option Lists',
	    countAssocs: sideBarConfigs['0K'].multi,
	    contentBlocks: [
	        {bn: 'DLS', columnId: 0, order: 0, sectionBn: 'DLS', size: 'LARGE', configuration: {bn: 'DLS', contentBlockType: 'OPTIONLIST_BROWSE', displayPivot: false}}
	    ],
	    sections: [
	        {bn: 'DLS', name: 'All Option Lists', type: 'OptionListBrowse', order: 0, category: 'primary', assocCode: 'DLS'}
	    ]
	};

PageNameToLayout['scorecard'] = {
    bn: '4A',
    name: 'Scorecard',
    countAssocs: sideBarConfigs['4A'].single,
    contentBlocks: [
        {bn: 'BASIC', columnId: 0, order: 0, sectionBn: 'BASIC', size: 'LARGE', configuration: {bn: 'BASIC', contentBlockType: 'SCORECARD_BASIC_INFO'}},
        {bn: 'PER', columnId: 0, order: 0, sectionBn: 'PER', size: 'LARGE', configuration: {bn: 'PER', contentBlockType: 'SCORECARD_PEOPLE'}},
        {bn: 'SCORECARDRESULTS', columnId: 0, order: 0, sectionBn: 'SCORECARDRESULTS', size: 'LARGE', configuration: {bn: 'SCORECARDRESULTS', contentBlockType: 'SCORECARD_RESULTS'}},
        complianceRuleAssociation,
        activityAssociation
    ],
    sections: [
        {bn: 'BASIC', name: 'Overview', type: 'ScorecardOverview', order: 0, category: 'primary'},
        //NOTE(Ryan): With the changes to tenant permissions and insight types we now use the insight type bn
        //to distinguish insight types. Because of this, we need to key off of the insight type bn
        {bn: '044S0000000C', name: 'Related Rules', type: 'ComplianceRules', order: 1, category: 'related',  assocCode: 'CRL', roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'SCORECARDRESULTS', name: 'Scorecard Results', type: 'ScorecardTable', order: 2, category: 'related'},
        {bn: 'PER', name: 'Contacts', type: 'People', order: 3, category: 'related'},
        {bn: 'EAY', name: 'Activity', type: 'Activity', order: 4, category: 'tertiary', assocCode: 'EAY'}
    ]
};

PageNameToLayout['scorecards'] = {
    bn: 'AUD',
    name: 'Scorecards',
    countAssocs: sideBarConfigs['4A'].multi,
    contentBlocks: [
        {bn: 'AUD', columnId: 0, order: 0, sectionBn: 'AUD', size: 'LARGE', configuration: {bn: 'AUD', contentBlockType: 'SCORECARD_BROWSE', displayPivot: false}}
    ],
    sections: [
        {bn: 'AUD', name: 'All Scorecards', type: 'ScorecardBrowse', order: 0, category: 'primary', assocCode: 'AUD'}
    ]
};

PageNameToLayout['tenantendpoints'] = {
    bn: 'TEP',
    name: 'Endpoints',
    countAssocs: sideBarConfigs['02'].multi,
    contentBlocks: [
        {bn: 'TEP', columnId: 0, order: 0, sectionBn: 'TEP', size: 'LARGE', configuration: {bn: 'TEP', contentBlockType: 'TENANTENDPOINT_BROWSE', displayPivot: false}}
    ],
    sections: [
        {bn: 'TEP', name: 'All Endpoints', type: 'TenantEndpointBrowse', order: 0, category: 'primary', assocCode: 'TEP'}
    ]
};

// PageNameToLayout['worksheets'] = {bn: 'WK2', name: 'Worksheets',
//   countAssocs: sideBarConfigs['4M'].multi,
//   contentBlocks: [
//     {bn: 'NEW_CB1', columnId: 0, order: 0, sectionBn: 'BROWSE', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'WORKSHEET2_BROWSE', displayPivot: false}},
//     documentAssociation
//   ],
//   sections: [
//     {bn: 'BROWSE', name: 'Overview', type: 'Worksheet2Browse', order: 0, category: 'primary', assocCode: 'WK2'}
//   ]};

PageNameToLayout['bubblecharts'] = {
    bn: '044S00000001',
    name: 'Bubble Charts',
    countAssocs: sideBarConfigs['4M'].multi,
    contentBlocks: [
        {bn: 'NEW_WK2_044S00000001', columnId: 0, order: 0, sectionBn: 'BROWSE', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'BUBBLE_CHARTS_BROWSE', displayPivot: false}}
    ],
    sections: [
        {bn: 'BROWSE', name: 'Overview', type: 'BubbleChartBrowse', order: 0, category: 'primary', assocCode: 'WK2'}
    ]
};
PageNameToLayout['fieldaggregations'] = {
    bn: '044S00000006',
    name: 'Field Aggregations',
    countAssocs: sideBarConfigs['4M'].multi,
    contentBlocks: [
        {bn: 'NEW_WK2_044S00000006', columnId: 0, order: 0, sectionBn: 'BROWSE', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'FIELD_AGGREGATION_BROWSE', displayPivot: false}}
    ],
    sections: [
        {bn: 'BROWSE', name: 'Overview', type: 'FieldAggregationBrowse', order: 0, category: 'primary', assocCode: 'WK2'}
    ]
};
PageNameToLayout['lifecycleroadmaps'] = {
    bn: '044S00000007',
    name: 'Lifecycle Roadmaps',
    countAssocs: sideBarConfigs['4M'].multi,
    contentBlocks: [
        {bn: 'NEW_WK2_044S00000007', columnId: 0, order: 0, sectionBn: 'BROWSE', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'LIFECYCLE_ROADMAP_BROWSE', displayPivot: false}}
    ],
    sections: [
        {bn: 'BROWSE', name: 'Overview', type: 'LifecycleRoadmapBrowse', order: 0, category: 'primary', assocCode: 'WK2'}
    ]
};
PageNameToLayout['matrices'] = {
    bn: '044S00000003',
    name: 'Capability Matrices',
    countAssocs: sideBarConfigs['4M'].multi,
    contentBlocks: [
        {bn: 'NEW_WK2_044S00000003', columnId: 0, order: 0, sectionBn: 'BROWSE', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'MATRIX_BROWSE', displayPivot: false}}
    ],
    sections: [
        {bn: 'BROWSE', name: 'Overview', type: 'MatrixBrowse', order: 0, category: 'primary', assocCode: 'WK2'}
    ]
};
PageNameToLayout['radialconnections'] = {
    bn: '044S00000008',
    name: 'Radial Connections',
    countAssocs: sideBarConfigs['4M'].multi,
    contentBlocks: [
        {bn: 'NEW_WK2_044S00000008', columnId: 0, order: 0, sectionBn: 'BROWSE', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'RADIAL_CONNECTION_BROWSE', displayPivot: false}}
    ],
    sections: [
        {bn: 'BROWSE', name: 'Overview', type: 'RadialConnectionBrowse', order: 0, category: 'primary', assocCode: 'WK2'}
    ]
};
PageNameToLayout['relationaldiagrams'] = {
    bn: '044S00000009',
    name: 'Relational Diagrams',
    countAssocs: sideBarConfigs['4M'].multi,
    contentBlocks: [
        {bn: 'NEW_WK2_044S00000009', columnId: 0, order: 0, sectionBn: 'BROWSE', size: 'LARGE', configuration: {bn: 'NEW_CONFIG1', contentBlockType: 'RELATIONAL_DIAGRAM_BROWSE', displayPivot: false}}
    ],
    sections: [
        {bn: 'BROWSE', name: 'Overview', type: 'RelationalDiagramBrowse', order: 0, category: 'primary', assocCode: 'WK2'}
    ]
};

PageNameToLayout['customdashboards'] = {
  bn: 'LYT',
  name: 'Dashboards',
  countAssocs: sideBarConfigs['1L'].multi,
  contentBlocks: [
    {bn: 'LYT', columnId: 0, order: 0, sectionBn: 'LYT', size: 'LARGE', configuration: {bn: 'LYT', contentBlockType: 'DASHBOARD_BROWSE', displayPivot: false}}
  ],
  sections: [
    {bn: 'LYT', name: 'All Dashboards', type: 'CustomDashboardBrowse', order: 0, category: 'primary', assocCode: 'LYT'}
  ]
};

// Mixed collection page layouts

var MixedCollectionNameToLayout = {};
MixedCollectionNameToLayout['myaudits'] = {bn: '1', name: 'My Audits',
    contentBlocks: [
        {bn: 'STR', columnId: 0, order: 0, sectionBn: 'STR', size: 'LARGE', configuration: {bn: 'STR', contentBlockType: 'STRATEGY_MYAUDITS'}, roles: 'ROLE_STRATEGY_MODULE_ACCESS, ROLE_AUDIT_MODULE_ACCESS'},
        {bn: 'DEM', columnId: 0, order: 1, sectionBn: 'DEM', size: 'LARGE', configuration: {bn: 'DEM', contentBlockType: 'DEMAND_MYAUDITS'}, roles: 'ROLE_DEMAND_MODULE_ACCESS, ROLE_AUDIT_MODULE_ACCESS'},
        {bn: 'ORG', columnId: 0, order: 2, sectionBn: 'ORG', size: 'LARGE', configuration: {bn: 'ORG', contentBlockType: 'ORGANIZATION_MYAUDITS'}, roles: 'ROLE_AUDIT_MODULE_ACCESS'},
        {bn: 'PER', columnId: 0, order: 3, sectionBn: 'PER', size: 'LARGE', configuration: {bn: 'PER', contentBlockType: 'PROFILE_MYAUDITS'}, roles: 'ROLE_AUDIT_MODULE_ACCESS'},
        {bn: 'COM', columnId: 0, order: 4, sectionBn: 'COM', size: 'LARGE', configuration: {bn: 'COM', contentBlockType: 'COMPANY_MYAUDITS'}, roles: 'ROLE_AUDIT_MODULE_ACCESS'},
        {bn: 'ACT', columnId: 0, order: 5, sectionBn: 'ACT', size: 'LARGE', configuration: {bn: 'ACT', contentBlockType: 'ACTOR_MYAUDITS'}, roles: 'ROLE_BLUEPRINT_MODULE_ACCESS, ROLE_AUDIT_MODULE_ACCESS'},
        {bn: 'CAP', columnId: 0, order: 6, sectionBn: 'CAP', size: 'LARGE', configuration: {bn: 'CAP', contentBlockType: 'CAPABILITY_MYAUDITS'}, roles: 'ROLE_BLUEPRINT_MODULE_ACCESS, ROLE_AUDIT_MODULE_ACCESS'},
        {bn: 'PRD', columnId: 0, order: 7, sectionBn: 'PRD', size: 'LARGE', configuration: {bn: 'PRD', contentBlockType: 'PRODUCT_MYAUDITS'}, roles: 'ROLE_BLUEPRINT_MODULE_ACCESS, ROLE_AUDIT_MODULE_ACCESS'},
        {bn: 'MKT', columnId: 0, order: 8, sectionBn: 'MKT', size: 'LARGE', configuration: {bn: 'MKT', contentBlockType: 'MARKET_MYAUDITS'}, roles: 'ROLE_BLUEPRINT_MODULE_ACCESS, ROLE_AUDIT_MODULE_ACCESS'},
        {bn: 'DAT', columnId: 0, order: 9, sectionBn: 'DAT', size: 'LARGE', configuration: {bn: 'DAT', contentBlockType: 'DATA_MYAUDITS'}, roles: 'ROLE_DATA_MODULE_ACCESS, ROLE_AUDIT_MODULE_ACCESS'},
        {bn: 'SYS', columnId: 0, order: 10, sectionBn: 'SYS', size: 'LARGE', configuration: {bn: 'SYS', contentBlockType: 'SYSTEM_MYAUDITS'}, roles: 'ROLE_BLUEPRINT_MODULE_ACCESS, ROLE_AUDIT_MODULE_ACCESS'},
        {bn: 'CON', columnId: 0, order: 11, sectionBn: 'CON', size: 'LARGE', configuration: {bn: 'CON', contentBlockType: 'CONNECTION_MYAUDITS'}, roles: 'ROLE_BLUEPRINT_MODULE_ACCESS, ROLE_AUDIT_MODULE_ACCESS'},
        {bn: 'TEC', columnId: 0, order: 12, sectionBn: 'TEC', size: 'LARGE', configuration: {bn: 'TEC', contentBlockType: 'TECHNOLOGY_MYAUDITS'}, roles: 'ROLE_AUDIT_MODULE_ACCESS'},
        {bn: 'STA', columnId: 0, order: 13, sectionBn: 'STA', size: 'LARGE', configuration: {bn: 'STA', contentBlockType: 'STANDARD_MYAUDITS'}, roles: 'ROLE_AUDIT_MODULE_ACCESS'},
        {bn: 'SKI', columnId: 0, order: 14, sectionBn: 'SKI', size: 'LARGE', configuration: {bn: 'SKI', contentBlockType: 'SKILL_MYAUDITS'}, roles: 'ROLE_AUDIT_MODULE_ACCESS'},
        {bn: 'PHY', columnId: 0, order: 15, sectionBn: 'PHY', size: 'LARGE', configuration: {bn: 'PHY', contentBlockType: 'PHYSICAL_MYAUDITS'}, roles: 'ROLE_BLUEPRINT_MODULE_ACCESS, ROLE_AUDIT_MODULE_ACCESS'},
        {bn: 'TAG', columnId: 0, order: 16, sectionBn: 'TAG', size: 'LARGE', configuration: {bn: 'TAG', contentBlockType: 'TAG_MYAUDITS'}, roles: 'ROLE_AUDIT_MODULE_ACCESS'},
        {bn: 'AST', columnId: 0, order: 17, sectionBn: 'AST', size: 'LARGE', configuration: {bn: 'AST', contentBlockType: 'DOCUMENT_MYAUDITS'}, roles: 'ROLE_AUDIT_MODULE_ACCESS'}
    ],
    sections: []
};

MixedCollectionNameToLayout['proposededits'] = {bn: '1', name: 'Proposed Edits',
    contentBlocks: [
        {bn: 'STR', columnId: 0, order: 0, sectionBn: 'STR', size: 'LARGE', configuration: {bn: 'STR', contentBlockType: 'STRATEGY_PROPOSED_EDITS'}, roles: 'ROLE_STRATEGY_MODULE_ACCESS'},
        {bn: 'DEM', columnId: 0, order: 1, sectionBn: 'DEM', size: 'LARGE', configuration: {bn: 'DEM', contentBlockType: 'DEMAND_PROPOSED_EDITS'}, roles: 'ROLE_DEMAND_MODULE_ACCESS'},
        {bn: 'ORG', columnId: 0, order: 2, sectionBn: 'ORG', size: 'LARGE', configuration: {bn: 'ORG', contentBlockType: 'ORGANIZATION_PROPOSED_EDITS'}, roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'COM', columnId: 0, order: 2, sectionBn: 'COM', size: 'LARGE', configuration: {bn: 'COM', contentBlockType: 'COMPANY_PROPOSED_EDITS'}},
        {bn: 'ACT', columnId: 0, order: 3, sectionBn: 'ACT', size: 'LARGE', configuration: {bn: 'ACT', contentBlockType: 'ACTOR_PROPOSED_EDITS'}, roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CAP', columnId: 0, order: 4, sectionBn: 'CAP', size: 'LARGE', configuration: {bn: 'CAP', contentBlockType: 'CAPABILITY_PROPOSED_EDITS'}, roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'PRD', columnId: 0, order: 5, sectionBn: 'PRD', size: 'LARGE', configuration: {bn: 'PRD', contentBlockType: 'PRODUCT_PROPOSED_EDITS'}, roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'MKT', columnId: 0, order: 6, sectionBn: 'MKT', size: 'LARGE', configuration: {bn: 'MKT', contentBlockType: 'MARKET_PROPOSED_EDITS'}, roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'DAT', columnId: 0, order: 7, sectionBn: 'DAT', size: 'LARGE', configuration: {bn: 'DAT', contentBlockType: 'DATA_PROPOSED_EDITS'}, roles: 'ROLE_DATA_MODULE_ACCESS'},
        {bn: 'SYS', columnId: 0, order: 8, sectionBn: 'SYS', size: 'LARGE', configuration: {bn: 'SYS', contentBlockType: 'SYSTEM_PROPOSED_EDITS'}, roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CON', columnId: 0, order: 9, sectionBn: 'CON', size: 'LARGE', configuration: {bn: 'CON', contentBlockType: 'CONNECTION_PROPOSED_EDITS'}, roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TEC', columnId: 0, order: 10, sectionBn: 'TEC', size: 'LARGE', configuration: {bn: 'TEC', contentBlockType: 'TECHNOLOGY_PROPOSED_EDITS'}},
        {bn: 'STA', columnId: 0, order: 11, sectionBn: 'STA', size: 'LARGE', configuration: {bn: 'STA', contentBlockType: 'STANDARD_PROPOSED_EDITS'}},
        {bn: 'SKI', columnId: 0, order: 12, sectionBn: 'SKI', size: 'LARGE', configuration: {bn: 'SKI', contentBlockType: 'SKILL_PROPOSED_EDITS'}},
        {bn: 'PHY', columnId: 0, order: 13, sectionBn: 'PHY', size: 'LARGE', configuration: {bn: 'PHY', contentBlockType: 'PHYSICAL_PROPOSED_EDITS'}, roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'AST', columnId: 0, order: 14, sectionBn: 'AST', size: 'LARGE', configuration: {bn: 'AST', contentBlockType: 'DOCUMENT_PROPOSED_EDITS'}},
        {bn: 'CIR', columnId: 0, order: 15, sectionBn: 'CIR', size: 'LARGE', configuration: {bn: 'CIR', contentBlockType: 'CIRCLE_PROPOSED_EDITS'}},
        {bn: 'WK2', columnId: 0, order: 16, sectionBn: 'WK2', size: 'LARGE', configuration: {bn: 'WK2', contentBlockType: 'WORKSHEET2_PROPOSED_EDITS'}}
    ],
    sections: []
};





MixedCollectionNameToLayout['search'] = {bn: '1', name: 'Search Results',
    contentBlocks: [
        {bn: 'STR', columnId: 0, order: 0, sectionBn: 'STR', size: 'LARGE', configuration: {bn: 'STR', contentBlockType: 'STRATEGY_BROWSE', displayPivot: false}, roles: 'ROLE_STRATEGY_MODULE_ACCESS'},
        {bn: 'DEM', columnId: 0, order: 1, sectionBn: 'DEM', size: 'LARGE', configuration: {bn: 'DEM', contentBlockType: 'DEMAND_BROWSE', displayPivot: false}, roles: 'ROLE_DEMAND_MODULE_ACCESS'},
        {bn: 'ORG', columnId: 0, order: 2, sectionBn: 'ORG', size: 'LARGE', configuration: {bn: 'ORG', contentBlockType: 'ORGANIZATION_BROWSE', displayPivot: false}},
        {bn: 'PER', columnId: 0, order: 3, sectionBn: 'PER', size: 'LARGE', configuration: {bn: 'PER', contentBlockType: 'PROFILE_BROWSE', displayPivot: false}},
        {bn: 'COM', columnId: 0, order: 4, sectionBn: 'COM', size: 'LARGE', configuration: {bn: 'COM', contentBlockType: 'COMPANY_BROWSE', displayPivot: false}},
        {bn: 'ACT', columnId: 0, order: 5, sectionBn: 'ACT', size: 'LARGE', configuration: {bn: 'ACT', contentBlockType: 'ACTOR_BROWSE', displayPivot: false}, roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CAP', columnId: 0, order: 6, sectionBn: 'CAP', size: 'LARGE', configuration: {bn: 'CAP', contentBlockType: 'CAPABILITY_BROWSE', displayPivot: false}, roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'PRD', columnId: 0, order: 7, sectionBn: 'PRD', size: 'LARGE', configuration: {bn: 'PRD', contentBlockType: 'PRODUCT_BROWSE', displayPivot: false}, roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'MKT', columnId: 0, order: 8, sectionBn: 'MKT', size: 'LARGE', configuration: {bn: 'MKT', contentBlockType: 'MARKET_BROWSE', displayPivot: false}, roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'DAT', columnId: 0, order: 9, sectionBn: 'DAT', size: 'LARGE', configuration: {bn: 'DAT', contentBlockType: 'DATA_BROWSE', displayPivot: false}, roles: 'ROLE_DATA_MODULE_ACCESS'},
        {bn: 'SYS', columnId: 0, order: 10, sectionBn: 'SYS', size: 'LARGE', configuration: {bn: 'SYS', contentBlockType: 'SYSTEM_BROWSE', displayPivot: false}, roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'CON', columnId: 0, order: 11, sectionBn: 'CON', size: 'LARGE', configuration: {bn: 'CON', contentBlockType: 'CONNECTION_BROWSE', displayPivot: false}, roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TEC', columnId: 0, order: 12, sectionBn: 'TEC', size: 'LARGE', configuration: {bn: 'TEC', contentBlockType: 'TECHNOLOGY_BROWSE', displayPivot: false}},
        {bn: 'STA', columnId: 0, order: 13, sectionBn: 'STA', size: 'LARGE', configuration: {bn: 'STA', contentBlockType: 'STANDARD_BROWSE', displayPivot: false}},
        {bn: 'SKI', columnId: 0, order: 14, sectionBn: 'SKI', size: 'LARGE', configuration: {bn: 'SKI', contentBlockType: 'SKILL_BROWSE', displayPivot: false}},
        {bn: 'PHY', columnId: 0, order: 15, sectionBn: 'PHY', size: 'LARGE', configuration: {bn: 'PHY', contentBlockType: 'PHYSICAL_BROWSE', displayPivot: false}, roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
        {bn: 'TAG', columnId: 0, order: 16, sectionBn: 'TAG', size: 'LARGE', configuration: {bn: 'TAG', contentBlockType: 'TAG_BROWSE', displayPivot: false}},
        {bn: 'AST', columnId: 0, order: 17, sectionBn: 'AST', size: 'LARGE', configuration: {bn: 'AST', contentBlockType: 'DOCUMENT_BROWSE', displayPivot: false}},
        {bn: 'CIR', columnId: 0, order: 18, sectionBn: 'CIR', size: 'LARGE', configuration: {bn: 'CIR', contentBlockType: 'WORKSPACE_BROWSE', displayPivot: false}},
        {bn: 'ADQ', columnId: 0, order: 19, sectionBn: 'ADQ', size: 'LARGE', configuration: {bn: 'ADQ', contentBlockType: 'ADHOCREPORT_BROWSE', displayPivot: false}},
        {bn: '044S0000000C', columnId: 0, order: 20, sectionBn: '044S0000000C', size: 'LARGE', configuration: {bn: '044S0000000C', contentBlockType: 'COMPLIANCE_BROWSE', displayPivot: false}},
        {bn: 'AUD', columnId: 0, order: 21, sectionBn: 'AUD', size: 'LARGE', configuration: {bn: 'AUD', contentBlockType: 'AUDIT_BROWSE', displayPivot: false}, roles: 'ROLE_AUDIT_MODULE_ACCESS'},
        {bn: 'LYT', columnId: 0, order: 22, sectionBn: 'LYT', size: 'LARGE', configuration: {bn: 'LYT', contentBlockType: 'DASHBOARD_BROWSE', displayPivot: false}},
        {bn: 'WK2', columnId: 0, order: 23, sectionBn: 'WK2', size: 'LARGE', configuration: {bn: 'WK2', contentBlockType: 'WORKSHEET2_BROWSE', displayPivot: false}},
        // {bn: 'WK2', columnId: 0, order: 24, sectionBn: 'WK2', size: 'LARGE', configuration: {bn: 'WK2', contentBlockType: 'BUBBLE_CHARTS_BROWSE', displayPivot: false}},
    ],
    sections: []
};

// Layout for Enterprise Overview
MixedCollectionNameToLayout['enterprise'] = {bn: '1', name: 'Enterprise',
    contentBlocks: [
      // SHOULD BE 16
      // Strategies
      {bn: 'STR', columnId: 0, order: 1, sectionBn: 'STR', size: 'LARGE', configuration: {bn: 'STR', contentBlockType: 'STRATEGY_BROWSE'}, roles: 'ROLE_STRATEGY_MODULE_ACCESS'},
      // Demands
      {bn: 'DEM', columnId: 0, order: 2, sectionBn: 'DEM', size: 'LARGE', configuration: {bn: 'DEM', contentBlockType: 'DEMAND_BROWSE'}, roles: 'ROLE_DEMAND_MODULE_ACCESS'},
      // Organizations
      {bn: 'ORG', columnId: 0, order: 3, sectionBn: 'ORG', size: 'LARGE', configuration: {bn: 'ORG', contentBlockType: 'ORGANIZATION_BROWSE'}},
      // People
      {bn: 'PER', columnId: 0, order: 4, sectionBn: 'PER', size: 'LARGE', configuration: {bn: 'PER', contentBlockType: 'PROFILE_BROWSE'}},
      // Companies
      {bn: 'COM', columnId: 0, order: 5, sectionBn: 'COM', size: 'LARGE', configuration: {bn: 'COM', contentBlockType: 'COMPANY_BROWSE'}},
      // Actors
      {bn: 'ACT', columnId: 0, order: 6, sectionBn: 'ACT', size: 'LARGE', configuration: {bn: 'ACT', contentBlockType: 'ACTOR_BROWSE'}, roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
      // Capabilities
      {bn: 'CAP', columnId: 0, order: 7, sectionBn: 'CAP', size: 'LARGE', configuration: {bn: 'CAP', contentBlockType: 'CAPABILITY_BROWSE'}, roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
      // Products
      {bn: 'PRD', columnId: 0, order: 8, sectionBn: 'PRD', size: 'LARGE', configuration: {bn: 'PRD', contentBlockType: 'PRODUCT_BROWSE'}, roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
      // Markets
      {bn: 'MKT', columnId: 0, order: 9, sectionBn: 'MKT', size: 'LARGE', configuration: {bn: 'MKT', contentBlockType: 'MARKET_BROWSE'}, roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
      // Data
      {bn: 'DAT', columnId: 0, order: 10, sectionBn: 'DAT', size: 'LARGE', configuration: {bn: 'DAT', contentBlockType: 'DATA_BROWSE'}, roles: 'ROLE_DATA_MODULE_ACCESS'},
      // Systems
      {bn: 'SYS', columnId: 0, order: 11, sectionBn: 'SYS', size: 'LARGE', configuration: {bn: 'SYS', contentBlockType: 'SYSTEM_BROWSE'}, roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
      // Connections
      {bn: 'CON', columnId: 0, order: 12, sectionBn: 'CON', size: 'LARGE', configuration: {bn: 'CON', contentBlockType: 'CONNECTION_BROWSE'}, roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'},
      // Technologies
      {bn: 'TEC', columnId: 0, order: 13, sectionBn: 'TEC', size: 'LARGE', configuration: {bn: 'TEC', contentBlockType: 'TECHNOLOGY_BROWSE'}},
      // Standards
      {bn: 'STA', columnId: 0, order: 14, sectionBn: 'STA', size: 'LARGE', configuration: {bn: 'STA', contentBlockType: 'STANDARD_BROWSE'}},
      // Skills
      {bn: 'SKI', columnId: 0, order: 15, sectionBn: 'SKI', size: 'LARGE', configuration: {bn: 'SKI', contentBlockType: 'SKILL_BROWSE'}},
      // Deployments
      {bn: 'PHY', columnId: 0, order: 16, sectionBn: 'PHY', size: 'LARGE', configuration: {bn: 'PHY', contentBlockType: 'PHYSICAL_BROWSE'}, roles: 'ROLE_BLUEPRINT_MODULE_ACCESS'}
    ],
    sections: []
};

// Layout for Insight Overview
MixedCollectionNameToLayout['insight'] = {bn: '1', name: 'Insight',
  contentBlocks: [
    {bn: 'DAY', columnId: 0, order: 0, sectionBn: 'DAY', category: 'primary', size: 'LARGE', configuration: {bn: 'DAY', contentBlockType: 'ACTIVITY_ASSOCIATIONS'}},
    // Dashboards
    {bn: 'LYT', columnId: 0, order: 1, sectionBn: 'LYT', size: 'LARGE', configuration: {bn: 'LYT', contentBlockType: 'DASHBOARD_BROWSE', displayPivot: false}},
    // Worksheets
    //{bn: 'WK2', columnId: 0, order: 2, sectionBn: 'WK2', size: 'LARGE', configuration: {bn: 'WK2', contentBlockType: 'WORKSHEET2_BROWSE', displayPivot: false}},
    // Scorecards
    {bn: 'AUD', columnId: 0, order: 3, sectionBn: 'AUD', size: 'LARGE', configuration: {bn: 'AUD', contentBlockType: 'AUDIT_BROWSE', displayPivot: false}},
    // Rules
    {bn: '044S0000000C', columnId: 0, order: 4, sectionBn: '044S0000000C', size: 'LARGE', configuration: {bn: '044S0000000C', contentBlockType: 'COMPLIANCE_BROWSE', displayPivot: false}},
    // Reports
    {bn: 'ADQ', columnId: 0, order: 5, sectionBn: 'ADQ', size: 'LARGE', configuration: {bn: 'ADQ', contentBlockType: 'ADHOCREPORT_BROWSE', displayPivot: false}},
    // Workspaces
    {bn: 'CIR', columnId: 0, order: 6, sectionBn: 'CIR', size: 'LARGE', configuration: {bn: 'CIR', contentBlockType: 'WORKSPACE_BROWSE', displayPivot: false}},
    // Documents
    {bn: 'AST', columnId: 0, order: 7, sectionBn: 'AST', size: 'LARGE', configuration: {bn: 'AST', contentBlockType: 'DOCUMENT_BROWSE'}},
    // Tags
    {bn: 'TAG', columnId: 0, order: 8, sectionBn: 'TAG', size: 'LARGE', configuration: {bn: 'TAG', contentBlockType: 'TAG_BROWSE'}}
  ],
  sections: []
};
