
angular.module('barometerApp.nav')
  .controller('PrimaryNavCtrl', ['$scope', 'primaryNavService', 'priorityEntitiesService', 'urlService', 'utilService',
    function ($scope, primaryNavService, priorityEntitiesService, urlService, utilService) {
      $scope.COLUMN_SIZE = 8;
      $scope.showAllEnterpriseEntities = false;
      $scope.navItems = [];
      var visibleEnterpriseEntityNavItems;

      const unsubscribe = priorityEntitiesService.listenForPriorityEntities((priorityEntities) => {
        $scope.showAllEnterpriseEntities = priorityEntities.length === 16 || priorityEntities.length === 0;
        $scope.navItems = $scope.navItems.map(navItem => {
          return { ...navItem, isPriorityEntityPage: isPriorityEntityPage(navItem.url) }
        });
        setVisibleEnterpriseEntities($scope.showAllEnterpriseEntities);
      });

      $scope.$on('$destory', unsubscribe);

      function isPriorityEntityPage(pageIdentifier) {
        return priorityEntitiesService.isPriorityEntityType(PageIdentifierToEntityTypeCode[pageIdentifier]);
      }

      function isEnterpriseEntityPage(pageIdentifier) {
        return urlService.isEnterprisePage(PageIdentifierToEntityTypeCode[pageIdentifier]);
      }

      function setVisibleEnterpriseEntities(showAll) {
        if (showAll) {
          visibleEnterpriseEntityNavItems = _.filter($scope.navItems, {
            isEnterpriseEntityPage: true
          });
        } else {
          visibleEnterpriseEntityNavItems = _.filter($scope.navItems, {
            isEnterpriseEntityPage: true,
            isPriorityEntityPage: true
          });
        }
        $scope.enterpriseNavItemsColumnOne = visibleEnterpriseEntityNavItems.slice(0, $scope.COLUMN_SIZE);
        $scope.enterpriseNavItemsColumnTwo = visibleEnterpriseEntityNavItems.slice($scope.COLUMN_SIZE);
      }

      primaryNavService.getPrimaryNav().then(function (data) {
        return data.data;
      }).then(function (data) {
        // Hydrate nav item data with section and icon details.  These are controlled client side.
        $.each(data, function () {
          var details;
          if(utilService.isBn(this.type)) {
            details = UrlToIconAndSection[this.type];
          } else {
            details = UrlToIconAndSection[this.url];
          }
          if (!details) {
            console.error('Missing IconAndSection info for ' + this.url);
          } else {
            this.iconClass = details[0];
            this.sectionId = details[1];
            this.order = details[2];
            this.isPriorityEntityPage = isPriorityEntityPage(this.url);
            this.isEnterpriseEntityPage = isEnterpriseEntityPage(this.url)
          }
        });
        $scope.navItems = data;

        setVisibleEnterpriseEntities($scope.showAllEnterpriseEntities);
      });

      $scope.sectionHasNavItems = function (sectionId) {
        return _.filter($scope.navItems, function (navItem) {
            return navItem.sectionId === sectionId;
          }).length > 0;
      };

      $scope.showAllEnterpriseEntitiesClicked = function (event) {
        $scope.showAllEnterpriseEntities = !$scope.showAllEnterpriseEntities;
        setVisibleEnterpriseEntities($scope.showAllEnterpriseEntities);
        event.preventDefault();
        event.stopImmediatePropagation();
      };
    }]);
