angular.module('barometerApp.table')
    .directive('editableTable', [
      '$modal',
      'layoutService',
      'tableService',
      'fieldService',
      'filterService',
      'bitConstants',
      'utilService',
      'pageService',
      'urlService',
      'domainFilterService',
      '$rootScope',
      '$timeout',
      'commonWorksheetService',
      'styleService',
      'redux',
      function (
          $modal,
          layoutService,
          tableService,
          fieldService,
          filterService,
          bitConstants,
          utilService,
          pageService,
          urlService,
          domainFilterService,
          $rootScope,
          $timeout,
          commonWorksheetService,
          styleService,
          redux
      ) {
        return {
          templateUrl: function (tElement, tAttrs) {

            // PAJ 9/5/13: this is used for AddByBrowse (I believe that's the only place)
            var tmplUrl = "/b/js/src/bit.ng/table/partials/table-add-by-browse.html";
            if (tAttrs.tableType.toLowerCase() === "association" || tAttrs.tableType.toLowerCase() === "multitypebrowse" || tAttrs.tableType.toLowerCase() === "customdashboard") {
              if (tAttrs.templateType && tAttrs.templateType.toLowerCase() === "quickadd") {
                tmplUrl = "/b/js/src/bit.ng/table/partials/table-quick-add.html"
              } else {
                tmplUrl = "/b/js/src/bit.ng/table/partials/table-editable.html"
              }
            } else if (tAttrs.tableType.toLowerCase() === "associationeditor") {
              tmplUrl = "/b/js/src/bit.ng/table/partials/table-assoc-editor.html";
            } else if (tAttrs.tableType.toLowerCase() === "auditresults") {
              tmplUrl = "/b/js/src/bit.ng/table/partials/table-scorecard.html";
            }
            return tmplUrl;
          },
          replace: true,
          scope: {
            entityBn: '=',
            targetBn: '=',
            entityTypeCode: '=',
            insightTypeBn: '=',
            paginationData: '@',
            filter: '=',
            relationshipType: '@',
            tableType: '=',
            options: '=',
            sort: '=',
            lazyLoad: '@',
            tableId: '=',
            useDefaultQuery: '='
          },
          controller: function ($scope, $element, $attrs) {
            //Default lazy loading to true
            var includeMetaData = true;
            $scope.openDialog = tableService.openDialog;
            var opts = angular.extend({
              displayAdd: true,
              displayFilters: true,
              displayPivot: true,
              associationDetailType: "associationDetails"
            }, $scope.options || {});

            $scope.editableTableModel = {
              rows: {},
              columns: {},
              filter: $scope.filter,
              lazyLoad: typeof $scope.lazyLoad === "undefined" ? true : $scope.lazyLoad,
              entityBn: $scope.entityBn,
              targetBn: $scope.targetBn,
              queryKey: urlService.getQueryKey(),
              entityTypeCode: $scope.entityTypeCode,
              insightTypeBn: $scope.insightTypeBn,
              pagination: $scope.paginationData ? $.parseJSON($scope.paginationData) : {pageSize: 10, maxSize: 0},
              assocDetailPagination: $scope.paginationData ? $.parseJSON($scope.paginationData) : {
                pageSize: 10,
                maxSize: 0
              },
              associationDetailParams: {},
              associationDetailsDetails: {
                entityLink: {},
                entityIcon: {}
              },
              relationshipType: $scope.relationshipType || null,
              sort: $scope.sort || {},
              sortClass: [],
              options: opts,
              typeaheadFilter: {},
              superSelectorFilter: {},
              defaultSelect2Options: {
                allowClear: true
              },
              associationDetail: {},
              associationDetails: {},
              loaded: false,
              isInitialLoad: true,
              assocDetailVisible: false,
              assocDetailPosition: {top: 0, left: 0},
              tableType: $scope.tableType,
              tableId: $scope.tableId,
              loading: true,
              activeRow: null,
              pivotCount: 0,
              useDefaultQuery: $scope.useDefaultQuery
            };

            $scope.editableTableModel.associationDetailTemplateUrl = $scope.editableTableModel.options.associationDetailType === "associationMulti" ? "/b/js/src/bit.ng/table/partials/association-multi.html" : "/b/js/src/bit.ng/table/partials/association-detail.html";

            if ($scope.editableTableModel.insightTypeBn != null) {
              $scope.editableTableModel.sourceEntity = $scope.editableTableModel.entityTypeCode ? bitConstants.getEntityTypeForTypeCode($scope.editableTableModel.entityTypeCode) : null;
              $scope.editableTableModel.entityIconClass = InsightTypeBnsToIconClass[$scope.editableTableModel.insightTypeBn];
            } else {
              $scope.editableTableModel.sourceEntity = $scope.editableTableModel.entityTypeCode ? bitConstants.getEntityTypeForTypeCode($scope.editableTableModel.entityTypeCode) : null;
              $scope.editableTableModel.entityIconClass = $scope.editableTableModel.sourceEntity ? EntityUtils.icons[EntityBnCodeToIconClass[$scope.editableTableModel.sourceEntity.bnCode]] : null;
            }

            $scope.init = function () {
              // dynamically add reducers to redux store
              const { store, reducers } = redux;
              const { relationshipEditor, relationshipFlyout } = reducers;
              store.manager.addMany({
                relationshipEditor,
                relationshipFlyout,
              });
              if ($scope.lazyLoad === "false") {
                $scope.loadPage(1);
              }
            };
            $rootScope.$on("loadSection", function (event, sectionBn, forceReload) {
              var matchesSectionBn = $scope.tableId && $scope.tableId.substring(0, sectionBn.length) === sectionBn;
              if (matchesSectionBn && (!$scope.editableTableModel.loaded || forceReload)) {
                $scope.editableTableModel.loaded = true;
                $scope.loadPage(1);
              }
            });

            $rootScope.$on("showNewEditor", function (event) {
              $scope.loadPage(1);
            });

            tableService.initTableId({tableId: $scope.tableId, relationshipType: $scope.relationshipType});

            tableService.setSourceBn($scope.editableTableModel.entityBn);
            tableService.setPagination($scope.editableTableModel.pagination);

            //association table type can be singleTypeBrowse if it's a collection page:
            if ($attrs.tableType !== "association") {
              $scope.editableTableModel.tableType = $attrs.tableType;
            } else {
              $scope.editableTableModel.tableType = pageService.isEntityPage() ? "association" : "singleTypeBrowse";
            }

            // handle filter changes
            var filterChanged = function () {

              // quit using the default filter once it's been changed:
              $scope.editableTableModel.useDefaultQuery = false;

              // broadcast an event - used to synch up other instances of this same filter (summary view vs table view)
              if ($scope.editableTableModel.tableType !== 'addByBrowse') {
                $rootScope.$broadcast("filterChanged", {
                  filter: $scope.editableTableModel.filter,
                  tableId: $scope.tableId
                });
              }

              // reload data
              $scope.loadPage(1);
            };

            // domain filter setup to tie it to the section's domain selector
            function initDomainFilter(editableTableData) {
              var
                  selectedDomains = [],
                  domainDataListBn = '',
                  domainFilterSpec = {};

              if (editableTableData.data.filterParams && editableTableData.data.filterParams.params.length) {
                domainDataListBn = Object.keys(editableTableData.data.filterParams.params[0])[0];  // TODO: un-FUCK this
              }

              // Getting domainFilterSpec seems buggy. This conditional may be a temporary solution. (BARO-18195)
              // The two models (below) are semi-synonymous.
              // Leaving this block until we get to the real heart of the problems.
              if ($scope.editableTableModel.filter && $scope.editableTableModel.filter.specification) {
                // Original technique. Sometimes this is unexpectedly undefined.
                // Get domainFilterSpec from our working scope.
                domainFilterSpec = _.find($scope.editableTableModel.filter.specification, function (filter) {
                  return filter.key === "domain.bn";
                });
              } else if (editableTableData.data.filterSpec && editableTableData.data.filterSpec.criteria) {
                // Fallback technique. Sometimes this is undefined because we told the server we didn't need it.
                // Get domainFilterSpec from the fresh payload we just received from the server.
                domainFilterSpec = _.find(editableTableData.data.filterSpec.criteria, function (filter) {
                  return filter.key === "domain.bn";
                });
              }

              if (domainFilterSpec) {
                // we want to hide these filters in filterBars everywhere except addByBrowse tables
                // (they will still be part of the model in all cases)
                if ($scope.editableTableModel.tableType !== "addByBrowse") {
                  domainFilterSpec.hide = "hide";
                }

                // set initial state of domainFilterService if necessary
                if (!domainFilterService.getDomainFilter($scope.tableId)) {

                  // gather any already-selected domains
                  _.each(domainFilterSpec.options, function (option) {
                    _.each($scope.editableTableModel.filter.selected, function (dataListBns) {
                      _.each(dataListBns, function (dataListBn) {
                        if (dataListBn === option.value) {
                          selectedDomains.push(dataListBn);
                          domainDataListBn = dataListBn;
                        }
                      });
                    })
                  });

                  domainFilterService.setDomainFilter($scope.tableId, {
                    filterSpec: domainFilterSpec,
                    selected: selectedDomains,
                    entityTypeCode: $scope.editableTableModel.entityTypeCode,
                    dataListBn: domainDataListBn
                  });
                }
              }
            }

            $scope.$on('domainFilterChanged', function (event, tableId) {
              if (tableId === $scope.tableId) {
                // set the selected domain(s) on the table model and reload
                var domainFilter = domainFilterService.getDomainFilter(tableId);
                if (domainFilter) {
                  // Just changing filter.selected triggers a reload of the dependent panel; see $watch below
                  $scope.editableTableModel.filter.selected[domainFilter.filterSpec.filterBn] = domainFilter.selected;
                }
              }
            });

            $scope.$watch('filter.selected', function (newValue, oldValue) {

              if (typeof newValue === "undefined" || angular.equals(newValue == oldValue)) return;
              //checking to see if the table filter has changed since the initial filter passed down.
              var diff = utilService.getDifference(newValue, $scope.editableTableModel.originalFilter);

              if (!_.isEmpty(diff) || !$scope.editableTableModel.isInitialLoad) {
                $scope.editableTableModel.isInitialLoad = false;
                filterChanged();
              }
            }, true);

            $scope.$watch('filter.qualifier', function (newValue, oldValue) {
              if ((!newValue && !oldValue) || angular.equals(newValue, oldValue)) {
                return;
              }
              filterChanged();
            });

            $scope.$watch('filter.searchString', function (newValue, oldValue) {
              if ((!newValue && !oldValue) || angular.equals(newValue, oldValue)) {
                return;
              }
              filterChanged();
            });

            // This function listens th the Redux store and waits for the
            // relationship editor's "requested" state to be false. Then it
            // reloads the page.
            redux.store.subscribe(()=> {
              if (!redux.store.getState().relationshipEditor.isOpen && window.location.href.includes('newRelationshipEditor')) {
                $scope.loadPage(1);
              }
            });

            $scope.loadPage = function (pageNumber) {
              // Show New Editor
              $scope.editableTableModel.relationshipEditorVisibility = redux.store.getState().relationshipEditor.isOpen;

              $scope.editableTableModel.loading = true;

              // reset popovers that could be open (if this load came from a filter or pagination change)
              setPopoverVisibility(false, true);
              cleanupDetailPopover();

              $scope.clearAssociationTypes();
              $scope.editableTableModel.pagination.currentPage = pageNumber;
              var queryParams = {
                sourceBn: $scope.editableTableModel.entityBn,
                targetTypeBn: $scope.editableTableModel.entityTypeCode ? bitConstants.getEntityTypeForTypeCode($scope.editableTableModel.entityTypeCode).bnCode : null,
                targetBn: $scope.editableTableModel.targetBn,
                queryKey: $scope.editableTableModel.queryKey,
                parentEntity: $scope.editableTableModel.options.parentEntity,
                relationshipType: $scope.editableTableModel.relationshipType,
                first: 0,
                count: $scope.editableTableModel.pagination ? $scope.editableTableModel.pagination.pageSize : 10,
                sortProperty: $scope.editableTableModel.sort ? $scope.editableTableModel.sort.sortProperty : null,
                ascending: $scope.editableTableModel.sort ? $scope.editableTableModel.sort.ascending : null,
                tableType: $scope.editableTableModel.tableType,
                insightTypeBn: $scope.editableTableModel.insightTypeBn,

                //converting filterParams.params object to JSON
                // requires the filterGroups param, which later gets dropped off before sending it to the service.
                includeFilterSpec: includeMetaData && $scope.editableTableModel.tableType !== "associationDetails" && $scope.editableTableModel.tableType !== "associationEditor",
                includeColumnSpec: includeMetaData,
                useDefaultQuery: $scope.editableTableModel.useDefaultQuery
              }, entityTypeCode = urlService.getEntityTypeCodeOfCurrentPage();

              //
              if ($scope.editableTableModel.filter && (!$scope.editableTableModel.options.parentEntity || !$scope.editableTableModel.options.parentEntity.bn)) {
                if ($scope.editableTableModel.options.predefinedFilter) {
                  // We have to mutate existing filter object here so that 2-way binding will work for the rest of the filter properties
                  $scope.editableTableModel.filter = Object.assign($scope.editableTableModel.filter, $scope.editableTableModel.options.predefinedFilter);
                }
                queryParams = angular.extend(queryParams, {
                  filterParams: $scope.editableTableModel.filter ? filterService.convertFilterObjectToFilterArray($scope.editableTableModel.filter) : {},
                  qualifier: $scope.editableTableModel.filter.qualifier && $scope.editableTableModel.filter.qualifier.length > 0 ? $scope.editableTableModel.filter.qualifier : null
                });
              }

              queryParams.first = (pageNumber - 1) * queryParams.count;
              const entityType = bitConstants.getEntityTypeForTypeCode(entityTypeCode);
              queryParams.sourceBnCode = entityType ? entityType.bnCode : null;
              //
              // Execute the query and process the results.
              // Mostly: Unpack results (editableTableData) into $scope.editableTableModel.
              getAssociationData(queryParams, function (editableTableData) {

                $scope.editableTableModel.loading = false;

                //BARO-16882: (Hack) Set all fields to visible when a user is viewing the field browse table.
                editableTableData = fieldService.forceEditableControlLevelForBrowseTable($scope.editableTableModel, editableTableData);

                if (includeMetaData) {
                  $scope.editableTableModel.columns = editableTableData.data.columnSpec;
                  _.each($scope.editableTableModel.columns, function (col) {
                    $scope.editableTableModel.sortClass[col.sortProperty] = col.id === "entity" ? 'icon-sort-down' : 'icon-sort';
                    $scope.editableTableModel.sort.ascending = true;
                    col.ascending = col.id === "entity";
                  });
                  if ($scope.editableTableModel.filter) {
                    var filterSpec = editableTableData.data.filterSpec ? editableTableData.data.filterSpec.criteria : {},
                        filters = editableTableData.data.filterParams ? editableTableData.data.filterParams : {},
                        filterObj = filterService.convertFilterArrayToFilterObject(filters.params);
                    var filterSelected = angular.copy(filterObj);
                    var filterObjKeys = Object.keys(filterObj);

                    filterObjKeys?.forEach(key => {
                      var defaultKeyInOptions = filterSpec.find(spec => spec.options?.find(opt => opt.value === key));
                      if (defaultKeyInOptions){
                        // check if the default filter key is still a valid option
                        const filterKey = filterObj[key];
                        const [firstVal] = filterKey;
                        if (!defaultKeyInOptions.options.find(opt => opt.name === firstVal)) {
                          console.warn('the default query key no longer exists, update the optionlists');
                          filterSelected[key] = []; // if the default option selected from db, no longer exists, set this to empty
                        }
                      }
                    });

                    $scope.editableTableModel.filter.specification = filterSpec;
                    $scope.editableTableModel.filter.qualifier = filters.qualifier;
                    $scope.editableTableModel.filter.searchString = filters.searchString;
                    $scope.editableTableModel.filter.selected = filterSelected;
                    $scope.editableTableModel.originalFilter = angular.copy(filterObj);

                    $scope.editableTableModel.filter.isLoading = false;

                    $rootScope.$broadcast("InitialFilter", {
                      tableId: $scope.tableId,
                      filter: $scope.editableTableModel.filter
                    });
                  }
                  includeMetaData = false;
                }
                $scope.editableTableModel.pagination.rowCount = editableTableData.data.rowCount || 0;
                $scope.editableTableModel.pagination.unfilteredRowCount = editableTableData.data.unfilteredRowCount || 0;
                $scope.editableTableModel.pagination.noOfPages = Math.ceil(editableTableData.data.rowCount / $scope.editableTableModel.pagination.pageSize);
                $scope.editableTableModel.pagination.numRecords = editableTableData.data.rows.length;
                $scope.editableTableModel.rows = editableTableData.data.rows;
                $scope.editableTableModel.loading = false;
                $scope.editableTableModel.pivotCount = editableTableData.data.pivotCount;

                initDomainFilter(editableTableData);
              });

            };
            $scope.loadAssociationDetails = function (pageNumber, closePopover) {   // @param ADD ROW ID / NUMBER?

              $scope.editableTableModel.assocDetailPagination.currentPage = pageNumber;
              $scope.editableTableModel.associationDetailParams.first = (pageNumber - 1) * $scope.editableTableModel.associationDetailParams.count;


              Object.keys($scope.editableTableModel).forEach(k => console.log('Need to get insightTypeBn: ',k , $scope.editableTableModel[k]));

              getAssociationData($scope.editableTableModel.associationDetailParams, function (associationDetail) {

                $scope.editableTableModel.associationDetail = associationDetail.data.rows.length > 0 ? associationDetail.data : null;
                $scope.editableTableModel.assocDetailPagination.rowCount = associationDetail.data.rowCount || 0;

                //setting to rowCount will allow us to hide the (Total :###), which we don't need for assoc details, and isn't returned
                $scope.editableTableModel.assocDetailPagination.unfilteredRowCount = associationDetail.data.rowCount || 0;
                $scope.editableTableModel.assocDetailPagination.noOfPages = Math.ceil(associationDetail.data.rowCount / $scope.editableTableModel.assocDetailPagination.pageSize);
                $scope.editableTableModel.assocDetailPagination.numRecords = associationDetail.data.rows.length;

                // set the details entity name "e.g. Related to 2 Systems", where "Systems" is the entityname
                // set to the displayNamePlural if rows.length is anything but 1
                $scope.editableTableModel.associationDetailsDetails.sourceEntityName = bitConstants.getEntityTypeForTypeCode($scope.editableTableModel.entityTypeCode)[associationDetail.data.rows.length == 1 ? 'displayName' : 'displayNamePlural'];

                // This assumes (as we do elsewhere in this file) that the 'parent' type for the table is defined by the URL of the page we are on.
                // If we ever try to, e.g., put a technology->system table in a popup on a person page, this will fail.
                var targetTypeCode = urlService.getEntityTypeCodeOfCurrentPage();
                $scope.editableTableModel.associationDetailsDetails.targetEntityName = !targetTypeCode ? null : bitConstants.getEntityTypeForTypeCode(targetTypeCode)[associationDetail.data.rowCount == 1 ? 'displayName' : 'displayNamePlural'];

                // popup management, delayed to allow rendering of contents first
                if (closePopover) {
                  $timeout(function () {
                    preparePopover();
                  })
                }
              });
            };
            var preparePopover = function () {
              var
                  activeCell = $element.find('tr.active td:last'),
                  activeCellPos = activeCell.position() || {},
                  popoverPos = {
                    top: activeCellPos.top + activeCell.outerHeight() - 12,
                    right: activeCell.width() / 2 + 4
                  };
              if (!tableService.getActiveRow($scope.tableId)) {
                setPopoverVisibility(false, true);
              } else {
                setPopoverVisibility(true, false);
              }
              $scope.editableTableModel.assocDetailPosition = popoverPos;
              $scope.editableTableModel.assocDetailVisible = true;
              $('body').on('click', $scope.handleClickAway);
            };
            var setPopoverVisibility = function (justThisOne, hide) {
              var popovers = justThisOne ? $element.find('.popover') : $('.popover');
              popovers.css({
                visibility: hide ? "hidden" : "visible"
              });
            };
            var cleanupDetailPopover = function () {
              $scope.editableTableModel.cachedActiveRow = null;
              $timeout(function () {
                tableService.setActiveRow($scope.tableId, null);
              });
              $('body').off('click', $scope.handleClickAway);
              $scope.clearAssociationTypes();
            };
            $scope.handleClickAway = function (event) {
              // check if click target was within the popover or the table body
              if ($element.find('.row-detail').has($(event.target)).length === 0 &&
                  $element.find('.editable-table-body tbody').has($(event.target)).length === 0) {
                setPopoverVisibility(false, true);
                cleanupDetailPopover();
              }
            };
            $scope.closeDetailPopover = function () {
              setPopoverVisibility(true, true);
              cleanupDetailPopover();
            };
            var getAssociationData = function (queryParams, callback) {
              tableService.getAssociationData(queryParams).then(callback);
            };

            $scope.$watch('editableTableModel.pagination.currentPage', function (newValue, oldValue) {
              if (typeof newValue === "undefined" || newValue == oldValue) return;
              $scope.loadPage(newValue);
            });
            $scope.$watch('editableTableModel.assocDetailPagination.currentPage', function (newValue, oldValue) {
              if (typeof newValue === "undefined" || newValue == oldValue) return;
              $scope.loadAssociationDetails(newValue, false);
            });

            $scope.$watch('options.parentEntity', function (newValue, oldValue) {
              if ((!newValue == null && !newValue) || newValue == oldValue) {
                return;
              }
              $scope.editableTableModel.options.displayFilters = !(newValue && newValue.bn);
              $scope.editableTableModel.options.parentEntity = newValue;

              // make sure the default filter isn't used for addByBrowse drill-in's
              // (if "root" is true, it will return no results)
              $scope.editableTableModel.useDefaultQuery = false;
              $scope.loadPage(1);
            });

            $scope.sortColumn = function (column) {
              column.sorted = true;

              column.ascending = !column.ascending;
              _.each($scope.editableTableModel.columns, function (col) {
                if (col.sortProperty !== column.sortProperty) {
                  col.ascending = false;
                }
              });
              for (var prop in $scope.editableTableModel.sortClass) {
                if ($scope.editableTableModel.sortClass.hasOwnProperty(prop)) {
                  $scope.editableTableModel.sortClass[prop] = 'icon-sort';
                }
              }

              $scope.editableTableModel.sortClass[column.sortProperty] = column.ascending ? 'icon-sort-down' : 'icon-sort-up';
              $scope.editableTableModel.sort.ascending = column.ascending;
              $scope.editableTableModel.sort.sortProperty = column.sortProperty;
              $scope.loadPage(1);
            };

            $scope.getDocumentIcon = function (iconClass) {
              return iconClass ? "attachment " + iconClass : "";
            };
            $scope.getAssociationDetailsHeaderIcon = function (extras) {
              extras = extras || {};
              var showStar = extras.enterpriseApproved || extras.core;
              return showStar ? "icon-star-active" : showStar == null ? "" : "icon-star-inactive";
            };
            const updateFlyoutFlag = (scope) => {
              if (window.location.href) {
                scope.newFlyout = window.location.href.includes('newFlyout')
              }
            }
            updateFlyoutFlag($scope)
            $scope.rowClicked = function (row, source, anEvent) {
              var clickedElement = $(anEvent.target);
              updateFlyoutFlag($scope)
              // don't load details if user clicked the edit link or any anchor that would cause a redirect
              if (clickedElement.hasClass('icon-edit') || clickedElement.is('a')) return;

              //don't load row again if it's currently the active row
              if (row === $scope.editableTableModel.cachedActiveRow) return;
              $scope.editableTableModel.cachedActiveRow = row;
              tableService.loadAssociationDetailsDetails(row).then(function (data) {
                $scope.editableTableModel.associationDetailsDetails = data.data;
                $scope.editableTableModel.associationDetailsDetails.entityLink = {
                  bn: data.data.bn,
                  color: data.data.color,
                  name: data.data.name,
                  controlLevel: data.data.controlLevel,
                  isVisible: true
                };
                $scope.$broadcast('rowClicked', $scope.editableTableModel.associationDetailsDetails.entityLink);
                $scope.editableTableModel.associationDetailsDetails.entityIcon = EntityUtils.icons[EntityBnCodeToIconClass[utilService.getBnCode($scope.editableTableModel.entityBn)]];
              });
              const sourceBn = row;
              const sourceBnCode = utilService.getBnCode(sourceBn);
              const targets = sideBarConfigs[sourceBnCode].multi;
              const closePopover = () => {
                $scope.closeDetailPopover();
              };
              redux.store.dispatch(redux.actions.flyoutSetEntity(sourceBn, sourceBnCode, closePopover))
              if ($scope.editableTableModel.options.associationDetailType === "associationMulti") {
                $scope.clearAssociationTypes();
                $scope.editableTableModel.showAssociationDetails = true;
                $scope.editableTableModel.associationDetailSourceBn = row;


                tableService.getCountsForAssociations({
                  sourceBn: row,
                  queryKey: $scope.editableTableModel.queryKey,
                  targets: sideBarConfigs[utilService.getBnCode(row)].multi,
                  splitRelationshipTypes: true
                }).then(function (results) {
                  const entityName = $scope.editableTableModel.associationDetailsDetails.name;
                  const entityBn = $scope.editableTableModel.associationDetailsDetails.bn;
                  const entityBnCode = utilService.getBnCode(entityBn);
                  const entityUrl = urlService.getUrlForBnCode(entityBn, entityBnCode);
                  const entity = {name: entityName, url: entityUrl, bn: entityBn, bnCode: entityBnCode}
                  const associations = results.data
                      .filter(dataElement => dataElement.count > 0)
                      .map(dataElement => {
                        const count = dataElement.count;
                        const displayNameKey = count == 1 ? 'displayName' : 'displayNamePlural';
                        const type = dataElement.entityType;
                        const name = EntityTypes[type][displayNameKey];
                        const bnCode = utilService.getBnCodeFromTypeCode(type);
                        const iconClass = styleService.getIconClassForEntityTypeCode(type);
                        return {name, count, iconClass, type, bnCode};
                      })
                  redux.store.dispatch(redux.actions.updateFlyoutInfo(
                      {associations, entity}
                  ))
                  var types = [];
                  var relTypeMap = {
                    CONTACT: 'Contacts',
                    EMPLOYMENT: 'Experience'
                  }, key = "", value = "";
                  for (var k in results.data) {
                    if (results.data[k].count > 0) {
                      var dataElement = results.data[k];
                      var displayNameKey = dataElement.count == 1 ? 'displayName' : 'displayNamePlural';
                      key = dataElement.count + " " + EntityTypes[dataElement.entityType][displayNameKey];
                      if (dataElement.relationshipType) {
                        key += ": " + relTypeMap[dataElement.relationshipType]
                      }
                      types.push({
                        key: key,
                        value: {
                          entityType: dataElement.entityType,
                          relationshipType: dataElement.relationshipType
                        }
                      })
                    }
                  }
                  $scope.editableTableModel.associationTypes = types;
                  preparePopover(row);
                });
              } else {
                var entity = bitConstants.getEntityTypeForTypeCode(urlService.getEntityTypeCodeOfCurrentPage());
                var targetTypeBn = entity ? entity.bnCode : null;
                $scope.editableTableModel.showAssociationDetails = true;
                $scope.editableTableModel.associationDetailParams = {
                  sourceBn: row,
                  targetBn: source,// || $scope.tableId,
                  targetTypeBn: targetTypeBn,
                  tableType: "associationDetails",
                  includeFilterSpec: false,
                  relationshipType: $scope.editableTableModel.relationshipType,
                  first: 0,
                  count: $scope.editableTableModel.assocDetailPagination ? $scope.editableTableModel.assocDetailPagination.pageSize : 10
                };
                $scope.loadAssociationDetails(1, true);
              }
            };
            $scope.clearAssociationTypes = function () {
              $scope.editableTableModel.associationDetails.associationType = null;
              $scope.editableTableModel.showAssociationDetails = false;
              $scope.editableTableModel.associationTypes = null;
              if ($scope.editableTableModel.associationDetail && $scope.editableTableModel.associationDetail.rows) {
                $scope.editableTableModel.associationDetail.rows.length = 0;
              }
            };
            $scope.$watch("editableTableModel.associationDetails.associationType", function (newValue, oldValue) {
              if (!newValue || newValue == oldValue) return;
              $scope.editableTableModel.associationDetailParams = {
                includeFilterSpec: false,
                sourceBn: $scope.editableTableModel.associationDetailSourceBn,
                relationshipType: $scope.editableTableModel.relationshipType || newValue.relationshipType,
                targetTypeBn: bitConstants.getEntityTypeForTypeCode(newValue.entityType).bnCode,
                tableType: "multiTypeAssociationDetails",
                first: 0,
                count: 10
              };
              $scope.loadAssociationDetails(1, false);
            });

            $scope.getCellRendererId = function (index) {
              var column = $scope.editableTableModel.columns[index];
              return column.rendererId;
            };

            // calculate the fixed width of the first column of the association editor
            $scope.getFirstColWidthForAssociationEditor = function (isFirstColumn) {

              if (!isFirstColumn) return;

              var
                  min = 200,
                  max = 750,
                  increment = 40,
                  width;

              width = Math.max(min, max - ($scope.editableTableModel.columns.length * increment));

              return {
                'width': width + 'px'
              };
            };

            $scope.init();
          }
        }
      }])
    .directive('nestedRows', ['$compile', 'tableService', 'utilService', function ($compile, tableService, utilService) {
      return {
        replace: true,
        scope: {
          rows: "=",
          columns: "=",
          options: '=',
          tableId: '@',
          rowClick: "&"
        },
        controller: function ($scope, $element, $attrs) {
          var ctrl = this;
          ctrl.rows = $scope.rows;
          ctrl.columns = $scope.columns;
          $scope.html = "";
          var depth = 0;
          var cellContent = {};

          $scope.$on("clearSelected", function () {
            tableService.setActiveRow($scope.tableId, null);
          });
          $scope.$watch(function () {
            return tableService.getActiveRow($scope.tableId);
          }, function (n) {
            // if newValue is null, clear selected
            if (!n) {
              $scope.selected = null;
            }
          }, true);
          $scope.select = function (item) {
            $scope.selected = item;
            tableService.setActiveRow($scope.tableId, item);
          };
          $scope.itemClass = function (bn) {
            return bn === $scope.selected ? 'active' : undefined;
          };
          var recursive = function (rows, tDepth, isChild) {
            isChild = isChild || false;
            tDepth = tDepth || 0;

            angular.forEach(rows, function (row, index) {
              var bn = (row.entity && row.entity.bn) || "UNDEFINED";
              cellContent[bn] = row;
              var trOpen;
              var popupVisible;
              if ($attrs.tableType && $attrs.tableType == "association") {
                trOpen = '<tr ng-class="itemClass(\'' + bn + '\')" entity="row.entity"';
                if (utilService.isControlLevelVisible(row.entity)) {
                  trOpen += ' ng-click="select(\'' + bn + '\');rowClick({row: \'' + bn + '\', anEvent:$event});"';
                  popupVisible = true;
                } else {
                  popupVisible = false;
                }
                trOpen += '">';
              } else {
                trOpen = '<tr>';
              }
              $scope.html +=
                  trOpen +
                  '<td ng-repeat="column in columns | filter: { display: true}" ng-class="{\'checkboxColumn\' : column.rendererId == \'CHECKBOX\'}">' +
                  '<div table-id="{{tableId}}" cell-content popup-visible="' + popupVisible + '" row="row" depth="' + tDepth + '" column="column" column-index="$index" row-bn="' + bn + '" entity="row.entity"></div>' +
                  '</td>' +
                  '</tr>';
              if (!isChild) {
                depth = 0;
              }
              if (row.children && row.children.length) {
                depth++;
                recursive(row.children, depth, true);
              } else {

              }
            })
          };
          if ($scope.rows && $scope.rows.length > 0) {
            recursive($scope.rows);
          }
          $scope.$watch('associations', function (newValue, oldValue) {
            if (!newValue || newValue == oldValue) return;
            ctrl.associations = $scope.associations = newValue;
          });
          $scope.$watch('rows', function (newValue, oldValue) {
            if (!newValue || angular.equals(newValue, oldValue)) return;
            ctrl.rows = $scope.rows = newValue;
            $scope.html = "";
            recursive($scope.rows);
          });
          $scope.$watch('columns', function (newValue, oldValue) {
            if (!newValue || newValue == oldValue) return;
            ctrl.columns = $scope.columns = newValue;
          });
          this.cellContent = cellContent;
        },
        link: function (scope, element, attrs, editableTableDirectiveCtrl) {
          scope.$watch('html', function (value) {
            if (typeof value === "undefined") return;
            element.html(value);
            $compile(element.contents())(scope);
          });
        }
      }
    }])
    //this directive replaces nestedRows for the association editor. It is much faster than nestedRows, due to using plain JS
    //instead of the many angular directives used in nestedRows.
    .directive('nestedRows2', ['$compile', 'urlService', 'utilService', 'tableService', function ($compile, urlService, utilService, tableService) {
      return {
        replace: true,
        scope: {
          rows: "=",
          columns: "=",
          options: '='
        }, //end-scope
        controller: function ($scope, $element, $attrs) {
          var ctrl = this;
          $scope.$watch('rows', function (newValue, oldValue) {
            if (!newValue || angular.equals(newValue, oldValue)) return;
            ctrl.rows = $scope.rows = newValue;
            $scope.html = "";
            buildTable($scope.rows, 0);
          });
          $scope.$watch('columns', function (newValue, oldValue) {
            if (!newValue || newValue == oldValue) return;
            ctrl.columns = $scope.columns = newValue;
          });
          $scope.updateCheckbox = function ($event, rowBn, colBn, relationshipType) {
            let checkbox = $event.target;
            let columnBn = utilService.isBn(colBn) ? colBn : "";
            let assoc = {
              targetBn: rowBn,
              relationshipIdentifier: columnBn,
              relationshipType: relationshipType
            };
            let assocType = "removedAssocs";
            if (checkbox.checked) {
              assocType = "addedAssocs";
            }
            tableService.updateAssociation(assocType, assoc);
          };
          var createCheckbox = function (row, column) {
            var rowBn = row.entity.bn;
            var colBn = column.id;
            var relType = column.relationshipType;

            return '<input type="checkbox" ng-click="updateCheckbox($event, \'' + rowBn + '\',\'' + colBn + '\',\'' + relType + '\')"' +
                (row[column.id] ? 'checked="checked"' : '>');
          };
          var createIconElement = function (row) {
            var rowBn = row.entity.bn;
            var bnCode = utilService.getBnCode(rowBn);
            var classForEntity = EntityBnCodeToIconClass[bnCode];
            //don't mess with the whitespace at the end of this string
            return '<span class="icon-' + classForEntity + '"></span> ';
          };
          var createAnchorElement = function (row) {
            var rowBn = row.entity.bn;
            var bnCode = utilService.getBnCode(rowBn);
            var name = row.entity.name;
            var url = urlService.getUrlForBnCode(rowBn, bnCode);
            return '<span><a href="' + url + '">' + name + '</a></span>';
          };
          var buildTable = function (rows, depth) {
            var i;
            for (i = 0; i < rows.length; i++) {
              var row = rows[i];
              $scope.html += '<tr>';
              var rowBn = row.entity.bn;
              var j;
              for (j = 0; j < $scope.columns.length; j++) {
                var column = $scope.columns[j];
                var colBn = column.id;
                if (column.rendererId == 'CHECKBOX') {
                  $scope.html +=
                      '<td class="checkboxColumn"><div>' +
                      createCheckbox(row, column) +
                      '</div></td>';
                } else {
                  $scope.html += '<td><div><span>' +
                      '<span class="depth depth' + depth + '">' + '</span>' +
                      createIconElement(row) +
                      createAnchorElement(row) +
                      '</span></div>' +
                      '</td>';
                }
              } //end-col-loop
              $scope.html += '</tr>';
              if (row.children && row.children.length) {
                //recursively call buildTable on the children
                buildTable(row.children, depth + 1);
              }
            } //end-row-loop
          };//end-buildTable
        }, //end-controller
        link: function (scope, element, attrs, editableTableDirectiveCtrl) {
          scope.$watch('html', function (value) {
            if (typeof value === "undefined") return;
            element.html(value);
            $compile(element.contents())(scope);
          }); //end-watch
        } //end-link
      }; //end-return
    }])
    .directive('rendererContent', ['$compile', function ($compile) {
      return {
        scope: {
          content: '=',
          rendererId: '@'
        },
        replace: true,
        link: function (scope, element, attrs) {
          var template = RendererIdToTemplate[scope.rendererId];
          if (template == null) {
            template = RendererIdToTemplate['DEFAULT'];
          }
          element.html(template);//.show();
          $compile(element.contents())(scope);
        }
      }
    }])
    .directive('fadeInColumnSelection', function () {
      return {
        link: function (scope, $element, $attrs) {
          if ($attrs.isNew) {
            $element.hide();
            $element.fadeIn(700, function () {
              $element.find('.well').animate({'borderColor': '#00b1ef'}, 300).animate({'borderColor': '#e3e3e3'}, 1300);
            });
          }
        }
      }
    })
    .directive('cellContent', ['$compile', 'tableService', '$rootScope', 'utilService', 'bitConstants',
      function ($compile, tableService, $rootScope, utilService, bitConstants) {
        return {
          scope: {
            rendererId: "@",
            column: '=',
            columnIndex: "=",
            depth: '@',
            entity: '=',
            popupVisible: '=',
            row: "@",
            rowBn: '@',
            tableId: '@'
          },
          restrict: 'E,A',
          replace: true,
          require: ["?^nestedRows"],
          link: function (scope, element, attrs, controllers) {
            // SS - there's a bug where sometimes the nestedRows controller is undefined. It doesn't seem to impact the output, so I'm confused by what's causing it.
            if (!controllers || !controllers[0]) {
              return
            }
            var tableOpts = tableService.getTable(scope.tableId);

            scope.cellContentModel = {
              isNotOrgToPeople: true,
              targetBn: null
            };
            if (scope.$parent && scope.$parent.$parent && scope.$parent.$parent.$parent) {
              scope.cellContentModel.targetBn = scope.$parent.$parent.$parent.targetBn;
            }
            if (scope.column) {
              var rendererIds = ['LAUNCH_ASSOCIATION_EDITOR', 'ICON_DETAILS', 'ICON_STAR', 'ICON_BLUEPRINT'];
              var rendererId = _.contains(rendererIds, scope.column.rendererId);
              // if we returned a value, and we are not a systemCore column
              if (rendererId) {
                // SS - sometimes these columns have names and need to be displayed, so we give them more room
                // SS - ("CORE") in systems, for example
                scope.column.className = 'span' + (scope.column.name ? '2' : '1');
              }
              if (scope.column.rendererId == "PROFILE_THUMBNAIL") {
                scope.column.className = "profileThumbnail";
              }
            }
            scope.dialogOpts = {
              dialog: 'associationEditorDialog',
              targetBn: scope.rowBn,
              dialogSource: 'edit',
              sectionBn: scope.tableId,
              relationshipType: tableOpts ? tableOpts.relationshipType : null,
              targetEntity: controllers && controllers.length > 0 && controllers[0].cellContent && controllers[0].cellContent[scope.rowBn] ? controllers[0].cellContent[scope.rowBn].entity : {}
            };
            if (scope.dialogOpts.targetEntity && scope.dialogOpts.targetEntity.bn) {
              scope.dialogOpts.targetEntity.typeBn = EntityBnCodeToIconClass[utilService.getBnCode(scope.dialogOpts.targetEntity.bn)];
            }
            var assocs = {
              addedAssocs: [],
              removedAssocs: []
            };
            scope.openDialog = tableService.openDialog;
            scope.rowBn = attrs.rowBn;

            scope.updateSelection = function ($event, rowBn, colBn) {

              var checkbox = $event.target;
              var action = checkbox.checked ? 'add' : 'remove';
              if (!scope.isNotOrgToPeople(rowBn)) {
                //Is Org to People. rowBn needs to become positionBn, relId becomes member/manager
                rowBn = scope.cellContentModel.targetBn;
              }
              scope.updateAssociation(action, rowBn, colBn);
            };
            scope.colorBoxStyle = function (content) {
              return {
                'background-color': '#' + content.color
              }
            };
            scope.documentIconClass = function (content) {
              return content ? content : "blank";
            };
            scope.iconStarClass = function (starred) {
              return starred ? "icon-star-active" : starred == null ? "" : "icon-star-inactive";
            };
            scope.iconRatingClass = function (rating) {
              return 'icon-star-' + rating;
            };
            scope.browseSelected = function (rowBn) {
              $rootScope.$broadcast("browseSelected", controllers[0].cellContent[rowBn]);
            };
            scope.isNotOrgToPeople = function (sourceBn) {
              var sourceBnCode = utilService.getBnCode(sourceBn);
              if (sourceBnCode == bitConstants.getEntityTypeForTypeCode('ORG').bnCode) {
                if (scope.cellContentModel.targetBn == bitConstants.getEntityTypeForTypeCode('PER').bnCode) {
                  scope.cellContentModel.isNotOrgToPeople = false;
                }
              }
              return scope.cellContentModel.isNotOrgToPeople;
            };

            scope.updateAssociation = function (action, rowBn, colBn) {
              if (action == 'add') {
                tableService.updateAssociation("addedAssocs", {targetBn: rowBn, relationshipIdentifier: colBn});
              } else {
                tableService.updateAssociation("removedAssocs", {targetBn: rowBn, relationshipIdentifier: colBn});
              }
            };
            scope.roundPercent = function (percent) {
              return percent.toFixed();
            };
            scope.calculateChecked = function (rowBn, colBn) {
              return _.filter(assocs, function (assoc, index) {
                return assoc.sourceBn == rowBn && assoc.relationshipBn == colBn;
              }).length;
            };
            scope.isChecked = function (rowBn, colBn) {
              return scope.calculateChecked(rowBn, colBn) > 0;
            };

            if (!scope.entity) {
              scope.content = controllers[0].cellContent[attrs.rowBn][scope.column.id];
            } else {
              scope.content = scope.entity;
            }

            scope.rowBn = attrs.rowBn;
            var rendererId = attrs.rendererId ? attrs.rendererId : scope.column.rendererId;
            var template = RendererIdToTemplate[rendererId];

            if (template == null || (typeof scope.content === "undefined" && scope.column && scope.column.name !== "")) {
              template = RendererIdToTemplate['DEFAULT'];
            }

            var cellVisible = true;
            if (!scope.popupVisible && rendererId == 'ICON_DETAILS') {
              cellVisible = false;
            }

            if (cellVisible) {
              element.html(template);//.show();
            }
            $compile(element.contents())(scope);
          }
        }
      }]);

var localizedDateTimeFormat = moment.localeData($LAB.LOCALE_NAME).longDateFormat("L") + " " + moment.localeData($LAB.LOCALE_NAME).longDateFormat("LT");
var localizedDateFormat = moment.localeData($LAB.LOCALE_NAME).longDateFormat("L");
var RendererIdToTemplate = {};
RendererIdToTemplate['TEXT'] = '{{content}}';
RendererIdToTemplate['COLOR_BOX'] = '<span class="colorBox" ng-style="colorBoxStyle(content)"></span>';
RendererIdToTemplate['SHORT_DATE'] = '<span formatted-date format="' + localizedDateFormat + '" date-string="content"></span>';
RendererIdToTemplate['SHORT_DATE_TIME'] = '<span formatted-date date-string="content" format="' + localizedDateTimeFormat + '"></span>';
RendererIdToTemplate['LAUNCH_ASSOCIATION_EDITOR'] = '<a target="_self" class="edit icon-edit" ng-click="openDialog(dialogOpts)"></a>';
RendererIdToTemplate['SELECT_ENTITY'] = '<button class="btn btn-primary" ng-click="browseSelected(rowBn)">Select</button>';
RendererIdToTemplate['SELECTABLE_ENTITY'] = '<button class="btn btn-primary select" ng-click="browseSelected(rowBn)" ng-show="content">Select</button><i class="icon-check" ng-show="!content"></i>';
RendererIdToTemplate['ENTITY_LINK'] = '<span entity-link entity="content" show-icon="true"></span>';
RendererIdToTemplate['ENTITY_ICON_LINK'] = '<span entity-icon-link depth="depth" entity="content"></span>';
RendererIdToTemplate['ENTITY_ICON_TEXT'] = '<span entity-icon-text entity="content"></span>';
RendererIdToTemplate['ENTITY_ICON_CHILD'] = '<span entity-icon-child depth="depth" entity="content"></span>';
RendererIdToTemplate['ENTITY_LIST'] = '<span entity-list list="content" show-icon="true"></span>';
RendererIdToTemplate['ENTITY_LIST_COMMA'] = '<span entity-list-comma list="content" show-icon="true"></span>';
RendererIdToTemplate['TEXT_LIST'] = '<span text-list list="content"></span>';
RendererIdToTemplate['SPARKLINE'] = '<div class="content-status-bar red-green" title="{{roundPercent(content) + \' percent\'}}"><div class="percent" ng-style="{ width: content + \'%\'}"></div></div>';
RendererIdToTemplate['CHECKBOX'] = '<input type="checkbox" ng-click="updateSelection($event,rowBn,column.id)" ng-checked="content"  />';
RendererIdToTemplate['CHECKBOX_PRIMARY'] = '<input type="checkbox" ng-click="updateSelection($event,rowBn,column.id)" ng-checked="content"  />';
RendererIdToTemplate['RADIO_BUTTON_PRIMARY'] = '<input type="radio" ng-click="updatePrimary($event,rowBn,column.id)" name="samesies" ng-checked="content"  />';
RendererIdToTemplate['TEXT_LIST_COMMA'] = '<span text-list-comma list="content"></span>';
RendererIdToTemplate['PROFILE_THUMBNAIL'] = '<img class="profileImg" src="/b/api/profile/image/{{content}}"/>';
RendererIdToTemplate['COUNT_DETAIL_LIST'] = '<span count-detail-label count="content.count" entity-bn="content.entityBn" assoc-entity-code="content.assocEntityCode"></span>';
RendererIdToTemplate['HTML'] = '<div ng-bind-html="content"></div>';
RendererIdToTemplate['ICON_ASSOCIATED'] = '';
RendererIdToTemplate['ICON_BLUEPRINT'] = '<span class="icon-blueprint" ng-show="content"></span>';
RendererIdToTemplate['ICON_STAR'] = '<span ng-class="iconStarClass(content)"></span>';
RendererIdToTemplate['ICON_DETAILS'] = '<span class="icon-hover-detail"></span>';
RendererIdToTemplate['ICON_DOCUMENT'] = '<span class="attachment" ng-class="documentIconClass(content)"></span>';
RendererIdToTemplate['ICON_RATING'] = '<span star-rating number-of-stars="content.weight"></span>';
RendererIdToTemplate['ICON_RATING_BUBBLE'] = '<span weight-rating number-of-weight-icons="content"></span>';
RendererIdToTemplate['XML_QUERY'] = '<span bit-query-no-watch query-xml="content" class="adv-query view-query"></span>';
RendererIdToTemplate['CON_LINKAGE'] = '<span con-linkage entity="content" show-icon="true"></span>';
RendererIdToTemplate['CON_SOURCES'] = '<span con-sources list="content" show-icon="true"></span>';
RendererIdToTemplate['CON_TARGETS'] = '<span con-targets list="content" show-icon="true"></span>';
RendererIdToTemplate['DEFAULT'] = '{{content.value}}';
RendererIdToTemplate['CONTENT_NAME'] = '{{content.name}}';
