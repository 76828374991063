angular
  .module('barometerApp.tasks')
  .directive('task', task);

task.$inject = ['$rootScope', 'tasksService', 'utilService', '$modal', 'styleService', 'urlService', 'entityService', 'alertService', 'associatedBnCacheService', 'graphService', '$timeout'];

/**
 * Displays one Task
 *
 * @author Tim Cramer
 */
function task($rootScope, tasksService, utilService, $modal, styleService, urlService, entityService, alertService, associatedBnCacheService, graphService, $timeout) {

  var directive = {
    templateUrl: '/b/js/src/bit.ng/tasks/partials/task.html',
    restrict: 'E',
    scope: {
      task: '='
    },
    link: function (scope) {
      scope.isOpen = false;
      scope.editing = false;
      scope.loadingDetails = true;
      scope.forms = [];
      scope.entities = [];
      scope.showEntityAdd = false;
      scope.assigneeSaved = null;
      scope.requesterSaved = null;
      if ('12/31/1969' === utilService.convertToLocalDate(scope.task.createDate * 1000)) {
        console.log('Moment JS returned it\'s initial date.');
        console.log('Task Object');
        console.log(scope.task);
        console.log('Timestamp from Neo4J scope.task.createDate: ' + scope.task.createDate);
        console.log('Timestamp from Neo4J scope.task.createDate * 1000: ' + scope.task.createDate);
        scope.task.createdDate = '';
      } else {
        scope.task.createdDate = utilService.convertToLocalDate(scope.task.createDate * 1000);
      }
      //we need to tell moment that the timestamp is in utc
      scope.task.completedDateFormatted = scope.task.taskCompletionDate ? moment(scope.task.taskCompletionDate * 1000).utc().format('L') : null;
      scope.taskModel = angular.copy(scope.task);
      scope.includeEntityTypes = ['PER'];
      scope.includeRelevantEntityTypesForTasks = ['ACT', 'ADQ', 'AST', 'AUD', 'CAP', 'CIR', 'COM', 'CON', 'CRL', 'DAT', 'DEM', 'MKT', 'ORG', 'PER', 'PHY', 'PRD', 'SKI', 'STA', 'SYS', 'STR', 'TAG', 'TEC', 'WK2'];
      scope.showTaskHistory = false;
      scope.addedRemediatedEntities = [];
      scope.removedRemediatedEntities = [];
      scope.formsModel = [];
      scope.entitiesModel = [];
      scope.quickEditModel = {
        modal: null,
        task: {},
        entities: [],
        form: {
          bn: null
        },
        formData: {}
      };
      scope.formBuilder = {
        modal: null,
        form: null
      };
      scope.activityModal = null;
      scope.editDisabled = false;
      scope.assignedByMe = false;
      scope.disableCompleteTaskButton = false;

      if (scope.task.isCompleted) {
        scope.editDisabled = true;
      }

      var setAssignedByMe = function (requesterBn) {
        if (requesterBn == utilService.getCurrentUserBn()) {
          scope.assignedByMe = true;
        }
      };

      scope.addRelevantEntity = function ($event, entity) {
        $event.preventDefault();
        if (scope.quickEditModel.entities.length < 10 && !entity.isAssociated) {
          var entityBnForAdd = entity.bn;
          var isRecentlyRemoved = false;
          associatedBnCacheService.addAssociatedBn(entityBnForAdd);

          scope.removedRemediatedEntities = _.filter(scope.removedRemediatedEntities, function (bn) {
            if (bn == entityBnForAdd) {
              isRecentlyRemoved = true;
            }
            return !(bn == entityBnForAdd);
          });
          if (!isRecentlyRemoved) {
            scope.addedRemediatedEntities.push(entityBnForAdd);
          }

          scope.showEntityAdd = false;
          scope.quickEditModel.entities.push(entity);
        } else {
          // we have 2 alerts we have to account for...
          if (entity.isAssociated) {
            // do absolutely nothing...
          } else {
            alertService.addErrorAlert("Tasks are limited to 10 relevant entities.");
          }
        }
      };

      scope.removeRemediatedEntity = function (entityIndex) {
        var entityBnForRemoval = scope.quickEditModel.entities[entityIndex].bn;
        var isRecentlyAdded = false;
        associatedBnCacheService.addAssociatedBn(entityBnForRemoval);
        // make sure this entity wasnt recently added,
        // if it was, remove it from added list
        scope.addedRemediatedEntities = _.filter(scope.addedRemediatedEntities, function (bn) {
          if (bn == entityBnForRemoval) {
            isRecentlyAdded = true;
          }
          return !(bn == entityBnForRemoval);
        });
        if (!isRecentlyAdded) {
          scope.removedRemediatedEntities.push(entityBnForRemoval);
        }
        if (scope.formBuilder.form || scope.quickEditModel.form) {
          scope.removeForm();
        }
        scope.quickEditModel.entities = _.filter(scope.quickEditModel.entities, function (entity) {
          return !(entity.bn == entityBnForRemoval);
        });
      };

      scope.removeForm = function () {
        if (scope.forms.length > 0) {
          scope.removedRemediatedEntities.push(scope.forms[0].bn);
        }
        scope.quickEditModel.form = {
          bn: null
        };
        scope.quickEditModel.formData = {};
        scope.formBuilder.form = null;
        scope.forms = [];
      };

      scope.openTaskActivityModal = function () {
        scope.activityModal = $modal.open({
          backdrop: 'static',
          keyboard: true,
          templateUrl: '/b/js/src/bit.ng/tasks/partials/task-activity-modal.html',
          scope: scope
        });
      };

      scope.closeTaskActivityModal = function () {
        scope.activityModal.close('cancel');
      };

      var doOpenEditModal = function () {
        scope.assigneeSaved = angular.copy(scope.task.assignee);
        scope.requesterSaved = angular.copy(scope.task.requester);
        scope.formSaved = angular.copy(scope.forms);
        if (scope.task.assignee.bn == utilService.getCurrentUserBn()) {
          scope.task.assignee.isCurrentUser = true;
        }
        if (scope.task.requester.bn == utilService.getCurrentUserBn()) {
          scope.task.requester.isCurrentUser = true;
        }
        _.forEach(scope.entities, function (entity) {
          associatedBnCacheService.addAssociatedBn(entity['bn']);
        });

        scope.quickEditModel.modal = $modal.open({
          backdrop: 'static',
          keyboard: true,
          templateUrl: '/b/js/src/bit.ng/tasks/partials/task-edit-modal.html',
          scope: scope
        });
      };

      scope.openTaskEditModal = function () {
        if (!scope.editDisabled) {
          if (typeof scope.quickEditModel.form != 'undefined' && scope.quickEditModel.form.bn != null) {
            scope.quickEditModel.formData = entityService.getBasicInfo(scope.quickEditModel.form.bn).then(function (form) {
              scope.quickEditModel.formData = form.data;
              doOpenEditModal();
            });
          } else {
            doOpenEditModal();
          }
        }
      };

      scope.openFormBuilderModal = function () {
        //
        scope.formBuilder.entityType = utilService.getEntityTypeCode(scope.quickEditModel.entities[0].bn);
        //
        scope.formBuilder.modal = $modal.open({
          backdrop: 'static',
          keyboard: true,
          templateUrl: '/b/js/src/bit.ng/remediation-form-builder/partials/form-builder-modal.html',
          scope: scope
        });
      };

      scope.toggleView = function () {
        scope.editing = false;
      };

      scope.headerClicked = function () {
        if (!scope.isOpen) {
          getTaskDetails();
        }
        scope.isOpen = !scope.isOpen;
      };

      var getTaskDetails = function () {
        scope.loadingDetails = true;
        scope.forms = [];
        scope.entities = [];
        setAssignedByMe(scope.task.requester.bn);
        tasksService.getTaskDetails(scope.task.bn, function (forms, entities) {
          if (forms != null || typeof forms != 'undefined' || forms.length != 0) {
            scope.forms = forms;
            scope.formsModel = angular.copy(forms);
            scope.quickEditModel.form = angular.copy(scope.forms[0]);
          }
          if (entities != null || entities.length > 0) {
            scope.entities = entities;
            scope.entitiesModel = angular.copy(entities);
          }
          scope.quickEditModel.task = angular.copy(scope.task);
          scope.quickEditModel.entities = angular.copy(scope.entities);
          scope.loadingDetails = false;
        });
      };

      var doTaskUpdate = function (taskBn, taskForUpdate) {
        tasksService.updateTask(taskBn, taskForUpdate, false).then(function (updatedTask) {
          tasksService.manageRemediatedEntityAssocs(scope.task.bn, scope.addedRemediatedEntities, scope.removedRemediatedEntities, function (data) {

          });
          scope.task.description = updatedTask.description;
          scope.taskModel = angular.copy(scope.task);
          scope.entities = angular.copy(scope.quickEditModel.entities);
          scope.editing = false;
          scope.addedRemediatedEntities = [];
          scope.removedRemediatedEntities = [];
          // update saved objects
          scope.formsModel = angular.copy(scope.forms);
          scope.entitiesModel = angular.copy(scope.entities);
          associatedBnCacheService.clear();
        }, function (data) {
          scope.cancelTaskEdit();
        });
      };

      scope.updateTask = function (formInvalid) {
        var saveObj = angular.copy(scope.quickEditModel.task);
        if (scope.task.requester.bn != scope.requesterSaved.bn) {
          // FYI scope.requester is unassigned at this point (BARO-17576).
          // But scope.task.requester is available, and seems to be safe for create and edit.
          tasksService.addRequester(scope.task.bn, scope.task.requester.bn);
        }
        if (scope.task.assignee.bn != scope.assigneeSaved.bn) {
          tasksService.addAssignee(scope.task.bn, scope.task.assignee.bn);
        }
        scope.quickEditModel.modal.close('cancel');
        if (scope.formBuilder.form != null) {
          entityService.createEntity(scope.formBuilder.form).then(function (data) {
            scope.addedRemediatedEntities.push(data.data.bn);
            // Only add removed form to removed entities list if one existed prior to new form create
            if (scope.formsModel.length > 0) {
              scope.removedRemediatedEntities.push(scope.formsModel[0].bn);
            }
            scope.forms = [];
            scope.forms.push(data.data);
            doTaskUpdate(scope.task.bn, saveObj);
          });
        } else {
          doTaskUpdate(scope.task.bn, saveObj);
        }
      };

      scope.cancelTaskEdit = function () {
        scope.quickEditModel.modal.close('cancel');
        if (scope.task.requester != scope.requesterSaved) {
          scope.task.requester = angular.copy(scope.requesterSaved);
        }
        if (scope.task.assignee != scope.assigneeSaved) {
          scope.task.assignee = angular.copy(scope.assigneeSaved);
        }
        scope.forms = angular.copy(scope.formsModel);
        scope.entities = angular.copy(scope.entitiesModel);
        scope.quickEditModel.entities = angular.copy(scope.entitiesModel);
        scope.quickEditModel.form = angular.copy(scope.formsModel);
        scope.task = angular.copy(scope.taskModel);
        associatedBnCacheService.clear();
        if (scope.forms.length == 0) {
          scope.formBuilder = {
            modal: null,
            form: null
          };
          scope.quickEditModel.form = {
            bn: null
          };
        }
        scope.editing = false;
        scope.showEntityAdd = false;
      };

      scope.completeTask = function () {
        var saveObj = angular.copy(scope.task);

        tasksService.updateTask(saveObj.bn, saveObj, true).then(function (completedTask) {
          tasksService.pollForTaskStatusInGraph(completedTask.bn, "COMPLETED", 2000, function(taskBn) {
            $rootScope.$broadcast("taskCompletedInGraph", taskBn);
          });
          scope.completed = true;
          scope.task.isCompleted = true;
          scope.editDisabled = true;
          scope.taskModel = angular.copy(scope.task);
          scope.editing = false;
        });
      };

      scope.addAssignee = function ($event, entity) {
        $event.preventDefault();
        scope.setAssignee(entity.bn, entity.name);
      };

      scope.addRequester = function ($event, entity) {
        $event.preventDefault();
        scope.setRequester(entity.bn, entity.name);
      };

      // Set person to "assignee" or "requester" roles
      function setPerson(bn, name) {
        if (typeof bn == "undefined") {
          return null;
        } else {
          return {
            bn: bn,
            name: name,
            isCurrentUser: utilService.getCurrentUserBn() === bn
          };
        }
      }

      scope.setAssignee = function (bn, name) {
        scope.task.assignee = setPerson(bn, name);
      };
      scope.setRequester = function (bn, name) {
        scope.task.requester = setPerson(bn, name);
      };

      scope.clearAssignee = function () {
        scope.task.assignee = null;
      };

      scope.clearRequester = function () {
        scope.task.requester = null;
      };

      scope.assignToCurrentUser = function () {
        scope.setAssignee(utilService.getCurrentUserBn(), utilService.getCurrentUserName());
      };

      scope.addEntityPlaceholderClicked = function () {
        scope.showEntityAdd = true;
      };

      scope.getEntityLink = function (entity) {
        return urlService.getUrlForBnCode(entity.bn, utilService.getBnCodeFromTypeCode(utilService.getEntityTypeCode(entity.bn)));
      };

      scope.getEntityIcon = function (entity) {
        return styleService.getIconClassForEntityTypeCode(utilService.getEntityTypeCode(entity.bn));
      };

      scope.requesterIsCurrentUser = function () {
        return scope.task.requester.bn === utilService.getCurrentUserBn();
      };

      scope.$on('taskFormEditToggled', function(event, isEditing){
        scope.disableCompleteTaskButton = isEditing;
      });
    }
  };
  //
  return directive;
}
