angular.module('barometerApp.donutChart')

//   Takes a chartModel Object in this format:
//
//        chartModel: {
//          count: 17,
//          title: 'Tasks Outstanding',
//          data: [
//            {name: '&lt; 7 days old', y: 3, color: 'green'},
//            {name: '7 to 30 days old', y: 9, color: 'orange'},
//            {name: '&gt; 30 days old', y: 4, color: 'red'}
//          ]
//        }
//
//   If chartModel changes, the graph redraws.
//
  .directive('donutChart', [
    function () {
      return {
        restrict: 'E',
        scope: {
          chartModel: '='
        },
        link: function (scope, $element)   {
          var chart;
          function renderChart() {
            if (scope.chartModel) {
              chart = new Highcharts.Chart({
                chart: {
                  renderTo: $element.get(0),
                  type: 'pie'
                },
                legend: { enabled: false },
                title:  {
                  text: scope.chartModel.count,
                  style: {"fontSize": "24px"},
                  floating: true,
                  y: 65
                },
                subtitle:  {
                  text: scope.chartModel.title,
                  floating: true,
                  y: 120
                },
                yAxis: {
                  title: ''
                },
                credits: {
                  enabled: false
                },
                plotOptions: {
                  pie: {
                    shadow: false
                  }
                },
                tooltip: {
                  formatter: function () {
                    if(this.point.count == 0) {
                      return '<b>' + this.point.name + '</b>';
                    } else {
                      return '<b>' + this.point.name + '</b>: ' + this.y;
                    }
                  }
                },
                series: [{
                  data: scope.chartModel.data,
                  size: '100%',
                  innerSize: '75%',
                  dataLabels: {
                    enabled: false
                  }
                }]
              });
            }
          }

          scope.$watch('chartModel', function (newVal, oldVal) {
            if (oldVal !== newVal) {
              renderChart();
            }
          });

          renderChart();
        }
      }
    }
  ]);
