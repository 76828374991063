// A bean is any simple persistent entity w/o associations.
angular.module('barometerApp.serviceNow')
    .service('serviceNowService', ['relationshipDataService', 'dataDictionaryHeaderService', 'fieldService', 'fieldsetService', '$q',
      function (relationshipDataService, dataDictionaryHeaderService, fieldService, fieldsetService, $q) {

        const PROPOSED_FIELDS = ['bn', 'fieldname', 'fromvalue', 'proposedchangebn', 'tovalue'];
        const RELATIONSHIP_FIELDS = ['qualifier', 'relationshiptype', 'child', 'parent'];
        const CONNECTION_AS_RELATIONSHIP_TYPES = ['SYS', 'COM'];

        var getNameFromEntityType = (entityType) => {
          return EntityTypes[entityType].displayName.toLowerCase();
        };

        /**
         * Generate the import set name, e.g. u_barometer_system_import or u_barometer_proposed_capability_import
         */
        var generateImportSetName = (dataType, proposed) => {
          var entityName;
          if(dataType.includes('-')) {
            var split = dataType.split('-');
            entityName = getNameFromEntityType(split[0]) + '_' + getNameFromEntityType(split[1]);
          } else {
            entityName = getNameFromEntityType(dataType);
          }
          if(proposed) {
            return 'u_barometer_proposed_' + entityName + '_import';
          } else {
            return 'u_barometer_' + entityName + '_import';
          }
        };

        /**
         * Generate the modal body.
         */
        var generateBody = (propertyMap, proposed) => {
          var body = '';

          _.each(propertyMap, (propertyNames, tableType) => {

            body += '\n\nImport Set: ' + generateImportSetName(tableType) + '\n\nFields:';

            propertyNames = _.uniq(propertyNames); // remove duplicates

            propertyNames.sort();

            _.each(propertyNames, propertyName => body += '\n' + propertyName);

            if(proposed) {

              body += '\n\nImport Set: ' + generateImportSetName(tableType, true) + '\n\nFields:';
              _.each(PROPOSED_FIELDS, field => body += '\n' + field);

            }
          });
          return body;
        };

        var getStandardFieldsForEntityType = function(entityType, tableType) {
          var deferred = $q.defer();
          dataDictionaryHeaderService.getAllDatadictionaryHeaders(entityType).then(results => {
            var propertyNames = [];
            _.each(results.data, property => {
              if (property.propertyType != 'ASSOCIATION' && !property.key.includes('.')) {
                propertyNames.push(property.key.toLowerCase());
              }
            });
            deferred.resolve({tableType: tableType, propertyNames: propertyNames});
          });
          return deferred.promise;
        };

        var getCustomFieldsForEntityType = function(entityType, tableType, allCustomFieldMap) {
          var deferred = $q.defer();
          fieldsetService.getFieldsetsForEntityType(entityType).then(results => {
            var propertyNames = [];
            _.each(results.data, fieldset => {
              var fields = fieldset.fields;
              _.each(fields, fieldBn => {
                var fieldId = allCustomFieldMap[fieldBn];
                if (fieldId != null) {
                  var fieldName = fieldId;

                  //remove all underscores from the end
                  while(fieldName.charAt(fieldName.length-1) === '_') {
                    fieldName = fieldName.substring(0, fieldName.length-1);
                  }

                  //collapse all multiple underscores together to one
                  while(fieldName.indexOf("__") > -1) {
                    fieldName = fieldName.replace("__", "_");
                  }

                  propertyNames.push(fieldName
                      .toLowerCase()
                      .replace(/:/g, "_")
                      .replace(/\//g, "_")
                      .replace(/\s/g, "")
                  );
                }
              });
            });
            deferred.resolve({tableType: tableType, propertyNames : propertyNames});
          });
          return deferred.promise;
        };

        var addEntityFieldsToPromises = function(entityType, tableType, allCustomFieldMap, entityPromises) {
          //first get all the non-custom fields that are not associations
          var standardFieldDeferred = $q.defer();
          getStandardFieldsForEntityType(entityType, tableType).then(results => standardFieldDeferred.resolve(results));

          //next get all the custom fields
          var customFieldDeferred = $q.defer();
          getCustomFieldsForEntityType(entityType, tableType, allCustomFieldMap).then(results => customFieldDeferred.resolve(results));

          entityPromises.push(standardFieldDeferred.promise);
          entityPromises.push(customFieldDeferred.promise);
        };

        function addRelationshipFieldsToPromises(relationshipType, snModel, allCustomFieldMap, entityPromises) {
          var types = relationshipType.split('-');
          var fromType = types[0];
          var toType = types[1];
          var deferredFromStandard = $q.defer();
          var deferredFromCustom = $q.defer();
          var deferredToStandard = $q.defer();
          var deferredToCustom = $q.defer();
          var deferredRelationship = $q.defer();

          if (snModel.handleConnectionsAsRelationships && CONNECTION_AS_RELATIONSHIP_TYPES.includes(fromType) && CONNECTION_AS_RELATIONSHIP_TYPES.includes(toType)) {
            addEntityFieldsToPromises('CON', relationshipType, allCustomFieldMap, entityPromises);
          }

          var propertyNames = [];
          _.each(RELATIONSHIP_FIELDS, field => propertyNames.push(field));
          deferredRelationship.resolve({tableType: relationshipType, propertyNames: propertyNames});

          getStandardFieldsForEntityType(fromType, fromType).then(results => {
            var propertyNames = [];
            _.each(results.propertyNames, name => propertyNames.push('source_' + name.toLowerCase()));
            return deferredFromStandard.resolve({tableType: relationshipType, propertyNames: propertyNames});
          });
          getCustomFieldsForEntityType(fromType, fromType, allCustomFieldMap).then(results => {
            var propertyNames = [];
            _.each(results.propertyNames, name => propertyNames.push('source_' + name.toLowerCase()));
            return deferredFromCustom.resolve({tableType: relationshipType, propertyNames: propertyNames});
          });
          getStandardFieldsForEntityType(toType, toType).then(results => {
            var propertyNames = [];
            _.each(results.propertyNames, name => propertyNames.push('target_' + name.toLowerCase()));
            return deferredToStandard.resolve({tableType: relationshipType, propertyNames: propertyNames});
          });
          getCustomFieldsForEntityType(toType, toType, allCustomFieldMap).then(results => {
            var propertyNames = [];
            _.each(results.propertyNames, name => propertyNames.push('target_' + name.toLowerCase()));
            return deferredToCustom.resolve({tableType: relationshipType, propertyNames: propertyNames});
          });

          entityPromises.push(deferredRelationship.promise);
          entityPromises.push(deferredFromStandard.promise);
          entityPromises.push(deferredFromCustom.promise);
          entityPromises.push(deferredToStandard.promise);
          entityPromises.push(deferredToCustom.promise);
        }

        var generateFieldModalBody = (snModel, allCustomFieldMap) => {
          var entityPromises = [];
          _.each(snModel.entityTypes, entityType => {
            addEntityFieldsToPromises(entityType, entityType, allCustomFieldMap, entityPromises);
          });

          _.each(snModel.relationshipTypes, relationshipType => {
            addRelationshipFieldsToPromises(relationshipType, snModel, allCustomFieldMap, entityPromises);
          });

          var deferred = $q.defer();
          $q.all(entityPromises).then(results => {

            var propertyMap = {};

            _.each(results, r => {
              var propertyNames = propertyMap[r.tableType];
              if(propertyNames == undefined || propertyNames.length < 1) {
                propertyNames = [];
              }
              propertyNames.push.apply(propertyNames, r.propertyNames);
              propertyMap[r.tableType] = propertyNames;
            });
            var body = generateBody(propertyMap, snModel.proposedChanges);

            deferred.resolve(body);
          });
          return deferred.promise;

        };

        return {
          loadEntityTypeChoices : entityTypeList => {
            var entityTypeChoices = [];
            _.each(entityTypeList, entry => {
              if(entry.value != 'TSK') {
                entityTypeChoices.push({bn: entry.value, displayValue : entry.displayName});
              }
            });
            return entityTypeChoices;
          },
          loadEntityTypeSelectModel : entityTypeChoices => ({
            name: "Entity Types",
            choices: entityTypeChoices,
            selectOptions: {allowClear: true},
            editing: true,
            helpText: "Select Entity Type",
            controlLevel: "EDITABLE"
          }),
          lookupRelationshipTypeSelectModel : () => relationshipDataService.getRelationshipPatterns(null, null),
          loadRelationshipTypeSelectModelFromServiceResults : (entityTypeChoices, results) => {
            var relationshipTypeChoices = []; //something like [{bn : 'SYS-CAP', displayValue: 'System-Capability'}, ...]
            var relationshipTypeKeys = []; //list of keys (e.g. 'SYS-CAP') we've used already to make sure we don't get duplicates
            var entityTypes = []; //list of all entity types (e.g. ['SYS', 'CAP', ...])

            _.each(entityTypeChoices, choice => entityTypes.push(choice.bn));

            _.each(results.data, data => {
              var fromEntityChoice;
              var toEntityChoice;
              if (entityTypes.indexOf(data.fromType) > -1 && entityTypes.indexOf(data.toType) > -1) {
                _.each(entityTypeChoices, entityTypeChoice => {
                  if (data.fromType == entityTypeChoice.bn) fromEntityChoice = entityTypeChoice;
                  if (data.toType == entityTypeChoice.bn) toEntityChoice = entityTypeChoice;
                });
                var entry = {
                  bn: fromEntityChoice.bn + "-" + toEntityChoice.bn,
                  displayValue: fromEntityChoice.displayValue + "-" + toEntityChoice.displayValue
                };
                if (relationshipTypeKeys.indexOf(entry.bn) == -1) {
                  relationshipTypeKeys.push(entry.bn);
                  relationshipTypeChoices.push(entry);
                }
              }
            });

            relationshipTypeChoices.sort((e1, e2) => {
              if (e1.bn < e2.bn) return -1;
              if (e1.bn > e2.bn) return 1;
              return 0;
            });

            return {
              name: "Relationship Types",
              choices: relationshipTypeChoices,
              selectOptions: {allowClear: true},
              editing: true,
              helpText: "Select a relationship",
              controlLevel: "EDITABLE"
            }
          },
          initializeSNAdapterModels : configStr => {
            if (configStr !== undefined && configStr !== null) {
              var configJson = JSON.parse(configStr);

              return {
                entityTypes: configJson.entityTypes,
                relationshipTypes: configJson.relationshipTypes,
                fieldsetBn: configJson.fieldsetBn,
                fieldBn: configJson.fieldBn,
                proposedChanges: configJson.proposedChanges,
                handleConnectionsAsRelationships: configJson.handleConnectionsAsRelationships,
                explodeUpdatesToWorkflow: configJson.explodeUpdatesToWorkflow
              };
            }
          },
          createSNAdapterConfiguration : snModel =>
              JSON.stringify({
                entityTypes: snModel.entityTypes,
                relationshipTypes: snModel.relationshipTypes,
                fieldsetBn: snModel.fieldsetBn,
                fieldBn: snModel.fieldBn,
                proposedChanges: snModel.proposedChanges,
                handleConnectionsAsRelationships: snModel.handleConnectionsAsRelationships,
                explodeUpdatesToWorkflow: snModel.explodeUpdatesToWorkflow
              }),

          generateServiceNowFieldModalBody : snModel => {
            var deferred = $q.defer();
            var allCustomFieldMap = {};
            fieldService.getAllFields().then(results => {
              _.each(results.data, field => allCustomFieldMap[field.bn] = field.fieldId);
              return deferred.resolve(generateFieldModalBody(snModel, allCustomFieldMap));
            });
            return deferred.promise;
          }
        };
      }]);
