angular
  .module('barometerApp.tasks')
  .factory('tasksService', tasksService);

tasksService.$inject = ['tasksDataService', 'urlService', 'utilService','entityService', 'optionListService', '$q', 'alertService', 'errorHandlingService', '$http',  '$timeout', 'graphService'];

/**
 * @author Tim Cramer
 */
function tasksService(tasksDataService, urlService, utilService, entityService, optionListService, $q, alertService, errorHandlingService, $http, $timeout, graphService) {
  //
  var TASK_PERSON_ASSIGNED_TO_RELATIONSHIP_TYPE = 'ASSIGNED_TO';
  var TASK_PERSON_ASSIGNED_BY_RELATIONSHIP_TYPE = 'ASSIGNED_BY';
  var TASK_RESOURCE_RELATIONSHIP_TYPE = "REMEDIATES";
  var TASK_STATUS_OPEN = "Open";
  var TASK_STATUS_COMPLETED = "Completed";
  var TASK_STATUS_ANY = "ANY";

  function getTasksForPersonBn(personBn, relationshipType, taskStatus, first, count, sortProperty, sortDirection){
    var deferred = $q.defer();
    var tasks;
    var totalTasks;
    tasksDataService.getTasksByPersonBnAndRelationshipType(personBn, relationshipType, taskStatus, first, count, sortProperty, sortDirection).then(function(data) {
      tasks = data.rows;
      totalTasks = data.rowCount;
      deferred.resolve({tasks: tasks, totalTasks: totalTasks});
    }, function(error){
      deferred.reject('Error fetching tasks', error);
    });
    return deferred.promise;
  }

  function getOpenTasksAssignedToPersonBn(personBn, first, count) {
    return getTasksForPersonBn(personBn, TASK_PERSON_ASSIGNED_TO_RELATIONSHIP_TYPE, TASK_STATUS_OPEN, first, count, "createDate", "DESC");
  }

  function getCompletedTasksAssignedToPersonBn(personBn, first, count, callback) {
    return getTasksForPersonBn(personBn, TASK_PERSON_ASSIGNED_TO_RELATIONSHIP_TYPE, TASK_STATUS_COMPLETED, first, count, "taskCompletionDate", "DESC");
  }

  function getAllTasksAssignedByPersonBn(personBn, first, count, callback) {
    return getTasksForPersonBn(personBn, TASK_PERSON_ASSIGNED_BY_RELATIONSHIP_TYPE, TASK_STATUS_ANY, first, count, "createDate", "DESC");
  }

  function getTaskRequester(taskBn, callback) {
    tasksDataService.getTaskRequesterByTaskBn(taskBn, TASK_PERSON_ASSIGNED_BY_RELATIONSHIP_TYPE, 1, 1).then(function(data) {
      var requester;
      requester = data.rows[0];
      return callback(requester);
    });
  }

  function getTaskAssignee(taskBn, callback) {
    tasksDataService.getTaskRequesterByTaskBn(taskBn, TASK_PERSON_ASSIGNED_TO_RELATIONSHIP_TYPE, 1, 1).then(function(data) {
      var assignee;
      assignee = data.rows[0];
      return callback(assignee);
    });
  }

  function getTaskDetails(taskBn, callback) {
    var resources;
    var assocs = [];
    var forms = [];
    tasksDataService.getTaskResourcesByTaskBn(taskBn, TASK_RESOURCE_RELATIONSHIP_TYPE, 1, 20).then(function(data) {
      resources = data.rows;

      _.forEach(resources, function(r) {
        if(utilService.getEntityTypeCode(r.bn) != 'RFM') {
          assocs.push(r);
        } else {
          forms.push(r);
        }
      });

      return callback(forms,assocs);

    });

  }

  function updateTask(taskBn, newTaskModel, isCompleted) {
    var deferred = $q.defer();
    var optionListTypes = EntityOptionListTypes['TSK'];
    optionListService.getOptionListChoices(optionListTypes).then(function(data) {
      var taskForUpdate = {
        taskStatus: {}
      };

      taskForUpdate.bn = taskBn;
      taskForUpdate.entityDiscriminator = "TSK";
      taskForUpdate.description = newTaskModel.description;
      // also need to update name, because I made that a requirement for some reason...
      taskForUpdate.name = newTaskModel.description;
      if (isCompleted) {
        taskForUpdate.completionNotes = newTaskModel.completionNotes;
        taskForUpdate.taskCompletionDate = utilService.convertToISOUTCDateTime(moment());
      }

      var taskStatusBn = null;
      _.forEach(data.data, function(option) {
        if(isCompleted && option.dataListItemType == "COMPLETED") {
          taskStatusBn = option.bn;
        } else if (!isCompleted && option.dataListItemType == "OPEN") {
          taskStatusBn = option.bn;
        }
      });
      taskForUpdate.taskStatus.bn = taskStatusBn;
      entityService.updateBasicInfo(taskForUpdate, utilService.getBnCode(taskBn)).then(function(data) {
        var msg = "Task has been updated.";
        if(isCompleted) {
          msg = "Task completed.";
        }
        alertService.addSuccessAlert(msg);
        deferred.resolve(data.data);
      });
    });

    return deferred.promise;
  }

  function removeRemediatedEntityAssoc(taskBn, entityBn, callback) {
    tasksDataService.removeRemediatedEntityAssoc(taskBn, TASK_RESOURCE_RELATIONSHIP_TYPE, entityBn).then(function(data) {
      return callback(data);
    });
  }

  function manageRemediatedEntityAssocs(taskBn, addedBns, removedBns, callback) {
    tasksDataService.manageRemediatedEntityAssocs(taskBn, TASK_RESOURCE_RELATIONSHIP_TYPE, addedBns, removedBns).then(function(data) {
      return callback(data);
    });
  }

  function addRemediatedEntityAssoc(taskBn, entityBn, callback) {
    tasksDataService.addRemediatedEntityAssoc(taskBn, TASK_RESOURCE_RELATIONSHIP_TYPE, entityBn).then(function(data) {
      return callback(data);
    });
  }

  function addAssignee(taskBn, entityBn) {
    tasksDataService.addPersonAssoc(taskBn, TASK_PERSON_ASSIGNED_TO_RELATIONSHIP_TYPE, entityBn).then(function(data) {
    });
  }

  function addRequester(taskBn, entityBn) {
    tasksDataService.addPersonAssoc(taskBn, TASK_PERSON_ASSIGNED_BY_RELATIONSHIP_TYPE, entityBn).then(function(data) {
    });
  }

  var BASE_URL =  urlService.getGraphApiUrl();

  function getTaskProgressStats(params) {

    var token = utilService.readCookie('baroJWTToken');

    var deferred = $q.defer();

    var lastWeek = moment().subtract(7, "days").hours(0).minutes(0).seconds(0).milliseconds(0).utc().format("YYYY-MM-DD");
    var lastMonth = moment().subtract(30, "days").hours(0).minutes(0).seconds(0).milliseconds(0).utc().format("YYYY-MM-DD");

    var restParams = {

      includeEnterprise: params.includeEnterprise,
      includeTeam: params.includeTeam,
      includeRollup: params.includeRollup,
      includePersonal: params.includePersonal,
      personBn: utilService.getCurrentUserBn(),
      groups: [
        {
          endDate : lastMonth,
          color: "red",
          name: "&gt; 30 days old"

        },
        {
          startDate : lastMonth,
          endDate : lastWeek,
          color: "orange",
          name: "7 to 30 days old"
        },
        {
          startDate : lastWeek,
          color:"green",
          name: "&lt; 7 days old"
        }

      ]
    };

    if (!token) {
      return deferred.reject('Authorization token missing');
    } else {
      $http({
        method: 'POST',
        url: BASE_URL + "task",
        headers: {
          'Authorization': 'Bearer ' + token,
          'bit-tenant-bn': utilService.getTenantBn(),
          'Content-Type': 'application/json'
        },
        data: restParams
      }).
      success(function (data, status, headers, config) {
        var ret = {
          data: data
        };
        deferred.resolve(ret);

      }).
      error(function (data, status, headers, config) {
        console.log('error during POST task');
        errorHandlingService.handleGenericError(status);
      });
      return deferred.promise;
    }
  }

  function pollForTaskStatusInGraph(taskBn, pollStatus, pollPeriod, cb) {
    graphService.getAssociatedEntities(taskBn, ['0M']).then( function(data) {
      var dataListItems = data.associatedEntities["0M"];
      if(!dataListItems || dataListItems.length > 1) {
        //something bonked happened
        return;
      }
      if(dataListItems.length < 1) {
        //in transition?
        $timeout(function() {
          pollForTaskStatusInGraph(taskBn, pollStatus, pollPeriod, cb);
        }, pollPeriod);
        return;
      }
      var status = dataListItems[0];
      var statusValue = _.find(status.fields, {name: 'dataListItemType'});
      if(statusValue.values[0].label != pollStatus) {
        //recheck in 2 seconds
        $timeout(function() {
          pollForTaskStatusInGraph(taskBn, pollStatus, pollPeriod, cb);
        }, pollPeriod);
      } else {
        //update event
        cb(taskBn);
      }
    });
  }

  return {

    getOpenTasksAssignedToPersonBn: getOpenTasksAssignedToPersonBn,
    getCompletedTasksAssignedToPersonBn: getCompletedTasksAssignedToPersonBn,
    getAllTasksAssignedByPersonBn: getAllTasksAssignedByPersonBn,
    getTaskAssignee: getTaskAssignee,
    getTaskRequester: getTaskRequester,
    getTaskDetails: getTaskDetails,
    updateTask: updateTask,
    removeRemediatedEntityAssoc: removeRemediatedEntityAssoc,
    addRemediatedEntityAssoc: addRemediatedEntityAssoc,
    manageRemediatedEntityAssocs: manageRemediatedEntityAssocs,
    addAssignee: addAssignee,
    addRequester: addRequester,
    getTaskProgressStats: getTaskProgressStats,
    pollForTaskStatusInGraph: pollForTaskStatusInGraph
  }
}