angular.module('barometerApp.roadmapChart')
  .factory('roadmapChartService', ['$window', '$http', 'utilService', 'workspaceService', 'entityService',
    function ($window, $http, utilService, workspaceService, entityService) {

      return {
        fetchData: fetchData,
        fetchOneRow: fetchOneRow,
        validateLifecycleStateModel: validateLifecycleStateModel
      };
      
      function validateLifecycleStateModel(lsType, startDate, endDate) {

        const errors = [];
        const dateFormat = utilService.getLocaleLongDateFormat();
        const isStartDefined = startDate && startDate.length > 0;
        const isEndDefined = endDate && endDate.length > 0;
        const startMoment = isStartDefined ? moment(startDate, dateFormat, true) : undefined;
        const endMoment = isEndDefined ? moment(endDate, dateFormat, true) : undefined;

        // Must select a type
        if (!lsType) {
          errors.push('Lifecycle state is required.');
        }

        // Must define startDate
        if (!isStartDefined) {
          errors.push('Start date is required.');
        }

        // Dates must be valid date string in the correct format.
        if (isStartDefined && !startMoment.isValid()){
          errors.push('Start date must be in the format:', dateFormat);
        }
        if (isEndDefined && !endMoment.isValid()){
          errors.push('End date must be in the format:', dateFormat);
        }

        // If both dates are defined, start must be before end.
        if (isStartDefined && isEndDefined && startMoment.isAfter(endMoment)) {
            errors.push('Start date must be before end date.');
        }

        return errors;
      }

      // Note, this service encompasses many nested API calls
      // TODO: consolidate API calls with a new batch API mechanism (TBD... maybe never!)
      // @param: callback is called after all data has been collected
      function fetchData (worksheetBn, callback){
        var entities = [];
        workspaceService.getAssociated(worksheetBn).then(function(data){
          processWorkspaceEntities(data, entities, callback);
        });
      }

      function fetchOneRow (associatedEntity, callback) {
        var entityRow = getRow(associatedEntity);
        fetchCurrentLifecycleState(entityRow, [entityRow], callback);
        fetchPlannedLifecycles(entityRow, [entityRow], callback);
      }

      function processWorkspaceEntities(data, entities, callback){
        // Populate entities, formatted for gantt lib
        _.each(data.data.results, function (entityTypeGroup) {
          // Filter out Orgs, Tags, and People which should not be allowed in the first place.  Just in case
          // will need to leave people in if this ever gets changed, as the member table is populated and will try to get pll's for them
          if (entityTypeGroup.entityTypeCode === 'TAG' || entityTypeGroup.entityTypeCode === 'ORG' || entityTypeGroup.entityTypeCode === 'PER'){
            console.error('Ignoring ' +  entityTypeGroup.entityTypeResults.length + ' ' + entityTypeGroup.entityTypeCode + 's, which do not have lifecycle states.');
          }
          else {
            _.each(entityTypeGroup.entityTypeResults, function (entity) {
              entities.push(getRow(entity));
            });
          }

        });

        _.each(entities, function(entity){
          fetchPlannedLifecycles(entity, entities, callback);
          fetchCurrentLifecycleState(entity, entities, callback);
        });
        checkDataCompleteness(entities, callback);
      }

      function fetchCurrentLifecycleState(entity, entities, callback){
        entityService.getBasicInfo(entity.data.bn).then(function(response){
          entity.lifecycleState = {
            label: response.data.lifecycleState.name,
            type: response.data.lifecycleState.type
          };
          checkDataCompleteness(entities, callback);
        });
      }

      function fetchPlannedLifecycles(entity, entities, callback){
         $http({
           method: 'GET',
           url: '/b/api/association',
           params: {
             sourceBn: entity.data.bn,
             sourceBnCode: utilService.getBnCode(entity.data.bn),
             targetBnCode: '49'
           }
         }).
           success(function (data, status, headers, config) {
             entity.plannedLifecycleCount = data.rows.length;
             _.each(data.rows, function(lifeCycle){
               fetchPlannedLifecycleDetails(lifeCycle, entity, entities, callback);
             });
             checkDataCompleteness(entities, callback);
           }).
           error(function (data, status, headers, config) {
             console.error("Error in fetchPlannedLifecycles for entity: " + entity.data.bn, status);
             // TODO: Temp workaround broken services.  Remove when all entity services are completed
             entity.plannedLifecycleCount = 0;
           });
      }

      function fetchPlannedLifecycleDetails(plannedLifecycle, entity, entities, callback){
        $http({
           method: 'GET',
           url: '/b/api/entities/' + plannedLifecycle.entity.bn
         }).success(function(data){

          // Fix date strings for IE compatibility
          data.startDate = convertDate(data.startDate);
          data.endDate = convertDate(data.endDate);

          data.entity = entity.data; // --> Add primary entity to data for later use
          entity.tasks.push(
            {
              "type": data.dataListItem.type.toLowerCase(),
              "from": data.startDate,
              "id": data.bn,
              "subject": data.displayableName,
              "to": data.endDate,
              "data": data  // --> Store complete data here to it can be accessed later
            });
           checkDataCompleteness(entities, callback);
         }).error(function(){
            console.error('Error in fetchPlannedLifecycleDetails: ' + entity.data.bn, status);
           // TODO: Temp workaround broken services.  Remove when all entity services are completed
           if (entity.plannedLifecycleCount > 0){
             entity.plannedLifecycleCount --;
           }
         });
      }

      function checkDataCompleteness(entities, callback){
        var complete = true;

        if (entities.length !== 0){
          _.each(entities, function(entity){
            var hasAllPlannedLifecycles = entity.plannedLifecycleCount === entity.tasks.length;
            var hasCurrentLifecycleState = typeof entity.lifecycleState === 'object';
            if (!hasAllPlannedLifecycles || !hasCurrentLifecycleState){
              complete = false;
              return false;
            }
          });
        }

        if (complete){
          cleanToDates(entities);
          callback(entities);
        }
      }

      function getRow (entity) {
        return {
          "data": {
            "bn": entity.bn,
            "name": entity.name,
            "iconClass": 'icon-' +  EntityBnCodeToIconClass[utilService.getBnCode(entity.bn)]
          },
          "description": entity.name,
          "id": entity.bn,
          "tasks" : []
        }
      }

      // Convert from "2014-09-20T19:36:41.000+0000" to ""2014-09-20"
      // This strips off the time portion of the string, which IE chokes on
      function convertDate(dateString){
        if (dateString){
          return dateString.substr(0,10);
        } else {
          return dateString;
        }
      }

      // Gantt doesn't currently handle null to dates.  We need to find any null to dates on the tasks
      // and set them to the greater of the global toDate (360 days beyond today) or the furthest toDate of the tasks.
      function cleanToDates(entities) {

        // Set baseline max date
        var maxDate = moment().add(360, 'days'); // Go 360 days forward
        var maxDateFormatted = maxDate.format('YYYY-MM-DD');  // Format to match other dates

        // Find max to date
        _.forEach(entities, function (row) {
          var maxTaskToDate = _.max(row.tasks, function (task) {
            return task.to;
          });
          if (maxTaskToDate.to > maxDateFormatted) {
            maxDateFormatted = maxTaskToDate.to;
          }
        });

        // Find any null to dates and set them accordingly
        _.forEach(entities, function (row) {
          _.forEach(row.tasks, function (task) {
            if (!task.to) {
              task.to = maxDateFormatted;
              task.data.noToDate = true;
            }
          });
        });
      }
    }])
  .factory('roadmapChartScaleService', [
    function(){

      var SCALE_FACTORS = {
        year: 0.02,
        month: 0.1,
        week: .4
      };

      // Ratio of pixels to ems in roadmap column widths
      // Warning!  CSS-dependent!!
      var PIXELS_TO_EMS = 13;

      return {
        getScaleFactor: getScaleFactor,
        getDateRange: getDateRange,
        getTailoredScale: getTailoredScale
      };

      // Get a nice-looking scale-factor (determines the width of 1 day, in ems)
      // @param timeScale: one of 'year', 'month', 'week'
      function getScaleFactor (timeScale) {
        return SCALE_FACTORS[timeScale];
      }

      // Calculate a useful date range for a given timeScale and widget size
      // @param timeScale: one of 'year', 'month', 'week'
      // @param roadmapElement: The jQuery element for the roadmap. Its closest visible parent is used as for
      // determining container width.
      function getDateRange (timeScale, roadmapElement){
        var fromDate = new Date();
        var toDate = new Date();
        var containerWidth = roadmapElement.closest(':visible').width();
        var dayRange = Math.round(containerWidth / this.getScaleFactor(timeScale) / PIXELS_TO_EMS);

        // Position range ~1/6 before NOW and ~5/6 after NOW
        var daysBeforeNow = dayRange * 1/6;
        var daysAfterNow = dayRange * 5/6;

        // Add buffer so range overflows width
        daysBeforeNow = Math.round(daysBeforeNow * 1.1);
        daysAfterNow = Math.round(daysAfterNow * 1.1);

        // (Dates in data will expand range)
        fromDate.setDate(new Date().getDate() - daysBeforeNow);
        toDate.setDate(new Date().getDate() + daysAfterNow);

        return {
          fromDate: fromDate,
          toDate: toDate
        }
      };

      function getTailoredScale (lifecycleStates){
        var earliest = moment();
        var latest = moment();
        var diff;

        // Get the range of dates
        _.each(lifecycleStates, function(state){
          if (moment(state.from).isBefore(earliest)){
            earliest = moment(state.from);
          }
          if (moment(state.to).isAfter(latest)){
            latest = moment(state.to);
          }
        });

        diff = moment(latest).diff(earliest, 'days');

        if (diff < 1000){
          return 'month'
        } else {
          return 'year';
        }
      }
  }]);