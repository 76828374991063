/**
 * Created by timstave on 4/22/15.
 */

angular.module('barometerApp.radialChart')
  .controller('RadialChartCtrl', ['$scope', '$timeout', 'radialChartService', 'commonWorksheetService',
    function ($scope, $timeout, radialChartService, commonWorksheetService) {

      // Note that $scope.worksheetBn is inherited from radialgraph directive
      $scope.hasLinks = false;
      $scope.hasData = false;
      $scope.loading = true;
      $scope.browserNotSupported = commonWorksheetService.browserNotSupported('Radial Connection');

      $scope.diagramEntities = {
        name: '',
        depth: 0,
        children: [],
        length: 1
      };

      // Get the inital data load to populate the graph
      commonWorksheetService.getWorksheetEntity($scope.worksheetBn).then(function(entity) {
        getDataFromService();
      });

      $scope.$on('loadSection', function (event, sectionBn) {
        if (sectionBn === 'WORKSHEET'){
          getDataFromService();
        }
      });

      function getDataFromService() {
        $scope.loading = true;
        radialChartService.fetchData($scope.worksheetBn, function (data) {

          $scope.diagramEntities = data.entities;
          $scope.diagramLinks = data.links;

          $scope.loading = false;
          $scope.hasData = data && data.entities.children.length > 0;
          $scope.hasLinks = data && data.links.length > 0;
        });
      }

    }])
  .controller("RadialChartSubheaderCtrl", ['$scope',
    function($scope) {
      $scope.includeEntityTypes = ['SYS'];
    }]);