angular.module('barometerApp.tour')
    .factory('tourService', ['localStorageService', 'urlService', function (localStorageService, urlService) {
      var
      // tour.intro
          getFlagPrefix = function (tourName) {
            return "tour." + tourName
          },
      // Viewed Flag - permanently disables tour when set
          getViewedFlagName = function (tourName) {
            return getFlagPrefix(tourName) + ".viewed";
          },
          setViewedFlag = function (tourName) {
            localStorageService.set(getViewedFlagName(tourName), "true");
          },
          clearViewedFlag = function (tourName) {
            localStorageService.remove(getViewedFlagName(tourName));
          },
          viewedFlagSet = function (tourName) {
            var viewedFlagName = getViewedFlagName(tourName);
            return localStorageService.get(viewedFlagName) === "true";
          },

      // Postponed Flag - disables tour until sessionStorage goes away when set
          getPostponedFlagName = function (tourName) {
            return getFlagPrefix(tourName) + ".postponed";
          },
          setPostponedFlag = function (tourName) {
            if ('sessionStorage' in window && window['sessionStorage'] !== null) {
              window.sessionStorage[getPostponedFlagName(tourName)] = "true";
            }
          },
          clearPostponedFlag = function (tourName) {
            window.sessionStorage.removeItem([getPostponedFlagName(tourName)]);
          },
          postponedFlagSet = function (tourName) {
            var postponedFlagName = getPostponedFlagName(tourName);
            return (postponedFlagName in window.sessionStorage) && window.sessionStorage[postponedFlagName] === "true";
          },
          checkFlagsAndStartTour = function (tourName) {
            //Dont tour on pdfs
            if(urlService.getQueryParamValue('pdf')) {
              return;
            }
            if (!viewedFlagSet(tourName) && !postponedFlagSet(tourName)) {
              // call hopscotch
              if (hopscotch && tourName in tours) {
                hopscotch.startTour(tours[tourName]);
              } else {
                console.error('Tour ' + tourName + ' failed to launch!');
              }
            }
          },
          checkFlagsAndStartTourForIntroOrUpdate = function (tourName) {
            //Dont tour on pdfs
            if(urlService.getQueryParamValue('pdf')) {
              return;
            }
            if (!viewedFlagSet(tourName) && !postponedFlagSet(tourName)) {
              // call hopscotch
              if (hopscotch && tourName in tours) {
                hopscotch.startTour(tours[tourName]);
              } else {
                console.error('Tour ' + tourName + ' failed to launch!');
              }
            }
            // if the intro tour has been viewed/ postponed
            // show the intro-update tour
            else {
              tourName = "intro-update";
              // if the intro has been postponed do not show intro update tour
              // otherwise show the intro update tour
              checkFlagsAndStartTour(tourName);
            }
          },

          tours = {
            "csvImportIntro": {
              id: "csvImportIntro",
              i18n: {
                nextBtn: "Next",
                prevBtn: "Back",
                doneBtn: "Done",
                stepNums: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']
              },
              steps: [
                {
                  target: ".title",
                  fixedElement: true,
                  placement: "bottom",
                  width: 500,
                  title: "Data Import",
                  showCTAButton: true,
                  ctaLabel: "Don't Show Again",
                  onCTA: function () {
                    setViewedFlag("csvImportIntro");
                    hopscotch.endTour();
                  },
                  content: "<p>CSV Imports allow you to quickly import your data into barometerIT. This feature can assist with bootstrapping a new environment or update existing Data.</p>" +
                  "<h3>Lets get started!</h3>"
                },
                {
                  target: ".field-value select",
                  fixedElement: true,
                  placement: "right",
                  yOffset: -20,
                  title: "Select Entity Type",
                  content: "<p>Please select the entity type for your import. Once you select the entity type, your required headers populate the table below.</p>",
                  nextOnTargetClick: true,
                  showNextButton: true,
                  showPrevButton: true
                },
                {
                  target: ".csv-import-headers-help p",
                  fixedElement: true,
                  placement: "top",
                  title: "CSV Layout",
                  xOffset: 500,
                  yOffset: 20,
                  content: "<p>Be aware, to match the input format exactly, you should set all Excel cell data types to plain 'Text' and proof your CSV in a plain text editor after exporting from Excel.</p>"
                },
                {
                  target: ".csv-header",
                  fixedElement: true,
                  placement: "top",
                  yOffset: 20,
                  title: "CSV Header",
                  content: "<p>The 'CSV Header' column displays the required value for the field(s) you wish to update.</p>" +
                  "<p>Required fields for new entities are in <strong>BOLD</strong> text.</p>" +
                  "<p>Updates must use the <strong>BOLD</strong> headers and BN.</p>" +
                  "<p>All other fields are optional.</p>"
                },
                {
                  target: ".csv-display",
                  fixedElement: true,
                  placement: "top",
                  yOffset: 20,
                  title: "CSV Display Value",
                  content: "<p>The 'Display Value Within barometerIT' column displays what you see as the field name in barometerIT. You can change this value in Option List settings.</p>"
                },
                {
                  target: ".csv-value",
                  fixedElement: true,
                  placement: "top",
                  yOffset: 20,
                  title: "CSV Values",
                  content: "<p>The 'Acceptable Values' column displays the required values for each field. You can change these values in Option List settings as well.</p>"
                },
                {
                  target: ".csv-value",
                  fixedElement: true,
                  placement: "top",
                  yOffset: 20,
                  title: "CSV Values Additional Info",
                  content: "<p>When the 'Acceptable Value' is in brackets { }, you may use any value within the provided context.</p>" +
                  "<p>e.g. The 'Acceptable Value' {Any date value in the 'mm/dd/yyyy' format}, may be any date, but must use our date format (e.g. 04/14/2010).</p>" +
                  "<p>Please note, these fields may need formatting if using Microsoft Excel.</p>"
                },
                {
                  target: ".field-value span",
                  fixedElement: true,
                  placement: "right",
                  yOffset: -22,
                  xOffset: -400,
                  title: "CSV Formatting",
                  content: "<p>Formatting a CSV file can be tedious. Keep in mind that the 'CSV Headers' are required, and extra commas (,) can prevent you from importing your data.</p>" +
                  "<p>We recommend you double-check to ensure that your headers are present, and there are no extra commas in your CSV Import file.</p>"
                },
                {
                  target: ".field-value span",
                  fixedElement: true,
                  placement: "right",
                  yOffset: -22,
                  xOffset: -400,
                  title: "Choose File",
                  content: "<p>Once you have formatted and saved your CSV file, you are ready to import!</p>" +
                  "<p>Click the 'Choose File' button and select the file you wish to import.</p>"
                },
                {
                  target: ".btn-primary",
                  fixedElement: true,
                  placement: "right",
                  yOffset: -20,
                  title: "Import",
                  content: "<p>Once you have selected your CSV file, click the <strong>Import Entities</strong> button!</p>" +
                  "<p>Please note that your screen will be blank while barometerIT imports your data. Upon completion, a table detailing your import will load.</p>"
                }
              ],
              // If closed, postpone until next session
              onClose: function () {
                setPostponedFlag("csvImportIntro");
              },
              showPrevButton: true
            }
          };

      return {

        launchTour: function (tourName, clearAlreadyViewedFlags) {
          // TODO: (perhaps) load scripts on demand
          // for now, assume they are loaded already
//                    var scriptUrl = "/b/tours/" + tourName + ".js";
//                    $.getScript....

          // clear flags if requested
          if (clearAlreadyViewedFlags) {
            clearViewedFlag(tourName);
            clearPostponedFlag(tourName);
          }

          // only run tours if localstorage is present (IE8+)
          // we REALLY don't want the tour constantly popping up
          if (localStorageService.isSupported) {
            checkFlagsAndStartTour(tourName);
          }
        }
      }
    }]);