angular.module('barometerApp.dataImport')
  .controller('CsvImportCtrl', ['$scope', 'csvImportService', 'alertService', 'securityService', 'tourService', 'priorityEntitiesService', 'csvUtilsService',
    function ($scope, csvImportService, alertService, securityService, tourService, priorityEntitiesService, csvUtilsService) {

      $(function () {
        tourService.launchTour("csvImportIntro");
      });

      $scope.csvFileUpload = {
        showProgress: true,
        url: '/b/api/csvfileupload',
        response: null
      };

      $scope.results = [];
      $scope.resultsHeaders = [];
      $scope.selectedEntity = {};
      $scope.entityChoices = {};

      // Read available catalogs from the server
      csvUtilsService.getEntityChoices().then(function (choices) {
        $scope.entityChoices.data = choices;
      });

      $scope.importCsvData = function (isInvalid) {
        if ($scope.csvFileUpload.response == null || $scope.selectedEntity.value == null) {
          //No file has been uploaded do nothing
          if ($scope.csvFileUpload.response == null) alertService.addErrorAlert("No file has been uploaded");
          if ($scope.selectedEntity.value == null) alertService.addErrorAlert("Entity type has not been selected");
          return;
        }
        $scope.submitAttempted = true;
        if (!isInvalid) {
          if ($scope.csvFileUpload.error != null) {
            alertService.addErrorAlert($scope.csvFileUpload.error);
          } else {
            csvImportService.importCsvData($scope.csvFileUpload.response, $scope.selectedEntity.value).then(function (data) {
              // assumes data is an array of strings
              $scope.results = data.data;

              // For the data returned by the service, each row could have different keys. So we find all keys for all rows, and sort them
              var allHeaders = [];
              angular.forEach($scope.results, function (result) {
                angular.forEach(result.data, function (value, key) {
                  if (allHeaders.indexOf(key) == -1) {
                    allHeaders.push(key);
                  }
                });
              });
              $scope.resultsHeaders = allHeaders;
              $scope.resultsHeaders.sort();

              //now fill in missing data for each row
              angular.forEach($scope.results, function (result) {
                angular.forEach(allHeaders, function (header) {
                  var keys = Object.keys(result.data);
                  if (keys.indexOf(header) == -1) {
                    result.data[header] = '';
                  }
                });
              });
            })
          }
        } else {
          console.log('error occurred uploading file', $scope.csvFileUpload.response);
        }
      }

    }])
  .controller('CsvAssocImportCtrl', ['$scope', 'csvAssocImportService', 'alertService', 'csvUtilsService',
    function ($scope, csvAssocImportService, alertService, csvUtilsService) {
      $scope.csvFileUpload = {
        showProgress: true,
        url: '/b/api/csvfileupload',
        response: null
      };

      $scope.results = [];
      $scope.selectedEntity = {};
      $scope.entityChoices = {};

      // Read available catalogs from the server
      csvUtilsService.getEntityChoices().then(function (choices) {
        $scope.entityChoices.data = choices;
      });

      $scope.importCsvData = function (isInvalid) {
        if ($scope.csvFileUpload.response == null || $scope.selectedEntity.value == null) {
          //No file has been uploaded do nothing
          if ($scope.csvFileUpload.response == null) alertService.addErrorAlert("No file has been uploaded");
          if ($scope.selectedEntity.value == null) alertService.addErrorAlert("Entity type has not been selected");
          return;
        }
        $scope.submitAttempted = true;
        if (!isInvalid) {
          if ($scope.csvFileUpload.error != null) {
            alertService.addErrorAlert($scope.csvFileUpload.error);
          } else {
            csvAssocImportService.importCsvData($scope.csvFileUpload.response).then(function (data) {
              // assumes data is an array of strings
              $scope.results = data.data;
            })
          }
        } else {
          console.log('error occurred uploading file', $scope.csvFileUpload.response);
        }
      }

    }])
  .controller('CsvAssocRemoveCtrl', ['$scope', 'csvAssocRemoveService', 'alertService', 'csvUtilsService',
    function ($scope, csvAssocRemoveService, alertService, csvUtilsService) {
      $scope.csvFileUpload = {
        showProgress: true,
        url: '/b/api/csvfileupload',
        response: null
      };

      $scope.results = [];
      $scope.selectedEntity = {};
      $scope.entityChoices = {};

      // Read available catalogs from the server
      csvUtilsService.getEntityChoices().then(function (choices) {
        $scope.entityChoices.data = choices;
      });

      $scope.removeCsvData = function (isInvalid) {
        if ($scope.csvFileUpload.response == null || $scope.selectedEntity.value == null) {
          //No file has been uploaded do nothing
          if ($scope.csvFileUpload.response == null) alertService.addErrorAlert("No file has been uploaded");
          if ($scope.selectedEntity.value == null) alertService.addErrorAlert("Entity type has not been selected");
          return;
        }
        $scope.submitAttempted = true;
        if (!isInvalid) {
          if ($scope.csvFileUpload.error != null) {
            alertService.addErrorAlert($scope.csvFileUpload.error);
          } else {
            csvAssocRemoveService.removeCsvData($scope.csvFileUpload.response).then(function (data) {
              // assumes data is an array of strings
              $scope.results = data.data;
            })
          }
        } else {
          console.log('error occurred uploading file', $scope.csvFileUpload.response);
        }
      }

    }])
  .controller('CsvAssocImportHelpCtrl', ['$scope', '$q', 'optionListService', 'relationshipDataService', 'fieldsetService', 'fieldService', 'csvHelpService',
    function ($scope, $q, optionListService, relationshipDataService, fieldsetService, fieldService, csvHelpService) {
      $scope.basicInfo = [];
      $scope.fieldList = [];
      $scope.optionLists = [];
      $scope.fieldList.loaded = false;
      $scope.optionLists.loaded = false;
      var optionListTypes = [];
      var entityType = '';

      entityType = csvHelpService.getEntityTypeCodeFromImport();

      let relationshipPatternPromises = $q.defer();

      // Build the row data for each entity type.
      $scope.rowData = {};

      // Fetch the Relationship Patterns.
      let relationshipPatternPromise = relationshipDataService.getRelationshipPatterns(entityType);

      // Once you have all the patterns for an entity Type, make some rows.
      relationshipPatternPromise.then(function (results) {
        let patternArray = results.data;

        _.forEach(patternArray, function (pattern) {
          let row = null;

          // Move onto the next Entity Type
          // if it's not in the list.
          if (!entityLabels[pattern.fromType] || !entityLabels[pattern.toType] || pattern.relationshipType.type === "IS_WATCHING") {
            return;
          }

          // Get the entity type for the "ENTITY BEING ASSOCIATED TO"
          let subjectType = (pattern.fromType !== entityType) ? pattern.fromType : pattern.toType;
          let subjectName = entityLabels[subjectType].name;

          // Check the rowData for an entry that matches the contain object.
          row = ($scope.rowData[subjectName] != null) ? $scope.rowData[subjectName] : {};

          // Setup basic row data.
          row.ASSOCD_ENTITY = (row.ASSOCD_ENTITY != null) ? row.ASSOCD_ENTITY : subjectName;
          row.FROM_BN = (row.FROM_BN != null) ? row.FROM_BN : entityLabels[pattern.fromType].label;
          row.TO_BN = (row.TO_BN != null) ? row.TO_BN : entityLabels[pattern.toType].label;

          // Setup sub-row data.
          row.RELATIONSHIP_TYPES = (row.RELATIONSHIP_TYPES != null) ? row.RELATIONSHIP_TYPES : {};

          // Add data to the sub-row columns.
          let relType = pattern.relationshipType.type;
          if (!row.RELATIONSHIP_TYPES.hasOwnProperty(relType)) {
            row.RELATIONSHIP_TYPES[relType] = {
              "display": relType,
              "qualifiers": [],
              "qualifierStrings": ""
            };
          }

          // If the Qualifier is not in the array, add it.
          let qualifier = pattern.qualifier;
          if (qualifier != null) {
            if (row.RELATIONSHIP_TYPES[relType].qualifiers.indexOf(qualifier.name) <= -1) {
              row.RELATIONSHIP_TYPES[relType].qualifiers.push(qualifier.name);
            }
          }

          //Stringify the Arrays.
          _.forEach(row.RELATIONSHIP_TYPES, function (type) {
            type.qualifierStrings = (type.qualifiers.length === 0) ? " " : type.qualifiers.join(', ');
          });

          // Label the row and add it to the rowData object.
          $scope.rowData[entityLabels[subjectType].name] = row;
        });
      });
      relationshipPatternPromises.resolve();

      $q.all(relationshipPatternPromises).then(function () {
        $scope.optionLists.loaded = true;
      });

    }])
  .controller('CsvImportHelpCtrl', ['$scope', '$q', 'optionListService', 'dataDictionaryHeaderService', 'fieldsetService', 'fieldService', 'csvHelpService',
    'utilService', '$filter',
    function ($scope, $q, optionListService, dataDictionaryHeaderService, fieldsetService, fieldService, csvHelpService, utilService, $filter) {
      $scope.basicInfo = [];
      $scope.fieldList = [];
      $scope.optionLists = [];
      $scope.dataDictionaryHeaders = [];
      $scope.fieldList.loaded = false;
      $scope.optionLists.loaded = false;
      var optionListTypes = [];
      var entityType = '';

      function resolveFields(results, deferredItemList) {
        var choices = '';
        var result = {'fieldId': results.data.fieldId, 'displayName': results.data.name};
        if (results.data != null) {
          if (!utilService.isControlLevelVisible(results.data)) {
            result.choices = 'You do not have permission to view this custom field.';
            result.isVisible = false;
            result.isEditable = false;
          } else if (!utilService.isControlLevelEditable(results.data)) {
            result.choices = 'You do not have permission to edit this custom field.';
            result.isVisible = true;
            result.isEditable = false;
          } else if (results.data.choices.length != 0) {
            result.isVisible = true;
            result.isEditable = true;
            for (var i = 0; i < results.data.choices.length; i++) {
              var choice = results.data.choices[i];
              if (i == 0) {
                choices = choice.storedValue;
              } else {
                choices += ", " + choice.storedValue;
              }
            }
            result.choices = choices;
          } else {
            result.isVisible = true;
            result.isEditable = true;
            if (results.data.dataType == "DATE") {
              result.choices = '{Any date value in the "mm/dd/yyyy" format}';
            } else if(results.data.displayType && results.data.displayType === "URL_LINK") {
              result.choices = '{Any URL}';
            } else {
              result.choices = String.format('{Any {0} value}', angular.lowercase(results.data.dataType));
            }
          }
          $scope.fieldList.loaded = false;
          $scope.fieldList.push(result);
        }
        deferredItemList.resolve();
      }

      // variables for completing promises ex from :http://www.devineloper.com/2014/07/29/complex-promises-angularjs/
      var promises = [];

      // If the entity type code is set, set it to the entityType variable
      entityType = csvHelpService.getEntityTypeCodeFromImport();

      // Begin Code for gathering CustomField data for header table output
      // assign the fieldsets to fieldSetPromise for AJAX request (promise)
      var fieldSetPromise = fieldsetService.getFieldsetsForEntityType(entityType);

      fieldSetPromise.then(function (results) {

        // Iterate through each fieldset and field to get the field bns, Then add each field to $scope.fieldList
        _.forEach(results.data, function (fieldSets, key) {

          _.forEach(fieldSets.fields, function (field, key) {

            // the $q service is used for promises/ deferment of ajax requests
            var deferredItemList = $q.defer();
            var fieldPromise = fieldService.getField(field.bn);

            // Add each choice to its corresponding field (if there are choices/ options available)
            fieldPromise.then(function (results) {
              resolveFields(results, deferredItemList);
            }, function (results) {
              results.data = null;
              resolveFields(results, deferredItemList);
            });

            // add promise to 'promises' array, to be checked with all() to assert all promises are completed
            promises.push(deferredItemList.promise);
          });
        });

        // Once all promises are completed, load table to page and set loaded=true for table loading (ng-if)
        $q.all(promises).then(function () {
          $scope.fieldList = _.uniq($scope.fieldList, function (item, key, fieldId) {
            return item.fieldId
          });
          $scope.fieldList = $filter('orderBy')($scope.fieldList, 'displayName', false);
          $scope.fieldList.loaded = true;
        });
      });

      // Begin Code for gathering OptionList data for header table output
      optionListTypes = EntityOptionListTypes[entityType];
      var optionListPromise = optionListService.getOptionListChoices(optionListTypes);
      var optionListPromises = $q.defer();
      $scope.optionListsFinal = [];

      optionListPromise.then(function (results) {

        $scope.optionLists.data = [];
        _.forEach(results.data, function (item) {

          if (!$scope.optionLists.data[item.dataListType]) {
            $scope.optionLists.data[item.dataListType] = [];
          }
          $scope.optionLists.data[item.dataListType].push(item);

        });
        optionListPromises.resolve();

      });

      // Once all promises are completed, concat all options for each optionList into one string
      $q.all(optionListPromises).then(function () {

        var buildOptionListHeaderName = function (optionList) {
          _.forEach(optionListTypes, function (option) {
            var csvHeadName = '';
            angular.forEach(optionList.data[option.dataListType], function (value) {
              if (!$scope.optionLists.data[value.dataListType].csvHeadName) {
                $scope.optionLists.data[value.dataListType].csvHeadName = '';
                csvHeadName = formatStringSpaceToUnderscoreAndUpperCase(value.dataListName);
              }
            });
            $scope.optionLists.data[option.dataListType].csvHeadName = csvHeadName;
          });

        };


        var buildOptionListOptions = function (optionList) {

          _.forEach(optionListTypes, function (option) {
            var options = '';
            angular.forEach(optionList.data[option.dataListType], function (value) {
              if (!$scope.optionLists.data[value.dataListType].options) {
                $scope.optionLists.data[value.dataListType].options = [];
                options = value.displayableName;
              } else {
                options += ", " + value.displayableName;
              }

            });
            $scope.optionLists.data[option.dataListType].options.push(options);
          });

        };

        buildOptionListOptions($scope.optionLists);
        buildOptionListHeaderName($scope.optionLists);
        $scope.optionLists.loaded = true;
      });

      // Begin Code for gathering DataDictionaryNames for header table ouput
      var dataDictionaryHeaderPromise = dataDictionaryHeaderService.getDataDictionaryHeaders(entityType);
      var dataDictionaryHeaderPromises = $q.defer();
      var formatStringSpaceToUnderscore = function (string) {
        var str = string;
        str = str.replace(/\s+/g, '_');
        return str;
      };
      var formatStringSpaceToUnderscoreAndUpperCase = function (string) {
        return formatStringSpaceToUnderscore(string).toUpperCase();
      };
      dataDictionaryHeaderPromise.then(function (results) {
        $scope.dataDictionaryHeaders.data = [];
        _.forEach(results.data, function (item) {
          item.propertyName = formatStringSpaceToUnderscoreAndUpperCase(item.name);
          item.acceptableValue = '';
          item.csvHeadName = '';
          var skipFormat = false;

          if (!$scope.dataDictionaryHeaders.data[item.propertyName]) {
            $scope.dataDictionaryHeaders.data[item.propertyName] = [];
          }

          switch (item.name) {
            case 'Acronym':
              item.acceptableValue = '{Any text value}';
              break;
            case 'BN':
              item.acceptableValue = '{barometerIT specific ID}';
              item.name = "Barometer_No";
              break;
            case 'First Name':
              item.acceptableValue = '{Any text value}';
              break;
            case 'Last Name':
              item.acceptableValue = '{Any text value}';
              break;
            case 'Name':
              item.acceptableValue = '{Any text value}';
              break;
            case 'Description':
              item.acceptableValue = '{Any text value}';
              break;
            case 'Content Status':
              item.acceptableValue = 'Approved, Completed, Identified, In Process, Planned';
              break;
            case 'Color':
              item.acceptableValue = '{Any hexadecimal color value, without the leading #}';
              break;
            case 'Enterprise Approval':
              item.acceptableValue = 'Yes, No';
              break;
            case 'Pattern':
              item.acceptableValue = 'API Call, Client/Server, Database, Program/Module, Publish/Subscribe, Request/Reply, Send/Receive, Staged File';
              break;
            case 'Reference ID':
              item.acceptableValue = '{Entity specific Reference ID}';
              break;
            case 'Ref ID':
              item.acceptableValue = '{Entity specific Reference ID}';
              break;
            case 'Status':
              if (entityType === 'PER') {
                item.acceptableValue = 'Active, Inactive';
              }
              break;
            case 'Type':
              if (entityType === 'CON') {
                item.acceptableValue = 'Database, Direct Call, File, Messaging, Remote Invocation, Web Service';
              } else if (entityType === 'STA') {
                item.acceptableValue = 'Standard, Standard Release';
              } else if (entityType === 'TEC') {
                item.acceptableValue = 'Technology, Technology Edition, Technology Group, Technology Release, Technology Release Set';
              }
              break;
            case 'Source Format':
              item.csvHeadName = 'SOURCE FORMAT';
              item.name = 'Format(Source)';
              skipFormat = true;
              break;
            case 'Target Format':
              item.csvHeadName = 'TARGET FORMAT';
              item.name = 'Format(Target)';
              skipFormat = true;
              break;
            case 'URL':
              item.acceptableValue = '{Any valid URL}';
              break;
            default:
              break;
          }
          if (!skipFormat) {
            item.csvHeadName = formatStringSpaceToUnderscore(item.name).toUpperCase();
          }
          $scope.dataDictionaryHeaders.data[item.propertyName].push(item);
        });
        dataDictionaryHeaderPromises.resolve();

      });


    }])
;
