angular.module('barometerApp.entity')
  .controller('EntityCollectionLayoutCtrl', ['$rootScope', '$scope', '$timeout', 'utilService', 'urlService', 'layoutService', 'searchService', 'pageService', 'recentLocationsService', 'bitConstants',
    function ($rootScope, $scope, $timeout, utilService, urlService, layoutService, searchService, pageService, recentLocationsService, bitConstants) {
      $scope.layoutModel = {
        layout: PageNameToLayout[urlService.getPageName()]
      };
      layoutService.setCurrentLayout($scope.layoutModel.layout);

      $scope.showWarningBanner = false;
      $scope.isInsightEntity = utilService.isBn(PageIdentifierToEntityTypeCode[urlService.getPageName()]);
      $scope.loadCount = function (queryKey) {
        var worksheetType = '';
        if ($scope.layoutModel.layout.bn === 'WK2') {
          worksheetType = getSectionType($scope.layoutModel.layout.sections);
        }

        // BARO-19654: We are deprecating the Org coverage reports. This if statement is
        // here to add warning banner to the Org coverage reports collection page.
        $scope.showWarningBanner = (worksheetType === 'ORGANIZATIONCOVERAGEREPORTBROWSE');


        var countPromise = searchService.getCountForSingleEntityCollectionPage(
          queryKey, EntityTypes[urlService.getEntityTypeCodeOfCurrentPage()].bnCode, worksheetType);

        if (urlService.getEntityTypeCodeOfCurrentPage() == "LYT") {
          countPromise = searchService.getCountForDSHEntityCollectionPage(queryKey, '1L');
        }

        countPromise.then(function (data) {
          $scope.layoutModel.collectionCount = data.data;
          pageService.setTitle($scope.layoutModel.collectionCount + " " + $scope.layoutModel.layout.name);
        });
      };

      $scope.loadCount(urlService.getQueryKey());

      // make call to load recents data.  notice we are not adding a new recents record for collection pages.
      recentLocationsService.getRecentsFromServerAndNotify();

      $scope.$on('sectionRenderComplete', function (event) {
        prototype.init();
        $scope.$broadcast("loadAllVisibleSections");
      });

      $scope.$on('refreshCollectionCount', function (evt, newQueryKey) {
        $scope.loadCount(newQueryKey);
      });

      function getSectionType(sections) {
        return (sections[0].type).toUpperCase();
      }

    }])
;
