angular
  .module('barometerApp.tasks')
  .controller('TaskActivityModalController', TaskActivityModalController);

TaskActivityModalController.$inject = ['$scope', 'utilService', 'activityService', 'alertService', 'urlService', 'styleService', 'bitConstants', '$timeout'];

/**
 * See https://barometer.jira.com/browse/BARO-17340.
 *
 * @author Tim Cramer
 */
function TaskActivityModalController($scope, utilService, activityService, alertService, urlService, styleService, bitConstants, $timeout) {

  var taskBn = urlService.getEntityBn();

  $scope.taskActivity = {};
  $scope.taskActivity.loaded = false;

  $scope.bsDateFormat = utilService.getDateFormatForBootstrapDatepicker();
  var date = moment().format('L');

  $scope.dateInfo = {};
  $scope.dateInfo.eventDate = date;
  $scope.dateInfo.bsDateFormat = utilService.getDateFormatForBootstrapDatepicker();

  var getEventDate = function (date) {
    if (!moment(date, 'L').isValid()) {
      return moment();
    }
    else {
      return moment(date.toString(), 'L');
    }
  };

  $scope.close = function () {
    // This triggers the function from the callee's scope
    this.closeTaskActivityModal();
  };

  $scope.activityModel = {
    loaded: false,
    events: {},
    loadDate: moment().format("LT"),
    eventDate: moment().format('L'),
    unformattedEventDate: moment().format("L"),
    pageSize: 10,
    //start it at 1 so we don't need to do any goofy math when we click it next time or are calculating rowsDisplayed
    loadMoreClicked: 1,
    prevDate: null,
    nextDate: null,
    tableType: 'undefined',
    isNews: 'undefined',
    todaysDate: moment().format("LL")
  };

  // When bs-datepicker init's, it nulls out it's model (IDK why).
  // So we need to re-set the model after letting it init.
  $timeout(function(){
    $scope.activityModel.unformattedEventDate = moment().format("L");
  });

  $scope.loadDate = function (direction) {
    $scope.activityModel.eventDate = direction === 'next' ? $scope.activityModel.nextDate : $scope.activityModel.prevDate;
    $scope.activityModel.unformattedEventDate = moment($scope.activityModel.eventDate, "L").format("L");
    $scope.getTaskActivity();
  };

  $scope.contributionStream = {};

  $scope.contributionStream.pagination = {
    noOfPages: 1,
    currentPage: 1,
    numRecords: 0,
    pageSize: 10,
    rowCount: 0,
    maxSize: 100
  };

  $scope.contributionStream.requestConfig = {
    dateRange: $scope.dateInfo.eventDate,
    dayRange: 7,
    tableType: 'ContributionActivityStream',
    eventDate: getEventDate($scope.dateInfo.eventDate),
    pageSize: 10,
    searchString: '',
    sortProperty: $scope.contributionStream.sortColumn,
    roleString: '',
    pageIndex: $scope.contributionStream.pagination.currentPage
  };

  $scope.sortColumn = function (sortProperty) {
    if ($scope.contributionStream.sortColumn.indexOf(sortProperty) > -1) {
      $scope.contributionStream.sortColumn = $scope.contributionStream.sortColumn.indexOf('descending') > -1 ? sortProperty + '_ascending' : sortProperty + '_descending';
    } else {
      $scope.contributionStream.sortColumn = sortProperty + '_descending';
    }
    $scope.contributionStream.requestConfig.sortProperty = $scope.contributionStream.sortColumn;
    $scope.getData();
  };

  window.calculateDateDiff = function (dateTime) {
    return moment(dateTime).fromNow();
  };

  $scope.getTaskActivity = function () {

    var historyParams = {
      // dateRange: $scope.dateInfo.eventDate,
      // dayRange: 7,
      // tableType: 'ContributionActivityStream',
      // eventDate: getEventDate($scope.dateInfo.eventDate),
      // pageSize: 10,
      // searchString: '',
      // sortProperty: $scope.contributionStream.sortColumn,
      // roleString:'',
      // pageIndex: $scope.contributionStream.pagination.currentPage || 0
      sourceBn: $scope.task ? $scope.task.bn : taskBn,
      pageIndex: 0,
      pageSize: $scope.activityModel.pageSize * $scope.activityModel.loadMoreClicked,
      eventDate: getEventDate($scope.activityModel.eventDate),
      tableType: "TaskActivityStream"
    };
    activityService.getActivities(historyParams).then(function (data) {
      $scope.taskActivity.loaded = true;
      _.each(data.data.activities, function (obj, index) {
        if (!obj.activity) {
          return;
        }
        obj.activity.dateString = calculateDateDiff(obj.activity.eventTime);
        obj.activity.eventTimeString = moment(obj.activity.eventTime).format("MMMM Do YYYY, h:mm:ss a ");
        obj.activity.personUrl = obj.activity.actingPersonBn !== "SYSTEM" ? urlService.getBaseUrl() + "profile/" + obj.activity.actingPersonBn : null;
        if (obj.activity.entityBn) {
          obj.activity.entityLink = {
            name: obj.activity.entityName || 'N/A', bn: obj.activity.entityBn, isVisible: true//Copy table-directive handling of isVisible
          };
          obj.activity.entityIconClass = styleService.getIconClassForEntityTypeCode(bitConstants.getEntityTypeForBnCode(utilService.getBnCode(obj.activity.entityBn)).typeCode);
        }
      });
      $scope.activityModel.nextDate = data.data.nextDataDate === null ? null : moment(data.data.nextDataDate).format('l');
      $scope.activityModel.prevDate = data.data.previousDataDate === null ? null : moment(data.data.previousDataDate).format('l');
      $scope.activityModel.totalRows = data.data.rowCount;
      $scope.activityModel.loaded = true;
      $scope.activityModel.activities = data.data.activities;
      $scope.activityModel.rowsDisplayed = $scope.activityModel.pageSize * ($scope.activityModel.loadMoreClicked);
    });
  };

  $scope.reloadSection = function () {
    $scope.taskActivity.loaded = false;
    $scope.activityModel.loadMoreClicked = 1;
    $scope.activityModel.events = {};
    $scope.activityModel.eventDate = moment().format('L');
    $scope.activityModel.unformattedEventDate = moment().format("L");
    $scope.activityModel.loadDate = moment().format("LT");
    $scope.getTaskActivity();
  };

  $scope.loadMore = function () {
    $scope.activityModel.loadMoreClicked++;
    //load more activities if <= 500 are being requested
    if ($scope.activityModel.loadMoreClicked <= 20) {
      $scope.getTaskActivity();
    } else {
      alertService.addAlert({
        type: 'error', message: 'You can only view 500 activities at a given time'
      })
    }
  };

  $scope.getDetailsClass = function (len) {
    return len > 0 ? 'icon-caret-hide' : 'icon-caret-show';
  };

  $scope.snagDetails = function (activity) {
    if ($scope.activityModel.events[activity.eventTime + (activity.personBn || activity.entityBn)] && $scope.activityModel.events[activity.eventTime + (activity.personBn || activity.entityBn)].length > 0) {
      $scope.activityModel.events[activity.eventTime + (activity.personBn || activity.entityBn)].length = 0;
      return;
    }

    var params = {
      sourceBn: $scope.task ? $scope.task.bn : taskBn,
      entityBn: activity.entityBn,
      eventDate: $scope.activityModel.eventDate
    };
    params.tableType = "TaskActivityStream";
    params.eventDate =
      activityService.getActivityDetails(params).then(function (data) {
        _.each(data.data.events, function (event, index) {
          event.dateString = calculateDateDiff(event.eventTime);
          event.personUrl = event.actingPersonBn !== "SYSTEM" ? urlService.getBaseUrl() + "profile/" + event.actingPersonBn : null;
        });
        if (data.data.events.length) {
          var evt = data.data.events[0];
          activity.dateString = calculateDateDiff(evt.eventTime);
          activity.actingPersonName = evt.actingPersonName;
          activity.personUrl = evt.actingPersonBn !== "SYSTEM" ? urlService.getBaseUrl() + "profile/" + evt.actingPersonBn : null;
        }
        $scope.activityModel.events[activity.eventTime + (activity.personBn || activity.entityBn)] = data.data.events;
      });
  };
  $scope.getTaskActivity();
}