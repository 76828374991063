angular.module('barometerApp.typeAhead')

/**
 * A helper service that can parse typeahead's syntax (string provided by users)
 * Extracted to a separate service for ease of unit testing
 */
  .factory('baroTypeaheadParser', ['$parse', function ($parse) {

    //                      00000111000000000000022200000000000000003333333333333330000000000044000
    var TYPEAHEAD_REGEXP = /^\s*(.*?)(?:\s+as\s+(.*?))?\s+for\s+(?:([\$\w][\$\w\d]*))\s+in\s+(.*)$/;

    return {
      parse: function (input) {

        var match = input.match(TYPEAHEAD_REGEXP), modelMapper, viewMapper, source;
        if (!match) {
          throw new Error(
            "Expected typeahead specification in form of '_modelValue_ (as _label_)? for _item_ in _collection_'" +
            " but got '" + input + "'.");
        }

        return {
          itemName: match[3],
          source: $parse(match[4]),
          viewMapper: $parse(match[2] || match[1]),
          modelMapper: $parse(match[1])
        };
      }
    };
  }])

  .directive('baroTypeahead', ['$compile', '$parse', '$q', '$document', '$position', 'baroTypeaheadParser', '$rootScope', '$timeout', function ($compile, $parse, $q, $document, $position, typeaheadParser, $rootScope, $timeout) {

    var HOT_KEYS = [9, 13, 27, 38, 40];

    return {
      require: 'ngModel',
      link: function (originalScope, element, attrs, modelCtrl) {
        var selected;

        //minimal no of characters that needs to be entered before typeahead kicks-in
        var minSearch = originalScope.$eval(attrs.typeaheadMinLength) || 1;

        //expressions used by typeahead
        var parserResult = typeaheadParser.parse(attrs.baroTypeahead);

        //minimal wait time after last character typed before typehead kicks-in
        var waitTime = originalScope.$eval(attrs.typeaheadWaitMs) || 0;

        var relationshipType = originalScope.$eval(attrs.relationshipType || "");

        //should it restrict model values to the ones selected from the popup only?
        var isEditable = originalScope.$eval(attrs.typeaheadEditable) !== false;

        var isLoadingSetter = $parse(attrs.typeaheadLoading).assign || angular.noop;
        //pop-up element used to display matches
        var popUpEl = angular.element(
          "<div baro-typeahead-popup " +
          "matches='matches' " +
          "active='activeIdx' " +
          "select='select(activeIdx)' " +
          "template-type='" + attrs.templateType + "'" +
          "relationship-type='relationshipType'" +
          "entity-type='entityType'" +
          "query='query' " +
          "position='position'>" +
          "</div>");

        //create a child scope for the typeahead directive so we are not polluting original scope
        //with typeahead-specific data (matches, query etc.)
        var scope = originalScope.$new();
        originalScope.$on('$destroy', function () {
          $timeout(function(){
            scope.$destroy();
          });
        });

        scope.templateType = attrs.templateType;
        var resetMatches = function () {
          scope.matches = [];
          scope.activeIdx = -1;
        };


        // TODO: replace this code with a throttle directive that calls the search service, and an event/callback that the search service callsback to update the matches
        // (this breaks in angular 1.2 rc2)
        var getMatchesAsync = function (inputValue) {
          if (typeof inputValue === "object") return;

          var locals = {$viewValue: inputValue};
          isLoadingSetter(originalScope, true);
          $q.when(parserResult.source(scope, locals)).then(function (matches) {

            //it might happen that several async queries were in progress if a user were typing fast
            //but we are interested only in responses that correspond to the current view value
            if (inputValue === modelCtrl.$viewValue) {
              if (matches && matches.length > 0) {

                scope.activeIdx = 0;
                scope.matches.length = 0;

                //transform labels
                for (var i = 0; i < matches.length; i++) {
                  locals[parserResult.itemName] = matches[i];
                  scope.matches.push({
                    label: parserResult.viewMapper(scope, locals),
                    model: matches[i]
                  });
                }

                scope.query = inputValue;
                //position pop-up with matches - we need to re-calculate its position each time we are opening a window
                //with matches as a pop-up might be absolute-positioned and position of an input might have changed on a page
                //due to other elements being rendered
                scope.position = $position.position(element);
                scope.position.top = scope.position.top + element.prop('offsetHeight');

              } else {
                resetMatches();
              }
              isLoadingSetter(originalScope, false);
            }
          }, function () {
            resetMatches();
            isLoadingSetter(originalScope, false);
          });
        };

        resetMatches();

        //we need to propagate user's query so we can higlight matches
        scope.query = undefined;

        var timeoutPromise;

        //plug into $parsers pipeline to open a typeahead on view changes initiated from DOM
        //$parsers kick-in on all the changes coming from the view as well as manually triggered by $setViewValue
        modelCtrl.$parsers.push(function (inputValue) {

          resetMatches();
          if (waitTime > 0) {
            if (timeoutPromise) {
              $timeout.cancel(timeoutPromise);
            }
            timeoutPromise = $timeout(function () {
              getMatchesAsync(inputValue);
            }, waitTime);
          }
          else {
            getMatchesAsync(inputValue);
          }

          return isEditable ? inputValue : undefined;
        });

        modelCtrl.$render = function () {
          var locals = {};
          locals[parserResult.itemName] = selected || modelCtrl.$viewValue;
          element.val(parserResult.viewMapper(scope, locals) || modelCtrl.$viewValue);
          selected = undefined;
        };

        scope.select = function (activeIdx) {
          //called from within the $digest() cycle
          var locals = {};
          locals[parserResult.itemName] = selected = scope.matches[activeIdx].model;
          modelCtrl.$setViewValue(parserResult.modelMapper(scope, locals));
          modelCtrl.$render();
        };

        //bind keyboard events: arrows up(38) / down(40), enter(13) and tab(9), esc(27)
        element.bind('keydown', function (evt) {

          //typeahead is open and an "interesting" key was pressed
          if (scope.matches.length === 0 || HOT_KEYS.indexOf(evt.which) === -1) {
            return;
          }

          evt.preventDefault();

          if (evt.which === 40) {
            scope.activeIdx = (scope.activeIdx + 1) % scope.matches.length;
            scope.$digest();

          } else if (evt.which === 38) {
            scope.activeIdx = (scope.activeIdx ? scope.activeIdx : scope.matches.length) - 1;
            scope.$digest();

          } else if (evt.which === 13 || evt.which === 9) {
            scope.$apply(function () {
              scope.select(scope.activeIdx);
            });

          } else if (evt.which === 27) {
            evt.stopPropagation();

            resetMatches();
            scope.$digest();
          }
        });
        $document.bind('click', function () {
          resetMatches();
          scope.$digest();
        });
        scope.$on("PositionTypeahead", function (blegh, data) {
          scope.position = $position.position(element);
          scope.position.top = scope.position.top + element.prop('offsetHeight');
        })

        element.after($compile(popUpEl)(scope));
      }
    };

  }])

  .directive('baroTypeaheadPopup', ['$rootScope', '$position', function ($rootScope, $position) {
    return {
      restrict: 'A',
      scope: {
        matches: '=',
        query: '=',
        active: '=',
        position: '=',
        relationshipType: '=',
        select: '&',
        templateType: '@',
        entityType: '='
      },
      replace: true,
      templateUrl: function (tElement, tAttrs) {
        tAttrs.templateType = tAttrs.templateType === "undefined" ? "" : tAttrs.templateType;
        return '/b/js/src/bit.ng/baro/typeahead/' + tAttrs.templateType + 'typeahead.html';
      },
      link: function (scope, element, attrs) {

        var beenCalled = false;
        scope.$on("SearchTermSelected", function (event, data) {
          scope.$emit("PositionTypeahead");
          beenCalled = data;
        });
        scope.getQuickAddDisplay = function (entityType) {
          return String.format("Create a new {0}", entityType.displayName);
        };
        scope.isOpen = function () {
          return beenCalled || scope.matches.length > 0;
        };
        scope.openDialog = function (dialog) {
          $rootScope.$broadcast("OpenDialog", dialog);
        };
        scope.isActive = function (matchIdx, isAssociated) {
          //If already associated, do not highlight
          if (isAssociated) return false;
          return scope.active === matchIdx;
        };

        scope.selectActive = function (matchIdx, isAssociated) {
          //If already associated, do not highlight
          if (isAssociated) return;
          scope.active = matchIdx;
        };
        scope.selectMatch = function (activeIdx, isAssociated) {
          //If already associated, do not select
          if (isAssociated) return;
          scope.select({activeIdx: activeIdx});
        };
      }
    };
  }])

  .filter('typeaheadHighlight', function () {

    function escapeRegexp(queryToEscape) {
      return queryToEscape.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");
    }

    return function (matchItem, query) {
      return query ? matchItem.replace(new RegExp(escapeRegexp(query), 'gi'), '<strong>$&</strong>') : query;
    };
  });