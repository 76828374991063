// A bean is any simple persistent entity w/o associations.
angular.module('barometerApp.bean')
  .service('beanService', ['$http', '$rootScope', 'alertService', 'utilService',
    function ($http, $rootScope, alertService, utilService) {
      return {
        getBean: function (bn) {
          utilService.assertIsBn(bn);
          return $http({method: 'GET', url: '/b/api/entities/' + bn}).
            success(function (data, status, headers, config) {
            }).
            error(function (data, status, headers, config) {
              console.log('error in beanService.getBean for bn: ' + bn);
            });
        },
        createBean: function (beanModel, entityDiscriminator) {
          // TODO Try to add entityDiscriminator earlier. Make it intrinsic to the beanModel instead of a tack-on.
          beanModel.entityDiscriminator = entityDiscriminator;
          var entityJson = angular.toJson(beanModel);
          return $http({
            method: 'POST',
            url: '/b/api/entities/',
            data: entityJson,
            headers: {'Content-Type': 'application/json'}
          }).
            success(function (data, status, headers, config) {
            }).
            error(function (data, status, headers, config) {
              console.log('error in beanService.createBean');
              var msgString = "Unable to edit entity";
              if (status >= 400 && data.violations) {
                msgString += ': ' + data.violations;
              }
              alertService.addErrorAlert(msgString);
            });
        },
        updateBean: function (beanModel, entityDiscriminator, errorCallback) {
          // TODO Try to add entityDiscriminator earlier. Make it intrinsic to the beanModel instead of a tack-on.
          beanModel.entityDiscriminator = entityDiscriminator;
          var entityJson = angular.toJson(beanModel);
          return $http({
            method: 'PUT',
            url: '/b/api/entities',
            data: entityJson,
            headers: {'Content-Type': 'application/json'}
          }).
            success(function (data, status, headers, config) {
            }).
            error(function (data, status, headers, config) {
              console.log('error in beanService.updateBean');
              if (status === 409) {
                $rootScope.$broadcast('errorAlertMessage', validationMsg);
              }
              if (errorCallback) {
                errorCallback(data);
              }
            });
        },
        deleteBean: function (beanBn) {
          utilService.assertIsBn(beanBn);
          return $http({
            method: 'DELETE',
            url: '/b/api/entities/' + beanBn
            //params: {
            //  bn: beanBn
            //}
          }).
            success(function (data, status, headers, config) {
            }).
            error(function (data, status, headers, config) {
              console.log('error in beanService.deleteBean');
              var msgString = "";
              if (status === 409) {
                //localStorage.setItem("alerts", JSON.stringify({type: 'alert', msg: msgString }));
                $rootScope.$broadcast('errorAlertMessage', msgString);
              } else {
                console.log('unhandled error in beanService.deleteBean bn: ' + bn);
              }
            });
        }
      };
    }]);
