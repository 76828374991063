angular
  .module('barometerApp.metricAggregation')
  .controller('MetricAggregationConfigController', MetricAggregationConfigController);

MetricAggregationConfigController.inject = [
  '$q',
  '$modalInstance',
  '$scope',
  'bitConstants',
  'config',
  'layoutService',
  'metricAggregationService'
];

/**
 * NOTE: config is "resolved" from MetricAggregationWorksheetSubheaderCtrl.
 */
function MetricAggregationConfigController($q,
                                           $modalInstance,
                                           $scope,
                                           bitConstants,
                                           config,
                                           layoutService,
                                           metricAggregationService) {

  $scope.newConfig = angular.copy(config);

  //--------------------------------------------
  // PRIVATE FUNCTIONS
  //--------------------------------------------

  function clearConfig() {
    $scope.newConfig.perspective.entity = null;
    $scope.newConfig.metric.entity = null;
    $scope.newConfig.metric.qualifiers = null;
    $scope.newConfig.pathway.data = null;
    $scope.newConfig.groupBy.data = null;
  }

  function getSelectedConfigsOptions(options, existingDataSource) {
    var selectedOption = null;
    if (existingDataSource) {
      _.each(options, function (option) {
        if ((option.bn && existingDataSource.bn && option.bn == existingDataSource.bn) || option === existingDataSource) {
          selectedOption = option;
        }
      });
    }
    return selectedOption;
  }

  function getMultiSelectQualifierOptions(options, existingDataSource) {
    var selectedOptions = [];
    if (existingDataSource && existingDataSource.length > 0) {
      _.each(options, function (option) {
        _.each(existingDataSource, function (dataSource) {
          if ((option.name && dataSource.name && option.name == dataSource.name) || option === dataSource) {
            selectedOptions.push(option);
          }
        });
      });
    }
    return selectedOptions;
  }

  function loadOptionsFromEntityType(entityTypeCode) {
    var pathwayOptions = metricAggregationService.getPathwayTypeOptions();
    var pathwayKeys = metricAggregationService.getPathwayTypeKeys();
    $scope.pathways = pathwayOptions[entityTypeCode];
    $scope.pathwayKeys = pathwayKeys[entityTypeCode];
  }

  function setOptionsForMetricAggConfig(metricEntityTypeCode, perspectiveEntityTypeCode) {
    if (perspectiveEntityTypeCode != null && perspectiveEntityTypeCode != 'undefined') {
      layoutService.getAdvancedQueriesForTypeCode(perspectiveEntityTypeCode).then(function (data) {
        $scope.perspectiveEntities = data.data;
        if (metricEntityTypeCode != null && metricEntityTypeCode != 'undefined') {
          metricAggregationService.getMetricEntityOptions(metricEntityTypeCode).then(function (data) {
            $scope.metricEntities = _.filter(data.Custom, function (entity) {
              return !entity.controlLevel || entity.controlLevel != 'NONE';
            });
            if ($scope.metricEntities.length <= 0) {
              $scope.metricEntities = "Empty"
            }
            $scope.newConfig.perspective.entity = getSelectedConfigsOptions($scope.perspectiveEntities, $scope.newConfig.perspective.entity);
            $scope.newConfig.pathway.data = getSelectedConfigsOptions($scope.pathwayKeys, $scope.newConfig.pathway.data); //TODO fix for saving lookup key
            $scope.newConfig.metric.entity = getSelectedConfigsOptions($scope.metricEntities, $scope.newConfig.metric.entity);
            $scope.newConfig.metric.qualifiers = getMultiSelectQualifierOptions($scope.metricValues, $scope.newConfig.metric.qualifiers);
            $scope.newConfig.groupBy.data = getSelectedConfigsOptions($scope.groupBys, $scope.newConfig.groupBy.data);
          });
        }
      });
    }
  }

  //--------------------------------------------
  // PUBLIC FUNCTIONS
  //--------------------------------------------

  $scope.perspectiveQualifierChanged = function (metricEntityTypeCode, perspectiveEntityTypeCode) {
    setOptionsForMetricAggConfig(metricEntityTypeCode, perspectiveEntityTypeCode);
  };

  $scope.pathwayChanged = function (pathway) {
    var groupByOptions = metricAggregationService.getGroupByOptions();
    pathwayValues = metricAggregationService.getPathwayTypeOptions()[$scope.newConfig.perspective.entityType.typeCode];
    $scope.groupBys = groupByOptions[pathwayValues[pathway].firstStep];
    var pathwayValues = metricAggregationService.getPathwayTypeOptions()[$scope.newConfig.perspective.entityType.typeCode];
    setOptionsForMetricAggConfig(pathwayValues[pathway].lastStep, $scope.newConfig.perspective.entityType.typeCode);
  };

  $scope.entityTypeChanged = function (entityTypeCode) {
    // when entity type is changed, clear all data
    clearConfig();
    loadOptionsFromEntityType(entityTypeCode);
    setOptionsForMetricAggConfig(entityTypeCode, $scope.newConfig.perspective.entityType.typeCode);
  };

  //--------------------------------------------
  // DO IT!
  //--------------------------------------------

  // When the config modal is first loaded, check if there is already a pathway selected.
  // If so then we build the Metric Aggregation Configuration Dropdown for that pathway
  // This is done because otherwise it is only built when there is a change to the pathway
  if ($scope.newConfig.perspective.entityType && $scope.newConfig.perspective.entityType.typeCode) {
    var pathwayValues = metricAggregationService.getPathwayTypeOptions()[$scope.newConfig.perspective.entityType.typeCode];
  }
  if ($scope.newConfig && $scope.newConfig.pathway && $scope.newConfig.pathway.data) {
    setOptionsForMetricAggConfig(pathwayValues[$scope.newConfig.pathway.data].lastStep, $scope.newConfig.perspective.entityType.typeCode);
  }

  // Note that the new config is passed back in the close() argument
  $scope.save = function () {
    $modalInstance.close($scope.newConfig);
  };
  $scope.cancel = function () {
    $modalInstance.dismiss('cancel');
  };

  $scope.perspectiveTypes = bitConstants.getMetricAggregationEntityTypes();
  $scope.perspectiveEntities = null;
  if ($scope.newConfig.perspective.entityType) {
    var pathwayOptions = metricAggregationService.getPathwayTypeOptions();
    var pathwayKeys = metricAggregationService.getPathwayTypeKeys();
    $scope.pathways = pathwayOptions[$scope.newConfig.perspective.entityType.typeCode];
    $scope.pathwayKeys = pathwayKeys[$scope.newConfig.perspective.entityType.typeCode];
  }
  $scope.metricValues = metricAggregationService.getMetricTypes();
  $scope.metricEntities = null;
  if ($scope.newConfig.pathway.data) {
    var groupByOptions = metricAggregationService.getGroupByOptions();
    $scope.groupBys = groupByOptions[pathwayValues[$scope.newConfig.pathway.data].firstStep];
  }

  if ($scope.newConfig.perspective.entityType) {
    _.each($scope.perspectiveTypes, function (entityType) {
      if ($scope.newConfig.perspective.entityType.typeCode === entityType.typeCode) {
        $scope.newConfig.perspective.entityType = entityType;
      }
    });
    setOptionsForMetricAggConfig(pathwayValues[$scope.newConfig.pathway.data].lastStep, $scope.newConfig.perspective.entityType.typeCode);
  }
}
