angular.module('barometerApp.activityStream')
  .directive('eventDetails', ['$compile', 'urlService', 'bitConstants', function ($compile, urlService, bitConstants) {

    var eventIdToTemplate = {
      changeEventCreateAssociationDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Added {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> to {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span>',
      changeEventCreateTypedAssociationFromAssetVersionDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Added {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> to {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span>',
      changeEventCreateAssociationFromAssetVersionWorkflow: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Proposed to add {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> to {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span>',
      changeEventCreateAssociationFromPositionDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Added {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> to {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span> - <span entity-activity-link entity="content.tertiaryEntity"></span>',
      changeEventCreateAssociationFromPositionWorkflow: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Proposed to add {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> to {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span> - <span entity-activity-link entity="content.tertiaryEntity"></span>',
      changeEventCreateAssociationToPositionDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Added {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> - <span entity-activity-link entity="content.tertiaryEntity"></span> to {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span>',
      changeEventCreateAssociationToPositionWorkflow: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Proposed to add {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> - <span entity-activity-link entity="content.tertiaryEntity"></span> to {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span>',
      changeEventCreateAssociationUdfDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Added {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> to {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span>',
      changeEventCreateAssociationUdfWorkflow: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Proposed to add {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> to {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span>',
      changeEventCreateAssociationWorkflow: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Proposed to add {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> to {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span>',
      changeEventCreateEntityColumnSpecificationDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Created Column Specification for {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span>',
      changeEventCreateEntityColumnSpecificationItemDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Added Column <span class="type"><span entity-activity-link entity="content.targetEntity"></span></span> to {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span>',
      changeEventCreateEntityDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Created {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span>',
      changeEventCreateEntityDiscussionDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Created {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span>',
      changeEventCreateEntityResponseDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Added Comment <span class="type"><span entity-activity-link entity="content.targetEntity"></span></span> to {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span>',
      changeEventCreateEntityRollupDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Created {{content.targetEntity.type}} for <span entity-activity-link entity="content.sourceEntity"></span>',
      changeEventCreateEntityRollupWorkflow: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Proposed to create {{content.targetEntity.type}} for <span entity-activity-link entity="content.sourceEntity"></span>',
      changeEventCreateEntitySecuredEntityPropertyDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Added field level security to the {{content.targetEntity.type}} {{content.property.name}} property.',
      changeEventCreateEntityWorkflow: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Proposed to create {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span>',
      changeEventCreateQualifiedAssociationDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Added {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> to {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span> ({{content.qualifier}})',
      changeEventCreateQualifiedAssociationFromPositionDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Added {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> to {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span> - <span entity-activity-link entity="content.tertiaryEntity"></span> ({{content.qualifier}})',
      changeEventCreateQualifiedAssociationFromPositionWorkflow: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Proposed to add {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> to {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span> - <span entity-activity-link entity="content.tertiaryEntity"></span> ({{content.qualifier}})',
      changeEventCreateQualifiedAssociationParentlessDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Added {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> as a root {{content.targetEntity.type}} ({{content.qualifier}})',
      changeEventCreateQualifiedAssociationParentlessWorkflow: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Proposed to add {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> as a root {{content.targetEntity.type}} ({{content.qualifier}})',
      changeEventCreateQualifiedAssociationToPositionDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Added {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> - <span entity-activity-link entity="content.tertiaryEntity"></span> to {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span> ({{content.qualifier}})',
      changeEventCreateQualifiedAssociationToPositionWorkflow: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Proposed to add {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> - <span entity-activity-link entity="content.tertiaryEntity"></span> to {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span> ({{content.qualifier}})',
      changeEventCreateQualifiedAssociationWorkflow: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Proposed to add {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> to {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span> ({{content.qualifier}})',
      changeEventCreateQualifiedTypedAssociationDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Added {{content.associationType}} {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> to {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span> ({{content.qualifier}})',
      changeEventCreateQualifiedTypedAssociationWorkflow: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Proposed to add {{content.associationType}} {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> to {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span> ({{content.qualifier}})',
      changeEventCreateTypedAssociationDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Added {{content.associationType}} {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> to {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span>',
      changeEventCreateTypedAssociationWorkflow: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Proposed to add {{content.associationType}} {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> to {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span>',
      changeEventDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> {{content.type}} event occurred for {{content.targetEntity.type}}  <span entity-activity-link entity="content.sourceEntity"></span>',
      changeEventDeleteAssociationDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Removed {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> from {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span>',
      changeEventDeleteAssociationFromAssetVersionDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Removed {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> from {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span>',
      changeEventDeleteAssociationFromAssetVersionWorkflow: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Proposed to remove {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> from {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span>',
      changeEventDeleteAssociationFromPositionDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Removed {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> from {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span> - <span entity-activity-link entity="content.tertiaryEntity"></span>',
      changeEventDeleteAssociationFromPositionWorkflow: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Proposed to remove {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> from {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span> - <span entity-activity-link entity="content.tertiaryEntity"></span>',
      changeEventDeleteAssociationToPositionDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Removed {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> - <span entity-activity-link entity="content.tertiaryEntity"></span> from {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span>',
      changeEventDeleteAssociationToPositionWorkflow: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Proposed to remove {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> - <span entity-activity-link entity="content.tertiaryEntity"></span> from {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span>',
      changeEventDeleteAssociationUdfDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Removed {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> from {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span>',
      changeEventDeleteAssociationUdfWorkflow: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Proposed to remove {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> from {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span>',
      changeEventDeleteAssociationWorkflow: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Proposed to remove {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> from {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span>',
      changeEventDeleteEntityColumnSpecificationDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Deleted Column Specification for {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span>',
      changeEventDeleteEntityColumnSpecificationItemDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Removed Column <span class="type"><span entity-activity-link entity="content.targetEntity"></span></span> from {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span>',
      changeEventDeleteEntityDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Deleted {{content.targetEntity.type}}  <span entity-activity-link entity="content.targetEntity"></span>',
      changeEventDeleteEntityDiscussionDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Deleted {{content.targetEntity.type}}  <span entity-activity-link entity="content.targetEntity"></span>',
      changeEventDeleteEntityResponseDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Removed Comment <span class="type"><span entity-activity-link entity="content.targetEntity"></span></span> from {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span>',
      changeEventDeleteEntityRollupDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Deleted {{content.targetEntity.type}} for <span entity-activity-link entity="content.sourceEntity"></span>',
      changeEventDeleteEntityRollupWorkflow: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Proposed to delete {{content.targetEntity.type}} for <span entity-activity-link entity="content.sourceEntity"></span>',
      changeEventDeleteEntitySecuredEntityPropertyDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Removed field level security from the {{content.targetEntity.type}} {{content.property.name}} property.',
      changeEventDeleteEntityWorkflow: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Proposed to delete {{content.targetEntity.type}}  <span entity-activity-link entity="content.targetEntity"></span>',
      changeEventDeleteQualifiedAssociationDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Removed {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> from {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span> ({{content.qualifier}})',
      changeEventDeleteQualifiedAssociationFromPositionDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Removed {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> from {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span> - <span entity-activity-link entity="content.tertiaryEntity"></span> ({{content.qualifier}})',
      changeEventDeleteQualifiedAssociationFromPositionWorkflow: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Proposed to remove {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> from {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span> - <span entity-activity-link entity="content.tertiaryEntity"></span> ({{content.qualifier}})',
      changeEventDeleteQualifiedAssociationParentlessDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Removed {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> as a root {{content.targetEntity.type}} ({{content.qualifier}})',
      changeEventDeleteQualifiedAssociationParentlessWorkflow: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Proposed to remove {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> as a root {{content.targetEntity.type}} ({{content.qualifier}})',
      changeEventDeleteQualifiedAssociationToPositionDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Removed {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> - <span entity-activity-link entity="content.tertiaryEntity"></span> from {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span> ({{content.qualifier}})',
      changeEventDeleteQualifiedAssociationToPositionWorkflow: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Proposed to remove {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> - <span entity-activity-link entity="content.tertiaryEntity"></span> from {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span> ({{content.qualifier}})',
      changeEventDeleteQualifiedAssociationWorkflow: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Proposed to remove {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> from {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span> ({{content.qualifier}})',
      changeEventDeleteQualifiedTypedAssociationDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Removed {{content.associationType}} {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> from {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span> ({{content.qualifier}})',
      changeEventDeleteQualifiedTypedAssociationWorkflow: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Proposed to remove {{content.associationType}} {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> from {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span> ({{content.qualifier}})',
      changeEventDeleteTypedAssociationDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Removed {{content.associationType}} {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> from {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span>',
      changeEventDeleteTypedAssociationWorkflow: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Proposed to remove {{content.associationType}} {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> from {{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span>',
      changeEventUpdateAssociationDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Updated association ({{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span>, {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span>): changed <span class="type">{{content.property.name}}</span> from [ <span>{{content.property.sourceValue}}</span> ] to [ <span>{{content.property.targetValue}}</span> ]',
      changeEventUpdateAssociationUdfDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Updated association ({{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span>, {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span>): changed <span class="type">{{content.property.name}}</span> from [ <span>{{content.property.sourceValue}}</span> ] to [ <span>{{content.property.targetValue}}</span> ]',
      changeEventUpdateAssociationUdfWorkflow: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Proposed to update association ({{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span>, {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span>): changed <span class="type">{{content.property.name}}</span> from [ <span>{{content.property.sourceValue}}</span> ] to [ <span>{{content.property.targetValue}}</span> ]',
      changeEventUpdateAssociationWorkflow: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Proposed to update association ({{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span>, {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span>): changed <span class="type">{{content.property.name}}</span> from [ <span>{{content.property.sourceValue}}</span> ] to [ <span>{{content.property.targetValue}}</span> ]',
      changeEventUpdateQualifiedAssociationDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Updated association ({{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span>, {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span>, {{content.qualifier}}): changed <span class="type">{{content.property.name}}</span> from [ <span>{{content.property.sourceValue}}</span> ] to [ <span>{{content.property.targetValue}}</span> ]',
      changeEventUpdateQualifiedAssociationWorkflow: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Proposed to update association ({{content.sourceEntity.type}} <span entity-activity-link entity="content.sourceEntity"></span>, {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span>, {{content.qualifier}}): changed <span class="type">{{content.property.name}}</span> from [ <span>{{content.property.sourceValue}}</span> ] to [ <span>{{content.property.targetValue}}</span> ]',
      changeEventUpdateRelationalDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Updated {{content.targetEntity.type}}  <span entity-activity-link entity="content.targetEntity"></span> : changed <span class="type">{{content.property.name}}</span> from [ {{content.property.sourceValue}} ] to [ {{content.property.targetValue}} ]',
      changeEventUpdateRelationalWorkflow: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Proposed to update {{content.targetEntity.type}}  <span entity-activity-link entity="content.targetEntity"></span> : changed <span class="type">{{content.property.name}}</span> from [ {{content.property.sourceValue}} ] to [ {{content.property.targetValue}} ]',
      changeEventUpdateSimpleDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Updated {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> : changed <span class="type">{{content.property.name}}</span> from [ <span>{{content.property.sourceValue}}</span> ] to [ <span>{{content.property.targetValue}}</span> ]',
      changeEventUpdateSimpleDefaultJson: '<div class="activity-stream-json-event" ng-init="showDetails = false"><span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Updated {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> : <span class="type">{{content.property.name}}</span> was updated <br /> <button ng-click="$event.stopPropagation(); showDetails = !showDetails">{{ showDetails ? "Show Less" : "Show More"}}</button> <br /> <div ng-if="showDetails">Previous Value: <br /><code>{{ content.property.sourceValue }}</code><br /> New Value: <br /><code>{{content.property.targetValue}}</code></div> </div>',
      changeEventUpdateSimpleQueryXmlDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Updated {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> : changed <span class="type">{{content.property.name}}</span> from [ <span bit-query-no-watch query-xml="content.property.sourceValue" class="adv-query view-query"></span> ] to [ <span bit-query-no-watch query-xml="content.property.targetValue" class="adv-query view-query"></span> ]',
      changeEventUpdateSimpleRollupDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Updated {{content.targetEntity.type}} for <span entity-activity-link entity="content.sourceEntity"></span> : changed <span class="type">{{content.property.name}}</span> from [ <span>{{content.property.sourceValue}}</span> ] to [ <span>{{content.property.targetValue}}</span> ]',
      changeEventUpdateSimpleRollupWorkflow: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Proposed to update {{content.targetEntity.type}} for <span entity-activity-link entity="content.sourceEntity"></span> : changed <span class="type">{{content.property.name}}</span> from [ <span>{{content.property.sourceValue}}</span> ] to [ <span>{{content.property.targetValue}}</span> ]',
      changeEventUpdateSimpleValuelessDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Updated {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> : changed <span class="type">{{content.property.name}}</span>',
      changeEventUpdateSimpleUdfDefault: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Updated {{content.targetEntity.type}} <span entity-activity-link entity="content.targetEntity"></span> : changed <span class="type">{{content.property.name}}</span> from [ <span>{{content.property.sourceValue}}</span> ] to [ <span>{{content.property.targetValue}}</span> ]',
      changeEventUpdateSimpleUdfWorkflow: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Proposed to update {{content.targetEntity.type}}  <span entity-activity-link entity="content.targetEntity"></span> : changed <span class="type">{{content.property.name}}</span> from [ <span>{{content.property.sourceValue}}</span> ] to [ <span>{{content.property.targetValue}}</span> ]',
      changeEventUpdateSimpleWorkflow: '<span class="scenario" ng-show="content.scenarioBn != null"> [Scenario] </span> Proposed to update {{content.targetEntity.type}}  <span entity-activity-link entity="content.targetEntity"></span> : changed <span class="type">{{content.property.name}}</span> from [ <span>{{content.property.sourceValue}}</span> ] to [ <span>{{content.property.targetValue}}</span> ]',

      eventDefault: '{{content.raw}}',

      securityEventAccountExplicitlyLocked: 'Account explicitly locked for user <span entity-activity-link entity="content.targetEntity"></span>',
      securityEventAccountExplicitlyUnlocked: 'Account explicitly unlocked for user <span entity-activity-link entity="content.targetEntity"></span>',
      securityEventAccountImplicitlyLocked: 'Account implicitly locked for user <span entity-activity-link entity="content.targetEntity"></span>',
      securityEventAccountImplicitlyUnlocked: 'Account implicitly unlocked for user <span entity-activity-link entity="content.targetEntity"></span>',
      securityEventAuthenticationAccepted: 'Authentication successful for user <span entity-activity-link entity="content.targetEntity"></span>',
      securityEventAuthenticationRejected: 'Authentication failed for user <span entity-activity-link entity="content.targetEntity"></span>',
      securityEventAuthorizationAccepted: 'Authorization succeeded for user <span entity-activity-link entity="content.targetEntity"></span> for resource [?]',
      securityEventAuthorizationRejected: 'Authorization failed for user <span entity-activity-link entity="content.targetEntity"></span> for resource [?]',
      securityEventEmailAddressChanged: 'Email address changed for user <span entity-activity-link entity="content.targetEntity"></span>',
      securityEventInactiveuserLoginAttempt: 'Login attempt for INACTIVE user <span entity-activity-link entity="content.targetEntity"></span>',
      securityEventLockeduserLoginAttempt: 'Login attempt for LOCKED user <span entity-activity-link entity="content.targetEntity"></span>',
      securityEventPasswordChanged: 'Password changed for user <span entity-activity-link entity="content.targetEntity"></span>',
      securityEventPasswordEmailed: 'Password emailed for user <span entity-activity-link entity="content.targetEntity"></span>',
      securityEventPasswordExpired: 'Password expired for user <span entity-activity-link entity="content.targetEntity"></span>',
      securityEventPasswordExpiring: 'Password expiring for user <span entity-activity-link entity="content.targetEntity"></span>',
      securityEventPasswordForgot: 'Password forgot for user <span entity-activity-link entity="content.targetEntity"></span>',
      securityEventPasswordGenerated: 'Password generated for user <span entity-activity-link entity="content.targetEntity"></span>',
      securityEventPasswordReset: 'Password reset for user <span entity-activity-link entity="content.targetEntity"></span>',
      securityEventPermissionGranted: 'Permission granted to <span entity-activity-link entity="content.targetEntity"></span> to edit <span entity-activity-link entity="content.sourceEntity"></span>',
      securityEventPermissionRevoked: 'Permission revoked from <span entity-activity-link entity="content.targetEntity"></span> to edit <span entity-activity-link entity="content.sourceEntity"></span>',
      securityEventQuestionsChanged: 'Questions changed for user <span entity-activity-link entity="content.targetEntity"></span>',
      securityEventRequiresQuestions: 'Questions missing for user <span entity-activity-link entity="content.targetEntity"></span>',
      securityEventRoleGranted: 'Role granted for user <span entity-activity-link entity="content.targetEntity"></span> for role [?]',
      securityEventRoleRevoked: 'Role revoked for user <span entity-activity-link entity="content.targetEntity"></span> for role [?]',
      securityEventSessionEnded: 'Session ended for user <span entity-activity-link entity="content.targetEntity"></span>',
      securityEventSessionStarted: 'Session started for user <span entity-activity-link entity="content.targetEntity"></span>',
      securityEventSsoLoginFailed: 'SSO authentication failed for user <span entity-activity-link entity="content.targetEntity"></span>',
      securityEventSsoLoginSuccess: 'SSO authentication successful for user <span entity-activity-link entity="content.targetEntity"></span>',
      securityEventSsoRegisterFailure: 'SSO registration failed for user <span entity-activity-link entity="content.targetEntity"></span>',
      securityEventSsoRegisterSuccess: 'SSO registration successful for user <span entity-activity-link entity="content.targetEntity"></span>',
      securityEventUserActivated: 'User <span entity-activity-link entity="content.targetEntity"></span> activated',
      securityEventUserDeleted: 'User <span entity-activity-link entity="content.targetEntity"></span> deleted',
      securityEventUserInactivated: 'User <span entity-activity-link entity="content.targetEntity"></span> deactivated',
      securityEventUsernameEmailed: 'Username emailed for user <span entity-activity-link entity="content.targetEntity"></span>',
      securityEventDefault: 'User <span entity-activity-link entity="content.targetEntity"></span> related security event'
    };

    function isJson(str) {
      //
      if (typeof str !== 'string') {
        return false;
      }
      //
      try {
        let obj = JSON.parse(str);
        if(Object.keys(obj).length > 0) {
          return true;
        }
      } catch (err) {
        return false;
      }
      return false;
    }

    return {
      replace: true,
      scope: {
        content: '='
      },
      link: function (scope, element, attrs) {
        let { presentationTemplate, property } = scope.content;
        if (property && (isJson(property.sourceValue) || isJson(property.targetValue))) {
          // until we know if/how to render JSON properties, just render a generic "was updated" template
          presentationTemplate = presentationTemplate.concat('Json');
        }
        const template = eventIdToTemplate[presentationTemplate] || eventIdToTemplate['eventDefault'];
        element.html(template);
        $compile(element.contents())(scope);
      }
    };
  }]);
