/**
 * Created by rbiggs on 3/3/17.
 */
angular.module('barometerApp.remediationForm')
  .service('remediationFormService', ['$http', 'utilService',
    function ($http, utilService) {

      return {

        getValuesForRemediationFormByEntityBn: function (remediationFormBn, entityBn) {
          utilService.assertIsBn(remediationFormBn);
          utilService.assertIsBn(entityBn);

          return $http({
            method: 'GET',
            url: '/b/api/forms/' + remediationFormBn + '/entities/' + entityBn,
          }).success(function (data) {

          }).error(function (data) {
            console.error('error in remediationFormService.getValuesForRemediationFormByEntityBn', remediationFormBn, entityBn, data);
          })
        },

        updateFieldsForEntityAndForm: function (remediationFormBn, entityBn, updateJson) {
          utilService.assertIsBn(remediationFormBn);
          utilService.assertIsBn(entityBn);

          return $http({
            method: 'POST',
            url: '/b/api/forms/' + remediationFormBn + '/entities/' + entityBn,
            headers: {'Content-Type': 'application/json'},
            data: updateJson
          }).success(function (data) {

          }).error(function (data, status) {
            console.error('error in remediationFormService.updateFieldsForEntityAndForm', remediationFormBn, entityBn, data)

          })
        }

      }
    }]);