/**
 * Created by timstave on 5/28/15.
 */
angular.module('barometerApp.summaryInfo')
  .directive('summaryInfoOrganizations', ['summaryInfoService', '$timeout', 'urlService', 'utilService',
    function (summaryInfoService, $timeout, urlService, utilService) {
      return {
        link: function ($scope, $element, $attrs) {

          var orgs = [];

          // Get the Organizations(0Y)
          summaryInfoService.getSummaryInfoDataByEntityBnAndTargetBnCode(urlService.getEntityBn(), '0Y', function (orgData) {
            orgs = orgData;

            // Example of this sorting use-case initially found at: http://stackoverflow.com/questions/16426774/underscore-sortby-based-on-multiple-attributes
            // This is using the underscore.js chain() function on the existing orgCoAssocData array to create a wrapped object
            // http://underscorejs.org/#chain
            // Then using the sortBy function to first sort the array by entity NAME
            $scope.organizations = _(orgs).chain().sortBy(function(assoc) {
              return assoc.entity.name;
            // Then using the sortBy function again to sort the array by entity RELATIONSHIP TYPE
            }).sortBy(function(assoc) {
              return assoc.relationship[0];
            // value() returns the final object that was initially wrapped by chain()
            }).value();

          });

        }
      }
  }]).directive('summaryInfoCompanies', ['summaryInfoService', '$timeout', 'urlService', 'utilService',
    function (summaryInfoService, $timeout, urlService, utilService) {
      return {
        link: function ($scope, $element, $attrs) {

          var comps = [];

          // Get the Companies(2F)
          summaryInfoService.getSummaryInfoDataByEntityBnAndTargetBnCode(urlService.getEntityBn(), '2F', function (companyData) {
            comps = companyData;

            // See above for comments on this sorting algorithm
            $scope.companies = _(comps).chain().sortBy(function(assoc) {
              return assoc.entity.name;
            }).sortBy(function(assoc) {
              // Library entities do not have assoc.relationship, but rather assoc.owns
              if(!assoc.relationship) {
                return assoc.owns;
              }
              return assoc.relationship[0];
            }).value();

          });

        }
      }
    }])
  .directive('summaryInfoContacts', ['summaryInfoService', '$timeout', 'urlService', 'utilService',
    function (summaryInfoService, $timeout, urlService, utilService) {
      return {
        link: function ($scope, $element, $attrs) {

          var contacts = [];

          // Get the People(0E)
          summaryInfoService.getSummaryInfoDataByEntityBnTargetBnCodeAssocTypeAndTargetQueryPurpose(urlService.getEntityBn(), '0E', 'HAS_CONTACT', 'association', function (contactData) {
            contacts = contactData;

            // See above for comments on this sorting algorithm
            $scope.contacts = _(contacts).chain().sortBy(function(assoc) {
              return assoc.entity.name;
            }).sortBy(function(assoc) {
              return assoc.contactType[0];
            }).value();

          });

        }
      }
    }])
    // .directive('summaryInfoCriticality', ['summaryInfoService', '$timeout', 'urlService', 'utilService',
    //   function (summaryInfoService, $timeout, urlService, utilService) {
    //     return {
    //       link: function ($scope, $element, $attrs) {
    //
    //         var promise = summaryInfoService.getSystemCriticality(urlService.getEntityBn());
    //         promise.then(function(data) {
    //           $scope.systemCriticality = data.data.systemCriticality > 0 ? data.data.systemCriticality : "-";
    //         });
    //
    //       }
    //     }
    //   }
    // ])
    // .directive('summaryInfoComplexity', ['summaryInfoService', '$timeout', 'urlService', 'utilService',
    //   function (summaryInfoService, $timeout, urlService, utilService) {
    //     return {
    //       link: function ($scope, $element, $attrs) {
    //
    //         var promise = summaryInfoService.getSystemComplexity(urlService.getEntityBn());
    //         promise.then(function(data) {
    //           $scope.systemComplexity = data.data.systemComplexity > 0 ? data.data.systemComplexity : "-";
    //         });
    //
    //       }
    //     }
    //   }
    // ]);