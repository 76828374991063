angular.module('barometerApp.common')
  .service('heatMap', ['$q', function ($q) {
    return {
      // Get heat map data (colors) for a set of bns and a dataSource
      // Not sure what defines a dataSource - same options as dashboard widget?
      // TODO: like build a real JSON service and stuff
      getColors: function (bns, dataSourceId) {
        var deferred = $q.defer();

        // TEMP: return random colors
        var data = [];
        var heatMapColors = ['#DAE8F8','#BCD4F1', '#C0DAF8', '#A9CAF1', '#89B1E2'];
        _.each(bns, function(bn){
          data.push({
            bn: bn,
            color: heatMapColors[Math.floor(Math.random() * heatMapColors.length)],
            value: Math.floor(Math.random() * 100)
          })
        });
        deferred.resolve(data);

        return deferred.promise;
      }
    }
  }]);