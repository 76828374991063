angular
    .module('barometerApp.relationalDiagram')
    .directive('relationalDiagram', relationalDiagram);

relationalDiagram.$inject = [];

/**
 * Factory method for the directive.
 */
function relationalDiagram() {
    //
    return {
        /**
         *
         */
        scope: {
            'previewMode': '@',
            'worksheetBn': '=',
            'isEditingLayout': '='
        },
        /**
         * AngularJS directive best practice: "Unless your template is very small, it's typically better to break
         * it apart into its own HTML file and load it with the templateUrl option." Called implicitly by AngularJS.
         *
         * @returns {string}
         */
        templateUrl: "/b/js/src/bit.ng/relational-diagram/partials/relational-diagram.html"
    };
}
