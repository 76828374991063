/**
 * Directives are a way to teach HTML new tricks. During DOM compilation directives are matched against the HTML
 * and executed. This allows directives to register behavior, or transform the DOM.
 *
 * This file tries to follow John Papa's Angular 1 style guide (adjusted to our AngularJS version).
 *
 * https://github.com/johnpapa/angular-styleguide/blob/master/a1/README.md
 */
angular
    .module('barometerApp.simpleTable')
    .directive('simpleTableFilter', simpleTableFilter);

simpleTableFilter.$inject = ['$compile', '$rootScope', '$timeout'];

/**
 * Factory method for the directive.
 *
 * @param $compile A reference to the global Angular HTML compiler service.
 */
function simpleTableFilter($scope, $rootScope, $timeout) {

    var directive = {
        /**
         * The link function is responsible for registering DOM listeners as well as updating the DOM.
         * It is executed after the template has been cloned. This is where most of the directive logic will be put.
         * This property is used only if the compile property is not defined.
         */
        link: function (scope, element, attrs) {

            var filterEditor;
            var nativeFormElement;
            var isTypeahead;
            var isSingleSelect;
            var isMultiSelect;
            var isGroupedMultiSelect;
            var selectedValues = element.find('.selected-values');
            var filterEditorLoaded = false;

            /**
             *
             */
            function closeEditor() {
                //
                // Hide (don't destroy - make it quick to re-open).
                if (filterEditor) filterEditor.hide();
                selectedValues.removeClass('active');
                // Clear any closeFilter event listeners.
                $('body').off('click.closeFilterEditor');
            }

            /**
             *
             */
            function activateFilterEditor() {
                //
                if (!filterEditorLoaded) initFilterEditor();
                else {
                    $timeout(function () {
                        if (isMultiSelect) {
                            // Set the initial values.
                            nativeFormElement.select2().val(scope.filter.selected);
                        }
                        // Precautionary: Close all filters before proceeding.
                        $rootScope.$broadcast('closeAllFilterEditors');
                        // Show.
                        filterEditor.show();
                        selectedValues.addClass('active');
                        // Hide tooltips.
                        $('.tooltip').hide();
                        // Handle click-away.
                        // Make sure we don't trigger the body click event.
                        // This handles any away-clicks that escape the backdrop, which takes a while to load.
                        $('body').on('click.closeFilterEditor', function (evt) {
                            // ie8 workaround and event target normalization (BARO-12685)
                            // element.click isn't properly stopping propogation to the body
                            var target = evt ? evt.target : window.event.srcElement;
                            if (!$(target).is('body')) {
                                closeEditor();
                            }
                        });
                        // can't use select2 API events because they are too intertwined
                        // can't trigger 'click' because listeners are keyed on 'mousedown'
                        // targets both multi and single select2's
                        element.find('select').select2('open');
                        // This event is fired when a selection is removed, the problem
                        // is when we use the clear button we want the dialog to close and
                        // select 2 wants the dialog to open so it opens it in the top left
                        // of the page. The event removes that behavior in the worst way possible
                        element.find('select').on('select2:unselecting', function (ev) {
                            if (ev.params.args.originalEvent) {
                                // When unselecting (in multiple mode)
                                ev.params.args.originalEvent.stopPropagation();
                            } else {
                                // When clearing (in single mode)
                                $(this).one('select2:opening', function (ev) {
                                    ev.preventDefault();
                                });
                            }
                        })
                    })
                }
            }

            /**
             * Used in cases where filter isTypeahead.
             */
            function initTypeAhead() {
                //
                nativeFormElement.on('change', function () {
                    // other code expects the data to be an array, transform it
                    var selectedBns = _.map(nativeFormElement.select2('data'), function (bn, name) {
                        return bn;
                    });
                    scope.filter.typeaheadFilter = selectedBns;
                    // ng-change isn't working, manually update model on change
                    scope.filter.selected = selectedBns;
                    scope.$apply();
                });
                // set the initial values
                nativeFormElement.select2().val(scope.filter.typeaheadFilter);
                // also set when swapping views
                scope.$on('reload', function () {
                    nativeFormElement.select2().val(scope.filter.typeaheadFilter);
                });
            }

            /**
             * Used in cases where filter isSingleSelect.
             */
            function initSingleSelect() {
                //
                nativeFormElement.on('change', function () {
                    // For single-selects: Close menu after selection is made.
                    closeEditor();
                })
            }

            /**
             * Used in cases where filter isMultiSelect.
             */
            function initMultiSelect() {
                //
                // Initialize values.
                nativeFormElement.select2().val(scope.filter.selected);
                //
                scope.$watch('filter.selected', function (newVal, oldVal) {
                    // Ancestor scope must implement updateTableModel();
                    scope.updateTableModel();
                });
            }

            /**
             *
             */
            function initFilterEditor() {
                //
                // Trigger ng-if to add DOM elements.
                //scope.showFilterEditor = true; // TODO Is this necessary?
                //scope.$apply();
                //
                // Wait for elements to be added.
                $timeout(function () {
                    //
                    // Initialize variables.
                    filterEditor = element.find('.filter-editor');
                    nativeFormElement = filterEditor.find('select, input');
                    isTypeahead = nativeFormElement.is('input');
                    isSingleSelect = nativeFormElement.is('select:not([multiple])');
                    isGroupedMultiSelect = scope.filter.type == 'GROUPED_MULTI_SELECT_VALUED';
                    isMultiSelect = scope.filter.type == 'MULTI_SELECT_VALUED';
                    //
                    // Initialize select2.
                    var select2options = isTypeahead ? scope.getTypeaheadSelect2Options(scope.filter) : scope.filterModel.defaultSelect2Options;
                    nativeFormElement.select2(select2options);
                    //
                    if (isTypeahead) initTypeAhead();
                    else if (isSingleSelect) initSingleSelect();
                    else if (isMultiSelect) initMultiSelect();
                    //
                    //initDomainSwitcher(); // TODO We may not need/want this for our simple filters.
                    //
                    // Prevent click events from bubbling to body tag.
                    element.on('click', function (event) {
                        event.stopPropagation();
                    });
                    filterEditorLoaded = true;
                    //
                    activateFilterEditor();
                });
            }

            /**
             * Re-initialize.
             */
            scope.rebuildEditor = function () {
                //
                initFilterEditor();
            }

            // initially keep filterEditor out of DOM
            //scope.showFilterEditor = false; // TODO Do we need this?

            /**
             * Handle click on label (class=selected-values).
             */
            selectedValues.on('click', function (event) {
                //
                activateFilterEditor();
            });

            // belt-and-suspenders approach to making sure only one dropdown is ever opened
            // broadcast closeAll event before opening a new one
            scope.$on('closeAllFilterEditors', function (event) {
                //
                closeEditor();
            });

            /**
             * add tooltip to label - just use the full label text
             * use manual trigger to prevent overlapping with filterEditor
             */
            $timeout(function () {
                //
                selectedValues.tooltip({
                    title: function () {
                        // filters in the filter bar
                        if ($(this).closest('.control-group').length) {
                            return $(this).closest('.control-group').find('.control-label').text() +
                                ': ' + $(this).text();
                        }
                        // domain filter in the section title
                        else {
                            return 'Domain: ' + $(this).text();
                        }
                    },
                    container: 'body',
                    placement: 'bottom',
                    html: true,
                    trigger: 'manual'
                }).on('mouseenter', function () {
                    if (!filterEditor || filterEditor.is(":hidden")) {
                        $(this).tooltip('show');
                    }
                }).on('mouseleave', function () {
                    if (!filterEditor || filterEditor.is(":hidden")) {
                        $(this).tooltip('hide');
                    }
                })
            })
        }
    }
    return directive;
}