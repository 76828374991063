/**
 * This file tries to follow John Papa's Angular 1 style guide (adjusted to our AngularJS version).
 *
 * https://github.com/johnpapa/angular-styleguide/blob/master/a1/README.md
 */
angular
  .module('barometerApp.watch')
  .factory('browseWatchedDataService', browseWatchedDataService);

browseWatchedDataService.$inject = ['$http', '$q', 'alertService', 'urlService', 'utilService'];

/**
 * @param $http
 * @param $q
 * @param utilService
 * @returns {{getData: getData}}
 */
function browseWatchedDataService($http, $q, alertService, urlService, utilService) {
  //
  var GRAPH_URL = urlService.getGraphApiUrl();

  return {
    getData: getData
  };

  function getAuthToken() {
    var authToken = utilService.readCookie('baroJWTToken');
    if (!authToken) {
      alertService.addErrorAlert('No authentication credential for Task service.');
    }
    return authToken;
  }

  /**
  Example response:
   {
     "columnSpec":[
        {
           "id":"perspectiveBn",
           "name":"perspectiveBn",
           "sortable":false,
           "rendererId":null,
           "sorted":null,
           "entityType":"PER",
           "entityIndex":0,
           "totalCount":1,
           "display":true
        },
        {
           "id":"perspectiveName",
           "name":"perspectiveName",
           "sortable":false,
           "rendererId":null,
           "sorted":null,
           "entityType":"PER",
           "entityIndex":0,
           "totalCount":1,
           "display":true
        },
        {
           "id":"bn",
           "name":"bn",
           "sortable":true,
           "rendererId":null,
           "sorted":null,
           "entityType":null,
           "entityIndex":1,
           "totalCount":1,
           "display":true
        },
        {
           "id":"name",
           "name":"name",
           "sortable":true,
           "rendererId":null,
           "sorted":"ASC",
           "entityType":null,
           "entityIndex":1,
           "totalCount":1,
           "display":true
        }
     ],
     "rows":[
        {
           "perspectiveBn":"050E0000003V",
           "perspectiveName":null,
           "bn":"ZZ0100000006",
           "name":"Actor 1"
        }
     ],
     "rowCount":1
  }
   */
  function getData(myRequestModel) {
    //
    var deferred = $q.defer();
    var token = getAuthToken();
    if (!token) {
      return deferred.reject('Authorization token missing');
    }
    $http({
      method: 'POST',
      url: GRAPH_URL + 'report/relatedEntities',
      headers: {
        'Authorization': 'Bearer ' + token,
        'bit-tenant-bn': utilService.getTenantBn(),
        'Content-Type': 'application/json'
      },
      data: myRequestModel
    }).success(function (data) {
      postProcessData(data);
      deferred.resolve(data);
    }).error(function (data) {
      console.error('Error getting neo4j path');
      deferred.reject(data);
    })
    return deferred.promise;
  }

  function postProcessData(data) {
    //
    var totalCount = data.columnSpec[0].totalCount;
    // Hide the perspective and BN columns.
    data.columnSpec[0].display = false;
    data.columnSpec[1].display = false;
    data.columnSpec[2].display = false;
    //
    data.columnSpec[4] = data.columnSpec[3];
    data.columnSpec[3] = null;
    // Add unpin column.
    data.columnSpec[3] = {
      display: true,
      entityIndex: 1,
      entityType: null,
      id: "action",
      name: "action",
      rendererId: "pin",
      sortable: false,
      sorted: null,
      totalCount: totalCount,
      width: "100px"
    };
    // Add unpin rows.
    $.each(data.rows, function() {
      this.action = "unpin";
    });
  }
}