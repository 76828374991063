angular.module('barometerApp.table')
  .service('tableService', ['$http', '$rootScope', '$location', 'alertService', 'errorHandlingService', 'utilService', '$modal', '$timeout', 'urlService', 'associatedBnCacheService', 'lockService', 'scorecardService', '$q',
    function ($http, $rootScope, $location, alertService, errorHandlingService, utilService, $modal, $timeout, urlService, associatedBnCacheService, lockService, scorecardService, $q) {
    var _targetBn, _sourceBn, _parentEntity, _pagination, _relationshipType, _targetEntity, _activeRow = {},
        _dialogSource, _sectionBn, _associations = {
          addedAssocs: [],
          removedAssocs: []
        }, _tableIds = {};
      var dialogs = {},
        dialogOpts = {
          add: {
            backdrop: 'static',
            keyboard: true,
            templateUrl: '/b/js/src/bit.ng/table/partials/add-dialog.html',
            controller: 'TableCtrlAdd',
            resolve: {
              sourceBn: function () {
                return _sourceBn
              },
              targetBn: function () {
                return _targetBn;
              },
              sectionBn: function () {
                return _sectionBn;
              }
            }
          },
          wicketAdd: {
            backdrop: 'static',
            keyboard: true,
            templateUrl: '/b/js/src/bit.ng/table/partials/add-dialog-wicket.html',
            controller: 'TableCtrlWicketAdd',
            resolve: {
              sourceBn: function () {
                return _sourceBn
              },
              entityTypeCode: function () {
                return _targetBn;
              }
            }
          },
          associationEditor: {
            backdrop: 'static',
            keyboard: true,
            windowClass: 'modal modal-large',
            templateUrl: '/b/js/src/bit.ng/table/partials/assoc-editor-dialog.html',
            controller: 'TableCtrlAssociationEditor',
            resolve: {
              sourceBn: function () {
                return _sourceBn
              },
              targetBn: function () {
                return _targetBn;
              },
              dialogSource: function () {
                return _dialogSource;
              },
              sectionBn: function () {
                return _sectionBn;
              },
              relationshipType: function () {
                return _relationshipType;
              },
              targetEntity: function () {
                return _targetEntity;
              }
            }
          },
          browse: {
            backdrop: 'static',
            keyboard: false,
            windowClass: 'modal modal-large',
            templateUrl: '/b/js/src/bit.ng/table/partials/browse-dialog.html',
            controller: 'TableCtrlBrowse',
            resolve: {
              sourceBn: function () {
                return _sourceBn
              },
              targetBn: function () {
                return _targetBn;
              },
              relationshipType: function () {
                return _relationshipType;
              }
            }
          },
          quickAdd: {
            backdrop: 'static',
            keyboard: false,
            backdropClick: false,
            templateUrl: '/b/js/src/bit.ng/table/partials/quickAddDialog.html',
            controller: 'TableCtrlQuickAdd',
            resolve: {
              sourceBn: function () {
                return _sourceBn
              },
              targetBn: function () {
                return _targetBn;
              }
            }
          },
        };
      dialogs.addDialog = angular.extend(dialogOpts.add, {});
      dialogs.wicketAddDialog = angular.extend(dialogOpts.wicketAdd, {});
      dialogs.associationEditorDialog = angular.extend(dialogOpts.associationEditor, {});
      dialogs.browseDialog = angular.extend(dialogOpts.browse, {});
      dialogs.quickAddDialog = angular.extend(dialogOpts.quickAdd, {});

      return {
        setActiveRow: function (tableId, rowBn) {
          _activeRow[tableId] = rowBn;
        },
        getActiveRow: function (tableId) {
          return _activeRow[tableId];
        },
        setAssociations: function (assocs) {
          _associations = assocs;
        },
        getAssociations: function () {
          return _associations;
        },
        getQualifier: function (tableId) {
          if (_tableIds[tableId] && _tableIds[tableId].qualifier) {
            return _tableIds[tableId].qualifier
          }
          return "";
        },
        getTables: function () {
          return _tableIds;
        },
        getTable: function (tableId) {
          return _tableIds[tableId]
        },

        setInitialLoad: function (tableId) {
          _tableIds[tableId].initialLoad = true;
        },
        initTableId: function (params) {
          _tableIds[params.tableId] = {
            isLoaded: false,
            initialLoad: false,
            relationshipType: params.relationshipType
          };
        },
        /**
         * This should be treated as an abstract extension point to decorate controllers of barometerApp.table module.
         * Structure of an object: { entityBnCode: '2-digit code of entity type',
         *                           decoratorFunctions: [
         *                             { controllerName: 'name of controller to decorate',
         *                               decorateModel: 'reference to appropriate decorator's function',
         *                               thisValue: 'reference to service to where decorator's function belongs'
         *                             }
         *                           ]
         *                         }
         * decorateModel() function is basically a contract. It should accept a model and return updated model wrapped in a promise. Implementations could be different.
         * @returns list of objects that represent entities that use barometerApp.table module, and list of decorator functions for them
         * WARN: The list is incomplete. Extra objects should be added as needed.
         */
        getAllSupportedEntityTypeObjects: function () {
          return [
            { entityBnCode: '4A',
              decoratorFunctions: [
                {controllerName: 'TableCtrlAdd', decorateModel: scorecardService.decorateTableCtrlAddModelForScorecard, thisValue: scorecardService},
                {controllerName: 'TableCtrlBrowse', decorateModel: scorecardService.decorateTableCtrlBrowseModelForScorecard, thisValue: scorecardService},
              ],
            },
          ];
        },
        /**
         *
         * @param entityBn
         * @param controllerName
         * @returns Wrapped implementation of decorator function for specific entity and controller
         */
        getDecoratorForSupportedEntityType: function (entityBn, controllerName) {
          const entityTypeObject = this.getAllSupportedEntityTypeObjects().find(p => p['entityBnCode'] === entityBn);
          return entityTypeObject
            ? entityTypeObject.decoratorFunctions.find(f => f['controllerName'] === controllerName)
            : null;
        },
        updateAssociation: function (assocType, assoc) {
          var existingAssoc;
          if (assocType == "addedAssocs") {
            existingAssoc = _.filter(_associations.removedAssocs, {
              'targetBn': assoc.targetBn,
              relationshipIdentifier: assoc.relationshipIdentifier
            });
            if (existingAssoc.length > 0) {
              _associations.removedAssocs.splice(_.indexOf(_associations.removedAssocs, existingAssoc), 1);
            } else {
              _associations.addedAssocs.push(assoc);
            }
          } else if (assocType == "removedAssocs") {
            existingAssoc = _.filter(_associations.addedAssocs, {
              'targetBn': assoc.targetBn,
              relationshipIdentifier: assoc.relationshipIdentifier
            });
            if (existingAssoc.length > 0) {
              _associations.addedAssocs.splice(_.indexOf(_associations.addedAssocs, existingAssoc), 1);
            } else {
              _associations.removedAssocs.push(assoc);
            }
          } else if (assocType == "primaryBn") {
            _associations.primaryBn = assoc;
          }
        },
        clearAssociations: function () {
          _associations.removedAssocs.length = 0;
          _associations.addedAssocs.length = 0;
        },
        updateAssociations: function (params) {
          var url = '/b/api/association';
          var removedBns = _.pluck(params.associations.removedAssocs, "targetBn");
          var addedBns = _.pluck(params.associations.addedAssocs, "targetBn");
          var formParams = {
            sourceBn: params.sourceBn,
            targetBnCode: params.targetBnCode,
            indexBn: params.indexBn,
            relationshipType: params.relationshipType || null,
            addedAssocs: (params.associations.addedAssocs) ? params.associations.addedAssocs : [],
            removedAssocs: (params.associations.removedAssocs) ? params.associations.removedAssocs : []
          };
          if (params.associations.primaryBn) {
            formParams.primaryBn = params.associations.primaryBn
          }

          $rootScope.$broadcast("changeSubmitted", params.sectionBn);

          var json = angular.toJson(formParams);
          return $http({
            method: "POST",
            url: url,
            //data: $.param(formParams),
            data: json,
            //headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            headers: {'Content-Type': 'application/json;charset=UTF-8'}
          }).success(function (data, status, headers, config) {

            var hasProposedChanges = data && data.proposedChangeBns && data.proposedChangeBns.length > 0;
            var hasSuccessChanges = data && data.successfulBns && data.successfulBns.length > 0;
            var indexingInProgress = data && data.transactionIds && data.transactionIds.length;

            if (hasProposedChanges) {
              $rootScope.$broadcast("newProposedEdits", data.proposedChangeBns, params.sectionBn);
            }

            if (hasSuccessChanges) {
              var sourceBnCode = utilService.getBnCode(params.sourceBn);
              var targetBnCode = utilService.getBnCode(params.targetBn);

              // always broadcast for blue-bar
              $rootScope.$broadcast("changeWithIndexingStarted", {
                  sectionBn: params.sectionBn,
                  transactionId: indexingInProgress ? data.transactionIds[0] : null
              });

              // Special handling for scorecard and compliance rules: Update properties (weights)
              // TODO: proposedChanges?
              if ((sourceBnCode === '4A') && (targetBnCode === '3Z')) {
                params.updateProperties(params);
              } else {
                associatedBnCacheService.removeAssociatedBns(_.intersection(removedBns, data.successfulBns));
                associatedBnCacheService.addAssociatedBns(_.intersection(addedBns, data.successfulBns));
              }
            }

          }).error(function (data, status, headers, config) {
            // Changed error handling in i67, for BARO-14239.
            var msgString = "Unable to edit association";
            console.log('error during getCountsForAssociations for sourceTypeCode:' + params.sourceTypeCode + ' sourceBn:' + params.sourceBn + ' targets:' + params.targets);
            if (status === 409 && data.errorMessages) {
              // Changed to handle error messages after the pattern of entityService.updateBasicInfo.
              msgString += ': ' + data.errorMessages;
              alertService.addErrorAlert(msgString);
            } else {
              // Legacy error handling behavior.
              // Left in order to have least impact on i67 release. TODO revisit.
              errorHandlingService.handleGenericError(status);
            }
          });
        },
        // Wraps standard assoc update with an up-to-5-second wait for indexing to complete
        updateAssociationsAndWaitForIndexing: function (assocParams) {
          var deferred = $q.defer();
          this.updateAssociations(assocParams).then(function (updateData) {
            // There are usually two transactionIds, one for the deletedAssocs, one for the addedAssocs
            var indexingPromises = _.map(updateData.data.transactionIds, function (id) {
              return lockService.processIndexResponseForTransactionId(5000, id);
            });
            // Wait for all transactions to complete
            $q.all(indexingPromises).then(function () {
              // Extra timeout accounts for gap between lock ready and solr _actually_ ready
              $timeout(function () {
                deferred.resolve();
              }, 1000)
            }, function () {
              deferred.reject();
            });
          });
          return deferred.promise;
        },
        updateProperties: function (params) {
          var url = '/b/api/association/updateProperties';
          var formParams = {
            sourceBn: params.sourceBn,
            targetBn: params.targetBn,
            propertyMap: angular.toJson({'weight': params.weight})
          };
          if (utilService.getBnCode(params.sourceBn) == '0E' && utilService.getBnCode(params.targetBn) == '15' ||
              utilService.getBnCode(params.sourceBn) == '4A' && utilService.getBnCode(params.targetBn) == '3Z' ) {
            formParams.relationshipType = params.relationshipType;
          }
          return $http({
            method: "POST",
            url: url,
            data: $.param(formParams),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
          }).success(function (data, status, headers, config) {
            $rootScope.$broadcast("loadSection", params.sectionBn, true);
            $rootScope.$broadcast("refreshCounts", params.sectionBn);
            // Workaround since broadcast of loadSection is not refreshing the table
            $rootScope.$broadcast('skillLevelUpdated', {stars: params.weight, targetBn: params.targetBn});
            $rootScope.$broadcast('weightUpdated', {weight: params.weight, targetBn: params.targetBn});
            $rootScope.$broadcast("successAlertMessage", "Your changes have been saved.");
          }).error(function (data, status, headers, config) {
            console.log('error during updateProperties for sourceBn:' + params.sourceBn + ' targetBn:' + params.targetBn);
            errorHandlingService.handleGenericError(status);
          });
        },
        setSourceBn: function (sourceBn) {
          _sourceBn = sourceBn;
        },
        setTargetBn: function (targetBn) {
          _targetBn = targetBn;
        },
        setParentEntity: function (entity) {
          _parentEntity = entity;
        },

        getParentEntity: function () {
          return _parentEntity;
        },
        setPagination: function (pagination) {
          _pagination = pagination;
        },
        getPagination: function () {
          return _pagination;
        },
        openDialog: function (params) { // (dialog, targetBn, type) {
          // SS - targetBn is actually the entityTypeCode. During the evolution of this directive, that changed, but I'd have to investigate if changing it would break things.
          // SS - At some point I'll refactor to have self-documenting property names.
          _targetBn = params.targetBn ? params.targetBn : null;
          _sectionBn = params.sectionBn ? params.sectionBn : null;
          _dialogSource = params.dialogSource ? params.dialogSource : null;
          _relationshipType = params.relationshipType ? params.relationshipType : null;
          _targetEntity = params.targetEntity ? params.targetEntity : null;
          $modal.open(dialogs[params.dialog]).opened.then(function () {
            $timeout(function () {
              prototype.setModalMaxHeights();
            }, 200);
          });

        },
        loadAssociationDetailsDetails: function (sourceBn) {
          return $http({
            method: 'GET',
            url: '/b/api/associationDetails/details',
            params: {sourceBn: sourceBn}
          }).success(function (data, status, headers, config) {
            utilService.hideLoading();
          }).error(function (data, status, headers, config) {
            //errorHandlingService.handleGenericError(status);
            $rootScope.$broadcast('errorMessage', 'An unexpected error occurred');
            utilService.hideLoading();
            errorHandlingService.handleGenericError(status);
          });
        },
        getAssociationData: function (params) {
          var parentBn = params.parentEntity && params.parentEntity.bn ? params.parentEntity.bn : "",
            url = '',
            queryParams = {};

          switch (params.tableType.toLowerCase()) {
            // unique case where this is an association details pane, but we need to call the association service, with associationDetails as the table type
            case "multitypeassociationdetails" :
              url = '/b/api/association';
              queryParams = {
                first: params.first || 0,
                count: params.count || 10,
                sortPropertyBn: params.sortProperty, // currently the sort property name. need to pass in bn
                ascending: params.ascending, // currently passing in params.descending. need to fix this
                sourceBnCode: params.sourceBnCode,
                sourceBn: params.sourceBn,
                queryKey: params.queryKey,
                targetBnCode: params.targetTypeBn,
                filter: null,
                qualifier: null,
                relationshipType: params.relationshipType,
                columnSpecPurpose: "associationDetails",
                includeColumnSpec: true,
                includeFilterSpec: false
              };
              break;
            case "browse" :
            case "multitypebrowse" :
            case "search" :
              url = '/b/api/browse';
              queryParams = {
                format: 'list',
                first: params.first || 0,
                count: params.count || 10,
                sortPropertyBn: params.sortProperty, // currently the sort property name. need to pass in bn
                ascending: params.ascending, // currently passing in params.descending. need to fix this
                bnCode: params.targetTypeBn,
                queryKey: params.queryKey,
                filter: angular.toJson(params.filterParams || {}),
                columnSpecPurpose: params.tableType,
                includeColumnSpec: typeof params.includeColumnSpec === "undefined" ? true : params.includeColumnSpec,
                includeFilterSpec: typeof params.includeFilterSpec === "undefined" ? true : params.includeFilterSpec,
                useDefaultQuery: typeof params.useDefaultQuery === "undefined" ? true : params.useDefaultQuery,
                insightTypeBn: (!!params.insightTypeBn) ? params.insightTypeBn : ''
              };
              break;
            case "singletypebrowse":
            case "association" :
              url = '/b/api/association';
              queryParams = {
                first: params.first || 0,
                count: params.count || 10,
                sortPropertyBn: params.sortProperty, // currently the sort property name. need to pass in bn
                ascending: params.ascending, // currently passing in params.descending. need to fix this
                sourceBnCode: params.sourceBnCode,
                sourceBn: params.sourceBn,
                queryKey: params.queryKey,
                targetBnCode: params.targetTypeBn,
                filter: angular.toJson(params.filterParams || {}),
                qualifier: params.qualifier,
                relationshipType: params.relationshipType,
                columnSpecPurpose: params.tableType,
                includeColumnSpec: typeof params.includeColumnSpec === "undefined" ? true : params.includeColumnSpec,
                includeFilterSpec: typeof params.includeFilterSpec === "undefined" ? true : params.includeFilterSpec,
                useDefaultQuery: typeof params.useDefaultQuery === "undefined" ? true : params.useDefaultQuery
              };
              break;
            case "associationdetails" :
              url = '/b/api/associationDetails/list';
              queryParams = {
                first: params.first || 0,
                count: params.count || 10,
                sortPropertyBn: params.sortProperty, // currently the sort property name. need to pass in bn
                ascending: params.ascending, // currently passing in params.descending. need to fix this
                sourceBn: params.sourceBn,
                targetBnCode: params.targetTypeBn,
                queryKey: urlService.getQueryKey(),
                targetBn: params.targetBn,
                relationshipType: params.relationshipType,
                columnSpecPurpose: params.tableType,
                includeColumnSpec: typeof params.includeColumnSpec === "undefined" ? true : params.includeColumnSpec
              };
              break;
            case "addbybrowse" :
              url = '/b/api/browse';
              queryParams = {
                format: 'list',
                first: params.first || 0,
                count: params.count || 10,
                sortPropertyBn: params.sortProperty, // currently the sort property name. need to pass in bn
                ascending: params.ascending, // currently passing in params.descending. need to fix this
                bnCode: params.targetTypeBn,
                sourceBn: params.sourceBn,
                columnSpecPurpose: params.tableType,
                filter: angular.toJson(params.filterParams || {}),
                parentBn: parentBn,
                relationshipType: params.relationshipType,
                includeColumnSpec: typeof params.includeColumnSpec === "undefined" ? true : params.includeColumnSpec,
                includeFilterSpec: typeof params.includeFilterSpec === "undefined" ? true : params.includeFilterSpec,
                useDefaultQuery: typeof params.useDefaultQuery === "undefined" ? true : params.useDefaultQuery,
                insightTypeBn: (!!params.insightTypeBn) ? params.insightTypeBn : ''
              };
              break;
            case "associationeditor" :
              url = '/b/api/association/' + params.sourceBn;
              queryParams = {
                sourceBnCode: params.sourceBnCode,
                targetBn: params.targetBn,
                relationshipType: params.relationshipType,
                includeColumnSpec: typeof params.includeColumnSpec === "undefined" ? true : params.includeColumnSpec
              };
              break;
            case "auditresults" :
              url = '/b/api/scorecard/results';
              queryParams = {
                scorecardBn: params.sourceBn,
                sortField: params.sortField,
                first: params.first,
                count: params.count,
                ascending: params.ascending
              };
              break;
            // Add a worksheet specific browse request.
            case "bubblechartbrowse":
            case "customdashboard":
            case "fieldaggregationbrowse":
            case "lifecycleroadmapbrowse":
            case "matrixbrowse":
            case "organizationcoveragereportbrowse":
            case "radialconnectionbrowse":
            case "relationaldiagrambrowse":
            case "systeminventorystatusbrowse":
            case "worksheetbrowse":
              url = '/b/api/browse';
              queryParams = {
                format: 'list',
                first: params.first || 0,
                count: params.count || 10,
                sortPropertyBn: params.sortProperty, // currently the sort property name. need to pass in bn
                ascending: params.ascending, // currently passing in params.descending. need to fix this
                bnCode: params.targetTypeBn,
                queryKey: params.queryKey,
                filter: angular.toJson(params.filterParams || {}),
                columnSpecPurpose: "MULTITYPEBROWSE",
                includeColumnSpec: typeof params.includeColumnSpec === "undefined" ? true : params.includeColumnSpec,
                includeFilterSpec: typeof params.includeFilterSpec === "undefined" ? true : params.includeFilterSpec,
                useDefaultQuery: typeof params.useDefaultQuery === "undefined" ? true : params.useDefaultQuery,
                type: typeof params.tableType === "undefined" ? '' : params.tableType,
                insightTypeBn: (!!params.insightTypeBn) ? params.insightTypeBn : ''
              };
              break;
          }
          return $http({
            method: "GET",
            url: url,
            params: queryParams
          }).success(function (data, status, headers, config) {
            utilService.hideLoading();
          }).error(function (data, status, headers, config) {
            //errorHandlingService.handleGenericError(status);
            $rootScope.$broadcast('errorMessage', 'An unexpected error occurred');
            utilService.hideLoading();
            errorHandlingService.handleGenericError(status);
          });
        },
        getCountsForAssociations: function (params) { //sourceTypeCode, sourceBn, queryKey, targets) {
          return $http({
            method: 'GET',
            url: '/b/api/association/countAll',
            params: {
              sourceBnCode: params.sourceBnCode || null, // provide sourceTypeCode and leave sourceBn empty to use default queryKey
              sourceBn: params.sourceBn,
              queryKey: params.queryKey,
              targets: angular.toJson(params.targets),
              splitRelationshipTypes: typeof params.splitRelationshipTypes === 'undefined' ? null : params.splitRelationshipTypes
            }
          }).success(function (data, status, headers, config) {
          }).error(function (data, status, headers, config) {
            console.log('error during getCountsForAssociations for sourceTypeCode:' + params.sourceTypeCode + ' sourceBn:' + params.sourceBn + ' targets:' + params.targets);
            errorHandlingService.handleGenericError(status);
          });
        },
        getQualifiers: function (params) {
          return $http({
            method: 'GET',
            url: '/b/api/relationshippatterns',
            params: {
              fromType: params.source,
              toType: params.target,
              relationshipType: params.relationshipType,
              entityBn: params.entityBn,
              flatten: true
            }
          }).success(function (data, status, headers, config) {
          }).error(function (data, status, headers, config) {
            console.log('error during getQualifiers for sourceBn: ' + params.source + ', target:' + params.target);
            errorHandlingService.handleGenericError(status);
          });
        },
        getCustomColumns: function (entityTypeCode, columnSpecBn) {
          return $http({
            method: 'GET',
            url: '/b/api/table/adhoc/columns',
            params: {
              entityTypeCode: entityTypeCode,
              columnSpecBn: columnSpecBn
            }
          }).success(function (data, status, headers, config) {
          }).error(function (data, status, headers, config) {
            console.log('error during getCustomColumns for entityTypeCode: ' + entityTypeCode + ', columnSpecBn:' + columnSpecBn);
            errorHandlingService.handleGenericError(status);
          });
        },
        updateColumns: function (columnSpecBn, columnData, adhocReportBn) {
          var url = '/b/api/table/adhoc/columns/update';
          var formParams = $.param({
            columnSpecBn: columnSpecBn,
            columnData: '{"columnSpecItems" : ' + angular.toJson(columnData) + '}',
            adhocReportBn: adhocReportBn
          });
          return $http({
            method: 'POST',
            url: url,
            data: formParams,
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
          }).success(function (data, status, headers, config) {
            return data.columnSpecBn;
          }).error(function (data, status, headers, config) {
            console.log('error updateColumns for columnSpecBn: ' + columnSpecBn + ' , columnData:' + columnData);
            errorHandlingService.handleGenericError(status);
          });
        }
      };
    }]);
