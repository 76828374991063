angular.module('barometerApp.compliance')
  .directive('entityComplianceSummary', ['complianceService', 'complianceDataService', 'urlService', '$rootScope',
    (complianceService, complianceDataService, urlService, $rootScope) => {
      return {
        scope: {},
        templateUrl: '/b/js/src/bit.ng/compliance/partials/entity-compliance-summary.html',
        link: (scope, element, attrs) => {

          scope.loading = true;
          scope.filtered = true;

          const load = () => {
            complianceService.getComplianceSummaryByEntityBn(urlService.getEntityBn(), scope.filtered, (nonCompliantProps) => {
              scope.nonCompliantProps = nonCompliantProps.sort(sortPropertyNames);
              scope.loading = false;
            });
          };

          const sortPropertyNames = (a, b) => {
            if (a.name < b.name)
              return -1;
            if (a.name > b.name)
              return 1;
            return 0;
          };

          scope.filterToggleClicked = () => {
            scope.filtered = !scope.filtered;
            $rootScope.$broadcast('togglePropertyCount', scope.filtered);
            scope.loading = true;
            load();
          };

          scope.$on('reloadEntityComplianceSummary', () => {
            scope.loading = true;
            load();
          });

          load();
        }
      }
    }])
  .directive('entityComplianceItem', ['entityService', 'urlService', 'utilService',
    function (entityService, urlService, utilService) {
      return {
        scope: {
          property: '=',
          order: '@'
        },
        templateUrl: '/b/js/src/bit.ng/compliance/partials/entity-compliance-item.html',
        link: function (scope, element, attrs) {
          scope.displayRules = true;
          scope.entityBnForTask = urlService.getEntityBn();

          function load () {
            scope.ruleObjects = [];
            scope.property.rules.forEach(function (ruleBn) {
              entityService.getBasicInfo(ruleBn, false, false).then(function (data) {
                var rule = data.data;
                rule._href = urlService.getUrlForBnCode(rule.bn, utilService.getBnCode(rule.bn));
                rule._propertyBn = scope.property.propertyBn;
                scope.ruleObjects.push(rule);
              });
            });
          }

          load();
        }
      }
    }])
  .directive('formComplianceAdd', ['adminService', 'complianceService', 'complianceDataService', 'entityService', 'urlService', '$modal', 'optionListService', 'utilService', 'tenantService',
    function (adminService, complianceService, complianceDataService, entityService, urlService, $modal, optionListService, utilService, tenantService) {

      return {
        scope: {},
        templateUrl: '/b/js/src/bit.ng/compliance/partials/form-compliance-add.html',
        link: function (scope, element, attrs) {

          scope.open = function ($event) {
            $event.preventDefault();

            complianceService.getNonCompliantEntitiesCountByRuleBn(urlService.getEntityBn(), function (nonCompliantCount) {
              var modal = $modal.open({
                backdrop: 'static',
                keyboard: true,
                templateUrl: '/b/js/src/bit.ng/compliance/partials/form-compliance-alert.html',
                controller: 'FormComplianceAlertCtrl',
                resolve: {
                  numOfNonCompliantEntities: function () {
                    return nonCompliantCount;
                  },
                  ruleBn: function () {
                    return urlService.getEntityBn();
                  },
                  getGenerateState: function () {
                    return scope.disableGenerate;
                  },
                  getGenerationPermission: function () {
                    return scope.hasGenerationPermission;
                  }
                }
              });

              modal.result.then(function (response) {
                if (response) {
                  scope.add();
                }
              })
            });
          };

          scope.add = function () {
            var promise = complianceDataService.createTasksForNonCompliantEntitiesByRuleBn(urlService.getEntityBn());
            promise.then(function (data) {
              if (data.data) {
                scope.disableGenerate = true;
              }
            });
          };

          scope.blackListEntityType = function () {
            var blackList = ['ACT', 'ORG', 'PER', 'SKI', 'TAG'];
            return !(blackList.indexOf(scope.entityType) > -1);
          };


          function evalCurrentUsersUserRoles (callback) {
            var personBn = utilService.getCurrentUserBn();
            var getUserRolesPromise = adminService.getUserRoles("ROLE_SUPER_EDITOR");
            getUserRolesPromise.then(function (userRoleResults) {

              var roleBn = userRoleResults.data[0].bn;
              var evaluateUserRoleByPersonBnPromise = adminService.evaluateUserRoleByPersonBn(roleBn, personBn);
              evaluateUserRoleByPersonBnPromise.then(function (evalResult) {
                return callback(evalResult.data.hasRole);
              }, function (evalErr) {
                return callback(false);
              });

            }, function (userRoleErr) {
              return callback(false);
            });

          }

          tenantService.getCurrentTenantsNavigationItems()
            .then(navItems => {
              scope.tasksEnabled = navItems.some(n => n.type === 'TASKS');
            });

          function load () {
            evalCurrentUsersUserRoles(function (evalResult) {
              scope.hasGenerationPermission = evalResult;
            });
            scope.disableGenerate = false;
            var rulePromise = entityService.getBasicInfo(urlService.getEntityBn(), false, false);
            rulePromise.then(function (data) {
              scope.entityType = data.data.entityType.code;
            });
          }

          load();
        }
      }
    }]);
