angular
  .module('barometerApp.metricAggregation')
  .controller('MetricAggregationController', MetricAggregationController);

MetricAggregationController.inject = [
  '$q',
  '$scope',
  'commonWorksheetService',
  'downloadService',
  'metricAggregationService'
];

/**
 *
 */
function MetricAggregationController($q,
                                     $scope,
                                     commonWorksheetService,
                                     downloadService,
                                     metricAggregationService) {

  $scope.loading = false;
  $scope.hasData = false;
  $scope.tableModel = {};
  $scope.currentPage = 1;
  $scope.sortColumn = null;
  $scope.csvContent = null;
  $scope.name = null;
  $scope.callbackInProcess = false;
  $scope.csvDownloading = false;

  //--------------------------------------------
  // PRIVATE FUNCTIONS
  //--------------------------------------------

  const createTopLevelTable = function (data) {
    // Create table row and column information
    var tableData = transformToRowsColumns(data);

    // Create table pagination information
    tableData.pagination = {pageSize: 10, maxSize: 0};
    tableData.pagination.currentPage = $scope.currentPage; // Page number
    tableData.pagination.numRecords = data.rows.length; // Total size of the current page - should always be less than 10
    tableData.pagination.rowCount = tableData.columns[0].totalCount; // Total number of records in the entire table
    tableData.pagination.noOfPages = Math.ceil(tableData.pagination.rowCount / 10); // Total number of pages (total records over 10 rounded up)

    // Create count information for header bar
    tableData.entityCounts = {};
    for (var l = 0; l < tableData.columns.length; l++) {
      tableData.entityCounts[tableData.columns[l].entityIndex] = tableData.columns[l].totalCount;
    }

    // Create Click function
    tableData.clickFunction = function (row, anEvent) {
      getDrilledDownTable(row, false, true);
    };

    tableData.checkboxFunction = function (checked, rowId, colId) {
      getDrilledDownTable(rowId, checked, false);
    };

    tableData.sortFunction = function (column) {
      $scope.callbackInProcess = true;
      $scope.sortColumn = column;
      metricAggregationService.getSortedMetricAggregationReportData($scope.configuration, $scope.currentPage, column).then(function (data) {
        $scope.tableModel = createTopLevelTable(data);
        $scope.callbackInProcess = false;
      }, function (data) {
        $scope.callbackInProcess = false;
        $scope.requestFailed = true;
        console.error("Error getting sorted report data ", data);
      })
    };

    return tableData;
  };

  const getDrilledDownTable = function (rowId, isGroupBySelected, isClearOldData) {
    //
    console.log("getDrilledDownTable()");
    console.log("rowId=" + rowId);
    console.log("isGroupBySelected=" + isGroupBySelected);
    console.log("isClearOldData=" + isClearOldData);
    //
    for (var i = 0; i < $scope.tableModel.rows.length; i++) {
      //
      console.log("For row i=" + i + " do ...");
      //
      if (isClearOldData) {
        //Set all non-clicked rows children to null
        $scope.tableModel.rows[i].children = null;
      }

      //Find clicked row
      if ($scope.tableModel.rows[i].entity.id == rowId) {
        //
        console.log("Found clicked row i=" + i);
        //
        const index = i;
        $scope.callbackInProcess = true;
        metricAggregationService.getMetricAggregationDrillDown(rowId, $scope.configuration, !isGroupBySelected)
          .then(function (data) {
            var childTable = transformToRowsColumns(data);

            // The child table doesn't need data in the first column
            for (var k = 0; k < childTable.columns.length; k++) {
              if (childTable.columns[k].id == "NAME1") {
                childTable.columns[k].renderId = "rowSpan";
                // If there is a group by configuration
                if ($scope.configuration.groupBy.data) {
                  childTable.columns[k].hasCheckbox = true;
                  childTable.columns[k].checkboxLabel = "Group By " + $scope.configuration.groupBy.data.name;
                  childTable.columns[k].checkboxSelected = isGroupBySelected;
                }
                // If groupby selected
                if (isGroupBySelected) {
                  //The group by returns the bn for the option list item
                  //We have to get the name, since that is a promise we have to bind a context to the promise
                  for (var l = 0; l < childTable.rows.length; l++) {
                    childTable.rows[l].COUNT2 += " " + childTable.rows[l].GROUP_BY;
                  }
                }
              }
            }

            //Set drill down row link to the drill down row bn
            for (var j = 0; j < childTable.rows.length; j++) {
              childTable.rows[j].entity.linkBn = childTable.rows[j].BN2
            }

            $scope.tableModel.rows[index].children = childTable;
            $scope.callbackInProcess = false;
          }, function (data) {
            $scope.callbackInProcess = false;
            $scope.requestFailed = true;
            console.error("Error getting drill down information ", data);
          });
      }
    }
  };

  const transformToRowsColumns = function (data) {
    //
    console.log("transformToRowsColumns()");
    //
    //Create Columns
    var returnVal = {};

    returnVal.columns = [];
    //For each column defined in the datas columnSpec
    for (var i = 0; i < data.columnSpec.length; i++) {
      //
      console.log("Transform column i=" + i);
      //
      var colEntry = {};
      var curColumnSpec = data.columnSpec[i];

      colEntry.sortable = curColumnSpec.sortable;
      colEntry.sortProperty = curColumnSpec.entityIndex;
      colEntry.display = true; //TODO find a better way to determine if the column is displayable
      if (curColumnSpec.id.indexOf("BN") > -1 || curColumnSpec.id === "GROUP_BY") colEntry.display = false; //This means that the column is just a BN column and shouldn't be displayed in the table
      if (curColumnSpec.id.indexOf("NAME") > -1) colEntry.createLink = true;

      //The first column
      if (curColumnSpec.entityIndex == 1) {
        colEntry.name = "Perspective " + EntityTypes[curColumnSpec.entityType].displayNamePlural;
        colEntry.subName = null;
      } else { //The pathway and aggregation columns
        var pathwayValues = metricAggregationService.getPathwayTypeOptions()[$scope.configuration.perspective.entityType.typeCode];
        colEntry.name = pathwayValues[$scope.configuration.pathway.data].headerInfo[curColumnSpec.entityIndex].name;
        colEntry.subName = pathwayValues[$scope.configuration.pathway.data].headerInfo[curColumnSpec.entityIndex].subName;
      }
      colEntry.entityType = curColumnSpec.entityType;
      colEntry.totalCount = curColumnSpec.totalCount;
      colEntry.entityIndex = curColumnSpec.entityIndex;
      //Since the aggregation columns have an entity type, we need to check for them and change the name to the correct value
      //The table bases icon display off of entity type, aggregation columns shouldn't have an icon
      if (curColumnSpec.id == "SUM" || curColumnSpec.id == "MIN" || curColumnSpec.id == "MAX" || curColumnSpec.id == "AVG") {
        colEntry.name = $scope.configuration.metric.entity.name + ": " + AggregationTypes[curColumnSpec.id].displayName;
        colEntry.subName = null;
        colEntry.entityType = null;
        colEntry.sortProperty = curColumnSpec.id;
        colEntry.displayType = $scope.configuration.metric.entity.displayType;
        colEntry.numberUom = $scope.configuration.metric.entity.numberUom;
        colEntry.ascending = true;
      }//End check if aggregation column

      colEntry.id = curColumnSpec.id;

      //If the current column is a sorted column
      if ($scope.sortColumn && $scope.sortColumn.id == colEntry.id) {
        colEntry.ascending = $scope.sortColumn.ascending;
      }
      console.log("Pushing colEntry.name=" + colEntry.name);
      returnVal.columns.push(colEntry);

    }//End loop through columns

    returnVal.rows = [];
    //For each row defined in the data
    for (var j = 0; j < data.rows.length; j++) {
      //
      console.log("Transform row j=" + j);
      //
      var rowEntry = {};
      var curRow = data.rows[j];

      rowEntry.entity = {};
      //In the case of the drill down, rows will have two BNs one pertaining to selected perspective and one for the particular entity
      rowEntry.entity.id = curRow.BN1;
      rowEntry.entity.linkBn = curRow.BN1;

      //for each column, grab the data
      for (var k = 0; k < data.columnSpec.length; k++) {
        //
        console.log("  For each column, grab the data: k=" + k);
        //
        var curCol = data.columnSpec[k];
        console.log("    curCol=" + curCol);
        if (curRow[curCol.id] === null) {
          curRow[curCol.id] = "No Values For Custom Field";
        }
        rowEntry[curCol.id] = curRow[curCol.id];
      }

      returnVal.rows.push(rowEntry);
    }

    return returnVal;
  };

  //--------------------------------------------
  // PUBLIC FUNCTIONS
  //--------------------------------------------

  $scope.getTableData = function (sortCol) {
    $scope.loading = true;
    commonWorksheetService.getWorksheetEntity($scope.worksheetBn).then(function (data) {
      if (!!data.configuration.perspective.entityType && !!data.configuration.pathway && !!data.configuration.metric.entity) {

        $scope.configuration = data.configuration;
        var pathwayTypes = metricAggregationService.getPathwayTypeOptions()[data.configuration.perspective.entityType.typeCode];
        $scope.pathway = pathwayTypes[data.configuration.pathway.data].pathway;
        $scope.name = data.displayableName;

        //Create table data
        metricAggregationService.getSortedMetricAggregationReportData(data.configuration, $scope.currentPage, sortCol).then(function (data) {
          $scope.tableModel = createTopLevelTable(data);
          if (!!$scope.tableModel) {
            $scope.hasData = true;
            $scope.loading = false;
            $scope.requestFailed = false;
          }
          else {
            $scope.hasData = false;
            $scope.loading = false;
            $scope.requestFailed = false;
          }
        }, function (err) {
          $scope.loading = false;
          $scope.hasData = false;
          $scope.requestFailed = true;
          console.error("Error getting report data ", err);
        });
      } else {
        $scope.hasData = false;
        $scope.loading = false;
        $scope.requestFailed = false;

      }
    });
  };

  $scope.getSortedTableData = function (sortCol) {
    $scope.callbackInProcess = true;
    commonWorksheetService.getWorksheetEntity($scope.worksheetBn).then(function (data) {
      if (!!data.configuration.perspective.entityType && !!data.configuration.pathway && !!data.configuration.metric.entity) {

        $scope.configuration = data.configuration;
        var pathwayTypes = metricAggregationService.getPathwayTypeOptions()[data.configuration.perspective.entityType.typeCode];
        $scope.pathway = pathwayTypes[data.configuration.pathway.data].pathway;
        $scope.name = data.displayableName;

        //Create table data
        metricAggregationService.getSortedMetricAggregationReportData(data.configuration, $scope.currentPage, sortCol).then(function (data) {
          $scope.tableModel = createTopLevelTable(data);
          if (!!$scope.tableModel) {
            $scope.hasData = true;
            $scope.callbackInProcess = false;
            $scope.requestFailed = false;
          }
          else {
            $scope.hasData = false;
            $scope.callbackInProcess = false;
            $scope.requestFailed = false;
          }
        }, function (err) {
          $scope.callbackInProcess = false;
          $scope.hasData = false;
          $scope.requestFailed = true;
          console.error("Error getting report data ", err);
        });
      } else {
        $scope.hasData = false;
        $scope.callbackInProcess = false;
        $scope.requestFailed = false;

      }
    });
  };

  $scope.downloadCSV = function () {
    var filename = $scope.name + ".csv";
    $scope.csvDownloading = true;
    metricAggregationService.getCSVData($scope.configuration, null, true).then(function (data) {
      $scope.csvDownloading = false;
      downloadService.download(data, filename, "text/plain");
    }, function (data) {
      $scope.csvDownloading = false;
      console.error("Error getting csv data ", data);
    })
  };

  //--------------------------------------------
  // LISTENERS (BROADCAST)
  //--------------------------------------------

  $scope.$on('MetricAggregationConfigChanged', function (event, newConfig) {
    $scope.loading = true;
    if (!!newConfig.perspective.entityType && !!newConfig.pathway && !!newConfig.metric.entity) {
      $scope.getTableData();
    }
  });

  //--------------------------------------------
  // LISTENERS (WATCH)
  //--------------------------------------------

  //TODO find a better way to do this
  //Currently, the currentPage variable is being passed
  //to the table directive, which passes it on to
  //the pagination directive.
  //The pagination directive changes the page which is what we are watching for
  $scope.$watch('currentPage', function (n, o) {
    if ($scope.sortColumn) {
      $scope.getSortedTableData($scope.sortColumn ? $scope.sortColumn : null);
    } else {
      $scope.getTableData($scope.sortColumn ? $scope.sortColumn : null);
    }
  }, true);
}