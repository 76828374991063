angular.module('barometerApp.matrix')
  .controller('MatrixCtrl', ['$scope', 'urlService', 'commonWorksheetService', 'workspaceService', 'matrixService', 'entityService', 'utilService', '$q',
    function ($scope, urlService, commonWorksheetService, workspaceService, matrixService, entityService, utilService, $q) {
      //Main worksheet controller
      $scope.loading = false;
      $scope.hasData = true;
      $scope.requestFailed = false;
      $scope.noCols = false;
      $scope.tableModel = {};
      $scope.currentPage = 1;

      //Right now the response isn't nearly nice enough for the table.
      //We need to group up the response by system then for each system group create a row list
      function createGroupedResponse (groupByList) {
        return groupBy(groupByList, function (item) {
          return [item.BN1]
        });
      }

      function groupBy (list, fun) {
        const groups = {};
        list.forEach(function (object) {
          const group = JSON.stringify(fun(object));
          groups[group] = groups[group] || [];
          groups[group].push(object);
        });
        return Object.keys(groups).map(function (group) {
          return groups[group];
        });
      }

      function createGrid (rows, configuration) {
        const grouped = createGroupedResponse(rows);
        const grid = [];
        grouped.forEach(function (object) {
          const row = {};
          object.forEach(function (item) {
            row.entity = {};
            row.entity.id = item.BN1;
            row.entity.linkBn = item.BN1;
            row.NAME1 = item.NAME1;
            row.BN1 = item.BN1;
            row[item.BN2] = { color: "GREY", value: "" };
            let color = "GREY";
            if (configuration.qualifierTypes) {
              //pessimistic color check: make the color the "worst color". So yellow beats green and red beats yellow
              item.QUALIFIER_BNS.forEach(qualifierBn => {
                const qualifierColor = configuration.qualifierTypes[qualifierBn];
                if (qualifierColor === 'RED') {
                  color = qualifierColor;
                } else if (qualifierColor === 'YELLOW' && color !== 'RED') {
                  color = qualifierColor;
                } else if (qualifierColor === 'GREEN' && color !== 'RED' && color !== 'YELLOW') {
                  color = qualifierColor;
                }
              });
              row[item.BN2].color = color
            }

            if (configuration.displayValues && configuration.displayValues[color]) {
              row[item.BN2].value = configuration.displayValues[color];
            }

          });
          grid.push(row);
        });
        return grid;
      }

      function createColumnSpec (configuration, colData) {
        const columns = [];
        //first column is the name column
        let column = {};
        column.id = "NAME1";
        column.sortable = false;
        column.sortProperty = "NAME";
        column.display = true;
        column.name = "";
        column.subName = null;
        column.createLink = true;
        column.entityType = configuration.rows.entityType.typeCode;
        columns.push(column);

        colData.forEach(function (item) {
          let column = {};
          column.renderId = 'colored';
          column.id = item.bn;
          column.sortable = false;
          column.sortProperty = item.bn;
          column.display = true;
          column.entityType = configuration.columns.entityType.typeCode;
          column.name = item.name;
          column.url = urlService.getUrlForBnCode(item.bn, utilService.getBnCode(item.bn));
          columns.push(column);
        });
        return columns;
      }

      function getColor (qualifierTypes, colInfo) {
        let color = null;
        if (colInfo == null) {
          return 'GREY';
        }
        const qualifierBns = colInfo.QUALIFIER_BNS;
        if (qualifierBns.length > 0) {
          for (var i = 0; i < qualifierBns.length; i++) {
            const qualBn = qualifierBns[i];
            const qualColor = qualifierTypes[qualBn];
            if (qualColor === 'RED') {
              color = 'RED';
              break;
            }
            else if (qualColor === 'YELLOW') {
              if (color == null || color === 'GREEN') {
                color = 'YELLOW';
              }
            }
            else if (qualColor === 'GREEN') {
              if (color == null) {
                color = 'GREEN';
              }
            }
          }
        }
        else {
          color = 'RED';
        }
        return color;
      }

      function createColumnColors (groupedQualifiers, configuration, rowData, colData, rows, columns) {
        //Skip the first column, that's just the names
        for(let col = 1; col < columns.length; col++) {
          const column = columns[col];
          let groupedQualifierRow = null;
          groupedQualifiers.rows.forEach(row => {
            if (row.BN2 === column.id) {
              groupedQualifierRow = row;
            }
          });
          column.color = getColor(configuration.qualifierTypes, groupedQualifierRow);
          column.color = getColor(configuration.qualifierTypes, groupedQualifierRow);
        }
      }

      function createTable (reportData, configuration, rowData, colData) {
        const deferred = $q.defer();
        const returnVal = { columns: [] };

        if (colData.length > 0) {
          returnVal.columns = createColumnSpec(configuration, colData);
          returnVal.rows = createGrid(reportData.rows, configuration);

          matrixService.getGroupedQualifiers($scope.configuration, rowData, colData).then(function (groupedQualifiers) {
            createColumnColors(groupedQualifiers, configuration, rowData, colData, returnVal.rows, returnVal.columns);
          }, function (err) {
            $scope.hasData = false;
            $scope.loading = false;
            $scope.noCols = false;
            $scope.requestFailed = true;
            console.error("Error in getGroupedQualifiers", err);
          });

          returnVal.pagination = { pageSize: 25, maxSize: 0 };
          returnVal.pagination.currentPage = $scope.currentPage;
          returnVal.pagination.numRecords = returnVal.rows.length;
          returnVal.pagination.rowCount = reportData.columnSpec[0].totalCount;
          returnVal.pagination.noOfPages = Math.ceil(returnVal.pagination.rowCount / returnVal.pagination.pageSize);

          returnVal.clickFunction = function (row, anEvent) {
            //NOOP
          };

          returnVal.sortFunction = function (column) {
            //NOOP
          };

          returnVal.checkboxFunction = function (row, anEvent) {
            //NOOP
          };

          deferred.resolve(returnVal);
        }
        else {
          $scope.hasData = false;
          $scope.loading = false;
          $scope.noCols = true;
          deferred.reject();
        }
        return deferred.promise;
      }


      $scope.getTableData = function () {
        $scope.loading = true;
        commonWorksheetService.getWorksheetEntity($scope.worksheetBn).then(function (data) {
          if (data.configuration &&
            data.configuration.rows && data.configuration.rows.report &&
            data.configuration.columns && data.configuration.columns.report &&
            data.configuration.displayValues && data.configuration.qualifierTypes) {
            $scope.configuration = data.configuration;
            //Get column list

            let columnCallbackData = null;
            let rowCallbackData = null;

            //mutex function for getBnNameListforreport async functions
            function getBnNameListForReportCallback (columnData, rowData) {

              if (columnData) {
                columnCallbackData = columnData;
              }
              if (rowData) {
                rowCallbackData = rowData;
              }
              if (rowCallbackData && columnCallbackData) {
                matrixService.getReportData($scope.configuration, $scope.currentPage, rowCallbackData.data, columnCallbackData.data)
                  .then(function (reportData) {
                    createTable(reportData, $scope.configuration, rowCallbackData.data, columnCallbackData.data)
                      .then(function (table) {
                        $scope.tableModel = table;
                        if (!!$scope.tableModel) {
                          $scope.hasData = true;
                          $scope.loading = false;
                          $scope.requestFailed = false;
                          $scope.$apply();
                        } else {
                          $scope.hasData = false;
                          $scope.loading = false;
                          $scope.requestFailed = false;
                        }
                      }, function (err) {
                        $scope.hasData = false;
                        $scope.loading = false;
                        $scope.requestFailed = false;
                        console.error("Error in CreateTable", err);
                      })
                  }, function (err) {
                    $scope.hasData = false;
                    $scope.loading = false;
                    $scope.noCols = false;
                    $scope.requestFailed = true;
                    console.error("Error in getReportData", err);
                  })
              }
            }

            matrixService.getBnNameListForReport($scope.configuration.columns.report.bn).then(function (colData) {
                getBnNameListForReportCallback(colData, null)
              },
              function (err) {
                $scope.hasData = false;
                $scope.loading = false;
                $scope.noCols = false;
                $scope.requestFailed = true;
                console.error("Error in getBnNameListForReport - columns", err);
              });
            matrixService.getBnNameListForReport($scope.configuration.rows.report.bn).then(function (rowData) {
                getBnNameListForReportCallback(null, rowData)
              },
              function (err) {
                $scope.hasData = false;
                $scope.loading = false;
                $scope.noCols = false;
                $scope.requestFailed = true;
                console.error("Error in getBnNameListForReport - rows", err);
              });
          } else {
            $scope.hasData = false;
            $scope.loading = false;
            $scope.noCols = false;
            $scope.requestFailed = false;
          }//end integrity check
        }, function (err) {
          $scope.loading = false;
          $scope.noCols = false;
          $scope.hasData = false;
          $scope.requestFailed = true;
          console.error("Error getting matrix report data ", err)
        });//end get worksheet promise
      };//End getTable

      $scope.$on("MatrixConfigChanged", function (event, newConfig) {
        $scope.loading = true;
        if (newConfig.rows && newConfig.rows.report &&
          newConfig.columns && newConfig.columns.report &&
          newConfig.displayValues && newConfig.qualifierTypes) {
          $scope.getTableData();
        }
      });//End watch config change

      $scope.$watch('currentPage', function (newVal, oldVal) {
        $scope.getTableData();
      }, true);

//$scope.getTableData();
    }])//end controller
  .controller('MatrixWorksheetSubheaderCtrl', ['$scope', 'urlService', '$rootScope', 'commonWorksheetService', '$modal', 'optionListService',
    function ($scope, urlService, $rootScope, commonWorksheetService, $modal, optionListService) {
      //Subheader controller
      $scope.config = null;
      $scope.colorValues = {};
      $scope.colorValues.green = [];
      $scope.colorValues.yellow = [];
      $scope.colorValues.red = [];
      $scope.prettyRows = null;
      $scope.prettyColumns = null;
      $scope.prettyColorLabels = null;

      $scope.includeEntityTypes = ['ACT', 'CAP', 'COM', 'CON', 'DAT', 'DEM', 'PHY', 'AST', 'MKT', 'ORG', 'PRD', 'SKI', 'STA', 'SYS', 'STR', 'TEC'];

      commonWorksheetService.getWorksheetEntity(urlService.getEntityBn()).then(function (entity) {
        $scope.config = entity.configuration;
        $scope.getPrettyRows();
        $scope.getPrettyColumns();
        $scope.getPrettyColorLabels();
      });

      $scope.getPrettyRows = function () {
        let name = '--';
        if ($scope.config && $scope.config.rows && $scope.config.rows.entityType) {
          name = $scope.config.rows.entityType.displayName;

          // Note that $scope.config.rows.report is the Report to filter by
          if ($scope.config && $scope.config.rows && $scope.config.rows.report) {
            name += ' (' + $scope.config.rows.report.name + ')';
          } else {
            name += ' (All)';
          }
        }
        $scope.prettyRows = name;
      };

      $scope.getPrettyColumns = function () {
        let name = '--';
        if ($scope.config && $scope.config.columns && $scope.config.columns.entityType) {
          name = $scope.config.columns.entityType.displayName;

          // Note that $scope.config.columns.report is the Report to filter by
          if ($scope.config && $scope.config.columns && $scope.config.columns.report) {
            name += ' (' + $scope.config.columns.report.name + ')';
          } else {
            name += ' (All)';
          }
        }
        $scope.prettyColumns = name;
      };

      $scope.getPrettyColorLabels = function () {
        $scope.colorValues.green = [];
        $scope.colorValues.yellow = [];
        $scope.colorValues.red = [];
        for (let item in $scope.config.qualifierTypes) {
          if ($scope.config.qualifierTypes.hasOwnProperty(item)) {
            optionListService.getOptionListItemByBn(item).then(function (data) {
              if ($scope.config.qualifierTypes[data.data.bn] === 'GREEN') {
                $scope.colorValues.green.push(data.data.name);
              } else if ($scope.config.qualifierTypes[data.data.bn] === 'YELLOW') {
                $scope.colorValues.yellow.push(data.data.name);
              } else if ($scope.config.qualifierTypes[data.data.bn] === 'RED') {
                $scope.colorValues.red.push(data.data.name);
              }
            });
          }
        }
      };

      $scope.launchConfigModal = function ($event) {
        $event.preventDefault();

        const modal = $modal.open({
          backdrop: 'static',
          keyboard: true,
          templateUrl: '/b/js/src/bit.ng/matrix/partials/matrix-config-modal.html',
          controller: 'MatrixConfigCtrl',
          resolve: {
            config: function () {
              return $scope.config;
            }
          }
        });

        // This is called for both Save and Cancel of the modal, but newConfig will only exist if Save.
        modal.result.then(function (newConfig) {
          if (newConfig) {
            $scope.config = newConfig;
            $scope.getPrettyRows();
            $scope.getPrettyColumns();
            $scope.getPrettyColorLabels();

            $rootScope.$broadcast('worksheetConfigUpdated', newConfig);
            commonWorksheetService.updateWorksheetConfig(urlService.getEntityBn(), newConfig, null, function () {
              $rootScope.$broadcast('MatrixConfigChanged', newConfig);
            });
          }
        });
      };

    }])
  .controller('MatrixConfigCtrl', ['$scope', 'bitConstants', '$modalInstance', 'layoutService', 'tableService', 'config',
    function ($scope, bitConstants, $modalInstance, layoutService, tableService, config) {
      //Config controller
      $scope.newConfig = _.cloneDeep(config);

      $scope.params = {
        source: null,
        target: null
      };

      $scope.rowTypes = bitConstants.getMatrixRowsEntityTypes();
      $scope.columnTypes = bitConstants.getMatrixColumnsEntityTypes();

      //Create correct options for existing configs
      if ($scope.newConfig.rows && $scope.newConfig.rows.entityType) {
        _.each($scope.rowTypes, function (entityType) {
          if ($scope.newConfig.rows.entityType.typeCode === entityType.typeCode) {
            $scope.newConfig.rows.entityType = entityType;
          }
        });
        setRowReports($scope.newConfig.rows.entityType.typeCode, $scope.newConfig.rows.entityType.bnCode);
      }
      if ($scope.newConfig.columns && $scope.newConfig.columns.entityType) {
        _.each($scope.columnTypes, function (entityType) {
          if ($scope.newConfig.columns.entityType.typeCode === entityType.typeCode) {
            $scope.newConfig.columns.entityType = entityType
          }
        });
        setColumnReports($scope.newConfig.columns.entityType.typeCode, $scope.newConfig.columns.entityType.bnCode);
      }

      $scope.save = function () {
        $modalInstance.close($scope.newConfig);
      };

      $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
      };

      $scope.rowEntityTypeChanged = function (typeCode, bnCode) {
        if (typeCode != null && typeCode !== 'undefined') {
          setRowReports(typeCode, bnCode);
        }
        else {
          $scope.rowReports = null;
        }
      };

      $scope.columnEntityTypeChanged = function (typeCode, bnCode) {
        if (typeCode != null && typeCode !== 'undefined') {
          setColumnReports(typeCode, bnCode);
        }
        else {
          $scope.columnReports = null;
        }
      };

      $scope.getPopulatedKeys = function (object) {
        const keys = [];
        for (const key in object) {
          if (object.hasOwnProperty(key)) {
            if (object[key]) {
              keys.push(key);
            }
          }
        }
        return keys;
      };

      function setRowReports (typeCode, bnCode) {
        layoutService.getAdvancedQueriesForTypeCode(typeCode).then(function (data) {
          $scope.rowReports = data.data;
          $scope.params.source = bnCode;
          $scope.newConfig.rows.report = getSelectedOptions($scope.rowReports, $scope.newConfig.rows.report);
          setQualifierTypes();
        });
      }

      function setColumnReports (typeCode, bnCode) {
        layoutService.getAdvancedQueriesForTypeCode(typeCode).then(function (data) {
          $scope.columnReports = data.data;
          $scope.params.target = bnCode;
          $scope.newConfig.columns.report = getSelectedOptions($scope.columnReports, $scope.newConfig.columns.report);
          setQualifierTypes();
        });
      }

      function setQualifierTypes () {
        if ($scope.params.source != null && $scope.params.target != null) {
          if (!$scope.newConfig.qualifierTypes) {
            $scope.newConfig.qualifierTypes = {};
          }
          tableService.getQualifiers($scope.params).then(function (data) {
            $scope.qualifierTypes = data.data;
            _.each($scope.qualifierTypes, function (qualifierType) {
              $scope.newConfig.qualifierTypes[qualifierType.name] = getSelectedOptions(['RED', 'YELLOW', 'GREEN'], $scope.newConfig.qualifierTypes[qualifierType.name]);
            })
          });
        }
      }

      function getSelectedOptions (options, existingDataSource) {
        let selectedOption = existingDataSource;
        if (existingDataSource) {
          _.each(options, function (option) {
            if (option.bn && existingDataSource.bn && option.bn === existingDataSource.bn) {
              selectedOption = option
            }
          });
        }
        return selectedOption;
      }

    }]);
