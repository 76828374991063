angular.module("barometerApp.filter")
/**
 * The master filter bar.
 */
    .directive("baroFilter", [
      'tableService',
      'filterService',
      'domainFilterService',
      'searchService',
      '$timeout',
      'commonWorksheetService',
      'scorecardService',
      'utilService',
      function (
          tableService,
          filterService,
          domainFilterService,
          searchService,
          $timeout,
          commonWorksheetService,
          scorecardService,
          utilService,
      ) {
        return {
          scope: {
            filter: "=",
            qualifier: '=',
            tableId: "=",
            entityType: '='
          },
          templateUrl: "/b/js/src/bit.ng/filters/partials/filters.html",
          controller: function ($scope, $element, $attrs) {
            let format = function (data) {
                  if (!data.element) {
                    return data.text;
                  }
                  let option = $(data.element);
                  let wrapper = $('<span></span>');
                  wrapper.addClass(option[0].className);
                  wrapper.text(data.text);
                  return wrapper;
                },
                setFilterState = function () {
                  // check for existing state first
                  // (leftover from a previous table/summary view of the same data)
                  let existingFilterState = filterService.getTableFilter($scope.tableId);
                  if (existingFilterState) {
                    $scope.filterModel = existingFilterState;
                  }

                  // otherwise just use the filter that was passed in
                  else {
                    $scope.filterModel = {
                      filter: $scope.filter,
                      cachedTypeahead: {},
                      entityTypeCodeOfCurrentPage: $scope.entityType,
                      typeaheadFilter: {},
                      superSelectorFilter: {},
                      tableId: $scope.tableId,

                      // used for all but typeahead
                      defaultSelect2Options: {
                        allowClear: true,
                        placeholder: 'Select filter value',   // required for single-selects to have 'x' to remove
                        dropdownCssClass: 'parent-label-group',
                        templateResult: format
                      }
                    };
                  }
                };
            setFilterState();

            // update the filter state whenever the table/summary views are swapped
            $scope.$on('load', setFilterState);
            $scope.$on('reload', setFilterState);

            $scope.shouldHideControlGroup = function (entityType) {
              return commonWorksheetService.isWorksheet(entityType) || scorecardService.isScorecard(entityType);
            };

            // special config for typeahead
            $scope.getTypeaheadSelect2Options = function (filter, select) {
              return {
                allowClear: true,
                multiple: true,
                placeholder: 'Select filter value',
                query: function (query) {
                  if ($scope.pendingPromise) {
                    $timeout.cancel($scope.pendingPromise);
                  }
                  $scope.pendingPromise = $timeout(function () {
                    // Wrap searchString with filter obj to satisfy searchService.searchEntity() signature
                    const filterSearchString = query.term ? query.term : "";
                    const filterObj = filterService.convertFilterObjectToFilterArray({ searchString: filterSearchString });
                    const targetBnTypeCode = utilService.getTypeCodeFromBnCode(filter.targetBnCode);
                    searchService.searchEntity(10, null, targetBnTypeCode, false, null, filterObj).then(function (data) {
                      let results = [];
                      _.each(data.data.entities, function (res) {
                        if (select.find("option[value='" + res.bn + "']").length === 0) {
                          let option = new Option(res.name, res.bn, false, false);
                          select.append(option);
                        }
                        results.push({id: res.bn, text: res.name});
                      });
                      query.callback({results: results});
                    })
                  }, 500);
                },
                // required for rendering existing values properly
                initSelection: function (element, callback) {

                  // this is already in the correct format:
                  // [{id: "ZZ180000001O", text: "Another Basic SYSTEM Release"}, ...]
                  callback($scope.filterModel.filter.selected[filter.filterBn] | []);

                }
              }
            };
            $scope.getMultiFilterOptions = function (filter) {
              return {
                results: filter.options
              }
            };

            // handles 'SINGLE_SELECT_VALUED' and 'GROUPED_SINGLE_SELECT_VALUED' cases
            $scope.getSelectedOptionNameForSingleSelect = function (selectedValue, filter) {
              var name = 'Any';
              $.each(filter.options, function (index, option) {

                // GROUPED_SINGLE_SELECT_VALUED matches on name(!) and has children
                if (filter.type === 'GROUPED_SINGLE_SELECT_VALUED') {
                  if (option.name === selectedValue) {
                    name = option.name;
                    return false;
                  }

                  // but children match on value again(!)
                  else if ('children' in option) {
                    $.each(option.children, function (index, childOption) {
                      if (childOption.value === selectedValue) {
                        name = childOption.name;
                        return false;
                      }
                    });
                  }
                }

                // default matches on value, is flat list
                else if (option.value === selectedValue) {
                  name = option.name;
                  return false;
                }
              });
              return name;
            };

            // handles 'MULTI_SELECT_BN' case (typehead entity selector)
            $scope.getSelectedOptionNamesForTypeahead = function (filter) {

              // this returns an array of objects for each selected entity:
              // [{id: ENTITY_BN, text: ENTITY_NAME}, ...]
              var selectedFilters = $scope.filterModel.filter.typeaheadFilter[filter.filterBn];

              // default empty state
              if (!selectedFilters || selectedFilters && selectedFilters.length === 0) {
                return ['Any'];
              }

              // array of selected entities' display names
              else {
                return _.map(selectedFilters, function (entityObj) {
                  return entityObj.text;
                });
              }
            };

            // handles all other cases
            $scope.getSelectedOptionNames = function (filter) {

              var filterBn = filter.filterBn,
                  selectedDictionary = $scope.filterModel.filter.selected,
                  filterOptionNames = [];

              if (filterBn in selectedDictionary && selectedDictionary[filterBn].length) {
                var selectedValues = selectedDictionary[filterBn];

                // check for all of the selected values in the filter options
                _.forEach(selectedValues, function (selectedValue) {
                  _.forEach(filter.options, function (option) {
                    if (selectedValue === option.value) {
                      filterOptionNames.push(option.name);
                    }
                  });
                });
              }

              if (filterOptionNames.length) {
                return filterOptionNames;
              } else {
                return ['Any'];
              }
            }
          }
        }
      }])
    /**
     * An individual filter control.
     */
    .directive('editableFilter', ['$timeout', 'domainFilterService', '$rootScope', function ($timeout, domainFilterService, $rootScope) {
      return {
        link: function (scope, element, attrs) {
          let filterEditor,
              nativeFormElement,
              isTypeahead,
              isSingleSelect,
              isMultiSelect,
              isGroupedMultiSelect,
              selectedValues = element.find('.selected-values'),
              filterEditorLoaded = false,

              closeEditor = function () {
                // hide-ification (don't destroy - make it quick to re-open)
                if (filterEditor) filterEditor.hide();
                selectedValues.removeClass('active');
                // clear any of our closeFilter event listeners
                $('body').off('click.closeFilterEditor');
              },

              activateFilterEditor = function () {
                if (!filterEditorLoaded) {
                  initFilterEditor();
                } else {
                  $timeout(function () {
                    // precautionarily close all filters before proceeding
                    $rootScope.$broadcast('closeAllFilterEditors');
                    // visual showification
                    filterEditor.show();
                    selectedValues.addClass('active');
                    // hide any tooltips
                    $('.tooltip').hide();
                    // handle click away
                    // make sure we don't trigger the body click event
                    // this handles any away-clicks that escape the backdrop, which takes a while to load
                    $('body').on('click.closeFilterEditor', function (evt) {
                      // ie8 workaround and event target normalization (BARO-12685)
                      // element.click isn't properly stopping propogation to the body
                      var target = evt ? evt.target : window.event.srcElement;
                      if (!target || !$(target).attr('class').includes('select2')) {
                         closeEditor();
                      }
                    });
                    // can't use select2 API events because they are too intertwined
                    // can't trigger 'click' because listeners are keyed on 'mousedown'
                    // targets both multi and single select2's
                    element.find('select').select2('open');
                    element.find('select').on('select2:unselecting', function (ev) {
                      if (ev.params.args.originalEvent) {
                        // When unselecting (in multiple mode)
                        ev.params.args.originalEvent.stopPropagation();
                      } else {
                        // When clearing (in single mode)
                        $(this).one('select2:opening', function (ev) {
                          ev.preventDefault();
                        });
                      }
                    })
                  })
                }
              },

              initFilterEditor = function () {
                // trigger ng-if to add DOM elements
                scope.showFilterEditor = true;
                scope.$apply();
                // wait for elements to be added
                $timeout(function () {
                  // set internal vars
                  filterEditor = element.find('.filter-editor');
                  nativeFormElement = filterEditor.find('select, input');
                  isTypeahead = nativeFormElement.is('select.select2-multi-select-bn');
                  isSingleSelect = nativeFormElement.is('select:not([multiple])');
                  isGroupedMultiSelect = scope.filter.type === 'GROUPED_MULTI_SELECT_VALUED';
                  isMultiSelect = scope.filter.type === 'MULTI_SELECT_VALUED';
                  // kick off select2
                  let select2options = isTypeahead ? scope.getTypeaheadSelect2Options(scope.filter, nativeFormElement) : scope.filterModel.defaultSelect2Options;
                  nativeFormElement.select2(select2options);
                  // typeahead gets special setup
                  if (isTypeahead) {
                    let multiSelectArray = [];
                    let change = function (e) {
                      if (e.type === 'select2:select') {
                        if (multiSelectArray.some(function (select) {
                          return select.id === e.params.data.id;
                        })) {
                          // value already exists, do nothing
                        } else {
                          multiSelectArray.push(e.params.data);
                        }
                      } else if (e.type === 'select2:unselect') {
                        multiSelectArray = multiSelectArray.filter(function (select) {
                          return select.id != e.params.data.id;
                        });
                      }
                      // other code expects the data to be an array, transform it
                      var selectedBns = _.map(multiSelectArray, function (opt) {
                        return opt.id;
                      });
                      nativeFormElement.val(selectedBns).trigger('change');
                      scope.filterModel.filter.typeaheadFilter[scope.filter.filterBn] = multiSelectArray;
                      // ng-change isn't working, manually update model on change
                      scope.filterModel.filter.selected[scope.filter.filterBn] = selectedBns;
                      scope.$apply();
                    };
                    nativeFormElement.on('select2:select', change);
                    nativeFormElement.on('select2:unselect', change);
                    // set the initial values
                    nativeFormElement.val(scope.filterModel.filter.typeaheadFilter[scope.filter.filterBn]).trigger('change');
                    // also set when swapping views
                    scope.$on('reload', function () {
                      nativeFormElement.val([]);
                    });
                  }
                  // for single-selects, close them after a selection is made
                  // (this just feels more natural)
                  else if (isSingleSelect) {
                    nativeFormElement.on('change', function () {
                      closeEditor();
                    })
                  } else if (isMultiSelect) {
                    // set the initial values
                    nativeFormElement.select2().val(scope.filterModel.filter.selected[scope.filter.filterBn]);
                  }
                  // prevent click events from bubbling to body tag
                  element.on('click', function (event) {
                    event.stopPropagation();
                  });

                  filterEditorLoaded = true;
                  activateFilterEditor();
                });
              };

          scope.rebuildEditor = function () {
            initFilterEditor();
          };

          // initially keep filterEditor out of DOM
          scope.showFilterEditor = false;

          // set click behavior on label
          selectedValues.on('click', activateFilterEditor);

          // belt-and-suspenders approach to making sure only one dropdown is ever opened
          // broadcast closeAll event before opening a new one
          scope.$on('closeAllFilterEditors', function () {
            closeEditor();
          });

          // add tooltip to label - just use the full label text
          // use manual trigger to prevent overlapping with filterEditor
          $timeout(function () {
            selectedValues.tooltip({
              title: function () {
                // filters in the filter bar
                if ($(this).closest('.control-group').length) {
                  return $(this).closest('.control-group').find('.control-label').text() +
                      ': ' + $(this).text();
                }
                // domain filter in the section title
                else {
                  return 'Domain: ' + $(this).text();
                }
              },
              container: 'body',
              placement: 'bottom',
              html: true,
              trigger: 'manual'
            }).on('mouseenter', function () {
              if (!filterEditor || filterEditor.is(":hidden")) {
                $(this).tooltip('show');
              }
            }).on('mouseleave', function () {
              if (!filterEditor || filterEditor.is(":hidden")) {
                $(this).tooltip('hide');
              }
            })
          })
        }
      }
    }])
    /**
     * An individual filter control.
     */
    .directive('groupedMultiSelect', ['tableService', '$rootScope', function (tableService, $rootScope) {
      return {
        scope: {
          gmsModel: '=',
          gmsChange: '=',
          tableId: '='
        },
        link: function (scope, element, attrs) {

          var render = function () {

            // add <option>s
            $.each(scope.gmsModel.filters, function (index, optionObj) {
              var option = $('<option></option>');
              option
                  .attr({
                    'data-is-child': optionObj.isChild,
                    'data-parent-bn': optionObj.parentBn,
                    'data-parent-value': optionObj.parentValue
                  })
                  .val(index)
                  .text(optionObj.display || optionObj.name);

              if (optionObj.isChild) {
                option.addClass('is-child');
              } else {
                option.addClass('is-parent');
              }

              // check for string match as well - after swapping the Numbers get .toString'ed apparently
              // (we could also have used optionObj.isSelected, but this is guaranteed to stay in synch over time)
              if ($.inArray(index, scope.gmsModel.multiSelectValue) > -1 || $.inArray(index + '', scope.gmsModel.multiSelectValue) > -1) {
                option.attr('selected', 'selected');
              }
              element.append(option);
            });

            // execute change event
            element.on('change', function () {

              // data is array of the selected options indices, eg: ["4", "0']
              // update model (this updates the labels)
              scope.gmsModel.multiSelectValue = element.val();

              // trigger change event requested by controller
              scope.gmsChange(element.val());
              scope.$apply();
            });
          };
          render();

          // whenever summary / table view swapped, reload values from model
          // this is required in addition to updating the gmsModel's in gmsCtrl via groupedMultiSelectChanged event
          scope.$on('load', function () {
            element.select2({
              dropdownCssClass: 'parent-label-group',
              templateResult: function (data) {
                if (!data.element) {
                  return data.text;
                }

                var option = $(data.element);

                var wrapper = $('<span></span>');
                wrapper.addClass(option[0].className);
                wrapper.text(data.text);

                return wrapper;
              }
            }).val(scope.gmsModel.multiSelectValue);
          });
          scope.$on('reload', function () {
            element.select2({
              dropdownCssClass: 'parent-label-group',
              templateResult: function (data) {
                if (!data.element) {
                  return data.text;
                }

                var option = $(data.element);

                var wrapper = $('<span></span>');
                wrapper.addClass(option[0].className);
                wrapper.text(data.text);

                return wrapper;
              }
            }).val(scope.gmsModel.multiSelectValue);
          });
        }
      }
    }]);
