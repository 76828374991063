/**
 * This file tries to follow John Papa's Angular 1 style guide (adjusted to our AngularJS version).
 *
 * https://github.com/johnpapa/angular-styleguide/blob/master/a1/README.md
 */
angular
  .module('barometerApp.simpleTable')
  .factory('simpleTableCellUtilityService', simpleTableCellUtilityService);

simpleTableCellUtilityService.$inject = ['urlService', 'utilService'];

/**
 *
 */
function simpleTableCellUtilityService(urlService, utilService) {
  //
  var placeholder = '- -';
  //
  return {
    createAnchorElement: createAnchorElement,
    createIconElement: createIconElement,
    renderIconAndAnchor: renderIconAndAnchor,
    renderLocalDate: renderLocalDate,
    renderISOUTCLocalDate: renderISOUTCLocalDate,
    renderPlain: renderPlain,
    renderUnixDateToLocalDate: renderUnixDateToLocalDate,
    renderNumberedAndNamedLink: renderNumberedAndNamedLink,
    renderPinControl: renderPinControl
  };

  /**
   * @param row
   * @param column
   * @returns {*}
   */
  function createIconElement(row, column) {
    //
    if (row.bn) {
      var bnCode = utilService.getBnCode(row.bn);
      var iconClass = EntityBnCodeToIconClass[bnCode];
      return '<span class="icon-' + iconClass + '"></span>';
    }
    return "";
  }

  /**
   * @param row
   * @param column
   * @returns {*}
   */
  function createAnchorElement(row, column) {
    if (row[column.id] !== "" && row.bn) {
      var bnCode = utilService.getBnCode(row.bn);
      var value = row[column.id];
      var url = urlService.getUrlForBnCode(row.bn, bnCode);
      value = '<a href="' + url + '">' + value + '</a>';
      return '<span>' + value + '</span>';
    }
    return "";
  };

  /**
   * Wrap any value in cell markup.
   */
  function renderCell(content) {
    //
    var value = (content) ? content : placeholder;
    return '<td><div><span>' + value + '</span></div></td>';
  }

  /**
   * Leave this here even when unused. It's useful for debugging.
   */
  function renderPlain(row, column) {
    //
    return renderCell(row[column.id]);
  }

  /**
   * Use this when your column should be linked, but it's not the same object as row.bn.
   *
   * Expects e.g. row[column.id]=["040E00000001","John Smith"].
   */
  function renderNumberedAndNamedLink(row, column) {
    //
    var cellValues = row[column.id];
    if (!cellValues || cellValues.length != 2) return renderCell(cellValues);
    //
    var cellBn = cellValues[0];
    var cellName = cellValues[1];
    //
    var bnCode = utilService.getBnCode(cellBn);
    var url = urlService.getUrlForBnCode(cellBn, bnCode);
    var anchor = '<a href="' + url + '">' + cellName + '</a>';
    //
    return renderCell(anchor);
  }

  function renderIconAndAnchor(row, column) {
    //
    return renderCell(createIconElement(row, column) + createAnchorElement(row, column))
  }

  function renderUnixDateToLocalDate(row, column) {
    //
    var unixDate = row[column.id] * 1000;
    //We need to tell moment that the timestamp is in utc
    var localDate = (unixDate === 0) ? placeholder : moment(unixDate).utc().format('L');
    return renderCell(localDate);
  }

  function renderLocalDate(row, column) {
    //
    var localDate = utilService.convertToLocalDate(row[column.id]);
    return renderCell(localDate);
  }

  function renderISOUTCLocalDate(row, column) {
    //
    var localDate = utilService.convertToISOUTCLocalDate(row[column.id]);
    return renderCell(localDate);
  }

  /**
   * TODO Need to encapsulate this better. It's not a common renderer.
   */
  function renderPinControl(row, column) {
    //
    var myLabel = 'Unpin';
    var button = '<button class="btn btn-small" disable-on-click ng-click="unPin($event, \'' + row.bn + '\')">' + myLabel + '</button>';
    return renderCell(button);
  }
}