angular.module('barometerApp.scorecard')
  .controller('TableCtrlNotCompliantView', ['$scope', '$modalInstance', 'sourceBn', 'targetBn', 'utilService',
    function ($scope, $modalInstance, sourceBn, targetBn, utilService) {

      // Note: Inspired by TableCtrlAssociationEditor
      var targetTypeCode = utilService.getEntityTypeCode(sourceBn);

      $scope.notCompliantViewModel = {
        sourceBn: sourceBn,
        targetBn: targetBn,
        targetTypeCode: targetTypeCode
      };

      $scope.close = function () {
        $modalInstance.close();
      };

    }])
    .controller('scorecardResultsController', ['$scope', 'utilService', 'bitConstants', '$rootScope', 'tableService', '$q', 'entityService', 'urlService', 'connectionService', '$modal', '$timeout',
      function($scope, utilService, bitConstants, $rootScope, tableService, $q, entityService, urlService, connectionService, $modal, $timeout) {

        $scope.scorecardResultsModel = {
          rows: [],
          columns: [],
          sort: $scope.sort || {},
          sortClass: 'icon-sort',
          pagination: $scope.paginationData ? $.parseJSON($scope.paginationData) : { pageSize: 10, maxSize: 0 },
          loaded: false,
          loading: true,
          lazyLoad: "false"
        };

        $scope.scorecardResultsModel.entityTypeCode = utilService.getEntityTypeCode($scope.entityBn);
        $scope.scorecardResultsModel.sourceEntity = $scope.scorecardResultsModel.entityTypeCode ? bitConstants.getEntityTypeForTypeCode($scope.scorecardResultsModel.entityTypeCode) : null;
        $scope.scorecardResultsModel.entityIconClass = $scope.scorecardResultsModel.sourceEntity ? EntityUtils.icons[EntityBnCodeToIconClass[$scope.scorecardResultsModel.sourceEntity.bnCode]] : null;

        var
            _targetBn,
            _sourceBn;

        $scope.openDialog = function (params) {
          // targetBn is actually the entityTypeCode. During the evolution of this directive, that changed, but I'd have to investigate if changing it would break things.
          _targetBn = params.targetBn ? params.targetBn : null;
          _sourceBn = params.sourceBn ? params.sourceBn : null;
          var modal = $modal.open({
            backdrop: 'static',
            keyboard: true,
            templateUrl: '/b/js/src/bit.ng/scorecard/partials/not-compliant-explanation-dialog.html',
            controller: 'TableCtrlNotCompliantView',
            resolve: {
              sourceBn: function () {
                return _sourceBn
              },
              targetBn: function () {
                return _targetBn;
              }
            }
          });
          modal.opened.then(function () {
            $timeout(function () {
              prototype.setModalMaxHeights();
            }, 200);
          });
        };

        function getRuleResult(ruleBn, entity) {
          if (entity.compliantRuleBns.indexOf(ruleBn) != -1) {
            return "Pass";
          } else if (entity.notCompliantRuleBns.indexOf(ruleBn) != -1) {
            return "Fail";
          } else {
            return "N/A";
          }
        }

        function augmentRowData(rowEntity) {
          var deferred = $q.defer();
          entityService.getBasicInfo(rowEntity.bn).then(function (data) {
            var row = {};
            row.name = data.data.name;
            row.bn = rowEntity.bn;
            row.ngDateFormat = utilService.getDateFormatForAnglularDateFilter()
            row.score = rowEntity.score;
            row.lastUpdate = utilService.convertToUtcDate(data.data.lastUpdateDate);
            row.compliantSince = (rowEntity.notCompliantRuleBns.length > 0) ? "--" : rowEntity.createdDate;
            row.nonCompliantProperties = rowEntity.notCompliantProperties.join(", ");
            row.url = urlService.getUrlForBnCode(rowEntity.bn, utilService.getBnCode(rowEntity.bn));
            row.ruleResults = [];
            deferred.resolve(row);
          });
          return deferred.promise;
        }

        function addConnectionInfoToRows (rows){
          var deferred = $q.defer();
          connectionService.getLinkages(_.map(rows, 'bn')).then(function(data){
            var linkages = data.data;
            // Add linkage info to each row
            _.each(rows, function(row){
              _.extend(row, _.find(linkages, function(linkage){
                return linkage.connection.bn == row.bn;
              }));
            });
            deferred.resolve(linkages);
          });
          return deferred.promise;
        }

        function getRows(pageNumber) {
          var rows = [];

          var params = {
            tableType: 'auditresults',
            sourceBn: $scope.entityBn,
            sortField: $scope.scorecardResultsModel.sort ? $scope.scorecardResultsModel.sort.field : null,
            first: (pageNumber - 1) * 10,
            count: 10,
            ascending: $scope.scorecardResultsModel.sort ? $scope.scorecardResultsModel.sort.ascending : null
          };
          tableService.getAssociationData(params).then(function (data) {
            rows = data.data.entityRows;

            // No data
            if (rows.length == 0) {
              $scope.scorecardResultsModel.loading = false;
              $scope.scorecardResultsModel.loaded = true;
            } else {
              // Pagination
              $scope.scorecardResultsModel.pagination.rowCount = data.data.rowCount || 0;
              $scope.scorecardResultsModel.pagination.unfilteredRowCount = data.data.rowCount || 0;
              $scope.scorecardResultsModel.pagination.noOfPages = Math.ceil(data.data.rowCount / $scope.scorecardResultsModel.pagination.pageSize);
              $scope.scorecardResultsModel.pagination.numRecords = data.data.entityRows.length;

              var rowPromises = [];
              _.each(rows, function (rowEntity, i) {
                var deferred = $q.defer();
                augmentRowData(rowEntity).then(function (row) {
                  rows[i] = row;

                  // Add rule result for each row
                  _.each($scope.scorecardResultsModel.columns, function (column) {
                    if (utilService.getEntityTypeCode(column.bn) === 'CRL') {
                      row.ruleResults.push({
                        bn: column.bn,
                        result: getRuleResult(column.bn, rowEntity)
                      });
                    }
                  });
                  deferred.resolve();
                });
                rowPromises.push(deferred.promise);
              });

              // Special handling for Connection results
              // Since scorecardResultsModel doesn't know the entityType, check the first row
              var isConnectionType = rows.length && utilService.getEntityTypeCode(rows[0].bn) == 'CON';
              if (isConnectionType){
                rowPromises.push(addConnectionInfoToRows(rows));
              }

              $q.all(rowPromises).then(function () {

                // Prepend the Non-Rule columns if necessary
                if ($scope.scorecardResultsModel.columns.length == 0 || $scope.scorecardResultsModel.columns[0].isRule) {
                  prependNonRuleColumns(isConnectionType);
                }
                $scope.scorecardResultsModel.rows = rows;
                $scope.scorecardResultsModel.loading = false;
                $scope.scorecardResultsModel.loaded = true;
              });

            }
          });
        }

        function getRules() {
          var params = {
            tableType: "association",
            includeColumnSpec: false,
            includeFilterSpec: false,
            sourceBn: $scope.entityBn,
            sourceCodeBn: "4A",
            targetTypeBn: "3Z",
            sortPropertyBn: null,
            relationshipType: "RELATED_TO"
          };
          return tableService.getAssociationData(params);
        }

        function prependNonRuleColumns(isConnectionType) {


          var nonRuleColumns = [];

          // Connections get special 3-column rendering
          if (isConnectionType){
            nonRuleColumns.push({
              bn: null,
              name: "Sources"
            });
            nonRuleColumns.push({
              bn: null,
              name: "Connection"
            });
            nonRuleColumns.push({
              bn: null,
              name: "Targets"              });

          } else {
            nonRuleColumns.push({
              bn: null,
              name: "Name",
              sortValue: "ORDER_VALUE",
              sortClass: !$scope.scorecardResultsModel.sorted ? 'icon-sort' : $scope.scorecardResultsModel.sort.field == 'ORDER_VALUE' ? $scope.scorecardResultsModel.sortClass : 'icon-sort',
              sorted: $scope.scorecardResultsModel.sorted ? $scope.scorecardResultsModel.sorted : false,
              ascending: $scope.scorecardResultsModel.sort.ascending ? $scope.scorecardResultsModel.sort.ascending : null
            });
          }
          nonRuleColumns.push({
            bn: null,
            name: "Score",
            sortValue: "SCORE",
            sortClass: !$scope.scorecardResultsModel.sorted ? 'icon-sort' : $scope.scorecardResultsModel.sort.field == 'SCORE' ? $scope.scorecardResultsModel.sortClass : 'icon-sort',
            sorted: $scope.scorecardResultsModel.sorted ? $scope.scorecardResultsModel.sorted : false,
            ascending: true
            // ascending: $scope.scorecardResultsModel.sort.ascending ? $scope.scorecardResultsModel.sort.ascending : null
          });
          nonRuleColumns.push({
            bn: null,
            name: "Last Updated"
          });
          nonRuleColumns.push({
            bn: null,
            name: "Compliant Since"
          });
          nonRuleColumns.push({
            bn: null,
            name: "Failed Properties"
          });
          $scope.scorecardResultsModel.columns = nonRuleColumns.concat($scope.scorecardResultsModel.columns);
        }

        $scope.sortColumn = function (column) {

          column.sorted = true;
          $scope.scorecardResultsModel.sorted = column.sorted;

          column.ascending = !column.ascending;

          column.sortClass = 'icon-sort';
          if (column.ascending != null) {
            column.sortClass = column.ascending ? 'icon-sort-up' : 'icon-sort-down';
          }

          $scope.scorecardResultsModel.sortClass = column.sortClass;
          $scope.scorecardResultsModel.sort.ascending = column.ascending;
          $scope.scorecardResultsModel.sort.sortProperty = column.sortProperty;
          $scope.scorecardResultsModel.sort.field = column.sortValue;

          $scope.loadPage(1);
        };

        $scope.loadPage = function (pageNumber) {
          $scope.scorecardResultsModel.pagination.currentPage = pageNumber;
          if ($scope.scorecardResultsModel.columns.length) {
            getRows(pageNumber);
          } else {
            getRules().then(function (data) {
              $scope.scorecardResultsModel.columns = _.map(data.data.rows, 'entity');
              _.each($scope.scorecardResultsModel.columns, function(column){
                column.isRule = true;
              });
              getRows(pageNumber);
            });
          }
        };

        $scope.$watch('scorecardResultsModel.pagination.currentPage', function (newValue, oldValue) {
          if (typeof newValue === "undefined" || newValue == oldValue) return;
          $scope.loadPage(newValue);
        });

        $scope.init = function () {
          if ($scope.scorecardResultsModel.lazyLoad === "false") {
            $scope.loadPage(1);
          }
        };

        $rootScope.$on("loadSection", function (event, sectionBn, forceReload) {
          var matchesSectionBn = $scope.tableType && $scope.tableType.substring(0, sectionBn.length) == sectionBn;
          if (matchesSectionBn && (!$scope.scorecardResultsModel.loaded || forceReload)) {
            $scope.scorecardResultsModel.loaded = true;
            $scope.loadPage(1);
          }
        });

        $scope.init();
      }]);
