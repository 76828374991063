angular.module('barometerApp.field')
    .service('fieldService', ['$http', '$rootScope', 'alertService',
      function ($http, $rootScope, alertService) {
        return {
          getField: function (bn, purpose) {
            return $http({method: 'GET', url: '/b/api/fields/' + bn, params: {purpose : purpose}}).
            success(function (data, status, headers, config) {
            }).
            error(function (data, status, headers, config) {
              console.log('error in fieldService.getField for bn: ' + bn);
            });
          },

          getAllFields: function () {
            return $http({method: 'GET', url: '/b/api/fields'}).
            success(function (data, status, headers, config) {
              //
            }).
            error(function (data, status, headers, config) {
              console.log('error in fieldService.getAllFields');
            });
          },

          getFieldPermission: function(bn) {
            return $http({method: 'GET', url: '/b/api/fieldpermissions/' + bn}).
              success(function (data, status, headers, config) {
            }).
            error(function (data, status, headers, config) {
              console.log('error in fieldService.getField for bn: ' + bn);
            });
          },

          removeFieldPermission: function(bn) {
            return $http({
              method: 'DELETE',
              url: '/b/api/fieldpermissions/' + bn
            }).
            success(function (data, status, headers, config) {
            }).
            error(function (data, status, headers, config) {
              console.log('error in fieldService.createFieldPermission');
              var msgString = "Unable to edit entity";
              if (status >= 400 && data.violations) {
                msgString += ': ' + data.violations;
              }

              alertService.addErrorAlert(msgString);
            });
          },

          upsertFieldPermission: function (json) {
            var jsonString = angular.toJson(json);
            var requestData = jsonString;
            return $http({
              method: 'POST',
              url: '/b/api/fieldpermissions',
              headers: {'Content-Type': 'application/json'},
              data: requestData
            }).
            success(function (data, status, headers, config) {
            }).
            error(function (data, status, headers, config) {
              console.log('error in fieldService.createFieldPermission');
              var msgString = "Unable to edit entity";
              if (status >= 400 && data.violations) {
                msgString += ': ' + data.violations;
              }

              alertService.addErrorAlert(msgString);
            });
          },
          
          createField: function (createJson, errorCallback) {
            var jsonString = angular.toJson(createJson);
            var requestData = jsonString;
            return $http({
              method: 'POST',
              url: '/b/api/fields',
              headers: {'Content-Type': 'application/json'},
              data: requestData
            }).
            success(function (data, status, headers, config) {

              // Send Field data
              $rootScope.$broadcast('addInitialFieldPermissions', data);


            }).
            error(function (data, status, headers, config) {
              console.log('error in fieldService.createField');
              var msgString = "Unable to edit entity";
              if (status >= 400 && data.violations) {
                msgString += ': ' + data.violations;
              }

              alertService.addErrorAlert(msgString);
              errorCallback(data, status);
            });
          },

          deleteField: function (fieldBn) {
            return $http({
              method: 'DELETE',
              url: '/b/api/fields/' + fieldBn
            }).
            success(function (data, status, headers, config) {
            }).
            error(function (data, status, headers, config) {
              console.log('error in fieldService.deleteField');
              var msgString = "";
              if (status === 409) {
                var count = data.associationErrors['FLO'];
                if (count > 1) {
                  msgString += String.format("Cannot delete this item. There are {0} locations that are currently using this custom field.", count);
                } else {
                  msgString += "Cannot delete this item. There is 1 location that is currently using this custom field";
                }
                //localStorage.setItem("alerts", JSON.stringify({type: 'alert', msg: msgString }));
                $rootScope.$broadcast('errorAlertMessage', msgString);
              } else {
                console.log('unhandled error in fieldService.deleteField bn: ' + bn);
              }
            });
          },

          updateField: function (bn, updateJson, errorCallback) {
            var jsonString = angular.toJson(updateJson);
            //var requestData = $.param({updateFieldReq: jsonString});
            var requestData = jsonString;
            return $http({
              method: 'PUT',
              url: '/b/api/fields/' + bn,
              headers: {'Content-Type': 'application/json'},
              data: requestData
            }).
            success(function (data, status, headers, config) {
            }).
            error(function (data, status, headers, config) {
              console.log('error in fieldService.updateField');
              if (status === 409) {
                var validationMsg = "Cannot save Custom Field, an option that was removed is in use by: ";
                _.each(EntityTypes, function (entityType) {
                  var associationError = data.associationErrors[entityType.typeCode];
                  if (associationError) {
                    if (associationError > 1) {
                      validationMsg += "<br>" + associationError + " " + entityType.displayNamePlural;
                    } else {
                      validationMsg += "<br>" + associationError + " " + entityType.displayName;
                    }
                  }
                });
                $rootScope.$broadcast('errorAlertMessage', validationMsg);
              }
              if (errorCallback) {
                errorCallback(data);
              }
            });
          },

          forceEditableControlLevelForBrowseTable: function(editableTableModel, editableTableData) {
            if (editableTableModel.entityTypeCode === 'FLD') {
              if (editableTableData.data.rows != null) {
                if(editableTableData.data.rows.length > 0) {

                  for(var i = 0; i<editableTableData.data.rows.length; i++){
                    editableTableData.data.rows[i].entity.controlLevel = "editable";
                  }

                }
              }
            }
            return editableTableData;
          },

          validateFieldPermission: function(oldModel, newModel) {
            // Make sure that the two models are not identical.
            var valid = true;
            if (
              oldModel.principalBn === newModel.principalBn
              && oldModel.controlLevel === newModel.controlLevel) {
              valid = false;
            }
            return valid;
          }
        };

      }]);