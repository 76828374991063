angular
  .module('barometerApp.tasks')
  .controller('TasksPageController', TasksPageController)
  .controller('TaskViewController', TaskViewController);

TasksPageController.$inject = ['$rootScope', '$scope', 'tasksService', 'utilService', 'recentLocationsService'];

/**
 * Manages entire Tasks page.
 *
 * @author Tim Cramer
 */
function TasksPageController($rootScope, $scope, tasksService, utilService, recentLocationsService) {

  $scope.currentUserFirstName = utilService.getCurrentUserFirstName();
  var userBn = utilService.getCurrentUserBn();

  recentLocationsService.getRecentsFromServerAndNotify();

  $scope.showHelp = true;
  $scope.myOpenTasksActive = true;
  $scope.myCompletedTasksActive = false;
  $scope.tasksIAssignedActive = false;

  var selectedTaskDataProvider = tasksService.getOpenTasksAssignedToPersonBn;

  function loadTasks() {
    $scope.loading = true;
    $scope.reset();

    var requestDataProvider = selectedTaskDataProvider;

    selectedTaskDataProvider(userBn, $scope.first, $scope.count).then(function (data) {

      var tasks = data.tasks;
      var totalTasks = data.totalTasks;

      // Only do anything with the response if the request's data provider is still selected
      // (Handles people clicking quickly between the tabs -- see BARO-17350)
      if (requestDataProvider === selectedTaskDataProvider) {
        $scope.first = $scope.first + tasks.length;
        $scope.tasks = tasks;
        $scope.totalItems = totalTasks;
        $scope.hiddenTaskCount = 0;
        if ($scope.totalItems >= $scope.first) {
          $scope.hasMoreTasks = true;
          // add 1, because first starts at 1 instead of 0
          var tempTaskCount = $scope.totalItems - $scope.first + 1;
          tempTaskCount >= 10 ? $scope.hiddenTaskCount = 10 : $scope.hiddenTaskCount = tempTaskCount;
        }
        $scope.loading = false;
      }
    });
  }

  $scope.reset = function () {
    $scope.first = 1;
    $scope.count = 10;
    $scope.tasks = [];
    $scope.totalItems = 0;
    $scope.hasMoreTasks = false;
    $scope.hiddenTaskCount = 0;
  };

  $scope.reloadTasks = function () {
    loadTasks();
    $rootScope.$broadcast('taskCountRefreshed');
  };

  $scope.$on("taskCompleted", function (event) {
    $scope.first--;
  });

  $scope.showMore = function () {
    $scope.loadingMore = true;
    selectedTaskDataProvider(userBn, $scope.first, $scope.count).then(function (data) {
      var additionalTasks = data.tasks;
      var totalTasks = data.totalTasks;

      $scope.first = $scope.first + additionalTasks.length;
      $scope.tasks = $scope.tasks.concat(additionalTasks);
      $scope.totalItems = totalTasks;
      $scope.hasMoreTasks = false;
      $scope.hiddenTaskCount = 0;
      if ($scope.totalItems >= $scope.first) {
        $scope.hasMoreTasks = true;
        // add 1, because first starts at 1 instead of 0
        var tempTaskCount = $scope.totalItems - $scope.first + 1;
        tempTaskCount >= 10 ? $scope.hiddenTaskCount = 10 : $scope.hiddenTaskCount = tempTaskCount;
      }
      $scope.loadingMore = false;
    });
  };

  $scope.myOpenTasksClicked = function () {
    selectedTaskDataProvider = tasksService.getOpenTasksAssignedToPersonBn;
    loadTasks();
    $scope.myOpenTasksActive = true;
    $scope.myCompletedTasksActive = false;
    $scope.tasksIAssignedActive = false;
  };

  $scope.myCompletedTasksClicked = function () {
    selectedTaskDataProvider = tasksService.getCompletedTasksAssignedToPersonBn;
    loadTasks();
    $scope.myOpenTasksActive = false;
    $scope.myCompletedTasksActive = true;
    $scope.tasksIAssignedActive = false;
  };

  $scope.tasksIAssignedClicked = function () {
    selectedTaskDataProvider = tasksService.getAllTasksAssignedByPersonBn;
    loadTasks();
    $scope.myOpenTasksActive = false;
    $scope.myCompletedTasksActive = false;
    $scope.tasksIAssignedActive = true;
  };

  loadTasks();
}

TaskViewController.$inject = ['$scope', 'entityService', 'tasksDataService', 'tasksService', 'utilService', 'pageService'];

function TaskViewController($scope, entityService, tasksDataService, tasksService, utilService, pageService) {

  pageService.setIsEntityPage(true);
  var TASK_PERSON_ASSIGNED_TO_RELATIONSHIP_TYPE = 'ASSIGNED_TO';
  var TASK_PERSON_ASSIGNED_BY_RELATIONSHIP_TYPE = 'ASSIGNED_BY';
  $scope.taskModel = {};
  var taskBn = location.pathname.split('/')[3];
  
  entityService.getBasicInfo(taskBn).then(function(data) {
    $scope.taskModel.ngDateFormat = utilService.getDateFormatForAnglularDateFilter();
    $scope.taskModel.task = data.data;
  });

  tasksDataService.getTaskRequesterByTaskBn(taskBn, TASK_PERSON_ASSIGNED_BY_RELATIONSHIP_TYPE, 1, 1).then(function(data) {
    $scope.taskModel.assignedBy = data.rows[0];
  });

  tasksDataService.getTaskRequesterByTaskBn(taskBn, TASK_PERSON_ASSIGNED_TO_RELATIONSHIP_TYPE, 1, 1).then(function(data) {
    $scope.taskModel.assignedTo = data.rows[0];
  });

  $scope.reopenTask = function() {
    tasksService.updateTask($scope.taskModel.task.bn, $scope.taskModel.task, false).then(function (updatedTask) {
      $scope.taskModel.task = updatedTask;
    });
  }

  $scope.completeTask = function() {
    tasksService.updateTask($scope.taskModel.task.bn, $scope.taskModel.task, true).then(function (updatedTask) {
      $scope.taskModel.task = updatedTask;
    });
  }
}
