angular.module('barometerApp.entityMixed')

// These two controllers are very similar.  Maybe we combine them into one controller with an unused method or two depending on the scenario
  .controller('SearchResultsCtrl', ['$rootScope', '$scope', 'searchService', '$location', 'urlService', 'bitConstants', 'entityMixedService', 'pageService', 'layoutService', 'styleService',
    function ($rootScope, $scope, searchService, $location, urlService, bitConstants, entityMixedService, pageService, layoutService, styleService) {

      $scope.layoutModel = {
        layout: MixedCollectionNameToLayout['search'],
        selectedSectionBn: urlService.getSectionBnFromUrl() || 'OVERVIEW'
      };

      $scope.configModel = {
        queryKey: null
      };
      $scope.entityData = {
        groups: []
      };
      $scope.loadingSearchResults = true;

      $scope.loadSearchResults = function (queryKey) {
        searchService.searchMixed(queryKey, 5).then(function (results) {
          $scope.loadingSearchResults = false;
          $scope.entityData.totalCount = results.data.count;
          $scope.entityData.queryString = results.data.queryString;
          //add sections based on layout
          _.forEach($scope.layoutModel.layout.contentBlocks, function (cBlock) {
            var resultGroup = entityMixedService.extractGroupResultsForEntityType(results.data.results, cBlock.sectionBn);
            if (resultGroup && resultGroup.groupCount > 0) {
              $scope.layoutModel.layout.sections.push({
                bn: cBlock.sectionBn,
                order: cBlock.order,
                roles: cBlock.roles,
                category: "related",
                assocCode: resultGroup.entityTypeCode,
                name: bitConstants.getEntityTypeDisplayNameForTypeCode(resultGroup.entityTypeCode, true),
                count: resultGroup.groupCount,
                iconClass: styleService.getIconClassForEntityTypeCode(resultGroup.entityTypeCode),
                entityResults: resultGroup.entityTypeResults
              });
            }
          });
          pageService.setTitle(String.format("{0} Results for '{1}'", $scope.entityData.totalCount, $scope.entityData.queryString));
        });
      };

      //parse url param to determine what to load
      var queryKey = urlService.getQueryKey();
      if (queryKey) {
        $scope.configModel.queryKey = queryKey;
        $scope.loadSearchResults(queryKey);
      }

      $scope.$on('sectionRenderComplete', function (event) {
        prototype.init();
        $scope.$broadcast("loadAllVisibleSections");
      });

      $scope.setSelectedSection = $scope.goToSection;

      $scope.goToSection = function (sectionBn, $event) {
        $rootScope.$broadcast('leftTabSelected', {sectionBn: sectionBn});
        $scope.layoutModel.selectedSectionBn = sectionBn;
      };
    }
  ])

  .controller('EnterpriseDashboardCtrl', ['$window', '$element', '$timeout', '$rootScope', '$scope', 'searchService', 'urlService', 'bitConstants', 'entityMixedService', 'pageService', 'recentLocationsService', 'styleService', '$filter', 'priorityEntitiesService', 'searchByFieldNameService', 'queryKeyService', 'localStorageService', 'tenantService', 'redux',
    function ($window, $element, $timeout, $rootScope, $scope, searchService, urlService, bitConstants, entityMixedService, pageService, recentLocationsService, styleService, $filter, priorityEntitiesService, searchByFieldNameService, queryKeyService, localStorageService, tenantService, redux) {

      var LS_KEY = 'enterprise_tiles_drill_in';
      var $isotopeTiles;

      $scope.priorityEntities = [];
      $scope.isotopeInitialized = false;

      let unsubscribePriorityEntities = () => {};

      $scope.layoutModel = {
        layout: MixedCollectionNameToLayout['enterprise'],
        selectedSectionBn: 'DASHBOARD',
        navExpanded: false
      };
      const catalogType = urlService.getSectionBnFromUrl();
      if (catalogType != null) {

      }
      $scope.entityData = {
        groups: []
      };

      $scope.tilesModel = {
        tiles: [],
        expanded: false
      };

      function saveDrillInStates() {
        var drillInStates = {};
        _.each($scope.tilesModel.tiles, function (tile) {
          drillInStates[tile.bn] = tile.drillInState;
        });
        localStorageService.set(LS_KEY, drillInStates);
      }

      function getSavedDrillInStates() {
        var saved = localStorageService.get(LS_KEY);
        if (saved) {
          return saved;
        }
        return null;
      }

      // Init fancy isotope JS layout
      function initIsotopeLayout(){
        return $timeout(function () {
          $isotopeTiles = $("#enterprise-tiles").isotope({
            layoutMode: 'fitRows',
            percentPosition: true,
            getSortData: {
              isDrilledIn: '[data-is-drilled-in]',
              order: '[data-order] parseInt'
            },
            sortBy: ['isDrilledIn', 'order'],
            sortAscending: {
              isDrilledIn: false,
              order: true
            },
            filter: '.tile.visible'
          });

          // Update layout on resize
          var resizeTimer;
          $(window).on('resize', function(e) {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(function() {
              $isotopeTiles.isotope('layout');
            }, 550);
          });

          $scope.isotopeInitialized = true;
        });
      }

      function updateIsotopeLayout(){
        $timeout(function(){
          $isotopeTiles.isotope('updateSortData').isotope();
          $timeout(function(){
            $isotopeTiles.isotope('layout');
          }, 550);
        });
      }

      $scope.isNonPriority = function (sectionOrTile) {
        // Note that sectionOrTile.bn is a three-letter typecode
        return !priorityEntitiesService.isPriorityEntityType(sectionOrTile.bn);
      };

      $scope.isNavItemCollapsed = function (section) {
        // Nav must be collapsed; priorities must exist; must not be priority
        return !$scope.layoutModel.navExpanded && $scope.priorityEntities.length && $scope.isNonPriority(section)
      };

      $scope.isTileVisible = function (tile) {
        // Tiles must be collapsed; priorities must exist; must not be a priority
        return (
          $scope.tilesModel.expanded ||
          $scope.priorityEntities.length === 0 ||
          !$scope.isNonPriority(tile)
        );
      };

      $scope.hasHiddenSectionOrTile = function () {
        var hasHidden;

        if ($scope.priorityEntities.length === 0) return false;

        hasHidden = false;
        _.each($scope.tilesModel.tiles, function (tile) {
          if (!tile.isPriorityEntity) {
            hasHidden = true;
            return false;
          }
        });
        return hasHidden;
      };

      $scope.hiddenTileToggleClicked = function(){
        $scope.tilesModel.expanded = !$scope.tilesModel.expanded;
        updateIsotopeLayout();
      };

      /**
       * Get domain counts.
       */
      $scope.domainDrillInClicked = function (event, tile) {
        event.preventDefault();
        event.stopPropagation();
        tile.isLoading = true;

        searchByFieldNameService.getDomainCountsForEntityType(tile.bn).then(function (data) {
          tile.drillInState = tile.drillInState === 'domain' ? null : 'domain';
          tile.drillInItems = _.sortBy(data, 'order');
          tile.isLoading = false;
          saveDrillInStates();
          updateIsotopeLayout();
        });
      };

      /**
       * Get type counts.
       */
      $scope.typeDrillInClicked = function (event, tile) {
        event.preventDefault();
        event.stopPropagation();
        tile.isLoading = true;

        searchByFieldNameService.getTypeCountsForEntityType(tile.bn).then(function (data) {
          tile.drillInState = tile.drillInState === 'type' ? null : 'type';
          tile.drillInItems = _.sortBy(data, 'order');
          tile.isLoading = false;
          saveDrillInStates();
          updateIsotopeLayout();
        });
      };

      $scope.collapseTile = function (event, tile) {
        event.preventDefault();
        event.stopPropagation();
        tile.drillInState = null;
        saveDrillInStates();
        updateIsotopeLayout();
      };

      $scope.tileHeaderClicked = function (event, tile) {
        if (!tile.drillInState) {
          $scope.goToSection(tile.bn)
        }
      };

      $scope.subTileClicked = function (subTile, parentTile) {
        var entityType = parentTile.bn;
        var fieldBn = subTile.bn;
        subTile.isLoading = true;

        // Pivot on domain or type
        if (parentTile.drillInState === 'domain') {
          var lifecycleStateEnabled = true;
          if(entityType === 'ORG') {
            lifecycleStateEnabled = false;
          }
          queryKeyService.getQueryKeyForDomainPivot(entityType, fieldBn, lifecycleStateEnabled).then(function (results) {
            var newQueryKey = results.data.queryKey;
            $window.location.href = urlService.getBaseUrl() + urlService.getSingleTypedCollectionPageForEntityType(entityType) + "/querykey" + newQueryKey;
          });
        } else if (parentTile.drillInState === 'type') {
          queryKeyService.getQueryKeyForTypePivot(entityType, fieldBn, true).then(function (results) {
            var newQueryKey = results.data.queryKey;
            $window.location.href = urlService.getBaseUrl() + urlService.getSingleTypedCollectionPageForEntityType(entityType) + "/querykey" + newQueryKey;
          });
        } else {
          console.log('Unknown subTile clicked...');
        }
      };

      const refreshPriorityEntities = newPriorityEntities => {
        $scope.priorityEntities = newPriorityEntities;
        updateIsotopeLayout();
      };

      $scope.loadDashboardData = function () {

        Promise.all([
          searchService.searchEnterpriseCounts(),
        ]).then(function ([searchResults]) {
          //add sections based on layout
          _.forEach($scope.layoutModel.layout.contentBlocks, function (cBlock) {
            var resultGroup = entityMixedService.extractGroupResultsForEntityType(searchResults.data.results, cBlock.sectionBn);
            if (resultGroup && tenantService.isAnAuthorizedEntityTypeCode(cBlock.sectionBn)) {
              $scope.layoutModel.layout.sections.push({
                bn: cBlock.sectionBn,
                order: cBlock.order,
                roles: cBlock.roles,
                iconClass: styleService.getIconClassForEntityTypeCode(resultGroup.entityTypeCode),
                category: cBlock.category ? cBlock.category : "related",
                assocCode: resultGroup.entityTypeCode,
                count: resultGroup.groupCount,
                name: bitConstants.getEntityTypeDisplayNameForTypeCode(resultGroup.entityTypeCode, true),
                isPriorityEntity: priorityEntitiesService.isPriorityEntityType(cBlock.sectionBn)
              });
            }
          });

          // Populate tiles
          var secureTiles = $filter('secureByRoles')($scope.layoutModel.layout.sections);

          // Get saved drill-in state
          var savedDrillInStates = getSavedDrillInStates();

          _.forEach(secureTiles, function (tile) {
            // Don't include the News
            if (tile.bn !== 'DAY') {

              tile.showDomainDrillIn = searchByFieldNameService.isDomained(tile.bn);
              tile.showTypeDrillIn = searchByFieldNameService.isTyped(tile.bn);

              // Puff out saved drill-in state
              if ($scope.isTileVisible(tile) && savedDrillInStates && savedDrillInStates.hasOwnProperty(tile.bn)) {
                tile.drillInState = savedDrillInStates[tile.bn];
                if (tile.drillInState === 'domain') {
                  tile.isLoading = true;
                  searchByFieldNameService.getDomainCountsForEntityType(tile.bn).then(function (data) {
                    tile.drillInItems = _.sortBy(data, 'order');
                    tile.isLoading = false;
                    updateIsotopeLayout();
                  });
                } else if (tile.drillInState === 'type') {
                  searchByFieldNameService.getTypeCountsForEntityType(tile.bn).then(function (data) {
                    tile.drillInItems = _.sortBy(data, 'order');
                    tile.isLoading = false;
                    updateIsotopeLayout();
                  });
                }
              }

              // The angular state management is fugged up because of the module
              // restriction code.
              $scope.$apply(function () {
                $scope.tilesModel.tiles.push(tile);
              });

            }
          });

          pageService.setTitle("Enterprise");

          initIsotopeLayout().then(() => {
            // don't subscribe to priorityEntities until the isotope layout has finished initializing
            unsubscribePriorityEntities = priorityEntitiesService.listenForPriorityEntities((newPriorityEntities = []) => {
              refreshPriorityEntities(newPriorityEntities);
            });
          });
        });
      };

      $scope.loadDashboardData();

      // make call to load recents data.  notice we are not adding a new recents record for collection pages.
      recentLocationsService.getRecentsFromServerAndNotify();

      $scope.$on('sectionRenderComplete', function (event) {
        prototype.init();
        $scope.$broadcast("loadAllVisibleSections");
      });

      $scope.$on('priorityEntitiesChanged', (event, newPriorityEntities) => {
        refreshPriorityEntities(newPriorityEntities)
      });

      $scope.goToSection = function (sectionBn, $event) {
        $rootScope.$broadcast('leftTabSelected', {sectionBn: sectionBn});
        $scope.layoutModel.selectedSectionBn = sectionBn;
      };

      // remove redux listeners when the component is destroyed
      $scope.$on('$destory', () => {
        unsubscribePriorityEntities();
      });
    }])

  .controller('BasicLoadResourcesCtrl', ['recentLocationsService',
    function (recentLocationsService) {
      recentLocationsService.getRecentsFromServerAndNotify();
    }])

  .controller('InsightDashboardCtrl', ['$window', '$rootScope', '$scope', 'searchService', '$location', 'urlService', 'recentLocationsService', 'insightTypeService',
    function ($window, $rootScope, $scope, searchService, $location, urlService, recentLocationsService, insightTypeService) {

      $scope.tagModel = {};

      recentLocationsService.getRecentsFromServerAndNotify();

      $scope.filterByTag = function (insightEntity) {
        return $scope.tagModel.activeTag === null || insightEntity.tags.findIndex(tag => tag.key === $scope.tagModel.activeTag) !== -1;
      };
      $scope.getTagDescription = () => (
        ($scope.tagModel.activeTag) ? $scope.tagModel.tags.find(tag => tag.key === $scope.tagModel.activeTag).description : 'A collection of features to help you gain and share insight into the data in your enterprise map'
      );

      insightTypeService.getInsightTypes().then(results => {
        const insightTypes = results.data;
        const uniqueTags = [];

        insightTypes.forEach(insightType => {
          insightType.tags.forEach(tag => {
            if (!uniqueTags.find(t => t.key === tag.key)) {
              uniqueTags.push(tag);
            }
          });
        });

        const compareName = (a, b) => { if (a.name < b.name) return -1; if (a.name > b.name) return 1; return 0; };

        insightTypes.sort(compareName);
        uniqueTags.sort(compareName);

        $scope.insightEntities = insightTypes;

        $scope.tagModel = {
          tags: uniqueTags,
          activeTag: null
        };
      });
    }])

  .controller('CustomDashboardCtrl', ['$scope', '$location', 'layoutService', 'pageService', 'securityService', 'recentLocationsService', 'urlService',
    'errorHandlingService', '$rootScope', 'entityService',
    function ($scope, $location, layoutService, pageService, securityService, recentLocationsService, urlService,
      errorHandlingService, $rootScope, entityService) {

      $scope.layoutModel = {
        dashboardEditor: true  // PAJ TODO: Any security ? securityService.hasRole('ROLE_REPORT_ADMINISTRATOR')
      };

      $scope.containsInsightHelp = false;
      $scope.showInsightHelp = false;
      $scope.toggleInsightHelp = function () {
        $scope.showInsightHelp = !$scope.showInsightHelp;
      };
      $scope.subheaderState = {
        active: false
      };
      $scope.toggleSubheader = function () {
        $scope.subheaderState.active = !$scope.subheaderState.active;
      };

      function checkForInsightHelp() {
        var type = urlService.getEntityTypeCodeOfCurrentPage();
        $scope.containsInsightHelp = urlService.isInsightPage(type);
      }

      // make call to load recents data.  notice we are not adding a new recents record for collection pages.
      recentLocationsService.getRecentsFromServerAndNotify();
      pageService.setIsEntityPage(true);

      $scope.loadLayout = function (layoutBn) {
        entityService.getBasicInfo(layoutBn).then(function (results) {
          $scope.entityBasicInfoModel = results.data;
        });
        layoutService.getLayout(layoutBn).then(function (results) {
          $scope.layoutModel.layout = results.data;
          // this is set to true to indicate to the configuration modal
          // that it should allow the user to pick a datasource when configuring a widget.
          $scope.layoutModel.layout.chooseDataSource = true;
          pageService.setTitle(results.data.name);
          $rootScope.$broadcast('addRecentRecord', results.data);
        }, function (data, status, headers, config) {
          errorHandlingService.handleGenericError(status);
        });
      };

      var initialLayoutBn = urlService.getEntityBn();
      if (initialLayoutBn) {
        $scope.loadLayout(initialLayoutBn);
      }

      $scope.$on('customDashboardUpdated', function (event, newData) {
        $rootScope.$broadcast('addRecentRecord', newData);
        $scope.layoutModel.layout = newData;
      });

      checkForInsightHelp();

    }])

  .controller('ProposedEditsCtrl', ['$scope', '$location', 'bitConstants', 'entityMixedService', 'layoutService', '$rootScope', 'urlService', '$window', 'securityService', '$timeout', 'recentLocationsService', 'styleService',
    function ($scope, $location, bitConstants, entityMixedService, layoutService, $rootScope, urlService, $window, securityService, $timeout, recentLocationsService, styleService) {
      $scope.proposedChangesModel = {};
      $scope.proposedChangesModel.selectedFilter = 'MINE';
      $scope.layoutModel = {
        layout: MixedCollectionNameToLayout['proposededits'],
        selectedSectionBn: urlService.getSectionBnFromUrl() || 'mixed-overview'
      };
      $scope.proposedChangesModel.secureFieldsEditor = securityService.hasRole("ROLE_SUPER_EDITOR");

      $scope.getSectionHeaderName = function (pcdFilter) {
        if (pcdFilter === 'ALL') {
          return 'All';
        }
        if (pcdFilter === 'MINE') {
          return 'All I Can Review';
        }
        if (pcdFilter === 'SECURED') {
          return 'Locked Fields Edits';
        }
      };

      //iterating over this backwards as we are (potentially) removing elements from the array
      $scope.updateCounts = function () {
        entityMixedService.getProposedEditCounts($scope.proposedChangesModel.selectedFilter).then(function (results) {
          $scope.proposedChangesModel.counts = results.data;
          for (var i = $scope.layoutModel.layout.sections.length - 1; i >= 0; i--) {
            var section = $scope.layoutModel.layout.sections[i];
            var result = entityMixedService.extractGroupResultsForEntityType($scope.proposedChangesModel.counts, section.bn);
            if (result) {
              section.count = result.count;
              if (section.count < 1) {
                //remove this from the array
                $scope.layoutModel.layout.sections.splice(i, 1);
              }
            }
          }
        });
      };

      $scope.loadCountsAndSections = function (callback) {
        $scope.layoutModel.layout.sections = [];
        var sectionHeaderName = $scope.getSectionHeaderName($scope.proposedChangesModel.selectedFilter);
        entityMixedService.getProposedEditCounts($scope.proposedChangesModel.selectedFilter).then(function (results) {
          $scope.proposedChangesModel.counts = results.data;
          _.forEach($scope.layoutModel.layout.contentBlocks, function (cBlock) {

            var resultGrp = entityMixedService.extractGroupResultsForEntityType($scope.proposedChangesModel.counts, cBlock.sectionBn);
            // log out cblock and resultgrp variables, check what their results are.

            if (resultGrp && resultGrp.count > 0) {
              //add to data model
              $scope.layoutModel.layout.sections.push({
                bn: cBlock.sectionBn,
                order: cBlock.order,
                roles: cBlock.roles,
                assocCode: cBlock.sectionBn,
                iconClass: styleService.getIconClassForEntityTypeCode(cBlock.sectionBn),
                count: resultGrp.count,
                name: bitConstants.getEntityTypeDisplayNameForTypeCode(cBlock.sectionBn, true),
                headerName: bitConstants.getEntityTypeDisplayNameForTypeCode(cBlock.sectionBn, false)
              });
            }
            //need a way to bind some properties to content-loaders, using cBlock as that mechanism
            cBlock.pcdFilter = $scope.proposedChangesModel.selectedFilter;
            cBlock.sectionHeaderName = sectionHeaderName;
          });
          if (callback) {
            callback();
          }
        });
      };

      $scope.goToSection = function (sectionBn) {
        $rootScope.$broadcast('leftTabSelected', {sectionBn: sectionBn});
        $scope.layoutModel.selectedSectionBn = sectionBn;
      };

      $scope.$on('updateProposedEditCount', function (event) {
        $scope.updateCounts();
      });

      $scope.$on('sectionRenderComplete', function (event) {
        prototype.init();
        $scope.$broadcast("loadAllVisibleSections");
      });

      var incomingFilter = urlService.getQueryParamValue('pcdFilter');
      if (incomingFilter) {
        $scope.proposedChangesModel.selectedFilter = incomingFilter;
      }

      $scope.loadCountsAndSections(function () {
        var sectionToLoad = $location.search().entityType;
        if (sectionToLoad) {
          //page needs to fully load for this to work
          $timeout(function () {
            $scope.goToSection(sectionToLoad);
          }, 1000);
        }
      });
      // make call to load recents data.  notice we are not adding a new recents record for collection pages.
      recentLocationsService.getRecentsFromServerAndNotify();
    }])

  .controller('MyAuditsCtrl', ['$scope', '$location', 'bitConstants', 'entityMixedService', 'layoutService', '$rootScope', '$timeout', 'recentLocationsService', 'styleService', 'urlService',
    function ($scope, $location, bitConstants, entityMixedService, layoutService, $rootScope, $timeout, recentLocationsService, styleService, urlService) {
      $scope.myAuditsModel = {};
      $scope.layoutModel = {
        layout: MixedCollectionNameToLayout['myaudits'],
        selectedSectionBn: urlService.getSectionBnFromUrl() || 'OVERVIEW'
      };

      $scope.loadCountsAndSections = function (callback) {
        $scope.layoutModel.layout.sections = [];
        entityMixedService.getMyAuditCounts().then(function (results) {
          $scope.myAuditsModel.counts = results.data;
          _.forEach($scope.layoutModel.layout.contentBlocks, function (cBlock) {
            var resultGrp = entityMixedService.extractGroupResultsForEntityType($scope.myAuditsModel.counts, cBlock.sectionBn);
            if (resultGrp && resultGrp.count > 0) {
              //add to data model
              $scope.layoutModel.layout.sections.push({
                bn: cBlock.sectionBn,
                order: cBlock.order,
                roles: cBlock.roles,
                assocCode: cBlock.sectionBn,
                iconClass: styleService.getIconClassForEntityTypeCode(cBlock.sectionBn),
                count: resultGrp.count,
                name: bitConstants.getEntityTypeDisplayNameForTypeCode(cBlock.sectionBn, true),
                headerName: bitConstants.getEntityTypeDisplayNameForTypeCode(cBlock.sectionBn, false)
              });
            }
          });
          if (callback) {
            callback();
          }
        });
      };

      $scope.goToSection = function (sectionBn) {
        $rootScope.$broadcast('leftTabSelected', {sectionBn: sectionBn});
        $scope.layoutModel.selectedSectionBn = sectionBn;
      };

      $scope.$on('sectionRenderComplete', function (event) {
        prototype.init();
        $scope.$broadcast("loadAllVisibleSections");
      });

      $scope.loadCountsAndSections(function () {
        var sectionToLoad = $location.search().entityType;
        if (sectionToLoad) {
          //page needs to fully load for this to work
          $timeout(function () {
            $scope.goToSection(sectionToLoad);
          }, 1000);
        }
      });
      // make call to load recents data.  notice we are not adding a new recents record for collection pages.
      recentLocationsService.getRecentsFromServerAndNotify();
    }])


  // A simple wrapper for the ActivityCtrl that lets us avoid layouts.js
  .controller('NewsCtrl', ['$scope', '$timeout', 'recentLocationsService',
    function ($scope, $timeout, recentLocationsService) {
      $scope.contentBlock = {
        sectionBn: 'DAY'
      };

      // Timeout required to allow child component to initialize and listen for event
      $timeout(function () {
        $scope.$broadcast('loadSection', 'DAY');
      });

      recentLocationsService.getRecentsFromServerAndNotify();
    }]);
;

