angular.module('barometerApp.insight')
  .directive('insightHelp', ['bitConstants', 'urlService', 'localStorageService', 'insightTypeService', 'entityService',
    function (bitConstants, urlService, localStorageService, insightTypeService, entityService) {
      return {
        scope: {
          isBrowse: '='
        },
        templateUrl: "/b/js/src/bit.ng/insight/partials/insight-help.html",
        link: function (scope, element, attrs) {
          // Check local storage for 'insightHelp' flags.
          setHelp();

          function setHelp() {
            if (insightHelpInitializedFlag()) {
              scope.showHelp = insightHelpDisplayFlag();
            } else {
              setInsightHelpInitializedFlag();
              setInsightHelpDisplayFlag();
              scope.showHelp = insightHelpDisplayFlag();
            }
          }
          scope.toggleHelp = function () {
            if (insightHelpDisplayFlag()) {
              removeInsightHelpDisplayFlag();
              scope.showHelp = false;
            } else {
              setInsightHelpDisplayFlag();
              scope.showHelp = true;
            }
          };

          // Check local storage for the 'insightHelp.display' flag.
          function insightHelpDisplayFlag() {
            return localStorageService.get(scope.flag) === 'true';
          }

          // Check local storage for the 'insightHelp.initialized' flag.
          function insightHelpInitializedFlag() {
            return localStorageService.get(scope.flagInit) === 'true';
          }

          function removeInsightHelpDisplayFlag() {
            localStorageService.remove(scope.flag);
          }

          function setInsightHelpDisplayFlag() {
            localStorageService.set(scope.flag, "true");
          }

          function setInsightHelpInitializedFlag() {
            localStorageService.set(scope.flagInit, "true");
          }


         const load = function(bn) {
           insightTypeService.getInsightTypes(bn).then(data => {
             const insightType = data.data[0];
             scope.flag = 'insightHelp.' + bn + '.display';
             scope.flagInit = 'insightHelp.' + bn + '.initialized';


             function getInsightHelpObject(insightType) {
               let mainText = '';
               let otherText = [];
               Object.keys(insightType.helpBlurbs).forEach(key => {
                 if (key === 0) mainText = insightType.helpBlurbs[key];
                 else otherText.push(insightType.helpBlurbs[key])
               });
               return {
                 name: insightType.name,
                 imagePath1: insightType.imageOne,
                 imagePath2: insightType.imageTwo,
                 link: insightType.link,
                 title: insightType.helpTitle,
                 type: insightType.entityType,
                 mainText: mainText,
                 otherText: otherText
               };
             }

             function setScope(helpModel) {
               scope.entityTypeDisplayName = helpModel.name;
               scope.imagePath1 = helpModel.imagePath1;
               scope.imagePath2 = helpModel.imagePath2;
               scope.type = helpModel.type;
               scope.helpTitle = helpModel.title;
               scope.link = helpModel.link;
               scope.mainText = helpModel.mainText;
               scope.otherText = helpModel.otherText;
               scope.loading = true;
             }

             let helpModel = getInsightHelpObject(insightType);
             setScope(helpModel);
           });
         }


          const init = function() {
            entityService.getBasicInfo(urlService.getEntityBn()).then(data => {
              // console.log(data.data)
              load(data.data.insightType.bn);
            })
          };
          init();

        }
      }
    }
  ])

  .directive('insightHelpToggle',[ 'bitConstants', 'urlService', 'entityService',
    function(bitConstants, urlService, entityService) {
      return {
        scope: {
          toggleHandler: '&'
        },
        templateUrl: "/b/js/src/bit.ng/insight/partials/insight-help-toggle.html",
        link: function(scope, element, attrs) {
          scope.active = false;

          const init = function() {
            entityService.getBasicInfo(urlService.getEntityBn()).then(data => {
              // console.log(data.data)
              scope.entityTypeName = InsightTypes[data.data.insightType.bn].displayName
            })
          };

          scope.click = function(){
            scope.toggleHandler();
            scope.active = !scope.active;
          };
          init();
        }
      }
    }
  ])
;
