angular
  .module('barometerApp.relationalDiagram')
  .factory('rdConfigModel', rdConfigModel);

rdConfigModel.$inject = [
  '$q',
  '$rootScope',
  'commonWorksheetService'
];

/**
 * Future home of centralized state management.
 */
function rdConfigModel($q,
                       $rootScope,
                       commonWorksheetService) {

  // GLOSSARY
  // worksheetEntity.name
  // worksheetEntity.configuration
  // worksheetEntity.configuration.layout.nodePositions
  // worksheetEntity.configuration.rolledUp.value
  // worksheetEntity.configuration.showLifecycle.value

  // Worksheet config format
  //{
  //  layout:{
  //    mode: 'auto',
  //    name: 'cola',
  //    nodePositions:  {
  //      1234567890: { x: 10, y: 26 }
  //    },
  //  }
  //};

  var theWorksheetBn = null;
  var theWorksheetEntity = null;
  // Used by sub-header controller to cancel edits quickly.
  var configBackup = null;

  //--------------------------------------
  // PRIVATE FUNCTIONS
  //--------------------------------------

  function applyDefaults() {
    // showLifecycle
    if (angular.isUndefined(theWorksheetEntity.configuration.showLifecycle) ||
      angular.isUndefined(theWorksheetEntity.configuration.showLifecycle.value)) {
      //
      theWorksheetEntity.configuration.showLifecycle = {value: true};
    }
    // rolledUp
    if (angular.isUndefined(theWorksheetEntity.configuration.rolledUp) ||
      angular.isUndefined(theWorksheetEntity.configuration.rolledUp.value)) {
      //
      theWorksheetEntity.configuration.rolledUp = {value: true};
    }
  }

  /**
   * Use this as a single funnel to manage change, rather than setting directly.
   */
  function setWorksheetBn(worksheetBn) {
    theWorksheetBn = worksheetBn
  }

  /**
   * Use this as a single funnel to manage change, rather than setting directly.
   */
  function setWorksheetEntity(worksheetEntity) {
    theWorksheetEntity = worksheetEntity
  }

  function hasWorksheetBn() {
    return theWorksheetBn && theWorksheetBn != null && theWorksheetBn !== 'undefined'
  }

  function assertHasWorksheetBn() {
    if (!hasWorksheetBn()) {
      throw 'Requires Worksheet BN.'
    }
  }

  function hasWorksheetEntity() {
    return theWorksheetEntity && theWorksheetEntity != null && theWorksheetEntity !== 'undefined'
  }

  function assertHasWorksheetEntity() {
    if (!hasWorksheetEntity()) {
      throw 'Requires Worksheet Entity.'
    }
  }

  /**
   * If you look into for commonWorksheetService.getWorksheetEntity(), you'll notice that
   * it decides whether to use cached state or call to the server. That cache seems to have
   * contributed to state management problems (BARO-18116).
   */
  function loadWorksheetWithConfig() {
    //
    var deferred = $q.defer();
    //
    assertHasWorksheetBn()
    // true = force read from server; not super-trusting that "cache" thing (BARO-18116)
    commonWorksheetService.getWorksheetEntity(theWorksheetBn, true)
      .then(function (worksheetEntity) {
        setWorksheetEntity(worksheetEntity);
        assertHasWorksheetEntity();
        applyDefaults();
        deferred.resolve(theWorksheetEntity);
      });
    //
    return deferred.promise;
  }

  function fireNonAssociativeWorksheetChange() {
    $rootScope.$broadcast("changeSubmitted", "WORKSHEET");
    // Because we submit no transactionId, the view will refresh w/o waiting.
    $rootScope.$broadcast("changeWithIndexingStarted", {
      sectionBn: "WORKSHEET",
      transactionId: null
    });
  }

  //--------------------------------------
  // PUBLIC FUNCTIONS
  //--------------------------------------

  /**
   * Export my public functions.
   */
  return {
    backUpConfig: backUpConfig,
    getConfig: getConfig,
    getGroups: getGroups,
    getGroupById: getGroupById,
    getGroupIndexById: getGroupIndexById,
    getLayoutMode: getLayoutMode,
    getLayoutName: getLayoutName,
    getWorksheetName: getWorksheetName,
    hasConfig: hasConfig,
    hasNodePositions: hasNodePositions,
    isShowLifecycle: isShowLifecycle,
    isShowRollUp: isShowRollUp,
    loadWorksheetForBn: loadWorksheetForBn,
    restoreConfigBackup: restoreConfigBackup,
    setNodePositions: setNodePositions,
    setShowLifecycle: setShowLifecycle,
    setShowRollUp: setShowRollUp,
    writeWorksheetConfig: writeWorksheetConfig
  };

  function backUpConfig() {
    configBackup = angular.copy(getConfig());
  }

  function getConfig() {
    assertHasWorksheetEntity();
    return theWorksheetEntity.configuration;
  }

  function getGroups() {
    assertHasWorksheetEntity();
    if (!theWorksheetEntity.configuration.groups) {
      theWorksheetEntity.configuration.groups = [];
    }
    return theWorksheetEntity.configuration.groups;
  }

  function getGroupById(id) {
    var myGroups = getGroups();
    return _.find(myGroups, function (group) {
      return group.data.id === id
    })
  }

  function getGroupIndexById(id) {
    var myGroups = getGroups();
    return _.findIndex(myGroups, function (group) {
      return group.data.id === id
    })
  }

  function getLayoutMode() {
    assertHasWorksheetEntity();
    return theWorksheetEntity.configuration.layout.mode;
  }

  function getLayoutName() {
    assertHasWorksheetEntity();
    return theWorksheetEntity.configuration.layout.name;
  }

  function getNodePositions() {
    assertHasWorksheetEntity();
    return theWorksheetEntity.configuration.layout.nodePositions;
  }

  function getWorksheetName() {
    assertHasWorksheetEntity();
    return theWorksheetEntity.name;
  }

  function hasConfig() {
    return !angular.isUndefined(theWorksheetEntity.configuration) && theWorksheetEntity.configuration !== null;
  }

  /**
   * When node positions are not captured and persisted, you can sometimes skip some of the "hard work".
   */
  function hasNodePositions() {
    var myNodePositions = getNodePositions();
    return myNodePositions && !_.isEmpty(myNodePositions);
  }

  function isShowLifecycle() {
    assertHasWorksheetEntity();
    // console.log("theWorksheetEntity.configuration.showLifecycle.value=" + theWorksheetEntity.configuration.showLifecycle.value);
    return theWorksheetEntity.configuration.showLifecycle.value;
  }

  function isShowRollUp() {
    assertHasWorksheetEntity();
    // console.log("theWorksheetEntity.configuration.rolledUp.value=" + theWorksheetEntity.configuration.rolledUp.value);
    return theWorksheetEntity.configuration.rolledUp.value;
  }

  function loadWorksheetForBn(worksheetBn) {
    setWorksheetBn(worksheetBn);
    assertHasWorksheetBn();
    return loadWorksheetWithConfig();
  }

  function restoreConfigBackup() {
    assertHasWorksheetEntity();
    return theWorksheetEntity.configuration = _.cloneDeep(configBackup);
  }

  function setNodePositions(aNodePositions) {
    assertHasWorksheetEntity();
    theWorksheetEntity.configuration.layout.nodePositions = aNodePositions;
  }

  function setShowLifecycle(aValue) {
    assertHasWorksheetEntity();
    theWorksheetEntity.configuration.showLifecycle = aValue;
  }

  function setShowRollUp(aValue) {
    assertHasWorksheetEntity();
    theWorksheetEntity.configuration.rolledUp = aValue;
  }

  /**
   * @param isFireEvent Should we fire fireNonAssociativeWorksheetChange?
   */
  function writeWorksheetConfig(isFireEvent) {
    //
    return commonWorksheetService.updateWorksheetConfig(theWorksheetBn, getConfig())
      .then(function (data) {
        if (isFireEvent) fireNonAssociativeWorksheetChange()
      });
  }
}
