angular.module('barometerApp.content')
  .directive('multiView', ['$rootScope', 'layoutService', 'tableService', 'urlService', 'bitConstants', 'entityService', 'styleService', '$timeout', 'redux',
    function ($rootScope, layoutService, tableService, urlService, bitConstants, entityService, styleService, $timeout, redux) {
      return {
        scope: {
          sectionBn: "=",
          assocCode: "=",
          defaultViewId: "@",
          cBlock: '=',
          viewIds: "@"
        },
        templateUrl: function (elem, attrs) {
          if (!attrs.templateUrl || attrs.templateUrl.length === 0) {
            console.error('Error in multi-view: template-url missing')
          }
          return attrs.templateUrl;
        },
        link: function (scope, element, attrs) {
          const viewIds = attrs.viewIds.split(',').map(v => v.trim());
          const urlViewId = urlService.getViewIdFromUrl();
          const defaultViewId = (!!urlViewId && viewIds.includes(urlViewId))
            ? urlViewId
            : attrs.defaultViewId;
          const { store, actions, reducers } = redux;
          store.manager.add('sectionViewSelector', reducers.sectionViewSelector);

          const scopeId = scope.$id;

          scope.entityService = entityService;
          scope.viewIdToReloadStatus = {};
          scope.multiViewModel = {
            sectionIconClass: styleService.getIconClassForEntityTypeCode(scope.assocCode),// EntityUtils.icons[EntityBnCodeToIconClass[utilService.getBnCode(scope.assocCode)]],
            loaded: false,
            editing: false,
            lastNonEditView: attrs.defaultViewId,
            idInView: null,
            viewIds: viewIds,
            allMode: urlService.getQueryKey() === undefined || urlService.getQueryKey() == null,
            editable: entityService.canEdit,
            canDelete: entityService.canDelete
          };
          scope.entityTypeCodeOfCurrentPage = urlService.getEntityTypeCodeOfCurrentPage();

          const includes = (source, val) => source.toLowerCase().indexOf(val.toLowerCase()) >= 0;
          const getLocalizedNameFromId = (id) => {
            if (includes(id, 'box')) {
              return 'Boxes';
            }
            if (includes(id, 'tree')) {
              return 'Tree';
            }
            if (includes(id, 'table')) {
              return 'Table';
            }
            if (includes(id, 'summary')) {
              return 'Summary';
            }
            if (includes(id, 'gantt')) {
              return 'Impact';
            }
            if (includes(id, 'direct')) {
              return 'Directly Connected Systems';
            }
            if (includes(id, 'diagram')) {
              return 'Diagram';
            }
            if (includes(id, 'view')) {
              return 'Viewer';
            }
            if (includes(id, 'chart')) {
              return 'Organization Chart';
            }

            return id;
          };
          const getIconClassFromId = (id) => {
            if (includes(id, 'box')) {
              return 'icon-boxes';
            }
            if (includes(id, 'tree')) {
              return 'icon-tree';
            }
            if (includes(id, 'table')) {
              return 'icon-table';
            }
            if (includes(id, 'summary')) {
              return 'icon-bars-alt';
            }
            if (includes(id, 'gantt')) {
              return 'icon-demand';
            }
            if (includes(id, 'direct')) {
              return 'icon-connect-alt';
            }
            if (includes(id, 'diagram')) {
              return 'icon-connect';
            }
            if (includes(id, 'view')) {
              return 'icon-boxes';
            }
            if (includes(id, 'chart')) {
              return 'icon-tree';
            }

            return '';
          };

          const options = viewIds.filter(id => !includes(id, 'edit'))
            .map(id => ({
              iconClass: getIconClassFromId(id),
              id: id,
              type: getLocalizedNameFromId(id),
            }));

          // Set the view options in the redux store
          store.dispatch(
            actions.setSectionViewSelectorOptions(scopeId, options)
          );

          // Set the active view id
          store.dispatch(
            actions.setSectionViewSelectorActiveViewId(scopeId, defaultViewId)
          );

          // Subscribe to changes to the redux store
          const unsubscribe = store.subscribe(() => {
            // The timeout's purpose is only to trigger a (safe) digest
            $timeout(() => {
              const activeViewId = store.getState().sectionViewSelector.sectionViewSelector[scopeId].activeViewId;
              if (activeViewId !== null && activeViewId !== undefined) {
                scope.multiViewModel.idInView = activeViewId;
              }
            });
          });

          // Cleanup
          scope.$on('$destroy', unsubscribe);

          const defaultView = $(element).find('#' + defaultViewId);
          if (defaultView.length === 0) {
            console.error('multiView > could not find default view', defaultViewId);
          } else {
            defaultView.scope().$broadcast('load');
            // need this to track if a view has been reloaded after a save
            $.each(scope.multiViewModel.viewIds, function (index) {
              scope.viewIdToReloadStatus[$.trim(this)] = false;
            });
          }

          scope.closeEditView = function (callLoadSection) {
            // set all views to reload next time they are requested
            $.each(scope.multiViewModel.viewIds, function (index) {
              scope.viewIdToReloadStatus[$.trim(this)] = true;
            });
            scope.multiViewModel.idInView = scope.multiViewModel.lastNonEditView;
            scope.multiViewModel.editing = false;
            if (callLoadSection) {
              $rootScope.$broadcast('loadSection', scope.sectionBn, true);
            }
          };
          scope.toggleEdit = function (editId) {
            // reload edit panel whenever they are displayed to make sure data is current
            scope.viewIdToReloadStatus[editId] = true;
            if (scope.multiViewModel.idInView && scope.multiViewModel.viewIds.includes(scope.multiViewModel.idInView)) {
              scope.multiViewModel.lastNonEditView = scope.multiViewModel.idInView;
            } else {
              scope.multiViewModel.lastNonEditView = defaultViewId;
            }
            scope.multiViewModel.idInView = editId;
            scope.multiViewModel.editing = true;
          };
          scope.toggleView = function (viewId) {
            scope.multiViewModel.lastNonEditView = scope.multiViewModel.idInView;
            scope.multiViewModel.idInView = viewId;
            scope.multiViewModel.editing = false;
          };
          // this is called from wicket land
          scope.$on('cancelEdit', function (event, payload) {
            $timeout(function () {
              scope.multiViewModel.idInView = scope.defaultViewId;
              scope.multiViewModel.editing = false;
              event.stopPropagation();
            });
          });
          // this is called from wicket land
          scope.$on('editBasicInfoClosed', function (event) {
            $timeout(scope.closeEditView);
          });
          scope.$on('loadSection', function (event, sectionBn, forceReload) {
            if (!scope.sectionBn) return;
            if (scope.sectionBn == sectionBn) {
              if (scope.multiViewModel.loaded == false || forceReload) {

                // load the view indicated in the url if it exists
                var viewId = urlService.getViewIdFromUrl();
                var viewSet = false;
                if (viewId) {
                  _.each(scope.multiViewModel.viewIds, function (aViewId) {
                    if (viewId == $.trim(aViewId)) {
                      scope.multiViewModel.idInView = viewId;
                      viewSet = true;
                    }
                  });
                }
                if (!viewSet) {
                  scope.multiViewModel.idInView = defaultViewId;
                }
                $timeout(function () {
                  scope.multiViewModel.loaded = true
                });
              }
              if (forceReload) {
                // broadcast only within my scope, otherwise everything on page will try to reload
                scope.$broadcast('reload', scope.multiViewModel.idInView);

                // set all views to reload next time they are requested
                $.each(scope.multiViewModel.viewIds, function (index) {
                  scope.viewIdToReloadStatus[$.trim(this)] = true;
                });
              }
            }
          });
          scope.$on('contentRequestStarted', function(event, data){
            $timeout(function () {
              scope.multiViewModel.loaded = true
            });
          });
          scope.$on("filterChanged", function (event, data) {
            if (data.tableId === scope.sectionBn) {
              // set all views not "in view" to reload the next time they become visible
              _.each(scope.multiViewModel.viewIds, function (viewId) {
                if (viewId != scope.multiViewModel.idInView) {
                  scope.viewIdToReloadStatus[$.trim(viewId)] = true;
                }
              });
              // reload the visible view
              var idInView = scope.multiViewModel.idInView;
              var elementInView = $(element).find('#' + idInView);
              if (elementInView.scope()) {
                $(element).find('#' + idInView).scope().$broadcast('reload', idInView);
                scope.viewIdToReloadStatus[idInView] = false;
              }
            }
          });
          scope.idInViewWatcher = function (newVal, oldVal) {
            var newView = $(element).find('#' + newVal);
            var oldView = $(element).find('#' + oldVal);

            // before toggling, if the view to show is a content-loader,
            // set it's min-height to the height of the old view to avoid
            // layout contraction while the content loads
            if (newView.scope().hasOwnProperty('contentLoaderModel')) {
              newView.css({
                minHeight: oldView.outerHeight() + 'px'
              })
            }

            if (scope.viewIdToReloadStatus[newVal]) {
              newView.scope().$broadcast('reload', newVal);
              scope.viewIdToReloadStatus[newVal] = false;
            } else {
              if (newView.scope()) {
                newView.scope().$broadcast('load', newVal);
              }
            }

            // hide all the views, then show the correct one.
            $.each(scope.multiViewModel.viewIds, function (index) {
              $(element).find('#' + $.trim(this)).hide();
            });
            newView.show();

            // Broadcast an event whenever new section content is loaded
            // Used to updated the current url with section and view information
            $rootScope.$broadcast('sectionContentLoaded', {
              sectionBn: scope.sectionBn,
              viewId: newVal,
              isEditing: scope.multiViewModel.editing
            });
          };
          scope.$watch('multiViewModel.idInView', function (newVal, oldVal, $scope) {
            if (!newVal) return;

            if (newVal != oldVal) {
              $scope.idInViewWatcher(newVal, oldVal);
            }
          });
          scope.$watch('entityService.getCanEdit()', function (newVal, oldVal) {
            if (!newVal) return;
            scope.multiViewModel.editable = newVal;
          });
          scope.$watch('entityService.getCanDelete()', function (newVal, oldVal) {
            if (!newVal) return;
            scope.multiViewModel.canDelete = newVal;
          });
        }
      }
    }])
  .directive('pieChart', ['$window', '$location', function ($window, $location) {
    return {
      scope: {
        chartData: '=',
        contentBlockBn: '='
      },
      templateUrl: '/b/js/src/bit.ng/content/partials/pie-chart.html',
      link: function (scope, element, attrs) {
        var $element = $(element);
        var $chartContainer = $element.find('.chart-container');
        var $chartLegend = $element.find('.chart-legend');
        var isModal = $element.closest('.modal-body').length > 0;
        var w = angular.element($window);

        scope.toggleSlice = function (data, index) {
          data.isHovered = !data.isHovered;

          $element.find('#legendText' + index).toggleClass('selected', data.isHovered);

          if (data.isHovered) {
            highlightByID(index, arc);
          } else {
            removeHighlightByID(index);
          }
        };

        var arc, margin, height, width, radius, chartWidth, chartHeight, colors, pie;

        var createChart = function () {
          if (!scope.chartData) {
            return;
          }
          var inSidebar = $element.closest('.section-inspector');

          margin = {top: 20, right: 20, bottom: 20, left: 20};
          width = $element.width() - margin.left - margin.right;
          height = width;
          chartWidth = width / (inSidebar ? 1 : 2);
          chartHeight = height / (inSidebar ? 1 : 2);

          radius = Math.min(chartWidth, chartHeight) / 2;

          colors = ["#295E9F", "#89B1E2", "#A9D4EA", "#DDEEF6", "#ffffff"];

          arc = d3.svg.arc()
            .outerRadius(radius - 10)
            .innerRadius(0);

          pie = d3.layout.pie()
            .sort(null)
            .value(function (d) {
              return d.count;
            });

          $chartContainer.empty();

          var svg = d3.select($chartContainer[0]).append("svg")
            .attr("width", chartWidth)
            .attr("height", chartHeight)
            .append("g")
            .attr("id", "pieChartGroup")
            .attr("transform", "translate(" + radius + "," + radius + ")");

          var g = svg.selectAll(".arc")
            .data(pie(scope.chartData))
            .enter().append("g")
            .attr("class", "arc")
            .attr("id", function (d, i) {
              return "group" + i;
            });


          g.append("path")
            .attr({
              d: arc,
              id: function (d, i) {
                return "slice" + i;
              }
            })
            .style("fill", function (d, i) {
              return colors[i % colors.length];
            })
            .on("mouseover", function (d, i) {
              highlightByID(i, arc);
            }).on("mouseout", function (d, i) {
            removeHighlightByID(i);
          })
            .on("click", function (d) {
              if (d.data.entityViewUrl) {
                $window.location = d.data.entityViewUrl;
              }
            });
        };

        function highlightByID(i, arc) {

          var groupElem = d3.select($chartContainer[0]).selectAll('#group' + i);
          var sliceElem = d3.select($chartContainer[0]).selectAll('#slice' + i);
          var legendElem = d3.select($chartLegend[0]).selectAll('#legendText' + i);

          groupElem.on("mouseover", null)
            .transition().duration(200)
            .attr("transform", function (d) {
              var center = arc.centroid(d);
              return 'translate(' + center[0] / 3 + ',' + center[1] / 3 + ')';
            })
            .each("end", function () {
              d3.select(this).on("mouseover", function (d, i) {
                return highlightByID(i);
              });
            });

          sliceElem.classed({"selected": true});
          legendElem.classed({"selected": true});
        }

        function removeHighlightByID(i) {

          var groupElem = d3.select($chartContainer[0]).selectAll('#group' + i);
          var sliceElem = d3.select($chartContainer[0]).selectAll('#slice' + i);
          var legendElem = d3.select($chartLegend[0]).selectAll('#legendText' + i);

          sliceElem.classed("selected", false);
          legendElem.classed("selected", false);

          groupElem.on("mouseover", null)
            .transition().duration(200)
            .attr("transform", function (d) {
              return "translate(0,0)";
            })
            .each("end", function () {
              d3.select(this).on("mouseover", function (d, i) {
                return highlightByID(i);
              });
            });

        }

        scope.getWindowDimensions = function () {
          return {
            'h': w.height(),
            'w': w.width()
          };
        };

        scope.$watch('chartData', function (newVal, oldVal, scope) {
          if (!newVal || angular.equals(newVal, oldVal)) {
            return;
          } else {
            createChart();
          }
        });

        scope.$watch(scope.getWindowDimensions, function (newVal, oldVal) {
          if (!newVal || angular.equals(newVal, oldVal)) {
            return;
          } else {
            createChart();
          }
        }, true);

        scope.$on('contentBlock:resized', function (ev, data) {
          if (isModal && data.bn === scope.contentBlockBn) {
            createChart();
          }
        });

        w.bind('resize', function () {
          scope.$apply();
        });
      }
    }
  }])
  .directive('barChart', ['$window', function ($window) {
    return {
      scope: {
        chartData: '=',
        contentBlockBn: '='
      },
      templateUrl: '/b/js/src/bit.ng/content/partials/bar-chart.html',
      link: function (scope, element, attrs) {
        var $element = $(element),
          $chartContainer = $element.find(".chart-container"),
          $chartLegend = $element.find(".chart-legend");

        var isModal = $element.closest('.modal-body').length > 0;
        var w = angular.element($window);

        scope.getWindowDimensions = function () {
          return {
            'h': w.height(),
            'w': w.width()
          };
        };

        scope.$on('contentBlock:resized', function (ev, data) {
          if (isModal && data.bn === scope.contentBlockBn) {
            createChart();
          }
        });

        scope.$watch(scope.getWindowDimensions, function (newVal, oldVal) {
          if (!newVal || angular.equals(newVal, oldVal)) {
            return;
          } else {
            createChart();
          }
        }, true);

        w.bind('resize', function () {
          scope.$apply();
        });


        scope.toggleSlice = function (data, index) {
          data.isHovered = !data.isHovered;

          $element
            .find('#legendText' + index).toggleClass('selected', data.isHovered);

          // toggleClass doesn't appear to be working
          var barElem = $element.find('#bar' + index);

          barElem.attr('class', data.isHovered ? 'bar barHighlight' : 'bar');
        };

        var createChart = function () {
          if (!scope.chartData) {
            return;
          }
          var margin = {top: 20, right: 40, bottom: 30, left: 40},
            width = $element.width() - margin.left - margin.right,
            height = width * .667;
          // height = $chartContainer.height() > 0 ? $chartContainer.height() - margin.top - margin.bottom : 300;

          if ($element.closest('.sl-fourth').length > 0) {
            margin = {top: 10, right: 20, bottom: 15, left: 30};
          } else if ($element.closest('.sl-third').length > 0) {
            margin = {top: 15, right: 30, bottom: 22, left: 30};
          }

          var x = d3.scale.ordinal()
            .rangeRoundBands([0, width], .1);

          var y = d3.scale.linear()
            .range([height, 0]);

          var xAxis = d3.svg.axis()
            .scale(x)
            .orient("bottom");

          var yAxis = d3.svg.axis()
            .scale(y)
            .ticks(3)
            .orient("left");
          $chartContainer.empty();

          var svg = d3.select($chartContainer[0]).append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom - 5)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

          x.domain(scope.chartData.map(function (d, i) {
            return d.name + i;
          }));
          y.domain([0, d3.max(scope.chartData, function (d) {
            return d.count;
          })]);

          svg.append("g")
            .attr("class", "y axis")
            .call(yAxis);

          svg.selectAll(".bar")
            .data(scope.chartData)
            .enter().append("rect")
            .attr({
              "class": "bar",
              "id": function (d, i) {
                return "bar" + i;
              },
              x: function (d, i) {
                return x(d.name + i);
              },
              y: function (d) {
                return y(d.count);
              },
              height: function (d) {
                return height - y(d.count);
              },
              width: x.rangeBand()
            })
            .on("mouseover", function (d, i) {
              var barElem = d3.select($chartContainer[0]).selectAll('#bar' + i);
              var legendElem = d3.select($chartLegend[0]).selectAll('#legendText' + i);
              barElem.classed("barHighlight", true);
              legendElem.classed("selected", true);
            })
            .on("mouseout", function (d, i) {
              var barElem = d3.select($chartContainer[0]).selectAll('#bar' + i);
              var legendElem = d3.select($chartLegend[0]).selectAll('#legendText' + i);
              barElem.classed("barHighlight", false);
              legendElem.classed("selected", false);
            })
            .on("click", function (d) {
              if (d.entityViewUrl) {
                $window.location = d.entityViewUrl;
              }
            });
        };

        scope.$watch('chartData', function (newVal, oldVal, scope) {
          if (!newVal || angular.equals(newVal, oldVal)) {
            return;
          } else {
            createChart();
          }
        })
      }
    };
  }])
  .directive('orderedListStart', [
    function () {
      return {
        link: function (scope, element, attrs) {
          scope.$watch('summaryViewModel.pagination.start', function (newVal, oldVal) {
            if (!newVal) return;
            if (newVal != oldVal) {
              element.attr('start', newVal);
            }
          });
        }
      }
    }])
  .directive('subSectionTitle', ['pageService',
    function (pageService) {
      return {
        templateUrl: '/b/js/src/bit.ng/content/partials/sub-section-title.html',
        link: function (scope, element, attrs) {
          scope.title = pageService.getTitle();
          scope.$on('basicInfoLoaded', function (event, basicInfo) {
            scope.title = basicInfo.displayableName;
          });
        }
      }
    }])
;
