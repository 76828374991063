// A bean is any simple persistent entity w/o associations.
angular.module('barometerApp.bean')
  .controller('BeanCtrl', ['$scope', 'beanService', 'bitConstants', '$location', '$window', '$modal', 'urlService', 'alertService', 'pageService', 'tenantEndpointService', 'serviceNowService', 'tenantService',
    function ($scope, beanService, bitConstants, $location, $window, $modal, urlService, alertService, pageService, tenantEndpointService, serviceNowService, tenantService) {

    // TODO much of this entire controller is specific to tenant endpoints. Should it be renamed, or refactored?

      pageService.setIsEntityPage(true);
      $scope.createMode = true;
      $scope.submitAttempted = false;
      $scope.beanMeta = {
        entityTypeCode: urlService.getEntityTypeCodeOfCurrentPage(),
        entityBnCode: bitConstants.getEntityTypeForEntityTypeCode(urlService.getEntityTypeCodeOfCurrentPage()).bnCode,
        entityBn: urlService.getEntityBn()
      };
      $scope.beanMeta.loaded = false;
      $scope.beanModel = {};
      $scope.bitPublicCa = '';
      $scope.bitPublicCert = '';
      $scope.webhookContentType = '';
      $scope.webhookEventType = '';
      $scope.optionListModel = {};
      $scope.snModel = {};

      // TODO Just for TenantEndpoint. Move.
      $scope.tenantEndpointTypeList = [
        {value: 'SNA', displayName: 'ServiceNow Adapter'},
        {value: 'WBH', displayName: 'Webhook'}
      ];

      var entityTypeList =
          [{value: 'ACT', displayName: 'Actor'},
          {value: 'CAP', displayName: 'Capability'},
          {value: 'COM', displayName: 'Company'},
          {value: 'CON', displayName: 'Connection'},
          {value: 'DAT', displayName: 'Data'},
          {value: 'DEM', displayName: 'Demand'},
          {value: 'PHY', displayName: 'Deployment'},
          {value: 'MKT', displayName: 'Market'},
          {value: 'ORG', displayName: 'Organization'},
          {value: 'PER', displayName: 'Person'},
          {value: 'PRD', displayName: 'Product'},
          {value: 'SKI', displayName: 'Skill'},
          {value: 'STA', displayName: 'Standard'},
          {value: 'STR', displayName: 'Strategy'},
          {value: 'SYS', displayName: 'System'},
          {value: 'TEC', displayName: 'Technology'}];

      var tenantAuth = tenantService.getCurrentTenantsAuthorizedCatalogs().map(auth => auth.entityTypeCode);
      var allowedTypeList = entityTypeList.filter(type => tenantAuth.includes(type.value));
      //Tasks are always allowed
      allowedTypeList.push(
        {value: 'TSK', displayName: 'Task'});

      $scope.serviceNowEntityTypeChoices = serviceNowService.loadEntityTypeChoices(allowedTypeList);

      // TODO Just for TenantEndpoint. Move.
      $scope.webhookEventTypeList = allowedTypeList;

      var loadBarometerPublicCertFromServer = function() {
        var promiseCa = tenantEndpointService.getBarometerPublicFile('ca');
        var promiseCert = tenantEndpointService.getBarometerPublicFile('cert');
        Promise.all([promiseCa, promiseCert]).then((results) => {
          console.log('All Promise Resolved.');
          console.log(results);

          if (results && results.length === 2) {
            $scope.bitPublicCa = results[0].data;
            $scope.bitPublicCert = results[1].data;

            $scope.populateBitPublicCerts($scope.beanModel.authType);
          } else {
            alertService.addDeferredAlert({type: 'error', msg: "Cannot retrieve barometerIT's Public Certificate from the server."});
            $scope.redirectToBrowse();
          }

        });
      };

      var initializeWebhookAdapterModels = function(configStr) {
        if (configStr !== undefined && configStr !== null) {
          var configJson = JSON.parse(configStr);

          $scope.webhookContentType = configJson.contentType;
          $scope.webhookEventType = configJson.eventType;
        }
      };

      var createWebhookAdapterConfiguration = function() {
        var configJson =
            {contentType : $scope.webhookContentType ,
            eventType : $scope.webhookEventType};

        return JSON.stringify(configJson);
      };

      // TODO Just for TenantEndpoint. Move.
      $scope.webhookContentTypeList = [
        {value: 'application/json', displayName: 'application/json'}
      ];

      // TODO Just for TenantEndpoint. Move.
      $scope.authTypeList = [
        {value: 'None', displayName: 'None'},
        {value: 'Basic', displayName: 'Basic'},
        {value: 'SSL', displayName: 'SSL'}
      ];

      // Clear the property from beanModel
      $scope.clear = function(key) {
        delete $scope.beanModel[key];
      };

      $scope.populateBitPublicCerts = function (authType) {
        if (authType === 'SSL') {

          // If the current beanModel has a credential2 value,
          // make sure it is the same value as the one from the server.
          // This is the CA's value.
          if ($scope.beanModel.credential2) {
            if ($scope.bitPublicCa !== $scope.beanModel.credential2) {
              $scope.beanModel.credential2 = $scope.bitPublicCa;
              alertService.addWarningAlert("The barometerIT public certificate has been updated. The 'barometerIT's Public Certificate' field contains the new certificate. Please update your system.");
            }
          } else if (!$scope.beanModel.credential2 && $scope.beanModel.authType === 'SSL') {
            $scope.beanModel.credential2 = $scope.bitPublicCa;
          }

        }
      };

      $scope.getCurrentBn = function () {
        return $scope.beanMeta.entityBn;
      };

      $scope.cancel = function () {
        $scope.redirectToBrowse();
      };

      $scope.loadBean = function (beanBn) {

        $scope.serviceNowEntityTypeSelectModel = serviceNowService.loadEntityTypeSelectModel($scope.serviceNowEntityTypeChoices);
        serviceNowService.lookupRelationshipTypeSelectModel().then(function (results) {
          $scope.serviceNowRelationshipTypeSelectModel = serviceNowService.loadRelationshipTypeSelectModelFromServiceResults($scope.serviceNowEntityTypeChoices, results);
          beanService.getBean(beanBn).then(function (results) {
            if (results.data) {
              $scope.beanModel = results.data;
              if($scope.beanModel.tenantEndpointType === 'SNA') {
                $scope.snModel = serviceNowService.initializeSNAdapterModels($scope.beanModel.configuration);
              } else if($scope.beanModel.tenantEndpointType === 'WBH') {
                initializeWebhookAdapterModels($scope.beanModel.configuration);
                loadBarometerPublicCertFromServer();
              }
            } else {
              alertService.addDeferredAlert({type: 'error', msg: String.format("Bean not found for BN: {0}", beanBn)});
              $scope.redirectToBrowse();
            }
          });
        });
      };

      $scope.saveBean = function (isInvalid) {
        $scope.submitAttempted = true;
        if (!isInvalid) {
          $scope.setDefaultAttributes();
          if($scope.beanModel.tenantEndpointType === 'SNA') {
            $scope.beanModel.configuration = serviceNowService.createSNAdapterConfiguration($scope.snModel);
          } else if($scope.beanModel.tenantEndpointType === 'WBH') {
            $scope.beanModel.configuration = createWebhookAdapterConfiguration();
          }
          if ($scope.beanModel.bn) {
            $scope.updateBean();
          } else {
            $scope.createBean();
          }
        }
      };

      $scope.setDefaultAttributes = function() {

      };

      $scope.createBean = function () {
        var entityDiscriminator = $scope.beanMeta.entityTypeCode;
        var response = beanService.createBean($scope.beanModel, entityDiscriminator);
        response.then(function (results) {
          if (results.data.bn) {
            $scope.beanModel = results.data;
            alertService.addDeferredAlert({
              type: "success",
              msg: String.format("Created Endpoint: '{0}'", $scope.beanModel.name)
            });
            $scope.redirectToBrowse();
          }
        });
      };

      $scope.updateBean = function () {
        var entityDiscriminator = $scope.beanMeta.entityTypeCode;
        var response = beanService.updateBean($scope.beanModel, entityDiscriminator, function (response) {
          //error callback, reload field
          $scope.loadBean($scope.beanModel.bn);
        });
        response.then(function (results) {
          $scope.beanModel = results.data;
          alertService.addDeferredAlert({type: "success", msg: String.format("Updated Endpoint: '{0}'", $scope.beanModel.name)});
          $scope.redirectToBrowse();
        });
      };

      $scope.deleteBean = function () {
        //if ($scope.beanMeta.entityBn) {
        if ($scope.beanModel.bn) {
          var promise = beanService.deleteBean($scope.beanModel.bn);
          promise.then(function (results) {
            alertService.addDeferredAlert({
              type: 'success',
              msg: String.format("Deleted: '{0}'", $scope.beanModel.name)
            });
            $scope.redirectToBrowse();
          });
        }
      };

      $scope.showServiceNowFieldModal = function() {
        serviceNowService.generateServiceNowFieldModalBody($scope.snModel).then(function (results) {
          $scope.body = results;
          $modal.open({
            templateUrl : '/b/js/src/bit.ng/tenant-endpoint/partials/tenant-endpoint-servicenow-adapter-fields-modal.html',
            backdrop: 'static',
            keyboard: true,
            scope : $scope
          });
        });
      };

      $scope.updateWebhookEventType = function(newWebhookEventType) {
        $scope.webhookEventType = newWebhookEventType;
      };

      $scope.updateWebhookContentType = function(webhookContentType) {
        $scope.webhookContentType = webhookContentType;
      };

      $scope.redirectToBrowse = function () {
        $window.location.href = urlService.getBaseUrl() + 'tenantendpoints';
      };

      var beanBn = urlService.getEntityBn();
      //if no bn, we are in create mode
      if (beanBn) {
        $scope.createMode = false;
        $scope.loadBean(beanBn);
      } else {
        // createMode remains true.
        loadBarometerPublicCertFromServer();

        //service-now
        $scope.serviceNowEntityTypeSelectModel = serviceNowService.loadEntityTypeSelectModel($scope.serviceNowEntityTypeChoices);
        serviceNowService.lookupRelationshipTypeSelectModel().then(function (results) {
          $scope.serviceNowRelationshipTypeSelectModel = serviceNowService.loadRelationshipTypeSelectModelFromServiceResults($scope.serviceNowEntityTypeChoices, results);
        });
      }

    }])
  .controller('BeanBrowseCtrl', ['$scope', '$window', 'urlService',
    function ($scope, $window, urlService) {

      $scope.createBean = function ($path) {
        $window.location.href = urlService.getBaseUrl() + $path + '/';
      }

    }]);
