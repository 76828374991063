angular.module('barometerApp.domainFilter')
    /**
     * This is a state-management service, not a data service (i.e. it doesn't read data from a server).
     *
     * Why do we have it? So that we can auto-magically inject our state into these other directives:
     *
     * contentLoader (content-loader)
     * editableTable (editable-table)
     * editableFilter (editable-filter)
     */
    .service('domainFilterService', ['$rootScope', function ($rootScope) {
    var domainFilters = {};
    return {

      /**
       * Set the domain filter object. (Initially populated from domainFilterSpec in table-directives.js.)
       */
      setDomainFilter: function (tableId, domainFilter) {
        domainFilters[tableId] = domainFilter;
        $rootScope.$broadcast('domainFilterSet', tableId);
      },

      /**
       * Set the currently selected domainBns.
       */
      setSelected: function (tableId, domainBns) {
        domainFilters[tableId].selected = domainBns;
        $rootScope.$broadcast('domainFilterChanged', tableId);
      },
      
      getDomainFilter: function (tableId) {
        if (!domainFilters[tableId]) {
          return null;
        } else {
          return domainFilters[tableId];
        }
      }
    }

  }]);
