angular
  .module('barometerApp.relationalDiagram')
  .controller('RelationalDiagramAssociatedEntityController', RelationalDiagramAssociatedEntityController);

RelationalDiagramAssociatedEntityController.inject = [
  '$modalInstance',
  '$scope',
  'config'
];

/**
 * This is a modal popped from relational-diagram-sidebar-controller.
 */
function RelationalDiagramAssociatedEntityController ($modalInstance,
                                                      $scope,
                                                      config) {

  $scope.tempConfig = _.cloneDeep(config);
  // Remove empty entity types
  Object.entries($scope.tempConfig).forEach(([entityTypeCode, entities]) => {
    if (entities.length === 0) delete $scope.tempConfig[entityTypeCode];
  });
  $scope.addedBns = [];
  $scope.checkboxValues = {};
  $scope.expanded = {};
  $scope.individualBns = {};

  //--------------------------------------
  // PRIVATE FUNCTIONS
  //--------------------------------------

  /**
   *
   */
  function addBnValuesToList (values) {
    values.forEach(val => {
      $scope.individualBns[val.bn] = true;
      if ($scope.addedBns.indexOf(val.bn) < 0) {
        $scope.addedBns.push(val.bn);
      }
    });
    return $scope.addedBns;
  }

  /**
   *
   */
  function removeBnValuesFromList (values) {
    values.forEach(val => {
      $scope.individualBns[val.bn] = false;
      const index = $scope.addedBns.indexOf(val.bn);
      if (index > -1) {
        $scope.addedBns.splice(index, 1);
      }
    });
    return $scope.addedBns;
  }

  //--------------------------------------
  // PUBLIC FUNCTIONS
  //--------------------------------------

  /**
   *
   */
  $scope.cancel = function () {
    $modalInstance.dismiss('cancel');
  };

  /**
   *
   */
  $scope.changeValuesInList = function (values, checkboxVal) {
    //The change event adds $$hashKey to the values, .toJson removes it.
    values = angular.toJson(values);
    values = JSON.parse(values);
    if (checkboxVal) {
      addBnValuesToList(values);
    } else {
      removeBnValuesFromList(values);
    }
  };

  /**
   *
   */
  $scope.expand = function (value) {
    $scope.expanded[value] = !$scope.expanded[value];
  };

  /**
   *
   */
  $scope.getEntityDisplayName = function (bnCode, length) {
    if (length > 1) {
      return EntityTypes[EntityCodes[bnCode]].displayNamePlural;
    } else {
      return EntityTypes[EntityCodes[bnCode]].displayName;
    }
  };

  /**
   *
   */
  $scope.individualCheckboxChanged = function (value, checkboxVal) {
    value = angular.toJson(value);
    value = JSON.parse(value);
    if (checkboxVal) {
      if ($scope.addedBns.indexOf(value.bn) < 0) {
        $scope.addedBns.push(value.bn);
      }
    } else {
      const bnCode = value.bn.substring(2, 4);
      $scope.checkboxValues[bnCode] = false;
      const index = $scope.addedBns.indexOf(value.bn);
      if (index > -1) {
        $scope.addedBns.splice(index, 1);
      }
    }
  };

  /**
   *
   */
  $scope.save = function () {
    $modalInstance.close($scope.addedBns)
  };
}
