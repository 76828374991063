angular.module('barometerApp.roadmapChart')
  .controller('RoadmapChartCtrl', ['$scope', '$timeout', 'roadmapChartService', 'urlService', 'commonWorksheetService', 'roadmapChartScaleService', '$element',
    function ($scope, $timeout, roadmapChartService, urlService, commonWorksheetService, roadmapChartScaleService, $element) {

      $scope.sectionBn = "WORKSHEET";
      $scope.loading = true;
      $scope.reloading = false;
      $scope.hasData = false;

      // Tied into the roadmap lib
      $scope.sortMode = 'date';
      $scope.firstDay = 1;
      $scope.weekendDays = [0, 6];
      $scope.maxHeight = 0;
      $scope.showWeekends = true;
      $scope.scale = 'year';
      $scope.scaleFactor = roadmapChartScaleService.getScaleFactor($scope.scale);
      var dateRange = roadmapChartScaleService.getDateRange($scope.scale, $element);
      $scope.fromDate = dateRange.fromDate;
      $scope.toDate = dateRange.toDate;

      commonWorksheetService.getWorksheetEntity($scope.worksheetBn).then(function (entity) {
        $scope.config = entity.configuration;
        loadViz();
      });

      function loadViz() {
        $scope.loading = true;

        // Set sortMode from config
        $scope.sortMode = $scope.config.order;

        roadmapChartService.fetchData($scope.worksheetBn, function (data) {
          $scope.loading = false;
          $scope.hasData = data && data.length > 0;
          orderRows(data);
          $scope.clearData();
          $scope.loadData(data); // loadData is a hook that's exposed from roadmapChart
        });
      }

      // Slightly less jarring (and holistic) than load
      function reloadViz() {
        $scope.reloading = true;
        roadmapChartService.fetchData($scope.worksheetBn, function (data) {
          $scope.hasData = data && data.length > 0;
          orderRows(data);
          $scope.clearData();
          $scope.loadData(data);  // loadData is a hook that's exposed from roadmapChart
          $scope.reloading = false;
        });
      }

      function orderRows(data) {
        // Apply custom order to rows, if applicable
        if ($scope.config && $scope.config.order && $scope.config.order === 'custom' && $scope.config.customOrder) {
          _.each(data, function (row) {
            row.order = $scope.config.customOrder[row.id];
          });
        }
      }

      function updateViewScale(viewScale) {
        $scope.scale = viewScale;
        $scope.scaleFactor = roadmapChartScaleService.getScaleFactor(viewScale);
        var dateRange = roadmapChartScaleService.getDateRange($scope.scale, $element);
        $scope.fromDate = dateRange.fromDate;
        $scope.toDate = dateRange.toDate;
        reloadViz();
      }

      // Called after indexing completed
      $scope.$on('loadSection', function (event, sectionBn) {
        if (sectionBn === $scope.sectionBn) {
          reloadViz();
        }
      });

      $scope.$on('roadmapScaleChange', function (event, viewScale) {
        updateViewScale(viewScale);
      });

      // When window is resized, re-build visualization
      // Debounced cuz resize fires continuously during window drag
      $(window).resize(_.debounce(function () {
        // Needs timeout to trigger a digest
        $timeout(function () {
          updateViewScale($scope.scale);
        });
      }, 100));

      $scope.$on('worksheetAssocAddedOrUpdated', function (event, associatedEntity) {
        roadmapChartService.fetchOneRow(associatedEntity, function (rowData) {
          // To properly redraw the row, we need to remove and re-add it.
          $scope.removeData([
            {id: associatedEntity.bn}
          ]);
          if ($scope.loadData(rowData) > 0) {
            $scope.hasData = true;
          }
        });
      });

      $scope.$on('worksheetAssocRemoved', function (event, associatedEntity) {
        var rowsCount = $scope.removeData([
          {id: associatedEntity.bn}
        ]);
        if (rowsCount === 0) {
          $scope.hasData = false;
        }
      });

      $scope.$on('worksheetConfigUpdated', function ($event, newConfig) {
        $scope.config = newConfig;
        loadViz();
      });

      // Map row ordering into bn:order map for persisting in config
      $scope.$on('roadmapCustomSortChanged', function ($event, rows) {
        $scope.config.customOrder = {};
        _.each(rows, function (row) {
          $scope.config.customOrder[row.id] = row.order;
        });
      });

      // When the menu goes off screen, treat that like a "click away"
      $scope.$on('roadmapChartLifecycleEditMenuOffScreen', function (event, data) {
        $timeout(function () {
          $("body").trigger("click")
        });
      });

      // Make sure roadmap click events reach body element
      // Allows open dropdowns to close as expected
      $scope.rowOrTaskClicked = function () {
        $timeout(function () {
          $("body").trigger("click")
        });
      };
    }])
  .controller('RoadmapChartWorksheetSubheaderCtrl', ['$scope', 'entityService', 'tableService', 'utilService', 'urlService', '$rootScope', 'optionListService', 'commonWorksheetService', 'worksheetSubheaderState', 'roadmapChartService', '$timeout',
    function ($scope, entityService, tableService, utilService, urlService, $rootScope, optionListService, commonWorksheetService, worksheetSubheaderState, roadmapChartService, $timeout) {

      $scope.sectionBn = "WORKSHEET";
      $scope.showSave = false;
      $scope.saveInProgress = false;
      $scope.showOrderSave = false;
      $scope.showAddMenu = false;
      $scope.showEditMenu = false;
      $scope.editErrors = [];
      $scope.saveErrors = [];
      var originalConfig = null;

      $scope.includeEntityTypes = ['ACT', 'CAP', 'COM', 'CON', 'DAT', 'DEM', 'PHY', 'AST', 'MKT', 'PRD', 'SKI', 'STA', 'SYS', 'STR', 'TEC'];

      // Share active/inactive and mode information via service
      $scope.sharedSubheaderState = worksheetSubheaderState;

      $scope.orderModel = {
        orderOptions: [
          {value: 'date', label: 'By Date'},
          {value: 'name', label: 'By Name'},
          {value: 'custom', label: 'Manually'}
        ]
      };

      function getIndexOfSelectedOrderOption() {
        var index = _.findIndex($scope.orderModel.orderOptions, function (orderOption) {
          return $scope.config.order === orderOption.value;
        });
        if (index < 0) index = 0;
        return index;
      }

      commonWorksheetService.getWorksheetEntity($scope.worksheetBn).then(function (entity) {
        $scope.config = entity.configuration;
        var selectedOptionIndex = getIndexOfSelectedOrderOption();
        $scope.orderModel.selectedOrderOption = $scope.orderModel.orderOptions[selectedOptionIndex];
      });

      $scope.$on('roadmapLifecycleStateSelected', function (event, data) {
        $scope.showAddMenu = false;
        $scope.showEditMenu = true;
        $scope.editErrors = [];
        $scope.selectedLSModel = {
          bn: data.lifecycleState.bn,
          entity: data.lifecycleState.entity,
          startDate: data.lifecycleState.startDate ? utilService.convertToLocalDate(data.lifecycleState.startDate) : null,
          endDate: data.lifecycleState.endDate ? utilService.convertToLocalDate(data.lifecycleState.endDate) : null,
          dataListName: data.lifecycleState.dataListItem.dataList.type,
          dataListItem: {
            bn: data.lifecycleState.dataListItem.bn
          },
          typeOptions: [],
          selectedTypeOption: null
        };
        $scope.selectedLSRowElem = data.elem;

        optionListService.getOptionListChoices([{dataListType: $scope.selectedLSModel.dataListName}])
          .then(function (data) {
            $scope.selectedLSModel.typeOptions = _.map(data.data, function (optionData) {
              var typeOption = {
                value: optionData.bn,
                label: optionData.displayableName
              };
              if (optionData.bn === $scope.selectedLSModel.dataListItem.bn) {
                $scope.selectedLSModel.selectedTypeOption = typeOption;
              }
              return typeOption;
            });
          });

        // Close after clicking anywhere else
        $timeout(function () {
          $('body').one('click', function (event) {
            if ($scope.selectedLSModel && $scope.selectedLSModel.bn === data.lifecycleState.bn) {
              $scope.showEditMenu = false;
              $scope.showAddMenu = false;
              $rootScope.$broadcast('roadmapLifecycleStateSelectionCleared');
            }
          });
        }, 100);
      });

      $scope.reorderRows = function () {
        $scope.sharedSubheaderState.mode = 'reordering';
        $scope.showAddMenu = false;
        $scope.showEditMenu = false;
        originalConfig = angular.copy($scope.config);
      };

      $scope.orderDropdownChange = function () {

        // The ng-change that triggers this is crazy hyperactive.
        // Make sure there's really a change before doing anything.
        if ($scope.orderModel.selectedOrderOption.value !== $scope.config.order) {
          $scope.showOrderSave = true;

          // Display new order immediately (it's not persisted yet, though)
          $scope.config.order = $scope.orderModel.selectedOrderOption.value;
          $rootScope.$broadcast('worksheetConfigUpdated', $scope.config);
        }
      };

      $scope.saveOrdering = function () {
        var typeCodesInWorksheet = 'SYS'; //TODO, use correct types (does it matter?!?)
        $rootScope.$broadcast('worksheetConfigUpdated', $scope.config);
        commonWorksheetService.updateWorksheetConfig(urlService.getEntityBn(), $scope.config, typeCodesInWorksheet)
          .then(function () {
            originalConfig = angular.copy($scope.config);
            $scope.showOrderSave = false;
            $scope.sharedSubheaderState.mode = 'default';
          });
      };

      $scope.cancelOrdering = function () {
        $scope.config = originalConfig;
        if ($scope.config.order !== 'custom' && $scope.config.customOrder) {
          $scope.config.customOrder = null;
        }
        $rootScope.$broadcast('worksheetConfigUpdated', $scope.config);

        // Re-select the right dropdown option
        $scope.orderModel.selectedOrderOption = $scope.orderModel.orderOptions[getIndexOfSelectedOrderOption()];
        $scope.showOrderSave = false;
        $scope.sharedSubheaderState.mode = 'default';
      };

      function validateSelectedLSModel() {
        return roadmapChartService.validateLifecycleStateModel(
          $scope.selectedLSModel.selectedTypeOption ? $scope.selectedLSModel.selectedTypeOption.value : null,
          $scope.selectedLSModel.startDate,
          $scope.selectedLSModel.endDate
        );
      }

      $scope.saveChanges = function () {

        $scope.editErrors = validateSelectedLSModel();
        if ($scope.editErrors.length) {
          return;
        }

        $scope.saveInProgress = true;
        var createParams = {
          entityDiscriminator: 'PLL',
          startDate: $scope.selectedLSModel.startDate ? utilService.convertToISOUTCLocalDate($scope.selectedLSModel.startDate) : null,
          endDate: $scope.selectedLSModel.endDate ? utilService.convertToISOUTCLocalDate($scope.selectedLSModel.endDate) : null,
          dataListItem: {
            bn: $scope.selectedLSModel.selectedTypeOption.value
          }
        };
        entityService.createEntity(createParams, "PLL").then(function (result) {
          var assocParams = {
            sourceBn: $scope.selectedLSModel.entity.bn,
            associations: {
              removedAssocs: [{targetBn: $scope.selectedLSModel.bn, relationshipType: "RELATED_TO"}],
              addedAssocs: [{targetBn: result.data.bn, relationshipType: "RELATED_TO"}]
            },
            sectionBn: $scope.sectionBn
          };
          $scope.clearSelected();
          $scope.saveInProgress = false;

          var bnToDelete = $scope.selectedLSModel.bn;
          tableService.updateAssociations(assocParams).then(function () {
            entityService.deleteEntity(bnToDelete);
          });
        });
      };

      // Nearly identical to saveChanges, except no deletes to make!
      $scope.saveNew = function () {

        $scope.createErrors = validateSelectedLSModel();
        if ($scope.createErrors.length) {
          return;
        }

        $scope.saveInProgress = true;
        var createParams = {
          entityDiscriminator: 'PLL',
          startDate: $scope.selectedLSModel.startDate ? utilService.convertToISOUTCLocalDate($scope.selectedLSModel.startDate) : null,
          endDate: $scope.selectedLSModel.endDate ? utilService.convertToISOUTCLocalDate($scope.selectedLSModel.endDate) : null,
          dataListItem: {
            bn: $scope.selectedLSModel.selectedTypeOption.value
          }
        };
        entityService.createEntity(createParams, "PLL").then(function (result) {
          var assocParams = {
            sourceBn: $scope.selectedLSModel.entity.bn,
            associations: {
              addedAssocs: [{targetBn: result.data.bn, relationshipType: "RELATED_TO"}]
            },
            sectionBn: $scope.sectionBn
          };
          tableService.updateAssociationsAndWaitForIndexing(assocParams).then(function () {
            $rootScope.$broadcast('worksheetAssocAddedOrUpdated', $scope.selectedLSModel.entity);
            $scope.clearSelected();
            $scope.saveInProgress = false;
          });
        });
      };

      $scope.cancelAdd = function () {
        $scope.showAddMenu = false;
      };

      $scope.$on('roadmapCustomSortChanged', function ($event, rows) {
        _.each(rows, function (row) {
          $scope.config.customOrder[row.id] = row.order;
        });
        $scope.showOrderSave = true;
      });

      // Find the right dataListName in the global constant.
      // Example dataListType array:
      //   EntityOptionListTypes['TEC'] = [
      //     {dataListType: 'TECHNOLOGY_LIFECYCLE'},
      //     {dataListType: 'TECHNOLOGY_DOMAIN'},
      //     {dataListType: 'TECHNOLOGY_APPROVAL'}
      //   ];
      function getDataListNameForEntity(entity) {
        var entityTypeCode = utilService.getEntityTypeCode(entity.bn);
        var optionListType = _.find(EntityOptionListTypes[entityTypeCode], function (type) {
          return type.dataListType.indexOf('_LIFECYCLE') !== -1;
        });
        return optionListType ? optionListType.dataListType : null;
      }

      $scope.$on('roadmapAddLifecycleStateToEntity', function (event, entity, targetElem) {
        $scope.showEditMenu = false;
        $scope.showAddMenu = true;
        $scope.createErrors = [];

        // Identify _this_pass_ of the event handler
        // This lets us distinguish it from future calls
        // Which is key to deciding when to close the Add Menu
        var addMenuId = $scope.currentAddMenuId = Math.random();

        $scope.selectedLSModel = {
          bn: null,
          entity: entity,
          startDate: null,
          endDate: null,
          dataListName: getDataListNameForEntity(entity),
          dataListItem: {
            bn: null
          },
          typeOptions: [],
          selectedTypeOption: null
        };
        $scope.selectedLSRowElem = $(targetElem);

        optionListService.getOptionListChoices([{dataListType: $scope.selectedLSModel.dataListName}])
          .then(function (data) {
            $scope.selectedLSModel.typeOptions = _.map(data.data, function (optionData) {
              var typeOption = {
                value: optionData.bn,
                label: optionData.displayableName
              };
              if (optionData.bn === $scope.selectedLSModel.dataListItem.bn) {
                $scope.selectedLSModel.selectedTypeOption = typeOption;
              }
              return typeOption;
            });
          });

        // Close after clicking anywhere else
        $timeout(function () {
          $('body').one('click', function () {
            var aNewAddHandlerWasCalled = addMenuId !== $scope.currentAddMenuId;
            if ($scope.showAddMenu && !aNewAddHandlerWasCalled) {
              $scope.showEditMenu = false;
              $scope.showAddMenu = false;
              $rootScope.$broadcast('roadmapLifecycleStateSelectionCleared');
            }
          });
        }, 100);
      });

      $scope.$on('roadmapRemoveEntity', function (event, entity) {
        $scope.showEditMenu = false;
        $scope.showAddMenu = false;
        $scope.removeEntity(entity);
      });

      $scope.deleteSelectedLifecycle = function () {
        $scope.showEditMenu = false;
        $scope.showAddMenu = false;
        $scope.saveInProgress = true;
        var params = {
          sourceBn: $scope.selectedLSModel.bn,
          targetBnCode: utilService.getBnCode($scope.selectedLSModel.entity.bn),
          indexBn: $scope.selectedLSModel.entity.bn,
          associations: {
            addedAssocs: [],
            removedAssocs: [
              {targetBn: $scope.selectedLSModel.entity.bn, relationshipType: "RELATED_TO"}
            ]
          },
          sectionBn: $scope.sectionBn
        };
        var bnToDelete = $scope.selectedLSModel.bn;
        $scope.clearSelected();
        $scope.saveInProgress = false;
        tableService.updateAssociations(params).then(function (response) {
          if(response.data.proposedChangeBns && response.data.proposedChangeBns.length > 0) {
            // don't delete the entity, the relationship was not actually removed.
          } else {
            entityService.deleteEntity(bnToDelete).then(function (data) {
            });
          }
        });
      };

      $scope.clearSelected = function () {
        $scope.showEditMenu = false;
        $scope.showAddMenu = false;
        $scope.editErrors = [];
        $scope.sharedSubheaderState.mode = 'default';
        $rootScope.$broadcast('roadmapLifecycleStateSelectionCleared');
      };

      // Trigger digests after subheader opens to recalculate flyout positions
      $scope.$watch('sharedSubheaderState.active', function (newValue, oldValue) {
        if (oldValue !== newValue) {
          $timeout(function () {
          }, 300);
        }
      });

      // Close menus when window is resized
      $(window).resize(_.debounce(function () {
        // Needs timeout to trigger a digest
        $timeout(function () {
          $scope.showEditMenu = false;
          $scope.showAddMenu = false;
          $rootScope.$broadcast('roadmapLifecycleStateSelectionCleared');
        });
      }, 100));
    }])
  .controller('DashboardRoadmapChartViewMenuCtrl', ['$scope', 'utilService', '$rootScope', '$timeout',
    function ($scope, utilService, $rootScope, $timeout) {
      $scope.showViewMenu = false;

      function clearSelected() {
        $scope.selectedLSModel = null;
        $scope.showViewMenu = false;
        $rootScope.$broadcast('roadmapLifecycleStateSelectionCleared');
      }

      $scope.$on('roadmapChartLifecycleEditMenuOffScreen', function (event, data) {
        console.log('handler for roadmapChartLifecycleEditMenuOffScreen')
        // Use up the body click handler
        $('body').trigger('click');
      });

      $scope.$on('roadmapLifecycleStateSelected', function (event, data) {

        // If clicked when already selected, de-select
        if ($scope.selectedLSModel && data.lifecycleState.bn === $scope.selectedLSModel.bn) {
          clearSelected();
          return;
        }

        $scope.showViewMenu = true;

        $scope.selectedLSModel = {
          bn: data.lifecycleState.bn,
          entity: data.lifecycleState.entity,
          startDate: data.lifecycleState.startDate ? utilService.convertToLocalDate(data.lifecycleState.startDate) : null,
          endDate: data.lifecycleState.endDate ? utilService.convertToLocalDate(data.lifecycleState.endDate) : null,
          dataListName: data.lifecycleState.dataListItem.dataList.type,
          dataListItem: {
            bn: data.lifecycleState.dataListItem.bn
          },
          typeOptions: [],
          selectedTypeOption: null
        };
        $scope.selectedLSRowElem = data.elem;
        $scope.selectedLSModel.selectedTypeOption = {
          value: data.lifecycleState.dataListItem.bn,
          label: data.lifecycleState.dataListItem.displayableName,
          type: data.lifecycleState.dataListItem.type
        };

        // Close after clicking anywhere else
        $timeout(function () {
          $('body').one('click', function (event) {
            console.log('body click handler')
            if ($scope.selectedLSModel && $scope.selectedLSModel.bn === data.lifecycleState.bn) {
              clearSelected();
            }
          });
        }, 100);

      });
    }]);
;
