angular.module('barometerApp.nav')
  .directive('navbarGlossy', ['tenantService', (tenantService) => {
    return {
      restrict: 'E',
      templateUrl: '/b/js/src/bit.ng/navbar-glossy/partials/navbar-glossy.html',
      scope: {
        activeElement: '@',
        navItems: '@'
      },
      link: (scope, element, attrs, controller) => {

        scope.isLoading = true;

        scope.isAvailable = (navItem) => {
          if (!scope.navItems) {
            return false;
          }
          return scope.navItems.some(n => n === navItem);
        };

        tenantService.getCurrentTenantsNavigationItems()
          .then(navItems => {
            scope.navItems = navItems;
            scope.isLoading = false;
          });
      }
    }
  }]);
