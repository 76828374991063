angular.module('barometerApp.filter')
  .service('filterService', ['$rootScope', 'tableService', function ($rootScope, tableService) {

    return {

      getTableFilter: function (tableId) {
        var table = tableService.getTable(tableId);
        if (table && table.filterParams) {
          return table.filterParams
        }
        return "";
      },

      getTableFilterSpec: function (tableId) {
        var table = tableService.getTable(tableId);
        return table ? table.filterSpec : null;
      },

      convertFilterObjectToFilterArray: function (filterParams) {
        if (!filterParams) return;
        var filterObject = filterParams.selected,
          filterGroups = angular.copy(filterParams ? filterParams.filterGroups : {}),
          tmpFilterObj = {};
        var filterArray = filterParams.params ? filterParams.params : [];

        // SS - converts the existing filterParam object to the array of param objects, per Sam's new filter JSON
        // Select2's binding is kind of wonky for this, so instead of trying to massage the binding, we're adjusting the filter object
        // on both data in (insert file & line number) and data out (here)
        for (var k in filterObject) {
          if (filterObject.hasOwnProperty(k)) {
            var group = _.filter(filterGroups, function (group) {
              return _.indexOf(group.grouping, k) > -1
            });
            tmpFilterObj[k] = filterObject[k];
            if (group && group.length > 0) {
              if (group[0].filterIndex !== null) {
                filterArray[group[0].filterIndex][k] = filterObject[k];
              } else {
                filterArray.push(tmpFilterObj);
                group[0].filterIndex = filterArray.length - 1;
              }
            } else {
              filterArray.push(tmpFilterObj);
            }
          }
          tmpFilterObj = {};
        }
        return {
          params: filterArray,
          searchString: filterParams.searchString
        };
      },

      convertFilterArrayToFilterObject: function (filterArray) {
        var filterObj = {};
        if (_.isArray(filterArray)) {
          _.each(filterArray, function (filter) {
            for (var k in filter) {
              if (filter.hasOwnProperty(k)) {
                filterObj[k] = filter[k];
              }
            }
          });
        }
        return filterObj;
      }
    }
  }]);
