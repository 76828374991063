angular.module('barometerApp.table')
  .filter('tableFilterType', function () {
    return function (items, filterType) {
      var arrayToReturn = [];
      arrayToReturn = _.filter(items, function (item) {
        if (filterType == "simpleText") {
          return item.key == "simpleTextSearchKey";
        } else if (filterType == "multiSelect") {
          return item.type == "MULTI_SELECT_VALUED";
        } else if (filterType == "typeahead") {
          return item.type == "MULTI_SELECT_BN";
        }
      });
      return arrayToReturn;
    }
  });