angular.module('barometerApp.radialChart')
  .factory('radialChartService', ['$http', 'associatedBnCacheService',
    function ($http, associatedBnCacheService) {

      function getAssociatedSystems(radialChartBn, callback) {
        $http({
          method: 'GET',
          url: '/b/api/workspace/resources',
          params: {
            entityBn: radialChartBn,
            maxResults: 1000
          }
        })
          .success(function (data) {
            var entities = processWorkspaceSystems(data, callback);
            var childrenBns = [];
            _.each(entities.children, function (child) {
              childrenBns.push(child.key);
            });
              getConnectionsWithRollup(childrenBns, entities, callback);
            //getAssociatedConnections(childrenBns, entities, callback);
          })
          .error(function (data, status) {
            console.error('Error in getWorkspaceEntities: ' + status);
          });
      }

      function processWorkspaceSystems(data, callback) {
        var root = {
          name: '',
          depth: 0,
          children: [],
          length: 1
        };
        // Populate entities, formatted for radial widget
        _.each(data.results, function (entityTypeGroup) {
          if (entityTypeGroup.entityTypeCode === 'SYS') {
            _.each(entityTypeGroup.entityTypeResults, function (system) {
              root.children.push(getSystemRow(system, root));
              associatedBnCacheService.addAssociatedBn(system['bn']);
            });
          } else {
            console.warn('Ignoring Everything But Systems');
          }
        });
        return root;
      }

      function getSystemRow(system, root) {
        return {
          name: system.name,
          key: system.bn,
          depth: 1,
          parent: root,
          children: []
        }
      }

      function getConnectionsWithRollup(entityList, entityDetails, callback) {
        $http({
          method: 'GET',
          url: '/b/api/connection/findManyConnectionInfo',
          params: {
            endpointBns: entityList
          }
        }).success(function (data) {
          var connections = data;
          var _entityLinks = [];

          if (!_entityLinks.links) {
            _.each(connections, function (link) {
              link.source = _.find(entityDetails.children, { key: link.sourceBn });
              link.target = _.find(entityDetails.children, { key: link.targetBn });
            });
            _entityLinks.links = connections;
          }
          _entityLinks.entities = entityDetails;

          callback(_entityLinks);
        })
        .error(function (data, status) {
          console.error('Error in radial-chart-services.getConnectionsWithRollup: ' + status);
        });
      }

      // TODO: Remove this once new implementation is complete (For TIM TODO)
      //function getAssociatedConnections(entityList, entityDetailList, callback) {
      //  $http({
      //    method: 'GET',
      //    url: '/b/api/connection/byentitybns',
      //    params: {
      //      entityList: entityList
      //    }
      //  })
      //    .success(function (data) {
      //      getSourceAndTargetEntityLists(data, entityList, entityDetailList, callback);
      //    })
      //    .error(function (data, status) {
      //      console.error('Error in getAssociatedConnections: ' + status);
      //    });
      //}
      //
      //function getSourceAndTargetEntityLists(connectionList, entityBnList, entityDetails, callback) {
      //
      //  // make sure entityBnList passes null as string if empty/ undefined
      //  // this will tell the API endpoint that we do not want to limit the results
      //  if (entityBnList == null || entityBnList == undefined) {
      //    entityBnList = "null";
      //  }
      //
      //  $http({
      //    method: 'GET',
      //    url: '/b/api/connection/sourcestargets',
      //    params: {
      //      connectionList: connectionList,
      //      entityBns: entityBnList
      //    }
      //  })
      //    .success(function (data) {
      //      var connections = data;
      //      var _entityLinks = [];
      //
      //      if (!_entityLinks.links) {
      //        _.each(connections, function (link) {
      //          link.source = _.find(entityDetails.children, { key: link.sourceBn });
      //          link.target = _.find(entityDetails.children, { key: link.targetBn });
      //        });
      //        _entityLinks.links = connections;
      //      }
      //      _entityLinks.entities = entityDetails;
      //
      //      callback(_entityLinks);
      //    })
      //    .error(function (data, status) {
      //      console.error('Error in getSourceAndTargetEntityLists: ' + status);
      //    });
      //}

      return {
        fetchData: function (radialChartBn, callback) {
          return getAssociatedSystems(radialChartBn, callback);
        }

        //fetchLinks: function (entityBns, entityData, callback) {
        //  return getAssociatedConnections(entityBns, entityData, callback);
        //}
      };
    }]);