angular
  .module('barometerApp.lock')
  .factory('lockService', lockService);

lockService.$inject = [
  '$http',
  '$q'];

function lockService($http,
                     $q) {

  var baseUrl = '/b/api/zookeeper/';
  var currentRunningTx = [];
  var txPromises = {};

  /**
   * Export my public functions.
   */
  return {
    processIndexResponseForTransactionId: processIndexResponseForTransactionId
  };

  //-----------------------------------
  // PRIVATE FUNCTIONS
  //-----------------------------------

  function getLocksForTransactionId(transactionId) {
    return $http({
      method: 'GET',
      url: baseUrl + transactionId,
      headers: 'application/json;charset=UTF-8'
    }).success(function (data) {
    }).error(function (data) {
      console.log('error', data);
    })
  }

  /**
   * @param transactionId Remove from current-running and list of outstanding promises.
   *  Do this after your promise is resolved or rejected.
   */
  function remove(transactionId) {
    currentRunningTx.splice(currentRunningTx.indexOf(transactionId), 1);
    delete txPromises[transactionId];
  }

  /**
   * When a lock is found, resolve its promise(s).
   * @param transactionId
   * @param data
   */
  function resolve(transactionId, data) {
    txPromises[transactionId].forEach(function(promise) {
      promise.resolve(data)
    });
    remove(transactionId);
  }

  /**
   * Give up if timed out.
   * @param transactionId
   * @param data
   */
  function reject(transactionId, data) {
    txPromises[transactionId].forEach(function (promise) {
      promise.reject(data);
    });
    remove(transactionId);
  }

  /**
   * @param transactionId
   * @returns {boolean}
   */
  function isCurrentlyRunning(transactionId) {
    return currentRunningTx.indexOf(transactionId) !== -1
  }

  /**
   * Put into my collections.
   *
   * @param transactionId
   * @param deferred
   */
  function remember(transactionId, deferred) {
    // Are we already polling this transactionId?
    // If no, then let's initialize our collections.
    if (!isCurrentlyRunning(transactionId)) {
      currentRunningTx.push(transactionId);
      // Init the array and "push" in one step.
      txPromises[transactionId] = [deferred];
    }
    //If yes, add this promise to the list to be resolved after completion.
    else {
      // Array already exists. Just push.
      txPromises[transactionId].push(deferred);
    }
  }

  //-----------------------------------
  // PUBLIC FUNCTIONS
  //-----------------------------------

  /**
   * This returns a promise that resolves if a lock is found and rejects if no lock is found.
   * If this rejects that means the front end should just give up.
   * @param timeout
   * @param transactionId
   */
  function processIndexResponseForTransactionId(timeout, transactionId) {
    //
    var startTime = new Date().getTime();
    var maxTimeout = timeout || 120000; // 2min is the default
    var maxTime = startTime + maxTimeout;
    var deferred = $q.defer();

    /**
     * Win-handler!
     */
    function foundHandler(data) {
      resolve(transactionId, data);
    } // end foundHandler

    /**
     * Lose-handler!
     */
    function notFoundHandler(data) {
      var timedOut = new Date().getTime() > maxTime;
      if (timedOut) {
        reject(transactionId, data);
      }
      else {
        getLocksForTransactionId(transactionId)
          .then(foundHandler, notFoundHandler);
      }
    } // end notFoundHandler

    remember(transactionId, deferred);
    //check for lock
    getLocksForTransactionId(transactionId)
      .then(foundHandler, notFoundHandler);

    //return the promise
    return deferred.promise;
  }
}
