angular.module('barometerApp.dataImport')
    .directive('csvHelp', ["$compile", '$http', '$templateCache', 'csvHelpService',
        function($compile, $http, $templateCache, csvHelpService) {

            var getTemplate = function(type) {

                var template = '/b/js/src/bit.ng/data-import/partials/help/csv_import_help.html';

                if(type.length == 3) {
                    template = '/b/js/src/bit.ng/data-import/partials/help/' + type + '_csv_import_help.html';
                }

                return template;
            };

            return {
                scope: {
                    csvHelp: '@'
                },
                link: function(scope, element, attrs) {

                    scope.$watch('csvHelp', function(value)
                    {
                        csvHelpService.setEntityTypeCodeFromImport(value);
                        var template = getTemplate(value);
                        loadTemplate(template);
                    });

                    var currentElement = element;
                    function loadTemplate(template) {
                        $http.get(template, { cache: $templateCache})
                            .success(function(templateContent) {
                                var replacementElement = angular.element($compile(templateContent)(scope));
                                currentElement.replaceWith(replacementElement);
                                currentElement = replacementElement;
                            })
                            .error(function(){
                                templateContent = $templateCache.get("/b/js/src/bit.ng/data-import/partials/help/missing_csv_import_help.html")
                                var replacementElement = angular.element($compile(templateContent)(scope));
                                currentElement.replaceWith(replacementElement);
                                currentElement = replacementElement;
                            });
                    }
                }
            }
    }])
  .directive('csvAssocHelp', ["$compile", '$http', '$templateCache', 'csvHelpService',
        function($compile, $http, $templateCache, csvHelpService) {
          var getTemplate = function (type) {
            var template = '/b/js/src/bit.ng/data-import/partials/help/csv_import_help.html';

            if(type.length == 3) {
              template = '/b/js/src/bit.ng/data-import/partials/help/assoc_import_help.html';
            }

            return template;
          };

          return {
            scope: {
              csvAssocHelp: '@'
            },
            link: function (scope, element, attrs) {
              scope.$watch('csvAssocHelp', function (value) {
                csvHelpService.setEntityTypeCodeFromImport(value);
                var template = getTemplate(value);
                loadTemplate(template);
              });

              var currentElement = element;
              function loadTemplate(template) {
                $http.get(template, { cache: $templateCache})
                  .success(function(templateContent) {
                    var replacementElement = angular.element($compile(templateContent)(scope));
                    currentElement.replaceWith(replacementElement);
                    currentElement = replacementElement;
                  })
                  .error(function(){
                    templateContent = $templateCache.get("/b/js/src/bit.ng/data-import/partials/help/missing_csv_import_help.html");
                    var replacementElement = angular.element($compile(templateContent)(scope));
                    currentElement.replaceWith(replacementElement);
                    currentElement = replacementElement;
                  });
              }
            }
          }

        }])
;