angular
  .module('barometerApp.relationalDiagram')
  .controller('RelationalDiagramController', RelationalDiagramController);

RelationalDiagramController.inject = [
  '$element',
  '$q',
  '$rootScope',
  '$scope',
  '$timeout',
  'alertService',
  'bitConstants',
  'downloadService',
  'entityService',
  'rdConfigModel',
  'relationalDiagramCytoService',
  'relationalDiagramGraphDataService',
  'relationalDiagramLayoutService',
  'relationalDiagramStyleService',
  'urlService',
  'utilService',
  'worksheetSubheaderState'];

/**
 * For the most part, this controller manages cytoscape-container.
 */
function RelationalDiagramController($element,
                                     $q,
                                     $rootScope,
                                     $scope,
                                     $timeout,
                                     alertService,
                                     bitConstants,
                                     downloadService,
                                     entityService,
                                     rdConfigModel,
                                     relationalDiagramCytoService,
                                     relationalDiagramGraphDataService,
                                     relationalDiagramLayoutService,
                                     relationalDiagramStyleService,
                                     urlService,
                                     utilService,
                                     worksheetSubheaderState) {

  //NOTE(Ryan) Since it is possible for worksheets to be rendered inside of other pages
  //We must have a way to get the bn that isn't from the url. This is done by setting
  //the scopeBn before the worksheet is rendered. If we are setting scope bn to null when
  //rendered this clears out the "passed" bn and forces us to rely on the bn in the url.
  // $scope.worksheetBn = null;
  $scope.workspaceBn = null;
  $scope.cy = null;
  $scope.tooltip = {};

  // The next four properties are used to manage success/fail presentation.
  // start manage success/fail messages
  $scope.loading = true;
  $scope.reloading = false;
  $scope.hasData = false;
  $scope.requestFailed = false;
  // end manage success/fail messages

  // Show/hide cyto-container.
  // Added this so we have more freedom to tune in this controller.
  $scope.isShowCytoContainer = false;

  // Share subheader active/inactive information via service.
  $scope.subheaderState = worksheetSubheaderState;

  //--------------------------------------
  // PRIVATE STATE FUNCTIONS
  //--------------------------------------

  function assertHasData() {
    if (!$scope.graph) {
      throw "Scope variable graph has no data.";
    }
    if (!$scope.hasData) {
      throw "Scope variable hasData is false.";
    }
  }

  function getCytoContainer() {
    // Gotta do this from the controller cuz we need $element.
    return $element.find('.cytoscape-container').get(0);
  }

  /**
   * Skyhook into the urlService to make sure we know our page identity.
   */
  function getWorksheetBn() {
    if (!$scope.worksheetBn) {
      $scope.worksheetBn = urlService.getEntityBn();
    }
    return $scope.worksheetBn;
  }

  function clearGraphData() {
    $scope.graph = null;
    $scope.hasData = false;
  }

  /**
   * @param graphData
   */
  function setGraphData(graphData) {
    $scope.graph = graphData;
    $scope.hasData = true;
  }

  /**
   * TODO We could almost leave the pane always visible, but there's presently some jank.
   * TODO Because we presently manage state integrity by fully reloading persistent state,
   * TODO there will probably always be jank.
   * TODO We'd probably have to do some careful atomic synchronization to/from cy to make it smooth.
   */
  function toggleShowCytoContainer() {
    $scope.isShowCytoContainer = !$scope.loading && $scope.hasData;
  }

  //--------------------------------------
  // PRIVATE VERY-CYTO FUNCTIONS
  //--------------------------------------

  /**
   *
   */
  function chooseLayout() {
    //
    // console.log("chooseLayout");
    //
    var layout;
    //
    if (rdConfigModel.hasNodePositions()) {
      if (rdConfigModel.getLayoutMode() === 'auto') {
        layout = relationalDiagramLayoutService[rdConfigModel.getLayoutName()];
      } else {
        layout = relationalDiagramLayoutService.PRESET;
      }
      if ($scope.previewMode) {
        layout.fit = true;
      }
    } else {
      layout = relationalDiagramLayoutService[rdConfigModel.getLayoutName()];
    }

    console.debug('chooseLayout, returning', layout);
    return layout;
  }

  /**
   * @param layout
   */
  function addCyListenersAndReady(layout) {
    //
    console.debug("addCyListenersAndReady");
    //
    var deferred = $q.defer();
    //
    if (layout === relationalDiagramLayoutService.PRESET) {
      // PRESET layout is always ready immediately: no need for cy.ready() callback.
      addCyListeners($scope.cy);
      deferred.resolve();
    } else {
      // Run a callback as soon as the graph becomes ready (i.e. data loaded and initial layout completed).
      $scope.cy.ready(function () {
        // attempting to drag a relational diagram around when editing a dashboard layout will cause the diagram to drag as well
        // isEditingLayout is specified in summary-content-block-edit.html
        if ($scope.isEditingLayout) { // if we are editing a layout...
          $scope.cy.autolock(true); // lock all the nodes
          $scope.cy.autoungrabify(true); // make all nodes ungrabbable
          $scope.cy.autounselectify(true); // make all nodes unselectable
          $scope.cy.boxSelectionEnabled(false); // prevent the user from making a drag selection box
          $scope.cy.userPanningEnabled(false); // prevent the user from dragging to pan the diagram
          $scope.cy.userZoomingEnabled(false); // prevent the user from zooming the diagram with the mouse
          $scope.cy.style().selector('core').css({'active-bg-size': 0}); // hide the circle when clicking on the background
          $scope.cy.style().selector('node').css({'overlay-opacity': 0}); // hide the overlay when clicking on nodes
          $scope.cy.style().selector('edge').css({'overlay-opacity': 0}); // hide the overlay when clicking on edges
        }

        addCyListeners($scope.cy);
        deferred.resolve();
      })
    }
    return deferred.promise;
  }

  /**
   *
   */
  function ensureCyInstance() {
    var deferred = $q.defer();
    if (!$scope.cy) {
      console.debug('found no scope.cy');
      var cytoContainer = getCytoContainer();
      var layout = chooseLayout();
      $scope.cy = relationalDiagramCytoService.newCyInstance(cytoContainer, layout);
      addCyListenersAndReady(layout)
        .then(deferred.resolve);
    }
    else {
      console.debug('found scope.cy');
      deferred.resolve();
    }
    //
    return deferred.promise;
  }

  //--------------------------------------
  // PRIVATE CYTO-LAYOUT FUNCTIONS
  //--------------------------------------

  /**
   *
   */
  function runLayoutRegardlessOfAutoLayoutSettings(layoutName) {
    console.debug('runLayoutRegardlessOfAutoLayoutSettings', layoutName);
    $scope.cy.layout(relationalDiagramLayoutService[layoutName]);
  }

  /**
   * Run the currently configured layout.
   * If layout mode not 'auto', do nothing.
   */
  function runLayout(optionalLayoutNameOverride) {
    //
    console.debug("runLayout");
    //
    var deferred = $q.defer();
    //
    if (rdConfigModel.getLayoutMode() === 'auto') {
      $scope.cy.layout(relationalDiagramLayoutService[optionalLayoutNameOverride || rdConfigModel.getLayoutName()]);
      // Run a callback as soon as the graph becomes ready (i.e. data loaded and initial layout completed).
      $scope.cy.ready(function () {
        deferred.resolve();
      });
    } else {
      // If not 'auto', just resolve the promise.
      deferred.resolve();
    }
    return deferred.promise;
  }

  /**
   *
   */
  function runLayoutAndRememberNodePositions() {
    console.debug('runLayoutAndRememberNodePositions')
    //
    runLayout()
    // When layout is done running ...
      .then(function () {
        // ... remember post-layout node positions in our config.
        rememberNodePositions();
      });
  }

  //--------------------------------------
  // PRIVATE TRANSFORM FUNCTIONS
  //--------------------------------------

  /**
   * Transform our entity nodes into cyto elements.
   */
  function toCytoNodeElements(entities) {
    return _.map(entities, toCytoNodeElement);
  }

  /**
   * Transform an entity node into cyto element.
   */
  function toCytoNodeElement(entity) {
    //
    //console.log("toCytoNodeElement");
    //
    // Refresh the entity to get the correct Lifecycle State.
    return $scope.getEntity(entity.bn)
    // When our freshEntity returns ... use this callback to make a cyto node from it.
      .then(function (freshEntity) {
          entity.lifecycleState = freshEntity.lifecycleState
          return relationalDiagramCytoService
            .toCytoNode(entity, rdConfigModel.getConfig())
        }
      );
  }

  /**
   *
   */
  function transformGraphDataIntoCyInstance() {
    //
    //console.log('transformGraphData');
    //
    // Ensure we have scoped data.
    assertHasData();
    // Ensure we've instanced cytoscape to $scope.cy.
    return ensureCyInstance()
      .then(function () {
        // Then let's populate cy from graphData ...
        var promiseElements = toCytoNodeElements($scope.graph.nodes);
        return $q.all(promiseElements)
        // Produced cytoNodes from graphNodes ...
          .then(function (cytoNodes) {
            // Apply our "remembered" state.
            //relationalDiagramCytoService.applyConfiguration(rdConfigModel.getConfig(), cytoNodes);
            // Obtain cytoscape edge elements.
            var cytoEdges = relationalDiagramCytoService.toCytoEdges($scope.graph.edges);
            //
            relationalDiagramCytoService.resetCyInstance($scope.cy, cytoNodes, cytoEdges, rdConfigModel.getConfig());
            //
            runLayoutAndRememberNodePositions();
          });
      });
  }

  //--------------------------------------
  // PRIVATE READ/WRITE FUNCTIONS
  //--------------------------------------

  /**
   *
   */
  const graphDataHandler = function (graphData) {
    //
    setGraphData(graphData);
    $timeout(function () {
      transformGraphDataIntoCyInstance();
    });
  };

  /**
   * We blew up. Reset scope state appropriately.
   */
  const loadGraphDataErrorHandler = function (err) {
    //
    $scope.hasData = false;
    $scope.loading = false;
    $scope.requestFailed = true;
    //
    console.error("Error in relational diagram.", err)
  }

  /**
   *
   */
  const doneLoadingHandler = function () {
    // Finally, let watchers know we're done!
    $scope.loading = false;
    $scope.reloading = false;
    // BARO-18857
    relationalDiagramCytoService.resizeCyWindow($scope.cy);
    toggleShowCytoContainer();
  };

  /**
   * @param worksheetBn
   * @param graphDataHandler What should we do with your graphData results?
   */
  function loadGraphData(worksheetBn, graphDataHandler) {
    //
    console.debug("loadGraphData");
    //
    var deferred = $q.defer();
    //
    relationalDiagramGraphDataService.getGraphDataForWorkspaceBn(worksheetBn, rdConfigModel.isShowRollUp())
      .then(graphDataHandler, loadGraphDataErrorHandler)
      .then(function () {
        deferred.resolve();
      });
    //
    return deferred.promise;
  }

  /**
   * @param worksheetBn
   * @param graphDataHandler What should we do with your graphData results?
   */
  function loadWorksheetAndGraphData(worksheetBn, graphDataHandler) {
    console.debug("loadWorksheetAndGraphData");

    var deferred = $q.defer();
    //
    rdConfigModel.loadWorksheetForBn(worksheetBn)
    // Now the rdConfigModel holds the worksheetEntity ...
      .then(function (worksheetEntity) {
        loadGraphData(worksheetBn, graphDataHandler)
        // Now scope hold graphData (probably, depending on your graphDataHandler) ...
          .then(function () {
            deferred.resolve();
          });
      });
    //
    return deferred.promise;
  }

  /**
   * Remember the new "real" nodePositions.
   */
  function rememberNodePositions() {
    //
    console.debug("rememberNodePositions");
    //
    // Transfer node positions from cy into our config.
    var myNodePositions = relationalDiagramCytoService.toNodePositions($scope.cy);
    // Update our config.
    rdConfigModel.setNodePositions(myNodePositions);
    // Persist our config (and do not broadcast event).
    return rdConfigModel.writeWorksheetConfig(false);
  }

  /**
   * Get the latest GRAPH data, populate cyto, and re-run layout.
   */
  function reloadGraph() {
    //
    console.debug('reloadGraph');
    //
    $scope.loading = false;
    $scope.reloading = true;
    clearGraphData();
    toggleShowCytoContainer();
    //
    return loadGraphData(getWorksheetBn(), graphDataHandler)
      .then(doneLoadingHandler);
  }

  /**
   * Get the latest data, populate cyto, and re-run layout.
   */
  function loadOrReload(isLoading, isReloading) {
    $scope.loading = isLoading;
    $scope.reloading = isReloading;
    toggleShowCytoContainer();
    return loadWorksheetAndGraphData(getWorksheetBn(), graphDataHandler)
      .then(doneLoadingHandler);
  }

  /**
   * First time! Get the latest data, populate cyto, and run layout.
   */
  function initEverything() {
    //
    console.debug('initEverything');
    //
    $scope.loading = true;
    $scope.reloading = false;
    //
    // Load the cytoscape library.
    relationalDiagramCytoService.loadCytoLib()
    // Then load our worksheet and graph.
      .then(function (r) {
        return loadOrReload(true, false);
      });
  }

  //--------------------------------------
  // PUBLIC FUNCTIONS
  //--------------------------------------

  /**
   * @param bn
   */
  $scope.getEntity = function (bn) {
    return entityService.getBasicInfo(bn)
      .then(function (data) {
        return data.data;
      });
  };

  //--------------------------------------------------
  // EVENT HANDLERS (CYTOSCAPE)
  //--------------------------------------------------

  /**
   *
   */
  function addCyListeners(cy) {

    /**
     * When a grab is completed (a node is finished being moved).
     */
    cy.on('free', function (evt) {
      //
      //console.log("cy free");
      //
      $rootScope.$broadcast('relationalDiagramNodeMoved');
    });

    /**
     *
     */
    cy.on('tap', function (event) {
      //
      //console.log("cy tap");
      //
      var evtTarget = event.cyTarget;
      // Tap on background, target will be cy itself
      if (evtTarget === cy) {
        relationalDiagramCytoService.clearHighlights($scope.cy);
        $rootScope.$broadcast('relationalDiagramBackgroundClicked');
      }
      else if (evtTarget.group() === 'nodes') {
        var node = evtTarget;
        relationalDiagramCytoService.clearHighlights($scope.cy);
        node.addClass('highlight');
        node.connectedEdges().addClass('highlight');
        if (!node.hasClass("group")) {
          $rootScope.$broadcast('relationalDiagramNodeClicked', node);
        } else {
          $rootScope.$broadcast('relationalDiagramGroupClicked', node);
        }
      }
      else if (evtTarget.group() === 'edges') {
        var edge = evtTarget;
        relationalDiagramCytoService.clearHighlights($scope.cy);
        edge.addClass('highlight');
        edge.connectedNodes().addClass('highlight');
        $rootScope.$broadcast('relationalDiagramEdgeClicked', edge);
      }
    });

    /**
     *
     */
    cy.on('tapdragover', 'node', function (evt, other) {
      //
      //console.log("cy tapdragover");
      //
      var hoveredNode = evt.cyTarget;
      // Place tooltip to the right of the hovered node
      var bbox = hoveredNode.renderedBoundingBox({includeLabels: false});
      var top = bbox.y1 + (bbox.h / 2) - 25;
      var left = bbox.x2;
      $scope.tooltip.position = {top: top, left: left};
      // Add content from node data
      $scope.tooltip.data = hoveredNode.data().entity;
      $scope.tooltip.data.entityType = bitConstants.getEntityTypeForBnCode(
        utilService.getBnCode($scope.tooltip.data.bn)).displayName;
      $timeout(function () {
        $scope.tooltip.active = true;
      });
    });

    /**
     *
     */
    cy.on('tapdragout', 'node', function (evt) {
      //
      //console.log("cy tapdragout");
      //
      $timeout(function () {
        $scope.tooltip.active = false;
      });
    });

    /**
     *
     */
    cy.on('zoom', function (event) {
      //
      //console.log("cy zoom");
      //
      $timeout(function () {
        $scope.tooltip.active = false;
      });
    });
  }

  //--------------------------------------------------
  // EVENT HANDLERS (WATCH)
  //--------------------------------------------------

  /**
   * Trigger a cyto re-draw whenever the subheader opens or closes.
   * Timing is sensitive, dependent on CSS animations completing.
   */
  $scope.$watch('subheaderState.active', function () {
    //
    //console.log("changed: subheaderState.active");
    //
    relationalDiagramCytoService.resizeCyWindow($scope.cy);
  });

  //--------------------------------------------------
  // EVENT HANDLERS (BROADCAST)
  //--------------------------------------------------

  /**
   * Broadcast when adds/removes are finished indexing.
   */
  $scope.$on('loadSection', function (event, sectionBn) {
    //
    console.debug("DATA CHANGED! loadSection");
    //
    if (sectionBn === 'WORKSHEET') loadOrReload(false, true);
  });

  /**
   * Broadcast from the data-service.
   */
  $scope.$on('cyResizeRequest', function () {
    //
    console.debug("cyResizeRequest");
    //
    relationalDiagramCytoService.resizeCyWindow($scope.cy);
  });

  /**
   * Broadcast from the subheader-controller.
   */
  $scope.$on('worksheetConfigSaveRequested', function ($event) {
    //
    console.debug("worksheetConfigSaveRequested");
    //
    rememberNodePositions()
      .then(function () {
        $rootScope.$broadcast('worksheetConfigSaveCompleted');
      });
  });

  /**
   * Broadcast from the subheader-controller.
   */
  $scope.$on('relationalDiagramLayoutRequested', function ($event, layoutName) {
    //
    console.debug("relationalDiagramLayoutRequested");
    //
    runLayoutRegardlessOfAutoLayoutSettings(layoutName);
  });

  /**
   * Broadcast from the subheader-controller.
   */
  $scope.$on('relationalDiagramDisplayedUpdated', function ($event) {
    //
    console.debug("relationalDiagramDisplayedUpdated");
    // Don't bonk our Worksheet config.
    reloadGraph();
  });

  /**
   * Broadcast from the subheader-controller.
   */
  $scope.$on('relationalDiagramOriginalLayoutRequested', function ($event) {
    //
    console.debug("relationalDiagramOriginalLayoutRequested");
    //
    initEverything();
  });

  /**
   * Broadcast from the subheader-controller.
   */
  $scope.$on('relationalDiagramImageDownloadRequested', function ($event) {
    //console.log("relationalDiagramImageDownloadRequested");
    var worksheetName = rdConfigModel.getWorksheetName();
    downloadService.download($scope.cy.png({bg: '#ffffff'}), worksheetName, 'image/png');
  });

  /**
   * Broadcast from the subheader-controller.
   */
  $scope.$on('relationalDiagramAutoungrabifyChangeRequested', function ($event, enabled) {
    //console.log("relationalDiagramAutoungrabifyChangeRequested");
    if ($scope.cy) {
      $scope.cy.autoungrabify(enabled);
    }
  });

  /**
   * TODO Is this never broadcast?
   */
  $scope.$on('relationalDiagramSidebarClosed', function (event) {
    console.debug("relationalDiagramSidebarClosed");
    relationalDiagramCytoService.clearHighlights($scope.cy);
  });

  /**
   * TODO Is this never broadcast?
   */
  $scope.$on('worksheetAssocAddedOrUpdatedFailed', function (event, data) {
    console.debug("worksheetAssocAddedOrUpdatedFailed");
    var entityTypeName = bitConstants.getEntityTypeDisplayNameForTypeCode(data.entityType, true);
    var message = String.format("Unable to {0} to this Worksheet", entityTypeName);
    alertService.addErrorAlert(message);
  });

  //--------------------------------------------------
  // DO IT!
  //--------------------------------------------------

  initEverything();
}
