angular.module('barometerApp.superSelector')
    .directive('superSelector', ['searchService', 'utilService', '$rootScope','$timeout','urlService', function(searchService, utilService, $rootScope, $timeout) {
        return {
            scope: {
              sourceBn: '=',

              // This MUST be here for proper two-way binding of searchString between model and view
              result: '=',

              targetEntityTypeCode: '=',
              checkForAssociations: '=',
              relationshipType: '=',
              filter: '=?',
              templateType: '@',

              // This MUST be here at least for quickAdd functionality to work properly.
              // It is inherited by child scopes and used there
              entityType: '=',
            },
            templateUrl: '/b/js/src/bit.ng/super-selector/partials/super-selector.html',
            controller: function($scope) {
                $scope.entities = function (searchTerm, iconClass) {
                    if (!$scope.filter) {
                      $scope.filter = { params: [] };
                    }
                    $scope.filter.searchString = searchTerm;

                    return searchService.searchEntity(
                      null,
                      $scope.sourceBn,
                      $scope.targetEntityTypeCode,
                      $scope.checkForAssociations,
                      $scope.relationshipType,
                      JSON.stringify($scope.filter))
                        .then(function (response) {
                            _.each(response.data.entities, function (result) {
                                result.iconClass = iconClass;
                            });
                        return response.data.entities;
                    });
                };
                $scope.handleDropdown = function(displayMe) {
                    $timeout(function() {
                        $rootScope.$broadcast("SearchTermSelected",displayMe);
                    }, 200);
                }
            },
            // give focus to the field once it's rendered
            link: function($scope, $element) {
                $timeout(function(){
                    $element.find('input').trigger('focus');
                });
            }
        }
    }]);
