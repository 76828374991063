/**
 * Created by rbiggs on 3/3/17.
 */
angular.module('barometerApp.remediationForm')
  .directive('remediationForm', ['remediationFormService', 'utilService', '$rootScope', 'styleService', 'urlService', 'redux', 'entityService', 'contentStatusService',
    function (remediationFormService, utilService, $rootScope, styleService, urlService, redux, entityService, contentStatusService) {
      return {
        templateUrl: '/b/js/src/bit.ng/remediation-form/partials/remediation-form.html',
        restrict: 'E',
        scope: {
          remediationFormBn: '@',
          entityBn: '@',
          entityName: '@',
          canEdit: '='
        },
        link: function (scope) {
          let { store, actions } = redux;
          scope.loading = true;
          scope.dataDictFieldResponses = [];
          scope.hasErrorMessage = false;
          scope.errorMessage = "";
          scope.editing = false;
          scope.isOpen = true;
          scope.hasWorkflow = false;

          // Change status
          scope.changesSubmittedCount = 0;
          scope.changesSavedCount = 0;
          scope.proposedEditsCount = 0;

          //============
          //Define needed functions
          //=========
          scope.indexingInProgressCount = function () {
            // Currently, no Task changes trigger indexing
            return 0;
          };
          scope.slowIndexingCount = function () {
            // Currently, no Task changes trigger indexing
            return 0;
          };
          scope.refreshViews = function(){
            scope.loadForm(scope.remediationFormBn, scope.entityBn);
          };

          scope.toggleEdit = function () {
            scope.editing = !scope.editing;
          };

          scope.isListDrivenField = function (field) {
            return _.contains(['CHECKBOX', 'SINGLE_SELECT', 'MULTI_SELECT'], field.displayType);
          };

          scope.loadForm = function (formBn, entityBn) {
            scope.dataDictFieldResponses = [];
            scope.loading = true;
            scope.editing = false;
            scope.isOpen = true;

            remediationFormService.getValuesForRemediationFormByEntityBn(formBn, entityBn).then(function (data) {
              scope.remediationForm = data.data;
              for (var i = 0; i < scope.remediationForm.dataDictionaryFields.length; i++) {
                scope.dataDictFieldResponses[i] = {};
              }
              scope.loading = false;
            });

          };

          scope.cancel = function () {
            scope.editing = false;
            scope.loadForm(scope.remediationFormBn, scope.entityBn);
          };

          scope.validateDataDictFieldResponses = function () {
            var dataDictFieldSubmit = [];
            for (var i = 0; i < scope.dataDictFieldResponses.length; i++) {
              dataDictFieldSubmit[i] = _.clone(scope.dataDictFieldResponses[i], true);
              switch (scope.dataDictFieldResponses[i].propertyType) {
                case 'LOCAL_DATE':
                  if (dataDictFieldSubmit[i].value != null) {
                    dataDictFieldSubmit[i].value = utilService.convertToISOUTCLocalDate(dataDictFieldSubmit[i].value);
                  }
                  break;
              }
            }
            return dataDictFieldSubmit;
          };

          scope.submit = function () {
            if (scope.RemForm.$invalid) {
              return;
            }
            var dataDictionaryFieldSubmit = scope.validateDataDictFieldResponses();
            var updateFormJson = {
              entityBn: scope.entityBn,
              remediationFormBn: scope.remediationFormBn,
              dataDictionaryFields: dataDictionaryFieldSubmit,
              customFields: []
            };

            _.forEach(scope.remediationForm.customFields, function (field) {
              //send fieldOptionBn OR a value
              if (utilService.isControlLevelEditable(field)) {
                if (scope.isListDrivenField(field)) {
                  var values = [];
                  _.forEach(field.values, function (optionBn) {
                    values.push({fieldOptionBn: optionBn})
                  });
                  updateFormJson.customFields.push({fieldBn: field.bn, values: values});
                } else {
                  updateFormJson.customFields.push({fieldBn: field.bn, values: [{value: field.values[0]}]});
                }
              }
            });

            scope.changesSubmittedCount ++;
            remediationFormService.updateFieldsForEntityAndForm(scope.remediationFormBn, scope.entityBn, updateFormJson).then(function (results) {
              scope.hasErrorMessage = false;
              scope.errorMessage = "";
              scope.editing = false;
              if (results.data[0] && results.data[0].contributedByBn) {
                scope.proposedEditsCount ++;
                scope.loadForm(scope.remediationFormBn, scope.entityBn);
              } else {
                scope.changesSavedCount ++;
                scope.loadForm(scope.remediationFormBn, scope.entityBn);
              }
            }, function (data) {
              scope.hasErrorMessage = true;
              var msgString = 'Unable to edit entity';
              if (data.status === 409 && data.data.errorMessages) {
                msgString += ': ' + data.data.errorMessages;
              }
              scope.errorMessage = msgString;
            });
          };

          scope.getEntityLink = function (entityBn) {
            return urlService.getUrlForBnCode(entityBn, utilService.getBnCodeFromTypeCode(utilService.getEntityTypeCode(entityBn)));
          };

          scope.getEntityIcon = function (entityBn) {
            return styleService.getIconClassForEntityTypeCode(utilService.getEntityTypeCode(entityBn));
          };

          //===========
          //Do work
          //===========
          remediationFormService.getValuesForRemediationFormByEntityBn(scope.remediationFormBn, scope.entityBn).then(function (data) {
            scope.remediationForm = data.data;
            for (var i = 0; i < scope.remediationForm.dataDictionaryFields.length; i++) {
              scope.dataDictFieldResponses[i] = {};
            }
            scope.loading = false;
          });

          scope.$watch('editing', function (newVal, oldVal) {
            if (newVal !== oldVal) {
              scope.$emit('taskFormEditToggled', newVal);
            }
          });
        }
      }
    }])
  .directive('remediationFormCustomFieldDisplay', [
    function () {
      return {
        templateUrl: '/b/js/src/bit.ng/remediation-form/partials/remediation-form-custom-field-display.html',
        restrict: 'E',
        scope: {
          field: '=',
          editing: '='
        },
        link: function (scope) {
          if (!scope.field.values) {
            scope.field.values = [];
          }
        }
      }
    }])
  .directive('remediationFormDataDictDisplay', ['optionListService', 'entityService', 'utilService', '$q', 'redux',
    function (optionListService, entityService, utilService, $q, redux) {
      return {
        templateUrl: '/b/js/src/bit.ng/remediation-form/partials/remediation-form-data-dict-display.html',
        restrict: 'E',
        scope: {
          entityBn: '=',
          field: '=',
          editing: '=',
          fieldResponse: '='
        },
        link: function (scope) {
          let {store, actions} = redux;
          let scopeId = scope.$id;
          scope.falseVal = false;
          scope.dateFormat = utilService.getDateFormatForBootstrapDatepicker();
          scope.fieldResponse = {
            bn: scope.field.bn,
            propertyType: scope.field.propertyType
          };

          store.dispatch(
            actions.setFieldWorkflowDisplayFieldBn(scopeId, scope.field.bn)
          );
          store.dispatch(
            actions.setFieldWorkflowDisplayEntityBn(scopeId, scope.entityBn)
          );

          if (scope.field.propertyKey == 'contentStatus' || scope.field.propertyKey == 'contentNodeStatus') {
            scope.field.propertyType = 'CONTENT_STATUS';
          }

          var value;
          switch (scope.field.propertyType) {
            case 'ASSOCIATION':
              entityService.getBasicInfo(scope.entityBn).then(function (results) {
                scope.entity = results.data;
              });
              value = null;
              break;
            case 'BOOLEAN':
              value = scope.field.value;
              break;
            case 'DATE':
              value = scope.field.value;
              break;
            case 'DECIMAL':
              value = scope.field.value;
              break;
            case 'ENUM':
              value = scope.field.value.id;
              break;
            case 'INTEGER':
              value = scope.field.value;
              break;
            case 'LOCAL_DATE':
              if (scope.field.value != null) {
                scope.field.value = utilService.convertToLocalDate(scope.field.value);
              }
              value = scope.field.value;
              break;
            case 'NOT_APPLICABLE':
              value = null;
              break;
            case 'NUMBERED':
              //TODO this assumes datalist
              //TODO maybe find a way to generisize

              populateNumberedValue(scope.field).then(function (results) {
                value = results;

                if (scope.field.value.dataList.dataListType === 'connectionType') {
                  if (!scope.entity || !scope.entity.basicInfo) {
                    entityService.getBasicInfo(scope.entityBn, false).then(function (data) {
                      scope.entity = {
                        basicInfo: data.data
                      };
                      setupNonOptionList(scope.entity.basicInfo.connectionTypeChoices, "connectionType");
                    });
                  } else {
                    setupNonOptionList(scope.entity.basicInfo.connectionTypeChoices, "connectionType");
                  }
                } else if (scope.field.value.dataList.dataListType === 'connectionPattern') {
                  if (!scope.entity || !scope.entity.basicInfo) {
                    entityService.getBasicInfo(scope.entityBn, false).then(function (data) {
                      scope.entity = {
                        basicInfo: data.data
                      };
                      setupNonOptionList(scope.entity.basicInfo.connectionPatternChoices, "connectionPattern");
                    });
                  } else {
                    setupNonOptionList(scope.entity.basicInfo.connectionPatternChoices, "connectionPattern");
                  }
                } else {
                  //load option list values
                  var newVal = _.cloneDeep(value);
                  optionListService.getOptionListChoices([{dataListType: value.dataList.dataListType}]).then(function (results) {
                    scope.optionListChoices = {};
                    _.forEach(results.data, function (item) {
                      if (!scope.optionListChoices[item.dataListType]) {
                        scope.optionListChoices[item.dataListType] = []
                      }
                      scope.optionListChoices[item.dataListType].push(item);
                    });
                    scope.fieldResponse.value = value;
                  });
                }
              });
              break;
            case 'STRING':
              if (scope.field.value == null) {
                value = "";
                scope.field.value = "";
              }
              value = scope.field.value;
              break;
            case 'CONTENT_STATUS':
              entityService.getBasicInfo(scope.entityBn).then(function (results) {
                scope.entity = results.data;
              });
              value = scope.field.value.id;
              break;

          }
          scope.fieldResponse.value = value;

          function setupNonOptionList(choices, name) {
            scope.optionListChoices = {};
            _.forEach(choices, function (choice, index) {
              var item = {
                bn: choice.bn,
                displayableName: choice.name,
                order: index
              };
              if (!scope.optionListChoices[name]) {
                scope.optionListChoices[name] = [];
              }
              scope.optionListChoices[name].push(item)
            });

            scope.fieldResponse.value = {
              bn: scope.field.value.bn
            }
          }

          function populateNumberedValue(field) {
            var value = {
              bn: null,
              dataList: null
            };

            var deferred = $q.defer();

            if (field.value) {
              value.bn = field.value.bn;
            } else {
              scope.field.value = {};
            }
            if (field.propertyKey === 'connectionType') {
              scope.field.value.dataList = {
                dataListType: 'connectionType'
              };
              return $q.when(value);
            } else if (field.propertyKey === 'connectionPattern') {
              scope.field.value.dataList = {
                dataListType: 'connectionPattern'
              };
              return $q.when(value);
            } else {
              optionListService.getOptionList([{dataListType: field.dataListType}]).then(function (results) {
                if (results.data.length == 1) {

                  value.dataList = results.data[0];
                  scope.field.value.dataList = results.data[0];
                  deferred.resolve(value);
                } else {
                  console.error("error in response to get option list");
                  deferred.error();
                }
              });
            }

            return deferred.promise;
          }
        }
      }
    }]);
