angular.module('barometerApp.tenant')
  .service('tenantService', ['$http', '$rootScope', 'alertService', 'utilService', 'insightTypeService', 'redux',
    function($http, $rootScope, alertService, utilService, insightTypeService, redux) {

      // Calls to the server.
      const getCurrentTenantsAuthorizedCatalogs = function() {
        return window.tenantAuthorizedCatalogs || [];
      };

      const getCurrentTenantsEnterpriseCatalogs = function () {
        return getCurrentTenantsAuthorizedCatalogs()
          .filter(c => c.entityTypeCode !== 'ARL')
          .filter(c => c.entityTypeCode !== 'IST')
          .filter(c => c.entityTypeCode !== 'TEP')
          .filter(c => c.entityTypeCode !== 'TOP');
      };
      const getCurrentTenantsInsightCatalogs = function () {
        return getCurrentTenantsAuthorizedCatalogs()
          .filter(c => c.entityTypeCode === 'IST');
      };
      const getCurrentTenantsRoleCatalogs = function () {
        return getCurrentTenantsAuthorizedCatalogs()
          .filter(c => c.entityTypeCode === 'ARL');
      };

      const isAnAuthorizedEntityTypeCode = function(entityTypeCode) {
        return (getCurrentTenantsAuthorizedCatalogs()
          .findIndex(c => c.entityTypeCode === entityTypeCode) > -1);
      };
      const isAnAuthorizedEntityBn = function(bn) {
        return (getCurrentTenantsAuthorizedCatalogs()
          .findIndex(c => c.bn === bn) > -1);
      };

      const getCurrentTenantBn = () => {
        const tenantBnHolder = document.getElementById('tenantBnHolder');
        return tenantBnHolder.innerText;
      };

      const getCurrentTenantsNavigationItems = () => {
        const tenantBn = getCurrentTenantBn();
        return $http({ method: 'GET', url: '/b/api/tenant/settings/navigation/' + tenantBn })
          .then(response => {
            return response.data.values;
          })
          .catch(err => {
            console.error(err);
            alertService.addErrorAlert('Error Fetching Navigation Items');
          });

      };

      return {
        getCurrentTenantsAuthorizedCatalogs,
        getCurrentTenantsEnterpriseCatalogs,
        getCurrentTenantsInsightCatalogs,
        getCurrentTenantsRoleCatalogs,
        isAnAuthorizedEntityTypeCode,
        isAnAuthorizedEntityBn,
        getCurrentTenantsNavigationItems,
        getCurrentTenantBn,
      }
    }]);
