angular
  .module('barometerApp.watch')
  .factory('pinDataService', pinDataService);

pinDataService.$inject = ['$http', '$q', 'alertService', 'urlService', 'utilService'];

function pinDataService($http, $q, alertService, urlService, utilService) {

  var GRAPH_URL = urlService.getGraphApiUrl();
  var RELATIONSHIP_TYPE = 'IS_WATCHING';

  return {
    getPin: getPin,
    addPin: addPin,
    removePin: removePin,
    listPinnedBy: listPinnedBy
  };

  /**
   * In order to log into graph service.
   */
  function getAuthToken() {
    var authToken = utilService.readCookie('baroJWTToken');
    if (!authToken) {
      alertService.addErrorAlert('No authentication credential for Task service.');
    }
    return authToken;
  }

  /**
   *
   * @param personBn
   * @param targetBn
   */
  function getPin(personBn, targetBn) {
    //
    var deferred = $q.defer();
    var token = getAuthToken();
    //
    if(!token) {
      return deferred.reject('Authorization token missing');
    } else {
      $http({
        method: 'GET',
        url: GRAPH_URL + 'relationships/' + personBn + '/' + RELATIONSHIP_TYPE + '/' + targetBn,
        headers: {
          'Authorization': 'Bearer ' + token,
          'bit-tenant-bn': utilService.getTenantBn(),
          'Content-Type': 'application/json'
        },
      }).success(function(data) {
        deferred.resolve(data);
      }).error(function(data) {
        var msgString = 'Unable to get pin.';
        if (status === 409 && data.errorMessages) {
          msgString += ': ' + data.errorMessages;
        }
        alertService.addErrorAlert(msgString);
        deferred.reject(data);
      })
    }
    return deferred.promise;
  }

  /**
   *
   * @param personBn
   * @param targetBn
   */
  function addPin(personBn, targetBn) {
    //
    return $http({
      method: 'PUT',
      url: '/b/api/relationship/' + personBn + '/' + RELATIONSHIP_TYPE + '/' + targetBn
    }).success(function (data, status, headers, config) {
      var msgString = 'Added pin.';
      alertService.addSuccessAlert(msgString);
    }).error(function (data, status, headers, config) {
      var msgString = 'Unable to add pin.';
      if (status === 409 && data.errorMessages) {
        msgString += ': ' + data.errorMessages;
      }
      alertService.addErrorAlert(msgString);
    });
  }

  /**
   *
   * @param personBn
   * @param targetBn
   */
  function removePin(personBn, targetBn) {
    //
    return $http({
      method: 'DELETE',
      url: '/b/api/relationship/' + personBn + '/' + RELATIONSHIP_TYPE + '/' + targetBn
    }).success(function (data, status, headers, config) {
      var msgString = 'Removed pin.';
      alertService.addSuccessAlert(msgString);
    }).error(function (data, status, headers, config) {
      var msgString = 'Unable to remove pin.';
      if (status === 409 && data.errorMessages) {
        msgString += ': ' + data.errorMessages;
      }
      alertService.addErrorAlert(msgString);
    });
  }

  /**
   * @param entityBn
   * @param currentPage
   * @param sortProperty
   * @param sortOrder
   * @returns {*}
   */
  function listPinnedBy(entityBn, page, sortProperty, sortOrder) {
    //
    var params = {};
    //
    params.perspectiveBns = [entityBn];
    params.relatedProperties = ["bn", "fullName"];
    params.filterBy = {
      "relationshipTypes": ["IS_WATCHING"]
    };
    params.firstRecord = (10 * (page - 1)) + 1;
    params.numberToReturn = 10;
    params.sortBy = {fieldName: sortProperty, sortOrder: sortOrder};
    //
    var deferred = $q.defer();
    var token = getAuthToken();
    if (!token) {
      return deferred.reject('Authorization token missing');
    }
    //
    $http({
      method: 'POST',
      url: GRAPH_URL + 'report/relatedEntities',
      headers: {
        'Authorization': 'Bearer ' + token,
        'bit-tenant-bn': utilService.getTenantBn(),
        'Content-Type': 'application/json'
      },
      data: params
    }).success(function (data) {
      deferred.resolve(data);
    }).error(function (data) {
      var msgString = 'Unable to get pins.';
      if (status === 409 && data.errorMessages) {
        msgString += ': ' + data.errorMessages;
      }
      alertService.addErrorAlert(msgString);
      deferred.reject(data);
    })
    return deferred.promise;
  }
}