angular.module('barometerApp.entity')
  .controller('EntityCtrl', ['$rootScope', '$scope', 'pageService', 'alertService', 'urlService', 'utilService', 'entityService', 'queryKeyService', '$window', 'insightTypeService',
    function ($rootScope, $scope, pageService, alertService, urlService, utilService, entityService, queryKeyService, $window, insightTypeService) {

      // Make sure to set isEntityPage flag BEFORE getting the entityBn
      // urlService.getEntityBn() expects isEntityPage == true
      pageService.setIsEntityPage(true);
      var entityBn = urlService.getEntityBn();


      // We'll assign the model once the basic info loads...
      $scope.entityModel = {};
      $scope.loading = true;
      $scope.containsInsightHelp = false;
      $scope.showInsightHelp = false;
      $scope.typeName = null;

      $scope.toggleInsightHelp = function () {
        $scope.showInsightHelp = !$scope.showInsightHelp;
      };

      $scope.domainPillClicked = function () {
        queryKeyService.getQueryKeyForDomainPivot($scope.entityModel.entityDiscriminator, $scope.entityModel.domain.bn).then(function (results) {
          $window.location.href = urlService.getBaseUrl() + $scope.browseUrl + "/querykey" + results.data.queryKey;
        });
      };

      $scope.typePillClicked = function () {
        queryKeyService.getQueryKeyForTypePivot($scope.entityModel.entityDiscriminator, $scope.typeBn).then(function (results) {
          $window.location.href = urlService.getBaseUrl()
            + urlService.getSingleTypedCollectionPageForEntityType($scope.entityModel.entityDiscriminator)
            + "/querykey" + results.data.queryKey;
        });
      };

      $scope.getCurrentBn = function () {
        return $scope.entityModel.entityBn;
      };

      // Prepare the Relationship Property info.
      // redux.store.dispatch(redux.actions.setRelationshipEditorBasicProperties(urlService.getEntityBn()));

      $scope.containsInsightHelp = utilService.isBn(PageIdentifierToEntityTypeCode[urlService.getPageName()]);

      entityService.getBasicInfo(entityBn, false, true).then(function (data) {
        $rootScope.$broadcast('basicInfoLoaded', data.data);
      });

      // Using an event here, because this will get called on updates from basic info edit, as well as initial load
      $scope.$on('basicInfoLoaded', function (event, basicInfo) {
        $scope.entityModel = basicInfo;

        entityService.setCanEdit($scope.entityModel.canEdit);
        entityService.setCanDelete($scope.entityModel.canDelete);

        // TODO what's up with this?
        $scope.entityModel.canEditCustomFields = $scope.entityModel.canDelete;

        $scope.isServiceAccount = $scope.entityModel.accountType && $scope.entityModel.accountType === 'SER';

        // Update the page title -- used by sub-sections
        pageService.setTitle($scope.entityModel.displayableName);

        // Pill setup
        $scope.browseUrl = urlService.getSingleTypedCollectionPageForEntityType($scope.entityModel.entityDiscriminator);
        $scope.browseLabel = urlService.isEnterprisePage($scope.entityModel.entityDiscriminator) ? "Catalog" : "Insight";

        $scope.browseValue = EntityTypes[$scope.entityModel.entityDiscriminator].displayName;
        $scope.entityNamePlural = EntityTypes[$scope.entityModel.entityDiscriminator].displayNamePlural;
        $scope.domainName = $scope.entityModel.domain ? $scope.entityModel.domain.name : null;
        if($scope.entityModel.hasOwnProperty('insightType')) {
          insightTypeService.getInsightTypes($scope.entityModel.insightType.bn).then(insights => {
            const insight = insights.data[0];
            $scope.collectionsBrowsePageUrl = 'insightbrowse?typeBn=' + insight.bn;
            $scope.workheetTypeName = insight.name;
            $scope.entityNamePlural = insight.pluralName;
          });
        }
        if (TYPE_DATA_DICT_PROPERTY_KEYS.hasOwnProperty($scope.entityModel.entityDiscriminator)) {
          $scope.typeLabel = $scope.entityModel[TYPE_DATA_DICT_PROPERTY_KEYS[$scope.entityModel.entityDiscriminator]].label;
          $scope.typeName = $scope.entityModel[TYPE_DATA_DICT_PROPERTY_KEYS[$scope.entityModel.entityDiscriminator]].name;
          $scope.typeBn = $scope.entityModel[TYPE_DATA_DICT_PROPERTY_KEYS[$scope.entityModel.entityDiscriminator]].bn;
        }

        $scope.loading = false;
      });

    }])
  .controller('EntityNavCtrl', ['$rootScope', '$scope', 'layoutService', 'searchService', 'urlService', 'tableService', '$timeout', 'priorityEntitiesService','tenantService', '$window', 'redux',
    function ($rootScope, $scope, layoutService, searchService, urlService, tableService, $timeout, priorityEntitiesService, tenantService,  $window, redux) {

      $scope.entityNavModel = {
        counts: {},
        selectedSectionBn: null
      };

      $scope.refreshCounts = function () {
        const sourceEntityTypeCode = urlService.getEntityTypeCodeOfCurrentPage();
        const sourceBnCode = EntityTypes[sourceEntityTypeCode].bnCode;
        const countAssocs = layoutService.getCurrentLayout().countAssocs;
        const countsPromise = tableService.getCountsForAssociations({
          sourceBnCode: sourceBnCode,
          sourceBn: urlService.getEntityBn(),
          queryKey: urlService.getQueryKey(),
          targets: countAssocs
        });
        countsPromise.then(function (data) {
          $scope.entityNavModel.counts = data.data;
        });
      };

      $scope.goToSection = function (sectionBn) {
        $rootScope.$broadcast('leftTabSelected', {sectionBn: sectionBn});

        redux.store.dispatch(redux.actions.setIsOpen(false));
        redux.store.dispatch(redux.actions.clearUnsaved());
        $scope.entityNavModel.selectedSectionBn = sectionBn;
        $window.scrollTo(0, 0);
      };

      $scope.getSectionCount = function(section) {
        return $scope.entityNavModel.counts[section.bn] ? $scope.entityNavModel.counts[section.bn] : $scope.entityNavModel.counts[section.assocCode]
      }

      $scope.isCollapsed = function (section) {
        return (
          !$scope.entityNavModel.expanded                               // Nav must be collapsed
          && $scope.getSectionCount(section) === 0             // Section must be empty
          && !priorityEntitiesService.isPriorityEntityType(section.bn)  // Section must NOT be priority (section.bn is a typecode)
        );
      };

      $scope.hasHiddenSection = function () {
        var hasHiddenSection = false;
        _.each($scope.entityNavModel.counts, function (count, bn) {
          if (count === 0 && !priorityEntitiesService.isPriorityEntityType(bn)) {
            hasHiddenSection = true;
            return false;
          }
        });
        return hasHiddenSection;
      };


      $scope.$watch(layoutService.getCurrentLayout, function (newValue, oldValue, $scope) {
        if (newValue) {
          $scope.entityNavModel.sections = newValue.sections
            .filter(s => tenantService.isAnAuthorizedEntityTypeCode(s.bn)
              || EntityTypes.EAY.typeCode === s.bn // Allow history to pass unscathed.
              || EntityTypes[s.bn] == null);

          // Select the correct section, trying the url parameter before selecting the first section by default.
          $scope.entityNavModel.selectedSectionBn = tenantService.isAnAuthorizedEntityTypeCode(urlService.getSectionBnFromUrl())
            ? urlService.getSectionBnFromUrl() : $scope.entityNavModel.sections[0].bn;

          // Hydrate nav item data with title and icon details.
          $.each($scope.entityNavModel.sections, function () {
            this.iconClass = SectionTypeToIconAndTitle[this.type][0];
            this.title = SectionTypeToIconAndTitle[this.type][1];
          });
          $scope.refreshCounts();
        }
      });

      $scope.$on('refreshCounts', function (event) {
        $timeout($scope.refreshCounts);
      });
    }])
  .controller('EntityBasicInfoCtrl', ['$rootScope', '$scope', '$timeout', 'entityService', 'pageService', 'utilService', 'optionListService', 'alertService', 'urlService', 'tenantService', 'tableService', 'scorecardService', '$window', '$element',
    function ($rootScope, $scope, $timeout, entityService, pageService, utilService, optionListService, alertService, urlService, tenantService, tableService, scorecardService, $window, $element) {
      $scope.entityBasicInfoModel = {};
      $scope.entityBasicInfoModel.loaded = false;
      $scope.optionListModel = {};

      $scope.addAlias = function () {
        var displayOrder = $scope.entityBasicInfoModel.aliases.length;
        $scope.entityBasicInfoModel.aliases.push({"displayOrder": displayOrder, "editStatusId": "2"});
      };

      $scope.addPhoneNumber = function () {
        $scope.entityBasicInfoModel.phoneNumbers.push({"editStatusId": "2", "type": {"code": "WRK"}});
      };

      $scope.cancelEdit = function () {
        $scope.$emit('editBasicInfoClosed');
      };

      $scope.getLinkedinImportUrl = function () {
        return urlService.getBaseUrl() + 'linkedinsync/reimport';
      };

      $scope.load = function (entityBn, reload) {
        if (!$scope.entityBasicInfoModel.loaded || reload) {

          var entityDataPromise = entityService.getBasicInfo(entityBn);
          entityDataPromise.then(function (data) {
            $scope.entityBasicInfoModel = data.data;
            $scope.entityBasicInfoModel.loaded = true;
            // if entity type is Scorecard, load its bn and catalog type to Redux state
            if (utilService.getBnCodeFromTypeCode($scope.entityBasicInfoModel.entityDiscriminator) === '4A') {
              scorecardService.loadInitialDataToRedux($scope.entityBasicInfoModel.bn, $scope.entityBasicInfoModel.entityType.code);
            }
            //We have two ways of formatting dates, angular-js for normal date display and bs-date-picker for all of the date picker stuff
            //The we updated bs-date-picker which changed the date format that it uses.
            $scope.entityBasicInfoModel.ngDateFormat = utilService.getDateFormatForAnglularDateFilter();
            $scope.entityBasicInfoModel.bsDateFormat = utilService.getDateFormatForBootstrapDatepicker();
            // this is only used by the system entity;
            $scope.entityBasicInfoModel.entity = {
              "bn": $scope.entityBasicInfoModel.bn,
              "typeBn": $scope.entityBasicInfoModel.systemTypeBn
            };
            //adjust date fields
            if ($scope.entityBasicInfoModel.lastAuditDataRefresh) {
              $scope.entityBasicInfoModel.lastAuditDataRefresh = $scope.entityBasicInfoModel.lastAuditDataRefresh === 'Invalid date' ? '' : utilService.convertToLocalDateTime($scope.entityBasicInfoModel.lastAuditDataRefresh);
            }
            if ($scope.entityBasicInfoModel.contentCompletionDate) {
              $scope.entityBasicInfoModel.contentCompletionDate = utilService.convertToLocalDate($scope.entityBasicInfoModel.contentCompletionDate);
            }
            if ($scope.entityBasicInfoModel.startDate) {
              $scope.entityBasicInfoModel.startDate = $scope.entityBasicInfoModel.startDate === 'Invalid date' ? '' : utilService.convertToLocalDate($scope.entityBasicInfoModel.startDate);
            }
            if ($scope.entityBasicInfoModel.endDate) {
              $scope.entityBasicInfoModel.endDate = $scope.entityBasicInfoModel.endDate === 'Invalid date' ? '' : utilService.convertToLocalDate($scope.entityBasicInfoModel.endDate);
            }
            if ($scope.entityBasicInfoModel.releaseDate) {
              $scope.entityBasicInfoModel.releaseDate = utilService.convertToLocalDate($scope.entityBasicInfoModel.releaseDate);
            }
            if ($scope.entityBasicInfoModel.retireDate) {
              $scope.entityBasicInfoModel.retireDate = utilService.convertToLocalDate($scope.entityBasicInfoModel.retireDate);
            }
            if ($scope.entityBasicInfoModel.sunsetDate) {
              $scope.entityBasicInfoModel.sunsetDate = utilService.convertToLocalDate($scope.entityBasicInfoModel.sunsetDate);
            }
            entityService.setCanEdit(data.data.canEdit);
            entityService.setCanDelete(data.data.canDelete);
            entityService.setCanEditCustomFields(data.data.canEditCustomFields);
            $rootScope.$broadcast('basicInfoLoaded', data.data);

            if (!reload) {
              // only add a recent record the first time the entity loads, not each time the basic info is loaded.
              $rootScope.$broadcast('addRecentRecord', data.data);
            }
          });
        }
        //load data-list choices
        if (!$scope.optionListModel.loaded) {
          var entityTypeCodeOfCurrentPage = urlService.getEntityTypeCodeOfCurrentPage();
          var dataListTypes = EntityOptionListTypes[entityTypeCodeOfCurrentPage];
          if (dataListTypes) {
            var promise = optionListService.getOptionListChoices(dataListTypes);
            promise.then(function (results) {
              $scope.optionListModel.data = [];
              _.forEach(results.data, function (item) {
                if (!$scope.optionListModel.data[item.dataListType]) {
                  $scope.optionListModel.data[item.dataListType] = [];
                }
                $scope.optionListModel.data[item.dataListType].push(item);
              });
            });
          }
          $scope.optionListModel.loaded = true;
        }
      };

      $scope.saveBasicInfo = function () {
        var bnCode = utilService.getBnCode($scope.entityBasicInfoModel.bn);
        var saveObj = _.clone($scope.entityBasicInfoModel, true);
        // convert dates to utc
        saveObj = entityService.adjustDateFields(saveObj);
        entityService.updateBasicInfo(saveObj, bnCode).then(function (results) {
          if (results.data[0] && results.data[0].contributedByBn) {
            $rootScope.$broadcast("successAlertMessage", "Your changes have been submitted for review.");
            $rootScope.$broadcast('newProposedEdits');
          } else {
            // tell the sidebar info that the photo has changed
            if ($scope.entityBasicInfoModel.photoChanged) { // boolean flag set by ProfilePhotoUploadCtrl if the image changes
              $rootScope.$broadcast("profilePhotoChanged", $scope.entityBasicInfoModel.bn);
            }
            $scope.entityBasicInfoModel = results.data;
            $rootScope.$broadcast("successAlertMessage", "Your changes have been saved.");
            $rootScope.$broadcast("entityUpdated", results.data);
          }
          $scope.$emit('editBasicInfoClosed');
          $rootScope.$broadcast('refreshTitle', results.data);
        });
      };

      $scope.showDomainChangeWarning = function (entityName, entityNamePlural) {
        if ($scope.entityBasicInfoModel.children > 0) {
          alertService.addAlert({
            type: 'warn',
            message: String.format("Changing the Domain will: Change the Domain for all child {0}", entityNamePlural)
          });
        }
        if ($scope.entityBasicInfoModel.parents > 0) {
          alertService.addAlert({
            type: 'warn',
            message: String.format("Changing the Domain will: Remove any parent {0} and make this a root {1}", entityNamePlural, entityName)
          });
        }
      };

      $scope.$on('load', function (event, viewId) {
        // always reload the content to make sure it is "fresh"
        $scope.load(urlService.getEntityBn(), false);
      });

      $scope.$on('basicInfoLoaded', function (e, basicInfo) {
        $element.find('select[multiple]').select2();
      });

      $scope.$on('reload', function (event, viewId) {
        $scope.load(urlService.getEntityBn(), true);
      });

      $scope.removeFromArray = function (item, arr) {
        var idx = arr.indexOf(item);
        arr.splice(idx, 1);
      }

    }])

  .controller('EntityDetailMarkdownCtrl', ['$scope', 'entityService', 'urlService', '$rootScope',
    function ($scope, entityService, urlService, $rootScope) {
      $scope.detailModel = {};
      $scope.detailModel.details = "";
      var fallBackDetails = "";

      $scope.cancelDetailEdit = function () {
        $scope.detailModel.details = fallBackDetails;
        $scope.$emit('editBasicInfoClosed');
        $rootScope.$broadcast('wysihtml-textarea-reset', $scope.detailModel.details);
      };

      $scope.saveDetail = function () {
        var promise = entityService.updateDetail($scope.detailModel);
        promise.then(function (results) {
          if (results.data.updated) {
            $rootScope.$broadcast("successAlertMessage", "Your changes have been saved.");
          } else {
            $rootScope.$broadcast("successAlertMessage", "Your changes have been submitted for review.");
          }
          $scope.$emit('editBasicInfoClosed');
          $scope.loadDetails();
          // Since we cant check if the edits went into proposed state, just load the proposed edits screen regardless
          $rootScope.$broadcast('newProposedEdits');
        });
      };

      $scope.loadDetails = function () {
        $scope.detailModel.bn = urlService.getEntityBn();
        var promise = entityService.getDetail($scope.detailModel.bn);
        promise.then(function (results) {
          $scope.detailModel.details = results.data.details;
          fallBackDetails = $scope.detailModel.details;
        });
      };

      $scope.loadDetails();
    }])

  .controller('EntityBasicInfoSidebarCtrl', ['$scope', 'pageService', 'utilService',
    function ($scope, pageService, utilService) {
      $scope.basicInfoSidebarModel = {};
      $scope.$watch(pageService.getEntity, function (newValue, oldValue) {
        // Even if the newValue is unchanged, set the model
        // This ensures the model is populated when the basic info section is reloaded
        $scope.basicInfoSidebarModel = newValue;
      });
      $scope.ngDateFormat = utilService.getDateFormatForAnglularDateFilter();
    }])
  .controller('EntitySideBarTabCtrl', ['$rootScope', '$scope', 'urlService', 'entityService', '$timeout', 'contentStatusService', 'redux',
    function ($rootScope, $scope, urlService, entityService, $timeout, contentStatusService, redux) {
      const { store, actions } = redux;
      $scope.entitySideBarCountModel = {};
      $scope.filteredPropertyCount = true;
      $scope.tabSelectModel = {};
      $scope.tabSelectModel.showSummaryInfo = true;
      $scope.tabSelectModel.showProposedEditsInfo = false;
      $scope.tabSelectModel.showAuditInfo = false;

      const setWorkflowEnabled = (contentStatusId) => {
        $scope.workflowEnabled = contentStatusService.contentStatusTriggersWorkflow(contentStatusId);
      };

      $scope.$watch('entityModel.contentStatus.id', (newVal, oldVal) => {
        if (newVal && newVal !== oldVal) {
          setWorkflowEnabled(newVal);
          store.dispatch(
           actions.setActiveContent(newVal, 'entityPage')
          );
        }
      });

      $scope.showTab = function (tabName) {
        if (tabName === 'SummaryInfo') {
          $scope.tabSelectModel.showSummaryInfo = true;
          $scope.tabSelectModel.showProposedEditsInfo = false;
          $scope.tabSelectModel.showAuditInfo = false;
        } else if (tabName === 'ProposedEditsInfo') {
          $scope.tabSelectModel.showSummaryInfo = false;
          $scope.tabSelectModel.showProposedEditsInfo = true;
          $scope.tabSelectModel.showAuditInfo = false;
        } else if (tabName === 'AuditInfo') {
          $scope.tabSelectModel.showSummaryInfo = false;
          $scope.tabSelectModel.showProposedEditsInfo = false;
          $scope.tabSelectModel.showAuditInfo = true;
        }
      };

      $scope.getContent = function () {
        var entityBn = urlService.getEntityBn();
        entityService.getSideBarCounts(entityBn).then(function (data) {
          $scope.entitySideBarCountModel = data.data;
          //TODO is this the best place to dispatch action?
          redux.store.dispatch(
            redux.actions.setProposedCount($scope.entitySideBarCountModel.proposedEditCount, 'entityPage')
          );
        });
      };

      $scope.$on('togglePropertyCount', function (event, filtered) {
        $scope.filteredPropertyCount = filtered;
      });

      $scope.$on('newProposedEdits', function (even, changeBns) {
        // can be called from wicket, need to apply when necessary
        $timeout(function () {
          entityService.setNewProposedEditBns(changeBns);
          $scope.getContent();
          $scope.showTab('ProposedEditsInfo');
        });
      });

      $scope.$on('txIdPosted', function (event, data) {
        console.log('txIdPosted', data);
      });

      $scope.$on('updateProposedEditCount', function (event) {
        // can be called from wicket, need to apply when necessary
        $timeout(function () {
          $scope.getContent();
          $scope.showTab('ProposedEditsInfo');
        });
      });

      $scope.getContent();
    }])

  .controller('EntityLayoutCtrl', ['$rootScope', '$scope', '$timeout', 'urlService', 'utilService', 'alertService', 'layoutService', 'tenantService',
    function ($rootScope, $scope, $timeout, urlService, utilService, alertService, layoutService, tenantService) {

      $scope.layoutModel = {
        layout: "",
      };
      $scope.layoutModel.layout = _.clone(BnCodeToLayout[utilService.getBnCodeFromUrl()]);
      $scope.layoutModel.layout = {
        ...$scope.layoutModel.layout,
        sections: $scope.layoutModel.layout.sections.filter(s => s.category !== 'related' || !EntityTypes[s.bn] || tenantService.isAnAuthorizedEntityTypeCode(s.bn) || tenantService.isAnAuthorizedEntityBn(s.bn))
      };

      layoutService.setCurrentLayout($scope.layoutModel.layout);


      $scope.$on('sectionRenderComplete', function (event) {
        prototype.init();
        $scope.$broadcast("loadAllVisibleSections");
      });
    }])
  .controller('LinkCtrl', ['$scope', 'urlService', 'entityService',
    function ($scope, urlService, entityService) {
      $scope.linkModel = {};
      $scope.linkModel.loaded = false;
      $scope.load = function (entityBn, reload) {
        if (!$scope.linkModel.loaded || reload) {
          var promise = entityService.getLinks(urlService.getEntityBn());
          promise.then(function (data) {
            $scope.linkModel.loaded = true;
            $scope.linkModel.links = data.data;
          });
        }
      };
      $scope.$on('load', function (event, viewId) {
        $scope.load(urlService.getEntityBn(), false);
      });
      $scope.$on('reload', function (event, viewId) {
        $scope.load(urlService.getEntityBn(), true);
      });
    }])
  .controller('TagCtrl', ['$scope', 'urlService', 'entityService',
    function ($scope, urlService, entityService) {
      $scope.tagModel = {};
      $scope.tagModel.loaded = false;
      $scope.load = function (entityBn, reload) {
        if (!$scope.tagModel.loaded || reload) {
          var tagPromise = entityService.getTags(urlService.getEntityBn());
          tagPromise.then(function (data) {
            $scope.tagModel.loaded = true;
            $scope.tagModel.tags = data.data;
          });
        }
      };
      $scope.$on('load', function (event, viewId) {
        $scope.load(urlService.getEntityBn(), false);
      });
      $scope.$on('reload', function (event, viewId) {
        $scope.load(urlService.getEntityBn(), true);
      });
    }])

  // InsightEntityCtrl applies to non-Worksheet Insight entities.
  // They are entities in the backend sense, but are missing some Enterprise entity traits
  .controller('InsightEntityCtrl', ['$rootScope', '$scope', '$timeout', 'urlService', 'entityService', 'pageService', 'alertService',
    function ($rootScope, $scope, $timeout, urlService, entityService, pageService, alertService) {
      $scope.insightEntityModel = {
        loaded: false
      };
      $scope.containsInsightHelp = false;
      $scope.showInsightHelp = false;
      $scope.toggleInsightHelp = function () {
        $scope.showInsightHelp = !$scope.showInsightHelp;
      };

      function checkForInsightHelp () {
        var type = urlService.getEntityTypeCodeOfCurrentPage();
        $scope.containsInsightHelp = urlService.isInsightPage(type);
      }

      pageService.setIsEntityPage(true);
      $scope.activateAudit = function (value) {
        var result = entityService.activateAudit(value, $scope.insightEntityModel.basicInfo.bn);
        result.then(function (data) {
          $scope.getContent();
          if (value) {
            alertService.addSuccessAlert(String.format("Scorecard {0} is now active", $scope.insightEntityModel.basicInfo));
          } else {
            alertService.addSuccessAlert(String.format("Scorecard {0} is now inactive", $scope.insightEntityModel.basicInfo));
          }
          $scope.$broadcast('reload');
        });
      };
      $scope.getContent = function () {
        entityService.getBasicInfo(urlService.getEntityBn(), false, true).then(function (data) {
          $scope.insightEntityModel.basicInfo = data.data;
          $scope.insightEntityModel.loaded = true;
        });
      };
      $scope.getContent();
      $scope.$on('reloadBasicInfo', function (event, viewId) {
        // called from wicket when wicket basic info edit saves
        $scope.getContent();
        $scope.$apply();
      });
      $scope.$on('entitySaved', function (event, viewId) {
        // called from wicket when wicket basic info edit saves
        $scope.getContent();
        $scope.$apply();
      });
      $scope.$on('sectionRenderComplete', function (event) {
        prototype.init();
        $scope.$broadcast("loadAllVisibleSections");
      });
      checkForInsightHelp();
    }])

  .controller('EntityEditControlsCtrl', ['$scope', 'entityService', 'adminService', 'urlService', '$modal', 'alertService', 'utilService',
    function ($scope, entityService, adminService, urlService, $modal, alertService, utilService) {
      $scope.editControlsModel = {};
      $scope.editControlsModel.settings = [];
      $scope.editControlsModel.entityLoaded = false;
      $scope.editControlsModel.canDelete = false;
      $scope.editControlsModel.canEdit = false;
      $scope.editControlsModel.profileInviteModal = null;
      $scope.editControlsModel.profileServiceAccountModal = null;
      $scope.editControlsModel.profileSettingsModal = null;
      $scope.editControlsModel.editorsModal = null;
      $scope.editControlsModel.username = null;
      $scope.editControlsModel.serviceAccount = null;
      $scope.editControlsModel.passwords = {
        passwordMatch: null,
        temporaryPassword: '',
        password: '',
        passwordConfirmation: ''
      };

      $scope.$on('basicInfoLoaded', function (event, basicInfo) {
        $scope.editControlsModel.canDelete = basicInfo.canDelete;
        $scope.editControlsModel.canEdit = basicInfo.canEdit;
        $scope.editControlsModel.canEditProfileSettings = basicInfo.canEditProfileSettings;
        $scope.editControlsModel.canSendInvite = basicInfo.canSendInvite;
        $scope.editControlsModel.bn = basicInfo.bn;
        $scope.editControlsModel.status = basicInfo.status;
        $scope.editControlsModel.entityLoaded = true;
        $scope.editControlsModel.entityName = basicInfo.displayableName;
        $scope.editControlsModel.inviteBtnText = basicInfo.inviteBtnText;
        $scope.editControlsModel.serviceAccountBtnText = basicInfo.serviceAccountBtnText;
        $scope.editControlsModel.firstName = basicInfo.firstName;
        $scope.editControlsModel.lastName = basicInfo.lastName;
        $scope.editControlsModel.emailAddress = basicInfo.emailAddress;

        //load settings list
        buildEntitySettingsList();
      });

      $scope.$on('cancelEdit', function (event) {
        $scope.closeProfileInviteModal();
      });

      function buildEntitySettingsList () {
        $scope.editControlsModel.settings.length = 0;
        if ($scope.showEditors()) {
          $scope.editControlsModel.settings.push({
            name: "View the Editors for this entity",
            iconClass: "icon-security",
            callback: $scope.openEditorsModal
          });
        }
        if ($scope.showDelete()) {
          $scope.editControlsModel.settings.push({
            name: "Delete Entity",
            iconClass: "icon-attention",
            callback: $scope.openDeleteConfirmationModal
          });
        }
        if ($scope.showInviteButton()) {
          $scope.editControlsModel.settings.push({
            name: $scope.editControlsModel.inviteBtnText,
            iconClass: "icon-export",
            callback: $scope.openProfileInviteModal
          });
          $scope.editControlsModel.settings.push({
            name: $scope.editControlsModel.serviceAccountBtnText,
            iconClass: "icon-integrate",
            callback: $scope.openProfileServiceAccountModal
          });
        }
      }

      $scope.closeEditorsModal = function () {
        $scope.editControlsModel.editorsModal.dismiss('cancel');
      };

      $scope.closeProfileInviteModal = function () {
        $scope.editControlsModel.profileInviteModal.dismiss('cancel');
      };

      $scope.closeProfileServiceAccountModal = function () {
        $scope.editControlsModel.profileServiceAccountModal.dismiss('cancel');
      };

      $scope.closeProfileServiceAccountModalAndRefreshPage = function () {
        if ($scope.editControlsModel.passwords.passwordMatch !== true) {
          adminService.resetPassword($scope.editControlsModel.bn).then(function () {
            $scope.editControlsModel.profileServiceAccountModal.dismiss('cancel');
            alertService.addErrorAlert(String.format("Temporary password was emailed to {0}.", $scope.editControlsModel.bn));
          });
        } else {
          $scope.editControlsModel.profileServiceAccountModal.dismiss('cancel');
          window.location.reload();
        }
      };

      $scope.closeProfileSettingsModal = function () {
        $scope.editControlsModel.profileSettingsModal.dismiss('cancel');
      };

      $scope.sendServiceAccountInitialization = function () {
        var request = {
          'username': $scope.editControlsModel.username,
          'type': 'SER'
        };
        adminService.createUser(request, $scope.editControlsModel.bn).then(function (data) {
          $scope.editControlsModel.serviceAccount = {};
          //$scope.editControlsModel.serviceAccount.temporaryPassword = data.data.temporaryPassword;
          $scope.editControlsModel.passwords.temporaryPassword = data.data.temporaryPassword;
        });
      };

      $scope.checkPasswords = function () {
        if ($scope.editControlsModel.passwords.password === $scope.editControlsModel.passwords.passwordConfirmation) {
          $scope.editControlsModel.passwords.passwordMatch = true;
          $scope.setServiceAccountPassword();
        }
        else
          $scope.editControlsModel.passwords.passwordMatch = false;
      };

      $scope.setServiceAccountPassword = function () {
        var request = {
          "oldPassword": $scope.editControlsModel.passwords.temporaryPassword,
          "newPassword": $scope.editControlsModel.passwords.password,
          "newPasswordConfirmation": $scope.editControlsModel.passwords.passwordConfirmation
        };
        adminService.setPassword(request, $scope.editControlsModel.bn).then(function (data) {
          $scope.closeProfileServiceAccountModalAndRefreshPage();
        })
      };

      $scope.canEdit = function () {
        return entityService.getCanEdit();
      };

      $scope.showDelete = function () {
        return entityService.getCanDelete();
      };

      $scope.showPin = function () {
        var entityTypeCode = urlService.getEntityTypeCodeOfCurrentPage();
        switch (entityTypeCode) {
          case 'PER':
            return false;
          default:
            return true;
        }
      };

      $scope.showEditors = function () {
        var entityTypeCode = urlService.getEntityTypeCodeOfCurrentPage();
        switch (entityTypeCode) {
          case 'ACT':
          case 'AST':
          case 'CAP':
          case 'COM':
          case 'CON':
          case 'DAT':
          case 'DEM':
          case 'MKT':
          case 'ORG':
          case 'PHY':
          case 'PRD':
          case 'PRO':
          case 'SKI':
          case 'STA':
          case 'STR':
          case 'SYS':
          case 'TEC':
            return true;
          default:
            return false;
        }
      };

      $scope.showInviteButton = function () {
        return $scope.editControlsModel.canSendInvite;
      };

      $scope.showProfileSettings = function () {
        return $scope.editControlsModel.canEditProfileSettings;
      };

      $scope.showProfilePersonalInfoLink = function () {
        return utilService.getCurrentUserBn() === urlService.getEntityBn();
      };

      $scope.showSettingsList = function () {
        return $scope.showDelete() || $scope.showEditors() || $scope.showInviteButton();
      };

      $scope.openEditorsModal = function () {
        $scope.editControlsModel.editorsModal = $modal.open({
          backdrop: 'static',
          keyboard: true,
          templateUrl: '/b/js/src/bit.ng/entity/partials/entity-editors.html',
          scope: $scope
        });
      };

      $scope.openProfileInviteModal = function () {
        $scope.editControlsModel.profileInviteModal = $modal.open({
          backdrop: 'static',
          keyboard: true,
          templateUrl: '/b/js/src/bit.ng/entity/partials/profile/profile-invite-dialog.html',
          scope: $scope
        });
      };

      $scope.openProfileServiceAccountModal = function () {
        $scope.editControlsModel.profileServiceAccountModal = $modal.open({
          backdrop: 'static',
          keyboard: true,
          templateUrl: '/b/js/src/bit.ng/entity/partials/profile/profile-service-accounts-dialog.html',
          scope: $scope
        });
      };

      $scope.openProfileSettingsModal = function () {
        $scope.editControlsModel.profileSettingsModal = $modal.open({
          backdrop: 'static',
          keyboard: true,
          templateUrl: '/b/js/src/bit.ng/entity/partials/profile/profile-settings-dialog.html',
          scope: $scope
        });
      };

      $scope.openProfilePersonalInfoModal = function () {
        $scope.editControlsModel.profilePersonalInfoModal = $modal.open({
          backdrop: 'static',
          keyboard: true,
          templateUrl: '/b/js/src/bit.ng/entity/partials/profile/profile-personal-info-dialog.html',
          scope: $scope
        });
      };

      $scope.closeProfilePersonalInfoModal = function () {
        $scope.editControlsModel.profilePersonalInfoModal.dismiss('cancel');
      };

      $scope.openDeleteConfirmationModal = function () {
        $scope.editControlsModel.deleteConfirmationModal = $modal.open({
          backdrop: 'static',
          keyboard: true,
          templateUrl: '/b/js/src/bit.ng/common/partials/delete-confirmation-dialog.html',
          scope: $scope
        });
      };

      $scope.closedDeleteConfirmationModal = function () {
        $scope.editControlsModel.deleteConfirmationModal.dismiss('cancel');
      };

      $scope.deleteEntity = function () {
        $scope.editControlsModel.deleteConfirmationModal.dismiss('cancel');
        entityService.deleteEntity($scope.editControlsModel.bn).then(function (data) {
          //set the alert in local storage for consumption when page reloads
          var deletedEntity = data.data.successfulEntities[0];
          if (deletedEntity) {
            alertService.addDeferredAlert({
              type: "success",
              msg: String.format("Successfully deleted {0}", deletedEntity.name)
            });
            var insightType = deletedEntity.insightType;
            if(!!insightType) {
              window.location.href = urlService.getBaseUrl() + 'insightbrowse?typeBn=' + insightType.bn;
            } else {
              var type = singleTypedCollectionPages[urlService.getEntityTypeCodeOfCurrentPage(true) || ""] || "";
              window.location.href = urlService.getBaseUrl() + (type ? type : "");
            }
          } else {
            alertService.addErrorAlert('Couldn\'t Delete Entity');
          }
        });
      }
    }])

  .controller('ProfilePhotoUploadCtrl', ['$scope', '$timeout', '$modal', '$http',
    function ($scope, $timeout, $modal, $http) {
      $scope.photoUploadModel = {
        assetStoreUrl: null,
        noPhotoSelected: true,
        cropperDialog: {},
        cropCoordinates: {}
      };
      $scope.photoUploadModel.options = {
        showProgress: false,
        url: '/b/api/assetstore',
        response: null
      };

      $scope.applyCropChanges = function () {
        var jsonReq = angular.toJson($scope.photoUploadModel.coordinates);
        var request = $.param({ coordinates: jsonReq });
        $http({
          method: 'POST',
          url: '/b/api/image/location/' + $scope.photoUploadModel.assetStoreUrl,
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          data: request
        })
          .success(function (data, status, headers, config) {
            //set new url that points at the cropped image
            $scope.photoUploadModel.assetStoreUrl = data;
            $scope.entityBasicInfoModel.photoAssetStoreUrl = data;
            $scope.closeProfilePhotoCropper();
          })
          .error(function (data, status, headers, config) {
            console.log('error uploading crop image', data);
          })
      };

      $scope.closeProfilePhotoCropper = function () {
        $scope.photoUploadModel.cropperDialog.dismiss('cancel');
      };

      $scope.cropImage = function () {
        $scope.photoUploadModel.cropperDialog = $modal.open({
          backdrop: 'static',
          keyboard: true,
          templateUrl: '/b/js/src/bit.ng/entity/partials/profile/profile-photo-crop-dialog.html',
          scope: $scope
        });
      };

      $scope.initCtrl = function () {
        if ($scope.entityBasicInfoModel.photoAssetStoreUrl) {
          $scope.photoUploadModel.noPhotoSelected = false;
          $scope.photoUploadModel.assetStoreUrl = $scope.entityBasicInfoModel.photoAssetStoreUrl;
        }
      };

      $scope.noPhotoSelected = function () {
        $scope.photoUploadModel.assetStoreUrl = null;
        $scope.entityBasicInfoModel.photoAssetStoreUrl = null;
        $scope.entityBasicInfoModel.photoChanged = true;
      };

      $scope.$watch('photoUploadModel.options.response', function (newVal, oldVal) {
        if (newVal) {
          $scope.photoUploadModel.assetStoreUrl = newVal;
          $scope.entityBasicInfoModel.photoAssetStoreUrl = newVal;
          $scope.entityBasicInfoModel.photoChanged = true;
        }
      });

      $scope.initCtrl();
    }])

  .controller('ProfilePhotoDisplayCtrl', ['$scope', 'urlService',
    function ($scope, urlService) {
      const getURL = () => `/b/api/profile/image/${urlService.getEntityBn()}?decache=${Date.now()}`;

      $scope.profilePhotoURL = getURL();

      $scope.$on('profilePhotoChanged', function (event, bn) {
        $scope.profilePhotoURL = getURL();
      });
    }])

  .controller('AddDirectConnectionCtrl', ['$scope', '$modal',
    function ($scope, $modal) {
      $scope.addConnectionModel = {}
      $scope.openAddConnectionModal = function () {
        $scope.addConnectionModel.modal = $modal.open({
          backdrop: 'static',
          keyboard: true,
          templateUrl: '/b/js/src/bit.ng/entity/partials/system/add-connection.html',
          scope: $scope
        });
      };
      $scope.$on('openAddConnectionModal', function (event) {
        $scope.openAddConnectionModal();
      });
      $scope.closeAddConnectionModal = function () {
        $scope.addConnectionModel.modal.dismiss('cancel');
      };
      $scope.$on('closeCreateConnectionModal', function (event) {
        $scope.closeAddConnectionModal();
      });
    }])
  .controller('RemediationEntityContactCtrl', ['$scope', 'entityService', 'optionListService', 'urlService', '$rootScope', 'utilService',
    function ($scope, entityService, optionListService, urlService, $rootScope, utilService) {
      $scope.remediationEntityContactTypeModel = {
        loaded: false,
        showContactType: false
      };

      function getContacts (data) {
        $scope.resetModel();
        $scope.remediationEntityContactTypeModel.showContactType = data.data.showSection;
        var items = data.data.contactTypes;
        for (var i = 0; i < items.length; i++) {
          $scope.remediationEntityContactTypeModel.contactTypes.push({ bn: items[i].bn, name: items[i].name });
          if (items[i].isActive) {
            $scope.remediationEntityContactTypeModel.activeContactTypes.push({ bn: items[i].bn, name: items[i].name });
            $scope.remediationEntityContactTypeModel.editedContactTypes.push(items[i].bn);
          }
        }
      }

      var selector = $('#auditContactTypeMultiSelect');
      var select2Options = function () {
        var selectList = [];
        _.each($scope.remediationEntityContactTypeModel.contactTypes, function (contactType) {
          selectList.push({ id: contactType.bn, text: contactType.name });
        });
        return {
          width: '100%',
          data: selectList,
          initSelection: function (element, callback) {
            var initialSelectedOptions = [];
            if ($scope.remediationEntityContactTypeModel.activeContactTypes) {
              _.each($scope.remediationEntityContactTypeModel.activeContactTypes, function (contactType) {
                initialSelectedOptions.push({ id: contactType.bn, text: contactType.name })
              })
            }
            callback(initialSelectedOptions);
          }
        }
      };

      $scope.load = function (reload) {
        if (!$scope.remediationEntityContactTypeModel.loaded || reload) {
          setCanEdit();
          $scope.remediationEntityContactTypeModel.bn = urlService.getEntityBn();
          var entityType = utilService.getEntityTypeCode($scope.remediationEntityContactTypeModel.bn);
          if (entityType == 'CRL') {
            optionListService.getContactTypesForRules($scope.remediationEntityContactTypeModel.bn).then(function (data) {
              getContacts(data);
            });
          } else if (entityType == 'AUD')
            optionListService.getContactTypesForAudit($scope.remediationEntityContactTypeModel.bn).then(function (data) {
              getContacts(data);
            });
          $scope.remediationEntityContactTypeModel.loaded = true;
        }
      };

      $scope.resetModel = function () {
        $scope.remediationEntityContactTypeModel.contactTypes = [];
        $scope.remediationEntityContactTypeModel.activeContactTypes = [];
        $scope.remediationEntityContactTypeModel.editedContactTypes = [];
      };

      function setCanEdit () {
        // When you navigate directly to the contacts section, the edit icon will not appear
        // unless you load the entity and sets the "canEdit" property.
        var entityDataPromise = entityService.getBasicInfo(urlService.getEntityBn());
        entityDataPromise.then(function (data) {
          entityService.setCanEdit(data.data.canEdit);
        });
      }

      $scope.$on('load', function (event, viewId) {
        if (viewId === 'audit_contact_view' || viewId === 'audit_contact_edit') {
          $scope.load(false);
          selector.select2(select2Options());
        }
      });

      $scope.$on('reload', function (event, viewId) {
        if (viewId === 'audit_contact_view' || viewId === 'audit_contact_edit') {
          // need to destroy and then reinstantiate select2 to get selected values
          selector.select2('destroy');
          $scope.load(true);
          selector.select2(select2Options());
        }
      });

      $scope.cancelContactTypeEdit = function () {
        $scope.$emit('editBasicInfoClosed');
      };

      $scope.saveContactTypeEdit = function () {
        var activeContactBns = [];
        for (var i = 0; i < $scope.remediationEntityContactTypeModel.activeContactTypes.length; i++) {
          activeContactBns.push($scope.remediationEntityContactTypeModel.activeContactTypes[i].bn);
        }
        var removedContactBns = $(activeContactBns).filter(function () {
          return $scope.remediationEntityContactTypeModel.editedContactTypes.indexOf(this.toString()) < 0;
        });
        var addedContactBns = $($scope.remediationEntityContactTypeModel.editedContactTypes).filter(function () {
          return activeContactBns.indexOf(this.toString()) < 0;
        });
        optionListService.updateAuditContactTypes($scope.remediationEntityContactTypeModel.bn, {
          addedContactTypes: addedContactBns.toArray(),
          removedContactTypes: removedContactBns.toArray()
        })
          .then(function (results) {
            $rootScope.$broadcast("successAlertMessage", "Your changes have been saved.");
            $scope.$emit('editBasicInfoClosed');
          });
      }
    }])
  .controller('EntityTagsCtrl', ['$scope', 'urlService', function ($scope, urlService) {
    // This might need to be a special static map just for these URLs
    // Not sure if all the URL's matchup with the page identifier map
    $scope.editUrl = urlService.getPageIdentifierOfCurrentPage() + "tagedit";
  }])
  .controller('EntityLinksCtrl', ['$scope', 'urlService', function ($scope, urlService) {
    // This might need to be a special static map just for these URLs
    // Not sure if all the URL's matchup with the page identifier map
    $scope.editUrl = urlService.getPageIdentifierOfCurrentPage() + "linksedit";
  }]);
