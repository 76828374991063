angular.module('barometerApp.scorecard')
  .directive('scorecardResults', ['$compile', 'tableService', 'entityService', 'urlService', 'utilService', 'bitConstants', '$q', 'scorecardService', '$rootScope', '$modal', '$timeout', 'connectionService',
    function ($compile, tableService, entityService, urlService, utilService, bitConstants, $q, scorecardService, $rootScope, $modal, $timeout, connectionService) {
      return {
        replace: true,
        scope: {
          rows: "=",
          columns: "=",
          paginationData: '@',
          tableType: '@',
          entityBn: "=",
          lazyLoad: '@'
        }
      }
    }]);