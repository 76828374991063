angular.module('barometerApp.matrix')
  .factory('matrixService', ['graphService', '$http',
    function(graphService, $http) {
      //Common services for matrix worksheet

      //TODO remove return statement
      function getBnNameListForReport(reportBn){
        return $http({
          method: 'GET',
          url: '/b/api/reports/' + reportBn + '/entityBnsNames'
        }).success(function(data) {
        }).error(function(data, status, headers, config) {
          console.error('Error in MatrixService.getBnListForReport for bn: ' + reportBn +
              '\n Error' + data);
        })
      }

      function createDefaultParams(configuration, page, rowData, colData) {

        var params = {};
        params.type1 = configuration.rows.entityType.typeCode;
        params.type2 = configuration.columns.entityType.typeCode;
        if(rowData) {
          params.type1Bns = getBns(rowData);
        }//end rowBns check
        if (colData) {
          params.type2Bns = getBns(colData);
        }//end colbns check
        params.firstRecord = (25 * (page -1)) + 1;
        params.numberToReturn = 25;
        params.sortBy = {entityIndex: 1, fieldType: "NAME", sortOrder: "ASC"};

        return params;
      }//end create default params

      function createGroupedQualifierParams(configuration, rowData, colData) {
        var params = {};
        params.type1 = configuration.rows.entityType.typeCode;
        params.type2 = configuration.columns.entityType.typeCode;
        if(rowData) {
          params.type1Bns = getBns(rowData);
        }//end rowBns check
        if (colData) {
          params.type2Bns = getBns(colData);
        }//end colbns check
        return params;
      }

      function getBns(rowOrColData) {
        var bns = [];
        for (var i = 0; i < rowOrColData.length; i++) {
          bns[i] = rowOrColData[i].bn;
        }
        return bns;
      }

      function getReportData(configuration, page, rowData, colData) {
        var params = createDefaultParams(configuration, page, rowData, colData);
        return graphService.getMatrixReportData(params);
      }//end get report data

      function getGroupedQualifiers(configuration, rowData, columnData) {
        var params = createGroupedQualifierParams(configuration, rowData, columnData);
        return graphService.getGroupedQualifiers(params);
      }

      return {
        getBnNameListForReport: getBnNameListForReport,
        getReportData: getReportData,
        getGroupedQualifiers: getGroupedQualifiers
      };//end service return
    }]);