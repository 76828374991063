angular
  .module('barometerApp.remediationForm')
  .controller('RemediationFormBuilderController', RemediationFormBuilderController);

RemediationFormBuilderController.$inject = ['$scope', '$q', 'urlService', 'utilService', 'remediationFormBuilderDataService', '$timeout'];

function RemediationFormBuilderController($scope, $q, urlService, utilService, remediationFormBuilderDataService, $timeout) {

  /**
   * My state.
   */
  // Expected by CustomColumnController.
  $scope.entityType = $scope.formBuilder.entityType;
  $scope.customColumnController = {
    options: {
      excludedCategories: ['All Columns', 'Associations'],
      // Some columns are excluded because they are un-editable.
      // Some columns are excluded because they don't work:
      // Details, BARO-17428
      // Alias is an association - should be excluded by association category, not sure why its not
      excludedColumns: ['BarometerIT No.','Created','Created By','Details','Last Updated','Roots','Updated By','Version', 'Aliases', 'Lifecycle State Roadmap', 'Systems Responsible For', 'People Count', 'Parent BN', 'Members', 'Systems Used', 'Managers', 'Color']
    }
  };
  // "this is a little funky because the model is set to match the way summary view sets them, so we can re-use the CustomColumnController"
  $scope.workingModel = {};
  $scope.workingModel.configuration = {
    columnSpecBn: 'TODO'
  };
  $scope.workingModel.customColumn = {
    categories: [],
    selectedColumn: null,
    selectedColumns: [],
    selectedCategory: 'dataDict',
    entityTypeCode: null,
    sortableOptions: {
      handle: '.handle',
      stop: function(event, ui) {
        applyReorder();
        $scope.$apply();
      }
    }

  };

  $scope.select2 = function() {
    // init selec2 on element
    var selector = $('#remediation-form-select2');
    selector.select2('destroy');
    selector.select2();
  };

  remediationFormBuilderDataService.getFieldOptions($scope.entityType).then(function(results) {
    $scope.workingModel.customColumn.categories = results.categories;
    $scope.workingModel.customColumn.availableColumns = results.availableColumns;
    $('#remediation-form-select2').select2();
  });

  var applyReorder = function () {
    $.each($('.selected-custom-column'), function (index) {
      $(this).scope().selectedColumn.order = $(this).index();
    });
  };


  /**
   * DATA_DICT or CUSTOM
   */
  function getFieldType(selectedField) {

    var bnCode = utilService.getBnCode(selectedField.bn);

    if(bnCode == '46') {
      return 'DATA_DICT';
    } else if (bnCode == '0L') {
      return 'CUSTOM';
    } else if(selectedField.fieldType == 'relationship') {
      return 'DATA_DICT'; //Relationship assocs still count as data dicts for the process of assocType
    }
    //
    //This section is mostly a fall back from the previous way types were distingueshed
    // If this is a dictionary field, it will have a 'sortPropertyBn' of of code '46'.
    if (selectedField.sortPropertyBn) return 'DATA_DICT';
    // If this is a custom field, it will have a 'bn' of code '0L'.
    if (selectedField.bn) return 'CUSTOM';
    //
    console.warn("selectedField has no recognizable type.");
  }

  /**
   * One of ...
   *
   * ASSOCIATION,
   * BOOLEAN,
   * DATE,
   * DECIMAL,
   * ENUM,
   * INTEGER,
   * LOCAL_DATE,
   * NOT_APPLICABLE,
   * NUMBERED,
   * STRING;
   */
  function getDataType(selectedField) {
    //
    return selectedField.propertyType;
  }

  /**
   * Data Dictionary BN or Custom Field BN
   */
  function getFieldBn(selectedField) {
    //
    // If this is a dictionary field, it will have a 'sortPropertyBn' of of code '46'. -- However, thats not the right bn. Still look at bn
    // If this is a custom field, it will have a 'bn' of code '0L'.
    if (selectedField.bn) return selectedField.bn;
    if (selectedField.qualifier && selectedField.qualifier.bn) return selectedField.qualifier.bn;
    //
    console.warn("selectedField has no BN.");
  }

  /**
   * @param selectedFields
   */
  function getFields(selectedFields) {
    //
    var associatedFields = [];
    angular.forEach(selectedFields, function (selectedField, index, selectedFields) {
      //
      var selectedFieldType = getFieldType(selectedField);
      var selectedFieldBn = getFieldBn(selectedField);
      var selectedDataType = getDataType(selectedField);
      var fieldItem = {
        "associationType": selectedFieldType,
        "field": {
          "bn": selectedFieldBn,
          // For display purposes only ...
          "name": selectedField.name
        }
      };
      // Custom Fields don't get a propertyType.
      if (selectedFieldType != 'CUSTOM') fieldItem.field.propertyType = selectedDataType;
      if (selectedField.fieldType == 'relationship') fieldItem.field.propertyType = 'ASSOCIATION';
      associatedFields[index] = fieldItem;
    });
    return associatedFields;
  }

  /**
   * Why to I #$%^ing have to put this in scope when my directive constructs this controller?
   *
   * @param selectedFields
   */
  $scope.createFormEntity = function (selectedFields) {
    //
    var jsonRequest = {
      "entityDiscriminator": "RFM", // Remediation Form
      "description": "",
      "entityTypeCode": $scope.entityType,
      "associatedFields": getFields(selectedFields)
    };
    // Push into scope, to be pulled when Task is finalized.
    $scope.formBuilder.form = jsonRequest;
  };

  $scope.addColumn = function () {
    // add a copy of the column to the selected
    var columnToAdd = $.extend(true, {}, $scope.workingModel.customColumn.selectedColumn);
    // set to new so it will fade in (handled by fadeInNewColumn directive)
    columnToAdd.isNew = true;
    if ($scope.workingModel.customColumn.selectedColumns) {
      // add to end of list
      columnToAdd.order = $scope.workingModel.customColumn.selectedColumns.length;
    }
    $scope.workingModel.customColumn.selectedColumns.push(columnToAdd);
    // remove column from list of available if it does not have any qualifiers
    if ($scope.workingModel.customColumn.selectedColumn.qualifiers == null) {
      removeColumnFromList($scope.workingModel.customColumn.selectedColumn, $scope.workingModel.customColumn.availableColumns);
    }
    $scope.workingModel.customColumn.selectedColumn = null;
    applyReorder();
  };

  $scope.removeColumn = function (column) {
    removeColumnFromList(column, $scope.workingModel.customColumn.selectedColumns);
    /// Add it back to the list of available.  If it has qualifiers it was never removed from the list, so don't add it back.
    if (column.qualifiers == null) {
      $scope.workingModel.customColumn.availableColumns.push(column);
    }
    $timeout(function () {
      // apply re-order after the list on the page has rendered
      applyReorder();
    }, 100);
  };

  var removeColumnFromList = function (column, list) {
    var indexToRemove = null;
    var index = 0;
    _.each(list, function (val) {
      //TODO remove by something awesome
      if(val.name == column.name) {
        indexToRemove = index;
      }
      index++;
    });
    var tempCopy = _.cloneDeep(list);
    list.splice(indexToRemove, 1);
    $timeout(function(){
      $('#remediation-form-select2').select2().trigger('change')
    }, 0, false);
  };

  $scope.sortButtonClicked = function (column) {
    if ($scope.workingModel.configuration.sortProperty == column.sortPropertyBn) {
      if ($scope.workingModel.configuration.sortDescending) {
        // toggle to no sort
        $scope.workingModel.configuration.sortProperty = null;
      } else {
        // toggle to descending for this column
        $scope.workingModel.configuration.sortDescending = true;
      }
    } else {
      // set this column as the sort, with ascending
      $scope.workingModel.configuration.sortProperty = column.sortPropertyBn;
      $scope.workingModel.configuration.sortDescending = false;
    }
  };

  $scope.getSortClass = function (column) {

    var sort_disabled = '';
    var no_sort_available = 'sort-button';
    var not_selected = 'sort-button icon-sort-asc';
    var selected_ascending = 'sort-button icon-sort-asc selected';
    var selected_descending = 'sort-button icon-sort-desc selected';
    var sortClass = sort_disabled;

    // for now reports do not support sorting
    if ($scope.workingModel.configuration.contentBlockType != null) {  // nd: hack. this means we are in summary view, so enable sorting.  remove this when we enable sorting on reports.
      if (column.sortPropertyBn == null) {
        sortClass = no_sort_available;
      } else if ($scope.workingModel.configuration.sortProperty == column.sortPropertyBn) {
        if ($scope.workingModel.configuration.sortDescending) {
          sortClass = selected_descending;
        } else {
          sortClass = selected_ascending;
        }
      } else {
        sortClass = not_selected;
      }
    }

    return sortClass;
  };
}