angular
    .module('barometerApp.tasks')
    .directive('taskActivity', taskActivity);

taskActivity.$inject = [];

/**
 * Displays one Task
 *
 * @author Tim Cramer
 */
function taskActivity() {

    var directive = {
        templateUrl: '/b/js/src/bit.ng/entity/partials/task/task-activity.html',
        restrict: 'E',
        scope: {
            // task: '='
        },
        controller: 'TaskActivityModalController',
        link: function(scope) {
        }
    };

    return directive;
}