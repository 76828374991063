/**
 * Created by timstave on 5/28/15.
 */
angular.module('barometerApp.summaryInfo')
.service('summaryInfoService', ['$http', 'assocService', 'utilService', 'alertService', 'urlService', function($http, assocService, utilService, alertService, urlService) {

    // getAssociatedDataByEntityBnTargetBnCodeAssocTypeAndTargetQueryPurpose is used to get associations and assoc details from Association detail service
    function getAssociatedDataByEntityBnTargetBnCodeAssocTypeAndTargetQueryPurpose(entityBn, targetBnCode, assocType, targetQueryPurpose, summaryData, callback) {
      $http({
        method: 'GET',
        url: '/b/api/associationDetails/list',
        params: {
          first: 0,
          count: 10,
          sortPropertyBn: null,       // null for all
          ascending: null,            // null for all
          sourceBn: entityBn,
          targetBnCode: targetBnCode,
          queryKey: urlService.getQueryKey(),
          targetBn: null,             // null for all
          relationshipType: assocType, // null for companies/ orgs, 'contact' for people
          columnSpecPurpose: null,    // null for all
          includeColumnSpec: true,    // true for all
          targetQueryPurpose: targetQueryPurpose,   // null for companies/ orgs (singleTypeBrowse), 'association' for people
          sourceQueryPurpose: null    // null for all
        }
      }).
        success(function (data, status) {
          processSummaryData(data, summaryData, callback);
        }).
        error(function (data, status) {
          console.log('error in summaryInfoService.getAssociatedDataByEntityBnAndTargetBnCode');
          alertService.addErrorAlert("Unable to get Association Details");
        });
    }

    // Adds all assoc data rows to summaryData returned array
    function processSummaryData(assocData, summaryData, callback) {

      _.each(assocData.rows, function(row) {
        summaryData.push(row);
      });

      callback(summaryData);
    }

    return {
      getSummaryInfoDataByEntityBnAndTargetBnCode: function (entityBn, targetBnCode, callback) {
        return getAssociatedDataByEntityBnTargetBnCodeAssocTypeAndTargetQueryPurpose(entityBn, targetBnCode, null, null, [], callback);
      },
      getSummaryInfoDataByEntityBnTargetBnCodeAssocTypeAndTargetQueryPurpose: function (entityBn, targetBnCode, assocType, targetQueryPurpose, callback) {
        return getAssociatedDataByEntityBnTargetBnCodeAssocTypeAndTargetQueryPurpose(entityBn, targetBnCode, assocType, targetQueryPurpose, [], callback);
      },
      getSystemComplexity: function(systemBn) {
        return $http({
          method: 'GET',
          url: '/b/api/entities/' + systemBn + '/systemComplexity',
          headers: {'Content-Type': 'application/json;charset=UTF-8'}
        }).
            success(function (data, status, headers, config) {
            }).
            error(function (data, status, headers, config) {
              console.log('error in entityService.getSystemComplexity for systemBn: ' + systemBn);
              alertService.addErrorAlert("Unable to edit entity detail");
            });
      },
      getSystemCriticality: function(systemBn) {
        return $http({
          method: 'GET',
          url: '/b/api/entities/' + systemBn + '/systemCriticality',
          headers: {'Content-Type': 'application/json;charset=UTF-8'}
        }).
            success(function (data, status, headers, config) {
            }).
            error(function (data, status, headers, config) {
              console.log('error in entityService.getSystemCriticality for systemBn: ' + systemBn);
              alertService.addErrorAlert("Unable to edit entity detail");
            });
      }
    }
  }]);