angular.module('barometerApp.editor')
    .directive('wysiwigEditor', function () {
        return {
            scope: {
              html: '='
            },
            link: function (scope, element) {

                var editorInitialized = false;

                element.addClass('wysihtml-textarea');

                // load editor when edit toggle clicked
                // it's odd that reload is fired here - not sure if that's a bug in multiView directive?
                scope.$on('reload', initEditor);
                function initEditor() {

                    // load the lib file
                    $LAB.script($LAB.jsUrl('wysihtml5')).wait(function () {
                        // call this in case the script took longer to load than it took the user to toggle to edit
                        // ensure this only runs once!
                        if (!editorInitialized) {
                            editorInitialized = true;

                            // stuff the stored html into the textarea
                            element.html(scope.html);

                            // add a toolbar BEFORE the textarea
                            var toolbar = $(
                                '<div style="display: none">' +
                                    '<div class="btn-group wysihtml-btn-group">' +
                                    '<button type="button" class="btn" data-wysihtml5-command="bold" title="Make text bold (CTRL + B)" ><span class="icon-bold"></span></button>' +
                                    '<button type="button" class="btn" data-wysihtml5-command="italic" title="Make text italic (CTRL + I)" ><span class="icon-italic"></span></button>' +
                                    '<button type="button" class="btn" data-wysihtml5-command="insertUnorderedList" title="Insert an unordered list" ><span class="icon-list"></span></button>' +
                                    '<button type="button" class="btn" data-wysihtml5-command="insertOrderedList" title="Insert an ordered list" ><span class="icon-list-numbered"></span></button>' +
                                    '<button type="button" class="btn" data-wysihtml5-command="formatBlock" data-wysihtml5-command-value="h1" title="Insert headline" >Headline</button>' +
                                    '<button type="button" class="btn" data-wysihtml5-command="createLink" title="Insert a link"><span class="icon-link"></span></button>' +
                                    '</div>' +
                                    '<div class="wysihtml-create-link input-append input-prepend" data-wysihtml5-dialog="createLink" style="display: none;">' +
                                        '<span class="add-on">Link</span>' +
                                        '<input type="text" data-wysihtml5-dialog-field="href" value="http://" />' +
                                        '<a class="btn btn-green" data-wysihtml5-dialog-action="save">Accept</a>' +
                                        '<a class="btn btn-red" data-wysihtml5-dialog-action="cancel">Discard</a>' +
                                    '</div>' +
                                    '</div>');
                            element.before(toolbar);

                            var editor = new wysihtml5.Editor(element.get(0), {
                                toolbar: toolbar.get(0),

                                // adapted from wysihtml5/master/parser_rules/simple.js
                                // ** Stay in synch with HtmlSanitizer.java
                                parserRules: {
                                    tags: {
                                        a: {
                                            set_attributes: {
                                                target: "_blank",
                                                rel: "nofollow"
                                            },
                                            check_attributes: {
                                                href: "href" // important to avoid XSS
                                            }
                                        },
                                        b: {},
                                        br: {},
                                        em: {},
                                        h1: {},
                                        i: {},
                                        li: {},
                                        ol: {},
                                        p: {},
                                        strong: {},
                                        table: {},
                                        tbody: {},
                                        td: {},
                                        th: {},
                                        tr: {},
                                        ul: {}
                                    }
                                },

                                // editor loads in iframe; give it a micro subset of the stylesheet
                                stylesheets: [$LAB.cssUrl('wysi')]
                            });

                            // changes need to be manually applied to the scope variable
                            // we originally used an ng-model on the textarea,
                            // but that wouldn't update the model with the new value
                            editor.on('change', function(){
                                scope.html = element.val();
                                scope.$apply();
                            });

                            scope.$on('wysihtml-textarea-reset', function(event, data){
                                editor.setValue(data);
                            });

                        }
                    });
                }
            }
        }
    });