angular.module('barometerApp.compliance')
  .service('complianceDataService', ['$http', 'errorHandlingService', 'utilService', 'alertService',
    function ($http, errorHandlingService, utilService, alertService) {
      return {
        getComplianceSummaryByEntityBn: function(bn, filtered) {
          utilService.assertIsBn(bn);
          // filtered = (filtered == null) ? true : filtered;
          return $http({
            method: 'GET',
            url: '/b/api/compliance/entity/' + bn + '?filtered=' + filtered,
            headers: {'Content-Type': 'application/json;charset=UTF-8'}
          }).
          success(function (data, status, headers, config) {
          }).
          error(function (data, status, headers, config) {
            console.log('error in complianceDataService.getComplianceSummary for bn: ' + bn);
            errorHandlingService.handleGenericError(status, "Unable to load detail for entity.");
          });
        },
        getCompliancePropertyCountByEntityBn: function(bn, filtered) {
          utilService.assertIsBn(bn);
          // filtered = (filtered == null) ? true : filtered;
          return $http({
            method: 'GET',
            url: '/b/api/compliance/entity/' + bn + '/count?filtered=' + filtered,
            headers: {'Content-Type': 'application/json;charset=UTF-8'}
          }).
          success(function (data, status, headers, config) {
          }).
          error(function (data, status, headers, config) {
            console.log('error in complianceDataService.getComplianceSummary for bn: ' + bn);
            errorHandlingService.handleGenericError(status, "Unable to load detail for entity.");
          });
        },
        getNonCompliantEntitiesCountByRuleBn: function(bn) {
          utilService.assertIsBn(bn);
          return $http({
            method: 'GET',
            url: '/b/api/compliance/rule/' + bn + "/count",
            headers: {'Content-Type': 'application/json;charset=UTF-8'}
          }).
          success(function (data, status, headers, config) {
          }).
          error(function (data, status, headers, config) {
            console.log('error in complianceDataService.getComplianceSummary for bn: ' + bn);
            errorHandlingService.handleGenericError(status, "Unable to load detail for entity.");
          });
        },
        createTasksForNonCompliantEntitiesByRuleBn: function(bn) {
          utilService.assertIsBn(bn);
          return $http({
            method: 'POST',
            url: '/b/api/compliance/' + bn,
            data: {},
            headers: {'Content-Type': 'application/json;charset=UTF-8'}
          }).
          success(function (data, status, headers, config) {
            alertService.addSuccessAlert("Task generation background processes have started. This may take some time, so we will send a Recap Task once completed");
          }).
          error(function (data, status, headers, config) {
            console.log('error in complianceDataService.createTasksForNonCompliantEntitiesByRuleBn for bn: ' + bn);
            if (status == 409) {
              alertService.addErrorAlert("This Rule is already generating Tasks.");
            } else {
              alertService.addErrorAlert("Unable to create tasks.");
            }
          });
        },
        generateTasksForBns: function(bns) {
          _.forEach(bns, function (bn) {
            utilService.assertIsBn(bn);
          });
          bns = bns.join();
          return $http({
            method: 'POST',
            url: '/b/api/queried/static',
            data: {
              bns
            },
            headers: {'Content-Type': 'application/json;charset=UTF-8'}
          }).
          success(function (data, status, headers, config) {
            alertService.addSuccessAlert("Task generation background processes have started. This may take some time, so we will send a Recap Task once completed");
          }).
          error(function (data, status, headers, config) {
            console.log('error in complianceDataService.createTasksForBns for bns: ' + bns);
            if (status == 409) {
              alertService.addErrorAlert("This Rule is already generating Tasks.");
            } else {
              alertService.addErrorAlert("Unable to create tasks.");
            }
          });
        }
      }
    }
  ])
  .service('complianceService', ['complianceDataService',
    function(complianceDataService) {

      var buildComplianceSummaryForView = function(nonCompliantProperties) {
        var summary = [];
        _.each(nonCompliantProperties, function (nonCompliantProperty) {
          var existing = _.find(summary, {'name': nonCompliantProperty.notCompliantPropertyName});

          var rule = nonCompliantProperty.rule;

          if (rule) {
            if (existing) {
              existing.rules.push(rule.bn);
            } else {
              summary.push({
                name: nonCompliantProperty.notCompliantPropertyName,
                rules: [rule.bn],
                propertyBn: nonCompliantProperty.notCompliantPropertyBn
              });
            }
          } else {
            console.error("Unable to find properly inflate non-compliant property.", nonCompliantProperty);
          }


        });

        return summary;
      };

      var getComplianceSummaryByEntityBn = function(bn, filtered, callback) {
        var promise = complianceDataService.getComplianceSummaryByEntityBn(bn, filtered);
        promise.then(function(data) {
          var outOfCompliance = data.data;
          var nonCompliantProperties = outOfCompliance.properties;
          var summary = buildComplianceSummaryForView(nonCompliantProperties);
          return callback(summary);
        })
      };

      var getCompliancePropertyCountByEntityBn = function(bn, filtered, callback) {
        var promise = complianceDataService.getCompliancePropertyCountByEntityBn(bn, filtered);
        promise.then(function(data) {
          var nonCompliantResult = data.data.numOfProperties;
          return callback(nonCompliantResult);
        })
      };

      var getNonCompliantEntitiesCountByRuleBn = function(bn, callback) {
        var promise = complianceDataService.getNonCompliantEntitiesCountByRuleBn(bn);
        promise.then(function(data) {
          var nonCompliantEntityCount = data.data.numOfEntities;
          return callback(nonCompliantEntityCount);
        });
      };


      // Public methods.
      return {
        getComplianceSummaryByEntityBn: getComplianceSummaryByEntityBn,
        getCompliancePropertyCountByEntityBn: getCompliancePropertyCountByEntityBn,
        getNonCompliantEntitiesCountByRuleBn: getNonCompliantEntitiesCountByRuleBn
      }
    }
  ]);
