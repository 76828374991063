angular.module("barometerApp.activityStream")
  .controller('ActivityCtrl', ['$scope', 'activityService', 'urlService', 'alertService', 'utilService', 'bitConstants', 'styleService', '$timeout',
    function ($scope, activityService, urlService, alertService, utilService, bitConstants, styleService, $timeout) {
      $scope.activityModel = {
        loaded: false,
        events: {},
        loadDate: moment().format("LT"),
        eventDate: moment().format('L'),
        unformattedEventDate: moment().format("L"),
        pageSize: 10,
        //start it at 1 so we don't need to do any goofy math when we click it next time or are calculating rowsDisplayed
        loadMoreClicked: 1,
        prevDate: null,
        nextDate: null,
        tableType: 'undefined',
        isNews: 'undefined',
        todaysDate: moment().format("LL")
      };

      // When bs-datepicker init's, it nulls out it's model (IDK why).
      // So we need to re-set the model after letting it init.
      $timeout(function(){
        $scope.activityModel.unformattedEventDate = moment().format("L");
      });
    //testing
      $scope.bsDateFormat = utilService.getDateFormatForBootstrapDatepicker();
      $scope.$watch("activityModel.unformattedEventDate", function (n, o) {
        n = moment(n, 'L').format('L');
        o = moment(o, 'L').format('L');
        if (!n || n == o) return;
        // BARO 11017
        // this is a firefox hack since FF can't handle moment w/out the second paramter that identifies the format of the incoming date
        // this is an issue when the prev/next buttons are clicked, as they are string values, not date objects.
        if (typeof n === "string") {
          $scope.activityModel.eventDate = moment(n, "L").format("L");
        } else {
          var date = new Date(n);
          date = new Date(date.getTime() + (date.getTimezoneOffset() * 60000));
          $scope.activityModel.eventDate = moment(date, "L").format("L");
        }
        $scope.loadActivities();
      });
      $scope.reloadSection = function () {
        $scope.activityModel.loadMoreClicked = 1;
        $scope.activityModel.events = {};
        $scope.activityModel.eventDate = moment().format('L');
        $scope.activityModel.unformattedEventDate = moment().format("L");
        $scope.activityModel.loadDate = moment().format("LT");
        $scope.loadActivities();
      };
      $scope.loadDate = function (direction) {
        $scope.activityModel.eventDate = direction === 'next' ? $scope.activityModel.nextDate : $scope.activityModel.prevDate;
        $scope.activityModel.unformattedEventDate = moment($scope.activityModel.eventDate, "L").format("L");
      };
      $scope.loadMore = function () {
        $scope.activityModel.loadMoreClicked++;
        //load more activities if <= 500 are being requested
        if ($scope.activityModel.loadMoreClicked <= 20) {
          $scope.loadActivities();
        } else {
          alertService.addAlert({
            type: 'error', message: 'You can only view 500 activities at a given time'
          })
        }
      };
      $scope.snagDetails = function (activity) {
        if ($scope.activityModel.events[activity.eventTime + (activity.personBn || activity.entityBn)] && $scope.activityModel.events[activity.eventTime + (activity.personBn || activity.entityBn)].length > 0) {
          $scope.activityModel.events[activity.eventTime + (activity.personBn || activity.entityBn)].length = 0;
          return;
        }

        var params = {};
        switch ($scope.activityModel.tableType) {
          case "DashboardActivityStream" :
            params = {
              entityBn: activity.entityBn,
              eventDate: $scope.activityModel.eventDate
            };
            break;
          case "CircleActivityStream" :
          case "DemandActivityStream" :
          case "StrategyActivityStream" :
          case "Worksheet2ActivityStream" :
            params = {
              sourceBn: urlService.getEntityBn(),
              entityBn: activity.entityBn,
              eventDate: $scope.activityModel.eventDate
            };
            break;
          case "EntityActivityStream" :
            params = {
              sourceBn: urlService.getEntityBn(),
              startEventDate: activity.eventTime,
              endEventDate: activity.eventTimeEndOfActivity,
              eventDate: $scope.activityModel.eventDate,
              entityBn: activity.actingPersonBn
            };
            break;
          case "ProfileActivityStream" :
            params = {
              sourceBn: urlService.getEntityBn(),
              startEventDate: activity.eventTime,
              endEventDate: activity.eventTimeEndOfActivity,
              eventDate: $scope.activityModel.eventDate,
              entityBn: activity.entityBn
            };
            break;
        }
        params.tableType = $scope.activityModel.tableType;
        params.eventDate =
          activityService.getActivityDetails(params).then(function (data) {
            _.each(data.data.events, function (event, index) {
              event.scenarioBn = event.scenarioBn || null;
              event.dateString = calculateDateDiff(event.eventTime);
              event.personUrl = event.actingPersonBn !== "SYSTEM" ? urlService.getBaseUrl() + "profile/" + event.actingPersonBn : null;
            });
            if (data.data.events.length) {
              var evt = data.data.events[0];
              activity.dateString = calculateDateDiff(evt.eventTime);
              activity.actingPersonName = evt.actingPersonName;
              activity.personUrl = evt.actingPersonBn !== "SYSTEM" ? urlService.getBaseUrl() + "profile/" + evt.actingPersonBn : null;
            }
            $scope.activityModel.events[activity.eventTime + (activity.personBn || activity.entityBn)] = data.data.events;
          });
      };
      window.calculateDateDiff = function (dateTime) {
        return moment(dateTime).fromNow();
      };

      $scope.setActivityType = function (sectionBn) {
        switch (sectionBn) {
          case "CAY" :
            $scope.activityModel.tableType = 'CircleActivityStream';
            $scope.activityModel.isNews = true;
            break;
          case "DAC":
            $scope.activityModel.tableType = 'DemandActivityStream';
            $scope.activityModel.isNews = true;
            break;
          case "DAY" :
            $scope.activityModel.tableType = 'DashboardActivityStream';
            $scope.activityModel.isNews = true;
            break;
          case "EAY" :
            $scope.activityModel.tableType = 'EntityActivityStream';
            $scope.activityModel.isNews = false;
            break;
          case "PAY" :
            $scope.activityModel.tableType = 'ProfileActivityStream';
            $scope.activityModel.isNews = true;
            break;
          case "SAC":
            $scope.activityModel.tableType = 'StrategyActivityStream';
            $scope.activityModel.isNews = true;
            break;
          case "WAY" :
            $scope.activityModel.tableType = 'Worksheet2ActivityStream';
            $scope.activityModel.isNews = true;
        }
      };
      $scope.getDetailsClass = function (len) {
        return len > 0 ? 'icon-caret-hide' : 'icon-caret-show';
      };
      var getEventDate = function (date) {
        if (!moment(date, 'L').isValid()) {
          return moment();
        }
        else {
          return moment(date.toString(), 'L');
        }
      };
      $scope.loadActivities = function () {
        activityService.getActivities({
          sourceBn: urlService.getEntityBn(),
          pageIndex: 0,
          pageSize: $scope.activityModel.pageSize * $scope.activityModel.loadMoreClicked,
          eventDate: getEventDate($scope.activityModel.eventDate),
          tableType: $scope.activityModel.tableType
        }).then(function (data) {

          _.each(data.data.activities, function (obj, index) {
            if (!obj.activity) {
              return;
            }
            obj.activity.dateString = calculateDateDiff(obj.activity.eventTime);
            obj.activity.eventTimeString = moment(obj.activity.eventTime).format("MMMM Do YYYY, h:mm:ss a ");
            obj.activity.personUrl = obj.activity.actingPersonBn !== "SYSTEM" ? urlService.getBaseUrl() + "profile/" + obj.activity.actingPersonBn : null;
            if (obj.activity.entityBn) {
              obj.activity.entityLink = {
                name: obj.activity.entityName || 'N/A', bn: obj.activity.entityBn
              };
              obj.activity.entityIconClass = styleService.getIconClassForEntityTypeCode(bitConstants.getEntityTypeForBnCode(utilService.getBnCode(obj.activity.entityBn)).typeCode);
            }
          });
          $scope.activityModel.nextDate = data.data.nextDataDate === null ? null : moment(data.data.nextDataDate).format('l');
          $scope.activityModel.prevDate = data.data.previousDataDate === null ? null : moment(data.data.previousDataDate).format('l');
          $scope.activityModel.totalRows = data.data.rowCount;
          $scope.activityModel.loaded = true;
          $scope.activityModel.activities = data.data.activities;
          $scope.activityModel.rowsDisplayed = $scope.activityModel.pageSize * ($scope.activityModel.loadMoreClicked);
        })
      };
      $scope.$on('load', function (event, viewId) {
        if (viewId === 'activityStream_table') {
          // only load if not previously loaded
          if (!$scope.activityModel.loaded) {
            $scope.setActivityType($scope.sectionBn);
            $scope.loadActivities();
          }
        }
      });

    }])
  .controller('EventStreamCtrl', ['$scope', 'activityService',
    function ($scope, activityService) {
      $scope.eventStream = {
        events: [],
        category: 'all'
      };
      $scope.eventStream.pagination = {
        noOfPages: 0,
        currentPage: 1,
        numRecords: 0,
        pageSize: 20,
        rowCount: 0,
        maxSize: 100
      };
      $scope.getData = function () {
        var first = ($scope.eventStream.pagination.currentPage - 1) * $scope.eventStream.pagination.pageSize;
        activityService.getEventStreamData($scope.eventStream.category, first, $scope.eventStream.pagination.pageSize).then(function (response) {
          $scope.eventStream.events = response.data.events;
          // total num of records
          $scope.eventStream.pagination.rowCount = response.data.rowCount;
          // num of records on current page: whatever is less, page size or total count
          $scope.eventStream.pagination.numRecords = Math.min(response.data.rowCount, $scope.eventStream.pagination.pageSize);
          $scope.eventStream.pagination.noOfPages = Math.ceil(response.data.rowCount / $scope.eventStream.pagination.pageSize);
        });
      };
      $scope.loadEvents = function (category) {
        $scope.eventStream.pagination.currentPage = 1;
        $scope.eventStream.category = category;
        $scope.getData();
      };
      $scope.formatDate = function (dateString) {
        return moment(dateString).format("LLL");
      };
      $scope.$watch('eventStream.pagination', function (newValue, oldValue) {
        if (typeof newValue === "undefined" || angular.equals(newValue == oldValue)) return;
        $scope.getData();
      }, true);
    }])
  .controller('ContributionCtrl', ['$scope', '$timeout', 'activityService', 'adminService', 'utilService',
    function ($scope, $timeout, activityService, adminService, utilService) {

      var loadInProgress;

      var searchChanged;

      $scope.contributionStream = {
        contributions: [],
        totalContributions: undefined,
        userRoles: undefined,
        sortColumn: ''
      };
      var date = moment().format('L');

      $scope.dateInfo = {};
      $scope.dateInfo.eventDate = date;
      $scope.dateInfo.bsDateFormat = utilService.getDateFormatForBootstrapDatepicker();

      var getEventDate = function (date) {
        if (!moment(date, 'L').isValid()) {
          return moment();
        }
        else {
          return moment(date.toString(), 'L');
        }
      };

      $scope.contributionStream.pagination = {
        noOfPages: 1,
        currentPage: 1,
        numRecords: 0,
        pageSize: 10,
        rowCount: 0,
        maxSize: 100
      };

      $scope.contributionStream.requestConfig = {
        dateRange: $scope.dateInfo.eventDate,
        dayRange: 7,
        tableType: 'ContributionActivityStream',
        eventDate: getEventDate($scope.dateInfo.eventDate),
        pageSize: 10,
        searchString: '',
        sortProperty: $scope.contributionStream.sortColumn,
        roleString: '',
        pageIndex: $scope.contributionStream.pagination.currentPage
      };

      $scope.sortColumn = function (sortProperty) {
        if ($scope.contributionStream.sortColumn.indexOf(sortProperty) > -1) {
          $scope.contributionStream.sortColumn = $scope.contributionStream.sortColumn.indexOf('descending') > -1 ? sortProperty + '_ascending' : sortProperty + '_descending';
        } else
          $scope.contributionStream.sortColumn = sortProperty + '_descending';

        $scope.contributionStream.requestConfig.sortProperty = $scope.contributionStream.sortColumn;
        $scope.getData();
      };

      $scope.formatDate = function (dateString) {
        return moment(dateString).format("LLL");
      };
      $scope.getData = function () {
        if (!loadInProgress) {
          loadInProgress = true;
          $scope.contributionStream.requestConfig.eventDate = getEventDate($scope.dateInfo.eventDate);
          $scope.contributionStream.requestConfig.pageIndex = ($scope.contributionStream.pagination.currentPage - 1) * $scope.contributionStream.pagination.pageSize;

          activityService.getActivities($scope.contributionStream.requestConfig).then(function (response) {
            $scope.contributionStream.contributions = response.data.displayContributions;
            $scope.contributionStream.totalContributions = response.data.totalContributions;
            $scope.contributionStream.sortColumn = response.data.sortProperty;
            $scope.contributionStream.pagination.rowCount = response.data.rowCount;
            $scope.contributionStream.pagination.numRecords = Math.min(response.data.rowCount, $scope.contributionStream.pagination.pageSize);
            $scope.contributionStream.pagination.noOfPages = Math.ceil(response.data.rowCount / $scope.contributionStream.pagination.pageSize);
            loadInProgress = false;
          })
        }
      };

      $scope.getUserRoles = function () {
        adminService.getUserRoles().then(function (response) {
          $scope.contributionStream.userRoles = response.data;
        });
      };

      $scope.loadContributions = function () {
        $scope.contributionStream.pagination.currentPage = 1;
        $scope.getData();
      };

      $scope.queueSearchChange = function () {
        $timeout.cancel(searchChanged);
        searchChanged = $timeout($scope.loadContributions, 500);
      };

      $scope.$watch('contributionStream.pagination', function (newValue, oldValue) {

        // Only reload data if currentPage property has changed
        if (newValue.currentPage != oldValue.currentPage) {
          $scope.getData();
        }
      }, true);

      // Init component
      $scope.getUserRoles();
      $scope.loadContributions();
    }]);
