angular
  .module('barometerApp.relationalDiagram')
  .controller('RelationalDiagramAddToGroupController', RelationalDiagramAddToGroupController);

RelationalDiagramAddToGroupController.inject = [
  '$modalInstance',
  '$rootScope',
  '$scope',
  'nodeBn',
  'config'
];

/**
 * This is a modal popped from relational-diagram-sidebar-controller.
 */
function RelationalDiagramAddToGroupController($modalInstance,
                                               $rootScope,
                                               $scope,
                                               nodeBn,
                                               config) {

  $scope.tempConfig = _.cloneDeep(config);
  $scope.selectedGroup = {};

  //--------------------------------------
  // PRIVATE FUNCTIONS
  //--------------------------------------

  function isBn(bn) {
    var regex = /[A-Z0-9]{12}/;
    var match = bn.match(regex);
    //console.log("isBn match = " + match);
    return match != null;
  }

  //--------------------------------------
  // PUBLIC FUNCTIONS
  //--------------------------------------

  $scope.cancel = function () {
    $modalInstance.dismiss('cancel');
  };

  $scope.save = function () {
    //
    var myGroup = $scope.selectedGroup.group;
    //
    if (!isBn(nodeBn)) {
      // If not a BN, then we are adding a group to a group.
      $modalInstance.close(myGroup.data.id);
    } else {
      // If a BN, then we are adding a node to a group.
      if (!myGroup.data.children) {
        myGroup.data.children = []
      }
      myGroup.data.children.push(nodeBn);
      $modalInstance.close(myGroup);
    }
  };

  $scope.selectedGroupChanged = function () {
    // Pointless? Commented out.
    //$scope.selectedGroup = $scope.selectedGroup;
  };

  //--------------------------------------
  // DO IT!
  //--------------------------------------
  
  // TODO This looks like code to remove entity nodes from
  // TODO the array of groups -- but why?
  // TODO Maybe there was a BARO defect for this?
  // TODO Commenting out til it makes sense.
  /*
  if (isBn(nodeBn)) {
    var index = 'undefined';
    for (var g in $scope.tempConfig.groups) {
        if ($scope.tempConfig.groups.hasOwnProperty(g)) {
            if ($scope.tempConfig.groups[g].data.id == bn) {
                index = g;
            }
        }
    }
    // This may be superfluous, or it may be trying to make sure our node BN doesn't show up as a group.
    if (index != 'undefined') {
      //console.log("Splicing index = " + index);
      $scope.tempConfig.groups.splice(index, 1);
    }
  }
  */
}