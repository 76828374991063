angular.module('barometerApp.search')
  .controller('GlobalSearchCtrl', ['$scope', 'searchService', '$window', 'bitConstants', 'utilService', 'urlService', '$document', 'queryKeyService', 'styleService',
    function ($scope, searchService, $window, bitConstants, utilService, urlService, $document, queryKeyService, styleService) {
      $scope.groups = [];
      $scope.topHit = {topHit: true, entityTypeResults: []};
      $scope.searchModel = {
        selected: undefined
      };
      $scope.openTypeahead = false;
      $scope.mouseInResultsPopup = false;
      // -1 represents the default 'browse all'
      $scope.activeGroupIndex = -1;
      $scope.activeResultsIndex = -1;

      $scope.$on('recentsRetrieved', function (event, recentsArray, isCollectionPage) {

        // when empty, API returns a string
        if (typeof recentsArray === 'string') {
          $scope.recents = [];
        }
        else if (isCollectionPage) {
          $scope.recents = recentsArray.splice(0, 10);
        }
        else {
          $scope.recents = recentsArray.splice(1, 10); // excludes the first item. the first item is already displayed in the search box
        }

      });

      $scope.getEntityDisplayName = function (resultGroup) {
        if (resultGroup.topHit) {
          return "Top Hit";
        }
        return bitConstants.getEntityTypeDisplayNameForTypeCode(resultGroup.entityTypeCode, true);
      };

      $scope.getHeaderIconClass = function (resultGroup) {
        if (resultGroup.topHit) {
          return styleService.getIconClassForEntityTypeCode(resultGroup.entityTypeResults[0].entityType);
        }
        return styleService.getIconClassForEntityTypeCode(resultGroup.entityTypeCode);
      };

      $scope.getMatches = function (query) {
        return searchService.searchUnified(query, 15).then(function (results) {
          $scope.count = results.data.count;
          $scope.groups = results.data.matches;
          //set top hit
          if ($scope.count > 0) {
            if (!$scope.openTypeahead) {
              $scope.openTypeahead = true;
              prototype.closeDropdowns();
            }
            $scope.topHit.topHit = true;
            $scope.topHit.entityTypeResults = [
              {
                bn: results.data.topHit.bn,
                entityType: results.data.topHit.entityType,
                name: results.data.topHit.name,
                isVisible: results.data.topHit.isVisible
              }
            ];
            $scope.groups.splice(0, 0, $scope.topHit);
          }

          $scope.resetCursorToDefault();
        });
      };

      $scope.handleDown = function (event) {
        event.preventDefault();//keep cursor from jumping to end when pressing down
        if ($scope.isOpen()) {
          if ($scope.activeGroupIndex < 0) {
            $scope.resetCursorToDefault();
          } else {
            var group = $scope.groups[$scope.activeGroupIndex];
            //if there are more results then increment that array index
            if (group.entityTypeResults[$scope.activeResultsIndex + 1]) {
              $scope.activeResultsIndex++;
            } else {
              //attempt to increment group index
              if ($scope.groups[$scope.activeGroupIndex + 1]) {
                $scope.activeGroupIndex++;
                $scope.activeResultsIndex = 0;
              }

            }
          }
        }
      };

      $scope.handleUp = function (event) {
        event.preventDefault();//keep cursor from jumping to beginning when pressing up
        if ($scope.isOpen()) {
          if ($scope.isCursorAtDefault()) {
            $scope.activeGroupIndex--;
            $scope.activeResultsIndex--;
          }
          if ($scope.activeGroupIndex > -1) {
            var group = $scope.groups[$scope.activeGroupIndex];
            //attempt to decrement results index
            if (group.entityTypeResults[$scope.activeResultsIndex - 1]) {
              $scope.activeResultsIndex--;
            } else {
              //attempt to decrement groups index, then set results index to last item
              if ($scope.groups[$scope.activeGroupIndex - 1]) {
                $scope.activeGroupIndex--;
                $scope.activeResultsIndex = $scope.groups[$scope.activeGroupIndex].entityTypeResults.length - 1;
              }
            }
          }
        }
      };

      $scope.handleEnter = function () {
        if ($scope.activeGroupIndex == -1) {
          $scope.redirectToSearchResultsBrowse();
        } else {
          var result = $scope.groups[$scope.activeGroupIndex].entityTypeResults[$scope.activeResultsIndex];
          if (result) {
            var url = BnCodeToUrl[utilService.getBnCode(result.bn)];
            if (url != null) {
              $window.location.href = urlService.getBaseUrl() + url + '/' + result.bn;
            }
          }
        }
        $scope.openTypeahead = false;
      };

      $scope.handleEscape = function () {
        $scope.openTypeahead = false;
        $scope.resetCursorToDefault();
      };

      $scope.isCursorAtDefault = function () {
        return $scope.activeGroupIndex == 0 && $scope.activeResultsIndex == 0;
      };

      $scope.isOpen = function () {
        return $scope.openTypeahead;
      };

      $scope.mouseEnteredDropdown = function () {
        $scope.mouseInResultsPopup = true;
      };

      $scope.mouseExitedDropdown = function () {
        $scope.mouseInResultsPopup = false;
      };

      $scope.resetCursorToDefault = function () {
        if ($scope.groups.length > 0) {
          $scope.activeGroupIndex = 0;
          $scope.activeResultsIndex = 0;
        } else {
          $scope.activeGroupIndex = -1;
          $scope.activeResultsIndex = -1;
        }
      };

      $scope.redirectToSearchResultsBrowse = function () {
        if ($scope.searchModel.selected) {
          //remove any slashes
          var searchString = $scope.searchModel.selected.replace(/[\\\/]/g, '');
          var promise = queryKeyService.getQueryKeyForGlobalSearch(searchString);
          promise.then(function (results) {
            var uuid = results.data.queryKey;
            if (uuid) {
              $window.location.href = urlService.getBaseUrl() + 'search-results/' + 'querykey' + uuid;
            }
          });
        }
      };

      $scope.doSearch = function (searchVal) {
        if (searchVal) {
          $scope.getMatches(searchVal);
        } else {
          $scope.openTypeahead = false;
        }
      };

      //is it ok to do this in a controller??
      $document.bind('click', function () {
        if (!$scope.mouseInResultsPopup) {
          $scope.openTypeahead = false;
          $scope.resetCursorToDefault();
        }
        $scope.$digest();
      });
    }]);
