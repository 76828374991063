angular
  .module('barometerApp.relationalDiagram')
  .controller('RelationalDiagramCreateGroupController', RelationalDiagramCreateGroupController);

RelationalDiagramCreateGroupController.inject = ['$scope', '$modalInstance', 'config', '$rootScope', 'bn'];

/**
 * This is a modal popped from relational-diagram-sidebar-controller.
 */
function RelationalDiagramCreateGroupController ($scope, $modalInstance, config, $rootScope, bn) {

  $scope.newConfig = _.cloneDeep(config);
  $scope.groupEntity = {
    classes: "group",
    position: {
      x: 0,
      y: 0
    },
    group: "nodes",
    data: {
      parent: null,
      children: [bn],
      id: "New Group",
      entity: {
        name: "New Group",
        bn: "000000000000",
        pathType: "direct"
      }
    }
  };

  /**
   * This function is and should be scoped/public. It is used from an html partial.
   */
  $scope.cancel = function () {
    $modalInstance.dismiss('cancel');
  };

  /**
   * This function is and should be scoped/public. It is used from an html partial.
   */
  $scope.save = function () {
    const newGroup = $scope.groupEntity;

    // Name and node ID are the same
    newGroup.data.entity.name = newGroup.data.id;
    $modalInstance.close(newGroup);
  };
}
