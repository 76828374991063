angular.module('barometerApp.pagination')
    .directive('baroPagination', [function () {
        return {
            restrict: 'EA',
            scope: {
                numPages: '=',
                currentPage: '=',
                maxSize: '=',
                onSelectPage: '&',
                unfilteredRowCount: '=',
                rowCount: '=',
                pageSize: '=',
                numRecords: '='
            },
            templateUrl: '/b/js/src/bit.ng/baro/pagination/pagination.html',
            link: function (scope, element, attrs) {
                scope.showDropDown = true;
                scope.currentRecord = scope.pageSize * (scope.currentPage - 1) + 1;
                scope.lastVisibleRecord = scope.currentRecord + scope.numRecords - 1;

                scope.$watch('numPages + currentPage + maxSize + numRecords', function (n,o) {
                    scope.pages = [];
                    if(scope.currentPage > 0 && scope.currentPage <= scope.numPages) {
                        //valid page number entered
                        scope.currentRecord = scope.pageSize * (scope.currentPage - 1) + 1;
                        scope.lastVisibleRecord = (scope.currentRecord + scope.numRecords - 1) > scope.rowCount ? scope.rowCount : scope.currentRecord + scope.numRecords - 1;

                        // Default page limits
                        var startPage = 1, endPage = scope.numPages;

                        // decide on text or drop down
                        if (scope.maxSize && scope.maxSize < scope.numPages) {
                            scope.showDropDown = false;
                        } else {
                            // Add page number links
                            for (var number = startPage; number <= endPage; number++) {
                                scope.pages.push(number);
                            }
                            if(isIELessThan(11)){
                                var select = element.find("select");
                                for(var x = 0;x  < select.length; x++){
                                    select[x].parentNode.insertBefore(select[x], select[x]);
                                }
                            }
                        }

                    } else if(scope.currentPage > scope.numPages) {
                        //number greater than total pages, default to last page
                        scope.selectPage(scope.numPages);
                    } else if(parseInt(scope.currentPage) && scope.currentPage < 1) {
                        //number less than 1 default to 1
                        scope.selectPage(1);
                    }
                });
                scope.noPrevious = function () {
                    return scope.currentPage === 1;
                };
                scope.nextPage = function() {
                    scope.currentPage++;
                }
                scope.prevPage = function() {
                    scope.currentPage--;
                }
                scope.noNext = function () {
                    return scope.currentPage === scope.numPages;
                };
                scope.isActive = function (page) {
                    return scope.currentPage === page;
                };

                scope.selectPage = function (page) {
                    if (!scope.isActive(page) && page > 0 && page <= scope.numPages) {
                        scope.currentPage = page;
                        scope.onSelectPage({ page: page });
                    }
                };
            }
        };
    }]);