/**
 * This file tries to follow John Papa's Angular 1 style guide (adjusted to our AngularJS version).
 *
 * https://github.com/johnpapa/angular-styleguide/blob/master/a1/README.md
 */
angular
  .module('barometerApp.taskManager')
  .factory('taskManagerUtilityService', taskManagerUtilityService);

taskManagerUtilityService.$inject = [];

/**
 * Stateless utility operations.
 */
function taskManagerUtilityService() {
  //
  return {
    collateAssignments: collateAssignments,
    findAssignment: findAssignment,
    getTaskBns: getTaskBns
  };

  /**
   * @param taskBn
   * @param assignmentType ASSIGNED_TO or ASSIGNED_BY, aka relationshipType.
   * @param assignmentData A response from getAssignments.
   * @returns {*}
   */
  function findAssignment(taskBn, assignmentType, assignmentData) {
    //
    // IE doesn't allow the Array.find method, so we have to use a
    // basic for loop to return a object if the user is using IE.
    // https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/Array/find
    var isIE = !!document.documentMode;

    if (isIE) {
      for(var i = 0; assignmentData.rows.length > i; i++) {
        if (assignmentData.rows[i].perspectiveBn == taskBn && assignmentData.rows[i].relationshipType == assignmentType) {
          return assignmentData.rows[i];
        }
      }
    } else {
      return assignmentData.rows.find(function (row) {
        return row.perspectiveBn == taskBn && row.relationshipType == assignmentType;
      });
    }
  }

  /**
   * Insert assignment data into task rows.
   *
   * @param taskData A response from getTasks(). Its rows will be augmented.
   * @param assignmentData A response from getAssignments().
   */
  function collateAssignments(taskData, assignmentData) {
    //
    angular.forEach(taskData.rows, function (row) {
      var taskBn = row.bn;
      var assignedTo = findAssignment(taskBn, "ASSIGNED_TO", assignmentData);
      var assignedBy = findAssignment(taskBn, "ASSIGNED_BY", assignmentData);
      // The cell renderer will parse this and make a link.
      if (assignedTo) row.assignedTo = [assignedTo.bn, assignedTo.name];
      if (assignedBy) row.assignedBy = [assignedBy.bn, assignedBy.name];
    })
  }

  /**
   * Extract task BNs from a response.
   *
   * @param taskData A response from getTasks().
   */
  function getTaskBns(taskData) {
    //
    var rows = taskData.rows;
    var taskBns = [];
    for (var i = 0; i < rows.length; i++) {
      var taskBn = rows[i].bn;
      taskBns[i] = taskBn;
    }
    return taskBns;
  }
}