angular.module('barometerApp.common')
  .service('boxDiagramService', ['$http', function ($http) {
    return {
      getBoxData: function(bn){
        return $http({
          method: "GET",
          url: '/b/api/entityboxes/' + bn
        });
      }
    };
  }]);