angular.module('barometerApp.common')
    .factory('ganttDataService', ['$window', 'alertService', '$http',
        function ($window, alertService, $http) {
            return {
                fetchData: function (queryParams) {
                    return $http({
                        method: 'GET',
                        url: '/b/api/content/demandimpact/gantt',
                        params: queryParams
                    }).
                        success(function (data, status, headers, config) {
                        }).
                        error(function (data, status, headers, config) {
                            console.log("Service Error:", status);
                        });
                }
            }
        }]);