angular.module('barometerApp.bubbleChart')
  .factory('bubbleChartService', ['$q', 'layoutService', 'searchService', 'entityService', 'scorecardService', 'utilService',
    function ($q, layoutService, searchService, entityService, scorecardService, utilService) {

      var scaleOptions = {
        calculated: function () {
          return {name: 'Fixed Scale', value: SCALE_MODES.CALCULATED_RANGE_FROM_BACKEND};
        },
        autoFit: function () {
          return {name: 'Zoom to Fit', value: SCALE_MODES.HIGHCHARTS_AUTO_FIT};
        },
        user: function () {
          return {name: 'Custom Range', value: SCALE_MODES.USER_CONFIGURED};
        }
      };

      var SCALE_MODES = {
        CALCULATED_RANGE_FROM_BACKEND: 0,
        HIGHCHARTS_AUTO_FIT: 1,
        USER_CONFIGURED: 2
      };

      var DATA_SOURCE_TYPES = {
        SCORECARD: 'Scorecard',
        CUSTOM_FIELD: 'Custom'
      };

      function getDataSourceTypeOptions() {
        return [
          {name: 'Scorecard', value: DATA_SOURCE_TYPES.SCORECARD},
          {name: 'Custom Field', value: DATA_SOURCE_TYPES.CUSTOM_FIELD}
        ];
      }

      function getDataSourceOptions(entityTypeCode) {
        var deferred = $q.defer();
        var scorecardPromise = layoutService.getScorecardsForTypeCode(entityTypeCode);
        var customFieldPromise = searchService.getFieldsForTypeCode(entityTypeCode);
        $q.all([scorecardPromise, customFieldPromise]).then(function (data) {
          var dataSourceOptions = {};
          var scorecardOptions = data[0].data || [];
          var customFieldOptions = [];
          var customFieldData = data[1].data;

          if (customFieldData && customFieldData.length) {
            _.each(customFieldData, function (field) {
              if ((field.dataType == 'INTEGER' || field.dataType == 'DECIMAL') &&
                (field.displayType == 'NUMBER' || field.displayType == 'CURRENCY' || field.displayType == 'SINGLE_SELECT') &&
                utilService.isControlLevelVisible(field)) {
                customFieldOptions.push(field);
              }
            });
          }

          // Pack them up into a map, keyed on the DATA_SOURCE_TYPE
          dataSourceOptions[DATA_SOURCE_TYPES.SCORECARD] = scorecardOptions;
          dataSourceOptions[DATA_SOURCE_TYPES.CUSTOM_FIELD] = customFieldOptions;
          deferred.resolve(dataSourceOptions);
        });
        return deferred.promise;
      }

      function getXYScaleModeOptions(dataSourceType) {
        var options = [];
        if (angular.isDefined(dataSourceType)) {
          if (dataSourceType == DATA_SOURCE_TYPES.SCORECARD) {
            options = [scaleOptions.calculated(), scaleOptions.autoFit(), scaleOptions.user()];
          }
          else if (dataSourceType == DATA_SOURCE_TYPES.CUSTOM_FIELD) {
            options = [scaleOptions.autoFit(), scaleOptions.user()];
          }
        }
        return options;
      }

      // In this case, a datasource should look like:
      // {
      //     bn: "ZZ4A00000002", dataType: "SCORECARD", name: "System Missing Data", type: "Scorecard"
      //
      //     or
      //
      //     bn: "040L0000004B", dataType: "CUSTOMFIELD", name: "Field 1", type: "Custom"
      // }
      function fetchAxisData(dataSource, worksheetBn, entities, scaleMode, min, max) {
        if (dataSource) {
          if (dataSource.type == DATA_SOURCE_TYPES.SCORECARD) {
            return fetchScorecardAxisData(dataSource.bn, entities, scaleMode, min, max);
          } else if (dataSource.type == DATA_SOURCE_TYPES.CUSTOM_FIELD) {
            return fetchCustomFieldAxisData(dataSource.bn, worksheetBn, scaleMode, min, max);
          }
          // TODO other dataSource dataTypes (ala Summary views)
          console.error('Error in BubbleChartCtrl: DataSource ' + dataSource.dataType + ' not supported.');
          return null;
        } else {
          console.info('BubbleChartCtrl: DataSource is not present.');
          return null;
        }
      }

      function fetchScorecardAxisData(fieldBn, entities, scaleMode, rangeMinFromConfig, rangeMaxFromConfig) {
        var deferred = $q.defer();
        var resultsPromise = scorecardService.getScorecardResultsForEntityBns(fieldBn, _.map(entities, 'bn'));
        var maxScorePromise = scorecardService.getMaxScoreForScorecard(fieldBn);
        $q.all([resultsPromise, maxScorePromise]).then(function (data) {
          var scorecardResultsData = data[0];
          var maxScoreData = data[1];
          var maxScore = 0;
          var rangeMin;
          var rangeMax;


          // Add up all the rule weights to get a max theoretical score
          _.each(maxScoreData.data.rows, function (rule) {
            maxScore += rule.weight.weight;
          });

          // USER_CONFIGURED
          if (scaleMode == SCALE_MODES.USER_CONFIGURED) {
            rangeMax = rangeMaxFromConfig;
            rangeMin = rangeMinFromConfig;
          }

          // HIGHCHARTS_AUTO_FIT
          // Leave null and let Highcharts fit the range
          else if (scaleMode == SCALE_MODES.HIGHCHARTS_AUTO_FIT){
            rangeMin = null;
            rangeMax = null;
          }

          // CALCULATED_RANGE_FROM_BACKEND
          // Range from 0 to maxScore
          else if (scaleMode == SCALE_MODES.CALCULATED_RANGE_FROM_BACKEND) {
            rangeMin = 0;
            rangeMax = maxScore;
          }

          else if (scaleMode != null) {
            console.error('Axis scale mode not handled:', scaleMode)
          }

          scorecardResultsData.data.rangeMax = rangeMax;
          scorecardResultsData.data.rangeMin = rangeMin;
          scorecardResultsData.data.maxScore = maxScore;
          deferred.resolve(scorecardResultsData);
        });
        return deferred.promise;
      }

      function fetchCustomFieldAxisData(fieldBn, worksheetBn, scaleMode, rangeMinFromConfig, rangeMaxFromConfig) {
        var deferred = $q.defer();
        var axisData = {data: []};
        entityService.getFieldValuesForWorksheet(fieldBn, worksheetBn).then(function (data) {
          var rangeMin;
          var rangeMax;

          _.each(data.data, function (item) {
            var value;
            if (item.numberValues && item.numberValues[0]) {
              value = item.numberValues[0];
            } else {
              value = item.displayValues[0];
            }
            axisData.data.push({
              entityBn: item.entityBn,
              score: parseFloat(value),
              scoreDisplayValue: item.displayValues[0]
            });
          });

          // USER_CONFIGURED
          if (scaleMode == SCALE_MODES.USER_CONFIGURED) {
            rangeMax = rangeMaxFromConfig;
            rangeMin = rangeMinFromConfig;
          }

          // HIGHCHARTS_AUTO_FIT
          // Leave null and let Highcharts fit the range
          else if (scaleMode == SCALE_MODES.HIGHCHARTS_AUTO_FIT){
            rangeMin = null;
            rangeMax = null;
          }

          else if (scaleMode != null) {
            console.error('Axis scale mode not handled:', scaleMode)
          }

          axisData.data.rangeMin = rangeMin;
          axisData.data.rangeMax = rangeMax;
          deferred.resolve(axisData);
        });
        return deferred.promise;
      }

      function migrateConfigIfNecessary(config){
        var ds = config.dataSources;

        // See if xDataSourceType, etc, need setting
        _.each(['x','y','z'], function(axis){
          if (ds[axis] && ds[axis].type && ds[axis + 'DataSourceType'] == undefined){
            ds[axis + 'DataSourceType'] = ds[axis].type;
          }
        });

        return config;
      }

      return {
        SCALE_MODES: SCALE_MODES,
        DATA_SOURCE_TYPES: DATA_SOURCE_TYPES,
        getDataSourceTypeOptions: getDataSourceTypeOptions,
        getDataSourceOptions: getDataSourceOptions,
        getXYScaleModeOptions: getXYScaleModeOptions,
        fetchAxisData: fetchAxisData,
        migrateConfigIfNecessary: migrateConfigIfNecessary
      };
    }]);
