angular.module('barometerApp.tenantEndpoint')
.service('tenantEndpointService', ['$http', '$rootScope', 'alertService', 'utilService', 'entityMixedService',
  function($http, $rootScope, alertService, utilService, entityMixedService) {
    return {
      getBarometerPublicFile: function (file) {
        return $http({method: 'GET', url: '/b/api/webhook?file=' + file}).
        success(function (data, status, headers, config) {
        }).
        error(function (data, status, headers, config) {
          console.log('error in beanService.getBarometerPublicCert');
        });
      }
    }
  }]);
