/**
 * Directives are a way to teach HTML new tricks. During DOM compilation directives are matched against the HTML
 * and executed. This allows directives to register behavior, or transform the DOM.
 *
 * This file tries to follow John Papa's Angular 1 style guide (adjusted to our AngularJS version).
 *
 * https://github.com/johnpapa/angular-styleguide/blob/master/a1/README.md
 */
angular
  .module('barometerApp.simpleTable')
  .directive('simpleTableRows', simpleTableRows);

simpleTableRows.$inject = ['$compile'];

/**
 * Factory method for the directive.
 *
 * @param $compile A reference to the global Angular HTML compiler service.
 */
function simpleTableRows($compile) {
  //
  var directive = {
    /**
     * Controller constructor function.
     */
    controller: 'SimpleTableRowsController',
    /**
     * If set to true then the template will replace the current element, rather than append the template to the element.
     */
    replace: true,
    /**
     * This directive has NO ISOLATE SCOPE.
     */
    //scope: {
      // AngularJS operators:
      // @ or @attr - bind a local scope property to the value of DOM attribute.
      // = or =attr - set up bi-directional binding between a local scope property and the parent scope property of name defined via the value of the attr attribute.
      // & or &attr - provides a way to execute an expression in the context of the parent scope.
      // If no property name is provided to the right of the operator, the property name is assumed to be identical to the one provided to the left.
    //},
    /**
     * The link function is responsible for registering DOM listeners as well as updating the DOM.
     * It is executed after the template has been cloned. This is where most of the directive logic will be put.
     * This property is used only if the compile property is not defined.
     */
    link: function (scope, element) {
      /**
       * Rows changed. Rebuild rows.
       */
      scope.$watch('tableModel.rows', function (newVal, oldVal) {
        //
        if (!newVal || angular.equals(newVal, oldVal)) {
          return;
        }
        scope.html = "";
        scope.buildTable(scope.tableModel.rows, scope.tableModel.columns, 0, "");
      }, true);
      /**
       * HTMl changed. Compile changes.
       */
      scope.$watch('html', function (value) {
        //
        if (typeof value === "undefined") {
          return;
        }
        element.html(value);
        $compile(element.contents())(scope);
      }, true);
    }
  };
  return directive;
}

