/**
 * This file tries to follow John Papa's Angular 1 style guide (adjusted to our AngularJS version).
 *
 * https://github.com/johnpapa/angular-styleguide/blob/master/a1/README.md
 */
angular
  .module('barometerApp.remediationForm')
  .factory('remediationFormBuilderDataService', remediationFormBuilderDataService);

remediationFormBuilderDataService.$inject = ['$http', '$q', 'utilService', 'alertService', 'searchService', 'relationshipDataService'];

/**
 * @param $http
 * @param $q
 * @param utilService
 * @returns {{getData: getData}}
 */
function remediationFormBuilderDataService($http, $q, utilService, alertService, searchService, relationshipDataService) {
  //
  var requestModel = {
    //
  };
  //
  return {
    createFormEntity: createFormEntity,
    getFieldOptions: getFieldOptions,
    getPropertyFieldOptions: getPropertyFieldOptions,
    getCustomFieldOptions: getCustomFieldOptions
  };

  /**
    {
      "entityDiscriminator": "RFM",
      "description": "New Created Form",
      "entityTypeCode": "TEC",
      "associatedFields": [
        {
          "associationType": "DATA_DICT",
          "field": {
            "bn": "0446000000TX",
            "propertyType": "NUMBERED"
          }
        },
        {
          "associationType": "CUSTOM",
          "field": {
            "bn": "040L0000003Q"
          }
        },
        {
          "associationType": "DATA_DICT",
          "field": {
            "bn": "0446000000P5",
            "propertyType": "ENUM"
          }
        },
        {
          "associationType": "DATA_DICT",
          "field": {
            "bn": "0446000000P4",
            "propertyType": "STRING"
          }
        }
      ]
    }
   */
  function createFormEntity(jsonRequest) {
    //
    return $http({
      method: 'POST',
      url: '/b/api/entity/create',
      data: jsonRequest,
      headers: {'Content-Type': 'application/json;charset=UTF-8'}
    }).
    success(function (data, status, headers, config) {
    }).
    error(function (data, status, headers, config) {
      var msgString = 'Unable to create entity';
      console.warn('Error in REST call to EntityService.create. Payload=', jsonRequest);
      if (status === 409 && data.errorMessages) {
        msgString += ': ' + data.errorMessages;
      }
      alertService.addErrorAlert(msgString);
    });
  }

  function getFieldOptions(entityType) {
    var fieldOptions = {
      categories: [{key: 'dataDict',
                    name: 'Basic Fields'},
                   {key: 'custom',
                    name: 'Custom Fields'},
        // {key: 'relationship', name: 'Relationships'}
        ],
      availableColumns: []
    };
    var promiseList = [];

    var deferred = $q.defer();

    promiseList.push(getPropertyFieldOptions(entityType).then(function(results) {
      for(var i = 0; i < results.length; i++) {
        results[i].fieldType = 'dataDict';
        fieldOptions.availableColumns.push(results[i])
      }
    }));

    promiseList.push(getCustomFieldOptions(entityType).then(function(results) {
      for(var i = 0; i < results.length; i++) {
        results[i].fieldType = 'custom';
        fieldOptions.availableColumns.push(results[i])
      }
    }));

    promiseList.push(getRelationshipPatterns(entityType).then(function(results) {
      var processedRelationships = [];
      for(var i=0; i < results.length; i++) {
        let targetEntityType = results[i].fromType === entityType ? results[i].toType : results[i].fromType;
        if(processedRelationships.includes(targetEntityType)) continue;
        processedRelationships.push(targetEntityType);
        results[i].name = EntityTypes[targetEntityType].displayName;
        results[i].fieldType = 'relationship';
        fieldOptions.availableColumns.push(results[i]);
      }
    }));

    $q.all(promiseList).then(function(results) {
      deferred.resolve(fieldOptions);
    });

    return deferred.promise;

  }

  function getPropertyFieldOptions(entityType) {
    var deferred = $q.defer();

    $http({
      method: 'GET',
      url: `/b/api/entitytypes/${entityType}/properties?formable=true&displayValues=false`
    }).success(function(data, status, headers, config) {
      deferred.resolve(data);
    }).error(function(data, status, headers, config) {
      console.error('error in getPropertyFieldOptions')
    });
    return deferred.promise;
  }

  function getCustomFieldOptions(entityType) {
    var deferred = $q.defer();
    searchService.getFieldsForTypeCode(entityType).then(function(data) {
      deferred.resolve(data.data);
    });
    return deferred.promise;
  }

  function getRelationshipPatterns(entityType) {
    var deferred = $q.defer();
    relationshipDataService.getRelationshipPatterns(entityType, null, false).then(function(data) {
      deferred.resolve(data.data);
    });
    return deferred.promise;
  }
}
