angular.module('barometerApp.recentLocations')
  .service('recentLocationsService', ['$rootScope', '$http', function ($rootScope, $http) {
    let LAST_UPDATE = 0;
    return {
      getRecentsFromServer: function () {
        return $http({
          method: 'GET',
          url: '/b/api/recents'
        }).success(function (data, status, headers, config) {
          //
        }).error(function (data, status, headers, config) {
          console.log('Error occurred retrieving the recents data');
        });
      },
      getRecentsFromServerAndNotify: function () {
        this.getRecentsFromServer().then(function (data) {
          $rootScope.$broadcast('recentsRetrieved', data.data, true);
        });
      },
      addRecentRecord: function (recentRecord) {
        var getExistingRecordIndex = function (records, record) {
          var existingIndex = -1;
          _.each(records, function (obj, index) {
            if (record[0].url === obj.bubbles[0].url) {
              //exists = true;
              existingIndex = index;
            }
          });
          return existingIndex;
        };
        this.getRecentsFromServer().then(function (data) {
          const delta = new Date().getTime() - LAST_UPDATE;
          if (delta >= 250) {
            var recents = data.data;
            var recentsArray = (recents !== "null" && recents !== '') ? angular.fromJson(recents) : [];

            var existingRecordIndex = getExistingRecordIndex(recentsArray, recentRecord);

            if (existingRecordIndex !== -1) {
              // remove existing, it is added to the beginning below
              recentsArray.splice(existingRecordIndex, 1)
            }
            if (recentsArray.length > 10) {
              recentsArray.pop();
            }

            // add to the beginning of the list
            recentsArray.unshift({"bubbles": recentRecord});

            // store recents data in database.
            var queryData = $.param({
              recentsData: angular.toJson(recentsArray)
            });

            // notify interested components the recents array is available
            $rootScope.$broadcast('recentsRetrieved', recentsArray);

            LAST_UPDATE = new Date().getTime();

            return $http({
              method: 'POST',
              url: '/b/api/recents/update',
              data: queryData,
              headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            }).success(function (data, status, headers, config) {
              //
            }).error(function (data, status, headers, config) {
              console.error('Error occurred updating the recents data');
            });
          }


        });
      }
    }
  }]);
