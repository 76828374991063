angular.module('barometerApp.table')
  .controller('TableCtrlAdd', ['$scope', '$modalInstance', 'alertService', 'searchService', 'bitConstants', 'urlService', 'utilService', 'targetBn', 'tableService', 'sectionBn', '$timeout', 'styleService',
    function ($scope, $modalInstance, alertService, searchService, bitConstants, urlService, utilService, targetBn, tableService, sectionBn, $timeout, styleService) {
      $scope.openDialog = tableService.openDialog;
      $scope.clearAssociations = tableService.clearAssociations;
      var tableData = tableService.getTable(sectionBn);
      var entityType = bitConstants.getEntityTypeForTypeCode(targetBn);
      var entityBn = urlService.getEntityBn();
      var entityBnCode = utilService.getBnCode(entityBn);
      var targetBnType = bitConstants.getEntityTypeForTypeCode(targetBn).bnCode;
      $scope.tableCtrlAddModel = {
        entityType: entityType,
        targetBnType: targetBnType,
        iconClass: styleService.getIconClassForEntityTypeCode(targetBn),
        entityBn: entityBn,
        tableRelationshipType: tableData ? tableData.relationshipType : null,
        isNotOrgToPeople: true,
        weights: [1, 2, 3, 4, 5], // Define in database?
        displayWeightEditor: entityBnCode === '4A' && targetBnType === '3Z' //  Special editor for Scorecard/Rule assoc
      };

      $scope.tableCtrlAddModel.dialogOpts = {
        dialog: 'associationEditorDialog',
        targetBn: $scope.tableCtrlAddModel.result ? $scope.tableCtrlAddModel.result.bn : null,
        dialogSource: 'add',
        sectionBn: sectionBn
      };

      // Decorate the model with filter params
      const decorator = tableService.getDecoratorForSupportedEntityType(entityBnCode, 'TableCtrlAdd');
      if (decorator) {
        const tableCtrlAddModelPromise = decorator.decorateModel.call(decorator.thisValue, $scope.tableCtrlAddModel);
        if (tableCtrlAddModelPromise) {
          tableCtrlAddModelPromise.then(function(data) {
            $scope.tableCtrlAddModel = data;
          }).catch(function (err) {
            console.error(err);
          });
        }
      }

      $scope.tableCtrlAddModel.entityBnCode = entityBnCode;
      $scope.tableCtrlAddModel.entityBnType = utilService.getTypeCodeFromBnCode(entityBnCode);
      $scope.tableCtrlAddModel.header = $scope.tableCtrlAddModel.entityType.displayName;

      $scope.setAssociation = function (result, assoc, relationshipType) {
        tableService.clearAssociations();
        if (result.bn) {
          tableService.updateAssociation("addedAssocs", {targetBn: result.bn, relationshipIdentifier: assoc.value, relationshipType: relationshipType});
        } else {
          tableService.updateAssociation("addedAssocs", {targetBn: assoc.value, relationshipIdentifier: result, relationshipType: relationshipType});
        }
      };

      $scope.$on("OpenDialog", function (event, dlg) {
        tableService.openDialog({dialog: dlg.dialog, targetBn: targetBn, relationshipType: dlg.relationshipType});
      });
      $scope.$on("closeDialog", function (event, dlg) {
        if (dlg === 'addDialog') {
          //The assoc editor has already saved this
          //Hacky, but w/e for now
          $scope.cancel();
        }
      });
      $scope.$on("newItemAssociation", function (event, data) {
        if ($scope.$root.$$phase != '$apply' && $scope.$root.$$phase != '$digest') {
          $scope.$apply(function () {
            $scope.tableCtrlAddModel.result = data;
          });
        } else {
          $scope.tableCtrlAddModel.result = data;
        }

      });

      function buildRelationshipType(data) {
        var result = {};

        result.name = (data.qualifier !== null) ? data.qualifier.name : data.relationshipType.name;
        result.type = data.relationshipType.type;
        result.value = (data.qualifier !== null) ? data.qualifier.bn : "";
        result.isQualified = (data.qualifier !== null);

        return result;
      }

      $scope.getTypeCount = function() {
        if($scope.tableCtrlAddModel && $scope.tableCtrlAddModel.relationshipTypes){
          return _.uniq(_.map($scope.tableCtrlAddModel.relationshipTypes, 'type')).length;
        }
        return 0;
      };

      $scope.getQualifiers = function () {
        var params = {
          source: $scope.tableCtrlAddModel.entityBnCode,
          target: utilService.getBnCode($scope.tableCtrlAddModel.result.bn)
        };

        if ($scope.tableCtrlAddModel.tableRelationshipType) {
          params.relationshipType = $scope.tableCtrlAddModel.tableRelationshipType;
          params.entityBn = $scope.tableCtrlAddModel.entityBnCode === "0E" ? $scope.tableCtrlAddModel.entityBn : $scope.tableCtrlAddModel.result.bn;
        }
        tableService.getQualifiers(params).then(function (data) {
          if(data.data) {
            $scope.tableCtrlAddModel.relationshipTypes = [];
          }
          _.forEach(data.data, function(type) {
            $scope.tableCtrlAddModel.relationshipTypes.push(buildRelationshipType(type));
          });
          tableService.clearAssociations();
          $scope.tableCtrlAddModel.defaultQualifier = (data.data[0] && (data.data[0].qualifier) !== null) ? data.data[0].qualifier.bn :  null;
          //  IE8 fix. For some reason, the person > org qualifier list, which has only 1 item, doesn't display the default barometer position unless it's thrown in to the next digest cycle.
          //  WTF IE8. WTF.
          $timeout(function () {
            $scope.tableCtrlAddModel.relationshipType = buildRelationshipType(data.data[0]);
          });
        });
      };
      $scope.$watch('tableCtrlAddModel.result', function (newValue, oldValue) {
        if (!newValue || angular.equals(newValue, oldValue) || !$scope.tableCtrlAddModel.entityBnCode || !($scope.tableCtrlAddModel.result && $scope.tableCtrlAddModel.result.bn)) return;
        $scope.tableCtrlAddModel.browseSelected = false;
        $scope.getQualifiers();
      });

      $scope.isNotOrgToPeople = function (resultBn, entityBn) {
        var resultBnCode = utilService.getBnCode(resultBn);
        var entityBnCode = utilService.getBnCode(entityBn);
        if (entityBnCode == bitConstants.getEntityTypeForTypeCode('ORG').bnCode) {
          if (resultBnCode == bitConstants.getEntityTypeForTypeCode('PER').bnCode) {
            if (!$scope.tableCtrlAddModel.posRelationshipType) {
              $scope.tableCtrlAddModel.isNotOrgToPeople = false;
              $scope.tableCtrlAddModel.posRelationshipType = 'MEMBER';
            }
            return false;
          }
        }
        return true;
      };

      var browseSelected = $scope.$on("browseSelected", function (event, data) {
        $scope.tableCtrlAddModel.result = data.entity;
        //allow current watch cycle to complete
        $timeout(function () {
          $scope.tableCtrlAddModel.browseSelected = true;
        });
      });
      $scope.cancel = function () {
        tableService.clearAssociations();
        $modalInstance.dismiss('cancel');
        // after opening a quick add, then canceling out of the popups, the backdrop doesn't disappear, not sure why, removing it manually
        $(".modal-backdrop").hide();
      };
      $scope.close = function () {
        // first check that a result was selected
        if ($scope.tableCtrlAddModel.result && $scope.tableCtrlAddModel.result.bn) {
          var assocs = tableService.getAssociations();
          if (assocs.addedAssocs.length == 0 && assocs.removedAssocs.length == 0) {
            if ($scope.tableCtrlAddModel.isNotOrgToPeople) {
              tableService.updateAssociation("addedAssocs", {
                targetBn: $scope.tableCtrlAddModel.result.bn,
                relationshipIdentifier: $scope.tableCtrlAddModel.defaultQualifier,
                // If we are dealing with scorecard/rule relationship, then relationshipType is stored in tableRelationshipType
                // In other cases (in ALL other cases?) it is stored in relationshipType.type
                relationshipType: $scope.tableCtrlAddModel.relationshipType ? $scope.tableCtrlAddModel.relationshipType.type : $scope.tableCtrlAddModel.tableRelationshipType
              });
            } else {
              tableService.updateAssociation("addedAssocs", {
                targetBn: $scope.tableCtrlAddModel.relationshipType.value,
                relationshipIdentifier: $scope.tableCtrlAddModel.posRelationshipType,
                // If we are dealing with scorecard/rule relationship, then relationshipType is stored in tableRelationshipType
                // In other cases (in ALL other cases?) it is stored in relationshipType.type
                relationshipType: $scope.tableCtrlAddModel.relationshipType ? $scope.tableCtrlAddModel.relationshipType.type : $scope.tableCtrlAddModel.tableRelationshipType
              });
            }
          }
          var params = {
            sourceBn: $scope.tableCtrlAddModel.entityBn,
            targetBnCode: utilService.getBnCode($scope.tableCtrlAddModel.entityBn) || null,
            indexBn: $scope.tableCtrlAddModel.result ? $scope.tableCtrlAddModel.result.bn : null,
            associations: tableService.getAssociations(),
            sectionBn: sectionBn,
            // If we are dealing with scorecard/rule relationship, then relationshipType is stored in tableRelationshipType
            // In other cases (in ALL other cases?) it is stored in relationshipType.type
            relationshipType: $scope.tableCtrlAddModel.relationshipType ? $scope.tableCtrlAddModel.relationshipType.type : $scope.tableCtrlAddModel.tableRelationshipType
          };

          if (entityBnCode == '4A' && targetBnType == '3Z') {
            params.targetBn = $scope.tableCtrlAddModel.result.bn;
            params.weight = $scope.tableCtrlAddModel.displayWeightEditor;
            params.updateProperties = tableService.updateProperties;
          }
          tableService.updateAssociations(params);
          browseSelected();
          tableService.clearAssociations();
          $modalInstance.close($scope.tableCtrlAddModel.result);
          // after adding a new entity, and closing the assoc dialog, the backdrop doesn't go away.  This fixes that.  Not sure why it doesn't go away
          $(".modal-backdrop").hide();
        }
      };
    }])
  .controller("TableCtrlWicketAdd", ['$scope', '$modalInstance', 'sourceBn', 'entityTypeCode', function ($scope, $modalInstance, sourceBn, entityTypeCode) {

    //To cancel dialog, simply get a handle on the $scope and call $scope.close

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };
    $scope.close = function () {
      //In here, write your persist code, or if you're doing it in wicket, broadcast section load change
      $modalInstance.close($scope.result);

      // Here is a jQuery hack to get around a bug in the dialog service, where the modal isn't being closed.
      // Issue: https://github.com/angular-ui/bootstrap/issues/441
      $(".modal-backdrop").hide();
    }
  }])
  .controller('TableCtrlBrowse', ['$scope', '$modalInstance', '$rootScope', 'sourceBn', 'targetBn', 'tableService', 'relationshipType', 'bitConstants', 'styleService', 'utilService',
    function ($scope, $modalInstance, $rootScope, sourceBn, entityTypeCode, tableService, relationshipType, bitConstants, styleService, utilService) {
      $scope.tableService = tableService;
      $scope.tableCtrlBrowseModel = {
        sourceBn: sourceBn,
        entityTypeCode: entityTypeCode,
        pagination: tableService.getPagination(),
        editableTableOptions: {
          displayAdd: false,
          parentEntity: null,
          displayPivot: false
        },
        sort: {
          sortProperty: "",
          descending: true
        },
        tableType: "browse",
        breadcrumbs: [
          {
            name: bitConstants.getEntityTypeDisplayNameForTypeCode(entityTypeCode, true),
            bn: null
          }
        ],
        relationshipType: relationshipType
      };

      // Decorate the model with optional filter params
      const decorator = tableService.getDecoratorForSupportedEntityType(utilService.getBnCode(sourceBn), 'TableCtrlBrowse');
      if (decorator) {
        const tableCtrlBrowseModelPromise = decorator.decorateModel.call(decorator.thisValue, $scope.tableCtrlBrowseModel);
        if (tableCtrlBrowseModelPromise) {
          tableCtrlBrowseModelPromise.then(function(data) {
            $scope.tableCtrlBrowseModel = data;
          }).catch(function (err) {
            console.error(err);
          });
        }
      }

      var browseSelected = $scope.$on("browseSelected", function () {
        tableService.setParentEntity(null);
        $scope.close();
      });
      $scope.setParentEntity = function (entity, index) {
        $scope.tableCtrlBrowseModel.breadcrumbs = _.first($scope.tableCtrlBrowseModel.breadcrumbs, index);
        tableService.setParentEntity(entity);
      };
      var getParentEntity = $scope.$watch("tableService.getParentEntity()", function (newValue, oldValue) {
        if (!newValue || newValue == oldValue) {
          return;
        }
        var crumbs = _.find($scope.tableCtrlBrowseModel.breadcrumbs, function (crumb) {
          return crumb.bn == newValue.bn;
        });
        if (!crumbs || crumbs.length > 0) {
          $scope.tableCtrlBrowseModel.breadcrumbs.push(newValue);
        }
        $scope.tableCtrlBrowseModel.editableTableOptions.parentEntity = newValue;
      }, true);

      $scope.entityTypeIconClass = styleService.getIconClassForEntityTypeCode($scope.tableCtrlBrowseModel.entityTypeCode);

      $scope.close = function () {
        $scope.tableCtrlBrowseModel.breadcrumbs.length = 0;
        browseSelected();
        getParentEntity();
        $modalInstance.close($scope.result);
        //$rootScope.$broadcast("browseClosed", {name: "IBM WebSphere Application Server Network Deployment", bn: "ZZ1H0000009H", entityType: "TEC", iconClass: "icon-cogs"})
      }
    }])
  .controller('TableCtrlAssociationEditor', ['$scope','$timeout', '$modalInstance', 'alertService', 'tableService', 'sourceBn', 'targetBn', 'utilService', 'dialogSource', 'sectionBn', 'relationshipType', 'targetEntity', '$rootScope'
    , function ($scope, $timeout, $modalInstance, alertService, tableService, sourceBn, targetBn, utilService, dialogSource, sectionBn, relationshipType, targetEntity, $rootScope) {

      // This is pretty confusing. Not sure how to handle this since the params are being passed in via the dialog service
      // The sourceBn being passed in is the original sourceBn (e.g. the Entity Page you're on)
      // The targetBn being passed in is the association that was clicked
      // The service expects the sourceBn parameter to be what was passed in as targetBn
      // So yeah, misunderstand at your own peril. I love lamp.

      var targetBnCode = utilService.getBnCode(sourceBn);
      var sourceBnCode = utilService.getBnCode(targetBn);

      $scope.associationEditorModel = {
        sourceBn: sourceBn,
        targetBn: targetBn,
        tableId: sectionBn,
        targetBnCode: targetBnCode,
        sourceBnCode: sourceBnCode,
        relationshipType: relationshipType,
        options: {
          displayAdd: false,
          displayPivot: false,
          displayWeightEditor: targetBnCode == '4A' && sourceBnCode == '3Z',  // Special display for Scorecard/Rule assoc TODO: generalize to any assoc with "Data" on it?
          displaySkillLevelEditor: targetBnCode == '0E' && sourceBnCode == '15', // Special display for Profile/Skill assoc
          weights: [1, 2, 3, 4, 5], // Define in database? At least define one constant for both dialogs
          weightSet: false,
          entity: targetEntity
        }
      };
      if (targetBnCode == '4A' && sourceBnCode == '3Z') { // Special editor for Scorecard/Rule assoc
        var params = {
          tableType: "associationdetails",
          sourceBn: sourceBn,
          targetBn: targetBn,
          relationshipType: "RELATED_TO"
        };
        tableService.getAssociationData(params).then(setWeight);
        function setWeight(associationData) {
          //If the weight is out of range things break;
          //Fail nicely
          var weight = associationData.data.rows[0].weight.weight;
          if(weight < 1 || weight > 5) {
            weight = 1;
          }

          $scope.associationEditorModel.options.displayWeightEditor = weight;
          $scope.associationEditorModel.options.weightSet = true;
        }
      } else if (targetBnCode == '0E' && sourceBnCode == '15') {
        var params = {
          tableType: "associationdetails",
          sourceBn: sourceBn,
          targetBn: targetBn,
          relationshipType: "EMPLOYMENT"
        };
        tableService.getAssociationData(params).then(setLevel);
        function setLevel(associationData) {
          var association = associationData.data.rows[0];
          $scope.associationEditorModel.options.displaySkillLevelEditor = association && association.level && association.level.weight ? association.level.weight : "level.0";
          $scope.associationEditorModel.options.levelSet = true;
        }
      }


      $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
        tableService.clearAssociations();
      };

      $scope.close = function () {
        var params = {
          sourceBn: $scope.associationEditorModel.targetBn,
          targetBnCode: $scope.associationEditorModel.targetBnCode,// && $scope.result.bn || null,
          indexBn: $scope.associationEditorModel.sourceBn,
          associations: tableService.getAssociations(),
          sectionBn: sectionBn,
          relationshipType: $scope.associationEditorModel.relationshipType
        };
        if (targetBnCode == '4A' && sourceBnCode == '3Z') { // Update weight
          params.sourceBn = $scope.associationEditorModel.sourceBn;
          params.targetBn = $scope.associationEditorModel.targetBn;
          params.weight = $scope.associationEditorModel.options.displayWeightEditor;
          params.relationshipType = "RELATED_TO";
          tableService.updateProperties(params);
          $modalInstance.close($scope.result);
        }
        else { // Update association, including remove
          //Doing the update callbacks this way prevents transaction errors when trying to update properties and associations at the same time
          if ($scope.associationEditorModel.options.displaySkillLevelEditor) {
            var oldParams = JSON.parse(JSON.stringify(params));
            params.weight = $scope.associationEditorModel.options.displaySkillLevelEditor;
            params.sourceBn = $scope.associationEditorModel.sourceBn;
            params.targetBn = $scope.associationEditorModel.targetBn;
            params.relationshipType = "RELATED_TO";
            tableService.updateProperties(params).then(function(data) {
              //NOTE if the association is removed - updateProperties will have transaction rollback unless with delete after update
              tableService.updateAssociations(oldParams).then(function (data) {
                tableService.clearAssociations();
              });
            });
          } else {
            tableService.updateAssociations(params).then(function (data) {
              tableService.clearAssociations();
            });
          }
          ////// ND: moved this code here so the close happens immediately and doesn't wait for the addAssocition to return (see commented out code 2 lines above)
          if (dialogSource === "add") {
            $timeout(function(){
              $rootScope.$broadcast("closeDialog", "addDialog");
            });
          }

          $modalInstance.close($scope.result);
        }
      };

      $scope.remove = function () {
        var params = {
          sourceBn: $scope.associationEditorModel.targetBn,
          targetBnCode: $scope.associationEditorModel.targetBnCode,
          indexBn: $scope.associationEditorModel.sourceBn,
          associations: {
            addedAssocs: [],
            removedAssocs: [
              {targetBn: $scope.associationEditorModel.sourceBn, relationshipType: $scope.associationEditorModel.relationshipType}
            ]
          },
          sectionBn: sectionBn,
          relationshipType: $scope.associationEditorModel.relationshipType
        };
        tableService.updateAssociations(params).then(function (data) {
          tableService.clearAssociations();
        });
        $modalInstance.close($scope.result);
      };

    }])
  .controller('TableCtrl', ['$scope', 'urlService', 'pageService',
    function ($scope, urlService, pageService) {
      $scope.contBlock = $scope.contentBlock || $scope.cBlock || {
          configuration: {}
        };
      $scope.tableModel = {
        browseTableOptions: {displayAdd: false},
        associationMultiOptions: {
          associationDetailType: "associationMulti",
          displayAdd: false,
          displayPivot: typeof $scope.contBlock.configuration.displayPivot === 'undefined' ? true : $scope.contBlock.displayPivot
        },
        associationOptions: {
          displayAdd: pageService.isEntityPage() && (typeof $scope.contBlock.configuration.displayAdd === 'undefined' ? true : $scope.contBlock.displayAdd),
          displayPivot: typeof $scope.contBlock.configuration.displayPivot === 'undefined' ? true : $scope.contBlock.displayPivot
        },
        connectionAssociationOptions: {
          displayAdd: pageService.isEntityPage()
        },
        employmentAssociationOptions: {
          displayAdd: (!pageService.isEntityPage() || urlService.getEntityTypeCodeOfCurrentPage() == 'ORG') ? false : true
        },
        personCompanyAssociationOptions: {
          displayAdd: (!pageService.isEntityPage() || urlService.getEntityTypeCodeOfCurrentPage() == 'PER' ) ? false : true
        },
        complianceAssociationOptions: {
          displayAdd: true,
          displayPivot: false
        },
        adqAssociationOptions: {
          displayAdd: false,
          displayPivot: false
        },
        crlAssociationOptions: {
          displayAdd: false,
          displayPivot: false
        },
        pagination: {
          pageSize: $scope.contBlock.configuration.pageSize || 10,
          currentPage: 1,
          maxSize: 25
        },
        sort: {
          descending: $scope.contBlock.configuration.sortDescending,
          sortProperty: $scope.contBlock.configuration.sortProperty
        },
        sourceBn: urlService.getEntityBn(),
        queryKey: urlService.getQueryKey(),
        options: {
          displayAdd: true
        }
      };
    }]).controller('SelectEntityController', ['$scope', 'bitConstants',
  function ($scope, bitConstants) {
    $scope.selectorModel = {};
    $scope.selectorModel.entityName = bitConstants.getEntityTypeForBnCode($scope.contentBlock.configuration.fromEntityBnCode).displayName;
    $scope.selectorModel.assocEntityTypeName = bitConstants.getEntityTypeForBnCode($scope.contentBlock.configuration.toEntityBnCode).displayName;

    $scope.selectedEntities = [];
    $scope.entitySelected = function (entity) {
      $scope.selectedEntities.push(entity);
    };
    $scope.publishSelectedEntities = function () {
      // do something with the list of selected entities
    }
  }]).controller('CustomColumnController', ['$scope', '$timeout', 'tableService',
  function ($scope, $timeout, tableService) {

    var columnSpecBn = null;
    var entityTypeCode = null;
    if ($scope.entityType != null) {
      // report column editor mode
      entityTypeCode = EntityTypes[$scope.entityType].bnCode;
    } else {
      // widget column editor mode
      entityTypeCode = $scope.configurationModel.entityType.bnCode;
    }
    columnSpecBn = $scope.workingModel.configuration.columnSpecBn;
    $scope.workingModel.customColumn = {
      categories: ["All Columns"],
      selectedColumn: null,
      selectedCategory: "All Columns",
      entityTypeCode: entityTypeCode,
      columnSpecBn: columnSpecBn,
      selectOptions: {
        allowClear: false
      },
      sortableOptions: {
        handle: '.handle',
        stop: function (event, ui) {
          applyReorder();
          $scope.$apply();
        }
      }
    };

    $scope.select2 = function() {
      // init selec2 on element
      var selector = $('#custom-column-select2');
      selector.select2('destroy');
      selector.select2();
    };

    var applyReorder = function () {
      $.each($('.selected-custom-column'), function (index) {
        $(this).scope().selectedColumn.order = $(this).index();
      });
    };

    var removeColumnFromList = function (column, list) {
      var indexToRemove = null;
      var index = 0;
      _.each(list, function (val) {
        if (column.beanName == "sortableCustomFieldColumnFactory") {
          // all custom fields have the same bean name, key off of the bn
          if (column.bn == val.bn) {
            indexToRemove = index;
          }
        } else if (column.beanName == val.beanName) {
          // check the if a qualifier is selected, multiple columns may exist with different qualifiers selected. Remove the correct one.
          if (column.qualifiers) {
            if (val.selectedQualifier == null && column.selectedQualifier == null) {
              indexToRemove = index;
            } else if (val.selectedQualifier != null && column.selectedQualifier != null && column.selectedQualifier.name == val.selectedQualifier.name) {
              indexToRemove = index;
            }
          } else {
            indexToRemove = index;
          }
        }
        index++;
      });
      list.splice(indexToRemove, 1);
      $timeout(function(){
          $('#custom-column-select2').select2().trigger('change')
      }, 0, false);
    };

    tableService.getCustomColumns($scope.workingModel.customColumn.entityTypeCode, $scope.workingModel.customColumn.columnSpecBn).then(function (data) {
      // Unpack my data.
      $scope.workingModel.customColumn.availableColumns = data.data.available;
      $scope.workingModel.customColumn.selectedColumns = data.data.selected;
      //
      var myExcludedCategories = [];
      var myExcludedColumns = [];
      if ($scope.customColumnController) {
        myExcludedCategories = $scope.customColumnController.options.excludedCategories;
        myExcludedColumns = $scope.customColumnController.options.excludedColumns;
      }
      // Remove excluded columns.
      $scope.workingModel.customColumn.availableColumns =
        $scope.workingModel.customColumn.availableColumns.filter(function( obj ) { return myExcludedColumns.indexOf(obj.name) < 0; });
      // Remove excluded columns.
      $scope.workingModel.customColumn.selectedColumns =
        $scope.workingModel.customColumn.selectedColumns.filter(function( obj ) { return myExcludedColumns.indexOf(obj.name) < 0; });

      // set selected qualifer model
      // njd: this is a bit of a hack.  The selectedQualifer is already set, but for some reason I need to use the
      // exact same object from the array then the drop down shows the correct initial value selected.  Prob related to object equality.
      // I cant just rely on the values in the object to match.
      _.each($scope.workingModel.customColumn.selectedColumns, function (selectedColumn) {
        if (selectedColumn.selectedQualifier != null) {
          _.each(selectedColumn.qualifiers, function (qualifier) {
            if (qualifier.name == selectedColumn.selectedQualifier.name) {
              selectedColumn.selectedQualifier = qualifier;
            }
          });
        }
      });

      _.each($scope.workingModel.customColumn.availableColumns, function (val) {
        // Add my column's category to list if it's not there yet.
        _.each(val.categories, function (category) {
          if (myExcludedCategories.indexOf(category) == -1 &&
            $scope.workingModel.customColumn.categories.indexOf(category) == -1) {
            $scope.workingModel.customColumn.categories.push(category);
          }
        });
      });
      // TODO Dopey way to do this, fer sure. But we don't want to muck existing functionality.
      if (myExcludedCategories.indexOf('All Columns') > -1) {
        var myIndex = myExcludedCategories.indexOf('All Columns');
        $scope.workingModel.customColumn.categories.splice(myIndex, 1);
        $scope.workingModel.customColumn.selectedCategory = $scope.workingModel.customColumn.categories[0];
      }
      $('#custom-column-select2').select2();
    });

    //Custom filter - used to filter the available columns by the category selected in the dropdown
    $scope.categoryFilter = function (data) {
      if ("All Columns" === $scope.workingModel.customColumn.selectedCategory) {
        return true;
      }
      var match = false;
      _.each(data.categories, function (category) {
        if (category === $scope.workingModel.customColumn.selectedCategory) {
          match = true;
        }
      });
      return match;
    };

    $scope.addColumn = function () {
      // add a copy of the column to the selected
      var columnToAdd = $.extend(true, {}, $scope.workingModel.customColumn.selectedColumn);
      // set to new so it will fade in (handled by fadeInNewColumn directive)
      columnToAdd.isNew = true;
      if ($scope.workingModel.customColumn.selectedColumns) {
        // add to end of list
        columnToAdd.order = $scope.workingModel.customColumn.selectedColumns.length;
      }
      $scope.workingModel.customColumn.selectedColumns.push(columnToAdd);
      // remove column from list of available if it does not have any qualifiers
      if ($scope.workingModel.customColumn.selectedColumn.qualifiers == null) {
        removeColumnFromList($scope.workingModel.customColumn.selectedColumn, $scope.workingModel.customColumn.availableColumns);
      }
      $scope.workingModel.customColumn.selectedColumn = null;
      applyReorder();
    };

    $scope.removeColumn = function (column) {
      removeColumnFromList(column, $scope.workingModel.customColumn.selectedColumns);
      /// Add it back to the list of available.  If it has qualifiers it was never removed from the list, so don't add it back.
      if (column.qualifiers == null) {
        $scope.workingModel.customColumn.availableColumns.push(column);
      }
      $timeout(function () {
        // apply re-order after the list on the page has rendered
        applyReorder();
      }, 100);
    };

    $scope.getSortClass = function (column) {

      var sort_disabled = '';
      var no_sort_available = 'sort-button';
      var not_selected = 'sort-button icon-sort-asc';
      var selected_ascending = 'sort-button icon-sort-asc selected';
      var selected_descending = 'sort-button icon-sort-desc selected';
      var sortClass = sort_disabled;

      // for now reports do not support sorting
      if ($scope.workingModel.configuration.contentBlockType != null) {  // nd: hack. this means we are in summary view, so enable sorting.  remove this when we enable sorting on reports.
        if (column.sortPropertyBn == null) {
          sortClass = no_sort_available;
        } else if ($scope.workingModel.configuration.sortProperty == column.sortPropertyBn) {
          if ($scope.workingModel.configuration.sortDescending) {
            sortClass = selected_descending;
          } else {
            sortClass = selected_ascending;
          }
        } else {
          sortClass = not_selected;
        }
      }

      return sortClass;
    };

    $scope.sortButtonClicked = function (column) {
      if ($scope.workingModel.configuration.sortProperty == column.sortPropertyBn) {
        if ($scope.workingModel.configuration.sortDescending) {
          // toggle to no sort
          $scope.workingModel.configuration.sortProperty = null;
        } else {
          // toggle to descending for this column
          $scope.workingModel.configuration.sortDescending = true;
        }
      } else {
        // set this column as the sort, with ascending
        $scope.workingModel.configuration.sortProperty = column.sortPropertyBn
        $scope.workingModel.configuration.sortDescending = false;
      }
    }

  }]);
