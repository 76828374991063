angular.module("barometerApp.entityMixed")
  // I think the last directive in the class was unused. Leaving module for future usage.
  //   .directive('proposedEditsSelector', ['$window', 'urlService',
  //       function ($window, urlService) {
  //
  //           return {
  //               link: function (scope, element) {
  //
  //                   var nativeFormElement = element.find('select');
  //                   var select2Options = {
  //                       allowClear: false,
  //                       width: '100%'
  //                   };
  //
  //                   scope.initSelect2 = function () {
  //                       // set the selected value
  //                       nativeFormElement.find("option[value='" + scope.proposedChangesModel.selectedFilter + "']").attr('selected', 'selected');
  //                       // kick off select2
  //                       nativeFormElement.select2(select2Options);
  //                       nativeFormElement.select2('open');
  //                       nativeFormElement.on('change', function () {
  //                           $window.location.href = urlService.getBaseUrl() + 'proposededitsview' + '?pcdFilter=' + scope.proposedChangesModel.selectedFilter;
  //                       });
  //                       $(nativeFormElement).show();
  //                   }
  //
  //               }
  //           }
  //       }])
;