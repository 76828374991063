angular.module('barometerApp.bubbleChart') // Bubble Chart is the new name for BubbleChart
  .controller('BubbleChartCtrl', ['$scope', 'urlService', 'commonWorksheetService', '$q', 'workspaceService', 'bubbleChartService',
    function ($scope, urlService, commonWorksheetService, $q, workspaceService, bubbleChartService) {

      // Note that $scope.worksheetBn is inherited from bubble-chart directive
      $scope.loading = true;
      $scope.hasData = false;
      $scope.config = null;
      $scope.chartModel = null;

      // See chartModel format in bubble-chartViz
      function buildChartModel(xData, yData, zData) {
        var series = [];
        _.each($scope.entities, function (entity) {
          var xResult = _.find(xData, {entityBn: entity.bn});
          var yResult = _.find(yData, {entityBn: entity.bn});
          var zResult = zData ? _.find(zData, {entityBn: entity.bn}) : null;

          series.push({
            x: xResult ? xResult.score : 0,
            xDisplay: xResult ? xResult.scoreDisplayValue : 0,
            y: yResult ? yResult.score : 0,
            yDisplay: yResult ? yResult.scoreDisplayValue : 0,
            z: zResult ? zResult.score : 0,
            zDisplay: zResult ? zResult.scoreDisplayValue : 0,
            entity: entity
          });
        });

        $scope.chartModel = {
          xAxis: $scope.config.dataSources.x,
          yAxis: $scope.config.dataSources.y,
          zAxis: $scope.config.dataSources.z,
          series: series
        };

        var xScale = $scope.config.dataSources.xScaleMode;
        var yScale = $scope.config.dataSources.yScaleMode;

        $scope.chartModel.xAxis.rangeMin = xData.rangeMin;
        $scope.chartModel.xAxis.rangeMax = xData.rangeMax;
        $scope.chartModel.xAxis.maxScore = xData.maxScore;
        $scope.chartModel.xAxis.scaleMode = xScale;

        $scope.chartModel.yAxis.rangeMin = yData.rangeMin;
        $scope.chartModel.yAxis.rangeMax = yData.rangeMax;
        $scope.chartModel.yAxis.maxScore = yData.maxScore;
        $scope.chartModel.yAxis.scaleMode = yScale;

        if ($scope.chartModel.zAxis) $scope.chartModel.zAxis.maxScore = zData.maxScore;

        $scope.loading = false;
        $scope.hasData = true;
      }

      function reset() {
        $scope.chartModel = null;
        $scope.loading = false;
        $scope.hasData = false;
      }

      function load() {
        $scope.loading = true;
        workspaceService.getAssociated($scope.worksheetBn, 1000).then(function (data) {
          $scope.entityTypes = data.data.results;

          if (!$scope.entityTypes || $scope.entityTypes.length === 0) {
            console.info('No relevant entities in Workspace.');
            $scope.$emit('worksheetOpenSubheader');
            reset();
          }
          else if (!$scope.config.entityType || !$scope.config.dataSources || !$scope.config.dataSources.x || !$scope.config.dataSources.y) {
            console.info('Required entityType or axes not configured');
            $scope.$emit('worksheetOpenSubheader');
            reset();
          }
          else {
            $scope.selectedEntityType = _.find($scope.entityTypes, {entityTypeCode: $scope.config.entityType.typeCode});
            if (!$scope.selectedEntityType) {
              console.info('No ' + $scope.config.entityType.displayNamePlural + ' associated.');
              $scope.$emit('worksheetOpenSubheader');
              reset();
            }

            // Proceed! Draw us a bubble chart!
            else {
              $scope.entities = $scope.selectedEntityType.entityTypeResults;
              var ds = $scope.config.dataSources;
              var xMin, xMax, yMin, yMax;

              if (ds.xScaleMode === bubbleChartService.SCALE_MODES.USER_CONFIGURED) {
                xMin = ds.xRangeMin;
                xMax = ds.xRangeMax;
              }
              if (ds.yScaleMode === bubbleChartService.SCALE_MODES.USER_CONFIGURED) {
                yMin = ds.yRangeMin;
                yMax = ds.yRangeMax;
              }

              // Get data for x, y and (if exists) z
              var xPromise = bubbleChartService.fetchAxisData(ds.x, $scope.worksheetBn, $scope.entities, ds.xScaleMode, xMin, xMax);
              var yPromise = bubbleChartService.fetchAxisData(ds.y, $scope.worksheetBn, $scope.entities, ds.yScaleMode, yMin, yMax);
              var zPromise = bubbleChartService.fetchAxisData(ds.z, $scope.worksheetBn, $scope.entities, null, null, null);

              // Returns an array of data in the same order as original promises
              $q.all([xPromise, yPromise, zPromise]).then(function (data) {
                buildChartModel(data[0].data, data[1].data, (data[2] ? data[2].data : null));
              });
            }
          }
        });
      }

      commonWorksheetService.getWorksheetEntity($scope.worksheetBn).then(function (entity) {
        $scope.config = entity.configuration;
        if (entity.entityTypes && entity.entityTypes.length) {
          $scope.config.entityType = EntityTypes[entity.entityTypes]; // For this worksheet type, there should be only one typeCode in entity.entityTypes
        }
        load();
      });

      $scope.$on('worksheetConfigUpdated', function ($event, newConfig) {
        $scope.config = newConfig;
        load();
      });

      // Triggered when indexing complete after changes
      $scope.$on('loadSection', function (event, sectionBn) {
        if(sectionBn === 'WORKSHEET'){
          load();
        }
      });

    }])
  .controller('BubbleChartWorksheetSubheaderCtrl', ['$scope', 'urlService', '$rootScope', 'commonWorksheetService', '$timeout', '$modal',
    function ($scope, urlService, $rootScope, commonWorksheetService, $timeout, $modal) {

      $scope.showSave = false;
      $scope.pointSelected = false;
      $scope.config = null;

      $scope.includeEntityTypes = ['ACT', 'CAP', 'COM', 'CON', 'DAT', 'DEM', 'PHY', 'AST', 'MKT', 'ORG', 'PRD', 'SKI', 'STA', 'SYS', 'STR', 'TEC'];

      commonWorksheetService.getWorksheetEntity(urlService.getEntityBn()).then(function (entity) {
        $scope.config = entity.configuration;
      });

      $scope.launchConfigModal = function ($event) {
        $event.preventDefault();

        var modal = $modal.open({
          backdrop: 'static',
          keyboard: true,
          templateUrl: '/b/js/src/bit.ng/bubble-chart/partials/bubble-chart-config-modal.html',
          controller: 'BubbleChartConfigCtrl',
          resolve: {
            config: function () {
              return $scope.config;
            }
          }
        });

        // This is called for both Save and Cancel of the modal, but newConfig will only exist if Save.
        modal.result.then(function (newConfig) {
          if (newConfig) {
            $scope.config = newConfig;
            $rootScope.$broadcast('worksheetConfigUpdated', newConfig);
            commonWorksheetService.updateWorksheetConfig(urlService.getEntityBn(), newConfig, newConfig.entityType.typeCode);
          }
        });
      };

      $scope.clearSelected = function () {
        $scope.pointSelected = false;
      };

      $scope.clearClicked = function () {
        $rootScope.$broadcast('bubbleChartClearSelected');
        $scope.clearSelected();
      };

      // Triggered when indexing complete after changes
      $scope.$on('loadSection', function (event, sectionBn) {
        if(sectionBn === 'WORKSHEET'){
          $scope.clearSelected();
        }
      });

      $scope.$on('bubbleChartPointSelected', function (event, data) {
        $scope.pointSelected = true;
        $scope.selectedPointData = data;
        $scope.selectedPointEntity = data.entity;
        $timeout(function () {
          $rootScope.$broadcast('worksheetOpenSubheader');
        });
      });

      $scope.$on('bubbleChartPointUnselected', function (event, data) {
        // The deSelect event delay needs to be longer than the select event,
        // So when you click from one bubble to the other, the deSelect event is the last one in
        // and can see if the deSelect is still relevant
        $timeout(function () {
          if (data.entity.bn === $scope.selectedPointEntity.bn) {
            $scope.pointSelected = false;
          }
        }, 100);
      });
    }])

  // NOTE: config is "resolved" from BubbleChartCtrl
  .controller('BubbleChartConfigCtrl', ['$scope', 'bitConstants', '$q', '$modalInstance', 'config', 'bubbleChartService',
    function ($scope, bitConstants, $q, $modalInstance, config, bubbleChartService) {

      $scope.newConfig = angular.copy(config);
      if (!$scope.newConfig.hasOwnProperty('dataSources')) {
        $scope.newConfig.dataSources = {x: null, y: null, z: null};
      }
      $scope.loading = true;

      $scope.xDataSourceTypeOptions = bubbleChartService.getDataSourceTypeOptions();
      $scope.yDataSourceTypeOptions = bubbleChartService.getDataSourceTypeOptions();
      $scope.zDataSourceTypeOptions = bubbleChartService.getDataSourceTypeOptions();

      // These options are kept in a map keyed on DATA_SOURCE_TYPE
      $scope.xDataSourceOptions = {};
      $scope.yDataSourceOptions = {};
      $scope.zDataSourceOptions = {};

      $scope.selectedDataSources = {};

      $scope.DATA_SOURCE_TYPES = bubbleChartService.DATA_SOURCE_TYPES;
      $scope.SCALE_MODES = bubbleChartService.SCALE_MODES;

      // Note that the new config is passed back in the close() argument
      $scope.save = function () {
        $modalInstance.close($scope.newConfig);
      };
      $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
      };

      function setXScaleModeOptions () {
        $scope.xScaleModeOptions = bubbleChartService.getXYScaleModeOptions($scope.newConfig.dataSources.xDataSourceType);
      }
      function setYScaleModeOptions () {
        $scope.yScaleModeOptions = bubbleChartService.getXYScaleModeOptions($scope.newConfig.dataSources.yDataSourceType);
      }

      // Use the ADQ entity type list for now
      // TODO: Get the right dataListItems for an Audit/Scorecard somehow
      $scope.entityTypes = bitConstants.getAdvancedQueryEntityTypes();

      function setOptionsForDataSources(entityTypeCode, xDataSourceType, yDataSourceType, zDataSourceType) {

        bubbleChartService.getDataSourceOptions(entityTypeCode).then(function (dataSourceOptions) {
          $scope.xDataSourceOptions = angular.copy(dataSourceOptions);
          $scope.yDataSourceOptions = angular.copy(dataSourceOptions);
          $scope.zDataSourceOptions = angular.copy(dataSourceOptions);

          $scope.newConfig.dataSources.x = getSelectedDataSourceOption($scope.xDataSourceOptions[xDataSourceType], $scope.newConfig.dataSources.x);
          $scope.newConfig.dataSources.y = getSelectedDataSourceOption($scope.yDataSourceOptions[yDataSourceType], $scope.newConfig.dataSources.y);
          $scope.newConfig.dataSources.z = getSelectedDataSourceOption($scope.zDataSourceOptions[zDataSourceType], $scope.newConfig.dataSources.z);

          setXScaleModeOptions();
          setYScaleModeOptions();

          if ($scope.newConfig.dataSources.xScaleMode == null || $scope.newConfig.dataSources.xScaleMode === undefined){
            $scope.newConfig.dataSources.xScaleMode = bubbleChartService.SCALE_MODES.HIGHCHARTS_AUTO_FIT;
          }
          if ($scope.newConfig.dataSources.yScaleMode == null || $scope.newConfig.dataSources.yScaleMode === undefined) {
            $scope.newConfig.dataSources.yScaleMode = bubbleChartService.SCALE_MODES.HIGHCHARTS_AUTO_FIT;
          }

          $scope.noOptions =
            dataSourceOptions[bubbleChartService.DATA_SOURCE_TYPES.SCORECARD].length === 0 &&
            dataSourceOptions[bubbleChartService.DATA_SOURCE_TYPES.CUSTOM_FIELD].length === 0;
          $scope.loading = false;
        });
      }

      function getSelectedDataSourceOption(options, existingDataSource) {
        var selectedOption = null;
        if (existingDataSource && existingDataSource.bn) {
          _.each(options, function (option) {
            if (option.bn === existingDataSource.bn) {
              selectedOption = option;
            }
          });
        }
        return selectedOption;
      }

      // Select the current entityType in the list
      if ( $scope.newConfig.entityType) {
        _.each($scope.entityTypes, function (entityType) {
          if ( $scope.newConfig.entityType &&  $scope.newConfig.entityType.typeCode === entityType.typeCode) {
            $scope.newConfig.entityType = entityType;
          }
        });
        var dataSources =  $scope.newConfig.dataSources;

        if (!dataSources.xDataSourceType) dataSources.xDataSourceType = bubbleChartService.DATA_SOURCE_TYPES.CUSTOM_FIELD;
        if (!dataSources.yDataSourceType) dataSources.yDataSourceType = bubbleChartService.DATA_SOURCE_TYPES.CUSTOM_FIELD;
        if (!dataSources.zDataSourceType) dataSources.zDataSourceType = bubbleChartService.DATA_SOURCE_TYPES.CUSTOM_FIELD;
        setOptionsForDataSources( $scope.newConfig.entityType.typeCode, dataSources.xDataSourceType, dataSources.yDataSourceType, dataSources.zDataSourceType);
      } else {
        $scope.loading = false;
      }

      $scope.entityTypeChanged = function (newEntityType) {
        $scope.loading = true;
        $scope.newConfig.dataSources.x = null;
        $scope.newConfig.dataSources.y = null;
        $scope.newConfig.dataSources.z = null;
        var dataSources = $scope.newConfig.dataSources;
        setOptionsForDataSources(newEntityType.typeCode, dataSources.xDataSourceType, dataSources.yDataSourceType, dataSources.zDataSourceType)
      };

      $scope.dataSourceTypeChanged = function (axis) {
        if (axis === 'x') {
          $scope.newConfig.dataSources.x = null;
          setXScaleModeOptions();
        }
        else if (axis === 'y') {
          $scope.newConfig.dataSources.y = null;
          setYScaleModeOptions();
        }
        else if (axis === 'z') {
          $scope.newConfig.dataSources.z = null;
        }
      };
    }]);
