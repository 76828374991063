angular.module('barometerApp.table')
  .controller('AdhocReportColumnEditorOpenerCtrl', ['$scope', '$modal', '$timeout', 'urlService',
    function ($scope, $modal, $timeout, urlService) {
      $scope.openColumnEditor = function () {
        var dialogOptions = {
          backdrop: 'static',
          keyboard: true,
          templateUrl: '/b/js/src/bit.ng/entity/partials/adhocreport/adhocreport-column-editor.html',
          controller: 'AdhocReportColumnEditorCtrl',
          resolve: {
            adHocReportBn: function () {
              return urlService.getEntityBn();
            },
            columnSpecBn: function () {
              return $('.column-spec-bn-holder').attr("data-columnspecbn");
            },
            entityTypeCode: function () {
              return $('.column-entity-type-holder').attr("data-entitytypecode");
            }
          }
        };
        $modal.open(dialogOptions).opened.then(function () {
          $timeout(function () {
            prototype.setModalMaxHeights();
          }, 200);
        });
      }
    }])
  .controller('AdhocReportColumnEditorCtrl', ['$rootScope', '$scope', '$modalInstance', 'adHocReportBn', 'columnSpecBn', 'entityTypeCode', 'tableService',
    function ($rootScope, $scope, $modalInstance, adHocReportBn, columnSpecBn, entityTypeCode, tableService) {

      // setup the scope params that the CustomColumnController is expecting
      $scope.entityType = entityTypeCode;
      $scope.adHocReportBn = adHocReportBn;
      $scope.saveInProgress = false;

      // this is a little funky because the model is set to match the way summary view sets them, so we can re-use the CustomColumnController
      $scope.workingModel = {};
      $scope.workingModel.configuration = {
        columnSpecBn: columnSpecBn
      };

      $scope.saveAdHocReportColumns = function () {
        $scope.saveInProgress = true;

        // make call to new rest service to persist the column spec. it should return a column spec bn
        tableService.updateColumns(
          $scope.workingModel.configuration.columnSpecBn,
          $scope.workingModel.customColumn.selectedColumns,
          $scope.adHocReportBn).then(function (data) {
          var columnSpecBn = data.data;

          $scope.workingModel.configuration = columnSpecBn;
          $('.column-spec-bn-holder').attr("data-columnspecbn", columnSpecBn);

          // refresh the table...
          $rootScope.$broadcast('entitySaved');

          $scope.saveInProgress = false;
          $modalInstance.close();
        });
      };

      $scope.cancel = function () {
        $modalInstance.close();
      };

    }])

