angular.module('barometerApp.connection')
  .factory('connectionService', ['$http',
    function ($http) {
      return {
        getLinkages: function (connectionBns) {
          return $http({
            method: 'GET',
            url: '/b/api/connection/linkages',
            params: {
              connectionBns: connectionBns
            }
          })
            .error(function (data, status) {
              console.error('Error in connection-services.getLinkages: ' + status);
            });
        }
      }
    }]);