angular.module('barometerApp.field')

  // Loads the correct editable field UI for the field in question
  // Also checks visibility and editability rules and hides / makes read-only when appropriate
  .directive('editableCustomField', ['utilService', function (utilService) {
    return {
      restrict: 'E',
      scope: {
        field: '=',
        editing: '='
      },
      templateUrl: '/b/js/src/bit.ng/field/partials/editable-custom-field.html',
      link: function (scope, elem, attrs) {

        scope.isVisible = function (field) {
          return utilService.isControlLevelVisible(field);
        };

        scope.isEditable = function (field) {
          return utilService.isControlLevelEditable(field);
        };
      }
    }
  }])
  .directive('fieldChoiceInput', [function () {
    return {
      scope: {
        fieldDataType: '=',
        data: '=',
        fieldLength: '=',
        decimalPlaces: '='
      },
      templateUrl: '/b/js/src/bit.ng/field/partials/field-choices-inputs.html'
    }
  }])
  /**
   * Wraps the angular-bootstrap timepicker.  Expects the timeData to be the time string.
   * Will update the timeModel with a time String as user manipulates the pickers.
   */
  .directive('bitTimePicker', [function () {
    return {
      scope: {
        timeData: '=',
        helpText: '@'
      },
      restrict: 'A',
      templateUrl: '/b/js/src/bit.ng/field/partials/time-field.html',
      link: function (scope, element, attrs) {
        if (scope.timeData) {
          scope.timeModel = scope.timeData;
        }

        scope.clearValues = function () {
          scope.timeModel = null;
        };

        scope.$watch('timeModel', function (newVal, oldVal) {
          if (newVal) {
            scope.timeModel = newVal;
            scope.timeData = newVal;
          }
        });
      }
    }
  }])
  /**
   * Wraps the angular-bootstrap datepicker and timepicker.  Expects the combinedModel to be the date and time.
   * Will update the combinedModel with a js Date object as user manipulates the pickers.
   */
  .directive('bitDateTimePicker', ['utilService', function (utilService) {
    return {
      scope: {
        combinedModel: '=',
        helpText: '@'
      },
      restrict: 'A',
      templateUrl: '/b/js/src/bit.ng/field/partials/date-time-field.html',
      link: function (scope, element, attrs) {
        if (scope.combinedModel) {
          scope.dateFormat = utilService.getDateFormatForBootstrapDatepicker();
          var dateTimeStr = scope.combinedModel;
          var firstSpace = dateTimeStr.indexOf(' ');

          scope.dateModel = dateTimeStr.substring(0, firstSpace);
          scope.timeModel = dateTimeStr.substring(firstSpace + 1, dateTimeStr.length);
        }

        scope.combineValues = function (dateVal, timeVal) {
          if (dateVal && timeVal) {
            scope.combinedModel = dateVal + ' ' + timeVal;
          }
        };

        scope.clearValues = function () {
          scope.dateModel = null;
          scope.timeModel = null;
          scope.combinedModel = null;
        };

        scope.$watch('dateModel', function (newVal, oldVal) {
          if (newVal) {
            scope.combineValues(newVal, scope.timeModel);
          }
        });

        scope.$watch('timeModel', function (newVal, oldVal) {
          if (newVal) {
            scope.combineValues(scope.dateModel, newVal);
          }
        });
      }
    }
  }])
  .directive('fieldPermissions', ['adminService', 'alertService', 'fieldService', 'entityService', 'tableService', 'urlService', 'utilService', 'redux',
    function (adminService, alertService, fieldService, entityService, tableService, urlService, utilService, redux) {
      return {
        scope: {},
        templateUrl: '/b/js/src/bit.ng/field/partials/field-permissions.html',
        link: function (scope, element, attrs) {
          scope.fieldBn = urlService.getEntityBn();
          scope.isCreatePage = false;
          scope.permissions = [];

          // sync any changes to permissions with Redux so that React can make use of them
          scope.$watch('permissions', newPermissions => {
            redux.store.dispatch(redux.actions.fieldEditUpdatePermissions(newPermissions));
          });

          scope.addPermission = function () {
            addPermission();
          };

          scope.hasPermissions = function () {
            return scope.permissions && scope.permissions.length > 0;
          };

          function addPermission(principalBn) {
            var permission = {};
            permission.fieldBn = scope.fieldBn;
            permission.id = setId();
            permission.controlLevel = "EDITABLE";
            if (typeof principalBn != "undefined") {
              permission.principalBn = principalBn;
            }
            scope.permissions.push(permission);
          }

          function compareType(a, b) {
            var a_type = utilService.getEntityTypeCode(a.principalBn);
            var b_type = utilService.getEntityTypeCode(b.principalBn);
            if (a_type > b_type) {
              return -1;
            } else if (a_type < b_type) {
              return 1;
            } else if (a_type == b_type) {
              return compareControlLevel(a, b);
            }
            return 0;
          }


          function compareControlLevel(a, b) {
            if (a.controlLevel < b.controlLevel)
              return -1;
            else if (a.controlLevel > b.controlLevel)
              return 1;

            return 0;
          }

          function load() {
            scope.isCreatePage = (scope.fieldBn == null);

            if (!scope.isCreatePage) {
              var promise = fieldService.getFieldPermission(scope.fieldBn);
              promise.then(function (results) {
                if (results.data) {
                  scope.permissions = [];
                  for (var i = 0; i < results.data.permissions.length; i++) {
                    results.data.permissions[i].id = setId();
                    scope.permissions.push(results.data.permissions[i]);
                  }
                  scope.permissions.sort(compareType);
                } else {
                  alertService.addErrorAlert("Unable to edit entity");
                }
              });
            }

            // Load all of the User roles once, then pass the roles
            // to each field permission option.
            var adminPromise = adminService.getUserRoles();
            adminPromise.then(function (results) {
              if (results.data) {
                // Set the roles.
                scope.roles = results.data;

                // If this is the create page, add a registered user permission.
                if (scope.isCreatePage) {
                  setInitialPermission();
                }
              } else {
                alertService.addErrorAlert("Unable to load detail for entity");
              }
            });
            // read our catalogs
            entityService.getEntityCatalogs().then((response) => {
              scope.entityCatalogs = response.data;
              _.forEach(scope.entityCatalogs, function(catalog) {
                //source target relationshipType entityBn
                // for each catalog read the contact types
                tableService.getQualifiers({
                  source: catalog.entityType.code,
                  target: 'PER',
                  relationshipType: 'HAS_CONTACT'
                }).error(function(err) {
                  console.error("err: ", err);
                  // this can happen if there isn't a contact relationship for the entity type
                  catalog.contactTypes = [];
                }).then(function(result) {
                  catalog.contactTypes = [];
                  // if there's a valid qualifier add it to our list otherwise skip it
                  // person person relationships are unqualified? Weird right?
                  _.forEach(result.data, function(relationshipPattern) {
                    if(!relationshipPattern.qualifier) {
                      return;
                    }
                    catalog.contactTypes.push(relationshipPattern);
                  })
                })
              })
            })
          }

          function modelExists(mod, models) {
            for (var i = 0; i < models.length; i++) {
              if (!fieldService.validateFieldPermission(models[i], mod)) {
                return true;
              }
            }
            return false;
          }

          function remove(model, reload, alert) {
            var deleteFieldPermission = fieldService.removeFieldPermission(model.bn);
            deleteFieldPermission.then(function (results) {
              if (results.data) {
                if (reload) {
                  load();
                }
                if (alert) {
                  alertService.addSuccessAlert("Field Permission Saved");
                }
              } else {
                alertService.addErrorAlert("Unable to edit entity");
              }
            });
          }

          function removePermission(oldModel) {
            scope.permissions = scope.permissions.filter(p => p.id !== oldModel.id);
          }

          function setInitialPermission() {
            var registeredUserBn = "";
            for (var i = 0; i < scope.roles.length; i++) {
              if (scope.roles[i].role == "ROLE_REGISTERED_USER") {
                registeredUserBn = scope.roles[i].bn;
              }
            }
            addPermission(registeredUserBn);
          }

          function setId() {
            function s4() {
              return Math.floor((Math.random() + 1) * 0x10000).toString(16).substring(1);
            }

            return (s4() + s4()) + '-' + s4() + '-' + s4() + '-' + s4() + '-' + (s4() + s4() + s4());
          }

          function upsert(model, reload, alert) {
            model.fieldBn = (!model.fieldBn) ? scope.fieldBn : model.fieldBn;
            delete model.id;
            var fieldFieldPermission = fieldService.upsertFieldPermission(model);
            fieldFieldPermission.then(function (results) {
              if (results.data) {
                if (reload) {
                  load();
                }
                if (alert) {
                  alertService.addSuccessAlert("Field Permission Saved");
                }
              } else {
                alertService.addErrorAlert("Unable to edit entity");
              }
            });
          }

          function updatePermission(oldModel, newModel) {
            for (var i = 0; i < scope.permissions.length; i++) {
              if (!fieldService.validateFieldPermission(scope.permissions[i], oldModel)) {
                scope.permissions[i] = newModel;
                scope.permissions = [...scope.permissions];
              }
            }
          }

          scope.$on('addInitialFieldPermissions', function (event, data) {
            // Update all the permissions after a field is created.
            for (var i = 0; i < scope.permissions.length; i++) {
              scope.permissions[i].fieldBn = data.bn;
              upsert(scope.permissions[i], false, false);
            }
          });

          scope.$on('fieldPermissionsOptionUpdated', function (event, data) {
            switch (data.state) {
              case "NEW":
                if (!modelExists(data.newModel, scope.permissions)) {
                  if (!scope.isCreatePage) {
                    upsert(data.newModel, true, true);
                  } else {
                    updatePermission(data.oldModel, data.newModel);
                  }
                } else {
                  removePermission(data.newModel);
                  alertService.addErrorAlert("Field permission already exists.");
                }
                break;
              case "REMOVE":
                if (!scope.isCreatePage) {
                  remove(data.oldModel, true, true);
                } else {
                  removePermission(data.oldModel)
                }
                break;
              case "UPDATE":
                if (!modelExists(data.newModel, scope.permissions)) {
                  if (!scope.isCreatePage) {
                    remove(data.oldModel, true, false);
                    upsert(data.newModel, true, true);
                  } else {
                    updatePermission(data.oldModel, data.newModel);
                  }
                } else {
                  removePermission(data.newModel);
                  alertService.addErrorAlert("Field permission already exists.");
                }
                break;
              default:
                break;
            }
          });

          scope.$on('removeFieldPermissionsOption', function (event, data) {
            removePermission(data);
          });

          load();
        }
      }
    }])
  .directive('fieldPermissionOption', ['alertService', 'entityService', 'fieldService', 'utilService', '$rootScope',
    function (alertService, entityService, fieldService, utilService, $rootScope) {
      return {
        scope: {
          model: '=',
          roles: '=',
          isCreatePage: '=',
          entityCatalogs: '='
        },
        templateUrl: '/b/js/src/bit.ng/field/partials/field-permission-option.html',
        link: function (scope, element, attrs) {
          scope.personEntityTypes = ['PER'];
          scope.organizationEntityTypes = ['ORG'];
          scope.person = null;
          scope.principalType = {id: 'ROLE', name: 'Role'};
          scope.init = {};
          scope.template = {
            principalTypes: [
              {id: 'PERSON', name: 'Person'},
              {id: 'ROLE', name: 'Role'},
              {id: 'ORG', name: 'Organization'},
              {id: 'CONTACT_TYPE', name: 'Contact Type'}
            ],
            privilegeTypes: [
              {id: 'EDITABLE', name: 'Edit'},
              {id: 'VISIBLE', name: 'View'},
              {id: 'APPROVE', name: 'Approve'}
            ]
          };
          scope.mode = "SAVED";
          scope.savedStyle = {};

          scope.addPerson = function ($event, entity) {
            $event.preventDefault();
            scope.setPerson(entity.bn, entity.name);
          };

          scope.addOrganization = function ($event, entity) {
            $event.preventDefault();
            scope.setOrganization(entity.bn, entity.name);
          };

          scope.cancel = function () {
            scope.newModel = angular.copy(scope.model);
            if (typeof scope.newModel.principalBn == "undefined") {
              $rootScope.$broadcast('removeFieldPermissionsOption', scope.newModel)
            }
            scope.principalType = angular.copy(scope.init.principalType);
            if (scope.init.person) {
              scope.setPerson(scope.init.person.bn, scope.init.person.name);
            }
            if (scope.init.organization) {
              scope.setOrganization(scope.init.organization.bn, scope.init.organization.name);
            }
            setMode('SAVED');
          };

          scope.remove = function () {
            scope.newModel.controlLevel = "NONE";
            updateFieldOption(scope.model, scope.newModel);
          };


          scope.edit = function () {
            // scope.setPerson();
            setMode('UNSAVED');
          };

          scope.save = function () {
            updateFieldOption(scope.model, scope.newModel);
            setMode('SAVED');
          };

          scope.setPerson = function (bn, name) {
            if (typeof bn == "undefined") {
              scope.person = null;
            } else {
              scope.person = {
                bn: bn,
                name: name
              };
              scope.newModel.principalBn = scope.person.bn;
            }
          };

          scope.setOrganization = function (bn, name) {
            if (typeof bn == "undefined") {
              scope.organization = null;
            } else {
              scope.organization = {
                bn: bn,
                name: name
              };
              scope.newModel.principalBn = scope.organization.bn;
            }
          };

          function getState(oldModel, newModel) {
            if (typeof oldModel.principalBn == "undefined") {
              return "NEW";
            } else if (newModel.controlLevel == "NONE") {
              return "REMOVE";
            } else if (fieldService.validateFieldPermission(oldModel, newModel)) {
              return "UPDATE";
            } else {
              return "INVALID";
            }
          }

          scope.isPrincipalVisible = function (id) {
            let isVisible = true;
            if (scope.newModel.controlLevel !== 'APPROVE' && (id === 'ORG' || id === 'CONTACT_TYPE')) {
              isVisible = false;
            }
            return isVisible;
          }

          function load() {
            // Clone a newModel object from the initial model.
            scope.newModel = angular.copy(scope.model);

            if (typeof scope.model.principalBn == "undefined") {
              // If this is true the "Remove" button will be disabled and the
              // save logic won't attempt to remove something that doesn't exist.
              setMode('UNSAVED');
              scope.addOnly = true;
            } else {
              scope.principalType = getPrincipalType(scope.newModel.principalBn);
              setMode('SAVED');
            }

            scope.init.principalType = angular.copy(scope.principalType);

            // If the principalType is PERSON then we and the principalBn exists
            // then we need to get That person's displayable name.
            if (!scope.addOnly && (scope.principalType.id === 'PERSON' || scope.principalType.id === 'ORG')) {
              var entityPromise = entityService.getBasicInfo(scope.model.principalBn);
              entityPromise.then(function (results) {
                if (results.data) {
                  if (scope.principalType.id === 'PERSON') {
                    scope.init.person = {};
                    scope.init.person.bn = angular.copy(results.data.bn);
                    scope.init.person.name = angular.copy(results.data.displayableName);
                    scope.setPerson(results.data.bn, results.data.displayableName);
                  } else {
                    scope.init.organization = {};
                    scope.init.organization.bn = angular.copy(results.data.bn);
                    scope.init.organization.name = angular.copy(results.data.displayableName);
                    scope.setOrganization(results.data.bn, results.data.displayableName);
                  }

                } else {
                  alertService.addErrorAlert("Unable to retrieve data: ");
                }
              });
            }
          }

          function setMode(mode) {
            if (mode === 'UNSAVED') {
              scope.mode = 'UNSAVED';
              isEditable(true);
            } else {
              scope.mode = 'SAVED';
              isEditable(false);
            }
          }

          function getPrincipalType(principalBn) {
            var entityType = utilService.getEntityTypeCode(principalBn),
              principalType;
            switch (entityType) {
              case 'ARL':
                principalType = {id: 'ROLE', name: 'Role'};
                break;
              case 'PER':
                principalType = {id: 'PERSON', name: 'Person'};
                break;
              case 'ORG':
                principalType = {id: 'ORG', name: 'Organization'};
                break;
              case 'DLI':
                principalType = {id: 'CONTACT_TYPE', name: 'Contact Type'};
                break;
              default:
                console.error("Found an unhandled Principal Type: ", principalBn, entityType);
                principalType = null;
                break;
            }
            return principalType;
          }

          function isEditable(val) {
            if (val) {
              scope.savedStyle = {
                "box-shadow": "2px 2px 4px #888888"
              }
            } else {
              scope.savedStyle = {}
            }
          }

          function updateFieldOption(oldModel, newModel) {
            var updateObject = {
              oldModel: oldModel,
              newModel: newModel,
              state: getState(oldModel, newModel)
            };
            $rootScope.$broadcast('fieldPermissionsOptionUpdated', updateObject);
          }

          scope.updatePrincipalType = function () {
            for (let i = 0; i < scope.privilegeType.principalTypes.length; i++) {
              let principal = scope.privilegeType.principalTypes[i];
              if (scope.principalType === principal.id) {
                scope.principalType = principal;
              }
            }
          }

          load();
        }
      }
    }]);
