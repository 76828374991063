angular
  .module('barometerApp.tasks')
  .directive('tasks', tasks);

tasks.$inject = [];

/**
 * Displays list of Task cards.
 *
 * @author Tim Cramer
 */
function tasks() {

  var directive = {
    //
    templateUrl: '/b/js/src/bit.ng/tasks/partials/tasks.html',
    restrict: 'E',
    scope: {
      userBn: '@',
      tasks: "="
    }
  }
  //
  return directive;
}