angular.module('barometerApp.common')
  .factory('errorHandlingService', ['$window', 'alertService', function ($window, alertService) {
    return {
      handleGenericError: function (status, message) {
        console.error('ERROR OCCURRED - CODE: ' + status);
        if (status > 400 && status < 500) {
          // ERROR CODE 401 is being thrown when session expires
          // this is an authentication/authorization type exception
          // redirect to the login page
          console.log(">>> Generic Error Handler: Error Code = " + status);
          $window.location = '/b';
        } else {
          if (message) {
            alertService.addErrorAlert(message);
          }
        }
      }
    }
  }])
  .factory('securityService', [function () {
    const entityTypeSecurityMap = {
      'ACT': 'ROLE_ACTOR_EDITOR',
      'CAP': 'ROLE_CAPABILITY_EDITOR',
      'COM': 'ROLE_COMPANY_EDITOR',
      'CON': 'ROLE_CONNECTION_EDITOR',
      'DAT': 'ROLE_DATA_EDITOR',
      'DEM': 'ROLE_DEMAND_EDITOR',
      'PHY': 'ROLE_PHYSICAL_EDITOR',
      'MKT': 'ROLE_MARKET_EDITOR',
      'ORG': 'ROLE_ORGANIZATION_EDITOR',
      'PER': 'ROLE_PERSON_EDITOR',
      'PRD': 'ROLE_PRODUCT_EDITOR',
      'SKI': 'ROLE_SKILL_EDITOR',
      'STA': 'ROLE_STANDARD_EDITOR',
      'STR': 'ROLE_STRATEGY_EDITOR',
      'SYS': 'ROLE_SYSTEM_EDITOR',
      'TEC': 'ROLE_TECHNOLOGY_EDITOR'
    };
    return {
      // Note: The 'window.roles' global var is set on initial page load by wicket in BasePage2.
      getRoles: function () {
        return window.roles;
      },
      hasEntityPermission(entityTypeCode) {
        // check to see if the list of roles for the user contains the required role based on entity type or super editor
        return _.intersection(this.getRoles(), [entityTypeSecurityMap[entityTypeCode], 'ROLE_SUPER_EDITOR']).length > 0;
      },
      hasRole: function (roles) {
        var hasRole = false;
        if (roles instanceof Array) {
          angular.forEach(roles, function (role) {
            angular.forEach(window.roles, function (val) {
              if ($.trim(val) === $.trim(role)) {
                hasRole = true;
              }
            });
          });
        } else {
          angular.forEach(this.getRoles(), function (val) {
            if ($.trim(val) === $.trim(roles)) {
              hasRole = true;
            }
          });
        }
        return hasRole;
      }
    };
  }])
  .factory('urlService', ['$location', 'pageService', '$rootScope', 'bitConstants', function ($location, pageService, $rootScope, bitConstants) {

    function getBaseUrl() {
      return $location.protocol() + '://' + $location.host() + '/b/';
    }

    // Can't currently use utilService.assertIsBn due to circular DI references
    // TODO: break utils up!
    function isBn(candidateBn) {
      if (typeof candidateBn !== 'string') {
        return false;
      }
      var bnPattern = /[A-Z0-9]{12}/;
      return candidateBn.match(bnPattern) !== null;
    }

    // Include section id and "view" (table vs summary, etc) as search parameter:
    // ?view={{sectionBn}}:{{viewId}}:{{summaryId}}
    // eg: view=TEC:tec_summary:ZZ1L0000000D
    function updateSectionParams(event, data) {

      // This depends on template naming conventions - treat defensively!
      var sectionBnMissing = !data.sectionBn || !data.sectionBn.length || data.sectionBn === 'undefined';
      // Don't record edits
      var isEditing = data.isEditing;
      if (sectionBnMissing || isEditing) {
        return;
      }

      var sectionAndView = data.sectionBn;
      if (data.viewId) {
        sectionAndView += ":" + data.viewId;
      }
      if (data.summaryId) {
        sectionAndView += ":" + data.summaryId;
      }

      $location.search('view', sectionAndView).replace();
    }

    // Triggered when a section in the left nav is selected
    // (at this point we should know sectionBn)
    $rootScope.$on('leftTabSelected', updateSectionParams);

    // Triggered when a content block within a section is loaded
    // (at this point we should know sectionBn:viewId)
    $rootScope.$on('sectionContentLoaded', updateSectionParams);

    // Triggered when a summary view is loaded
    // (at this point we should know sectionBn:viewId:summaryId)
    $rootScope.$on('summaryViewLoaded', updateSectionParams);

    return {
      /**
       * BARO-18191
       */
      getGraphApiUrl: function () {
        return $location.protocol() + '://' + $location.host() + '/graph/api/';
      },
      // Last-viewed section and view are now stored in url as a search parameter:
      // ?view={{sectionBn}}:{{viewId}}
      // eg: section=TEC:technology_summary
      getSectionBnFromUrl: function () {
        var sectionBn = null;
        var searchVal = $location.search()['view'];
        if (searchVal && searchVal.length) {
          searchVal = searchVal.split(':');
          sectionBn = searchVal[0] || null;
        }
        return sectionBn;
      },
      getViewIdFromUrl: function () {
        var viewId = null;
        var searchVal = $location.search()['view'];
        if (searchVal && searchVal.length) {
          searchVal = searchVal.split(':');
          viewId = searchVal[1] || null;
        }
        return viewId;
      },
      getSummaryIdFromUrl: function () {
        var summaryId = null;
        var searchVal = $location.search()['view'];
        if (searchVal && searchVal.length) {
          searchVal = searchVal.split(':');
          summaryId = searchVal[2] || null;
        }
        return summaryId;
      },

      getQueryParamValue: function(key) {
        var abUrl = $location.absUrl();
        var rgx = new RegExp(key + '=\\w+');

        if (abUrl.match(rgx)) {
          // Make a key/value array for the query param.
          var kvArray = abUrl.match(rgx)[0].split('=');
          // Return the value from that array.
          return kvArray[1];
        }
        // Couldn't find a value for the provided key.
        return null;

      },

      getUrlForBnCode: function (bn, code) {
        var codeToUrl = BnCodeToUrl[code];
        return codeToUrl ? getBaseUrl() + codeToUrl + '/' + bn : null;
      },

      getQueryKey: function () {
        var path = location.pathname;
        var queryKey = path.substring(path.length - 40, path.length);
        if (queryKey.substring(0, 8) === "querykey") { //make sure this is a querykey, it will start with "querykey"
          return queryKey.substring(8, queryKey.length);
        }
        return null;
      },

      getEntityBn: function () {
        var entityBn = null;
        if (pageService.isEntityPage()) {

          // Start with last pathParam return first valid bn
          var pathParam = location.pathname.split("/");
          for (var i = pathParam.length; i >= 0; i--) {
            if (isBn(pathParam[i])) {
              entityBn = pathParam[i];
              break;
            }
          }
        }
        return entityBn;
      },

      getPageName: function () {
        // this assumes the 2nd path is the collection (ie systems, actors)
        return location.pathname.split('/')[2]
      },

      getIsPdf: function () {
        return $location.search().pdf === "true";
      },

      getCurrentUrl: function () {
        return $location.absUrl();
      },

      getBaseUrl: getBaseUrl,

      getEntityTypeCodeOfCurrentPage: function (ignoreRestriction) {
        var obj =  PageIdentifierToEntityTypeCode[this.getPageName()];
        if(!ignoreRestriction && obj && obj.indexOf("WK2", 0) > -1) {
          return "WK2"
        }
        var insightType = InsightTypes[obj];
        var entityTypeCode = insightType?.typeCode || obj;
        return entityTypeCode;
      },

      getPageIdentifierOfCurrentPage: function(){
        return this.getPageName();
      },

      getSingleTypedCollectionPageForEntityType: function (entityTypeCode) {
        return singleTypedCollectionPages[entityTypeCode];
      },
      // calls to wicket pages need to match the number of dir levels as the source page because
      // wicket will resolve urls on the wicket page to match the incoming request
      // ie: a call to load a wicket page from /b/system/xyz should look like /b/pageurl/
      //     a call to load a wicket page from /b/systems should look like /b/pageurl  (no trailing slash)
      prepareWicketPageUrl: function (baseUrl) {
        var urlString = '/b/' + baseUrl;
        if (pageService.isEntityPage() || this.isPivotPage()) {
          urlString = urlString + "/";
        }
        return urlString;
      },
      // Re-adding wicket page url prep
      // This fixes any wicket links on pages that cause the wicket resources links to break
      // Currently only breaking on OptionListEdit pages
      prepareWicketPageUrl2: function(baseUrl) {
          var urlString = baseUrl;
          var pageName = this.getPageName();
          if(pageName != 'optionlistedit') {
            return urlString;
          }
          urlString = urlString + "/";
          return urlString;
      },
      isPivotPage: function () {
        return (this.getQueryKey() !== null);
      },
      isInsightPage: function (entityTypeName) {
        var insightEntities = bitConstants.getInsightEntityTypes();
        for (var i = 0; i < insightEntities.length; i++) {
          if (insightEntities[i].typeCode === entityTypeName) {
            return true;
          }
        }
        return false;
      },
      isEnterprisePage: function (entityTypeName) {
        var enterpriseEntities = bitConstants.getEnterpriseEntityTypes();
        for (var i = 0; i < enterpriseEntities.length; i++) {
          if (enterpriseEntities[i].typeCode === entityTypeName) {
            return true;
          }
        }
        return false;
      }
    };
  }])
  .factory('alertService', ['$rootScope', '$timeout', 'localStorageService', 'notifier', function ($rootScope, $timeout, localStorageService, notifier) {

    // upon init, check for deferred alerts saved in localStorage
    var alerts = localStorageService.get('alerts') ? [localStorageService.get('alerts')] : [];
    if (alerts.length) {
      localStorageService.remove("alerts");
    }

    alerts.forEach(deferredAlert => {
      const { msg: message, type: variant } = deferredAlert;
      const autoHideDuration = variant === 'success' ? 5000 : null;
      notifier.enqueue(message, { variant, autoHideDuration });
    });

    return {
      addAlert: function (params) {
        const autoHideDuration = params.isSticky ? null : 5000;
        const { message, type: variant } = params;
        notifier.enqueue(message, { variant, autoHideDuration });
      },
      addErrorAlert: function (message) {
        notifier.error(message);
      },
      addWarningAlert: function (message) {
        notifier.warning(message);
      },
      addSuccessAlert: function (message) {
        const autoHideDuration = 5000;
        notifier.success(message, { autoHideDuration });
      },
      // Add an alert to be shown on the next page view
      // alertObj: { type: "type", msg: "msg" }
      addDeferredAlert: function (alertObj) {
        localStorageService.set("alerts", alertObj);
      },
      clearAllAlerts: function () {
        notifier.closeAll();
      },
      closeAlert: function (index) {
        // handle by notifier module
      },
      /**
       * These are the standard fields returned by an AngularJS HTTP Response:
       *
       * @param data
       * @param status
       * @param headers
       * @param config
       * @param statusText
       *
       * We'll pick them apart here and try to post revealing error explanations.
       */
      addHttpErrorAlert: function (data, status, headers, config) {
        //
        // Not localized cuz this english is probably pretty universal. ;)
        var message = 'HTTP Error';
        //
        console.debug('HTTP Error:');
        console.debug('data = ' + data);
        console.debug('data.errorMessages = ' + data.errorMessages);
        console.debug('status = ' + status);
        console.debug('headers = ' + headers);
        console.debug('config = ' + config);
        //
        message += ': ';
        message += status;
        //
        this.addErrorAlert(message);
      },
      addHttpSuccessAlert: function (data, status, headers, config) {
        //
        // Not localized cuz this english is probably pretty universal. ;)
        var message = 'HTTP Success';
        //
        console.debug('HTTP Success:');
        console.debug('data = ' + data);
        console.debug('status = ' + status);
        console.debug('headers = ' + headers);
        console.debug('config = ' + config);
        //
        message += ': ';
        message += status;
        //
        this.addSuccessAlert(message);
      },
    }
  }])
  .factory('utilService', ['urlService', 'bitConstants', 'reactUtil', function (urlService, bitConstants, reactUtil) {
    var difference = function (template, override) {
      var ret = {};
      if (!override) return template;
      for (var name in template) {
        if (name in override) {
          if (_.isObject(override[name]) && !_.isArray(override[name])) {
            var diff = difference(template[name], override[name]);
            if (!_.isEmpty(diff)) {
              ret[name] = diff;
            }
          } else if (!_.isEqual(template[name], override[name])) {
            ret[name] = override[name];
          }
        } else {
          if (_.isArray(template[name]) && template[name].length > 0) {
            ret[name] = template[name];
          }
        }
      }
      return ret;
    };
    return {
      assertIsBn: function (candidateBn) {
        if (!this.isBn(candidateBn)) {
          throw new Error(candidateBn + ' is not a BN.');
        }
      },
      isBn: (candidateBn) => bitConstants.isBn(candidateBn),
      isBnCode: (candidateBnCode) => bitConstants.isBnCode(candidateBnCode),
      isTypeCode: (candidateTypeCode) => bitConstants.isTypeCode(candidateTypeCode),
      showLoading: function () {
        $('#loading').show();
      },
      hideLoading: function () {
        $('#loading').hide();
      },
      isControlLevelVisible: function (entity) {
        return (entity.hasOwnProperty('controlLevel')) ? entity.controlLevel && (entity.controlLevel.toLowerCase() === 'editable' || entity.controlLevel.toLowerCase() === 'visible') : true;
      },
      isControlLevelEditable: function (entity) {
        return (entity.hasOwnProperty('controlLevel')) ? entity.controlLevel && (entity.controlLevel.toLowerCase() === 'editable') : true;
      },
      getDifference: function (template, override) {
        return difference(template, override);
      },
      getBnCode: function (bn) {
        if (typeof bn === 'string') {
          return bn.substring(2, 4);
        }
        return "";
      },
      getBnCodeFromUrl: function () {
        var bnFromUrl = urlService.getEntityBn();
        return this.getBnCode(bnFromUrl);
      },

      getBaseEntityType: function (entityType) {
        return entityType;
      },
      getMetricNumberFormat: function(value, dataType, displayType, currencyUom = '$'){
        var countDisplay = value;
        if (dataType === "DECIMAL" || dataType == "INTEGER"){
          countDisplay = reactUtil.displayDecimalFormat(value, 2);
        }
        if (displayType === "CURRENCY"){
          countDisplay = reactUtil.displayCurrencyFormat(value, currencyUom);
        }
        return countDisplay;
      },
      // these two functions will get the desired date format based on the user's locale
      getDateFormatForAnglularDateFilter: function() {
        // The AngularJS date filter expects months to be uppercase 'M's, ie: MM/dd/yyyy
        // AngularJS considers lowercase "m" to mean minute!
        return moment.localeData().longDateFormat("L").toLowerCase().replace(new RegExp("m", 'g'), "M");
      },
      getDateFormatForBootstrapDatepicker: function() {
        // the Bootstrap Datepicker uses an all lowercase date format, ie: mm/dd/yyyy
        return moment.localeData().longDateFormat("L").toLowerCase();
      },
      getLocaleLongDateFormat: function() {
        return moment.localeData().longDateFormat('L');
      },
      // returns eg: 'COM'
      getEntityTypeCode: function (bn) {
        const bnCode = this.getBnCode(bn);
        return this.getTypeCodeFromBnCode(bnCode);
      },
      //From SYS->18
      getBnCodeFromTypeCode: function (typeCode) {
        return EntityTypes[typeCode].bnCode;
      },
      //From SYS->18
      getTypeCodeFromBnCode: function (bnCode) {
        let result = '';
        if (bnCode) {
          angular.forEach(EntityTypes, function (val, key) {
            if (bnCode === val.bnCode) {
              result = val.typeCode;
            }
          });
        }
        return result;
      },
      getCurrentUserBn: function () {
        return $('#userBnHolder').html();
      },
      getCurrentUserName: function () {
        return this.getCurrentUserFirstName() + ' ' + this.getCurrentUserLastName();
      },
      getCurrentUserFirstName: function () {
        return $('#userFirstNameHolder').html();
      },
      getCurrentUserLastName: function () {
        return $('#userLastNameHolder').html();
      },
      getTenantBn: function () {
        return $('#tenantBnHolder').text()
      },
      convertToISOUTCDateTime: function (dtToConvert) {
        //
        // The only way to guarantee that a date submitted from a user's timezone is not shifted at the server,
        // is to force a date (or dateTime) to ISO8601. Converting to UTC simplifies non-linear, non-subjective
        // wibbly wobbly timey wimey stuff.
        //
        // 1) convert incoming dtToConvert value to a Moment Date.
        // 2) convert Moment Date to UTC and format to ISO_DATETIME_TIME_ZONE_FORMAT
        return moment(dtToConvert, 'L').format("YYYY-MM-DDTHH:mm:ssZ");
      },
      convertToISOUTCLocalDate: function (dtToConvert) {
        var dateFormat = moment.localeData().longDateFormat("L");
        //
        // Convert to a LocalDate (Date without timezone).
        //
        // 1) convert incoming dtToConvert value to a Moment Date, using the provided pattern
        // 2) convert to UTC and format to ISO_DATE_FORMAT
        return moment.utc(dtToConvert, dateFormat).format('YYYY-MM-DD');
      },
      convertToLocalDate: function (dtToConvert) {
        // take an existing date and convert to MM/DD/YYYY format for the datepicker
        return moment(dtToConvert).format('L');
      },
      convertToLocalDateTime: function(dtToConvert) {
        return moment.utc(dtToConvert).local().format('LLL');
      },
      convertToUtcDate: function (dtToConvert) {
        //
        // This code uses (or used) ...
        // 1. ECMAScript.js Date. See http://www.ecma-international.org/ecma-262/5.1
        // 2. moment.js. See http://momentjs.com
        // 3. AngularJS JSON serialization.
        //
        // The point of converting to UTC date is this: on the server-side, Jackson
        // expects all dates to be UTC (default behavior). As a matter of philosophy
        // Jackson revolves around epoch time expressed as an integer -- even when it
        // passes formatted strings, instead of actual long integers.
        //
        // But, the problem in our case here is that, with date-only Dates, we
        // never really intend time or timezone to be a factor. And yet so long
        // as we are using real Date objects, time and timezone are in play.
        //
        // ESPECIALLY IF THE TIME IS MIDNIGHT, 00:00:00!
        //
        // BARO-13625: For the future reference, there is a problem with this code ...
        // That is, it converts an input Date to UTC, then creates a local-time Date
        // from it. For Dates that are not intended to express time, that causes a
        // problem because the local offset introduces time where there was none.
        // For example, a submission from ET offsets 00:00:00 to 04:00:00. Offsets
        // from the other side of the dateline can actually slip into the previous day.
        // The original code here was written and tested only in CT.
        //
        // Maybe the thing to do here is force both the client and server to keep
        // date-only dates in UTC time, so that 00:00:00 stays 00:00:00 no matter
        // what. So long as we are using Date objects on both ends, that may be
        // the only way to fix time at midnight.
        //
        // WAS ...
        // Reverting to previous DATE formatting
        // The MM/DD/YYYY format is required due to Safari and IE handing of dates
        // Slashes (/) are required instead of dashes (-) due to the ISO spec (8601) That both IE and Safari Rely upon
        // Solution explained (somewhat) here: http://biostall.com/javascript-new-date-returning-nan-in-ie-or-invalid-date-in-safari
        var newDate = new Date(moment(dtToConvert).utc());

        // The inbound date can look very confusing during edits. It can look like
        // yesterday (i.e. the day before). This is because the Bootstrap Datepicker
        // we use produces UTC 00:00:00 times. So, if your browser is in CT, you'll
        // see a five hour offset into yesterday, even though the "real" time is
        // midnight on the chosen date.

        // var newDate = new Date(dtToConvert);

        // FIX: Force UTC hours to NOON, 12:00:00, instead of MIDNIGHT, 00:00:00,
        // because then all offset handling will be safe -- will never slide into
        // previous or next day. This is most important to do just before sending
        // to the server. So long as we stay in the client we're clearly consistent.
        // As soon as we leave for the server we can't be sure.
        newDate.setUTCHours(12);

        return newDate;
      },
      // Function for adding a soft-hyphen
      // stringForHyphenation is the string that may be too long and requres a soft-hyphen
      addSoftHyphen: function (stringForHyphenation) {
        if (stringForHyphenation) {

          stringForHyphenation = stringForHyphenation.toString();

          // setting a hard break at 128 characters, this is currently the max limit for refid though
          // break long strings at 128 character intervals
          var parts = stringForHyphenation.match(/.{1,128}/g) || [];

          // glue the strings back together with soft-hyphen ascii character
          var partsReturn = parts.join("&shy;");

          return partsReturn;
        }
      },

      // Adapted from http://www.quirksmode.org/js/cookies.html
      readCookie: function (name) {
        var nameEQ = name + "=";
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
          var cookie = cookies[i];
          while (cookie.charAt(0) === ' ') cookie = cookie.substring(1, cookie.length);
          if (cookie.indexOf(nameEQ) === 0) return cookie.substring(nameEQ.length, cookie.length);
        }
        return null;
      },
      titleCaseString: function (str) {
        var patternToMatch = /([^\s:\-])([^\s:\-]*)/g;
        return str.replace(patternToMatch, function (string, firstChar, subsequentChars) {
          return firstChar.toUpperCase() + subsequentChars.toLowerCase();
        });
      },
      sortByProperty: (propName, lowercase = false) => (a, b) => {
        let A = (lowercase) ? a[propName].toLowerCase() : a[propName];
        let B = (lowercase) ? b[propName].toLowerCase() : b[propName];
        if (A < B) return -1;
        if (A > B) return 1;
        return 0;
      }
    }
  }])
  .factory('bitConstants', [function () {
    const isCandidate = function(candidate, pattern) {
      if (typeof candidate !== 'string') {
        return false;
      }
      return candidate.match(pattern) !== null;
    };
    const isBn = function (candidateBn) {
      return isCandidate(candidateBn, /[A-Z0-9]{12}/);
    };
    const isBnCode = function(candidateBnCode) {
      return isCandidate(candidateBnCode, /^[A-Z0-9]{2}$/);
    };
    const isTypeCode = function(candidateTypeCode) {
      return isCandidate(candidateTypeCode, /^[A-Z0-9]{3}$/);
    };
    return {
      isBn,
      isBnCode,
      isTypeCode,
      isEnterpriseEntityType: function(typeCode) {
        const enterpriseEntityTypes = this.getEnterpriseEntityTypeCodes();
        let index = 0;
        let found = false;
        while (index < enterpriseEntityTypes.length) {
          if (enterpriseEntityTypes[index] === typeCode) found = true;
          index++;
        }
        return found;
      },
      getEnterpriseEntityTypes: function () {
        return [
          EntityTypes['ACT'],
          EntityTypes['CAP'],
          EntityTypes['COM'],
          EntityTypes['CON'],
          EntityTypes['DAT'],
          EntityTypes['DEM'],
          EntityTypes['PHY'],
          EntityTypes['MKT'],
          EntityTypes['ORG'],
          EntityTypes['PER'],
          EntityTypes['PRD'],
          EntityTypes['SKI'],
          EntityTypes['STA'],
          EntityTypes['STR'],
          EntityTypes['SYS'],
          EntityTypes['TEC']
        ]
      },
      getEnterpriseEntityTypeCodes: function () {
        return [
          EntityTypes['ACT'].typeCode,
          EntityTypes['CAP'].typeCode,
          EntityTypes['COM'].typeCode,
          EntityTypes['CON'].typeCode,
          EntityTypes['DAT'].typeCode,
          EntityTypes['DEM'].typeCode,
          EntityTypes['PHY'].typeCode,
          EntityTypes['MKT'].typeCode,
          EntityTypes['ORG'].typeCode,
          EntityTypes['PER'].typeCode,
          EntityTypes['PRD'].typeCode,
          EntityTypes['SKI'].typeCode,
          EntityTypes['STA'].typeCode,
          EntityTypes['STR'].typeCode,
          EntityTypes['SYS'].typeCode,
          EntityTypes['TEC'].typeCode
        ]
      },
      getFieldsetEntityTypes: function () {
        return [
          EntityTypes['ACT'],
          EntityTypes['CAP'],
          EntityTypes['CIR'],
          EntityTypes['COM'],
          EntityTypes['CON'],
          EntityTypes['DAT'],
          EntityTypes['DEM'],
          EntityTypes['PHY'],
          EntityTypes['MKT'],
          EntityTypes['ORG'],
          EntityTypes['PER'],
          EntityTypes['PRD'],
          EntityTypes['SKI'],
          EntityTypes['STA'],
          EntityTypes['STR'],
          EntityTypes['SYS'],
          EntityTypes['TAG'],
          EntityTypes['TEC']
        ]
      },
      getAdvancedQueryEntityTypes: function () {
        return [
          EntityTypes['ACT'], EntityTypes['CAP'], EntityTypes['COM'], EntityTypes['CON'], EntityTypes['DAT'],
          EntityTypes['DEM'], EntityTypes['PHY'], EntityTypes['AST'], EntityTypes['MKT'], EntityTypes['ORG'],
          EntityTypes['PER'], EntityTypes['PRD'], EntityTypes['SKI'], EntityTypes['STA'], EntityTypes['STR'],
          EntityTypes['SYS'], EntityTypes['TAG'], EntityTypes['TEC']
        ]
      },

      getInsightEntityTypes: function () {
        return [
          EntityTypes['ADQ'],
          EntityTypes['AST'],
          EntityTypes['AUD'],
          EntityTypes['CIR'],
          EntityTypes['CRL'],
          EntityTypes['LYT'],
          EntityTypes['TAG'],
          EntityTypes['WK2']
        ]
      },

      getMetricAggregationEntityTypes: function () {
        return [
          EntityTypes['CAP'], EntityTypes['DEM'], EntityTypes['ORG'], EntityTypes['SYS']
        ]
      },

      getMatrixRowsEntityTypes: function () {
        return [
          EntityTypes['SYS']
        ]
      },

      getMatrixColumnsEntityTypes: function () {
        return [
          EntityTypes['CAP']
        ]
      },

      getEntityTypeForEntityTypeCode: function (entityTypeCode) {
        if (isTypeCode(entityTypeCode)) {
          const key = Object.keys(EntityTypes)
            .find(ent => EntityTypes[ent].typeCode === entityTypeCode);
          if (key != null) return EntityTypes[key];
        }
        return '';
      },
      getEntityTypeForBnCode: function (bnCode) {
        if (isBnCode(bnCode)) {
          const key = Object.keys(EntityTypes)
            .find(ent => EntityTypes[ent].bnCode === bnCode);
          if (key != null) return EntityTypes[key];
        }
        return '';
      },

      getEntityTypeDisplayNameForTypeCode: function (entityTypeCode, plural) {
        var entityType = this.getEntityTypeForTypeCode(entityTypeCode);
        if (entityType) {
          return plural ? entityType.displayNamePlural : entityType.displayName;
        }
      },

      getEntityTypeForTypeCode: function (entityTypeCode) {
        return EntityTypes[entityTypeCode];
      },

      getFieldDataTypeDisplayValue: function (dataType) {
        return fieldDataTypeToDisplayValue[dataType];
      },

      getFieldDisplayTypeDisplayValue: function (displayType) {
        return fieldDisplayTypeToDisplayValue[displayType];
      },

      getFieldDisplayTypesMatrix: function () {
        return fieldDisplayTypeMatrix;
      },

      getFieldDataTypes: function () {
        return fieldDataTypes;
      },

      getModalOptions: function () {
        return {
          backdropFade: true,
          dialogFade: false
        }
      }
    };
  }])

  .factory('queryKeyService', ['$http', function ($http) {
    return {
      getQueryKeyForPivot: function (paramsObj) {

        var jsonReq = angular.toJson(paramsObj);
        var request = $.param({request: jsonReq});
        return $http({
          method: 'POST',
          url: '/b/api/querykey',
          data: request,
          headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).success(function (data, status, headers, config) {
        }).error(function (data, status, headers, config) {
          console.log('error in getQueryKeyForPivot');
        });
      },

      getQueryKeyForDomainPivot: function (entityTypeCode, domainBn, defaultLifecycleState) {
        var lifecycleStateParam = (defaultLifecycleState) ? '?useDefaultLifecycleState=true' : '';
        return $http({
          method: 'GET',
          url: '/b/api/querykey/domain/' + entityTypeCode + '/' + domainBn + lifecycleStateParam
        }).success(function (data, status, headers, config) {
        }).error(function (data, status, headers, config) {
          console.log('error in getQueryKeyForDomainPivot');
        });
      },

      getQueryKeyForTypePivot: function (entityTypeCode, typeBn, defaultLifecycleState) {
        var propertyKey = TYPE_DATA_DICT_PROPERTY_KEYS[entityTypeCode];
        var lifecycleStateParam = (defaultLifecycleState) ? '?useDefaultLifecycleState=true' : '';
        return $http({
          method: 'GET',
          url: '/b/api/querykey/property/' + entityTypeCode + '/' + typeBn + '/' + propertyKey + lifecycleStateParam
        }).success(function (data, status, headers, config) {
        }).error(function (data, status, headers, config) {
          console.log('error in getQueryKeyForPropertyPivot');
        });
      },

      getQueryKeyForGlobalSearch: function (searchString) {
        var jsonString = JSON.stringify(
          {
            filter: {
              searchString: searchString,
              params: null
            },
            pivot: null,
            entityType: null
          }
        );
        var request = $.param({request: jsonString});
        return $http({
          method: 'POST',
          url: '/b/api/querykey',
          data: request,
          headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).success(function (data, status, headers, config) {
        }).error(function (data, status, headers, config) {
          console.log('error in getQueryKeyForGlobalSearch for search string: ' + searchString);
        });
      },

      getGlobalSearchNodesForQueryKey: function (queryKey) {
        return $http({
          method: 'GET',
          url: '/b/api/query/' + queryKey + '/description'
        }).success(function (data, status, headers, config) {
        }).error(function (data, status, headers, config) {
          console.log('error in getGlobalSearchNodesForQueryKey for queryKey: ' + queryKey);
        });
      }
    }
  }])

  .factory('dataDictionaryHeaderService', ['$http', function ($http) {
    return {
      getAllDatadictionaryHeaders: function (entityTypeCode) {
        return $http({
          method: 'GET',
          url: `/b/api/entitytypes/${entityTypeCode}/properties`,
          params: {
            displayValues: false
          }
        }).success(function (data, status, headers) {
          //NOOP
        }).error(function (data, status, headers) {
          console.log("error in dataDictionaryHeaderService.readPropertyBnsByEntityTypeAndSearchFieldName for entityTypeCode: " + entityTypeCode + " and searchableFieldName: " + searchFieldName);
          errorHandlingService.handleGenericError(status);
        })
      }
,
      getDataDictionaryHeaders: function (entityTypeCode, uploadable) {
        var jsonReq = typeof entityTypeCode === 'undefined' ? null : angular.toJson(entityTypeCode);
        var request = $.param({entityTypeCode: jsonReq});
        var uploadQueryParam = true;
        if (uploadable === false) {
          uploadQueryParam = false;
        }
        return $http({
          method: 'GET',
          url: `/b/api/entitytypes/${entityTypeCode}/properties?displayValues=false`,
          data: request,
          headers: {'Content-Type': 'application/json;charset=UTF-8' },
          params: {
            uploadable: uploadQueryParam
          }
        }).success(function (data, status, headers) {
        }).error(function (data, status, headers) {
          console.log('error in dataDictionaryHeaderService.getDataDictionaryNames for entityTypeCode: ' + entityTypeCode);
        });
      },
      getDataDictionaryBnsByEntityTypeAndSearchableFieldName: function (entityTypeCode, searchFieldName) {
        return $http({
          method: 'GET',
          url: `/b/api/entitytypes/${entityTypeCode}/properties?displayValues=false`,
          params: {
            searchableName: searchFieldName
          }
        }).success(function (data, status, headers) {
          //NOOP
        }).error(function (data, status, headers) {
          console.log("error in dataDictionaryHeaderService.readPropertyBnsByEntityTypeAndSearchFieldName for entityTypeCode: " + entityTypeCode + " and searchableFieldName: " + searchFieldName);
          errorHandlingService.handleGenericError(status);
        })
      }
    }
  }])

  .factory('optionListService', ['$http', function ($http) {
    return {
      getOptionList: function (dataListTypesReq) {
        // when undefined, IE8 screws up the ensuing request.  null is better. (BARO-12969)
        var request = typeof dataListTypesReq === 'undefined' ? [] : angular.toJson(dataListTypesReq);
        return $http({
          method: 'POST',
          url: '/b/api/optionlistsdata',
          data: request,
          headers: {'Content-Type': 'application/json'}
        }).success(function (data, status, headers, config) {
        }).error(function (data, status, headers, config) {
          console.log('error in optionListService.getOptionListChoices for dataListTypesReq: ', dataListTypesReq);
        });
      },
      getOptionListChoices: function (dataListTypesReq) {

        // when undefined, IE8 screws up the ensuing request.  null is better. (BARO-12969)
        var jsonReq = typeof dataListTypesReq === 'undefined' ? [] : angular.toJson(dataListTypesReq);
        return $http({
          method: 'POST',
          url: '/b/api/optionlists',
          data: jsonReq,
          headers: {'Content-Type': 'application/json'}
        }).success(function (data, status, headers, config) {
        }).error(function (data, status, headers, config) {
          console.log('error in optionListService.getOptionListChoices for dataListTypesReq: ', dataListTypesReq);
        });
      },
      getOptionListItemByBn: function (bn) {
        return $http({
          method: 'GET',
          url: '/b/api/optionlists/' + bn
        }).success(function (data, status, headers, config) {
        }).error(function (data, status, headers, config) {
          console.log('error in optionListService.getOptionListItemByBn for bn: ', bn);
        });
      },
      getContactTypesForAudit: function (auditBn) {
        return $http({
          method: 'POST',
          url: '/b/api/auditContactTypes/' + auditBn
        })
      },
      getContactTypesForRules: function (ruleBn) {
        return $http({
          method: 'POST',
          url: '/b/api/ruleContactTypes/' + ruleBn
        })
      },
      updateAuditContactTypes: function (auditBn, updateJson) {
        var request = $.param({contactTypes: angular.toJson(updateJson)});
        return $http({
          method: 'POST',
          url: '/b/api/updateAuditContactTypes/' + auditBn,
          data: request,
          headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        })
      }
    }
  }])

  .factory('relationshipDataService', ['$http', function ($http) {
    return {
      getRelationshipPatterns: function(fromType, toType, flatten) {
        let urlStr = '/b/api/relationshippatterns';

        if (flatten == null) flatten = true;

        if (fromType != null && toType != null) {
          urlStr += `?flatten=${flatten}&fromType=${fromType}&toType=${toType}`;
        } else if (fromType != null && flatten) {
          urlStr += `?flatten=${flatten}&type=${fromType}`;
        } else if(fromType !== null && toType === null && !flatten) {
          urlStr += `?type=${fromType}`;
        }
        return $http({
          method: 'GET',
          url: urlStr
        }).success(function (data, status, headers, config) {
        }).error(function (data, status, headers, config) {
          console.log('error in relationshipDataService.getRelationshipPatterns for entityType: ', fromType);
        });
      }
    }
  }])


  /**
   * Returns a markup string from a Wicket component for a given url
   */
  .factory('wicketMarkupService', ['$http', '$rootScope', 'errorHandlingService', function ($http, $rootScope, errorHandlingService) {
    return {
      getMarkup: function (parameterizedUrl) {
        return $http({method: 'GET', url: parameterizedUrl}).success(function (data, status, headers, config) {
          //
        }).error(function (data, status, headers, config) {
          errorHandlingService.handleGenericError(status);
        });
      }
    };
  }])
  .factory('assocService', ['$http', 'errorHandlingService', '$rootScope', function ($http, errorHandlingService, $rootScope) {
    const buildWorksheetQueryString = (insightTypeBn) => {
      const filter = {
        "params": [{"544600000372": [`${insightTypeBn}`]}],
        "searchString": null
      };
      return JSON.stringify(filter);
    };
    return {
      getAssocList: function (entityBn, assocType) {
        return $http({
          method: 'GET',
          url: '/b/api/assoc/list/' + entityBn + '/' + assocType
        }).success(function (data, status, headers, config) {
          //
        }).error(function (data, status, headers, config) {
          errorHandlingService.handleGenericError(status);
          console.error('Error calling assocService.getAssocCount entityBn: ' + entityBn + ' assocType: ' + assocType);
        });
      },
      getAllWorkSheetsByInsightTypeBn: function (insightTypeBn) {
        var url = '/b/api/browse';
        var queryParams = {
          format: 'LIST',
          first: 0,
          count: 99999,
          sortPropertyBn: null, // currently the sort property name. need to pass in bn
          ascending: true, // currently passing in params.descending. need to fix this
          bnCode: '4M',
          queryKey: null,
          filter: (!insightTypeBn) ? null : buildWorksheetQueryString(insightTypeBn),
          columnSpecPurpose: null,
          includeColumnSpec: false,
          includeFilterSpec: false,
          useDefaultQuery: false,
          insightTypeBn: insightTypeBn || ''
        };
        return $http({
          method: "GET",
          url: url,
          params: queryParams
        }).success(function (data, status, headers, config) {

        }).error(function (data, status, headers, config) {
          $rootScope.$broadcast('errorMessage', 'An unexpected error occurred');
          errorHandlingService.handleGenericError(status);
        });
      }
    };
  }])
  .factory('pageService', ['$timeout', '$window', function ($timeout, $window) {
    var _title = "",
      _isEntityPage = false,
      _entity = {};
    return {
      setTitle: function (title) {
        _title = title;
        // this seems like a decent place for this, but maybe should broadcast even and have main app ctrl execute this code?
        $timeout(function () {
          $window.document.title = String.format("Changepoint EAM : {0}", _title);
        }, 500)
      },
      getTitle: function () {
        return _title;
      },
      setEntity: function (entity) {
        _entity = entity;
      },
      getEntity: function () {
        return _entity;
      },
      isEntityPage: function () {
        return _isEntityPage;
      },
      setIsEntityPage: function (value) {
        _isEntityPage = value;
      }
    };
  }])

  // Client-side cache of fetched associations to allow client-side filtering/flagging of existing associations
  // No fetching data should occur here - that all occurs in other services which cache and update associated entities here.
  // Format of cache is { 012345678912: true, ... }
  // The lifespan of this cache is one pageview.
  .factory('associatedBnCacheService', ['$rootScope', '$http', function ($rootScope, $http) {
    var _fetchedAssocBns = {};
    return {
      isAssociated: function (bn) {
        return _fetchedAssocBns.hasOwnProperty(bn);
      },
      addAssociatedBn: function (associatedBn) {
        _fetchedAssocBns[associatedBn] = true;
      },
      addAssociatedBns: function (bns) {
        _.each(bns, function (bn) {
          _fetchedAssocBns[bn] = true;
        });
      },
      removeAssociatedBn: function (bn) {
        return delete _fetchedAssocBns[bn];
      },
      removeAssociatedBns: function (bns) {
        _.each(bns, function (bn) {
          delete _fetchedAssocBns[bn];
        })
      },
      hasData: function () {
        return Object.keys(_fetchedAssocBns).length > 0;
      },
      clear: function () {
        _fetchedAssocBns = {}
      }
    }
  }])

  /*
   *Service used for localization
   * It is used to load translation resources into local storage,
   * Fetch values from translation resources,
   * Check versioning of translation resources
   */
  .factory('translatorService', ['$http', '$timeout', 'localStorageService', 'utilService',
    function ($http, $timeout, localStorageService, utilService) {

      var fileLocation = '{0}_{1}.properties';//Stores format for file location TODO fix file location

      return {
        //Given a resource pack and key, returns the associated translated value
        //If necessary loads the resource into local storage
        translate: function (resource, key) {
          if (!(this.checkVersion(resource))) {
            this.loadResource(resource);
          }

          var obj = localStorageService.get(resource);
          return obj[key];
        },
        //Loads the given resource into local storage
        //This is a synchronous http request
        //TODO make this asynchronous???
        loadResource: function (resource) {
          var loc = String.format(fileLocation, resource, this.getLocale());
          var request = new XMLHttpRequest();
          request.open('GET', loc, false);
          request.send(null);

          if (request.status === 200) {
            var response = request.responseText;
            response = JSON.parse(response);
            this.curVersion = response.version;
            localStorageService.set(resource, response);
          }
        },

        //Checks if the version of the current loaded translation file
        //(if exists) is the most recent version.
        //This checks the actual version once a page loads
        //The check is done by making a synchronous http call to
        //TODO make this asynchronous????
        checkVersion: function (resource) {
          var storedVal = localStorageService.get(resource);
          //If there is nothing loaded
          if (storedVal === null) {
            return false;
          }
          //If the version has not been checked yet
          if (this.curVersion === null) {
            var loc = String.format(fileLocation, resource, this.getLocale());
            var request = new XMLHttpRequest();
            request.open('GET', loc, false);
            request.send(null);
            if (request.status === 200) {
              var response = JSON.parse(request.responseText);
              this.curVersion = response.version;
              if (response.version !== storedVal.version) {
                localStorageService.remove(resource);
                localStorageService.set(resource, response);
              }
              return true;
            }
          }
          //If current loaded version does not match the last check
          if (this.curVersion !== storedVal.version) {
            localStorageService.remove(resource);
            return false;
          }
          return true;
        },

        //Gets the locale from the tenant
        //TODO get locale information from tenant
        //TODO expand to get information from user
        getLocale: function () {
          // var tenantBn = utilService.getTenantBn();
          //getLocale(tenantBn);
          return 'en';
        }
      };
    }])
;
